const INITIAL_STATE = {
    loading: false,
  };
  
  const loadingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START_LOADING":
        return { ...state, loading: true };
      case "STOP_LOADING":
        return { ...state, loading: false };
      default:
        return { ...state };
    }
  };
  
  export default loadingReducer;
  