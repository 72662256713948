const INITIAL_STATE = {
  careDate: "",
  codeCCAM: "",
  dents: "",
  honoraire: "",
};

const actReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_ACT_FIELD": {
      const key = action.payload.key;
      const value = action.payload.value;
      // if (key === 'careDate') {
      //   console.log('careDate: ', value);
      // }
      const newState = {
        ...state,
        [key]: value,
      };
      return newState;
    }
    case "RESET_ACT_FORM": {
      return { ...INITIAL_STATE };
    }
    default: {
      return { ...state };
    }
  }
};

export default actReducer;
