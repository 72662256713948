const INITIAL_STATE = {
  isLogged: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CONNECT_USER": {
      const newState = {
        ...state,
        isLogged: true,
      };
      return newState;
    }
    case "DISCONNECT_USER": {
      console.log("disconnected");
      return { ...INITIAL_STATE };
    }
    default: {
      return { ...state };
    }
  }
};

export default userReducer;
