import { combineReducers } from "redux";
import loadingReducer from "./loading-reducer";
import loginReducer from "./login-reducer";
import userReducer from "./user-reducer";
import actesReducer from "./actes-reducer";
import actReducer from "./act-reducer";
import clinicReducer from "./clinic-reducer";
import modalReducer from "./modal-reducer";

const finalReducer = combineReducers({
  // add reducers here
  actReducer,
  userReducer,
  actesReducer,
  loginReducer,
  clinicReducer,
  loadingReducer,
  modalReducer,
});

export default finalReducer;
