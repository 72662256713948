const INITIAL_STATE = {
  clinic: "",
  pecs: [],
  clinics: [],
};

const clinicReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_CLINIC": {
      const selectedClinic = action.payload;
      const newState = {
        ...state,
        clinic: selectedClinic,
      };
      return newState;
    }
    case "RESET_CLINIC": {
      return { ...INITIAL_STATE };
    }
    case "GET_ALL_CLINICS": {
      const allClinics = action.payload;
      const newState = {
        ...state,
        clinics: allClinics,
      };
      return newState;
    }
    case "GET_ALL_CLINIC_PECS": {
      const allPecs = action.payload;
      const newState = {
        ...state,
        pecs: allPecs,
      };
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};

export default clinicReducer;
