import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase.config";
import mc from "./navbar.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const NavBar = ({ isLogged, logo, logoSeasy }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutUser = async (event) => {
    event.preventDefault();
    await signOut(auth);
    dispatch({ type: "DISCONNECT_USER" });
    navigate("/", { replace: true });
  };
  return (
    <nav
      className="navbar is-fixed-top has-shadow"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="https://staging.seasy.cloud">
          <img
            src={logo}
            alt="logo"
            className={mc["logo-seasy"]}
            // width="60"
            // height="60"
          />
        </a>

        {/* <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          href=""
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a> */}
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <div className={`navbar-item`}>
          <img
            src={logoSeasy}
            alt=""
            className={mc["logo-seasy"]}
            // width="60"
            // height="60"
          />
            {/* <strong>seasy</strong> */}
          </div>
          {isLogged && (
            <div className="navbar-item has-control">
              <div className="control">
                <input
                  placeholder="Recherche..."
                  className="input is-rounded"
                />
              </div>
            </div>
          )}
          {/* 
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">More</a>

            <div className="navbar-dropdown">
              <a className="navbar-item">About</a>
              <a className="navbar-item">Jobs</a>
              <a className="navbar-item">Contact</a>
              <hr className="navbar-divider" />
              <a className="navbar-item">Report an issue</a>
            </div>
          </div> */}
        </div>

        <div className="navbar-end">
          {isLogged && (
            <div className="navbar-item">
              <div className="buttons">
                <a className={`button is-light ${mc.operator}`} href="/#">
                  <i className="fa-solid fa-circle-user"></i>
                  <strong>Admin Operator</strong>
                </a>
                <a onClick={(e) => logoutUser(e)} className="button is-primary" href="/#">
                  <i className="fas fa-right-from-bracket"></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
