import { useDispatch, useSelector } from "react-redux";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebase.config";
import formatDateInput from "../../utils/functions/formatDate";
import { useNavigate } from "react-router-dom";

const BACKEND = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PORT}`;

const writePecToDB = async (clinicId, data) => {
  console.log("data in writePecToDB: ", data);
  try {
    // Check if user is authenticated
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User not authenticated");
    }
    // Get the collection "clinics"
    const clinicRef = doc(db, "clinics", clinicId);
    // Get the sub-collection "pecs"
    const pecsRef = collection(clinicRef, "pecs");
    // Format date in actes array
    // const formattedData = {
    //   ...data,
    //   ...(data.actes = data.actes.forEach(
    //     (a, i) => (a.careDate = formatDateInput(a.careDate))
    //   )),
    // };
    // Add a new document with generated id
    const newPecRef = await addDoc(pecsRef, data);
    console.log("Document written with ID: ", newPecRef.id);
    // console.log("formatted Data: ", formattedData);
    return newPecRef.id;
  } catch (error) {
    console.error("Error writing PEC to DB:", error);
    throw error;
  }
};

const updatePecInDB = async (clinicId, pecId, key, newValue) => {
  try {
    // Check if user is authenticated
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User not authenticated");
    }
    // Get the collection "clinics"
    const clinicRef = doc(db, "clinics", clinicId);
    // Get the sub-collection "pecs"
    const pecsRef = collection(clinicRef, "pecs");
    // Get the PEC document id
    const pecRef = doc(pecsRef, pecId);
    // Update PEC
    const updatedPec = await updateDoc(pecRef, { [key]: newValue });

    console.log(`PEC ${pecId} updated successfully`);
    return updatedPec;
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

const submitPecToMutuelle = async (clinicId, data) => {
  try {
    // Get the Firebase ID token
    const idToken = await auth.currentUser.getIdToken();
    // Format date in actes array
    // const formattedData = {
    //   ...data,
    //   ...(data.actes = data.actes.forEach(
    //     (a, i) => (a.careDate = formatDateInput(a.careDate))
    //   )),
    // };

    // Make an HTTP request to the Cloud Function endpoint, the function must be deployed in firebase function !
    const response = await fetch(
      `${BACKEND}/submitPec${data.reseau}`,
      // `https://us-central1-medsimple-gtp.cloudfunctions.net/submitPec${data.reseau}`,
      // "http://127.0.0.1:5001/medsimple-gtp/us-central1/submitPecAlmerys",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`, // Include the Firebase ID token in the Authorization header
        },
        body: JSON.stringify({ clinicId, data }), // Include the clinicId in the request body
      }
    );

    // Check if the request was successful
    if (response.ok) {
      // Handle success
      console.log("PEC submitted successfully");
    } else {
      // Handle error
      console.error("Failed to submit PEC:", response.statusText);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const Modal = ({ isActive, clinic }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((store) => store.loadingReducer);
  const {
    numDevis,
    dateDevis,
    expireDevis,
    numSS,
    lastName,
    firstName,
    dob,
    reseau,
    mutuelle,
    doctor,
    actes,
    statut,
  } = useSelector((store) => store.actesReducer);

  const pecData = {
    numDevis,
    dateDevis: formatDateInput(dateDevis),
    expireDevis: formatDateInput(expireDevis),
    numSS,
    patientFirstName: firstName,
    patientLastName: lastName,
    patientDob: formatDateInput(dob),
    reseau,
    mutuelle,
    doctor,
    actes,
    statut,
  };

  const handleClickCancel = (e) => {
    e.preventDefault();
    dispatch({ type: "CLOSE_MODAL" });
  };

  const handleClickValidate = async (e) => {
    e.preventDefault();
    dispatch({ type: "START_LOADING" });
    // Write data in database :
    console.log("Writing data in DB...");
    console.log("pec data sending to DB: ", pecData);
    const pecId = await writePecToDB(clinic.id, pecData);
    // Send data to mutuelle :
    console.log("Sending data to Mutuelle...");
    console.log("pec data sending to Mutu: ", pecData); // pecData.actes === undefined ??
    await submitPecToMutuelle(clinic.id, pecData);
    // if data successfully sent, change statut of pec to 'En Cours' in database
    console.log("Updating data in DB...");
    await updatePecInDB(clinic.id, pecId, "statut", "En Cours");
    console.log("End");
    dispatch({ type: "STOP_LOADING" });
    // clear form
    dispatch({ type: "RESET_ACTES_FORM" });
    // close modal
    dispatch({ type: "CLOSE_MODAL" });
    navigate(`/dashboard/${clinic.id}`, { replace: true });
  };

  return (
    <div className={`modal ${isActive ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Vérifier la Prise En Charge</p>
          <button
            className="delete"
            aria-label="close"
            onClick={(e) => handleClickCancel(e)}
            disabled={loading}
          ></button>
        </header>
        <section className="modal-card-body">
          <ul>
            <li>N° Devis: {numDevis}</li>
            <li>Cabinet: {clinic.data.name}</li>
            <li>Praticien: {doctor}</li>
            <li>PATIENT:</li>
            <li>N° SS: {numSS}</li>
            <li>Nom: {lastName}</li>
            <li>Prénom: {firstName}</li>
            <li>Date de Naissance: {dob}</li>
            <li>Réseau Tiers-Payant: {reseau}</li>
            <li>Mutuelle: {mutuelle}</li>
            <li>ACTES:</li>
            <li>
              <div className="block">
                <table className="table is-striped is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th>Date Soins</th>
                      <th>Code CCAM</th>
                      <th>{`# Dent(s)`}</th>
                      <th>{`Honoraire TTC (€)`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actes.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{v.careDate}</td>
                          <td>{v.codeCCAM}</td>
                          <td>{v.dents}</td>
                          <td>{v.honoraire}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-success ${loading ? "is-loading" : ""}`}
            onClick={(e) => handleClickValidate(e)}
          >
            Valider
          </button>
          <button
            className="button"
            onClick={(e) => handleClickCancel(e)}
            disabled={loading}
          >
            Annuler
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
