const INITIAL_STATE = {
  numDevis: "",
  dateDevis: "",
  expireDevis: "",
  numSS: "",
  firstName: "",
  lastName: "",
  dob: "",
  reseau: "",
  mutuelle: "",
  doctor: "",
  actes: [],
  statut: "Création",
};

const actesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_ACTES_FIELD": {
      const key = action.payload.key;
      const value = action.payload.value;
      const newState = {
        ...state,
        [key]: value,
      };
      return newState;
    }
    case "UPDATE_ACTES_FIELD_BY_INDEX": {
      const key = action.payload.key;
      const value = action.payload.value;
      const index = action.payload.index;
      const updatedActes = [...state.actes];

      updatedActes[index] = { ...updatedActes[index], [key]: value };

      // console.log("updated actes: ", updatedActes);
      const newState = {
        ...state,
        actes: updatedActes,
      };
      return newState;
    }
    case "RESET_ACTES_FORM": {
      return { ...state, ...INITIAL_STATE };
    }
    case "ADD_ACT_IN_ACTES": {
      const newAct = action.payload;
      const newState = {
        ...state,
        actes: [...state.actes, newAct],
      };
      return newState;
    }
    case "DELETE_ACT_FROM_ACTES": {
      const indexToDelete = action.payload;
      const updatedActes = [...state.actes];
      updatedActes.splice(indexToDelete, 1);
      return {
        ...state,
        actes: updatedActes,
      };
    }
    case "SEND_FORM": {
      const newState = { ...state };
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};

export default actesReducer;
