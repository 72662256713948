import { Link } from "react-router-dom";
import { useEffect } from "react";
import mc from "./dashboard.module.scss";
import "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../config/firebase.config";
import { useDispatch, useSelector } from "react-redux";

// Function to fetch clinics from Firestore
const fetchClinics = async () => {
  try {
    // Check if user is authenticated
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User not authenticated");
    }
    // Get the collection "clinics"
    const clinicsSnapshot = await getDocs(collection(db, "clinics"));
    // Extract data from snapshot
    const clinics = clinicsSnapshot.docs.map((doc) => {
      return { id: doc.id, data: doc.data() };
    });
    // Return the array of clinics
    return clinics;
  } catch (error) {
    console.error("Error fetching clinics:", error);
    throw error; // You can handle the error appropriately in your application
  }
};

const Dashboard = ({ handleClickClinic }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allClinics = await fetchClinics();
        dispatch({ type: "GET_ALL_CLINICS", payload: allClinics });
      } catch (error) {
        console.error("Error fetching clinics:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const { clinics } = useSelector((store) => store.clinicReducer);

  return (
    <div className="columns is-multiline is-3">
      {clinics.map((v, i) => {
        return (
          <div key={i} className="column is-one-quarter">
            <div className="card" onClick={() => handleClickClinic(v)}>
              <Link to={`/dashboard/${v.id}`}>
                <div className={mc.wrapper}>
                  <div className="card-content">
                    <div className="content">
                      <p className="title is-4">{v.data.name}</p>
                      <p className="subtitle is-6">{v.data.city}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
