const INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
  };
  
  const loginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "UPDATE_LOGIN_FIELD": {
        return { ...state, [action.payload.key]: action.payload.value };
      }
      case "RESET_LOGIN_FORM": {
        return { ...state, ...INITIAL_STATE };
      }
      case "SET_LOGIN_ERROR": {
        return { ...state, error: action.payload.error };
      }
      case "RESET_LOGIN_ERROR": {
        return { ...state, error: null };
      }
      default:
        return { ...state };
    }
  };
  
  export default loginReducer;
  