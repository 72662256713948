export const listMutuelles = [
  { reseau: "Almerys", mutuelles: [] },
  {
    reseau: "Carte Blanche",
    mutuelles: [],
    url1: "https://pec.carteblanchepartenaires.fr/#/login",
    url2: "https://pec.carteblanchepartenaires.fr/#/login?username=P0398428631"
  },
  { reseau: "Corelio", mutuelles: [] },
  { reseau: "Groupama", mutuelles: [] },
  { reseau: "I Santé", mutuelles: [] },
  { reseau: "Itelis", mutuelles: [] },
  { reseau: "Kalixia", mutuelles: [] },
  { reseau: "Matmut", mutuelles: [] },
  { reseau: "Oxantys", mutuelles: [] },
  { reseau: "Santé Clair", mutuelles: [] },
  { reseau: "Seveane", mutuelles: [] },
  { reseau: "SP Santé", mutuelles: [] },
  { reseau: "Viamedis", mutuelles: [] },
];
