import { Link } from "react-router-dom";
import mc from "./sidebar.module.scss";

const SideBar = () => {
  return (
    <aside className={`menu ${mc.theme}`}>
      <p className="menu-label">Général</p>
      <ul className="menu-list">
        <li>
          <Link className={mc.link} to="/dashboard">
              <span className="icon-text">
                <span className="icon">
                  <i className="fas fa-gauge-high"></i>
                </span>
                <span>Dashboard</span>
              </span>
          </Link>
        </li>
      </ul>
      <p className="menu-label">Administration</p>
      <ul className="menu-list">
        <li>
          <a href="https://staging.seasy.cloud/stats/">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-chart-column"></i>
              </span>
              <span>Stats</span>
            </span>
          </a>
        </li>
      </ul>
      <p className="menu-label">Réglages</p>
      <ul className="menu-list">
        <li>
          <a href="https://staging.seasy.cloud/settings/">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-gears"></i>
              </span>
              <span>Paramètres</span>
            </span>
          </a>
        </li>
        <li>
          <a href="https://staging.seasy.cloud/help/">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-circle-info"></i>
              </span>
              <span>Aide</span>
            </span>
          </a>
        </li>
        <li>
          <a href="https://staging.seasy.cloud/contacts/">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-headset"></i>
              </span>
              <span>Contact</span>
            </span>
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
