const Hero = ({ title, subtitle }) => {
  return (
    <section className="hero is-small is-info">
      <div className="hero-body">
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
      </div>
    </section>
  );
};

export default Hero;
