import { listCodeCCAM } from "../../utils/constants/codeCcam.constant";
import Hero from "../hero/Hero";
import SearchBar from "../searchbar/SearchBar";
import { listMutuelles } from "../../utils/constants/mutuelles.constant";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal/Modal";
import { useEffect, useState } from "react";
import { auth, storage } from "../../config/firebase.config";
import { ref, uploadBytes } from "firebase/storage";

const BACKEND = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PORT}`;
const apiKey = `${process.env.REACT_APP_OPENAI_API_KEY}`;

const NewPec = ({ clinic }) => {
  const dispatch = useDispatch();
  const {
    numDevis,
    dateDevis,
    expireDevis,
    numSS,
    lastName,
    firstName,
    dob,
    reseau,
    mutuelle,
    doctor,
    actes,
    statut,
  } = useSelector((store) => store.actesReducer);
  const { careDate, codeCCAM, dents, honoraire } = useSelector(
    (store) => store.actReducer
  );
  const { loading } = useSelector((store) => store.loadingReducer);
  const { isActive } = useSelector((store) => store.modalReducer);

  const handleChangeFieldActes = (key, value) => {
    dispatch({ type: "UPDATE_ACTES_FIELD", payload: { key, value } });
  };
  const handleChangeFieldAct = (key, value) => {
    dispatch({ type: "UPDATE_ACT_FIELD", payload: { key, value } });
  };

  const handleUpdateFieldAct = (key, value, index) => {
    dispatch({
      type: "UPDATE_ACTES_FIELD_BY_INDEX",
      payload: { key, value, index },
    });
  };

  const findDesignationByCodeCCAM = (codeCCAM) => {
    const result = listCodeCCAM.find((obj) => obj.codeCCAM === codeCCAM);
    return result ? result.designation : null;
  };

  const handleClickAddAct = (e) => {
    e.preventDefault();
    // add act in array actes reducer
    dispatch({
      type: "ADD_ACT_IN_ACTES",
      payload: {
        careDate,
        codeCCAM,
        dents,
        honoraire,
      },
    });
    dispatch({ type: "RESET_ACT_FORM" });
  };

  const handleClickDeleteAct = (e, index) => {
    e.preventDefault();
    dispatch({ type: "DELETE_ACT_FROM_ACTES", payload: index });
  };

  const submitPEC = (e) => {
    e.preventDefault();
    dispatch({
      type: "SEND_FORM",
      payload: { numDevis, numSS, reseau, mutuelle, doctor, actes, clinic },
    });
    dispatch({ type: "OPEN_MODAL" });
  };

  // ============= UPLOAD DEVIS PDF TO EXTRACT DATA =============== //
  const [fileDevis, setFileDevis] = useState(null);

  const handleChangeFileDevis = (e) => {
    const selectedFile = e.target.files[0];
    setFileDevis(selectedFile);
  };

  const uploadFileToDB = async (clinicId) => {
    try {
      // Check if user is authenticated
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("User not authenticated");
      }

      if (fileDevis) {
        const metadata = {
          customMetadata: {
            clinicId: clinicId,
            originalFileName: fileDevis.name,
            jsonFileLocation: "",
            status: "",
          },
        };
        const storageDevisRef = ref(
          storage,
          `devis/${clinic.data.name}-${fileDevis.name}`
        );
        uploadBytes(storageDevisRef, fileDevis, metadata).then((snapshot) => {
          console.log("Uploaded a devis file!: ", storageDevisRef.name);
        });
        return storageDevisRef;
      } else {
        console.alert("please choose a file first");
        return;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const processPdfToJson = async (filePath, fileName) => {
    try {
      // Get the Firebase ID token
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(`${BACKEND}/processDevisPdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({ filePath, fileName }),
      });

      let jsonFilePath;
      if (response.ok) {
        const data = await response.json();
        jsonFilePath = data.devisJsonPath;
        console.log(
          "PDF processed successfully and stored in JSON: ",
          jsonFilePath.destinationUri
        );
        return jsonFilePath;
      } else {
        console.error("Failed to process PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error Processing Pdf to Json: ", error);
    }
  };

  const getJsonText = async (jsonFileName) => {
    try {
      // Get the Firebase ID token
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(`${BACKEND}/extractJsonText`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({ jsonFileName }),
      });

      let text;
      if (response.ok) {
        // Handle success
        const data = await response.json();
        // Handle the extracted data
        text = data.extractedTexts;
        console.log("JSON file text extracted successfully!");
      } else {
        // Handle error
        console.error(
          "Failed to extract text from JSON: ",
          response.statusText
        );
      }
      return text;
    } catch (error) {
      console.error("Error extracting text from Json: ", error);
    }
  };

  const getPecData = async (apiKey, textsArray) => {
    try {
      // Get the Firebase ID token
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(`${BACKEND}/processDevisTextAI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${idToken}`,
        },
        body: JSON.stringify({ apiKey, textsArray }),
      });

      let pecData;
      if (response.ok) {
        // Handle success
        const structuredData = await response.json();
        // Handle the extracted data
        pecData = structuredData.data;
        console.log("PEC data processed successfully!");
      } else {
        // Handle error
        console.error("Failed to process PEC data: ", response.statusText);
      }
      return pecData;
    } catch (error) {
      console.error(
        "Error Processing PEC data from Devis Texts Array: ",
        error
      );
    }
  };

  const fillForm = async (dataObj) => {
    for (let key in dataObj) {
      let value = dataObj[key];
      if (key === "numSS") {
        value = value.length === 16 ? value.slice(0, -1) : value;
      }
      console.log("key, value: ", key + ", " + value);
      dispatch({ type: "UPDATE_ACTES_FIELD", payload: { key, value } });
    }
  };

  const handleClickUploadDevis = async (e) => {
    e.preventDefault();
    dispatch({ type: "START_LOADING" });
    // Upload to firebase storage :
    console.log("Uploading Devis PDF into cloud storage...");
    const file = await uploadFileToDB(clinic.id);
    const filePath = file.fullPath;
    const fileName = file.name;
    // Process Google Cloud Vision OCR, PDF to JSON file in Cloud Storage :
    console.log("Processing OCR to get JSON File...");
    const jsonDevisPath = await processPdfToJson(filePath, fileName);
    console.log("JSON file data: ", jsonDevisPath); // destinationUri: 'gs://medsimple-gtp.appspot.com/ocr_results/infinidents-DEVIS-5513_', fileNameRoot: 'infinidents-DEVIS-5513'
    // Extract data from JSON file :
    console.log("Extracting data from JSON file...");
    const devisText = await getJsonText(jsonDevisPath.fileNameRoot);
    // const devisText = await getJsonText("infinidents-DEVIS-5513");
    console.log("devis text: ", devisText);
    // Process OpenAI to get usefull data
    console.log("Processing OpenAI to get PEC data...");
    const pecData = await getPecData(apiKey, devisText);
    console.log("pecData: ", pecData); // JSON stringified format output
    // Fill form
    await fillForm(JSON.parse(pecData));
    // await fillForm(pecData);
    dispatch({ type: "STOP_LOADING" });
  };

  useEffect(() => {
    const devisData = {
      numDevis,
      dateDevis,
      expireDevis,
      numSS,
      lastName,
      firstName,
      dob,
      reseau,
      mutuelle,
      doctor,
      actes,
      statut,
    };
    console.log("devisData: ", devisData);
  }, [
    numDevis,
    dateDevis,
    expireDevis,
    numSS,
    lastName,
    firstName,
    dob,
    reseau,
    mutuelle,
    doctor,
    actes,
    statut,
  ]);

  // =================================================== //

  return (
    <>
      <div className="block">
        <Hero
          title="Nouvelle Saisie"
          subtitle={`${clinic.data.name}, ${clinic.data.city}`}
        />
      </div>
      <div className="box">
        <div className="field">
          <label className="label">Charger le Devis</label>
          <div className="file is-info has-name">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="devis"
                onChange={handleChangeFileDevis}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Fichier…</span>
              </span>
              <span className="file-name">
                {`${fileDevis === null ? "Insérez un devis" : fileDevis.name}`}
              </span>
            </label>
          </div>
        </div>
        <div className="field">
          <button
            className={`button is-primary ${loading ? "is-loading" : ""}`}
            onClick={(e) => handleClickUploadDevis(e)}
          >
            <span>Soumettre Devis</span>
          </button>
        </div>
      </div>
      <div className="block">
        <form onSubmit={(e) => submitPEC(e)} name="form">
          <div className="field is-horizontal is-justify-content-space-between">
            <div className="field">
              <label className="label">Numéro du Devis</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="text"
                  name="devis"
                  placeholder="N° Devis"
                  onChange={(e) =>
                    handleChangeFieldActes("numDevis", e.target.value)
                  }
                  value={numDevis || ""}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-file-invoice"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Date du Devis</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="date"
                  name="devis-date"
                  onChange={(e) =>
                    handleChangeFieldActes("dateDevis", e.target.value)
                  }
                  value={dateDevis || ""}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-file-invoice"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">N° Sécurité Sociale</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="text"
                name="numero-secu"
                placeholder="N° Sécurité Sociale"
                onChange={(e) =>
                  handleChangeFieldActes("numSS", e.target.value)
                }
                value={numSS}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-id-card"></i>
              </span>
              {numSS && numSS.length === 15 && (
                <span className="icon is-small is-right">
                  <i className="fas fa-check"></i>
                </span>
              )}
            </div>
            {numSS && numSS.length === 15 ? (
              <p className="help is-success">N°SS Valide</p>
            ) : (
              <p className="help is-danger">N°SS Invalide</p>
            )}
          </div>
          <div className="field is-horizontal is-justify-content-space-between">
            <div className="field">
              <label className="label">Nom du Patient</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="text"
                  name="patient-nom"
                  placeholder="NOM"
                  onChange={(e) =>
                    handleChangeFieldActes("lastName", e.target.value)
                  }
                  value={lastName || ""}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </div>
              {/* <p className="help is-success">Nom Valide</p> */}
            </div>
            <div className="field">
              <label className="label">Prénom du Patient</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="text"
                  name="patient-prenom"
                  placeholder="PRENOM"
                  onChange={(e) =>
                    handleChangeFieldActes("firstName", e.target.value)
                  }
                  value={firstName || ""}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Date de Naissance</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="date"
                  name="patient-ddn"
                  onChange={(e) =>
                    handleChangeFieldActes("dob", e.target.value)
                  }
                  value={dob || ""}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="field is-horizontal is-justify-content-space-between">
            <div className="field">
              <label className="label">Réseau Mutuelle</label>
              <div className="control has-icons-left">
                <div className="select">
                  <select
                    onChange={(e) =>
                      handleChangeFieldActes("reseau", e.target.value)
                    }
                    name="reseau"
                    value={reseau}
                  >
                    <option value="">Sélectionner...</option>
                    {listMutuelles.map((v, index) => (
                      <option key={index} value={v.reseau}>
                        {v.reseau}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="icon is-left">
                  <i className="fas fa-suitcase-medical"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Mutuelle</label>
              <div className="control has-icons-left">
                <div className="select">
                  <select
                    onChange={(e) =>
                      handleChangeFieldActes("mutuelle", e.target.value)
                    }
                    name="mutuelle"
                    value={mutuelle}
                  >
                    <option value="">Sélectionner...</option>
                    {listMutuelles.map((v, index) => (
                      <option key={index} value={v.mutuelles}>
                        {v.mutuelles}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="icon is-left">
                  <i className="fas fa-suitcase-medical"></i>
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Uploader Carte Mutuelle</label>
              <div className="file is-info has-name">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="carte-mutuelle"
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">Fichier…</span>
                  </span>
                  <span className="file-name">
                    {`carte-mutuelle_${lastName}-${firstName}.png`}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="field is-horizontal is-justify-content-space-between">
            <div className="field">
              <label className="label">Praticien</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="text"
                  name="doctor"
                  placeholder="Nom du Praticien"
                  onChange={(e) =>
                    handleChangeFieldActes("doctor", e.target.value)
                  }
                  value={doctor || ""}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-stethoscope"></i>
                </span>
              </div>
              {/* <p className="help is-success">Nom Valide</p> */}
            </div>
          </div>
          <div className="block">
            <table className="table is-striped is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Date Soins</th>
                  <th>Code CCAM</th>
                  <th>Intitulé CCAM</th>
                  <th>N° Dent</th>
                  <th>Honoraire TTC</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="is-selected">
                  <td>
                    <div className="control">
                      <input
                        className="input"
                        type="date"
                        placeholder="0"
                        onChange={(e) =>
                          handleChangeFieldAct("careDate", e.target.value)
                        }
                        value={careDate}
                        name="date soins"
                      />
                    </div>
                  </td>
                  <td>
                    <SearchBar
                      onOptionSelect={(e) =>
                        handleChangeFieldAct("codeCCAM", e)
                      }
                      value=""
                    />
                  </td>
                  <td>
                    <strong>{findDesignationByCodeCCAM(codeCCAM)}</strong>
                  </td>
                  <td>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="15, 16"
                        onChange={(e) =>
                          handleChangeFieldAct("dents", e.target.value)
                        }
                        value={dents}
                        name="numero-dent"
                      />
                      <span className="icon is-left">
                        <i className="fas fa-tooth"></i>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="0"
                        onChange={(e) =>
                          handleChangeFieldAct("honoraire", e.target.value)
                        }
                        value={honoraire}
                        name="honoraire"
                      />
                      <span className="icon is-left">
                        <i className="fas fa-euro-sign"></i>
                      </span>
                    </div>
                  </td>
                  <td>
                    <button
                      onClick={handleClickAddAct}
                      className="button is-link is-fullwidth"
                    >
                      <span className="icon">
                        <i className="fas fa-plus"></i>
                      </span>
                    </button>
                  </td>
                </tr>
                {actes.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className="control">
                          <input
                            className="input"
                            type="date"
                            placeholder="0"
                            onChange={(e) =>
                              handleUpdateFieldAct("careDate", e.target.value)
                            }
                            value={v.careDate}
                            name="date soins"
                          />
                        </div>
                      </td>
                      <td>
                        <SearchBar
                          onOptionSelect={(e) =>
                            handleUpdateFieldAct("codeCCAM", e, i)
                          }
                          value={v.codeCCAM}
                        />
                      </td>

                      <td>{findDesignationByCodeCCAM(v.codeCCAM)}</td>
                      <td>
                        <div className="control has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="15, 16"
                            onChange={(e) =>
                              handleUpdateFieldAct("dents", e.target.value, i)
                            }
                            value={v.dents}
                            name="numero-dent"
                          />
                          <span className="icon is-left">
                            <i className="fas fa-tooth"></i>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="control has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="0"
                            onChange={(e) =>
                              handleUpdateFieldAct(
                                "honoraire",
                                e.target.value,
                                i
                              )
                            }
                            value={v.honoraire}
                            name="honoraire"
                          />
                          <span className="icon is-left">
                            <i className="fas fa-euro-sign"></i>
                          </span>
                        </div>
                      </td>
                      <td>
                        <button
                          onClick={(e) => handleClickDeleteAct(e, i)}
                          className="button is-danger is-fullwidth"
                        >
                          <span className="icon">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="block">
            <button
              className={`button is-medium is-warning is-rounded ${
                loading ? "is-loading" : ""
              }`}
              disabled={actes.length === 0}
            >
              <span>Soumettre PEC</span>
            </button>
          </div>
        </form>
      </div>
      <div className="block">
        <Hero title="" subtitle="" />
      </div>
      <div>
        {actes.length > 0 && <Modal isActive={isActive} clinic={clinic} />}
      </div>
    </>
  );
};

export default NewPec;
