import { useState } from "react";
import { listCodeCCAM } from "../../utils/constants/codeCcam.constant";

const SearchBar = ({ onOptionSelect, value }) => {
  const [inputValue, setInputValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const options = listCodeCCAM.map((obj) => obj.codeCCAM);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    if (inputValue.trim() === "") {
      setFilteredOptions([]);
      return;
    }
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setFilteredOptions([]);
    onOptionSelect(option);
  };

  return (
    <div>
      <input
        className="input is-normal"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Rechercher..."
        name="code-ccam"
      />
      {filteredOptions.length > 0 && (
        <ul>
          {filteredOptions.map((option) => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
