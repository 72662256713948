const INITIAL_STATE = {
  isActive: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return { ...state, isActive: true };

    case "CLOSE_MODAL":
      return { ...state, isActive: false };

    default:
      return { ...state };
  }
};

export default modalReducer;
