import mc from "./app.module.scss";
import { Routes, Route } from "react-router-dom";
import NavBar from "../components/navbar/NavBar";
import SideBar from "../components/sidebar/SideBar";
import Login from "../components/login/Login";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../components/dashboard/Dashboard";
import OverviewClinic from "../components/overviewclinic/OverviewClinic";
import NewPec from "../components/new-pec-form/NewPec";
import logo from "../images/logo-only.jpg";
import logoSeasy from "../images/seasy-logo.jpg";

const App = () => {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((store) => store.userReducer);
  const { clinic } = useSelector((store) => store.clinicReducer);

  const handleClickClinic = (clinic) => {
    dispatch({ type: "GET_CLINIC", payload: clinic });
  };

  return (
    <div className={mc.view}>
      <NavBar isLogged={isLogged} logo={logo} logoSeasy={logoSeasy} />
      <div className={mc.wrapper}>
        <div className="columns is-gapless">
          {isLogged && (
            <div className="column is-one-fifth">
              <div className={mc.sidebar}>
                <SideBar />
              </div>
            </div>
          )}

          <div className="column">
            <div className={mc.main}>
              <section className="section">
                <div className="container">
                  <div className={mc.content}>
                    <div className="block">
                      <Routes>
                        <Route path="/" />
                        <Route index element={<Login />} />
                        {isLogged && (
                          <Route path="/dashboard">
                            <Route
                              index
                              element={
                                <Dashboard
                                  handleClickClinic={handleClickClinic}
                                />
                              }
                            />
                            <Route path={`:${clinic.id}`}>
                              <Route
                                index
                                element={<OverviewClinic clinic={clinic} />}
                              />
                              <Route
                                path="new-pec"
                                element={<NewPec clinic={clinic} />}
                              />
                            </Route>
                          </Route>
                        )}
                      </Routes>
                    </div>
                    {/* <div className="block">
                      <BreadCrumb />
                    </div>
                    <div className="block">
                      <Form />
                    </div> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
