export const listCodeCCAM = [
  {
    codeCCAM: "AHQP001",
    designation:
      "Électromyographie par électrode de surface, sans enregistrement vidéo",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP002",
    designation:
      "Électromyographie par électrode de surface, avec enregistrement vidéo",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB027",
    designation:
      "Électromyographie de 1 ou 2 muscles striés au repos et à l'effort sans stimulodétection, par électrode aiguille",
    tarifSecteur1Optam: "51.84",
    tarifHorsSecteur1HorsOptam: "51.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB025",
    designation:
      "Électromyographie de 1 ou 2 muscles striés au repos et à l'effort avec stimulodétection, par électrode aiguille",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB024",
    designation:
      "Électromyographie de 3 à 6 muscles striés au repos et à l'effort sans stimulodétection, par électrode aiguille",
    tarifSecteur1Optam: "64.8",
    tarifHorsSecteur1HorsOptam: "59.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB026",
    designation:
      "Électromyographie de 3 à 6 muscles striés au repos et à l'effort avec stimulodétection, par électrode aiguille",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB013",
    designation:
      "Électromyographie de 7 muscles striés ou plus au repos et à l'effort, par électrode aiguille",
    tarifSecteur1Optam: "73.44",
    tarifHorsSecteur1HorsOptam: "64.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB032",
    designation:
      "Électromyographie de 3 à 6 muscles striés au repos et à l'effort par électrode aiguille, avec mesure des vitesses de conduction motrice et de l'amplitude des réponses musculaires de 2 à 4 nerfs sans étude de la conduction proximale par électrode de surface, et mesure des vitesses de la conduction sensitive et de l'amplitude du potentiel sensitif de 2 à 4 nerfs",
    tarifSecteur1Optam: "122.69",
    tarifHorsSecteur1HorsOptam: "121.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB033",
    designation:
      "Électromyographie de 7 muscles striés ou plus au repos et à l'effort par électrode aiguille, avec mesure des vitesses de conduction motrice et de l'amplitude des réponses musculaires de 5 nerfs ou plus avec étude de la conduction proximale par électrode de surface, et mesure des vitesses de la conduction sensitive et de l'amplitude du potentiel sensitif de 5 nerfs ou plus",
    tarifSecteur1Optam: "144.29",
    tarifHorsSecteur1HorsOptam: "134.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB015",
    designation: "Électromyographie de fibre unique, par électrode aiguille",
    tarifSecteur1Optam: "139.97",
    tarifHorsSecteur1HorsOptam: "102.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB006",
    designation: "Macroélectromyographie, par électrode aiguille",
    tarifSecteur1Optam: "139.97",
    tarifHorsSecteur1HorsOptam: "102.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB001",
    designation: "Électromyographie, au lit du malade",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "61.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP003",
    designation:
      "Mesure des vitesses de conduction motrice et de l'amplitude des réponses musculaires de 2 à 4 nerfs, sans étude de la conduction proximale",
    tarifSecteur1Optam: "55.3",
    tarifHorsSecteur1HorsOptam: "46.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP008",
    designation:
      "Mesure des vitesses de conduction motrice et de l'amplitude des réponses musculaires de 2 à 4 nerfs, avec étude de la conduction proximale sur au moins 2 nerfs",
    tarifSecteur1Optam: "65.66",
    tarifHorsSecteur1HorsOptam: "52.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP010",
    designation:
      "Mesure des vitesses de conduction motrice et de l'amplitude des réponses musculaires de 5 nerfs ou plus, sans étude de la conduction proximale",
    tarifSecteur1Optam: "67.39",
    tarifHorsSecteur1HorsOptam: "53.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP009",
    designation:
      "Mesure des vitesses de conduction motrice et de l'amplitude des réponses musculaires de 5 nerfs ou plus, avec étude de la conduction proximale sur au moins 4 nerfs",
    tarifSecteur1Optam: "76.9",
    tarifHorsSecteur1HorsOptam: "59.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP011",
    designation:
      "Mesure des vitesses de la conduction sensitive et de l'amplitude du potentiel sensitif de 2 à 4 nerfs",
    tarifSecteur1Optam: "60.48",
    tarifHorsSecteur1HorsOptam: "49.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP012",
    designation:
      "Mesure des vitesses de la conduction sensitive et de l'amplitude du potentiel sensitif de 5 nerfs ou plus",
    tarifSecteur1Optam: "71.71",
    tarifHorsSecteur1HorsOptam: "56.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP013",
    designation:
      "Mesure des vitesses de conduction sensitive du nerf dorsal du pénis",
    tarifSecteur1Optam: "42.34",
    tarifHorsSecteur1HorsOptam: "39.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADQP014",
    designation:
      "Mesure du réflexe trigéminopalpébral [réflexe de clignement] et/ou du réflexe massétérin",
    tarifSecteur1Optam: "50.11",
    tarifHorsSecteur1HorsOptam: "43.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB019",
    designation: "Mesure des latences des réflexes sacraux",
    tarifSecteur1Optam: "68.26",
    tarifHorsSecteur1HorsOptam: "54.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQD003",
    designation:
      "Mesure des latences distales du nerf pudendal [nerf honteux], par stimulation intrarectale",
    tarifSecteur1Optam: "80.35",
    tarifHorsSecteur1HorsOptam: "61.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHRP001",
    designation:
      "Épreuve de stimulation répétitive d'au moins 2 nerfs pour recherche de bloc de la jonction neuromusculaire",
    tarifSecteur1Optam: "90.72",
    tarifHorsSecteur1HorsOptam: "67.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP900",
    designation: "Mesure du réflexe nociceptif R III",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP016",
    designation: "Mesure des réflexes H et/ou T",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP007",
    designation:
      "Électroencéphalographie sur 8 dérivations ou plus avec enregistrement d'une durée minimale de 20 minutes sans numérisation et sans enregistrement vidéo",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP129",
    designation:
      "Électroencéphalographie sur 8 dérivations ou plus avec enregistrement d'une durée minimale de 30 minutes, avec numérisation et enregistrement vidéo, chez un patient de moins de 6 ans",
    tarifSecteur1Optam: "152.93",
    tarifHorsSecteur1HorsOptam: "152.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP350",
    designation:
      "Électroencéphalographie sur 14 dérivations ou plus avec enregistrement d'une durée minimale de 20 minutes, avec numérisation chez un patient de 6 ans ou plus",
    tarifSecteur1Optam: "130.46",
    tarifHorsSecteur1HorsOptam: "130.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP011",
    designation:
      "Électroencéphalographie sur 8 dérivations ou plus avec enregistrement d'une durée minimale de 20 minutes, au lit du malade ",
    tarifSecteur1Optam: "84.67",
    tarifHorsSecteur1HorsOptam: "73.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP006",
    designation:
      "Électroencéphalographie de longue durée de 1 à 4 heures sur 8 dérivations ou plus, avec enregistrement vidéo",
    tarifSecteur1Optam: "139.97",
    tarifHorsSecteur1HorsOptam: "105.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP010",
    designation:
      "Électroencéphalographie de longue durée de plus de 4 heures sur 8 dérivations ou plus, avec enregistrement vidéo",
    tarifSecteur1Optam: "233.28",
    tarifHorsSecteur1HorsOptam: "159.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP002",
    designation:
      "Électroencéphalographie continue ambulatoire sur 8 dérivations ou plus, pendant au moins 24 heures [Holter EEG]",
    tarifSecteur1Optam: "209.95",
    tarifHorsSecteur1HorsOptam: "145.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP009",
    designation:
      "Électroencéphalographie avec quantification, sur 32 dérivations ou plus",
    tarifSecteur1Optam: "93.31",
    tarifHorsSecteur1HorsOptam: "78.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP900",
    designation:
      "Électroencéphalographie avec modélisation de sources, sur 32 dérivations ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AALA002",
    designation:
      "Implantation d’électrode subdurale pour enregistrement électrocorticographique, par craniotomie",
    tarifSecteur1Optam: "272.83",
    tarifHorsSecteur1HorsOptam: "272.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AALB002",
    designation:
      "Implantation d’électrode intracérébrale pour enregistrement électroencéphalographique, par voie stéréotaxique",
    tarifSecteur1Optam: "627",
    tarifHorsSecteur1HorsOptam: "627",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP013",
    designation:
      "Surveillance électrocorticographique continue avec enregistrement vidéo, par 24 heures",
    tarifSecteur1Optam: "345.6",
    tarifHorsSecteur1HorsOptam: "256.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP003",
    designation:
      "Surveillance électroencéphalographique continue sans enregistrement vidéo, par 24 heures",
    tarifSecteur1Optam: "80.35",
    tarifHorsSecteur1HorsOptam: "70.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP012",
    designation:
      "Surveillance électroencéphalographique continue avec enregistrement vidéo, par 24 heures",
    tarifSecteur1Optam: "345.6",
    tarifHorsSecteur1HorsOptam: "256.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP009",
    designation:
      "Évaluation diurne de la vigilance ou de l'endormissement par épreuves itératives",
    tarifSecteur1Optam: "120.96",
    tarifHorsSecteur1HorsOptam: "106.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP010",
    designation: "Polysomnographie de 4 à 8 heures, sans enregistrement vidéo",
    tarifSecteur1Optam: "136.32",
    tarifHorsSecteur1HorsOptam: "136.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP011",
    designation: "Polysomnographie de 4 à 8 heures, avec enregistrement vidéo",
    tarifSecteur1Optam: "136.32",
    tarifHorsSecteur1HorsOptam: "136.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP012",
    designation: "Polysomnographie de 8 à 12 heures, sans enregistrement vidéo",
    tarifSecteur1Optam: "214.27",
    tarifHorsSecteur1HorsOptam: "181.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP013",
    designation: "Polysomnographie de 8 à 12 heures, avec enregistrement vidéo",
    tarifSecteur1Optam: "214.27",
    tarifHorsSecteur1HorsOptam: "181.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP014",
    designation:
      "Polysomnographie de 12 à 24 heures, sans enregistrement vidéo",
    tarifSecteur1Optam: "246.24",
    tarifHorsSecteur1HorsOptam: "200.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AMQP015",
    designation:
      "Polysomnographie de 12 à 24 heures, avec enregistrement vidéo",
    tarifSecteur1Optam: "246.24",
    tarifHorsSecteur1HorsOptam: "200.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJQP001",
    designation:
      "Mesure des réponses cutanées sympathiques par électrode de surface",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ABLB001",
    designation:
      "Pose d'un cathéter ventriculaire cérébral par voie transcrânienne, avec mesure instantanée de la pression intracrânienne et tests dynamiques",
    tarifSecteur1Optam: "110.6",
    tarifHorsSecteur1HorsOptam: "99.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABLB002",
    designation:
      "Pose d'un capteur ventriculaire de pression intracrânienne, par voie transcrânienne",
    tarifSecteur1Optam: "116.99",
    tarifHorsSecteur1HorsOptam: "102.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABLB003",
    designation:
      "Pose d'un capteur extraventriculaire de pression intracrânienne, par voie transcrânienne",
    tarifSecteur1Optam: "96.42",
    tarifHorsSecteur1HorsOptam: "91.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFLB004",
    designation:
      "Pose d'un cathéter intrathécal spinal par voie lombale transcutanée, avec mesure instantanée de la pression du liquide cérébrospinal et tests dynamiques",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABGA001",
    designation: "Ablation d'un capteur de pression intracrânienne",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABQP001",
    designation:
      "Surveillance continue de la pression intracrânienne, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQM002",
    designation: "Échographie transfontanellaire de l'encéphale",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "AEQM001",
    designation: "Échographie transcutanée de la moelle épinière",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "AEQH002",
    designation: "Myélographie cervicale",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AEQH001",
    designation: "Myélographie dorsale et/ou lombale",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AFQH003",
    designation:
      "Myélographie avec scanographie de la colonne vertébrale [Myéloscanner]",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AFQH002",
    designation: "Saccoradiculographie",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AFQH001",
    designation:
      "Saccoradiculographie avec scanographie de la colonne vertébrale",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQK001",
    designation:
      "Scanographie du crâne et de son contenu, sans injection de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQH003",
    designation:
      "Scanographie du crâne et de son contenu, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQH001",
    designation:
      "Scanographie du crâne et de son contenu, avec injection intrathécale de produit de contraste [Cysternoscanner]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQK003",
    designation:
      "Scanographie du crâne et de son contenu et/ou du massif facial pour repérage stéréotaxique",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQH002",
    designation:
      "Scanographie du crâne, de son contenu et du thorax, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQH004",
    designation:
      "Scanographie du crâne, de son contenu et du tronc, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQN001",
    designation:
      "Remnographie [IRM] du crâne et de son contenu, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQJ002",
    designation:
      "Remnographie [IRM] du crâne et de son contenu, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQN003",
    designation:
      "Remnographie [IRM] du crâne et de son contenu pour procédure stéréotaxique",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQN002",
    designation:
      "Remnographie [IRM] du crâne et de son contenu et/ou du massif facial pour planification dosimétrique",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQN004",
    designation:
      "Remnographie [IRM] du crâne et de son contenu, avec étude de la viabilité du parenchyme cérébral par imagerie de diffusion et de perfusion",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQJ001",
    designation:
      "Remnographie [IRM] du crâne et de son contenu avec étude de la viabilité du parenchyme cérébral par imagerie de diffusion et de perfusion, avec remnographie des vaisseaux [angio-IRM] cervicocéphaliques",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AAQN004",
    designation:
      "Remnographie [IRM] fonctionnelle du cerveau pour étude des fonctions motrices",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AAQN900",
    designation:
      "Remnographie [IRM] fonctionnelle du cerveau pour étude des fonctions visuelles",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AAQN901",
    designation:
      "Remnographie [IRM] fonctionnelle du cerveau pour étude des fonctions phasiques",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AAQN902",
    designation:
      "Spectroscopie par résonance magnétique cérébrale protonique [Chemical shift imaging] [CSI]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AAQN002",
    designation:
      "Spectroscopie par résonance magnétique cérébrale protonique localisée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "AAQN903",
    designation:
      "Spectroscopie par résonance magnétique cérébrale du phosphore",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL007",
    designation:
      "Tomoscintigraphie de perfusion cérébrale sans test d'activation",
    tarifSecteur1Optam: "274.25",
    tarifHorsSecteur1HorsOptam: "274.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL005",
    designation:
      "Tomoscintigraphie de perfusion cérébrale avec test d'activation et quantification complexe",
    tarifSecteur1Optam: "327.3",
    tarifHorsSecteur1HorsOptam: "327.3",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL008",
    designation:
      "Tomoscintigraphie de perfusion cérébrale après épreuve pharmacodynamique",
    tarifSecteur1Optam: "274.25",
    tarifHorsSecteur1HorsOptam: "274.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL004",
    designation: "Tomoscintigraphie de perfusion cérébrale en période critique",
    tarifSecteur1Optam: "274.25",
    tarifHorsSecteur1HorsOptam: "274.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL001",
    designation:
      "Tomoscintigraphie cérébrale à l'aide de marqueur de la neurotransmission et/ou du métabolisme",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL003",
    designation:
      "Tomoscintigraphie cérébrale pour diagnostic et bilan de tumeur cérébrale",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ACQL002",
    designation:
      "Tomoscintigraphie cérébrale par émission de positons, avec tépographe [caméra TEP] dédié",
    tarifSecteur1Optam: "89.54",
    tarifHorsSecteur1HorsOptam: "89.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ABQL002",
    designation: "Cisternographie radio-isotopique",
    tarifSecteur1Optam: "279.63",
    tarifHorsSecteur1HorsOptam: "279.63",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ABQL003",
    designation: "Recherche radio-isotopique d'une brèche ostéoméningée",
    tarifSecteur1Optam: "138.15",
    tarifHorsSecteur1HorsOptam: "138.15",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ABML001",
    designation:
      "Vérification radio-isotopique d'une dérivation du liquide cérébrospinal",
    tarifSecteur1Optam: "138.15",
    tarifHorsSecteur1HorsOptam: "138.15",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BLQP001",
    designation:
      "Enregistrement des potentiels évoqués visuels avec stimulation par flash lumineux",
    tarifSecteur1Optam: "53.76",
    tarifHorsSecteur1HorsOptam: "53.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP009",
    designation:
      "Enregistrement des potentiels évoqués visuels par inversion du stimulus de contraste",
    tarifSecteur1Optam: "64.8",
    tarifHorsSecteur1HorsOptam: "60.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP017",
    designation:
      "Enregistrement des potentiels évoqués auditifs précoces pour étude des temps de conduction et mesure des amplitudes",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP006",
    designation:
      "Enregistrement des potentiels évoqués auditifs précoces pour recherche de seuil, étude des temps de conduction et mesure des amplitudes, sans anesthésie générale",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP014",
    designation:
      "Enregistrement des potentiels évoqués auditifs précoces pour recherche de seuil, étude des temps de conduction et mesure des amplitudes, sous anesthésie générale",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP008",
    designation:
      "Enregistrement des potentiels évoqués auditifs de moyennes et de longues latences avec mesure des amplitudes et latences des réponses",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CEQP008",
    designation:
      "Enregistrement des potentiels évoqués vestibulaires d’origine sacculaire [potentiels évoqués otolithiques] [potentiels évoqués sacculocolliques] par électromyographie",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANQP004",
    designation:
      "Enregistrement des potentiels évoqués somesthésiques cérébraux par stimulation bilatérale d'un ou de 2 nerfs des membres supérieurs ou inférieurs",
    tarifSecteur1Optam: "80.35",
    tarifHorsSecteur1HorsOptam: "70.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANQP006",
    designation:
      "Enregistrement des potentiels évoqués somesthésiques cérébraux par stimulation bilatérale de 3 nerfs ou plus des membres supérieurs et inférieurs",
    tarifSecteur1Optam: "132.19",
    tarifHorsSecteur1HorsOptam: "100.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANQP002",
    designation:
      "Enregistrement des potentiels évoqués somesthésiques cérébraux par stimulation des nerfs pudendaux [nerfs honteux]",
    tarifSecteur1Optam: "65.66",
    tarifHorsSecteur1HorsOptam: "62.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP004",
    designation:
      "Enregistrement des potentiels moteurs par stimulation corticale et/ou spinale",
    tarifSecteur1Optam: "73.44",
    tarifHorsSecteur1HorsOptam: "66.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ALQP001",
    designation:
      "Enregistrement des potentiels évoqués cognitifs événementiels",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANQP005",
    designation:
      "Enregistrement des potentiels évoqués nociceptifs avec mesure des amplitudes et des latences des réponses corticales, par stimulation au laser",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AZQP002",
    designation: "Enregistrement de potentiels évoqués, au lit du malade",
    tarifSecteur1Optam: "95.9",
    tarifHorsSecteur1HorsOptam: "78.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ACQC001",
    designation: "Exploration intracrânienne, par vidéochirurgie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABHB002",
    designation:
      "Ponction de liquide cérébrospinal ou de collection intracrânienne, par voie transfontanellaire",
    tarifSecteur1Optam: "39",
    tarifHorsSecteur1HorsOptam: "34.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ABHB001",
    designation:
      "Ponction de liquide cérébrospinal ventriculaire, par voie transcrânienne",
    tarifSecteur1Optam: "92.88",
    tarifHorsSecteur1HorsOptam: "86.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFHB001",
    designation:
      "Ponction de liquide cérébrospinal, par voie infraoccipitale ou latérocervicale transcutanée [Ponction sousoccipitale]",
    tarifSecteur1Optam: "63.94",
    tarifHorsSecteur1HorsOptam: "43.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFHB002",
    designation:
      "Ponction de liquide cérébrospinal, par voie lombale transcutanée [Ponction lombaire]",
    tarifSecteur1Optam: "34.56",
    tarifHorsSecteur1HorsOptam: "26.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ACHH001",
    designation:
      "Ponction ou cytoponction de lésion intracrânienne, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ACHJ001",
    designation:
      "Ponction ou cytoponction de lésion intracrânienne, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ACHB001",
    designation:
      "Biopsie de lésion intracrânienne, par voie transcrânienne stéréotaxique",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ACHA002",
    designation: "Biopsie de lésion intracrânienne, par craniotomie",
    tarifSecteur1Optam: "231.13",
    tarifHorsSecteur1HorsOptam: "204.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACHA003",
    designation:
      "Biopsie de lésion de la base du crâne, par endoscopie buccale ou nasale",
    tarifSecteur1Optam: "220.73",
    tarifHorsSecteur1HorsOptam: "220.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACHA001",
    designation:
      "Biopsie de lésion de la base du crâne, par abord transoral ou nasosphénoïdal",
    tarifSecteur1Optam: "369.39",
    tarifHorsSecteur1HorsOptam: "284.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHHB001",
    designation: "Biopsie de nerf périphérique, par voie transcutanée",
    tarifSecteur1Optam: "44.61",
    tarifHorsSecteur1HorsOptam: "44.61",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHHA001",
    designation: "Biopsie de nerf périphérique, par abord direct",
    tarifSecteur1Optam: "51.76",
    tarifHorsSecteur1HorsOptam: "47.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHHA002",
    designation: "Biopsie neuromusculaire, par abord direct",
    tarifSecteur1Optam: "65.94",
    tarifHorsSecteur1HorsOptam: "55.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AGLB001",
    designation:
      "Injection péridurale [épidurale] d'agent pharmacologique à visée antalgique, avec évaluation diagnostique et pronostique",
    tarifSecteur1Optam: "65.73",
    tarifHorsSecteur1HorsOptam: "62.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANLB004",
    designation:
      "Injection intrathécale d'agent pharmacologique à visée antalgique, avec évaluation diagnostique et pronostique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADLH001",
    designation:
      "Infiltration anesthésique du ganglion trigéminal avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "44.87",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADLH002",
    designation:
      "Infiltration anesthésique d'une branche de division du nerf trijumeau avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "13.9",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADLH003",
    designation:
      "Infiltration anesthésique du nerf glossopharyngien avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "59.41",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH001",
    designation:
      "Infiltration anesthésique de nerf spinal à son émergence rachidienne avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "42.34",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH011",
    designation:
      "Infiltration anesthésique de branche dorsale de nerf spinal avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "32.86",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH008",
    designation:
      "Infiltration anesthésique unilatérale ou bilatérale du nerf grand occipital [d'Arnold] avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB008",
    designation:
      "Infiltration anesthésique du plexus brachial, avec évaluation diagnostique et pronostique",
    tarifSecteur1Optam: "54.98",
    tarifHorsSecteur1HorsOptam: "39.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH016",
    designation:
      "Infiltration anesthésique du plexus lombosacral avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "46.77",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH005",
    designation:
      "Infiltration anesthésique du plexus lombosacral avec évaluation diagnostique et pronostique, avec guidage scanographique",
    tarifSecteur1Optam: "33.12",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB007",
    designation:
      "Infiltration anesthésique de tronc nerveux superficiel avec évaluation diagnostique et pronostique, sans stimulodétection électrique",
    tarifSecteur1Optam: "25.91",
    tarifHorsSecteur1HorsOptam: "23.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB017",
    designation:
      "Infiltration anesthésique de tronc nerveux superficiel avec évaluation diagnostique et pronostique, avec stimulodétection électrique",
    tarifSecteur1Optam: "32.86",
    tarifHorsSecteur1HorsOptam: "31.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB005",
    designation:
      "Infiltration anesthésique de tronc nerveux profond avec évaluation diagnostique et pronostique, sans guidage",
    tarifSecteur1Optam: "60.67",
    tarifHorsSecteur1HorsOptam: "43.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH009",
    designation:
      "Infiltration anesthésique de tronc nerveux profond avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "41.08",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH020",
    designation:
      "Infiltration anesthésique de tronc nerveux profond avec évaluation diagnostique et pronostique, avec guidage scanographique",
    tarifSecteur1Optam: "26.88",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH004",
    designation:
      "Infiltration anesthésique du ganglion ptérygopalatin avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH012",
    designation:
      "Infiltration anesthésique du ganglion ptérygopalatin avec évaluation diagnostique et pronostique, avec guidage scanographique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLB002",
    designation:
      "Infiltration anesthésique du ganglion cervicothoracique [stellaire], avec évaluation diagnostique et pronostique",
    tarifSecteur1Optam: "54.98",
    tarifHorsSecteur1HorsOptam: "39.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH014",
    designation:
      "Infiltration anesthésique de la chaîne sympathique thoracique avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "68.26",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH018",
    designation:
      "Infiltration anesthésique de la chaîne sympathique thoracique avec évaluation diagnostique et pronostique, avec guidage scanographique",
    tarifSecteur1Optam: "49.44",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLJ001",
    designation:
      "Infiltration anesthésique des nerfs splanchniques avec évaluation diagnostique et pronostique, avec guidage échographique",
    tarifSecteur1Optam: "61.94",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH006",
    designation:
      "Infiltration anesthésique des nerfs splanchniques avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLJ003",
    designation:
      "Infiltration anesthésique du plexus cœliaque avec évaluation diagnostique et pronostique, avec guidage échographique",
    tarifSecteur1Optam: "61.94",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH007",
    designation:
      "Infiltration anesthésique du plexus cœliaque avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "76.47",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH015",
    designation:
      "Infiltration anesthésique de la chaîne sympathique lombale avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "70.15",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH001",
    designation:
      "Infiltration anesthésique de la chaîne sympathique lombale avec évaluation diagnostique et pronostique, avec guidage scanographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH002",
    designation:
      "Infiltration anesthésique du plexus hypogastrique ou sacral avec évaluation diagnostique et pronostique, avec guidage radiologique",
    tarifSecteur1Optam: "50.56",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH010",
    designation:
      "Infiltration anesthésique du plexus hypogastrique ou sacral avec évaluation diagnostique et pronostique, avec guidage scanographique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ALQP006",
    designation: "Test d'évaluation d'un déficit cognitif",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ALQP002",
    designation: "Test d'évaluation de l'efficience intellectuelle de l'enfant",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ALQP005",
    designation: "Test d'évaluation de l'efficience intellectuelle de l'adulte",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ALQP003",
    designation: "Test d'évaluation d'une dépression",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ALQP004",
    designation:
      "Test d'évaluation des aspects psychopathologiques de la personnalité",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZFQP002",
    designation:
      "Test d'évaluation et de catégorisation des déficiences, incapacités et désavantages sociaux",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZFQP003",
    designation: "Test d'évaluation de la qualité de vie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AZQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de tumeur de l'encéphale, de la moelle épinière, ou des méninges",
    tarifSecteur1Optam: "92.09",
    tarifHorsSecteur1HorsOptam: "92.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AZQP001",
    designation: "Actimétrie de longue durée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AANB001",
    designation:
      "Destruction d'une cible intracérébrale à visée fonctionnelle, par voie stéréotaxique",
    tarifSecteur1Optam: "695.53",
    tarifHorsSecteur1HorsOptam: "695.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAPA900",
    designation: "Déconnexion d'une zone épileptogène, par craniotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA007",
    designation: "Excision d'une zone épileptogène, par craniotomie",
    tarifSecteur1Optam: "601.23",
    tarifHorsSecteur1HorsOptam: "524.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA004",
    designation: "Hémisphérectomie fonctionnelle, par craniotomie",
    tarifSecteur1Optam: "850.16",
    tarifHorsSecteur1HorsOptam: "850.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA005",
    designation:
      "Exérèse de lésion du corps calleux, du fornix hypothalamique ou du septum pellucide, par craniotomie",
    tarifSecteur1Optam: "676.39",
    tarifHorsSecteur1HorsOptam: "655.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA002",
    designation:
      "Exérèse de tumeur intraparenchymateuse du cerveau, par craniotomie",
    tarifSecteur1Optam: "603.36",
    tarifHorsSecteur1HorsOptam: "525.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA006",
    designation:
      "Résection de parenchyme cérébral pour infarctus expansif, par craniotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJH003",
    designation:
      "Évacuation de collection intracérébrale, par voie transcrânienne avec guidage radiologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJH001",
    designation:
      "Évacuation de collection intracérébrale, par voie transcrânienne avec guidage scanographique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJA001",
    designation: "Évacuation de collection intracérébrale, par craniotomie",
    tarifSecteur1Optam: "523.24",
    tarifHorsSecteur1HorsOptam: "408.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJA005",
    designation:
      "Évacuation de collection intracrânienne postopératoire, par reprise de la craniotomie précédente",
    tarifSecteur1Optam: "250.99",
    tarifHorsSecteur1HorsOptam: "215.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJH004",
    designation:
      "Évacuation d'hématome intracérébral non traumatique, par voie transcrânienne avec guidage radiologique",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJH002",
    designation:
      "Évacuation d'hématome intracérébral non traumatique, par voie transcrânienne avec guidage scanographique",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJA004",
    designation:
      "Évacuation d'hématome intracérébral non traumatique, par craniotomie",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "269.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJA002",
    designation:
      "Évacuation d'hématome intracérébral traumatique [contusion], par craniotomie",
    tarifSecteur1Optam: "305.58",
    tarifHorsSecteur1HorsOptam: "282.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA001",
    designation:
      "Exérèse de tumeur intraparenchymateuse du cervelet, par craniotomie",
    tarifSecteur1Optam: "647.32",
    tarifHorsSecteur1HorsOptam: "594.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA008",
    designation:
      "Résection de parenchyme cérébelleux pour infarctus expansif, par craniotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAFA003",
    designation: "Exérèse de lésion du tronc cérébral, par craniotomie",
    tarifSecteur1Optam: "947.93",
    tarifHorsSecteur1HorsOptam: "813.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJA003",
    designation: "Évacuation d'hématome intracérébelleux, par craniotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAJA006",
    designation: "Parage de plaie craniocérébrale",
    tarifSecteur1Optam: "433.91",
    tarifHorsSecteur1HorsOptam: "383.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJB002",
    designation:
      "Évacuation de liquide cérébrospinal ou de collection intracrânienne, par voie transfontanellaire",
    tarifSecteur1Optam: "34.71",
    tarifHorsSecteur1HorsOptam: "34.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ABJB001",
    designation:
      "Évacuation de liquide cérébrospinal ventriculaire avec injection à visée thérapeutique, par voie transcrânienne",
    tarifSecteur1Optam: "102.1",
    tarifHorsSecteur1HorsOptam: "91.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABLA001",
    designation:
      "Pose d'un cathéter intraventriculaire ou intrakystique cérébral par voie transcrânienne, avec pose d'un système diffuseur ou d'une pompe implantable souscutané",
    tarifSecteur1Optam: "156.69",
    tarifHorsSecteur1HorsOptam: "125.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABGA003",
    designation:
      "Ablation d'un système diffuseur implanté et du cathéter intraventriculaire ou intrakystique cérébral",
    tarifSecteur1Optam: "65.23",
    tarifHorsSecteur1HorsOptam: "55.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABLB006",
    designation:
      "Séance d'administration intraventriculaire ou intrakystique cérébrale d'agent pharmacologique anticancéreux, par un dispositif implanté",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ABCB001",
    designation:
      "Dérivation externe du liquide cérébrospinal ventriculaire ou subdural, par voie transcrânienne",
    tarifSecteur1Optam: "145.35",
    tarifHorsSecteur1HorsOptam: "119.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABCA003",
    designation:
      "Dérivation péritonéale d'une collection subdurale du liquide cérébrospinal crânien, par abord direct",
    tarifSecteur1Optam: "261.62",
    tarifHorsSecteur1HorsOptam: "257.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABCA002",
    designation:
      "Dérivation péritonéale ou atriale du liquide cérébrospinal ventriculaire, par abord direct",
    tarifSecteur1Optam: "261.62",
    tarifHorsSecteur1HorsOptam: "257.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABCA004",
    designation:
      "Dérivation péritonéale ou atriale de kyste intracrânien, par abord direct",
    tarifSecteur1Optam: "268",
    tarifHorsSecteur1HorsOptam: "260.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABCC001",
    designation:
      "Ventriculoventriculostomie, ventriculocisternostomie, kystocisternostomie ou kystoventriculostomie, par vidéochirurgie intracrânienne",
    tarifSecteur1Optam: "366.55",
    tarifHorsSecteur1HorsOptam: "344.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABCA001",
    designation:
      "Ventriculoventriculostomie, ventriculocisternostomie, kystocisternostomie ou kystoventriculostomie, par craniotomie",
    tarifSecteur1Optam: "421.86",
    tarifHorsSecteur1HorsOptam: "376.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABMA003",
    designation:
      "Révision ou changement d'élément d'une dérivation interne du liquide cérébrospinal, par craniotomie",
    tarifSecteur1Optam: "262.33",
    tarifHorsSecteur1HorsOptam: "239.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA012",
    designation:
      "Fermeture d'une brèche ostéodurale ou d'une méningocèle de l'étage antérieur de la base du crâne, par endoscopie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA010",
    designation:
      "Fermeture d'une brèche ostéodurale ou d'une méningocèle de l'étage antérieur de la base du crâne, par abord paralatéronasal",
    tarifSecteur1Optam: "518.55",
    tarifHorsSecteur1HorsOptam: "518.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA011",
    designation:
      "Fermeture d'une brèche ostéodurale ou d'une méningocèle de l'étage antérieur de la base du crâne, par abord nasosphénoïdal",
    tarifSecteur1Optam: "455.84",
    tarifHorsSecteur1HorsOptam: "439.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA005",
    designation:
      "Fermeture d'une brèche ostéodurale ou d'une méningocèle de l'étage antérieur de la base du crâne, par abord orbitaire",
    tarifSecteur1Optam: "470.32",
    tarifHorsSecteur1HorsOptam: "470.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA007",
    designation:
      "Fermeture d'une brèche ostéodurale ou d'une méningocèle de l'étage antérieur de la base du crâne, par craniotomie",
    tarifSecteur1Optam: "517.57",
    tarifHorsSecteur1HorsOptam: "475.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA003",
    designation:
      "Fermeture d'une brèche ostéodurale du sinus frontal, par abord coronal",
    tarifSecteur1Optam: "501.58",
    tarifHorsSecteur1HorsOptam: "501.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA001",
    designation:
      "Fermeture d'une brèche ostéodurale de l'étage moyen de la base du crâne, par craniotomie",
    tarifSecteur1Optam: "419.02",
    tarifHorsSecteur1HorsOptam: "418.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA002",
    designation:
      "Fermeture d'une brèche ostéodurale de l'étage moyen de la base du crâne, par abord translabyrinthique",
    tarifSecteur1Optam: "589.82",
    tarifHorsSecteur1HorsOptam: "517.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA006",
    designation:
      "Fermeture d'une brèche ostéodurale de l'étage moyen de la base du crâne, par abord suprapétreux",
    tarifSecteur1Optam: "589.82",
    tarifHorsSecteur1HorsOptam: "517.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA004",
    designation:
      "Fermeture d'une brèche ostéodurale de l'étage moyen de la base du crâne, par abord mastoïdoattical",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA008",
    designation:
      "Fermeture d'une fistule postopératoire de liquide cérébrospinal de la base du crâne",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABSA009",
    designation:
      "Fermeture d'une fistule de liquide cérébrospinal ou d'une méningocèle postopératoire de la voûte crânienne, par craniotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA007",
    designation:
      "Exérèse d'une fistule dermique avec prolongement intradural occipital",
    tarifSecteur1Optam: "244.87",
    tarifHorsSecteur1HorsOptam: "244.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA003",
    designation:
      "Exérèse et/ou fermeture de méningoencéphalocèle de la voûte crânienne",
    tarifSecteur1Optam: "282.29",
    tarifHorsSecteur1HorsOptam: "282.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA001",
    designation:
      "Exérèse et/ou fermeture de méningoencéphalocèle de la base du crâne, sans rapprochement orbitaire",
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "445.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA004",
    designation:
      "Exérèse et/ou fermeture de méningoencéphalocèle de la base du crâne, avec rapprochement orbitaire",
    tarifSecteur1Optam: "891.92",
    tarifHorsSecteur1HorsOptam: "666.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA005",
    designation:
      "Évacuation d'un hématome extradural supratentoriel, par craniotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA004",
    designation:
      "Évacuation d'un hématome extradural infratentoriel, par craniotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA007",
    designation:
      "Évacuation d'un hématome extradural plurifocal supratentoriel et/ou infratentoriel, par craniotomie",
    tarifSecteur1Optam: "324.72",
    tarifHorsSecteur1HorsOptam: "320.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA002",
    designation: "Évacuation d'un hématome subdural aigu, par craniotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA003",
    designation:
      "Évacuation d'un hématome subdural chronique unilatéral, par craniotomie",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "182.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA006",
    designation:
      "Évacuation d'un hématome subdural chronique bilatéral, par craniotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA008",
    designation:
      "Évacuation d'un empyème intracrânien extracérébral, par craniotomie",
    tarifSecteur1Optam: "254.53",
    tarifHorsSecteur1HorsOptam: "235.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJC900",
    designation:
      "Évacuation d'une hémorragie intraventriculaire cérébrale non traumatique, par vidéochirurgie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABJA001",
    designation:
      "Évacuation d'une hémorragie intraventriculaire cérébrale, par craniotomie",
    tarifSecteur1Optam: "259.96",
    tarifHorsSecteur1HorsOptam: "259.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA001",
    designation:
      "Exérèse de tumeur de l'étage antérieur de la base du crâne, par craniotomie frontale unilatérale",
    tarifSecteur1Optam: "699.07",
    tarifHorsSecteur1HorsOptam: "668.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA015",
    designation:
      "Exérèse de tumeur de l'étage antérieur de la base du crâne, par craniotomie frontale bilatérale",
    tarifSecteur1Optam: "787.7",
    tarifHorsSecteur1HorsOptam: "720.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA026",
    designation:
      "Exérèse de tumeur de l'étage antérieur de la base du crâne, par craniotomie frontale bilatérale et abord ethmoïdal",
    tarifSecteur1Optam: "991.89",
    tarifHorsSecteur1HorsOptam: "838.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA022",
    designation:
      "Exérèse de tumeur de la région optochiasmatique et/ou hypothalamique, par craniotomie",
    tarifSecteur1Optam: "1046.48",
    tarifHorsSecteur1HorsOptam: "870.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA011",
    designation:
      "Exérèse de tumeur de l'étage moyen de la base du crâne, par craniotomie",
    tarifSecteur1Optam: "706.16",
    tarifHorsSecteur1HorsOptam: "672.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA013",
    designation:
      "Exérèse de tumeur du tiers interne de l'étage moyen de la base du crâne intéressant l'angle sphénoorbitaire, par craniotomie",
    tarifSecteur1Optam: "1177.65",
    tarifHorsSecteur1HorsOptam: "946.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA006",
    designation:
      "Exérèse de tumeur de la pointe du rocher sans déroutement du nerf facial, par abord transpétreux",
    tarifSecteur1Optam: "741.51",
    tarifHorsSecteur1HorsOptam: "605.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA029",
    designation:
      "Exérèse de tumeur de la pointe du rocher avec déroutement du nerf facial, par abord transpétreux",
    tarifSecteur1Optam: "944.79",
    tarifHorsSecteur1HorsOptam: "723.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA003",
    designation:
      "Exérèse de tumeur de la pointe du rocher, par abord translabyrinthique",
    tarifSecteur1Optam: "715.05",
    tarifHorsSecteur1HorsOptam: "715.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA009",
    designation:
      "Exérèse de tumeur de la pointe du rocher, par abord suprapétreux",
    tarifSecteur1Optam: "963.27",
    tarifHorsSecteur1HorsOptam: "734.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA016",
    designation:
      "Exérèse de tumeur de la région pétroclivale sans déroutement du nerf facial, par abord transpétreux",
    tarifSecteur1Optam: "1214.52",
    tarifHorsSecteur1HorsOptam: "1055.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA025",
    designation:
      "Exérèse de tumeur de la région pétroclivale avec déroutement du nerf facial, par abord transpétreux",
    tarifSecteur1Optam: "1238.54",
    tarifHorsSecteur1HorsOptam: "1238.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA004",
    designation: "Exérèse de tumeur du clivus, par craniotomie",
    tarifSecteur1Optam: "1159.22",
    tarifHorsSecteur1HorsOptam: "1023.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA020",
    designation:
      "Exérèse de tumeur du clivus, par abord transoral ou nasosphénoïdal",
    tarifSecteur1Optam: "942.26",
    tarifHorsSecteur1HorsOptam: "897.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA012",
    designation:
      "Exérèse de tumeur de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne], par abord suprapétreux",
    tarifSecteur1Optam: "940.5",
    tarifHorsSecteur1HorsOptam: "940.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA007",
    designation:
      "Exérèse de tumeur de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne], par abord translabyrinthique",
    tarifSecteur1Optam: "1256.64",
    tarifHorsSecteur1HorsOptam: "1123.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA005",
    designation:
      "Exérèse de tumeur de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne], par abord rétrolabyrinthique présigmoïdien",
    tarifSecteur1Optam: "1228.92",
    tarifHorsSecteur1HorsOptam: "1107.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA010",
    designation:
      "Exérèse de tumeur de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne], par abord infraoccipital rétrosigmoïdien",
    tarifSecteur1Optam: "1228.92",
    tarifHorsSecteur1HorsOptam: "1107.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA027",
    designation:
      "Exérèse de tumeur de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne], par abord transotique",
    tarifSecteur1Optam: "1370.6",
    tarifHorsSecteur1HorsOptam: "1189.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA014",
    designation:
      "Exérèse de tumeur de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne], par deux abords",
    tarifSecteur1Optam: "1205.14",
    tarifHorsSecteur1HorsOptam: "1205.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA024",
    designation:
      "Exérèse de tumeur du foramen magnum sans déroutement de l'artère vertébrale, par craniotomie",
    tarifSecteur1Optam: "808.97",
    tarifHorsSecteur1HorsOptam: "732.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA019",
    designation:
      "Exérèse de tumeur du foramen magnum avec déroutement de l'artère vertébrale, par craniotomie",
    tarifSecteur1Optam: "989.76",
    tarifHorsSecteur1HorsOptam: "837.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA023",
    designation: "Exérèse de tumeur du foramen jugulaire, par craniotomie",
    tarifSecteur1Optam: "1041.52",
    tarifHorsSecteur1HorsOptam: "867.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA002",
    designation:
      "Exérèse de tumeur extraparenchymateuse de la convexité du cerveau sans atteinte de sinus veineux dural, par craniotomie",
    tarifSecteur1Optam: "621.08",
    tarifHorsSecteur1HorsOptam: "535.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA028",
    designation:
      "Exérèse de tumeur extraparenchymateuse de la convexité du cerveau envahissant un sinus veineux dural, par craniotomie",
    tarifSecteur1Optam: "773.52",
    tarifHorsSecteur1HorsOptam: "624.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA008",
    designation:
      "Exérèse de tumeur extraparenchymateuse de la convexité du cervelet sans atteinte de sinus veineux dural, par craniotomie",
    tarifSecteur1Optam: "571.45",
    tarifHorsSecteur1HorsOptam: "550.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACFA018",
    designation:
      "Exérèse de tumeur extraparenchymateuse de la convexité du cervelet envahissant un sinus veineux dural, par craniotomie",
    tarifSecteur1Optam: "761.47",
    tarifHorsSecteur1HorsOptam: "661.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA010",
    designation: "Exérèse de tumeur de la faux du cerveau, par craniotomie",
    tarifSecteur1Optam: "725.31",
    tarifHorsSecteur1HorsOptam: "684.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA008",
    designation:
      "Exérèse de tumeur de la tente du cervelet, par craniotomie soustentorielle",
    tarifSecteur1Optam: "792.66",
    tarifHorsSecteur1HorsOptam: "723.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA009",
    designation:
      "Exérèse de tumeur de l'incisure de la tente, par craniotomie sustentorielle",
    tarifSecteur1Optam: "1006.78",
    tarifHorsSecteur1HorsOptam: "935.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFC002",
    designation:
      "Exérèse de lésion d'un ventricule latéral cérébral, par vidéochirurgie intracrânienne",
    tarifSecteur1Optam: "627",
    tarifHorsSecteur1HorsOptam: "627",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA005",
    designation:
      "Exérèse de lésion d'un ventricule latéral cérébral, par craniotomie",
    tarifSecteur1Optam: "811.1",
    tarifHorsSecteur1HorsOptam: "733.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFC001",
    designation:
      "Exérèse de lésion du troisième ventricule, par vidéochirurgie intracrânienne",
    tarifSecteur1Optam: "651.57",
    tarifHorsSecteur1HorsOptam: "641.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA002",
    designation: "Exérèse de lésion du troisième ventricule, par craniotomie",
    tarifSecteur1Optam: "1000.4",
    tarifHorsSecteur1HorsOptam: "843.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABFA006",
    designation: "Exérèse de lésion du quatrième ventricule, par craniotomie",
    tarifSecteur1Optam: "884.83",
    tarifHorsSecteur1HorsOptam: "732.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AENA001",
    designation:
      "Destruction sélective de la corne postérieure de la moelle épinière ou de la jonction radicellospinale, par abord postérieur",
    tarifSecteur1Optam: "550.59",
    tarifHorsSecteur1HorsOptam: "550.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEPB001",
    designation: "Cordotomie spinale, par voie transcutanée",
    tarifSecteur1Optam: "366.07",
    tarifHorsSecteur1HorsOptam: "366.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AEPA002",
    designation:
      "Myélotomie, commissurotomie ou cordotomie spinale, par abord postérieur",
    tarifSecteur1Optam: "521.82",
    tarifHorsSecteur1HorsOptam: "434.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA025",
    designation: "Radicotomie spinale, par abord postérieur",
    tarifSecteur1Optam: "386.41",
    tarifHorsSecteur1HorsOptam: "355.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEPA001",
    designation: "Libération de moelle attachée, par abord postérieur",
    tarifSecteur1Optam: "541.68",
    tarifHorsSecteur1HorsOptam: "445.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEFA001",
    designation:
      "Exérèse de lésion de la moelle épinière sur un étage vertébral, par abord postérieur",
    tarifSecteur1Optam: "733.11",
    tarifHorsSecteur1HorsOptam: "688.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEFA002",
    designation:
      "Exérèse de lésion de la moelle épinière sur plusieurs étages vertébraux, par abord postérieur",
    tarifSecteur1Optam: "1046.48",
    tarifHorsSecteur1HorsOptam: "1001.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEFA003",
    designation: "Exérèse de lésion du cône médullaire, par abord postérieur",
    tarifSecteur1Optam: "737.36",
    tarifHorsSecteur1HorsOptam: "691.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA007",
    designation:
      "Exérèse de lésion de la queue de cheval sur un étage vertébral, par abord postérieur",
    tarifSecteur1Optam: "704.04",
    tarifHorsSecteur1HorsOptam: "566.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA003",
    designation:
      "Exérèse de lésion de la queue de cheval étendue sur plusieurs étages vertébraux [lésion géante de la queue de cheval], par abord postérieur",
    tarifSecteur1Optam: "836",
    tarifHorsSecteur1HorsOptam: "836",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA012",
    designation:
      "Exérèse de lipome de la queue de cheval avec fermeture de méningocèle ou de myéloméningocèle, par abord postérieur",
    tarifSecteur1Optam: "813.93",
    tarifHorsSecteur1HorsOptam: "669.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEJA004",
    designation:
      "Parage et fermeture de plaie pénétrante vertébrospinale, par abord postérieur",
    tarifSecteur1Optam: "395.62",
    tarifHorsSecteur1HorsOptam: "334.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEJA001",
    designation:
      "Parage et fermeture de plaie pénétrante vertébrospinale, par abord antérieur ou antérolatéral",
    tarifSecteur1Optam: "476.45",
    tarifHorsSecteur1HorsOptam: "408.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEJA005",
    designation:
      "Parage et fermeture de plaie pénétrante vertébrospinale avec ostéosynthèse vertébrale, par abord postérieur",
    tarifSecteur1Optam: "428.99",
    tarifHorsSecteur1HorsOptam: "428.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEJA002",
    designation:
      "Parage et fermeture de plaie pénétrante vertébrospinale avec ostéosynthèse vertébrale, par abord antérieur ou antérolatéral",
    tarifSecteur1Optam: "494.67",
    tarifHorsSecteur1HorsOptam: "494.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFSA002",
    designation:
      "Fermeture d'un spina bifida avec méningocèle, par abord postérieur",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFSA001",
    designation:
      "Fermeture d'un spina bifida avec myéloméningocèle, par abord postérieur",
    tarifSecteur1Optam: "359.48",
    tarifHorsSecteur1HorsOptam: "359.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEPA004",
    designation: "Libération d'une diastématomyélie, par abord postérieur",
    tarifSecteur1Optam: "458.58",
    tarifHorsSecteur1HorsOptam: "458.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA006",
    designation:
      "Exérèse d'une fistule dermique avec prolongement intradural rachidien",
    tarifSecteur1Optam: "270.84",
    tarifHorsSecteur1HorsOptam: "209.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEJA003",
    designation: "Évacuation d'hématome intraspinal [hématomyélie]",
    tarifSecteur1Optam: "453.05",
    tarifHorsSecteur1HorsOptam: "394.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFLB008",
    designation:
      "Pose d'un cathéter péridural [épidural], avec pose d'un système diffuseur ou d'une pompe implantable souscutané",
    tarifSecteur1Optam: "183.63",
    tarifHorsSecteur1HorsOptam: "163.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFLA003",
    designation:
      "Pose d'un cathéter intrathécal ou intrakystique spinal, avec pose d'un système diffuseur ou d'une pompe implantable souscutané",
    tarifSecteur1Optam: "184.34",
    tarifHorsSecteur1HorsOptam: "172.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFGA001",
    designation:
      "Ablation d'un système diffuseur ou d'une pompe implanté souscutané et du cathéter péridural [épidural] ou intrathécal spinal",
    tarifSecteur1Optam: "65.23",
    tarifHorsSecteur1HorsOptam: "55.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFKB002",
    designation:
      "Changement d'un cathéter péridural [épidural] relié à un système diffuseur ou à une pompe implanté souscutané",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFKB001",
    designation:
      "Changement d'un cathéter intrathécal spinal relié à un système diffuseur ou une pompe implanté souscutané",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZKA001",
    designation:
      "Changement d'un système diffuseur ou d'une pompe implanté souscutané sans changement du cathéter péridural [épidural] ou intrathécal spinal",
    tarifSecteur1Optam: "82.95",
    tarifHorsSecteur1HorsOptam: "74.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFLB007",
    designation:
      "Injection thérapeutique péridurale [épidurale] d'agent pharmacologique, sans guidage",
    tarifSecteur1Optam: "44.9",
    tarifHorsSecteur1HorsOptam: "44.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLB001",
    designation:
      "Injection péridurale [épidurale] de sang autologue [Blood patch]",
    tarifSecteur1Optam: "50.56",
    tarifHorsSecteur1HorsOptam: "35.78",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLH001",
    designation:
      "Injection thérapeutique péridurale [épidurale] d'agent pharmacologique, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "68.26",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLB006",
    designation:
      "Injection thérapeutique intrathécale d'agent pharmacologique, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "35.39",
    tarifHorsSecteur1HorsOptam: "26.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLH002",
    designation:
      "Injection thérapeutique intrathécale d'agent pharmacologique, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "47.4",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLB003",
    designation:
      "Séance d'injection intrathécale d'agent pharmacologique anticancéreux, par voie transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLB013",
    designation:
      "Séance d'administration intrathécale d'agent pharmacologique anticancéreux, par un dispositif implanté",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLB017",
    designation:
      "Administration péridurale [épidurale] d'agent pharmacologique au long cours",
    tarifSecteur1Optam: "106.81",
    tarifHorsSecteur1HorsOptam: "97.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFLB016",
    designation:
      "Administration intrathécale ou intrakystique spinale d'agent pharmacologique au long cours",
    tarifSecteur1Optam: "106.81",
    tarifHorsSecteur1HorsOptam: "97.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZMP004",
    designation:
      "Remplissage secondaire ou reprogrammation d'une pompe souscutanée à infusion continue du système nerveux central",
    tarifSecteur1Optam: "28.44",
    tarifHorsSecteur1HorsOptam: "24.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFJB002",
    designation:
      "Évacuation de liquide cérébrospinal, par voie transcutanée lombale",
    tarifSecteur1Optam: "52.7",
    tarifHorsSecteur1HorsOptam: "37.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFJA001",
    designation:
      "Évacuation d'hématome péridural [épidural] rachidien, par abord direct",
    tarifSecteur1Optam: "314.09",
    tarifHorsSecteur1HorsOptam: "313.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFJA004",
    designation:
      "Évacuation d'hématome péridural [épidural] rachidien postopératoire, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFJA003",
    designation: "Évacuation d'hématome intradural rachidien, par abord direct",
    tarifSecteur1Optam: "361.59",
    tarifHorsSecteur1HorsOptam: "341.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFPA001",
    designation:
      "Mise à plat de lésion infectieuse péridurale rachidienne et/ou paravertébrale postopératoire [sepsis], par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFCA004",
    designation:
      "Dérivation externe du liquide cérébrospinal subarachnoïdien rachidien",
    tarifSecteur1Optam: "116.99",
    tarifHorsSecteur1HorsOptam: "102.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AFCA002",
    designation:
      "Dérivation lombopéritonéale du liquide cérébrospinal subarachnoïdien rachidien, par abord direct",
    tarifSecteur1Optam: "248.86",
    tarifHorsSecteur1HorsOptam: "179.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFCA001",
    designation:
      "Dérivation d'une cavité liquidienne intraspinale [syringomyélique] ou périspinale dans l'espace subarachnoïdien rachidien, par abord direct",
    tarifSecteur1Optam: "470.78",
    tarifHorsSecteur1HorsOptam: "404.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFCA003",
    designation:
      "Dérivation d'une cavité liquidienne intraspinale [syringomyélique] ou périspinale en dehors de l'espace subarachnoïdien rachidien, par abord direct",
    tarifSecteur1Optam: "523.24",
    tarifHorsSecteur1HorsOptam: "435.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFGA002",
    designation:
      "Ablation d'une dérivation interne intraspinale du liquide cérébrospinal rachidien, par abord direct",
    tarifSecteur1Optam: "126.2",
    tarifHorsSecteur1HorsOptam: "90.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFJA002",
    designation:
      "Parage d'une épidurite et/ou d'une spondylodiscite sans reconstruction vertébrale, par abord direct",
    tarifSecteur1Optam: "348.12",
    tarifHorsSecteur1HorsOptam: "307.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFJA005",
    designation:
      "Parage d'une épidurite et/ou d'une spondylodiscite avec reconstruction vertébrale par greffe et/ou ostéosynthèse, par abord direct",
    tarifSecteur1Optam: "516.86",
    tarifHorsSecteur1HorsOptam: "431.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA004",
    designation:
      "Exérèse de kyste méningé intracanalaire vertébral, par abord postérieur",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFSA003",
    designation:
      "Fermeture d'une fistule de liquide cérébrospinal ou d'une méningocèle postopératoire spinale",
    tarifSecteur1Optam: "258.79",
    tarifHorsSecteur1HorsOptam: "255.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA007",
    designation:
      "Exérèse de tumeur intradurale extraspinale, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "635.26",
    tarifHorsSecteur1HorsOptam: "526.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA001",
    designation:
      "Exérèse de tumeur intradurale extraspinale sans reconstruction vertébrale, par abord antérieur ou antérolatéral",
    tarifSecteur1Optam: "705.46",
    tarifHorsSecteur1HorsOptam: "567.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA009",
    designation:
      "Exérèse de tumeur intradurale extraspinale avec reconstruction vertébrale, par abord antérieur ou antérolatéral",
    tarifSecteur1Optam: "806.84",
    tarifHorsSecteur1HorsOptam: "691.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA010",
    designation:
      "Exérèse de tumeur épidurale rachidienne, par abord postérieur",
    tarifSecteur1Optam: "417.6",
    tarifHorsSecteur1HorsOptam: "373.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA005",
    designation:
      "Exérèse de tumeur épidurale rachidienne avec ostéosynthèse vertébrale, par abord postérieur",
    tarifSecteur1Optam: "504.1",
    tarifHorsSecteur1HorsOptam: "489.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA003",
    designation:
      "Exérèse de tumeur en sablier extracanalaire et intracanalaire vertébrale sans reconstruction vertébrale, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "657.95",
    tarifHorsSecteur1HorsOptam: "601.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA008",
    designation:
      "Exérèse de tumeur en sablier extracanalaire et intracanalaire vertébrale sans reconstruction vertébrale, par abord antérieur ou antérolatéral",
    tarifSecteur1Optam: "742.32",
    tarifHorsSecteur1HorsOptam: "650",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA002",
    designation:
      "Exérèse de tumeur en sablier extracanalaire et intracanalaire vertébrale avec reconstruction vertébrale, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "752.25",
    tarifHorsSecteur1HorsOptam: "721.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AFFA011",
    designation:
      "Exérèse de tumeur en sablier extracanalaire et intracanalaire vertébrale avec reconstruction vertébrale, par abord antérieur ou antérolatéral",
    tarifSecteur1Optam: "871.36",
    tarifHorsSecteur1HorsOptam: "724.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABMP001",
    designation:
      "Contrôle et/ou réglage secondaire transcutané d'une valve de dérivation de liquide cérébrospinal à pression d'ouverture réglable",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ABMA002",
    designation:
      "Révision ou changement d'élément d'une dérivation interne du liquide cérébrospinal, par abord direct",
    tarifSecteur1Optam: "255.24",
    tarifHorsSecteur1HorsOptam: "183.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ABGA002",
    designation:
      "Ablation d'une dérivation interne du liquide cérébrospinal intracrânien, par abord direct",
    tarifSecteur1Optam: "91.46",
    tarifHorsSecteur1HorsOptam: "70.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AALB001",
    designation:
      "Implantation d’électrode de stimulation intracérébrale à visée thérapeutique, par voie stéréotaxique",
    tarifSecteur1Optam: "916.03",
    tarifHorsSecteur1HorsOptam: "794.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AALA900",
    designation:
      "Implantation d’électrode de stimulation corticale cérébrale à visée thérapeutique, par craniotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AELB002",
    designation:
      "Implantation d’électrode de stimulation de la moelle épinière à visée thérapeutique, par voie transcutanée",
    tarifSecteur1Optam: "168.74",
    tarifHorsSecteur1HorsOptam: "132.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AELB001",
    designation:
      "Implantation d’électrode de stimulation de la moelle épinière à visée thérapeutique par voie transcutanée, avec implantation souscutanée d'un générateur de stimulation neurologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AELA001",
    designation:
      "Implantation d’électrode de stimulation de la moelle épinière à visée thérapeutique, par abord direct",
    tarifSecteur1Optam: "265.17",
    tarifHorsSecteur1HorsOptam: "259.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AALA004",
    designation:
      "Implantation souscutanée d'un générateur de stimulation cérébrale",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AELA002",
    designation:
      "Implantation souscutanée d'un générateur de stimulation de la moelle épinière",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAMP387",
    designation:
      "Réglage secondaire d’un paramètre de système de stimulation cérébrale profonde",
    tarifSecteur1Optam: "60.48",
    tarifHorsSecteur1HorsOptam: "60.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAMP173",
    designation:
      "Réglage secondaire de plusieurs paramètres de système de stimulation cérébrale profonde",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "102.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAMP003",
    designation:
      "Réglage secondaire des paramètres de chaque plot de système de stimulation cérébrale profonde [cartographie]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AEMP491",
    designation:
      "Réglage secondaire ou reprogrammation d'un générateur souscutané de stimulation de la moelle épinière",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAGB001",
    designation: "Ablation d’électrode intracérébrale",
    tarifSecteur1Optam: "131.17",
    tarifHorsSecteur1HorsOptam: "93.63",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAGA900",
    designation: "Ablation d’électrode corticale cérébrale, par craniotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEGB001",
    designation:
      "Ablation d’électrode de stimulation de la moelle épinière, par voie transcutanée",
    tarifSecteur1Optam: "63.1",
    tarifHorsSecteur1HorsOptam: "54.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AEGA001",
    designation:
      "Ablation d’électrode de stimulation de la moelle épinière, par abord direct",
    tarifSecteur1Optam: "182.92",
    tarifHorsSecteur1HorsOptam: "123.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AZGA001",
    designation:
      "Ablation d'un générateur souscutané de stimulation du système nerveux central",
    tarifSecteur1Optam: "49.63",
    tarifHorsSecteur1HorsOptam: "46.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AAKA001",
    designation:
      "Changement d'un générateur souscutané de stimulation cérébrale",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AEKA001",
    designation:
      "Changement d'un générateur souscutané de stimulation de la moelle épinière",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA020",
    designation: "Décompression du nerf optique, par abord orbitaire",
    tarifSecteur1Optam: "227.59",
    tarifHorsSecteur1HorsOptam: "227.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA001",
    designation: "Décompression du nerf optique, par craniotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA023",
    designation:
      "Décompression du nerf optique, par abord transsinusien [transethmoïdal]",
    tarifSecteur1Optam: "468.72",
    tarifHorsSecteur1HorsOptam: "447.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADLB001",
    designation:
      "Infiltration thérapeutique de branche terminale du nerf trijumeau",
    tarifSecteur1Optam: "35.45",
    tarifHorsSecteur1HorsOptam: "24.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADNB001",
    designation:
      "Destruction sélective de branche terminale du nerf trijumeau, par voie transcutanée ou transmuqueuse sans guidage",
    tarifSecteur1Optam: "34.03",
    tarifHorsSecteur1HorsOptam: "23.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADNH002",
    designation:
      "Destruction sélective de branche terminale du nerf trijumeau, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADPA016",
    designation: "Décompression du nerf infraorbitaire, par abord direct",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "109.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA004",
    designation:
      "Décompression du nerf alvéolaire inférieur [dentaire inférieur], par abord intrabuccal",
    tarifSecteur1Optam: "134",
    tarifHorsSecteur1HorsOptam: "121.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADCA004",
    designation:
      "Déroutement du nerf alvéolaire inférieur [dentaire inférieur], par abord intrabuccal",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA003",
    designation:
      "Section d'une branche extracrânienne du nerf trijumeau, par abord direct",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADCA002",
    designation: "Suture de plaie du nerf facial extrapétreux",
    tarifSecteur1Optam: "343.42",
    tarifHorsSecteur1HorsOptam: "264.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADEA001",
    designation: "Autogreffe du nerf facial, par abord transmastoïdien",
    tarifSecteur1Optam: "481.23",
    tarifHorsSecteur1HorsOptam: "481.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADEA004",
    designation:
      "Autogreffe du nerf facial, par abord suprapétreux et/ou par abord rétrosigmoïdien",
    tarifSecteur1Optam: "654.51",
    tarifHorsSecteur1HorsOptam: "654.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADEA003",
    designation:
      "Autogreffe du nerf facial avec déroutement, par abord translabyrinthique",
    tarifSecteur1Optam: "990.99",
    tarifHorsSecteur1HorsOptam: "692.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADEA002",
    designation: "Autogreffe du nerf facial extrapétreux",
    tarifSecteur1Optam: "349.93",
    tarifHorsSecteur1HorsOptam: "349.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA008",
    designation: "Décompression du nerf facial, par abord transmastoïdien",
    tarifSecteur1Optam: "410.41",
    tarifHorsSecteur1HorsOptam: "356.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA011",
    designation: "Décompression du nerf facial, par abord suprapétreux",
    tarifSecteur1Optam: "773.08",
    tarifHorsSecteur1HorsOptam: "623.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA021",
    designation:
      "Décompression du nerf facial, par abord transmastoïdien et par abord suprapétreux",
    tarifSecteur1Optam: "702.55",
    tarifHorsSecteur1HorsOptam: "702.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADCA001",
    designation:
      "Déroutement et suture du nerf facial, par abord transmastoïdien",
    tarifSecteur1Optam: "440.58",
    tarifHorsSecteur1HorsOptam: "440.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADCA003",
    designation: "Déroutement et suture du nerf facial, par abord suprapétreux",
    tarifSecteur1Optam: "672.66",
    tarifHorsSecteur1HorsOptam: "672.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADCA007",
    designation:
      "Déroutement et suture du nerf facial, par abord translabyrinthique",
    tarifSecteur1Optam: "707.49",
    tarifHorsSecteur1HorsOptam: "707.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA015",
    designation: "Section du nerf du canal ptérygoïdien [Neurotomie vidienne]",
    tarifSecteur1Optam: "121.76",
    tarifHorsSecteur1HorsOptam: "121.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA009",
    designation: "Section des nerfs pétreux, par abord suprapétreux",
    tarifSecteur1Optam: "347.73",
    tarifHorsSecteur1HorsOptam: "347.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADFA004",
    designation:
      "Exérèse de lésion du nerf facial intrapétreux avec réparation immédiate",
    tarifSecteur1Optam: "746.79",
    tarifHorsSecteur1HorsOptam: "746.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADFA003",
    designation:
      "Exérèse de lésion du nerf facial extrapétreux sans réparation immédiate",
    tarifSecteur1Optam: "257.66",
    tarifHorsSecteur1HorsOptam: "257.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADFA006",
    designation:
      "Exérèse de lésion du nerf facial extrapétreux avec réparation immédiate",
    tarifSecteur1Optam: "443.27",
    tarifHorsSecteur1HorsOptam: "443.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA007",
    designation: "Neurotomie vestibulaire, par abord suprapétreux",
    tarifSecteur1Optam: "614.6",
    tarifHorsSecteur1HorsOptam: "614.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA005",
    designation: "Neurotomie vestibulaire, par abord translabyrinthique",
    tarifSecteur1Optam: "634.69",
    tarifHorsSecteur1HorsOptam: "634.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA010",
    designation: "Neurotomie vestibulaire, par abord rétrolabyrinthique",
    tarifSecteur1Optam: "909.37",
    tarifHorsSecteur1HorsOptam: "684.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA017",
    designation:
      "Section du nerf tympanique [de Jacobson] dans le cavum tympanique",
    tarifSecteur1Optam: "92.72",
    tarifHorsSecteur1HorsOptam: "92.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA012",
    designation: "Section du nerf ampullaire postérieur",
    tarifSecteur1Optam: "202.59",
    tarifHorsSecteur1HorsOptam: "202.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPC004",
    designation: "Vagotomie tronculaire, par thoracoscopie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA014",
    designation: "Vagotomie tronculaire, par thoracotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPC001",
    designation: "Vagotomie tronculaire, par cœlioscopie",
    tarifSecteur1Optam: "403.28",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA022",
    designation: "Vagotomie tronculaire, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA018",
    designation:
      "Vagotomie tronculaire avec gastrojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA024",
    designation:
      "Vagotomie tronculaire avec antrectomie gastrique, par laparotomie",
    tarifSecteur1Optam: "423.98",
    tarifHorsSecteur1HorsOptam: "403.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPC003",
    designation:
      "Vagotomie tronculaire postérieure avec séromyotomie œsogastrique antérieure, par cœlioscopie",
    tarifSecteur1Optam: "404.18",
    tarifHorsSecteur1HorsOptam: "404.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA002",
    designation:
      "Vagotomie tronculaire postérieure avec séromyotomie œsogastrique antérieure, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPC002",
    designation: "Vagotomie suprasélective, par cœlioscopie",
    tarifSecteur1Optam: "448.09",
    tarifHorsSecteur1HorsOptam: "429.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA019",
    designation: "Vagotomie suprasélective, par laparotomie",
    tarifSecteur1Optam: "393.5",
    tarifHorsSecteur1HorsOptam: "385.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADLA001",
    designation:
      "Implantation d'un stimulateur du nerf vague, par abord direct",
    tarifSecteur1Optam: "226,88",
    tarifHorsSecteur1HorsOptam: "226,88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADKA001",
    designation:
      "Changement d'un générateur de stimulation du nerf vague, par abord direct",
    tarifSecteur1Optam: "63,81",
    tarifHorsSecteur1HorsOptam: "63,81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADGA001",
    designation:
      "Ablation d'un générateur de stimulation du nerf vague, par abord direct",
    tarifSecteur1Optam: "56,72",
    tarifHorsSecteur1HorsOptam: "56,72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADNH001",
    designation:
      "Destruction sélective du nerf glossopharyngien, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADCA006",
    designation: "Suture de plaie d'un nerf crânien, par craniotomie",
    tarifSecteur1Optam: "497.7",
    tarifHorsSecteur1HorsOptam: "497.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ACPA001",
    designation:
      "Libération d'un conflit vasculonerveux intracrânien, par craniotomie",
    tarifSecteur1Optam: "596.27",
    tarifHorsSecteur1HorsOptam: "521.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADEA005",
    designation: "Autogreffe de nerf crânien, par craniotomie",
    tarifSecteur1Optam: "573.36",
    tarifHorsSecteur1HorsOptam: "573.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPC900",
    designation: "Section de nerf crânien, par vidéochirurgie intracrânienne",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADPA013",
    designation:
      "Section de nerf crânien, par abord infraoccipital rétrosigmoïdien",
    tarifSecteur1Optam: "570.75",
    tarifHorsSecteur1HorsOptam: "436.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADCA005",
    designation: "Anastomose extracrânienne entre deux nerfs crâniens",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADFA005",
    designation:
      "Exérèse de lésion extracrânienne d'un nerf crânien sans réparation immédiate",
    tarifSecteur1Optam: "248.15",
    tarifHorsSecteur1HorsOptam: "226.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ADFA002",
    designation:
      "Exérèse de lésion extracrânienne d'un nerf crânien avec réparation immédiate par autogreffe",
    tarifSecteur1Optam: "420.55",
    tarifHorsSecteur1HorsOptam: "420.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KFFA001",
    designation: "Exérèse de tumeur parapharyngienne, par cervicotomie",
    tarifSecteur1Optam: "512.61",
    tarifHorsSecteur1HorsOptam: "380.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB003",
    designation:
      "Infiltration thérapeutique unilatérale ou bilatérale du nerf grand occipital [grand nerf occipital d'Arnold]",
    tarifSecteur1Optam: "34.13",
    tarifHorsSecteur1HorsOptam: "27.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHNB002",
    designation:
      "Destruction sélective unilatérale ou bilatérale du nerf grand occipital [grand nerf occipital d'Arnold], par voie transcutanée",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHFA002",
    designation:
      "Exérèse de tumeur nerveuse cervicale superficielle, par cervicotomie",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA010",
    designation:
      "Exérèse de tumeur nerveuse cervicale profonde, par cervicotomie",
    tarifSecteur1Optam: "572.87",
    tarifHorsSecteur1HorsOptam: "415.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA011",
    designation:
      "Exérèse de tumeur nerveuse cervicale profonde, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "444.08",
    tarifHorsSecteur1HorsOptam: "444.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA007",
    designation: "Suture de plaie d'un nerf cervicofacial, par abord direct",
    tarifSecteur1Optam: "179.38",
    tarifHorsSecteur1HorsOptam: "169.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA012",
    designation: "Autogreffe d'un nerf cervicofacial, par abord direct",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB016",
    designation: "Infiltration thérapeutique du plexus brachial",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB001",
    designation: "Bloc anesthésique continu du plexus brachial",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHCA001",
    designation:
      "Suture, autogreffe non pédiculée et/ou neurotisation du plexus brachial, par abord supraclaviculaire",
    tarifSecteur1Optam: "737.77",
    tarifHorsSecteur1HorsOptam: "737.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA022",
    designation:
      "Suture, autogreffe non pédiculée et/ou neurotisation du plexus brachial, par abord infraclaviculaire",
    tarifSecteur1Optam: "737.77",
    tarifHorsSecteur1HorsOptam: "737.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA020",
    designation:
      "Suture, autogreffe non pédiculée et/ou neurotisation du plexus brachial, par abord supraclaviculaire et par abord infraclaviculaire",
    tarifSecteur1Optam: "828.11",
    tarifHorsSecteur1HorsOptam: "790.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA018",
    designation:
      "Autogreffe pédiculée du plexus brachial, par abord supraclaviculaire et par abord infraclaviculaire",
    tarifSecteur1Optam: "954.31",
    tarifHorsSecteur1HorsOptam: "863.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA020",
    designation: "Libération du plexus brachial, par abord infraclaviculaire",
    tarifSecteur1Optam: "415.47",
    tarifHorsSecteur1HorsOptam: "346.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA004",
    designation:
      "Libération du plexus brachial avec scalénotomie, par abord supraclaviculaire",
    tarifSecteur1Optam: "437.45",
    tarifHorsSecteur1HorsOptam: "376.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA005",
    designation:
      "Libération du plexus brachial avec scalénotomie et ablation de côte cervicale, par abord supraclaviculaire",
    tarifSecteur1Optam: "531.04",
    tarifHorsSecteur1HorsOptam: "439.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA006",
    designation:
      "Libération du plexus brachial, par abord supraclaviculaire et par abord infraclaviculaire",
    tarifSecteur1Optam: "544.51",
    tarifHorsSecteur1HorsOptam: "421.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA008",
    designation:
      "Exérèse de tumeur du plexus brachial ou du plexus lombosacral avec réparation immédiate, par abord direct",
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "441.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB006",
    designation:
      "Infiltration thérapeutique du nerf médian dans le canal carpien",
    tarifSecteur1Optam: "29.48",
    tarifHorsSecteur1HorsOptam: "29.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHCA019",
    designation: "Suture de plaie du nerf circonflexe, par abord direct",
    tarifSecteur1Optam: "323.72",
    tarifHorsSecteur1HorsOptam: "323.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA005",
    designation: "Suture de plaie du nerf musculocutané, par abord direct",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "160.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA009",
    designation:
      "Suture de plaie du nerf radial au bras ou au coude, par abord direct",
    tarifSecteur1Optam: "294.94",
    tarifHorsSecteur1HorsOptam: "236.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA016",
    designation:
      "Suture de plaie de nerf profond du membre supérieur en amont du poignet, par abord direct",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "232.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA018",
    designation:
      "Suture de plaie du nerf médian ou du nerf ulnaire au poignet, par abord direct",
    tarifSecteur1Optam: "287.15",
    tarifHorsSecteur1HorsOptam: "232.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA012",
    designation:
      "Suture de plaie du nerf médian ou du nerf ulnaire au poignet avec suture de plaie de l'artère radiale ou de l'artère ulnaire, par abord direct",
    tarifSecteur1Optam: "387.11",
    tarifHorsSecteur1HorsOptam: "309.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA006",
    designation:
      "Suture de plaies du nerf médian et du nerf ulnaire au poignet, par abord direct",
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "320.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA008",
    designation:
      "Suture de plaies du nerf médian et du nerf ulnaire au poignet avec suture de plaie de l'artère radiale ou de l'artère ulnaire, par abord direct",
    tarifSecteur1Optam: "514.73",
    tarifHorsSecteur1HorsOptam: "416.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA017",
    designation:
      "Suture de plaies du nerf médian et du nerf ulnaire au poignet avec suture de plaies de l'artère radiale et de l'artère ulnaire, par abord direct",
    tarifSecteur1Optam: "554.44",
    tarifHorsSecteur1HorsOptam: "439.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA023",
    designation:
      "Suture de plaie d’un nerf digital palmaire, sur un rayon de la main",
    tarifSecteur1Optam: "158.11",
    tarifHorsSecteur1HorsOptam: "157.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA015",
    designation:
      "Suture de plaies d’un nerf digital palmaire et d’une artère digitale palmaire, sur un rayon de la main",
    tarifSecteur1Optam: "333.54",
    tarifHorsSecteur1HorsOptam: "333.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA013",
    designation:
      "Suture de plaies de 2 nerfs digitaux palmaires, sur un rayon de la main",
    tarifSecteur1Optam: "249.94",
    tarifHorsSecteur1HorsOptam: "249.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA002",
    designation:
      "Suture de plaies de 2 nerfs digitaux palmaires et d’une artère digitale palmaire, sur un rayon de la main",
    tarifSecteur1Optam: "396.24",
    tarifHorsSecteur1HorsOptam: "396.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA010",
    designation: "Autogreffe du nerf circonflexe, par abord direct",
    tarifSecteur1Optam: "516.86",
    tarifHorsSecteur1HorsOptam: "417.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA002",
    designation: "Autogreffe du nerf musculocutané, par abord direct",
    tarifSecteur1Optam: "387.08",
    tarifHorsSecteur1HorsOptam: "387.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA014",
    designation: "Autogreffe du nerf radial, par abord direct",
    tarifSecteur1Optam: "433.91",
    tarifHorsSecteur1HorsOptam: "369.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA004",
    designation: "Autogreffe du nerf médian à l'avant-bras, par abord direct",
    tarifSecteur1Optam: "443.83",
    tarifHorsSecteur1HorsOptam: "375.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA011",
    designation: "Autogreffe du nerf ulnaire à l'avant-bras, par abord direct",
    tarifSecteur1Optam: "443.83",
    tarifHorsSecteur1HorsOptam: "375.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA008",
    designation:
      "Autogreffe du nerf médian ou du nerf ulnaire au poignet, par abord direct",
    tarifSecteur1Optam: "379.32",
    tarifHorsSecteur1HorsOptam: "338.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA005",
    designation:
      "Autogreffe du nerf médian et du nerf ulnaire au poignet, par abord direct",
    tarifSecteur1Optam: "521.12",
    tarifHorsSecteur1HorsOptam: "473.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA016",
    designation: "Autogreffe nerveuse à la main, par abord direct",
    tarifSecteur1Optam: "372.23",
    tarifHorsSecteur1HorsOptam: "334.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA016",
    designation: "Libération du nerf circonflexe, par abord direct",
    tarifSecteur1Optam: "402",
    tarifHorsSecteur1HorsOptam: "277.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA012",
    designation:
      "Libération du nerf supraclaviculaire et/ou de ses branches, par abord direct",
    tarifSecteur1Optam: "331.81",
    tarifHorsSecteur1HorsOptam: "236.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA013",
    designation: "Libération du nerf musculocutané, par abord direct",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "109.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA027",
    designation: "Libération du nerf radial au bras, par abord direct",
    tarifSecteur1Optam: "172.29",
    tarifHorsSecteur1HorsOptam: "143.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA023",
    designation: "Libération du nerf radial au coude, par abord direct",
    tarifSecteur1Optam: "172.29",
    tarifHorsSecteur1HorsOptam: "143.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA022",
    designation: "Libération du nerf ulnaire au coude, par abord direct",
    tarifSecteur1Optam: "138.96",
    tarifHorsSecteur1HorsOptam: "124.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA021",
    designation:
      "Libération du nerf ulnaire au poignet et/ou à la main, par abord direct",
    tarifSecteur1Optam: "120.53",
    tarifHorsSecteur1HorsOptam: "113.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPC001",
    designation:
      "Libération du nerf médian au canal carpien, par vidéochirurgie",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "109.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA009",
    designation: "Libération du nerf médian au canal carpien, par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA028",
    designation:
      "Libération de nerf digital par abord direct, sur un rayon de la main",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA007",
    designation:
      "Neurotomie ou neurectomie intrafasciculaire ou extrafasciculaire des troncs et/ou des rameaux moteurs au membre supérieur, pour hyponeurotisation",
    tarifSecteur1Optam: "220.5",
    tarifHorsSecteur1HorsOptam: "163",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA026",
    designation: "Dénervation complète du poignet, par abord direct",
    tarifSecteur1Optam: "297.07",
    tarifHorsSecteur1HorsOptam: "224.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLA001",
    designation:
      "Implantation d'un stimulateur électrique pour restauration de la motricité du membre supérieur",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHGA002",
    designation:
      "Ablation d'un stimulateur électrique de restauration de la motricité du membre supérieur",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHKA003",
    designation:
      "Changement du générateur d'un stimulateur électrique de restauration de la motricité du membre supérieur",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHKA002",
    designation:
      "Changement d'électrode d'un stimulateur électrique de restauration de la motricité du membre supérieur",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB015",
    designation:
      "Infiltration thérapeutique de nerf de la paroi du tronc, sans stimulodétection",
    tarifSecteur1Optam: "22.12",
    tarifHorsSecteur1HorsOptam: "20.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB010",
    designation:
      "Infiltration thérapeutique de nerf de la paroi du tronc, avec stimulodétection",
    tarifSecteur1Optam: "39.18",
    tarifHorsSecteur1HorsOptam: "30.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHNB001",
    designation:
      "Destruction de nerf de la paroi du tronc, par voie transcutanée",
    tarifSecteur1Optam: "61.3",
    tarifHorsSecteur1HorsOptam: "43.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHPA014",
    designation: "Libération de nerf de la paroi du tronc, par abord direct",
    tarifSecteur1Optam: "182.92",
    tarifHorsSecteur1HorsOptam: "149.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLA002",
    designation:
      "Implantation d'électrode de stimulation du nerf phrénique par thoracotomie, avec pose d'un stimulateur externe",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHGA001",
    designation:
      "Ablation d'électrode de stimulation du nerf phrénique, par thoracotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHKA001",
    designation:
      "Changement d'électrode de stimulation du nerf phrénique, par thoracotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLH010",
    designation:
      "Infiltration thérapeutique de nerf pelvien profond, avec guidage radiologique",
    tarifSecteur1Optam: "32.23",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH004",
    designation:
      "Infiltration thérapeutique de nerf pelvien profond, avec guidage scanographique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH002",
    designation:
      "Infiltration thérapeutique du plexus lombosacral, avec guidage radiologique",
    tarifSecteur1Optam: "42.34",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH003",
    designation:
      "Infiltration thérapeutique du plexus lombosacral, avec guidage scanographique",
    tarifSecteur1Optam: "29.76",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB013",
    designation:
      "Bloc anesthésique continu du plexus lombosacral, sans guidage",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH019",
    designation:
      "Bloc anesthésique continu du plexus lombosacral, avec guidage scanographique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHCA014",
    designation:
      "Suture, greffe et/ou neurotisation du plexus lombosacral, par abord direct",
    tarifSecteur1Optam: "787.7",
    tarifHorsSecteur1HorsOptam: "766.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA004",
    designation: "Suture de plaie du nerf fémoral [crural], par abord direct",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "232.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA010",
    designation:
      "Suture de plaie du nerf sciatique à la cuisse, par abord direct",
    tarifSecteur1Optam: "340.17",
    tarifHorsSecteur1HorsOptam: "340.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA011",
    designation:
      "Suture de plaie du nerf tibial [sciatique poplité interne], par abord direct",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "232.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA021",
    designation:
      "Suture de plaie du nerf fibulaire commun [sciatique poplité externe], par abord direct",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "232.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHCA003",
    designation:
      "Suture de plaie de nerf à la cheville ou au pied, par abord direct",
    tarifSecteur1Optam: "286.44",
    tarifHorsSecteur1HorsOptam: "231.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA013",
    designation: "Autogreffe du nerf fémoral [crural], par abord direct",
    tarifSecteur1Optam: "387.9",
    tarifHorsSecteur1HorsOptam: "387.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA017",
    designation: "Autogreffe du nerf sciatique à la fesse, par abord direct",
    tarifSecteur1Optam: "431.08",
    tarifHorsSecteur1HorsOptam: "431.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA006",
    designation: "Autogreffe du nerf sciatique à la cuisse, par abord direct",
    tarifSecteur1Optam: "387.49",
    tarifHorsSecteur1HorsOptam: "387.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA009",
    designation:
      "Autogreffe du nerf tibial [sciatique poplité interne] ou du nerf fibulaire commun [sciatique poplité externe], par abord direct",
    tarifSecteur1Optam: "460.85",
    tarifHorsSecteur1HorsOptam: "385.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA003",
    designation:
      "Autogreffe nerveuse à la cheville ou au pied, par abord direct",
    tarifSecteur1Optam: "383.57",
    tarifHorsSecteur1HorsOptam: "340.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA002",
    designation: "Libération du plexus lombosacral, par abord direct",
    tarifSecteur1Optam: "455.18",
    tarifHorsSecteur1HorsOptam: "329.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA017",
    designation:
      "Libération du nerf fémoral [crural], du nerf sciatique et/ou de leurs branches à la racine du membre inférieur ou à la cuisse, par abord direct",
    tarifSecteur1Optam: "334.65",
    tarifHorsSecteur1HorsOptam: "246.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA010",
    designation:
      "Libération du nerf fibulaire commun [sciatique poplité externe] au genou, par abord direct",
    tarifSecteur1Optam: "197.1",
    tarifHorsSecteur1HorsOptam: "158.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA008",
    designation:
      "Libération du nerf tibial [sciatique poplité interne] à la jambe et/ou au canal calcanéen, par abord direct",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA001",
    designation:
      "Libération de nerf à la cheville et/ou au pied, par abord direct",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA011",
    designation:
      "Libération ou résection d'un nerf digital plantaire, par abord direct",
    tarifSecteur1Optam: "116.99",
    tarifHorsSecteur1HorsOptam: "111.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA005",
    designation: "Résection du nerf présacral, par laparotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB018",
    designation:
      "Implantation d'une électrode test sur une racine nerveuse sacrale pour neuromodulation, par voie transcutanée",
    tarifSecteur1Optam: "163.07",
    tarifHorsSecteur1HorsOptam: "129.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLA003",
    designation:
      "Implantation d'une électrode définitive sur une racine nerveuse sacrale par abord direct, avec implantation souscutanée d'un générateur de neuromodulation",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "189.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA015",
    designation:
      "Neurotomie ou neurectomie intrafasciculaire ou extrafasciculaire des troncs ou des rameaux moteurs au membre inférieur, pour hyponeurotisation",
    tarifSecteur1Optam: "117.69",
    tarifHorsSecteur1HorsOptam: "103.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB011",
    designation:
      "Infiltration thérapeutique de nerf spinal à l'émergence rachidienne, sans guidage",
    tarifSecteur1Optam: "37.29",
    tarifHorsSecteur1HorsOptam: "29.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH014",
    designation:
      "Infiltration thérapeutique de nerf spinal à l'émergence rachidienne, avec guidage radiologique",
    tarifSecteur1Optam: "34.76",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH007",
    designation:
      "Infiltration thérapeutique de nerf spinal à l'émergence rachidienne, avec guidage scanographique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH018",
    designation:
      "Infiltration thérapeutique de rameau dorsal de nerf spinal, avec guidage radiologique",
    tarifSecteur1Optam: "44.87",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH017",
    designation:
      "Infiltration thérapeutique de rameau dorsal de nerf spinal, avec guidage scanographique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHNH001",
    designation:
      "Destruction du rameau dorsal d'un nerf spinal, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "59.41",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHNH002",
    designation:
      "Destruction du rameau dorsal d'un nerf spinal, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH012",
    designation:
      "Bloc anesthésique continu d'un nerf spinal à son émergence rachidienne, avec guidage radiologique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH013",
    designation:
      "Bloc anesthésique continu d'un nerf spinal à son émergence rachidienne, avec guidage scanographique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB014",
    designation:
      "Infiltration thérapeutique de nerf superficiel d'un membre, sans stimulodétection électrique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB002",
    designation:
      "Infiltration thérapeutique de nerf superficiel d'un membre, avec stimulodétection électrique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB012",
    designation:
      "Infiltration thérapeutique de nerf profond d'un membre, sans guidage",
    tarifSecteur1Optam: "61.3",
    tarifHorsSecteur1HorsOptam: "43.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH006",
    designation:
      "Infiltration thérapeutique de nerf profond d'un membre, avec guidage radiologique",
    tarifSecteur1Optam: "28.44",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLB009",
    designation: "Bloc anesthésique continu d'un nerf profond d'un membre",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHEA007",
    designation:
      "Neurotisation d'un nerf spinal profond sans libération nerveuse, par abord direct",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA015",
    designation:
      "Neurotisation d'un nerf spinal profond avec libération nerveuse, par abord direct",
    tarifSecteur1Optam: "333.54",
    tarifHorsSecteur1HorsOptam: "333.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHEA001",
    designation:
      "Autogreffe pédiculée d'un nerf spinal profond, par abord direct",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA004",
    designation:
      "Exérèse de tumeur d'un nerf spinal sans réparation immédiate, par abord direct",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHFA001",
    designation:
      "Exérèse de tumeur d'un nerf spinal avec réparation immédiate, par abord direct",
    tarifSecteur1Optam: "392.79",
    tarifHorsSecteur1HorsOptam: "310.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHLB004",
    designation:
      "Infiltration thérapeutique d'un nerf profond dans un canal ostéofibreux, sans guidage",
    tarifSecteur1Optam: "28.44",
    tarifHorsSecteur1HorsOptam: "24.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHLH015",
    designation:
      "Infiltration thérapeutique d'un nerf profond dans un canal ostéofibreux, avec guidage scanographique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHPA024",
    designation:
      "Libération de nerf spinal superficiel des membres, par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA019",
    designation:
      "Section, résection ou destruction de nerf spinal superficiel, par abord direct",
    tarifSecteur1Optam: "117.69",
    tarifHorsSecteur1HorsOptam: "103.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA018",
    designation:
      "Section de nerf spinal superficiel avec déroutement, par abord direct",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "94.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHPA003",
    designation:
      "Section de nerf spinal profond avec déroutement, par abord direct",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "94.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJLH019",
    designation:
      "Infiltration thérapeutique du ganglion ptérygopalatin, avec guidage radiologique",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLB001",
    designation:
      "Infiltration thérapeutique du ganglion cervicothoracique [stellaire], sans guidage",
    tarifSecteur1Optam: "43.61",
    tarifHorsSecteur1HorsOptam: "37.39",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH020",
    designation:
      "Infiltration thérapeutique du ganglion cervicothoracique [stellaire], avec guidage radiologique",
    tarifSecteur1Optam: "32.86",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLJ002",
    designation:
      "Infiltration thérapeutique transgastrique des nerfs splanchniques, avec guidage échoendoscopique",
    tarifSecteur1Optam: "186.2",
    tarifHorsSecteur1HorsOptam: "186.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH017",
    designation:
      "Infiltration thérapeutique du plexus cœliaque et/ou de la chaîne splanchnique, avec guidage scanographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH016",
    designation:
      "Infiltration thérapeutique de la chaîne sympathique lombale, avec guidage radiologique",
    tarifSecteur1Optam: "70.15",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH009",
    designation:
      "Infiltration thérapeutique de la chaîne sympathique lombale, avec guidage scanographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH003",
    designation:
      "Infiltration thérapeutique du plexus hypogastrique ou sacral, avec guidage radiologique",
    tarifSecteur1Optam: "60.67",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH008",
    designation:
      "Infiltration thérapeutique du plexus hypogastrique ou sacral, avec guidage scanographique",
    tarifSecteur1Optam: "44.16",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH011",
    designation:
      "Bloc anesthésique continu du plexus cœliaque et/ou de la chaîne splanchnique, avec guidage scanographique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH005",
    designation:
      "Bloc anesthésique continu de la chaîne sympathique lombale, avec guidage radiologique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLH013",
    designation:
      "Bloc anesthésique continu de la chaîne sympathique lombale, avec guidage scanographique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJLF001",
    designation:
      "Bloc sympathique médicamenteux périphérique, par injection intraveineuse transcutanée sous garrot ischémique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH005",
    designation:
      "Destruction chimique du ganglion ptérygopalatin, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH002",
    designation:
      "Destruction chimique de la chaîne sympathique cervicale ou thoracique, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH001",
    designation:
      "Destruction chimique de la chaîne sympathique cervicale ou thoracique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "42.24",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH009",
    designation:
      "Thermocoagulation du ganglion cervicothoracique [stellaire], par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "42.34",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNJ001",
    designation:
      "Destruction chimique du plexus cœliaque et/ou de la chaîne splanchnique, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "61.94",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH008",
    designation:
      "Destruction chimique du plexus cœliaque et/ou de la chaîne splanchnique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "53.76",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNC002",
    designation:
      "Destruction chimique unilatérale ou bilatérale de la chaîne splanchnique, par thoracoscopie",
    tarifSecteur1Optam: "467.94",
    tarifHorsSecteur1HorsOptam: "419.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJNC001",
    designation:
      "Destruction chimique unilatérale ou bilatérale de la chaîne splanchnique, par cœlioscopie",
    tarifSecteur1Optam: "359.23",
    tarifHorsSecteur1HorsOptam: "359.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJNA001",
    designation:
      "Destruction chimique unilatérale ou bilatérale de la chaîne splanchnique, par laparotomie",
    tarifSecteur1Optam: "387.11",
    tarifHorsSecteur1HorsOptam: "329.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJNH003",
    designation:
      "Destruction chimique de la chaîne ganglionnaire sympathique lombale, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH004",
    designation:
      "Destruction chimique de la chaîne ganglionnaire sympathique lombale, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH006",
    designation:
      "Destruction chimique du plexus hypogastrique ou sacral, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "87.85",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJNH007",
    designation:
      "Destruction chimique du plexus hypogastrique ou sacral, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "64.8",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AJFC002",
    designation:
      "Sympathectomie cervicothoracique ou thoracique, par thoracoscopie",
    tarifSecteur1Optam: "289.2",
    tarifHorsSecteur1HorsOptam: "289.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJFA003",
    designation:
      "Sympathectomie cervicothoracique ou thoracique, par thoracotomie",
    tarifSecteur1Optam: "285.73",
    tarifHorsSecteur1HorsOptam: "271.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJFC001",
    designation:
      "Sympathectomie lombale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "304.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJFA001",
    designation: "Sympathectomie lombale, par laparotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AJFA002",
    designation:
      "Sympathectomie des artères métacarpiennes et digitales, par abord direct",
    tarifSecteur1Optam: "202.07",
    tarifHorsSecteur1HorsOptam: "143.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ANMP001",
    designation:
      "Mise en place et surveillance d'une analgésie contrôlée par le patient [ACP] [PAC]",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANLP002",
    designation: "Pose d'un stimulateur électrique externe à visée antalgique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AZRP001",
    designation: "Séance d'électroconvulsivothérapie [sismothérapie]",
    tarifSecteur1Optam: "39.18",
    tarifHorsSecteur1HorsOptam: "29.18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANRP001",
    designation: "Séance d'hypnose à visée antalgique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ANLB003",
    designation: "Séance de mésothérapie à visée antalgique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADQP002",
    designation: "Électrooculographie sensorielle [EOG sensorielle]",
    tarifSecteur1Optam: "53.76",
    tarifHorsSecteur1HorsOptam: "53.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADQP003",
    designation: "Électrooculographie motrice [EOG motrice]",
    tarifSecteur1Optam: "58.03",
    tarifHorsSecteur1HorsOptam: "56.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP010",
    designation:
      "Électrorétinographie [ERG] par flash lumineux, avec mesure des amplitudes et latences des réponses",
    tarifSecteur1Optam: "53.76",
    tarifHorsSecteur1HorsOptam: "53.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADQP007",
    designation:
      "Électrorétinographie [ERG] par flash lumineux, avec mesure des amplitudes et latences des réponses, et étude des potentiels évoqués visuels [PEV]",
    tarifSecteur1Optam: "80.64",
    tarifHorsSecteur1HorsOptam: "80.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP012",
    designation: "Électrorétinographie [ERG] avec adaptation à l'obscurité",
    tarifSecteur1Optam: "55.8",
    tarifHorsSecteur1HorsOptam: "54.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP011",
    designation:
      "Électrorétinographie [ERG] avec enregistrement des potentiels évoqués visuels [PEV] et électrooculographie [EOG]",
    tarifSecteur1Optam: "112.34",
    tarifHorsSecteur1HorsOptam: "102.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP014",
    designation:
      "Détermination objective de l'acuité visuelle par étude des potentiels évoqués visuels [PEV]",
    tarifSecteur1Optam: "57.29",
    tarifHorsSecteur1HorsOptam: "55.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADQB002",
    designation:
      "Électromyographie [EMG] unilatérale ou bilatérale de détection du muscle releveur de la paupière supérieure et/ou des muscles oculomoteurs, par électrode aiguille",
    tarifSecteur1Optam: "44.58",
    tarifHorsSecteur1HorsOptam: "44.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BZQM001",
    designation: "Échographie de l'œil et/ou de l'orbite en mode B",
    tarifSecteur1Optam: "56.54",
    tarifHorsSecteur1HorsOptam: "47.88",
    regroupement: "ADE",
  },
  {
    codeCCAM: "BZQM002",
    designation: "Échographie de l'œil et/ou de l'orbite avec biométrie",
    tarifSecteur1Optam: "37.2",
    tarifHorsSecteur1HorsOptam: "36.66",
    regroupement: "ADE",
  },
  {
    codeCCAM: "BFQM001",
    designation:
      "Biométrie oculaire par échographie avec mesure des différents paramètres oculaires pour détermination de la puissance d'un implant",
    tarifSecteur1Optam: "33.22",
    tarifHorsSecteur1HorsOptam: "33.22",
    regroupement: "ADE",
  },
  {
    codeCCAM: "BZQP003",
    designation: "Biomicroscopie de l'œil aux ultrasons",
    tarifSecteur1Optam: "54.31",
    tarifHorsSecteur1HorsOptam: "46.58",
    regroupement: "ADE",
  },
  {
    codeCCAM: "BBQH001",
    designation: "Lacrymo-dacryo-cystographie unilatérale ou bilatérale",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BEQP007",
    designation: "Photographies diagnostiques du segment antérieur de l'œil",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BEQP002",
    designation:
      "Photographies diagnostiques du segment antérieur de l'œil après instillation de colorants vitaux",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BEQP004",
    designation:
      "Photographies diagnostiques du segment antérieur de l'œil en biomicroscopie",
    tarifSecteur1Optam: "24.96",
    tarifHorsSecteur1HorsOptam: "24.96",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BJQP001",
    designation:
      "Photographies diagnostiques du regard dans ses différentes positions et au cours d'épreuves de duction",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BJQP003",
    designation:
      "Enregistrement de la cinétique du regard [motricité oculopalpébrale]",
    tarifSecteur1Optam: "23.66",
    tarifHorsSecteur1HorsOptam: "23.66",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BGQP006",
    designation:
      "Rétinographie par stéréophotographie, clichés composés de la périphérie rétinienne ou cliché grand champ supérieur à 60°",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BGQP007",
    designation:
      "Rétinographie en couleur ou en lumière monochromatique, sans injection [Photographie du segment postérieur de l'œil, sans injection]",
    tarifSecteur1Optam: "20.83",
    tarifHorsSecteur1HorsOptam: "19.34",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BGQP009",
    designation:
      "Rétinographie en lumière bleue avec analyse des fibres optiques",
    tarifSecteur1Optam: "26.78",
    tarifHorsSecteur1HorsOptam: "22.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BGQP140",
    designation:
      "Lecture différée d’une rétinographie en couleur, sans la présence du patient",
    tarifSecteur1Optam: "12",
    tarifHorsSecteur1HorsOptam: "11.3",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BZQK001",
    designation:
      "Tomographie unilatérale ou bilatérale de l'œil par scanographie à cohérence optique",
    tarifSecteur1Optam: "56.54",
    tarifHorsSecteur1HorsOptam: "47.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBQL001",
    designation: "Scintigraphie unilatérale ou bilatérale des voies lacrymales",
    tarifSecteur1Optam: "176.85",
    tarifHorsSecteur1HorsOptam: "176.85",
    regroupement: "ADI",
  },
  {
    codeCCAM: "BHQP002",
    designation: "Gonioscopie oculaire",
    tarifSecteur1Optam: "17.28",
    tarifHorsSecteur1HorsOptam: "17.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BHQB001",
    designation:
      "Mesure de la pression [tension] intraoculaire [Tonométrie intraoculaire], sous anesthésie générale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BHQP001",
    designation:
      "Mesure de la pression [tension] intraoculaire avec courbe nycthémérale comportant au moins 6 mesures",
    tarifSecteur1Optam: "41.66",
    tarifHorsSecteur1HorsOptam: "34.65",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BHRP001",
    designation: "Épreuve de provocation du glaucome",
    tarifSecteur1Optam: "45.38",
    tarifHorsSecteur1HorsOptam: "41.65",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP010",
    designation: "Examen de la vision binoculaire",
    tarifSecteur1Optam: "25.32",
    tarifHorsSecteur1HorsOptam: "25.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP011",
    designation:
      "Mesure de l'acuité visuelle potentielle par interférométrie au laser",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP003",
    designation:
      "Mesure de l'acuité visuelle, par étude de la sensibilité au contraste",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP012",
    designation:
      "Évaluation de l'acuité visuelle par la technique du regard préférentiel, avant l'âge de 2 ans",
    tarifSecteur1Optam: "25.3",
    tarifHorsSecteur1HorsOptam: "22.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP015",
    designation:
      "Mesure de la fonction visuelle centrale et scotométrie par ophtalmoscope à balayage laser",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP005",
    designation:
      "Mesure de l'adaptation visuelle à l'obscurité avec courbe d'adaptation",
    tarifSecteur1Optam: "39.43",
    tarifHorsSecteur1HorsOptam: "33.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP002",
    designation:
      "Campimétrie ou périmétrie manuelle ou automatisée, sans programme spécifique de mesure de seuils",
    tarifSecteur1Optam: "35.71",
    tarifHorsSecteur1HorsOptam: "31.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP004",
    designation:
      "Campimétrie ou périmétrie manuelle ou automatisée, avec programmes spécifiques de mesure de seuils",
    tarifSecteur1Optam: "39.43",
    tarifHorsSecteur1HorsOptam: "33.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP008",
    designation:
      "Exploration du sens chromatique [vision des couleurs] par épreuves pseudo-isochromatiques",
    tarifSecteur1Optam: "18.6",
    tarifHorsSecteur1HorsOptam: "18.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP007",
    designation:
      "Exploration du sens chromatique [vision des couleurs] par assortiment",
    tarifSecteur1Optam: "60.26",
    tarifHorsSecteur1HorsOptam: "42.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLQP016",
    designation:
      "Exploration du sens chromatique [vision des couleurs] assistée par ordinateur avec enregistrement et mesures",
    tarifSecteur1Optam: "48.36",
    tarifHorsSecteur1HorsOptam: "38.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BJQP004",
    designation:
      "Nystagmographie optocinétique sans étude des poursuites et des saccades",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BJQP006",
    designation:
      "Nystagmographie optocinétique avec étude des poursuites et des saccades",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BJQP005",
    designation: "Vidéonystagmoscopie",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP004",
    designation:
      "Examen du fond d'œil par ophtalmoscopie indirecte à image inversée, chez le prématuré ou le nouveau-né",
    tarifSecteur1Optam: "48.36",
    tarifHorsSecteur1HorsOptam: "36.92",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP002",
    designation:
      "Examen du fond d'œil par biomicroscopie avec verre de contact",
    tarifSecteur1Optam: "28.29",
    tarifHorsSecteur1HorsOptam: "28.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP003",
    designation: "Examen du fond d'œil, au lit du malade",
    tarifSecteur1Optam: "21.12",
    tarifHorsSecteur1HorsOptam: "21.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BAQP001",
    designation:
      "Examen fonctionnel de la motricité palpébrale avec épreuves pharmacologiques",
    tarifSecteur1Optam: "29.76",
    tarifHorsSecteur1HorsOptam: "24.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BJQP002",
    designation: "Examen fonctionnel de la motricité oculaire",
    tarifSecteur1Optam: "26.24",
    tarifHorsSecteur1HorsOptam: "26.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBQP001",
    designation: "Exploration fonctionnelle des flux lacrymaux",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BCQP001",
    designation: "Capillaroscopie conjonctivale",
    tarifSecteur1Optam: "16.37",
    tarifHorsSecteur1HorsOptam: "14.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEQP001",
    designation: "Fluorométrie de la chambre antérieure de l'œil",
    tarifSecteur1Optam: "68.45",
    tarifHorsSecteur1HorsOptam: "62.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP008",
    designation:
      "Fluorométrie du corps vitré et du segment postérieur de l'œil",
    tarifSecteur1Optam: "68.32",
    tarifHorsSecteur1HorsOptam: "68.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEQP900",
    designation: "Tyndallométrie oculaire [Laser cell flare meter]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDQP002",
    designation: "Vidéo-photo-kératographie topographique",
    tarifSecteur1Optam: "23.81",
    tarifHorsSecteur1HorsOptam: "21.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDQP003",
    designation: "Pachymétrie cornéenne",
    tarifSecteur1Optam: "21.58",
    tarifHorsSecteur1HorsOptam: "19.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDQP004",
    designation:
      "Microscopie spéculaire de la cornée, avec analyse morphométrique et/ou quantitative numérisée de l'endothélium cornéen",
    tarifSecteur1Optam: "43.15",
    tarifHorsSecteur1HorsOptam: "35.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDQP001",
    designation:
      "Kératoesthésiométrie topographique et quantitative [kératesthésie quantitative]",
    tarifSecteur1Optam: "17.28",
    tarifHorsSecteur1HorsOptam: "17.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBQE900",
    designation: "Endoscopie des voies lacrymales",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BCHP001",
    designation: "Empreintes diagnostiques sur la conjonctive oculaire",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDHP001",
    designation:
      "Prélèvement superficiel unilatéral ou bilatéral de la cornée pour frottis et/ou culture",
    tarifSecteur1Optam: "13.1",
    tarifHorsSecteur1HorsOptam: "13.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEHB001",
    designation: "Ponction de la chambre antérieure de l'œil [humeur aqueuse]",
    tarifSecteur1Optam: "39.43",
    tarifHorsSecteur1HorsOptam: "36.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGHB002",
    designation: "Ponction de la choroïde",
    tarifSecteur1Optam: "48.12",
    tarifHorsSecteur1HorsOptam: "48.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGHB001",
    designation: "Ponction du corps vitré à l'aiguille",
    tarifSecteur1Optam: "52.82",
    tarifHorsSecteur1HorsOptam: "43.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGHB003",
    designation: "Ponction du corps vitré par aspiration-section automatisée",
    tarifSecteur1Optam: "122.76",
    tarifHorsSecteur1HorsOptam: "106.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BAHA001",
    designation: "Biopsie unilatérale ou bilatérale de paupière",
    tarifSecteur1Optam: "29.02",
    tarifHorsSecteur1HorsOptam: "24.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBHA001",
    designation: "Biopsie de glande lacrymale",
    tarifSecteur1Optam: "31.72",
    tarifHorsSecteur1HorsOptam: "31.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBHA002",
    designation: "Biopsie de sac lacrymal",
    tarifSecteur1Optam: "31.72",
    tarifHorsSecteur1HorsOptam: "31.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCHA001",
    designation: "Biopsie de la conjonctive",
    tarifSecteur1Optam: "16.98",
    tarifHorsSecteur1HorsOptam: "16.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDHA001",
    designation: "Biopsie de la cornée",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGHA002",
    designation: "Biopsie de la sclère [sclérotique]",
    tarifSecteur1Optam: "43.81",
    tarifHorsSecteur1HorsOptam: "43.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGHA001",
    designation: "Biopsie rétinochoroïdienne",
    tarifSecteur1Optam: "251.32",
    tarifHorsSecteur1HorsOptam: "251.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKHB003",
    designation:
      "Biopsie des organes accessoires de l'œil, par voie orbitaire sans guidage",
    tarifSecteur1Optam: "52.58",
    tarifHorsSecteur1HorsOptam: "52.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKHB002",
    designation:
      "Biopsie des organes accessoires de l'œil, par voie orbitaire avec guidage échographique",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKHB004",
    designation:
      "Biopsie des organes accessoires de l'œil, par voie orbitaire avec guidage radiologique",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKHA002",
    designation:
      "Biopsie de lésion de l'orbite, par abord conjonctivopalpébral",
    tarifSecteur1Optam: "70.2",
    tarifHorsSecteur1HorsOptam: "70.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKHA003",
    designation:
      "Biopsie de lésion de l'orbite, par abord latéral ou par abord ethmoïdal",
    tarifSecteur1Optam: "154.01",
    tarifHorsSecteur1HorsOptam: "133.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BZQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse d'organe accessoire de l'œil",
    tarifSecteur1Optam: "51.28",
    tarifHorsSecteur1HorsOptam: "51.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BHQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'énucléation du bulbe [globe] oculaire ",
    tarifSecteur1Optam: "92.09",
    tarifHorsSecteur1HorsOptam: "92.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exentération de l'orbite",
    tarifSecteur1Optam: "89.44",
    tarifHorsSecteur1HorsOptam: "89.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP001",
    designation:
      "Transillumination transsclérale de lésion du segment postérieur de l'œil, sans désinsertion conjonctivale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGQP005",
    designation:
      "Transillumination transsclérale de lésion du segment postérieur de l'œil, avec désinsertion conjonctivale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BELA001",
    designation: "Pose de repère radioopaque du segment antérieur de l'œil",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGLA001",
    designation: "Pose de repère radioopaque du segment postérieur de l'œil",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGLA002",
    designation:
      "Pose d'un système de repérage d'une lésion choriorétinienne préalable à sa destruction par radiothérapie externe ou protonthérapie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA008",
    designation: "Suture de plaie du sourcil",
    tarifSecteur1Optam: "29.07",
    tarifHorsSecteur1HorsOptam: "25.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA002",
    designation:
      "Suture d'une plaie cutanée d'une paupière, sans atteinte du bord libre",
    tarifSecteur1Optam: "29.07",
    tarifHorsSecteur1HorsOptam: "25.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA005",
    designation:
      "Suture d'une plaie cutanée d'une paupière, avec atteinte du bord libre",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA007",
    designation:
      "Suture de plaies cutanées multiples unilatérales ou bilatérales des paupières, sans atteinte du bord libre",
    tarifSecteur1Optam: "104.9",
    tarifHorsSecteur1HorsOptam: "87.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA006",
    designation:
      "Suture de plaies cutanées multiples unilatérales ou bilatérales des paupières avec atteinte du bord libre, du tarse et/ou du muscle releveur de la paupière supérieure, sans repositionnement du ligament palpébral",
    tarifSecteur1Optam: "148.06",
    tarifHorsSecteur1HorsOptam: "125.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA001",
    designation:
      "Suture de plaies cutanées multiples unilatérales ou bilatérales des paupières avec atteinte du bord libre, du tarse et/ou du muscle releveur de la paupière supérieure, avec repositionnement du ligament palpébral",
    tarifSecteur1Optam: "134.66",
    tarifHorsSecteur1HorsOptam: "117.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAGA001",
    designation: "Ablation secondaire de corps étranger de la paupière",
    tarifSecteur1Optam: "31.99",
    tarifHorsSecteur1HorsOptam: "31.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BANP003",
    designation: "Destruction d'une lésion d'une paupière avec laser",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BANP001",
    designation:
      "Destruction de lésions multiples unilatérales ou bilatérales des paupières avec laser",
    tarifSecteur1Optam: "55.54",
    tarifHorsSecteur1HorsOptam: "49.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BANP004",
    designation:
      "Séance d'épilation ciliaire électrique unilatérale ou bilatérale",
    tarifSecteur1Optam: "21.36",
    tarifHorsSecteur1HorsOptam: "18.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BAFA012",
    designation: "Exérèse de lésion cutanée du sourcil",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "33.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA010",
    designation: "Exérèse de lésion souscutanée du sourcil",
    tarifSecteur1Optam: "42.41",
    tarifHorsSecteur1HorsOptam: "37.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA013",
    designation: "Exérèse de chalazion",
    tarifSecteur1Optam: "32.74",
    tarifHorsSecteur1HorsOptam: "32.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA006",
    designation: "Exérèse non transfixiante d'une lésion d'une paupière",
    tarifSecteur1Optam: "53.57",
    tarifHorsSecteur1HorsOptam: "48.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA005",
    designation:
      "Exérèse non transfixiante de lésions multiples unilatérales de paupière",
    tarifSecteur1Optam: "94.49",
    tarifHorsSecteur1HorsOptam: "81.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA014",
    designation:
      "Exérèse transfixiante de lésion d'une paupière, sans libération du canthus latéral",
    tarifSecteur1Optam: "63.24",
    tarifHorsSecteur1HorsOptam: "54.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA002",
    designation:
      "Exérèse transfixiante de lésion d'une paupière, avec libération du canthus latéral",
    tarifSecteur1Optam: "142.85",
    tarifHorsSecteur1HorsOptam: "135.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA015",
    designation:
      "Résection cutanée, musculaire et/ou graisseuse au niveau d'une paupière, par abord cutané",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA007",
    designation:
      "Résection unilatérale cutanée, musculaire et/ou graisseuse au niveau des paupières, par abord cutané",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA008",
    designation:
      "Résection bilatérale cutanée, musculaire et/ou graisseuse au niveau des paupières supérieures, par abord cutané",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA011",
    designation:
      "Résection bilatérale cutanée, musculaire et/ou graisseuse au niveau des paupières inférieures, par abord cutané",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA009",
    designation:
      "Résection bilatérale cutanée, musculaire et/ou graisseuse au niveau des paupières, par abord cutané",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "190.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA016",
    designation:
      "Résection graisseuse unilatérale des paupières, par abord conjonctival",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA017",
    designation:
      "Résection graisseuse bilatérale des paupières, par abord conjonctival",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA018",
    designation: "Résection cutanée suprasourcilière unilatérale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA003",
    designation: "Résection cutanée suprasourcilière bilatérale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA001",
    designation: "Résection du muscle orbiculaire de l'œil",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA004",
    designation:
      "Réparation de perte de substance cutanée de la paupière par lambeau local ou autogreffe",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA019",
    designation:
      "Réparation de perte de substance de la paupière par autogreffe composée",
    tarifSecteur1Optam: "217.99",
    tarifHorsSecteur1HorsOptam: "205.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA015",
    designation:
      "Réparation de perte de substance de la paupière supérieure par lambeau palpébral inférieur, avec réparation de la zone de prélèvement par suture directe",
    tarifSecteur1Optam: "256.68",
    tarifHorsSecteur1HorsOptam: "227.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA018",
    designation:
      "Réparation de perte de substance de la paupière supérieure par lambeau palpébral inférieur, avec réparation de la zone de prélèvement par lambeau cutané",
    tarifSecteur1Optam: "270.07",
    tarifHorsSecteur1HorsOptam: "235.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA008",
    designation:
      "Réparation de perte de substance de la paupière supérieure par lambeau palpébral inférieur, avec réparation de la zone de prélèvement par lambeau cutané et autogreffe muqueuse ou chondromuqueuse",
    tarifSecteur1Optam: "296.86",
    tarifHorsSecteur1HorsOptam: "251.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA010",
    designation:
      "Réparation de perte de substance de la paupière inférieure par lambeau de rotation temporojugal",
    tarifSecteur1Optam: "237.34",
    tarifHorsSecteur1HorsOptam: "216.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA013",
    designation:
      "Réparation de perte de substance de la paupière inférieure par lambeau régional, avec autogreffe",
    tarifSecteur1Optam: "276.77",
    tarifHorsSecteur1HorsOptam: "239.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA001",
    designation:
      "Réparation de perte de substance de la paupière inférieure par lambeau frontal, avec autogreffe muqueuse ou chondromuqueuse",
    tarifSecteur1Optam: "270.07",
    tarifHorsSecteur1HorsOptam: "235.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAPA001",
    designation: "Section du pédicule [Sevrage] d'un lambeau hétéropalpébral",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMB001",
    designation:
      "Séance de réfection de l'aspect du sourcil par dermopigmentation",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BAMA005",
    designation:
      "Reconstruction d'un sourcil par autogreffe de cuir chevelu ou de sourcil opposé",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA011",
    designation:
      "Reconstruction d'un sourcil par lambeau pédiculé de cuir chevelu ou de sourcil opposé",
    tarifSecteur1Optam: "236.81",
    tarifHorsSecteur1HorsOptam: "217.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA012",
    designation:
      "Reconstruction du bord libre de la paupière avec repositionnement des cils, sans autogreffe ni lambeau",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA016",
    designation:
      "Reconstruction du bord libre de la paupière avec repositionnement des cils, avec autogreffe ou lambeau",
    tarifSecteur1Optam: "106.39",
    tarifHorsSecteur1HorsOptam: "96.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA017",
    designation: "Reformation du pli palpébral supérieur",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAEA001",
    designation:
      "Repositionnement du bord libre de la paupière, sans autogreffe ni lambeau",
    tarifSecteur1Optam: "132.43",
    tarifHorsSecteur1HorsOptam: "111.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAEA002",
    designation:
      "Repositionnement du bord libre de la paupière, avec autogreffe ou lambeau",
    tarifSecteur1Optam: "215.76",
    tarifHorsSecteur1HorsOptam: "204.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAPA004",
    designation: "Canthotomie latérale",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA005",
    designation: "Canthopexie médiale pour dystopie congénitale",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA001",
    designation: "Canthopexie médiale pour dystopie traumatique",
    tarifSecteur1Optam: "140.25",
    tarifHorsSecteur1HorsOptam: "140.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA003",
    designation: "Canthopexie latérale",
    tarifSecteur1Optam: "88.54",
    tarifHorsSecteur1HorsOptam: "68.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA020",
    designation:
      "Canthoplastie médiale sans rétablissement de la continuité des voies lacrymales",
    tarifSecteur1Optam: "106.88",
    tarifHorsSecteur1HorsOptam: "106.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA002",
    designation:
      "Canthoplastie médiale avec rétablissement de la continuité des voies lacrymales",
    tarifSecteur1Optam: "207.17",
    tarifHorsSecteur1HorsOptam: "207.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA009",
    designation: "Canthoplastie latérale",
    tarifSecteur1Optam: "137.64",
    tarifHorsSecteur1HorsOptam: "97.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA003",
    designation:
      "Suture partielle ou totale des bords libres des paupières supérieure et inférieure",
    tarifSecteur1Optam: "71.42",
    tarifHorsSecteur1HorsOptam: "58.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BACA004",
    designation:
      "Ouverture partielle ou totale de blépharorraphie ou de tarsorraphie",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA007",
    designation: "Fixation du sourcil au rebord supraorbitaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA003",
    designation:
      "Plastie en Z de la paupière pour correction de l'épicanthus, sans autogreffe de peau",
    tarifSecteur1Optam: "191.24",
    tarifHorsSecteur1HorsOptam: "191.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAMA007",
    designation:
      "Plastie en Z bilatérale de la paupière pour correction de l'épicanthus, avec autogreffe de peau",
    tarifSecteur1Optam: "282.15",
    tarifHorsSecteur1HorsOptam: "282.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA004",
    designation:
      "Suspension de la paupière supérieure au muscle frontal, sans autogreffe",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA006",
    designation:
      "Suspension unilatérale de la paupière supérieure au muscle frontal, avec autogreffe",
    tarifSecteur1Optam: "260.39",
    tarifHorsSecteur1HorsOptam: "260.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BADA002",
    designation:
      "Suspension bilatérale de la paupière supérieure au muscle frontal, avec autogreffe",
    tarifSecteur1Optam: "268.58",
    tarifHorsSecteur1HorsOptam: "261.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BABA001",
    designation:
      "Raccourcissement du muscle releveur de la paupière supérieure",
    tarifSecteur1Optam: "180.79",
    tarifHorsSecteur1HorsOptam: "175.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA004",
    designation:
      "Résection de la lame profonde du muscle releveur de la paupière supérieure [muscle de Müller] et/ou résection tarsoconjonctivale",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAAA002",
    designation:
      "Allongement du muscle releveur de la paupière supérieure et/ou section de sa lame profonde [muscle de Müller], sans interposition de matériau inerte ni autogreffe",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAAA001",
    designation:
      "Allongement du muscle releveur de la paupière supérieure et/ou section de sa lame profonde [muscle de Müller], avec interposition de matériau inerte ou autogreffe",
    tarifSecteur1Optam: "175.09",
    tarifHorsSecteur1HorsOptam: "175.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAEA003",
    designation: "Transfert de muscle temporal pour réanimation palpébrale",
    tarifSecteur1Optam: "306.29",
    tarifHorsSecteur1HorsOptam: "247.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BALA001",
    designation:
      "Insertion d'un dispositif intrapalpébral pour correction de lagophtalmie",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "180.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAPA003",
    designation:
      "Désinsertion musculaire sans autogreffe, pour correction de rétraction de la paupière inférieure",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAPA005",
    designation:
      "Désinsertion musculaire avec autogreffe, pour correction de rétraction de la paupière inférieure",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BASA001",
    designation: "Fermeture de colobome palpébral",
    tarifSecteur1Optam: "207.17",
    tarifHorsSecteur1HorsOptam: "207.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAPA002",
    designation: "Incision unilatérale ou bilatérale de collection de paupière",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BALB001",
    designation:
      "Séance d'injection unilatérale ou bilatérale de toxine botulique au niveau des paupières",
    tarifSecteur1Optam: "31.99",
    tarifHorsSecteur1HorsOptam: "25.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBLD003",
    designation: "Sondage des voies lacrymales",
    tarifSecteur1Optam: "23.81",
    tarifHorsSecteur1HorsOptam: "21.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBJD001",
    designation: "Lavage des voies lacrymales",
    tarifSecteur1Optam: "19.34",
    tarifHorsSecteur1HorsOptam: "19.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBLD004",
    designation:
      "Intubation monocanaliculaire unilatérale ou bilatérale des voies lacrymales",
    tarifSecteur1Optam: "40.18",
    tarifHorsSecteur1HorsOptam: "35.39",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBLD001",
    designation: "Intubation bicanaliculaire homolatérale des voies lacrymales",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBLD002",
    designation:
      "Pose d'obturateur ou de clou-trou méatique sur un point lacrymal",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBGA001",
    designation:
      "Ablation de corps étranger des voies lacrymales, par abord direct",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBMA003",
    designation:
      "Rétablissement de la continuité des canalicules lacrymaux pour sténose, avec intubation canaliculaire",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBMA002",
    designation:
      "Rétablissement de la continuité des canalicules lacrymaux pour rupture",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBMA004",
    designation:
      "Rétablissement de la continuité des canalicules lacrymaux pour rupture, avec intubation canaliculaire",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBCE001",
    designation: "Dacryo-cysto-rhinostomie [DCR], par endoscopie nasale",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BBCA003",
    designation: "Dacryo-cysto-rhinostomie [DCR], par abord facial",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBCA001",
    designation: "Laco-dacryo-cysto-rhinostomie sans laser",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBPA002",
    designation: "Incision des points et/ou des canalicules lacrymaux",
    tarifSecteur1Optam: "35.71",
    tarifHorsSecteur1HorsOptam: "33.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBPA003",
    designation: "Incision du sac lacrymal",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBPA001",
    designation: "Incision ou exérèse partielle de la glande lacrymale",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBFA002",
    designation: "Exérèse et/ou plastie d'un point lacrymal",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBFA003",
    designation: "Exérèse du sac lacrymal",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBFA004",
    designation: "Exérèse totale de la glande lacrymale sans ostéotomie",
    tarifSecteur1Optam: "142.13",
    tarifHorsSecteur1HorsOptam: "142.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBFA001",
    designation: "Exérèse totale de la glande lacrymale avec ostéotomie",
    tarifSecteur1Optam: "284.56",
    tarifHorsSecteur1HorsOptam: "284.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBLA001",
    designation: "Pose de pompe à larmes",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BBSP001",
    designation:
      "Fermeture d'un point lacrymal, par cautérisation ou avec laser",
    tarifSecteur1Optam: "23.17",
    tarifHorsSecteur1HorsOptam: "23.17",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BCLB001",
    designation:
      "Injection subconjonctivale ou subtenonienne unilatérale ou bilatérale d'agent pharmacologique",
    tarifSecteur1Optam: "16.37",
    tarifHorsSecteur1HorsOptam: "15.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BCLA001",
    designation:
      "Autohémothérapie ou libération d'adhérence subconjonctivale après opération fistulisante",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BCNP001",
    designation: "Destruction de lésion de la conjonctive par agent physique",
    tarifSecteur1Optam: "33.48",
    tarifHorsSecteur1HorsOptam: "27.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BCPA001",
    designation: "Incision de la conjonctive",
    tarifSecteur1Optam: "34.22",
    tarifHorsSecteur1HorsOptam: "28.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCCA001",
    designation: "Suture de plaie de la conjonctive",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA008",
    designation: "Exérèse de lésion de la conjonctive, sans autogreffe",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA004",
    designation:
      "Exérèse de lésion de la conjonctive, avec autogreffe de muqueuse",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA009",
    designation: "Exérèse primitive de ptérygion, sans autogreffe",
    tarifSecteur1Optam: "76.63",
    tarifHorsSecteur1HorsOptam: "66.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA003",
    designation:
      "Exérèse primitive de ptérygion, avec autogreffe de conjonctive ou de muqueuse",
    tarifSecteur1Optam: "106.39",
    tarifHorsSecteur1HorsOptam: "105.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA007",
    designation:
      "Exérèse primitive de ptérygion, avec greffe lamellaire de cornée",
    tarifSecteur1Optam: "276.93",
    tarifHorsSecteur1HorsOptam: "276.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA006",
    designation: "Exérèse de ptérygion récidivant, sans autogreffe",
    tarifSecteur1Optam: "122.02",
    tarifHorsSecteur1HorsOptam: "92.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCFA005",
    designation:
      "Exérèse de ptérygion récidivant, avec autogreffe de conjonctive ou de muqueuse",
    tarifSecteur1Optam: "133.18",
    tarifHorsSecteur1HorsOptam: "121.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCPA005",
    designation:
      "Désinsertion de la conjonctive oculaire au limbe [Péritomie], sans autogreffe",
    tarifSecteur1Optam: "35.71",
    tarifHorsSecteur1HorsOptam: "33.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCPA003",
    designation:
      "Désinsertion de la conjonctive oculaire au limbe [Péritomie], avec autogreffe muqueuse",
    tarifSecteur1Optam: "59.52",
    tarifHorsSecteur1HorsOptam: "52.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCMA001",
    designation:
      "Conjonctivoplastie par autogreffe de conjonctive ou de muqueuse",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCPA004",
    designation: "Libération de symblépharon, sans autogreffe",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCPA002",
    designation:
      "Libération de symblépharon, avec autogreffe de conjonctive ou de muqueuse",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDNP001",
    designation:
      "Destruction de lésion superficielle de la cornée par moyen physique",
    tarifSecteur1Optam: "29.02",
    tarifHorsSecteur1HorsOptam: "24.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDNP002",
    designation: "Abrasion mécanique de l'épithélium de la cornée sans laser",
    tarifSecteur1Optam: "28.27",
    tarifHorsSecteur1HorsOptam: "25.17",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDNP003",
    designation: "Abrasion mécanique de l'épithélium de la cornée avec laser",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDCB001",
    designation:
      "Microponctions [Micropunctures] mécaniques antérieures de la cornée, à visée thérapeutique",
    tarifSecteur1Optam: "21.87",
    tarifHorsSecteur1HorsOptam: "21.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDCA003",
    designation: "Suture d'une plaie linéaire non transfixiante de la cornée",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDCA004",
    designation: "Suture d'une plaie linéaire transfixiante de la cornée",
    tarifSecteur1Optam: "116.81",
    tarifHorsSecteur1HorsOptam: "111.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDCA001",
    designation: "Suture d'une plaie non linéaire de la cornée",
    tarifSecteur1Optam: "150.29",
    tarifHorsSecteur1HorsOptam: "131.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDCA002",
    designation:
      "Suture de plaies multiples unilatérales ou bilatérales de la cornée",
    tarifSecteur1Optam: "171.12",
    tarifHorsSecteur1HorsOptam: "165.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDGP002",
    designation: "Ablation d'un corps étranger superficiel de la cornée",
    tarifSecteur1Optam: "20.9",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDGP003",
    designation:
      "Ablation de corps étrangers superficiels multiples unilatéraux ou bilatéraux de la cornée",
    tarifSecteur1Optam: "26.78",
    tarifHorsSecteur1HorsOptam: "24.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDGA005",
    designation: "Ablation d'un corps étranger profond [stromal] de la cornée",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDGA002",
    designation:
      "Ablation de corps étrangers profonds [stromaux] multiples unilatéraux ou bilatéraux de la cornée",
    tarifSecteur1Optam: "78.38",
    tarifHorsSecteur1HorsOptam: "78.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDGA004",
    designation:
      "Ablation de corps étrangers superficiels et profonds multiples unilatéraux ou bilatéraux de la cornée",
    tarifSecteur1Optam: "78.38",
    tarifHorsSecteur1HorsOptam: "78.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFA002",
    designation: "Kératectomie lamellaire",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFA004",
    designation: "Excision de fistule du limbe de la cornée",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFA003",
    designation:
      "Exérèse de tumeur du limbe de la cornée sans greffe de cornée",
    tarifSecteur1Optam: "54.74",
    tarifHorsSecteur1HorsOptam: "54.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFA001",
    designation:
      "Exérèse de tumeur du limbe de la cornée avec greffe de cornée",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFL001",
    designation:
      "Exérèse de tumeur du limbe de la cornée avec implantation de source de rayonnement",
    tarifSecteur1Optam: "235.13",
    tarifHorsSecteur1HorsOptam: "235.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDMA001",
    designation: "Autokératoplastie par rotation",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDMA002",
    designation:
      "Kératoplastie lamellaire profonde [Transplantation cornéenne lamellaire]",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BCBP001",
    designation: "Recouvrement conjonctival partiel ou total de la cornée",
    tarifSecteur1Optam: "45.17",
    tarifHorsSecteur1HorsOptam: "45.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDMA006",
    designation:
      "Conjonctivokératoplastie par greffe de membrane amniotique humaine",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDMA008",
    designation:
      "Kératoplastie transfixiante [Transplantation cornéenne transfixiante]",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDMA003",
    designation:
      "Kératoplastie transfixiante [Transplantation cornéenne transfixiante], avec extraction extracapsulaire du cristallin",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDMA900",
    designation:
      "Kératoplastie lamellaire avec conjonctivoplastie [Transplantation cornéoconjonctivale]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDLA002",
    designation: "Pose de kératoprothèse en un temps",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDPA001",
    designation:
      "Kératotomie relaxante pour correction de l'astigmatisme cornéen induit",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFP002",
    designation: "Photokératectomie avec laser excimère",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDFP001",
    designation:
      "Photokératectomie réfractive [de confort] avec laser excimère",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDEA002",
    designation: "Épikératophakie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDLA005",
    designation: "Pose d’anneaux intra cornéens à but thérapeutique",
    tarifSecteur1Optam: "139.13",
    tarifHorsSecteur1HorsOptam: "139.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDRP027",
    designation: "Photopolymérisation de la cornée [crosslinking]",
    tarifSecteur1Optam: "112.34",
    tarifHorsSecteur1HorsOptam: "112.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFP003",
    designation: "Kératomileusis in situ avec laser excimère",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BELA002",
    designation:
      "Pose d'implant à but réfractif dans la chambre antérieure de l'œil",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDJP001",
    designation: "Irrigation continue de la cornée et/ou de la conjonctive",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BENA001",
    designation: "Destruction de lésion de l'iris ou du corps ciliaire",
    tarifSecteur1Optam: "90.77",
    tarifHorsSecteur1HorsOptam: "87.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BENA002",
    designation: "Destruction partielle du corps ciliaire",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEPP001",
    designation:
      "Section de synéchies et/ou de brides du segment antérieur de l'œil avec laser",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEPA001",
    designation:
      "Libération de synéchies et/ou de brides du segment antérieur de l'œil, par kératotomie",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BENP001",
    designation:
      "Séance de photocoagulation de l'angle iridocornéen avec laser",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEJB002",
    designation:
      "Évacuation de collection de la chambre antérieure de l'œil, par voie transsclérale ou par voie transcornéenne sans irrigation-aspiration automatisée",
    tarifSecteur1Optam: "62.5",
    tarifHorsSecteur1HorsOptam: "49.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEJB001",
    designation:
      "Évacuation de collection de la chambre antérieure de l'œil, par voie transsclérale ou par voie transcornéenne avec irrigation-aspiration automatisée",
    tarifSecteur1Optam: "99.7",
    tarifHorsSecteur1HorsOptam: "70.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BELB001",
    designation:
      "Injection de substance inerte ou organique dans la chambre antérieure de l'œil, par voie transcornéenne [Reformation de la chambre antérieure]",
    tarifSecteur1Optam: "49.42",
    tarifHorsSecteur1HorsOptam: "49.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEPA004",
    designation:
      "Iridotomie ou iridectomie périphérique ou sectorielle sans laser",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEPP002",
    designation: "Iridotomie avec laser",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BEPB001",
    designation: "Goniotomie oculaire, par voie transsclérale",
    tarifSecteur1Optam: "174.84",
    tarifHorsSecteur1HorsOptam: "154.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEPA003",
    designation: "Trabéculotomie, par sclérotomie",
    tarifSecteur1Optam: "174.1",
    tarifHorsSecteur1HorsOptam: "153.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEPA002",
    designation: "Décollement du corps ciliaire [Cyclodialyse]",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEJB004",
    designation: "Drainage de l'humeur aqueuse de l'œil",
    tarifSecteur1Optam: "241.06",
    tarifHorsSecteur1HorsOptam: "227.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEGA002",
    designation:
      "Ablation de système mécanique de drainage de l'humeur aqueuse",
    tarifSecteur1Optam: "84.48",
    tarifHorsSecteur1HorsOptam: "84.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEMA008",
    designation:
      "Correction chirurgicale d'une fistule ou d'un drainage de l'humeur aqueuse",
    tarifSecteur1Optam: "136.81",
    tarifHorsSecteur1HorsOptam: "136.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEGA003",
    designation:
      "Ablation de corps étranger intraoculaire du segment antérieur avec réparation de plaie perforante de l'œil intéressant au moins 2 des structures suivantes : cornée, iris, cristallin",
    tarifSecteur1Optam: "302.06",
    tarifHorsSecteur1HorsOptam: "300.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEGA004",
    designation:
      "Ablation secondaire de corps étranger du segment antérieur de l'œil",
    tarifSecteur1Optam: "120.53",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA007",
    designation: "Résection ou réintégration secondaire de hernie de l'iris",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA001",
    designation: "Exérèse de lésion de l'iris",
    tarifSecteur1Optam: "134.66",
    tarifHorsSecteur1HorsOptam: "113.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA004",
    designation: "Exérèse de lésion du corps ciliaire",
    tarifSecteur1Optam: "249.98",
    tarifHorsSecteur1HorsOptam: "232.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA009",
    designation: "Iridocyclectomie pour tumeur du corps ciliaire",
    tarifSecteur1Optam: "336.29",
    tarifHorsSecteur1HorsOptam: "282.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA003",
    designation:
      "Exérèse d'une invasion épithéliale de la chambre antérieure de l'œil, sans autogreffe",
    tarifSecteur1Optam: "128.75",
    tarifHorsSecteur1HorsOptam: "128.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA005",
    designation:
      "Exérèse d'une invasion épithéliale de la chambre antérieure de l'œil, avec autogreffe cornéenne ou cornéosclérale",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEFA008",
    designation: "Trabéculectomie [Sclérectomie transfixiante]",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA014",
    designation:
      "Sclérectomie profonde non transfixiante ou sclérokératectomie profonde, sans viscocanaliculoplastie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA900",
    designation:
      "Sclérectomie profonde non transfixiante ou sclérokératectomie profonde, avec viscocanaliculoplastie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEEA001",
    designation: "Corépraxie",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEMA002",
    designation: "Plastie de la pupille ou de l'iris, sans laser",
    tarifSecteur1Optam: "89.28",
    tarifHorsSecteur1HorsOptam: "86.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEMP001",
    designation: "Plastie de la pupille ou de l'iris, avec laser",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BELA340",
    designation:
      "Implantation d'iris artificiel sur œil pseudophaque dans le sulcus en arrière d'un résidu irien et en avant de l'implant à support capsulaire ",
    tarifSecteur1Optam: "129.46",
    tarifHorsSecteur1HorsOptam: "129.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BELA129",
    designation:
      "Implantation d’iris artificiel sur œil pseudophaque dans le sulcus en avant de l’implant à support capsulaire avec fixation de la sclère",
    tarifSecteur1Optam: "267.84",
    tarifHorsSecteur1HorsOptam: "267.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BELA226",
    designation:
      "Implantation d’iris artificiel en arrière d’un résidu irien avec extraction du cristallin et mise en place d’un implant à support capsulaire",
    tarifSecteur1Optam: "234.36",
    tarifHorsSecteur1HorsOptam: "234.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BELA201",
    designation:
      "Implantation d’iris artificiel dans le sulcus avec fixation à la sclère, avec extraction du cristallin et mise en place d’un implant à support capsulaire",
    tarifSecteur1Optam: "316.2",
    tarifHorsSecteur1HorsOptam: "316.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BELA473",
    designation:
      "Implantation d’iris artificiel sur œil aphaque avec fixation à la sclère, avec mise en place d’un implant cristallinien suturé",
    tarifSecteur1Optam: "476.16",
    tarifHorsSecteur1HorsOptam: "476.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEEA670",
    designation: "Repositionnement d’un iris artificiel",
    tarifSecteur1Optam: "238.08",
    tarifHorsSecteur1HorsOptam: "238.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEGA607",
    designation: "Ablation d’un iris artificiel",
    tarifSecteur1Optam: "103.42",
    tarifHorsSecteur1HorsOptam: "103.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BEEA002",
    designation: "Réinsertion de la racine de l'iris",
    tarifSecteur1Optam: "107.14",
    tarifHorsSecteur1HorsOptam: "97.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA003",
    designation:
      "Extraction extracapsulaire manuelle du cristallin, sans implantation de cristallin artificiel",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA002",
    designation:
      "Extraction extracapsulaire manuelle du cristallin, avec implantation de cristallin artificiel dans la chambre postérieure de l'œil",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA008",
    designation:
      "Extraction extracapsulaire du cristallin par phakoémulsification, sans implantation de cristallin artificiel",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA427",
    designation:
      "Extraction extracapsulaire du cristallin par phakoémulsification, avec implantation de cristallin artificiel dans la chambre postérieure de l'œil, sans implantation de dispositif de drainage trabéculaire",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA368",
    designation:
      "Extraction extracapsulaire du cristallin par phakoémulsification, avec implantation de cristallin artificiel dans la chambre postérieure de l'œil, avec implantation de dispositif de drainage trabéculaire ab interno",
    tarifSecteur1Optam: "252.96",
    tarifHorsSecteur1HorsOptam: "252.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA009",
    designation:
      "Extraction intracapsulaire du cristallin, sans implantation de cristallin artificiel",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA006",
    designation:
      "Extraction intracapsulaire ou extracapsulaire du cristallin, avec implantation de cristallin artificiel dans la chambre antérieure de l'œil en cas d'impossibilité d'implantation dans la chambre postérieure",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA010",
    designation:
      "Extraction du cristallin par sclérotomie postérieure [pars plana] [Phakophagie]",
    tarifSecteur1Optam: "212.06",
    tarifHorsSecteur1HorsOptam: "212.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA007",
    designation: "Extraction de cristallin subluxé ou ectopique",
    tarifSecteur1Optam: "231.38",
    tarifHorsSecteur1HorsOptam: "221.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA001",
    designation: "Extraction de cristallin luxé",
    tarifSecteur1Optam: "251.47",
    tarifHorsSecteur1HorsOptam: "233.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFPA002",
    designation:
      "Capsulotomie ou exérèse partielle ou totale de reliquats de la capsule du cristallin, par kératotomie",
    tarifSecteur1Optam: "94.66",
    tarifHorsSecteur1HorsOptam: "94.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFPP001",
    designation:
      "Capsulotomie du cristallin pour cataracte secondaire, avec laser",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFGA005",
    designation:
      "Ablation de matériel implanté dans le segment antérieur de l'œil",
    tarifSecteur1Optam: "141.36",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFLA001",
    designation:
      "Implantation secondaire d'un cristallin artificiel non suturé",
    tarifSecteur1Optam: "140.62",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFLA003",
    designation: "Implantation secondaire d'un cristallin artificiel suturé",
    tarifSecteur1Optam: "200.88",
    tarifHorsSecteur1HorsOptam: "145.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFLA004",
    designation:
      "Implantation secondaire de cristallin artificiel sur un œil pseudophake",
    tarifSecteur1Optam: "136.15",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFKA001",
    designation:
      "Changement de matériel implanté dans le segment antérieur de l'œil",
    tarifSecteur1Optam: "180.79",
    tarifHorsSecteur1HorsOptam: "156.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFEA001",
    designation:
      "Repositionnement de cristallin artificiel ou de lentille intraoculaire",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGLB002",
    designation: "Injection de gaz dans le corps vitré",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGLB001",
    designation: "Injection d'agent pharmacologique dans le corps vitré",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGJB001",
    designation:
      "Évacuation de collection de la choroïde, par voie transsclérale",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP006",
    designation:
      "Destruction de lésion choriorétinienne par photocoagulation transsclérale",
    tarifSecteur1Optam: "123.83",
    tarifHorsSecteur1HorsOptam: "123.83",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP004",
    designation:
      "Séance de destruction de lésion choriorétinienne par photocoagulation transpupillaire avec laser",
    tarifSecteur1Optam: "119.78",
    tarifHorsSecteur1HorsOptam: "113.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP002",
    designation:
      "Séance de destruction de lésion choriorétinienne par cryoapplication transsclérale",
    tarifSecteur1Optam: "109.37",
    tarifHorsSecteur1HorsOptam: "107.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP003",
    designation:
      "Séance de destruction de lésion choriorétinienne par photocoagulation avec laser, à l'aide de verre de contact",
    tarifSecteur1Optam: "101.16",
    tarifHorsSecteur1HorsOptam: "101.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP007",
    designation:
      "Séance de destruction de lésion choriorétinienne par photocoagulation avec laser, à l'aide d'ophtalmoscope indirect",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP005",
    designation:
      "Séance de photocoagulation transpupillaire pour rétinopathie du prématuré",
    tarifSecteur1Optam: "126.48",
    tarifHorsSecteur1HorsOptam: "126.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGLP001",
    designation:
      "Séance de cryoapplication transsclérale pour rétinopathie du prématuré",
    tarifSecteur1Optam: "127.22",
    tarifHorsSecteur1HorsOptam: "117.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP008",
    designation:
      "Séance de photocoagulation choriorétinienne du pôle postérieur, avec laser à argon ou diode",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNP001",
    designation:
      "Séance de photocoagulation choriorétinienne du pôle postérieur, avec laser monochromatique ou laser à colorants",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGRF001",
    designation:
      "Séance de photothérapie dynamique de la région maculaire avec injection de produit photosensibilisant",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGNA001",
    designation:
      "Destruction de lésion choriorétinienne par agent physique, par sclérotomie",
    tarifSecteur1Optam: "233.64",
    tarifHorsSecteur1HorsOptam: "233.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGJA001",
    designation: "Évacuation d'hématome prérétinien avec laser",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGJA002",
    designation: "Évacuation de collection subrétinienne, par rétinotomie",
    tarifSecteur1Optam: "330.17",
    tarifHorsSecteur1HorsOptam: "330.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGPA001",
    designation:
      "Rétinotomie relaxante et/ou dissection de membrane rétrorétinienne",
    tarifSecteur1Optam: "348.19",
    tarifHorsSecteur1HorsOptam: "333.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA002",
    designation:
      "Exérèse de néovaisseaux choroïdiens de topographie maculaire, par rétinotomie",
    tarifSecteur1Optam: "348.19",
    tarifHorsSecteur1HorsOptam: "333.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA004",
    designation: "Exérèse de tumeur de la choroïde sans iridocyclectomie",
    tarifSecteur1Optam: "382.42",
    tarifHorsSecteur1HorsOptam: "353.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA007",
    designation: "Exérèse de tumeur de la choroïde avec iridocyclectomie",
    tarifSecteur1Optam: "462.77",
    tarifHorsSecteur1HorsOptam: "400.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA001",
    designation:
      "Vitrectomie par sclérotomie postérieure, sans coagulation de la rétine",
    tarifSecteur1Optam: "220.97",
    tarifHorsSecteur1HorsOptam: "215.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA009",
    designation:
      "Vitrectomie par sclérotomie postérieure, avec endocoagulation de la rétine avec laser ou par diathermie sans tamponnement",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA006",
    designation:
      "Vitrectomie par sclérotomie postérieure, avec coagulation transsclérale de la rétine sans tamponnement",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA010",
    designation:
      "Vitrectomie par sclérotomie postérieure, avec coagulation de la rétine et tamponnement interne provisoire",
    tarifSecteur1Optam: "302.06",
    tarifHorsSecteur1HorsOptam: "289.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA011",
    designation:
      "Vitrectomie par sclérotomie postérieure, avec extraction du cristallin",
    tarifSecteur1Optam: "330.34",
    tarifHorsSecteur1HorsOptam: "305.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA005",
    designation:
      "Vitrectomie par sclérotomie postérieure, avec dissection du cortex vitréen [pelage] et tamponnement interne par gaz",
    tarifSecteur1Optam: "278.26",
    tarifHorsSecteur1HorsOptam: "275.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA003",
    designation:
      "Vitrectomie avec dissection des membranes, coagulation et tamponnement intraoculaire ou indentation transsclérale, pour rétinopathie du prématuré",
    tarifSecteur1Optam: "550.86",
    tarifHorsSecteur1HorsOptam: "550.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGGA003",
    designation:
      "Ablation de matériel implanté ou de matériel d'indentation du segment postérieur de l'œil, extériorisé",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGGA004",
    designation:
      "Ablation de matériel implanté ou de matériel d'indentation du segment postérieur de l'œil, non extériorisé",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGGA005",
    designation: "Ablation d'huile de silicone intravitréenne",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGGA002",
    designation:
      "Ablation secondaire de corps étranger du segment postérieur de l'œil sans électroaimant",
    tarifSecteur1Optam: "263.38",
    tarifHorsSecteur1HorsOptam: "240.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGGA001",
    designation:
      "Ablation secondaire de corps étranger du segment postérieur de l'œil avec électroaimant",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGBA001",
    designation:
      "Tamponnement interne du segment postérieur de l'œil par fossette colobomateuse ou par trou maculaire, pour décollement de rétine",
    tarifSecteur1Optam: "295.37",
    tarifHorsSecteur1HorsOptam: "259.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA008",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire sans indentation, avec tamponnement interne par gaz",
    tarifSecteur1Optam: "200.88",
    tarifHorsSecteur1HorsOptam: "169.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA004",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire avec indentation sclérale limitée à un quadrant, sans tamponnement",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA003",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire avec indentation sclérale limitée à un quadrant, avec tamponnement interne par gaz",
    tarifSecteur1Optam: "237.34",
    tarifHorsSecteur1HorsOptam: "225.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA002",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire avec indentation sclérale sur plus d'un quadrant, sans tamponnement",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA001",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire avec indentation sclérale sur plus d'un quadrant, avec tamponnement interne par gaz",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA005",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire avec indentation sclérale sur 360° [cerclage], sans tamponnement",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA006",
    designation:
      "Rétinopexie par coagulation par cryoapplication ou avec laser extraoculaire avec indentation sclérale sur 360° [cerclage], avec tamponnement interne par gaz",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGDA007",
    designation: "Indentation transsclérale par ballonnet",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGMA003",
    designation:
      "Modification d'indentation sclérale pour décollement de rétine persistant",
    tarifSecteur1Optam: "391.88",
    tarifHorsSecteur1HorsOptam: "391.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA012",
    designation: "Exérèse de lésion de la sclère",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGCA002",
    designation: "Suture de plaie de la sclère",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGSA002",
    designation: "Fermeture de fistule de la sclère",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGMA900",
    designation:
      "Renforcement scléral postérieur de l'œil par autogreffe tissulaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGMA901",
    designation:
      "Renforcement scléral postérieur de l'œil par matériau de synthèse",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGPA002",
    designation:
      "Dissection de la membrane épirétinienne et/ou de la membrane vitrée [hyaloïde]",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGPA003",
    designation:
      "Section ou libération de brides, de membranes et/ou d'adhérences vitréorétiniennes postopératoires",
    tarifSecteur1Optam: "146.42",
    tarifHorsSecteur1HorsOptam: "146.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGPP002",
    designation: "Vitréosection avec laser",
    tarifSecteur1Optam: "105.65",
    tarifHorsSecteur1HorsOptam: "105.17",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BGMA002",
    designation:
      "Opération rétinovitréenne associant au moins 4 des actes suivants : coagulation, vitrectomie, endocoagulation avec laser, rétinotomie, tamponnement interne, échange fluide-gaz, dissection de brides, rétinectomie",
    tarifSecteur1Optam: "514.85",
    tarifHorsSecteur1HorsOptam: "474.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGMA001",
    designation:
      "Opération rétinovitréenne associant une indentation et au moins 4 des actes suivants : coagulation, vitrectomie, endocoagulation avec laser, rétinotomie, tamponnement interne, échange fluide-gaz, dissection de brides",
    tarifSecteur1Optam: "563.06",
    tarifHorsSecteur1HorsOptam: "502.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHMA001",
    designation:
      "Réparation de plaie linéaire perforante du bulbe [globe] oculaire, avec suture cornéosclérale",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHMA002",
    designation:
      "Réparation de plaie non linéaire perforante du bulbe [globe] oculaire intéressant au moins 3 des structures suivantes : cornée, sclère, iris, corps ciliaire, cristallin, corps vitré, rétine",
    tarifSecteur1Optam: "240.31",
    tarifHorsSecteur1HorsOptam: "218.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA002",
    designation:
      "Éviscération du bulbe [globe] oculaire, sans comblement de la cavité sclérale",
    tarifSecteur1Optam: "110.35",
    tarifHorsSecteur1HorsOptam: "110.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA006",
    designation:
      "Éviscération du bulbe [globe] oculaire, avec comblement de la cavité sclérale",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA004",
    designation:
      "Énucléation du bulbe [globe] oculaire sans mobilisation [no touch]",
    tarifSecteur1Optam: "125.45",
    tarifHorsSecteur1HorsOptam: "125.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA001",
    designation:
      "Énucléation du bulbe [globe] oculaire, sans insertion d'implant ni autogreffe",
    tarifSecteur1Optam: "106.47",
    tarifHorsSecteur1HorsOptam: "106.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA005",
    designation:
      "Énucléation du bulbe [globe] oculaire, avec insertion d'un implant nu dans la gaine du bulbe [capsule de Tenon]",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA003",
    designation:
      "Énucléation du bulbe [globe] oculaire, avec insertion d'un implant habillé dans la gaine du bulbe [capsule de Tenon]",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHGA007",
    designation:
      "Énucléation du bulbe [globe] oculaire, avec insertion d'un implant biocolonisable dans la gaine du bulbe [capsule de Tenon]",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA007",
    designation: "Exentération de l'orbite",
    tarifSecteur1Optam: "242.54",
    tarifHorsSecteur1HorsOptam: "219.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA006",
    designation:
      "Exentération de l'orbite étendue aux structures musculaires adjacentes et/ou aux parois osseuses, sans comblement",
    tarifSecteur1Optam: "328.85",
    tarifHorsSecteur1HorsOptam: "269.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA004",
    designation:
      "Exentération de l'orbite étendue aux structures musculaires adjacentes et/ou aux parois osseuses, avec comblement par autogreffe cutanée et lambeau musculaire temporal",
    tarifSecteur1Optam: "415.15",
    tarifHorsSecteur1HorsOptam: "368.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFA006",
    designation:
      "Prélèvement unilatéral ou bilatéral de cornée avec collerette sclérale sur un sujet décédé, avec pose de prothèse",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BDFA007",
    designation:
      "Prélèvement unilatéral ou bilatéral de cornée avec collerette sclérale au cours d'un prélèvement multiorgane sur un sujet en état de mort encéphalique, avec pose de prothèse",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BHFA001",
    designation:
      "Prélèvement unilatéral ou bilatéral du bulbe [globe] oculaire, au cours d'un prélèvement multiorgane sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJCA001",
    designation: "Suture de plaie des muscles oculomoteurs",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJEA002",
    designation: "Réinsertion posttraumatique de muscle oculomoteur",
    tarifSecteur1Optam: "129.48",
    tarifHorsSecteur1HorsOptam: "129.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA002",
    designation:
      "Renforcement, affaiblissement ou déplacement de l'insertion d'un muscle oculomoteur droit",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA004",
    designation:
      "Renforcement, affaiblissement ou déplacement de l'insertion d'un muscle oculomoteur oblique",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA005",
    designation:
      "Renforcement, affaiblissement ou déplacement unilatéral de l'insertion de 2 muscles oculomoteurs",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA003",
    designation:
      "Renforcement, affaiblissement ou déplacement bilatéral de l'insertion d'un muscle oculomoteur",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA006",
    designation:
      "Renforcement, affaiblissement ou déplacement de l'insertion de 2 muscles oculomoteurs d'un côté et d'un muscle oculomoteur de l'autre",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA007",
    designation:
      "Renforcement, affaiblissement ou déplacement de l'insertion de 4 muscles oculomoteurs ou plus",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA001",
    designation:
      "Renforcement ou affaiblissement unilatéral ou bilatéral de muscle oculomoteur, avec suture ajustable",
    tarifSecteur1Optam: "187.24",
    tarifHorsSecteur1HorsOptam: "187.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA009",
    designation:
      "Renforcement, affaiblissement ou déplacement itératif de l'insertion d'un muscle oculomoteur",
    tarifSecteur1Optam: "177.65",
    tarifHorsSecteur1HorsOptam: "177.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJMA008",
    designation:
      "Renforcement, affaiblissement ou déplacement itératif de l'insertion de 2 muscles oculomoteurs",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJEA001",
    designation: "Transposition de muscles oculomoteurs pour suppléance",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJDA002",
    designation:
      "Myopexie unilatérale rétroéquatoriale des muscles oculomoteurs",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BJDA001",
    designation:
      "Myopexie bilatérale rétroéquatoriale des muscles oculomoteurs",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKLB001",
    designation:
      "Injection thérapeutique intraorbitaire, rétrobulbaire ou latérobulbaire, d'agent pharmacologique",
    tarifSecteur1Optam: "20.09",
    tarifHorsSecteur1HorsOptam: "18.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BJLB901",
    designation:
      "Injection unilatérale ou bilatérale de toxine botulique dans les muscles oculomoteurs",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKJA001",
    designation: "Évacuation de collection de l'orbite",
    tarifSecteur1Optam: "74.4",
    tarifHorsSecteur1HorsOptam: "60.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA005",
    designation:
      "Ablation de corps étranger de l'orbite sans électroaimant, sans orbitotomie osseuse",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA003",
    designation:
      "Ablation de corps étranger de l'orbite sans électroaimant, avec orbitotomie osseuse",
    tarifSecteur1Optam: "274.54",
    tarifHorsSecteur1HorsOptam: "229.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA008",
    designation:
      "Ablation de corps étranger de l'orbite avec électroaimant, sans orbitotomie osseuse",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA002",
    designation:
      "Ablation de corps étranger de l'orbite avec électroaimant, avec orbitotomie osseuse",
    tarifSecteur1Optam: "217.38",
    tarifHorsSecteur1HorsOptam: "217.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKFA002",
    designation:
      "Exérèse de lésion de l'orbite, par abord conjonctivopalpébral",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKFA001",
    designation: "Exérèse de lésion de l'orbite, par abord latéral",
    tarifSecteur1Optam: "248.87",
    tarifHorsSecteur1HorsOptam: "248.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKFA003",
    designation: "Exérèse de lésion de l'orbite, par abord coronal",
    tarifSecteur1Optam: "389.84",
    tarifHorsSecteur1HorsOptam: "389.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKMA003",
    designation:
      "Reconstruction partielle de la cavité orbitaire par autogreffe",
    tarifSecteur1Optam: "257",
    tarifHorsSecteur1HorsOptam: "257",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKMA001",
    designation: "Reconstruction totale de la cavité orbitaire par autogreffe",
    tarifSecteur1Optam: "294.79",
    tarifHorsSecteur1HorsOptam: "294.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKMA002",
    designation:
      "Reconstruction orbitopalpébrale par matériau inerte et/ou autogreffe, après exentération de l'orbite",
    tarifSecteur1Optam: "329.24",
    tarifHorsSecteur1HorsOptam: "329.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKMA004",
    designation:
      "Restauration du volume orbitaire par matériau inerte et/ou autogreffe",
    tarifSecteur1Optam: "230.18",
    tarifHorsSecteur1HorsOptam: "230.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKBA001",
    designation:
      "Comblement de cavité d'exentération de l'orbite par autogreffe cutanée et lambeau musculaire temporal",
    tarifSecteur1Optam: "246.26",
    tarifHorsSecteur1HorsOptam: "230.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKMP001",
    designation:
      "Moulage de la cavité de l'orbite pour fabrication de conformateur ou de prothèse d'expansion orbitaire, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "51.09",
    tarifHorsSecteur1HorsOptam: "51.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKLP001",
    designation:
      "Pose ou changement de conformateur ou de prothèse d'expansion orbitaire",
    tarifSecteur1Optam: "23.02",
    tarifHorsSecteur1HorsOptam: "23.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKLP002",
    designation: "Pose d'une épithèse orbitopalpébrale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKCP001",
    designation:
      "Forage secondaire d'un implant intraorbitaire pour fixation d'une prothèse oculaire",
    tarifSecteur1Optam: "35.6",
    tarifHorsSecteur1HorsOptam: "35.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BKLA002",
    designation:
      "Insertion secondaire d'un implant intraorbitaire, après énucléation ou éviscération du bulbe [globe] oculaire",
    tarifSecteur1Optam: "122.71",
    tarifHorsSecteur1HorsOptam: "122.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKGA001",
    designation:
      "Ablation d'un implant intraorbitaire, après énucléation ou éviscération du bulbe [globe] oculaire",
    tarifSecteur1Optam: "98.21",
    tarifHorsSecteur1HorsOptam: "92.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKKP003",
    designation:
      "Changement d'un implant intraorbitaire, après énucléation ou éviscération du bulbe [globe] oculaire",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKFA004",
    designation:
      "Résection de graisse orbitaire pour décompression de l'orbite, par voie palpébrale",
    tarifSecteur1Optam: "302.06",
    tarifHorsSecteur1HorsOptam: "245.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKLA001",
    designation:
      "Pose de matériau inerte intraorbitaire pour correction d'énophtalmie",
    tarifSecteur1Optam: "283.39",
    tarifHorsSecteur1HorsOptam: "283.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BKLA003",
    designation:
      "Pose d'autogreffe intraorbitaire pour correction d'énophtalmie",
    tarifSecteur1Optam: "367.54",
    tarifHorsSecteur1HorsOptam: "283.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BLRP001",
    designation:
      "Séance de stimulation de la vision binoculaire et de l'amblyopie",
    tarifSecteur1Optam: "23.89",
    tarifHorsSecteur1HorsOptam: "23.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BDMP002",
    designation:
      "Adaptation unilatérale ou bilatérale de lentille cornéenne thérapeutique ou de lentille-pansement",
    tarifSecteur1Optam: "44.16",
    tarifHorsSecteur1HorsOptam: "44.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLMP002",
    designation:
      "Adaptation unilatérale ou bilatérale de lentille de contact souple",
    tarifSecteur1Optam: "81.6",
    tarifHorsSecteur1HorsOptam: "81.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLMP005",
    designation:
      "Adaptation unilatérale ou bilatérale de lentille de contact rigide",
    tarifSecteur1Optam: "88.32",
    tarifHorsSecteur1HorsOptam: "88.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLMP003",
    designation:
      "Adaptation unilatérale de lentille de contact pour kératocône ou astigmatisme irrégulier",
    tarifSecteur1Optam: "71.04",
    tarifHorsSecteur1HorsOptam: "71.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLMP001",
    designation:
      "Adaptation bilatérale de lentille de contact pour kératocône ou astigmatisme irrégulier",
    tarifSecteur1Optam: "88.32",
    tarifHorsSecteur1HorsOptam: "88.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLMP007",
    designation: "Adaptation unilatérale de verre scléral obtenu par moulage",
    tarifSecteur1Optam: "71.04",
    tarifHorsSecteur1HorsOptam: "71.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BLMP006",
    designation: "Adaptation bilatérale de verre scléral obtenu par moulage",
    tarifSecteur1Optam: "88.32",
    tarifHorsSecteur1HorsOptam: "88.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP010",
    designation: "Audiométrie tonale ou vocale",
    tarifSecteur1Optam: "26.18",
    tarifHorsSecteur1HorsOptam: "23.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP015",
    designation: "Audiométrie tonale avec tympanométrie",
    tarifSecteur1Optam: "40.81",
    tarifHorsSecteur1HorsOptam: "40.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP011",
    designation: "Audiométrie vocale dans le bruit",
    tarifSecteur1Optam: "33.11",
    tarifHorsSecteur1HorsOptam: "31.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP012",
    designation: "Audiométrie tonale et vocale",
    tarifSecteur1Optam: "39.27",
    tarifHorsSecteur1HorsOptam: "34.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP002",
    designation: "Audiométrie tonale et vocale avec tympanométrie",
    tarifSecteur1Optam: "53.13",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP005",
    designation: "Audiométrie en hautes fréquences",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP013",
    designation: "Audiométrie avec tests osmotiques",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP016",
    designation: "Audiométrie automatique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP007",
    designation:
      "Audiométrie en champ libre et en cabine chez l'enfant [tests conditionnés]",
    tarifSecteur1Optam: "53.13",
    tarifHorsSecteur1HorsOptam: "42.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDMP001",
    designation:
      "Conditionnement d'un enfant pour test audiométrique sans mesure",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDRP002",
    designation: "Épreuves de dépistage de surdité avant l'âge de 3 ans",
    tarifSecteur1Optam: "48.51",
    tarifHorsSecteur1HorsOptam: "40.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBQD001",
    designation: "Tympanométrie",
    tarifSecteur1Optam: "22.33",
    tarifHorsSecteur1HorsOptam: "21.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQD001",
    designation: "Tubomanométrie auditive [Sonotubomanométrie]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQD002",
    designation: "Sonomanométrie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CEQP001",
    designation: "Électrocochléographie sans anesthésie générale",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CEQP006",
    designation: "Électrocochléographie sous anesthésie générale",
    tarifSecteur1Optam: "80.85",
    tarifHorsSecteur1HorsOptam: "71.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP009",
    designation: "Enregistrement des otoémissions",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDQP004",
    designation: "Enregistrement des produits de distorsion des otoémissions",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CCRD002",
    designation:
      "Électrostimulation transtympanique de l'oreille interne sans anesthésie générale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CCRD001",
    designation:
      "Électrostimulation transtympanique de l'oreille interne sous anesthésie générale, avec enregistrement",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CCRA001",
    designation:
      "Électrostimulation de l'oreille interne avec décollement du lambeau tympanoméatal",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GKQP002",
    designation:
      "Évaluation phoniatrique de la communication chez l'enfant malentendant",
    tarifSecteur1Optam: "79.31",
    tarifHorsSecteur1HorsOptam: "75.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQP005",
    designation:
      "Évaluation phoniatrique de la communication chez l'adulte malentendant",
    tarifSecteur1Optam: "60.06",
    tarifHorsSecteur1HorsOptam: "60.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CDRP001",
    designation: "Électrostimulation des acouphènes",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CERP005",
    designation:
      "Épreuve vestibulaire bicalorique calibrée sans enregistrement [clinique]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CERP001",
    designation:
      "Épreuve vestibulaire bicalorique calibrée avec électronystagmographie ou vidéonystagmographie",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CERP004",
    designation:
      "Épreuve vestibulaire pendulaire ou rotatoire avec électronystagmographie ou vidéonystagmographie",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CERP003",
    designation:
      "Épreuve vestibulaire bicalorique calibrée, pendulaire ou rotatoire avec électronystagmographie ou vidéonystagmographie, et tests oculographiques",
    tarifSecteur1Optam: "84.48",
    tarifHorsSecteur1HorsOptam: "84.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CEQP005",
    designation:
      "Analyse de la posture verticale statique et/ou dynamique sur plateforme de force [Posturographie]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CEQP002",
    designation: "Statokinésimétrie avec stabilométrie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBQE001",
    designation:
      "Examen unilatéral ou bilatéral de l'oreille externe et/ou moyenne, sous microscope ou par endoscopie méatale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBQE900",
    designation: "Fibroendoscopie de l'oreille moyenne, par voie tubaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CAHA001",
    designation:
      "Biopsie unilatérale ou bilatérale de la peau de l'oreille externe",
    tarifSecteur1Optam: "29.9",
    tarifHorsSecteur1HorsOptam: "25.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAHA002",
    designation:
      "Biopsie unilatérale ou bilatérale du cartilage de l'oreille externe",
    tarifSecteur1Optam: "32.04",
    tarifHorsSecteur1HorsOptam: "26.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBQA001",
    designation:
      "Exploration du cavum tympanique [caisse du tympan], avec décollement du lambeau tympanoméatal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAJD001",
    designation:
      "Soin instrumental unilatéral ou bilatéral d'oreille externe, sous microscope",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CAJA001",
    designation: "Évacuation de collection de l'auricule",
    tarifSecteur1Optam: "65.45",
    tarifHorsSecteur1HorsOptam: "42.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA006",
    designation: "Excision de chondrite de l'auricule",
    tarifSecteur1Optam: "78.54",
    tarifHorsSecteur1HorsOptam: "63.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAPA001",
    designation: "Incision de collection du méat acoustique externe",
    tarifSecteur1Optam: "33.11",
    tarifHorsSecteur1HorsOptam: "23.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAGD001",
    designation:
      "Ablation unilatérale ou bilatérale de bouchon de cérumen ou de corps étranger du méat acoustique externe",
    tarifSecteur1Optam: "19.25",
    tarifHorsSecteur1HorsOptam: "17.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CAGD002",
    designation:
      "Extraction unilatérale ou bilatérale de bouchon épidermique du méat acoustique externe",
    tarifSecteur1Optam: "30.03",
    tarifHorsSecteur1HorsOptam: "21.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CAFA009",
    designation:
      "Exérèse de lésion osseuse oblitérante du méat acoustique externe",
    tarifSecteur1Optam: "298.76",
    tarifHorsSecteur1HorsOptam: "225.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA011",
    designation: "Exérèse partielle du méat acoustique externe",
    tarifSecteur1Optam: "83.16",
    tarifHorsSecteur1HorsOptam: "65.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAJA002",
    designation: "Parage et/ou suture de plaie de l'auricule",
    tarifSecteur1Optam: "46.79",
    tarifHorsSecteur1HorsOptam: "33.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CASA001",
    designation:
      "Hémostase secondaire à un acte sur l'oreille externe ou l'oreille moyenne",
    tarifSecteur1Optam: "56.21",
    tarifHorsSecteur1HorsOptam: "41.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA002",
    designation: "Exérèse partielle non transfixiante de l'auricule",
    tarifSecteur1Optam: "46.79",
    tarifHorsSecteur1HorsOptam: "44.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA005",
    designation: "Exérèse partielle transfixiante de l'auricule",
    tarifSecteur1Optam: "95.72",
    tarifHorsSecteur1HorsOptam: "73.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA003",
    designation:
      "Exérèse totale de l'auricule, sans exérèse du cartilage du méat acoustique externe",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA007",
    designation:
      "Exérèse totale de l'auricule et du cartilage du méat acoustique externe",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "149.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA004",
    designation: "Exérèse de l'oreille externe élargie à l'oreille moyenne",
    tarifSecteur1Optam: "364.98",
    tarifHorsSecteur1HorsOptam: "308.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA010",
    designation:
      "Exérèse de l'oreille externe élargie à la glande parotide et à la fosse infratemporale",
    tarifSecteur1Optam: "786.17",
    tarifHorsSecteur1HorsOptam: "639.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA001",
    designation: "Exérèse de l'oreille externe élargie au rocher",
    tarifSecteur1Optam: "770.77",
    tarifHorsSecteur1HorsOptam: "648.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAFA008",
    designation:
      "Exérèse de l'oreille externe élargie au rocher, à la glande parotide et à la fosse infratemporale",
    tarifSecteur1Optam: "950.18",
    tarifHorsSecteur1HorsOptam: "818.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAEA002",
    designation:
      "Repositionnement de tout ou partie de l'auricule, sans anastomose vasculaire",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAEA003",
    designation:
      "Réimplantation de tout ou partie de l'auricule avec anastomoses vasculaires",
    tarifSecteur1Optam: "380.86",
    tarifHorsSecteur1HorsOptam: "380.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA019",
    designation:
      "Réparation de perte de substance partielle de l'auricule par puits de bourgeonnement chondrocutané",
    tarifSecteur1Optam: "44.67",
    tarifHorsSecteur1HorsOptam: "39.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA021",
    designation:
      "Réparation de perte de substance partielle de l'auricule par autogreffe chondrocutanée",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA017",
    designation:
      "Réparation de perte de substance partielle de l'auricule par autogreffe de cartilage de l'auricule controlatéral ou du septum nasal",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA022",
    designation:
      "Réparation de perte de substance partielle de l'auricule par lambeau local cutané ou chondrocutané",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA005",
    designation:
      "Réparation de perte de substance partielle de l'auricule par autogreffe de cartilage costal",
    tarifSecteur1Optam: "333.23",
    tarifHorsSecteur1HorsOptam: "298.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA008",
    designation: "Plastie unilatérale d'oreille décollée",
    tarifSecteur1Optam: "143.93",
    tarifHorsSecteur1HorsOptam: "118.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA013",
    designation: "Plastie bilatérale d'oreille décollée",
    tarifSecteur1Optam: "236.81",
    tarifHorsSecteur1HorsOptam: "190.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA004",
    designation:
      "Reconstruction totale de l'auricule par autogreffe de cartilage costal",
    tarifSecteur1Optam: "601.23",
    tarifHorsSecteur1HorsOptam: "454.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAEA001",
    designation: "Transposition du lobule de l'auricule",
    tarifSecteur1Optam: "131.87",
    tarifHorsSecteur1HorsOptam: "102.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA002",
    designation:
      "Reconstruction du tragus par lambeau cutané et autogreffe de peau",
    tarifSecteur1Optam: "191.24",
    tarifHorsSecteur1HorsOptam: "191.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA012",
    designation:
      "Reconstruction du tragus par autogreffe chondrocutanée de conque controlatérale et autogreffe de peau",
    tarifSecteur1Optam: "191.24",
    tarifHorsSecteur1HorsOptam: "191.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA003",
    designation:
      "Reconstruction du sillon rétroauriculaire par lambeau cutané d'avancement et autogreffe de peau",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "193",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA014",
    designation:
      "Reconstruction du sillon rétroauriculaire par lambeau de fascia temporal ou occipital, et autogreffe de peau",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "247.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA006",
    designation:
      "Reconstruction de l'auricule par lambeau pédiculé de fascia temporal, avec autogreffe chondrocutanée et autogreffe de peau",
    tarifSecteur1Optam: "713.96",
    tarifHorsSecteur1HorsOptam: "563.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA001",
    designation: "Reconstruction totale de l'auricule en un temps",
    tarifSecteur1Optam: "665.75",
    tarifHorsSecteur1HorsOptam: "491.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA007",
    designation:
      "Correction secondaire du résultat esthétique d'une reconstruction totale de l'auricule",
    tarifSecteur1Optam: "407.55",
    tarifHorsSecteur1HorsOptam: "407.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CALP001",
    designation: "Pose d'une épithèse auriculaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CAMA020",
    designation:
      "Reconstruction du méat acoustique externe par lambeau de fascia temporal et autogreffe de peau, sans temps de caisse",
    tarifSecteur1Optam: "318.01",
    tarifHorsSecteur1HorsOptam: "264.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA010",
    designation:
      "Reconstruction du méat acoustique externe par lambeau cutané et/ou autogreffe de peau, sans temps de caisse",
    tarifSecteur1Optam: "231.77",
    tarifHorsSecteur1HorsOptam: "214.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA018",
    designation:
      "Reconstruction du méat acoustique externe par lambeau cutané et/ou autogreffe de peau, avec myringoplastie",
    tarifSecteur1Optam: "522.83",
    tarifHorsSecteur1HorsOptam: "418.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA016",
    designation:
      "Reconstruction du méat acoustique externe par lambeau cutané et/ou autogreffe de peau, avec myringoplastie et ossiculoplastie, sans ouverture du labyrinthe",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "496.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA015",
    designation:
      "Reconstruction du méat acoustique externe par lambeau cutané et/ou autogreffe de peau, avec myringoplastie et ossiculoplastie, avec ouverture du labyrinthe, sans laser",
    tarifSecteur1Optam: "539",
    tarifHorsSecteur1HorsOptam: "510.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA009",
    designation:
      "Reconstruction du méat acoustique externe par lambeau cutané et/ou autogreffe de peau, avec myringoplastie et ossiculoplastie, avec ouverture du labyrinthe, avec laser",
    tarifSecteur1Optam: "549.78",
    tarifHorsSecteur1HorsOptam: "516.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CAMA011",
    designation:
      "Plastie du pore acoustique externe cartilagineux [Méatoplastie]",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "149.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CALD001",
    designation: "Pose d'un conformateur du méat acoustique externe",
    tarifSecteur1Optam: "91.63",
    tarifHorsSecteur1HorsOptam: "79.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CASA002",
    designation:
      "Fermeture de l'oreille externe et de l'oreille moyenne avec suture du pore acoustique externe",
    tarifSecteur1Optam: "398.09",
    tarifHorsSecteur1HorsOptam: "336.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBPD001",
    designation: "Myringotomie unilatérale ou bilatérale",
    tarifSecteur1Optam: "35.42",
    tarifHorsSecteur1HorsOptam: "32.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBLD003",
    designation: "Pose unilatérale d'aérateur transtympanique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBLD001",
    designation: "Pose bilatérale d'aérateur transtympanique",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBGD001",
    designation:
      "Ablation unilatérale ou bilatérale d'aérateur transtympanique en place sur le tympan, sous anesthésie générale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA008",
    designation: "Myringoplastie sans décollement du lambeau tympanoméatal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA009",
    designation:
      "Myringoplastie avec décollement du lambeau tympanoméatal, sans exploration mastoïdoatticale",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA011",
    designation:
      "Myringoplastie avec décollement du lambeau tympanoméatal et exploration mastoïdoatticale",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA005",
    designation:
      "Myringoplastie avec ossiculoplastie, sans exploration mastoïdoatticale",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA007",
    designation:
      "Myringoplastie avec ossiculoplastie et exploration mastoïdoatticale",
    tarifSecteur1Optam: "333.41",
    tarifHorsSecteur1HorsOptam: "325.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMD001",
    designation:
      "Tympanoplastie en technique fermée, avec tympanotomie postérieure",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "456.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMD002",
    designation: "Tympanoplastie secondaire en technique fermée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMD003",
    designation:
      "Tympanoplastie secondaire en technique fermée, avec ossiculoplastie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA002",
    designation:
      "Tympanoplastie secondaire sur technique ouverte, sans reconstruction de cadre",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA004",
    designation:
      "Tympanoplastie secondaire sur technique ouverte, avec reconstruction de cadre",
    tarifSecteur1Optam: "344.19",
    tarifHorsSecteur1HorsOptam: "331.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA004",
    designation: "Exérèse de tympanosclérose sans ouverture du labyrinthe",
    tarifSecteur1Optam: "199.43",
    tarifHorsSecteur1HorsOptam: "185.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA001",
    designation:
      "Exérèse de tympanosclérose sans ouverture du labyrinthe, avec ossiculoplastie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA013",
    designation:
      "Exérèse de tympanosclérose avec ouverture du labyrinthe et rétablissement de l'effet columellaire, sans laser",
    tarifSecteur1Optam: "468.93",
    tarifHorsSecteur1HorsOptam: "429.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA012",
    designation:
      "Exérèse de tympanosclérose avec ouverture du labyrinthe et rétablissement de l'effet columellaire, avec laser",
    tarifSecteur1Optam: "468.93",
    tarifHorsSecteur1HorsOptam: "429.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBLD002",
    designation: "Insufflation unilatérale ou bilatérale de trompe auditive",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBJD001",
    designation:
      "Soin instrumental unilatéral ou bilatéral d'oreille moyenne sous microscope",
    tarifSecteur1Optam: "36.19",
    tarifHorsSecteur1HorsOptam: "29.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBGA001",
    designation:
      "Ablation de corps étranger de l'oreille moyenne avec décollement du lambeau tympanoméatal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA005",
    designation:
      "Exérèse de cholestéatome limitée au cavum tympanique, sans exploration antroatticale",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA006",
    designation:
      "Exérèse de cholestéatome avec tympanoplastie en technique fermée",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "456.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA002",
    designation:
      "Exérèse de cholestéatome avec tympanoplastie en technique fermée et ossiculoplastie",
    tarifSecteur1Optam: "597.52",
    tarifHorsSecteur1HorsOptam: "478.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA007",
    designation:
      "Exérèse de cholestéatome avec tympanoplastie en technique ouverte",
    tarifSecteur1Optam: "520.52",
    tarifHorsSecteur1HorsOptam: "433.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA003",
    designation:
      "Exérèse de cholestéatome avec tympanoplastie en technique ouverte et ossiculoplastie",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "456.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA010",
    designation:
      "Exérèse de tumeur non cholestéatomateuse de l'oreille moyenne, limitée au cavum tympanique",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA008",
    designation:
      "Exérèse de tumeur non cholestéatomateuse de l'oreille moyenne, étendue à l'ensemble des cavités de l'oreille moyenne, sans laser",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "500.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBFA009",
    designation:
      "Exérèse de tumeur non cholestéatomateuse de l'oreille moyenne, étendue à l'ensemble des cavités de l'oreille moyenne, avec laser",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "500.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBPA002",
    designation:
      "Platinotomie sans laser ou platinectomie, avec rétablissement de l'effet columellaire",
    tarifSecteur1Optam: "388.85",
    tarifHorsSecteur1HorsOptam: "382.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBPA003",
    designation:
      "Platinotomie avec laser, avec rétablissement de l'effet columellaire",
    tarifSecteur1Optam: "381.15",
    tarifHorsSecteur1HorsOptam: "378.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA001",
    designation: "Ossiculoplastie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA006",
    designation:
      "Ossiculoplastie pour malformation ossiculaire sans ouverture du labyrinthe",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA010",
    designation:
      "Ossiculoplastie pour malformation ossiculaire avec ouverture du labyrinthe, sans laser",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMA003",
    designation:
      "Ossiculoplastie pour malformation ossiculaire avec ouverture du labyrinthe, avec laser",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBPA001",
    designation: "Déblocage d'une ankylose ossiculaire atticale",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBLA001",
    designation:
      "Pose d'une prothèse auditive implantable dans l'oreille moyenne",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "223.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBMP001",
    designation:
      "Séance d'adaptation et de réglage secondaires d'une prothèse auditive implantée dans l'oreille moyenne",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CBLA002",
    designation:
      "Pose d'un appareillage auditif ostéo-intégré dans l'oreille moyenne, en un temps",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBGA003",
    designation:
      "Ablation d'une prothèse auditive implantée ou d'un appareillage auditif ostéo-intégré",
    tarifSecteur1Optam: "88.55",
    tarifHorsSecteur1HorsOptam: "68.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CBKA001",
    designation:
      "Changement ou repositionnement de prothèse ossiculaire après platinotomie ou platinectomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA008",
    designation: "Évidement pétromastoïdien",
    tarifSecteur1Optam: "521.29",
    tarifHorsSecteur1HorsOptam: "420.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA014",
    designation: "Mastoïdectomie ou atticomastoïdectomie totale",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCPA001",
    designation:
      "Décompression intralabyrinthique par abord des fenêtres, sans laser",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCPA002",
    designation:
      "Décompression intralabyrinthique par abord des fenêtres, avec laser",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCPA003",
    designation: "Décompression ou dérivation du sac endolymphatique",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCCA002",
    designation:
      "Ouverture sans laser et occlusion du conduit semicirculaire postérieur",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCCA001",
    designation:
      "Ouverture avec laser et occlusion du conduit semicirculaire postérieur",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCNB001",
    designation:
      "Destruction du labyrinthe par injection d'agent pharmacologique, par voie transtympanique",
    tarifSecteur1Optam: "41.58",
    tarifHorsSecteur1HorsOptam: "32.19",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CCFA001",
    designation: "Labyrinthectomie partielle ou totale, par abord direct",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CCSA001",
    designation: "Fermeture de fistule de périlymphe",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CDLA004",
    designation: "Pose d'un implant auditif à électrodes extracochléaires",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CDLA003",
    designation: "Pose d'un implant auditif à électrodes intracochléaires",
    tarifSecteur1Optam: "497.72",
    tarifHorsSecteur1HorsOptam: "497.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CDLA002",
    designation: "Pose d'un implant auditif à électrodes du tronc cérébral",
    tarifSecteur1Optam: "764.3",
    tarifHorsSecteur1HorsOptam: "764.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CDGA001",
    designation: "Ablation d'un implant auditif à électrodes intracochléaires ",
    tarifSecteur1Optam: "169.45",
    tarifHorsSecteur1HorsOptam: "169.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "CDMP002",
    designation:
      "Séance d'adaptation et de réglage secondaires d'implant auditif à électrodes intracochléaires ou à électrodes du tronc cérébral",
    tarifSecteur1Optam: "91.46",
    tarifHorsSecteur1HorsOptam: "91.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "CERP002",
    designation:
      "Séance de rééducation de la fonction vestibulaire labyrinthique",
    tarifSecteur1Optam: "48.51",
    tarifHorsSecteur1HorsOptam: "33.78",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP003",
    designation: "Électrocardiographie sur au moins 12 dérivations",
    tarifSecteur1Optam: "14.26",
    tarifHorsSecteur1HorsOptam: "13.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP002",
    designation: "Électrocardiographie à haute amplification",
    tarifSecteur1Optam: "14.26",
    tarifHorsSecteur1HorsOptam: "13.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP006",
    designation: "Vectocardiographie",
    tarifSecteur1Optam: "42.79",
    tarifHorsSecteur1HorsOptam: "30.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP005",
    designation:
      "Électrocardiographie sur au moins 2 dérivations, avec enregistrement continu pendant au moins 24 heures",
    tarifSecteur1Optam: "77.01",
    tarifHorsSecteur1HorsOptam: "77.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP001",
    designation:
      "Électrocardiographie, avec enregistrement événementiel déclenché et télétransmission",
    tarifSecteur1Optam: "14.26",
    tarifHorsSecteur1HorsOptam: "13.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP004",
    designation:
      "Surveillance continue de l'électrocardiogramme par oscilloscopie et/ou télésurveillance, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQP007",
    designation:
      "Surveillance continue de l'électrocardiogramme par oscilloscopie et/ou télésurveillance, avec surveillance continue de la pression intraartérielle et/ou de la saturation artérielle en oxygène par méthodes non effractives, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQD001",
    designation:
      "Exploration électrophysiologique de l'oreillette, par voie œsophagienne",
    tarifSecteur1Optam: "77.68",
    tarifHorsSecteur1HorsOptam: "77.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQF003",
    designation:
      "Exploration électrophysiologique cardiaque par sonde intracavitaire droite, par voie veineuse transcutanée",
    tarifSecteur1Optam: "179.95",
    tarifHorsSecteur1HorsOptam: "179.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQF004",
    designation:
      "Exploration électrophysiologique cardiaque par sonde intracavitaire droite par voie veineuse transcutanée, avec manœuvres provocatrices d'une tachycardie à l'étage atrial",
    tarifSecteur1Optam: "232.79",
    tarifHorsSecteur1HorsOptam: "232.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQF001",
    designation:
      "Exploration électrophysiologique cardiaque par sonde intracavitaire droite par voie veineuse transcutanée, avec manœuvres provocatrices d'une tachycardie à l'étage ventriculaire",
    tarifSecteur1Optam: "237.97",
    tarifHorsSecteur1HorsOptam: "237.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQF002",
    designation:
      "Exploration électrophysiologique cardiaque par sonde intracavitaire droite par voie veineuse transcutanée, avec manœuvres provocatrices d'une tachycardie à l'étage atrial et à l'étage ventriculaire",
    tarifSecteur1Optam: "241.97",
    tarifHorsSecteur1HorsOptam: "241.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEQF005",
    designation:
      "Exploration électrophysiologique cardiaque par sondes intracavitaires droite et gauche par voie vasculaire transcutanée, avec manœuvres provocatrices d'une tachycardie",
    tarifSecteur1Optam: "250.41",
    tarifHorsSecteur1HorsOptam: "250.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQF006",
    designation:
      "Mesure et enregistrement des pressions du cœur droit et de l'artère pulmonaire, sans injection de produit de contraste, par voie veineuse transcutanée",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQH001",
    designation:
      "Mesure et enregistrement des pressions du cœur droit et de l'artère pulmonaire, avec injection de produit de contraste, par voie veineuse transcutanée",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQF002",
    designation:
      "Mesure et enregistrement des pressions du cœur gauche et de l'aorte, sans injection de produit de contraste, par voie artérielle transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQH002",
    designation:
      "Mesure et enregistrement des pressions du cœur gauche et de l'aorte, avec injection de produit de contraste, par voie artérielle transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQF001",
    designation:
      "Mesure et enregistrement des pressions du cœur droit, de l'artère pulmonaire et du cœur gauche, sans injection de produit de contraste, par voie veineuse transcutanée et par voie artérielle transcutanée ou cathétérisme du foramen ovale",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQH005",
    designation:
      "Mesure et enregistrement des pressions du cœur droit, de l'artère pulmonaire et du cœur gauche, avec injection de produit de contraste, par voie veineuse transcutanée et par voie artérielle transcutanée ou cathétérisme du foramen ovale, avant l'âge de 24 mois",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQH006",
    designation:
      "Mesure et enregistrement des pressions du cœur droit, de l'artère pulmonaire et du cœur gauche, avec injection de produit de contraste, par voie veineuse transcutanée et par voie artérielle transcutanée ou cathétérisme du foramen ovale, à l'âge de 24 mois ou plus",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQF004",
    designation:
      "Mesure et enregistrement des pressions du cœur droit, de l'artère pulmonaire et du cœur gauche, sans injection de produit de contraste, par voie veineuse transcutanée avec perforation du septum interatrial",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQH004",
    designation:
      "Mesure et enregistrement des pressions du cœur droit, de l'artère pulmonaire et du cœur gauche, avec injection de produit de contraste, par voie veineuse transcutanée avec perforation du septum interatrial",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQF003",
    designation:
      "Mesure et enregistrement des pressions intravasculaires pulmonaires et systémiques et des différences artérioveineuses des contenus artériels en oxygène, à l'état basal et après administration d'agent pharmacologique vasodilatateur, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP008",
    designation:
      "Enregistrement ambulatoire discontinu de la pression intraartérielle par méthode non effractive pendant au moins 24 heures [MAPA] [Holter tensionnel]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQM006",
    designation:
      "Mesure de la pression intraartérielle d'un membre en au moins 3 points, par doppler transcutané ou pléthysmographie",
    tarifSecteur1Optam: "21.12",
    tarifHorsSecteur1HorsOptam: "21.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP007",
    designation:
      "Mesure de la pression intraartérielle digitale à la main et/ou au pied",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQRP008",
    designation:
      "Épreuve d'inclinaison [Tilt test] avec étude des variations de la pression intraartérielle et de la fréquence cardiaque",
    tarifSecteur1Optam: "62.09",
    tarifHorsSecteur1HorsOptam: "50.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQF007",
    designation:
      "Mesure directe unilatérale ou bilatérale de la pression intraveineuse, par ponction transcutanée d'une veine du pied",
    tarifSecteur1Optam: "32.25",
    tarifHorsSecteur1HorsOptam: "21.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENLF001",
    designation:
      "Pose de dispositif intraartériel de surveillance de la pression intraartérielle",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLF004",
    designation:
      "Pose de dispositif de mesure des pressions du cœur droit et du débit cardiaque, par voie veineuse transcutanée",
    tarifSecteur1Optam: "94.8",
    tarifHorsSecteur1HorsOptam: "71.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQJ001",
    designation:
      "Surveillance hémodynamique continue par doppler par voie œsophagienne, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQM001",
    designation:
      "Surveillance hémodynamique continue par échocardiographie transthoracique itérative, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP011",
    designation:
      "Surveillance continue de la pression intraartérielle et/ou de la pression intraveineuse centrale par méthodes effractives, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP012",
    designation:
      "Surveillance continue des pressions du cœur droit avec mesure du débit cardiaque ou de la fraction d'éjection du ventricule droit, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP014",
    designation:
      "Surveillance continue des pressions du cœur droit avec mesure du débit cardiaque ou de la fraction d'éjection du ventricule droit, avec surveillance continue de la pression intraartérielle par méthode effractive, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DZQM006",
    designation:
      "Échographie-doppler transthoracique du cœur et des vaisseaux intrathoraciques",
    tarifSecteur1Optam: "96.49",
    tarifHorsSecteur1HorsOptam: "96.49",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ009",
    designation:
      "Échographie-doppler transthoracique du cœur et des vaisseaux intrathoraciques, avec injection intraveineuse de produit de contraste ultrasonore ne franchissant pas le poumon",
    tarifSecteur1Optam: "107.65",
    tarifHorsSecteur1HorsOptam: "107.65",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ001",
    designation:
      "Échographie-doppler du cœur et des vaisseaux intrathoraciques, par voie œsophagienne [Échocardiographie-doppler transœsophagienne]",
    tarifSecteur1Optam: "143.08",
    tarifHorsSecteur1HorsOptam: "143.08",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ011",
    designation:
      "Échographie-doppler du cœur et des vaisseaux intrathoraciques par voie œsophagienne, avec injection intraveineuse de produit de contraste ultrasonore ne franchissant pas le poumon",
    tarifSecteur1Optam: "152.16",
    tarifHorsSecteur1HorsOptam: "152.16",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ008",
    designation:
      "Échographie-doppler transthoracique et échographie-doppler par voie œsophagienne du cœur et des vaisseaux intrathoraciques",
    tarifSecteur1Optam: "160.11",
    tarifHorsSecteur1HorsOptam: "160.11",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ010",
    designation:
      "Échographie-doppler transthoracique et échographie-doppler par voie œsophagienne du cœur et des vaisseaux intrathoraciques, avec injection intraveineuse de produit de contraste ultrasonore ne franchissant pas le poumon",
    tarifSecteur1Optam: "168.39",
    tarifHorsSecteur1HorsOptam: "168.39",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQM005",
    designation:
      "Échographie-doppler transthoracique du cœur et des vaisseaux intrathoraciques, au lit du malade",
    tarifSecteur1Optam: "111.71",
    tarifHorsSecteur1HorsOptam: "111.71",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ006",
    designation:
      "Échographie-doppler du cœur et des vaisseaux intrathoraciques par voie œsophagienne, au lit du malade",
    tarifSecteur1Optam: "158.16",
    tarifHorsSecteur1HorsOptam: "158.16",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DAQM003",
    designation:
      "Échocardiographie transthoracique continue avec épreuve pharmacologique de stress, pour étude de la viabilité et/ou de l'ischémie du myocarde",
    tarifSecteur1Optam: "165.31",
    tarifHorsSecteur1HorsOptam: "165.31",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQM002",
    designation:
      "Échocardiographie transthoracique continue avec épreuve de stress sur lit ergométrique",
    tarifSecteur1Optam: "170.66",
    tarifHorsSecteur1HorsOptam: "170.66",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DBQM001",
    designation:
      "Échographie-doppler transthoracique continue du cœur avec épreuve pharmacologique de stress, pour étude de valvopathie [valvulopathie]",
    tarifSecteur1Optam: "166.28",
    tarifHorsSecteur1HorsOptam: "166.28",
    regroupement: "ADE",
  },
  {
    codeCCAM: "BZQM003",
    designation:
      "Échographie-doppler unilatérale ou bilatérale de l'œil et/ou de l'orbite",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EBQM001",
    designation:
      "Échographie-doppler des artères cervicocéphaliques extracrâniennes, sans mesure de l'épaisseur de l'intima-média",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EBQM900",
    designation:
      "Échographie-doppler des artères cervicocéphaliques extracrâniennes, avec mesure de l'épaisseur de l'intima-média",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EBQM002",
    designation:
      "Échographie-doppler des artères cervicocéphaliques extracrâniennes, avec échographie-doppler des artères des membres inférieurs",
    tarifSecteur1Optam: "104.9",
    tarifHorsSecteur1HorsOptam: "104.9",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EBQM003",
    designation:
      "Échographie-doppler des vaisseaux cervicocéphaliques extracrâniens avec échographie-doppler transcrânienne des vaisseaux intracrâniens",
    tarifSecteur1Optam: "94.16",
    tarifHorsSecteur1HorsOptam: "94.16",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EAQM004",
    designation:
      "Échographie-doppler transcrânienne des vaisseaux intracrâniens, sans épreuve pharmacodynamique",
    tarifSecteur1Optam: "54.99",
    tarifHorsSecteur1HorsOptam: "54.99",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EAQM003",
    designation:
      "Échographie-doppler transcrânienne des vaisseaux intracrâniens, avec épreuve pharmacodynamique",
    tarifSecteur1Optam: "54.99",
    tarifHorsSecteur1HorsOptam: "54.99",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EAQM005",
    designation:
      "Échographie-doppler transfontanellaire des vaisseaux intracrâniens",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EAQM002",
    designation:
      "Monitorage de la circulation artérielle intracrânienne par doppler pulsé transcrânien",
    tarifSecteur1Optam: "43.62",
    tarifHorsSecteur1HorsOptam: "43.62",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EAQM001",
    designation:
      "Surveillance continue de la circulation artérielle intracrânienne par doppler transcrânien, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ECQM002",
    designation: "Échographie-doppler des artères des membres supérieurs",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ECQM001",
    designation:
      "Examen doppler continu des artères des membres supérieurs avec épreuve dynamique [test d'Allen] et manœuvres positionnelles",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DGQM002",
    designation:
      "Échographie-doppler de l'aorte abdominale, de ses branches viscérales et des artères iliaques",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DGQM001",
    designation:
      "Échographie-doppler de l'aorte abdominale, de ses branches viscérales, des artères iliaques et des artères des membres inférieurs",
    tarifSecteur1Optam: "76.59",
    tarifHorsSecteur1HorsOptam: "76.59",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EDQM001",
    designation:
      "Échographie-doppler des artères iliaques et des artères des membres inférieurs",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EFQM001",
    designation: "Échographie-doppler des veines des membres supérieurs",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DHQM001",
    designation:
      "Échographie-doppler transthoracique de la veine cave supérieure et de ses affluents chez l'enfant",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EJQM004",
    designation:
      "Échographie-doppler des veines des membres inférieurs et des veines iliaques, sans marquage cutané",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EJQM001",
    designation:
      "Échographie-doppler des veines des membres inférieurs, avec marquage cutané ou cartographie hémodynamique",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EJQM003",
    designation:
      "Échographie-doppler des veines des membres inférieurs et des veines iliaques, pour recherche de thrombose veineuse profonde",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DHQM002",
    designation:
      "Échographie-doppler de la veine cave inférieure et de ses affluents",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ELQM001",
    designation: "Échographie-doppler des vaisseaux digestifs",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ELQJ002",
    designation:
      "Échographie-doppler des vaisseaux pelviens et péniens, avec épreuve pharmacodynamique par injection transcutanée intracaverneuse",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ELQM002",
    designation:
      "Échographie-doppler unilatérale ou bilatérale des vaisseaux des bourses et du cordon spermatique",
    tarifSecteur1Optam: "37.05",
    tarifHorsSecteur1HorsOptam: "37.05",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EZQM001",
    designation:
      "Échographie-doppler d'une fistule artérioveineuse pour circulation extracorporelle",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EZQM002",
    designation:
      "Échographie-doppler pour repérage préopératoire de pédicule vasculaire d'un lambeau de réparation",
    tarifSecteur1Optam: "39.45",
    tarifHorsSecteur1HorsOptam: "39.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DDQH009",
    designation:
      "Artériographie coronaire sans ventriculographie gauche, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH012",
    designation:
      "Artériographie coronaire avec ventriculographie gauche, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH010",
    designation:
      "Artériographie coronaire avec ventriculographie gauche et artériographie thoracique [mammaire] interne unilatérale ou bilatérale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH006",
    designation:
      "Angiographie de pontage coronaire, par voie artérielle transcutanée",
    tarifSecteur1Optam: "172.8",
    tarifHorsSecteur1HorsOptam: "172.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH014",
    designation:
      "Artériographie coronaire avec angiographie d'un pontage coronaire sans ventriculographie gauche, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH011",
    designation:
      "Artériographie coronaire avec angiographie d'un pontage coronaire et ventriculographie gauche, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH013",
    designation:
      "Artériographie coronaire avec angiographie de plusieurs pontages coronaires sans ventriculographie gauche, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DDQH015",
    designation:
      "Artériographie coronaire avec angiographie de plusieurs pontages coronaires et ventriculographie gauche, par voie artérielle transcutanée",
    tarifSecteur1Optam: "259.2",
    tarifHorsSecteur1HorsOptam: "259.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH006",
    designation:
      "Artériographie globale de l'aorte thoracique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH007",
    designation:
      "Artériographie globale de la crosse de l'aorte et de ses branches cervicocéphaliques [Gerbe aortique], par voie artérielle transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH005",
    designation:
      "Artériographie globale de l'aorte thoracique et abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH002",
    designation:
      "Artériographie globale de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH001",
    designation:
      "Artériographie globale de l'aorte abdominale et des membres inférieurs, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH003",
    designation:
      "Artériographie de l'aorte abdominale et des membres inférieurs, par injection intraaortique transcutanée lombale",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DGQH004",
    designation:
      "Artériographie de l'aorte et de ses branches, par injection intraveineuse transcutanée",
    tarifSecteur1Optam: "144",
    tarifHorsSecteur1HorsOptam: "144",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DFQH001",
    designation:
      "Artériographie sélective du tronc et/ou des branches de l'artère pulmonaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DFQH002",
    designation:
      "Artériographie hypersélective des artères pulmonaires, par voie veineuse transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH015",
    designation:
      "Artériographie sélective ou hypersélective d'artère intrathoracique à destination pariétale et/ou viscérale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH016",
    designation:
      "Artériographie suprasélective d'artère intrathoracique à destination pariétale et/ou viscérale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH010",
    designation:
      "Artériographie d'un axe cervicocéphalique, par injection intraartérielle transcutanée unique",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH008",
    designation:
      "Artériographie de plusieurs axes cervicocéphaliques, par injections intraartérielles transcutanées multiples",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH011",
    designation:
      "Artériographie sélective d'un ou 2 axes cervicocéphaliques, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH002",
    designation:
      "Artériographie sélective de 3 axes cervicocéphaliques ou plus, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH005",
    designation:
      "Artériographie hypersélective cervicocéphalique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH007",
    designation:
      "Artériographie suprasélective cervicocéphalique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH003",
    designation:
      "Artériographie cervicocéphalique par voie vasculaire transcutanée, pour diagnostic de mort cérébrale",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH006",
    designation:
      "Artériographie du membre supérieur par injection intraartérielle transcutanée, sans manœuvre positionnelle",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH003",
    designation:
      "Artériographie unilatérale de la main, par injection intraartérielle transcutanée",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH007",
    designation:
      "Artériographie bilatérale de la main, par injection intraartérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH004",
    designation:
      "Artériographie unilatérale du membre supérieur par voie artérielle ou injection intraartérielle transcutanée, avec manœuvre positionnelle",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH001",
    designation:
      "Artériographie bilatérale du membre supérieur par voie artérielle ou injection intraartérielle transcutanée, avec manœuvre positionnelle",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH005",
    designation:
      "Artériographie sélective ou hypersélective du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH002",
    designation:
      "Artériographie suprasélective du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH013",
    designation:
      "Artériographie sélective ou hypersélective d'un segment de la moelle épinière, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH012",
    designation:
      "Artériographie sélective ou hypersélective de la totalité de la moelle épinière, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH014",
    designation:
      "Artériographie suprasélective de la moelle épinière, par voie artérielle transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EDQH008",
    designation:
      "Artériographie sélective ou hypersélective d'une branche digestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EDQH006",
    designation:
      "Artériographie sélective et/ou hypersélective de plusieurs branches digestives de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EDQH007",
    designation:
      "Artériographie suprasélective de branche digestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EDQH003",
    designation:
      "Artériographie sélective ou hypersélective d'une branche extradigestive de l'aorte abdominale ou d'une branche de l'artère iliaque interne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EDQH005",
    designation:
      "Artériographie sélective et/ou hypersélective de plusieurs branches extradigestives de l'aorte abdominale ou de plusieurs branches de l'artère iliaque interne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EDQH001",
    designation:
      "Artériographie suprasélective de branche extradigestive de l'aorte abdominale ou de branche de l'artère iliaque interne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EEQH006",
    designation:
      "Artériographie unilatérale du membre inférieur, par injection intraartérielle fémorale transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EEQH001",
    designation:
      "Artériographie bilatérale de membre inférieur, par injection intraartérielle fémorale transcutanée bilatérale",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EEQH005",
    designation:
      "Artériographie globale du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EEQH002",
    designation:
      "Artériographie sélective ou hypersélective du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EEQH004",
    designation:
      "Artériographie suprasélective du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EEQH003",
    designation:
      "Artériographie du pied, par injection intraartérielle ou voie artérielle transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH009",
    designation:
      "Phlébographie d'un axe cervicocéphalique, par injection intrajugulaire transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH001",
    designation:
      "Phlébographie globale d'un axe cervicocéphalique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH002",
    designation:
      "Phlébographie sélective de la veine brachiocéphalique ou de la veine cave supérieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH007",
    designation:
      "Phlébographie hypersélective de la veine brachiocéphalique ou de la veine cave supérieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH001",
    designation:
      "Phlébographie sélective du membre supérieur par voie veineuse transcutanée, sans étude des troncs veineux proximaux",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH006",
    designation:
      "Phlébographie unilatérale du membre supérieur par injection intraveineuse transcutanée, sans étude des troncs veineux proximaux",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH005",
    designation:
      "Phlébographie unilatérale du membre supérieur par injection intraveineuse ou voie veineuse transcutanée, avec étude des troncs veineux proximaux et de la veine cave supérieure",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH004",
    designation:
      "Phlébographie bilatérale du membre supérieur par injection intraveineuse transcutanée, sans étude des troncs veineux proximaux",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EFQH003",
    designation:
      "Phlébographie bilatérale du membre supérieur par injection intraveineuse transcutanée, avec étude des troncs veineux proximaux et de la veine cave supérieure",
    tarifSecteur1Optam: "129.6",
    tarifHorsSecteur1HorsOptam: "129.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH003",
    designation:
      "Phlébographie de la veine cave supérieure [Cavographie supérieure], par injection intraveineuse transcutanée",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH006",
    designation:
      "Phlébographie globale de la veine cave supérieure [Cavographie supérieure], par voie veineuse transcutanée",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EJQH006",
    designation:
      "Phlébographie unilatérale du membre inférieur, par injection intraveineuse transcutanée au pied",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EJQH004",
    designation:
      "Phlébographie bilatérale du membre inférieur, par injection intraveineuse transcutanée au pied",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EJQH002",
    designation:
      "Phlébographie bilatérale du membre inférieur par injection intraveineuse transcutanée au pied, avec iliocavographie par injection intraveineuse transcutanée fémorale bilatérale",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EJQH005",
    designation:
      "Phlébographie rétrograde du membre inférieur, par injection intraveineuse transcutanée poplitée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EJQH003",
    designation:
      "Phlébographie rétrograde du membre inférieur, par injection intraveineuse transcutanée fémorale homolatérale ou par voie veineuse fémorale controlatérale",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EJQH001",
    designation:
      "Varicographie du membre inférieur, par injection intraveineuse transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH005",
    designation:
      "Phlébographie des veines iliaque et cave inférieure [Iliocavographie], par injection intraveineuse transcutanée fémorale unilatérale ou bilatérale",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH002",
    designation:
      "Phlébographie de la veine cave inférieure [Cavographie inférieure], par voie veineuse transcutanée",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH004",
    designation:
      "Phlébographie sélective d'une branche de la veine iliaque commune ou de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH001",
    designation:
      "Phlébographie sélective de plusieurs branches des veines iliaques communes et/ou de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EHQH001",
    designation:
      "Phlébographie sélective de veine hépatique [sushépatique], par voie veineuse transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DHQH007",
    designation:
      "Phlébographie hypersélective d'une branche de la veine iliaque commune ou de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EZQH003",
    designation:
      "Angiographie d'un accès vasculaire artérioveineux d'un membre, par injection intravasculaire transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EZQH002",
    designation:
      "Angiographie d'un accès vasculaire artérioveineux d'un membre, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EKQH002",
    designation:
      "Angiographie d'un accès vasculaire artérioveineux du membre supérieur avec exploration des troncs veineux profonds proximaux et de la veine cave supérieure, par injection intravasculaire transcutanée",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EZMH001",
    designation:
      "Contrôle radiologique secondaire de perméabilité et/ou de position d'un dispositif d'accès vasculaire ou d'une endoprothèse vasculaire, par injection de produit de contraste",
    tarifSecteur1Optam: "21.28",
    tarifHorsSecteur1HorsOptam: "21.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH010",
    designation:
      "Scanographie des vaisseaux du thorax et/ou du cœur [Angioscanner thoracique]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQH011",
    designation:
      "Scanographie des vaisseaux du thorax et/ou du cœur, avec scanographie des vaisseaux de l'abdomen et/ou du petit bassin [Angioscanner thoracique avec angioscanner de l'abdomen et/ou du pelvis]",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH006",
    designation: "Scanographie des vaisseaux cervicaux [Angioscanner cervical]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EAQH002",
    designation:
      "Scanographie des vaisseaux encéphaliques [Angioscanner cérébral]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQH004",
    designation:
      "Scanographie des vaisseaux cervicocéphaliques [Angioscanner cervicocérébral]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EKQH001",
    designation:
      "Scanographie des vaisseaux des membres supérieurs [Angioscanner des membres supérieurs]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ELQH002",
    designation:
      "Scanographie des vaisseaux de l'abdomen et/ou du petit bassin [Angioscanner abdominopelvien]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ELQH001",
    designation:
      "Scanographie des vaisseaux du foie pour étude de la vascularisation à au moins 3 temps différents",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EMQH001",
    designation:
      "Scanographie des vaisseaux des membres inférieurs [Angioscanner des membres inférieurs]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DZQN001",
    designation: "Remnographie [IRM] morphologique du cœur",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DZQN002",
    designation:
      "Remnographie [IRM] fonctionnelle du cœur, sans épreuve pharmacologique de stress",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DZQM008",
    designation:
      "Remnographie [IRM] fonctionnelle du cœur, avec épreuve pharmacologique de stress",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ECQJ001",
    designation: "Remnographie des vaisseaux du thorax [Angio-IRM thoracique]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQJ002",
    designation: "Remnographie des vaisseaux cervicaux [Angio-IRM cervicale]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EAQJ001",
    designation:
      "Remnographie des vaisseaux encéphaliques [Angio-IRM cérébrale]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EBQJ001",
    designation:
      "Remnographie des vaisseaux cervicocéphaliques [Angio-IRM cervicocérébrale]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EKQJ001",
    designation:
      "Remnographie des vaisseaux des membres supérieurs [Angio-IRM des membres supérieurs]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ELQJ001",
    designation:
      "Remnographie des vaisseaux de l'abdomen et/ou du petit bassin [Angio-IRM abdominopelvien]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ELQJ003",
    designation:
      "Remnographie des vaisseaux [Angio-IRM] de l'abdomen et/ou du petit bassin, avec remnographie des vaisseaux [angio-IRM] des membres",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EMQJ001",
    designation:
      "Remnographie des vaisseaux des membres inférieurs [Angio-IRM des membres inférieurs]",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL007",
    designation:
      "Scintigraphie myocardique sans utilisation de traceur de perfusion",
    tarifSecteur1Optam: "172.32",
    tarifHorsSecteur1HorsOptam: "172.32",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL002",
    designation:
      "Scintigraphie des cavités cardiaques au repos selon 1 incidence",
    tarifSecteur1Optam: "266.19",
    tarifHorsSecteur1HorsOptam: "266.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL008",
    designation:
      "Scintigraphie des cavités cardiaques au repos selon plusieurs incidences",
    tarifSecteur1Optam: "266.19",
    tarifHorsSecteur1HorsOptam: "266.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL012",
    designation: "Scintigraphie des cavités cardiaques à visée rythmologique",
    tarifSecteur1Optam: "395.43",
    tarifHorsSecteur1HorsOptam: "395.43",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL004",
    designation:
      "Scintigraphie des cavités cardiaques pendant une épreuve d'effort ou une épreuve pharmacologique",
    tarifSecteur1Optam: "395.43",
    tarifHorsSecteur1HorsOptam: "395.43",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL005",
    designation: "Recherche radio-isotopique de thrombose intracardiaque",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL003",
    designation:
      "Tomoscintigraphie de perfusion myocardique de repos, sans synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "256.77",
    tarifHorsSecteur1HorsOptam: "256.77",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL014",
    designation:
      "Tomoscintigraphie de perfusion myocardique de repos, avec synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "316.85",
    tarifHorsSecteur1HorsOptam: "316.85",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL001",
    designation:
      "Tomoscintigraphie de perfusion myocardique après épreuve d'effort ou épreuve pharmacologique, sans synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "261.91",
    tarifHorsSecteur1HorsOptam: "261.91",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL010",
    designation:
      "Tomoscintigraphie de perfusion myocardique après épreuve d'effort ou épreuve pharmacologique, avec synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "319.42",
    tarifHorsSecteur1HorsOptam: "319.42",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL011",
    designation:
      "Tomoscintigraphie de perfusion myocardique de repos, avec tomoscintigraphie de perfusion myocardique après épreuve d'effort ou épreuve pharmacologique sans synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "390.29",
    tarifHorsSecteur1HorsOptam: "390.29",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL009",
    designation:
      "Tomoscintigraphie de perfusion myocardique de repos, avec tomoscintigraphie de perfusion myocardique après épreuve d'effort ou épreuve pharmacologique avec synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "472.72",
    tarifHorsSecteur1HorsOptam: "472.72",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL015",
    designation:
      "Tomoscintigraphie des cavités cardiaques au repos, avec synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "266.19",
    tarifHorsSecteur1HorsOptam: "266.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL900",
    designation:
      "Tomoscintigraphie des cavités cardiaques après épreuve d'effort ou épreuve pharmacologique, avec synchronisation à l'électrocardiogramme",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DAQL006",
    designation:
      "Tomoscintigraphie myocardique par émission de positons, avec tépographe [caméra TEP] dédié",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DFQL001",
    designation:
      "Recherche d'une thrombose artérielle pulmonaire, par injection de traceur radio-isotopique spécifique",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EZQL001",
    designation: "Phlébographie radio-isotopique des membres",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EPQL001",
    designation:
      "Recherche radio-isotopique de thrombose veineuse périphérique",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "EZQL002",
    designation:
      "Mesure de la perméabilité capillaire par méthode radio-isotopique",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DKRP004",
    designation:
      "Épreuve d'effort sur tapis roulant ou bicyclette ergométrique, avec électrocardiographie discontinue",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQRP002",
    designation:
      "Épreuve d'effort sur tapis roulant ou bicyclette ergométrique, avec électrocardiographie discontinue et mesure du débit d'oxygène consommé [VO2]",
    tarifSecteur1Optam: "106.55",
    tarifHorsSecteur1HorsOptam: "94.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQRM001",
    designation:
      "Épreuve d'effort sur tapis roulant, avec électrocardiographie discontinue, examen doppler continu des artères des membres inférieurs et mesure de l'index de pression systolique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP001",
    designation:
      "Mesure de la distance de marche sur tapis roulant ou par enregistrement électromagnétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQM004",
    designation:
      "Mesure de la distance de marche sur tapis roulant ou par enregistrement électromagnétique, avec mesures de la pression systolique résiduelle de cheville et du temps de récupération [test de Strandness] par doppler continu transcutané ou pléthysmographie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP010",
    designation: "Mesure de la compliance artérielle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP009",
    designation:
      "Mesure de la pulsatilité artérielle par débitmétrie électromagnétique ou par impédance [irrigraphie]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP005",
    designation: "Capillaroscopie périunguéale",
    tarifSecteur1Optam: "25.24",
    tarifHorsSecteur1HorsOptam: "19.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQM003",
    designation:
      "Étude dynamique de la microcirculation digitale, par pléthysmographie, laser-doppler, thermométrie ou mesure transcutanée de la pression partielle en oxygène [TcPO2]",
    tarifSecteur1Optam: "18.93",
    tarifHorsSecteur1HorsOptam: "15.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJQP001",
    designation: "Pléthysmographie veineuse des membres inférieurs",
    tarifSecteur1Optam: "23.13",
    tarifHorsSecteur1HorsOptam: "19.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQM005",
    designation:
      "Étude de la fréquence cardiaque et des vitesses circulatoires par doppler pulsé transcrânien, avec épreuve dynamique",
    tarifSecteur1Optam: "104.54",
    tarifHorsSecteur1HorsOptam: "92.39",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DAHF001",
    designation:
      "Biopsie de l'endocarde et du myocarde, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "158.7",
    tarifHorsSecteur1HorsOptam: "158.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBHA001",
    designation:
      "Biopsie unilatérale ou bilatérale de l'artère temporale superficielle, par abord direct",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBHA002",
    designation: "Biopsie d'artère des membres ou du cou, par abord direct",
    tarifSecteur1Optam: "116.28",
    tarifHorsSecteur1HorsOptam: "102.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZQA001",
    designation:
      "Exploration d'un axe vasculonerveux des membres ou du cou, par abord direct",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "90.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENQX011",
    designation:
      "Examen histopathologique de biopsie d'artère avec coloration spéciale",
    tarifSecteur1Optam: "28",
    tarifHorsSecteur1HorsOptam: "28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DZQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de tumeur du cœur",
    tarifSecteur1Optam: "62.15",
    tarifHorsSecteur1HorsOptam: "62.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBQF003",
    designation:
      "Angiographie du segment antérieur de l'œil, par injection intraveineuse transcutanée de fluorescéine",
    tarifSecteur1Optam: "61.44",
    tarifHorsSecteur1HorsOptam: "61.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBQF004",
    designation:
      "Angiographie du segment postérieur de l'œil, par injection intraveineuse transcutanée de fluorescéine",
    tarifSecteur1Optam: "64.07",
    tarifHorsSecteur1HorsOptam: "64.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBQF002",
    designation:
      "Angiographie du segment postérieur de l'œil, par injection intraveineuse transcutanée de fluorescéine, avec superposition d'images",
    tarifSecteur1Optam: "80.35",
    tarifHorsSecteur1HorsOptam: "72.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBQF001",
    designation:
      "Angiographie du segment postérieur de l'œil, par injection intraveineuse transcutanée de fluorescéine, avec examen à l'ophtalmoscope à balayage laser",
    tarifSecteur1Optam: "72.17",
    tarifHorsSecteur1HorsOptam: "67.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBQF005",
    designation:
      "Angiographie du segment postérieur de l'œil, par injection intraveineuse transcutanée de vert d'indocyanine",
    tarifSecteur1Optam: "73.66",
    tarifHorsSecteur1HorsOptam: "72.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBQF006",
    designation:
      "Angiographie du segment postérieur de l'œil, par injection intraveineuse transcutanée de vert d'indocyanine, avec examen à l'ophtalmoscope à balayage laser",
    tarifSecteur1Optam: "78.86",
    tarifHorsSecteur1HorsOptam: "75.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DAAF001",
    designation:
      "Dilatation intraluminale de la voie d'éjection du ventricule droit et du tronc de l'artère pulmonaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DAAF002",
    designation:
      "Dilatation intraluminale de la voie d'éjection infraaortique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DAGF001",
    designation:
      "Ablation de corps étranger intracardiaque ou intravasculaire, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZGF001",
    designation:
      "Ablation d'une endoprothèse vasculaire ou d'un filtre cave ayant migré en position intracardiaque ou intravasculaire, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DAGA002",
    designation:
      "Ablation d'électrode intracardiaque de stimulation ou de défibrillation, par thoracotomie sans CEC",
    tarifSecteur1Optam: "643.06",
    tarifHorsSecteur1HorsOptam: "592.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAGA001",
    designation:
      "Ablation de corps étranger ou exérèse de végétation ou de caillot intracardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "755.79",
    tarifHorsSecteur1HorsOptam: "745.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBFA002",
    designation:
      "Thrombectomie de prothèse orificielle cardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "915.32",
    tarifHorsSecteur1HorsOptam: "838.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA002",
    designation:
      "Résection ou consolidation d'une zone myocardique infarcie, par thoracotomie sans CEC",
    tarifSecteur1Optam: "844.42",
    tarifHorsSecteur1HorsOptam: "709.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA008",
    designation:
      "Résection ou consolidation d'une zone myocardique infarcie, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1090.44",
    tarifHorsSecteur1HorsOptam: "939.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA003",
    designation:
      "Résection d'un anévrisme de la paroi ventriculaire du cœur, par thoracotomie avec CEC",
    tarifSecteur1Optam: "989.76",
    tarifHorsSecteur1HorsOptam: "881.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBFA001",
    designation:
      "Résection d'une fibrose endocardique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1175.52",
    tarifHorsSecteur1HorsOptam: "989.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA006",
    designation:
      "Résection d'un bourrelet musculaire infraaortique pour cardiomyopathie obstructive, par thoracotomie avec CEC",
    tarifSecteur1Optam: "858.6",
    tarifHorsSecteur1HorsOptam: "805.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA001",
    designation: "Exérèse d'un diverticule du cœur, par thoracotomie sans CEC",
    tarifSecteur1Optam: "566.49",
    tarifHorsSecteur1HorsOptam: "548.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA005",
    designation: "Exérèse d'une tumeur du cœur, par thoracotomie sans CEC",
    tarifSecteur1Optam: "704.04",
    tarifHorsSecteur1HorsOptam: "627.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA009",
    designation: "Exérèse d'une tumeur du cœur, par thoracotomie avec CEC",
    tarifSecteur1Optam: "940.5",
    tarifHorsSecteur1HorsOptam: "940.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAMA901",
    designation:
      "Cardiomyoplastie de substitution ventriculaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAMA900",
    designation:
      "Cardiomyoplastie de substitution ventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DACA001",
    designation: "Suture de plaie du cœur, par thoracotomie sans CEC",
    tarifSecteur1Optam: "650.15",
    tarifHorsSecteur1HorsOptam: "596.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASF002",
    designation:
      "Fermeture d'une déhiscence d'une prothèse de cloisonnement intraatrial, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DASA006",
    designation:
      "Fermeture d'une rupture du septum interventriculaire cardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1693.8",
    tarifHorsSecteur1HorsOptam: "1289.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBAF003",
    designation:
      "Dilatation intraluminale de l'orifice atrioventriculaire droit, par voie veineuse transcutanée",
    tarifSecteur1Optam: "679.73",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DBAF002",
    designation:
      "Dilatation intraluminale de l'orifice pulmonaire sans perforation de la valve atrésique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DBAF005",
    designation:
      "Dilatation intraluminale de l'orifice pulmonaire avec perforation de la valve atrésique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DBAF004",
    designation:
      "Dilatation intraluminale de l'orifice atrioventriculaire gauche, par voie veineuse transcutanée avec perforation du septum interatrial",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DBAF001",
    designation:
      "Dilatation intraluminale de l'orifice aortique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DBPA002",
    designation:
      "Commissurotomie ou valvectomie atrioventriculaire droite, par thoracotomie avec CEC",
    tarifSecteur1Optam: "731.5",
    tarifHorsSecteur1HorsOptam: "731.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBPA004",
    designation:
      "Commissurotomie ou valvectomie pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "731.5",
    tarifHorsSecteur1HorsOptam: "731.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBPA005",
    designation:
      "Commissurotomie de la valve atrioventriculaire gauche, par thoracotomie sans CEC",
    tarifSecteur1Optam: "541.96",
    tarifHorsSecteur1HorsOptam: "541.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBPA006",
    designation:
      "Commissurotomie de la valve atrioventriculaire gauche, par thoracotomie avec CEC",
    tarifSecteur1Optam: "735.23",
    tarifHorsSecteur1HorsOptam: "733.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBPA007",
    designation:
      "Commissurotomie de la valve aortique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "750.83",
    tarifHorsSecteur1HorsOptam: "742.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA008",
    designation:
      "Annuloplastie atrioventriculaire droite, par thoracotomie avec CEC",
    tarifSecteur1Optam: "745.16",
    tarifHorsSecteur1HorsOptam: "739.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA012",
    designation:
      "Valvoplastie atrioventriculaire droite, par thoracotomie avec CEC",
    tarifSecteur1Optam: "833.78",
    tarifHorsSecteur1HorsOptam: "790.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA003",
    designation:
      "Annuloplastie atrioventriculaire gauche, par thoracotomie avec CEC",
    tarifSecteur1Optam: "804.72",
    tarifHorsSecteur1HorsOptam: "773.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA002",
    designation:
      "Valvoplastie atrioventriculaire gauche, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1372.62",
    tarifHorsSecteur1HorsOptam: "1103.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA011",
    designation: "Valvoplastie aortique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1184.74",
    tarifHorsSecteur1HorsOptam: "994.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA004",
    designation:
      "Remplacement de la valve atrioventriculaire droite par prothèse mécanique ou bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "769.97",
    tarifHorsSecteur1HorsOptam: "753.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA008",
    designation:
      "Remplacement de la valve atrioventriculaire droite par homogreffe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1108.17",
    tarifHorsSecteur1HorsOptam: "949.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA007",
    designation:
      "Remplacement de la valve pulmonaire par prothèse mécanique ou bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "773.52",
    tarifHorsSecteur1HorsOptam: "755.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA012",
    designation:
      "Remplacement de la valve pulmonaire par homogreffe ou bioprothèse sans armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1030.89",
    tarifHorsSecteur1HorsOptam: "905.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA010",
    designation:
      "Remplacement de la valve atrioventriculaire gauche par prothèse mécanique ou bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "832.37",
    tarifHorsSecteur1HorsOptam: "790.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA005",
    designation:
      "Remplacement de la valve atrioventriculaire gauche par homogreffe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1046.19",
    tarifHorsSecteur1HorsOptam: "1046.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA002",
    designation:
      "Remplacement de la valve atrioventriculaire gauche par prothèse en position non anatomique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1532.15",
    tarifHorsSecteur1HorsOptam: "1195.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA006",
    designation:
      "Remplacement de la valve aortique par prothèse mécanique ou bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "789.83",
    tarifHorsSecteur1HorsOptam: "765.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA003",
    designation:
      "Remplacement de la valve aortique par bioprothèse sans armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "789.83",
    tarifHorsSecteur1HorsOptam: "765.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA001",
    designation:
      "Remplacement de la valve aortique par homogreffe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1286.13",
    tarifHorsSecteur1HorsOptam: "1053.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA011",
    designation:
      "Remplacement de la valve aortique par prothèse en position non anatomique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1532.15",
    tarifHorsSecteur1HorsOptam: "1195.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBKA009",
    designation:
      "Remplacement de la valve aortique et de la valve atrioventriculaire gauche par prothèse mécanique ou par bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1556.26",
    tarifHorsSecteur1HorsOptam: "1297.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA007",
    designation:
      "Reconstruction de l'anneau atrioventriculaire gauche avec valvoplastie, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1598.8",
    tarifHorsSecteur1HorsOptam: "1234.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA013",
    designation:
      "Reconstruction de l'anneau atrioventriculaire gauche avec remplacement de la valve par prothèse mécanique ou bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1498.12",
    tarifHorsSecteur1HorsOptam: "1263.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA005",
    designation:
      "Reconstruction de l'anneau atrioventriculaire gauche avec remplacement de la valve par homogreffe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1386.46",
    tarifHorsSecteur1HorsOptam: "1386.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA009",
    designation:
      "Reconstruction de l'anneau aortique avec remplacement de la valve par prothèse mécanique ou bioprothèse avec armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1449.91",
    tarifHorsSecteur1HorsOptam: "1235.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA010",
    designation:
      "Reconstruction de l'anneau aortique avec remplacement de la valve par homogreffe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1570.44",
    tarifHorsSecteur1HorsOptam: "1305.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA006",
    designation:
      "Reconstruction de l'anneau aortique avec remplacement de la valve par bioprothèse sans armature, par thoracotomie avec CEC",
    tarifSecteur1Optam: "964.24",
    tarifHorsSecteur1HorsOptam: "954.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA001",
    designation:
      "Reconstruction de la voie aortique par élargissement antérodroit de l'anneau avec remplacement de la valve, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1312.36",
    tarifHorsSecteur1HorsOptam: "1156.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA015",
    designation:
      "Reconstruction de la voie aortique par élargissement antérogauche de l'anneau et ouverture de l'infundibulum pulmonaire, avec remplacement de la valve, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1672.53",
    tarifHorsSecteur1HorsOptam: "1452.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBMA004",
    designation:
      "Reconstruction de la voie aortique par transfert de la valve pulmonaire en position aortique avec reconstruction de la voie pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2006.47",
    tarifHorsSecteur1HorsOptam: "1646.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBLF009",
    designation:
      "Pose d'une bioprothèse de la valve pulmonaire dans un conduit prothétique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "2757.95",
    tarifHorsSecteur1HorsOptam: "2757.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBLF001",
    designation:
      "Pose d'une bioprothèse de la valve aortique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "2040.6",
    tarifHorsSecteur1HorsOptam: "2040.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBLA004",
    designation:
      "Pose d'une bioprothèse de la valve aortique, par abord de l'apex du cœur par thoracotomie sans CEC",
    tarifSecteur1Optam: "2040.6",
    tarifHorsSecteur1HorsOptam: "2040.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBSF001",
    designation:
      "Fermeture d'une déhiscence par désinsertion de prothèse orificielle cardiaque, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DBEA001",
    designation:
      "Réinsertion d'une prothèse orificielle cardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "940.13",
    tarifHorsSecteur1HorsOptam: "852.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DELF011",
    designation:
      "Pose d'une sonde intraventriculaire droite pour stimulation cardiaque temporaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "100.79",
    tarifHorsSecteur1HorsOptam: "100.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF006",
    designation:
      "Pose d'une sonde intraventriculaire droite et d'une sonde dans le sinus coronaire ou l'oreillette droite pour stimulation cardiaque temporaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "109.38",
    tarifHorsSecteur1HorsOptam: "109.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF007",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale ou intraventriculaire droite par voie veineuse transcutanée",
    tarifSecteur1Optam: "238.62",
    tarifHorsSecteur1HorsOptam: "238.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF001",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale ou intraventriculaire droite, et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "281.83",
    tarifHorsSecteur1HorsOptam: "281.83",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF904",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale ou intraventriculaire droite, d'une sonde dans le sinus coronaire et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF010",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale et intraventriculaire droite unique par voie veineuse transcutanée",
    tarifSecteur1Optam: "277.98",
    tarifHorsSecteur1HorsOptam: "277.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF005",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites par voie veineuse transcutanée",
    tarifSecteur1Optam: "277.98",
    tarifHorsSecteur1HorsOptam: "277.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF902",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites, et d'une sonde dans le sinus coronaire par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF015",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites, et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "284.94",
    tarifHorsSecteur1HorsOptam: "284.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF905",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites, d'une sonde dans le sinus coronaire, et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF903",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose de 2 sondes intraatriales et d'une sonde intraventriculaire droites par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF901",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde dans le sinus coronaire et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF012",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque définitif, avec pose d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "239.5",
    tarifHorsSecteur1HorsOptam: "239.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF223",
    designation:
      "Implantation d’un stimulateur cardiaque définitif dans le ventricule droit par voie veineuse transcutanée ",
    tarifSecteur1Optam: "763",
    tarifHorsSecteur1HorsOptam: "763",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELA003",
    designation:
      "Implantation sousucutanée d'un stimulateur cardiaque avec pose d'électrode épicardique ventriculaire pour stimulation définitive, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DELA001",
    designation:
      "Implantation souscutanée d'un stimulateur cardiaque avec pose d'électrodes épicardiques atriale et ventriculaire pour stimulation définitive, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DELF086",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque avec pose d'une sonde souscutanée",
    tarifSecteur1Optam: "670.3",
    tarifHorsSecteur1HorsOptam: "670.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF013",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque automatique, avec pose d'une sonde intraventriculaire droite par voie veineuse transcutanée",
    tarifSecteur1Optam: "306.71",
    tarifHorsSecteur1HorsOptam: "306.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF020",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque automatique, avec pose d'une sonde intraventriculaire droite et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "312.93",
    tarifHorsSecteur1HorsOptam: "312.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF014",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque automatique, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites, et d'une sonde dans une veine cardiaque gauche par voie veineuse transcutanée",
    tarifSecteur1Optam: "320.33",
    tarifHorsSecteur1HorsOptam: "320.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF016",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque automatique sans fonction de défibrillation atriale, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites par voie veineuse transcutanée",
    tarifSecteur1Optam: "312.93",
    tarifHorsSecteur1HorsOptam: "312.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF900",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque automatique avec fonction de défibrillation atriale, avec pose d'une sonde intraatriale et d'une sonde intraventriculaire droites par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELA004",
    designation:
      "Implantation souscutanée d'un défibrillateur cardiaque avec pose d'électrode épicardique, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DEEF001",
    designation:
      "Repositionnement de sonde définitive intracavitaire de stimulation cardiaque, par voie veineuse transcutanée",
    tarifSecteur1Optam: "105.87",
    tarifHorsSecteur1HorsOptam: "105.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEEF002",
    designation:
      "Repositionnement de sonde définitive intracavitaire de défibrillation cardiaque, par voie veineuse transcutanée",
    tarifSecteur1Optam: "121.86",
    tarifHorsSecteur1HorsOptam: "121.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEEA001",
    designation:
      "Repositionnement souscutané d'un générateur implantable de stimulation ou de défibrillation cardiaque",
    tarifSecteur1Optam: "125.87",
    tarifHorsSecteur1HorsOptam: "125.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DEGF004",
    designation:
      "Ablation d'une sonde définitive intracavitaire de stimulation ou de défibrillation cardiaque sans utilisation de dispositif spécifique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEGF001",
    designation:
      "Ablation d'une sonde définitive intracavitaire de stimulation ou de défibrillation cardiaque avec utilisation de dispositif spécifique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "319.04",
    tarifHorsSecteur1HorsOptam: "319.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEGF002",
    designation:
      "Ablation de plusieurs sondes définitives intracavitaires de stimulation cardiaque sans utilisation de dispositif spécifique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "470.25",
    tarifHorsSecteur1HorsOptam: "470.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEGF003",
    designation:
      "Ablation de plusieurs sondes définitives intracavitaires de stimulation cardiaque avec utilisation de dispositif spécifique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "470.25",
    tarifHorsSecteur1HorsOptam: "470.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEGF006",
    designation:
      "Ablation d'une sonde définitive intracavitaire de défibrillation et d'une sonde définitive intracavitaire de stimulation cardiaque sans utilisation de dispositif spécifique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "470.25",
    tarifHorsSecteur1HorsOptam: "470.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEGF005",
    designation:
      "Ablation d'une sonde définitive intracavitaire de défibrillation et d'une sonde définitive intracavitaire de stimulation cardiaque avec utilisation de dispositif spécifique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "470.25",
    tarifHorsSecteur1HorsOptam: "470.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEGA002",
    designation:
      "Ablation d'électrode épicardique définitive, par thoracotomie",
    tarifSecteur1Optam: "292.82",
    tarifHorsSecteur1HorsOptam: "257.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DELF017",
    designation:
      "Pose d'une sonde supplémentaire de stimulation cardiaque, par voie veineuse transcutanée",
    tarifSecteur1Optam: "123.05",
    tarifHorsSecteur1HorsOptam: "123.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF019",
    designation:
      "Pose de plusieurs sondes supplémentaires de stimulation cardiaque, par voie veineuse transcutanée",
    tarifSecteur1Optam: "169.91",
    tarifHorsSecteur1HorsOptam: "169.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELF018",
    designation:
      "Pose d'une sonde supplémentaire de défibrillation cardiaque, par voie veineuse transcutanée",
    tarifSecteur1Optam: "157.1",
    tarifHorsSecteur1HorsOptam: "157.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELA006",
    designation:
      "Implantation souscutanée d'un générateur de stimulation cardiaque",
    tarifSecteur1Optam: "117.43",
    tarifHorsSecteur1HorsOptam: "117.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DELA007",
    designation:
      "Implantation souscutanée d'un générateur de défibrillation cardiaque",
    tarifSecteur1Optam: "222.09",
    tarifHorsSecteur1HorsOptam: "117.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DEGA003",
    designation:
      "Ablation d'un générateur souscutané de stimulation ou de défibrillation cardiaque implantable",
    tarifSecteur1Optam: "61.63",
    tarifHorsSecteur1HorsOptam: "61.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DEGF207",
    designation:
      "Ablation d'un stimulateur cardiaque définitif implanté dans le ventricule droit par voie veineuse transcutanée ",
    tarifSecteur1Optam: "832",
    tarifHorsSecteur1HorsOptam: "832",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEKA001",
    designation:
      "Changement d'un générateur souscutané de stimulation cardiaque implantable",
    tarifSecteur1Optam: "118.18",
    tarifHorsSecteur1HorsOptam: "118.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DEKA002",
    designation:
      "Changement d'un générateur souscutané de défibrillation cardiaque implantable",
    tarifSecteur1Optam: "119.06",
    tarifHorsSecteur1HorsOptam: "119.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DEMP002",
    designation:
      "Contrôle et réglage transcutané secondaires d'un stimulateur cardiaque",
    tarifSecteur1Optam: "60.41",
    tarifHorsSecteur1HorsOptam: "55.19",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEMP001",
    designation:
      "Contrôle et réglage transcutané secondaires d'un défibrillateur cardiaque",
    tarifSecteur1Optam: "70.48",
    tarifHorsSecteur1HorsOptam: "61.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERP001",
    designation:
      "Déclenchement de fibrillation ou de tachycardie ventriculaire, pour contrôle et mesure du seuil de défibrillation chez un porteur de défibrillateur",
    tarifSecteur1Optam: "141.79",
    tarifHorsSecteur1HorsOptam: "89.27",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF010",
    designation:
      "Interruption de la voie lente ou de la voie rapide de la jonction atrioventriculaire avec courant de radiofréquence ou cryothérapie, par voie veineuse transcutanée",
    tarifSecteur1Optam: "647.92",
    tarifHorsSecteur1HorsOptam: "647.92",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF006",
    designation:
      "Interruption complète du tissu nodal de la jonction atrioventriculaire avec courant de radiofréquence, par voie veineuse transcutanée",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF005",
    designation:
      "Interruption complète d'une voie accessoire de conduction cardiaque avec courant de radiofréquence, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "437.28",
    tarifHorsSecteur1HorsOptam: "437.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF004",
    designation:
      "Interruption complète de plusieurs voies accessoires de conduction cardiaque avec courant de radiofréquence, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "487.07",
    tarifHorsSecteur1HorsOptam: "487.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF012",
    designation:
      "Interruption complète de circuit arythmogène au niveau de l'isthme cavotricuspidien avec courant de radiofréquence ou cryothérapie, par voie veineuse transcutanée",
    tarifSecteur1Optam: "467.89",
    tarifHorsSecteur1HorsOptam: "467.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF025",
    designation:
      "Interruption complète de circuit arythmogène en dehors de l'isthme cavotricuspidien avec courant de radiofréquence, par voie veineuse transcutanée",
    tarifSecteur1Optam: "878.63",
    tarifHorsSecteur1HorsOptam: "878.63",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF014",
    designation:
      "Interruption complète de circuit arythmogène en dehors de l'isthme cavotricuspidien avec courant de radiofréquence, par voie veineuse transcutanée et voie transseptale",
    tarifSecteur1Optam: "1358.38",
    tarifHorsSecteur1HorsOptam: "1358.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DEPF033",
    designation:
      "Interruption complète de circuit arythmogène au niveau de l'ostium des veines pulmonaires avec courant de radiofréquence ou cryothérapie, par voie veineuse transcutanée et voie transseptale",
    tarifSecteur1Optam: "1529.79",
    tarifHorsSecteur1HorsOptam: "1529.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DENF017",
    designation:
      "Destruction d'un foyer arythmogène atrial avec courant de radiofréquence, par voie veineuse transcutanée",
    tarifSecteur1Optam: "667.33",
    tarifHorsSecteur1HorsOptam: "667.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DENF014",
    designation:
      "Destruction d'un foyer arythmogène atrial avec courant de radiofréquence, par voie veineuse transcutanée et voie transseptale",
    tarifSecteur1Optam: "1015.55",
    tarifHorsSecteur1HorsOptam: "1015.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DENF021",
    designation:
      "Destruction de plusieurs foyers arythmogènes atriaux avec courant de radiofréquence, par voie veineuse transcutanée",
    tarifSecteur1Optam: "988.6",
    tarifHorsSecteur1HorsOptam: "988.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DENF018",
    designation:
      "Destruction de plusieurs foyers arythmogènes atriaux avec courant de radiofréquence, par voie veineuse transcutanée et voie transseptale",
    tarifSecteur1Optam: "1453.25",
    tarifHorsSecteur1HorsOptam: "1453.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DENF015",
    designation:
      "Destruction d'un foyer ou interruption d'un circuit arythmogène ventriculaire avec courant de radiofréquence, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "858.15",
    tarifHorsSecteur1HorsOptam: "858.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DENF035",
    designation:
      "Destruction de plusieurs foyers et/ou interruption de plusieurs circuits arythmogènes ventriculaires avec courant de radiofréquence, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "1509.31",
    tarifHorsSecteur1HorsOptam: "1509.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERP005",
    designation: "Stimulation cardiaque temporaire transcutanée",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERF003",
    designation:
      "Stimulation électrique temporaire de l'oreillette pour interrompre une tachycardie supraventriculaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "104.98",
    tarifHorsSecteur1HorsOptam: "104.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERD001",
    designation:
      "Stimulation électrique temporaire de l'oreillette par voie œsophagienne, pour interrompre une tachycardie supraventriculaire",
    tarifSecteur1Optam: "80.05",
    tarifHorsSecteur1HorsOptam: "80.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERF004",
    designation:
      "Stimulation électrique temporaire de l'oreillette et/ou du ventricule pour interrompre une tachycardie ventriculaire, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "109.72",
    tarifHorsSecteur1HorsOptam: "109.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERP003",
    designation:
      "Choc électrique cardiaque transcutané [Cardioversion externe], en dehors de l'urgence",
    tarifSecteur1Optam: "73.83",
    tarifHorsSecteur1HorsOptam: "67.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERP004",
    designation:
      "Choc électrique cardiaque transcutané [Cardioversion externe], en urgence",
    tarifSecteur1Optam: "65.44",
    tarifHorsSecteur1HorsOptam: "62.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERF001",
    designation:
      "Choc électrique cardiaque à basse énergie, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "82.5",
    tarifHorsSecteur1HorsOptam: "82.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DERF002",
    designation:
      "Choc électrique cardiaque à haute énergie, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "76.14",
    tarifHorsSecteur1HorsOptam: "76.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF001",
    designation:
      "Dilatation intraluminale d'un vaisseau coronaire sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "386.38",
    tarifHorsSecteur1HorsOptam: "386.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF006",
    designation:
      "Dilatation intraluminale d'un vaisseau coronaire avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "386.38",
    tarifHorsSecteur1HorsOptam: "386.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF004",
    designation:
      "Dilatation intraluminale de 2 vaisseaux coronaires avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "579.57",
    tarifHorsSecteur1HorsOptam: "579.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF003",
    designation:
      "Dilatation intraluminale de 3 vaisseaux coronaires ou plus avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "579.57",
    tarifHorsSecteur1HorsOptam: "579.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF010",
    designation:
      "Dilatation intraluminale d'un vaisseau coronaire avec artériographie coronaire, sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "526.49",
    tarifHorsSecteur1HorsOptam: "526.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF008",
    designation:
      "Dilatation intraluminale d'un vaisseau coronaire avec artériographie coronaire, avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "526.49",
    tarifHorsSecteur1HorsOptam: "526.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF007",
    designation:
      "Dilatation intraluminale de 2 vaisseaux coronaires avec artériographie coronaire, avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "719.68",
    tarifHorsSecteur1HorsOptam: "719.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAF009",
    designation:
      "Dilatation intraluminale de 3 vaisseaux coronaires ou plus avec artériographie coronaire, avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "719.68",
    tarifHorsSecteur1HorsOptam: "719.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDNF240",
    designation:
      "Lithotritie intraluminale d'artère coronaire avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "895.09",
    tarifHorsSecteur1HorsOptam: "895.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDNF775",
    designation:
      "Lithotritie intraluminale d'artère coronaire avec artériographie coronaire, avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "983.93",
    tarifHorsSecteur1HorsOptam: "983.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDMA025",
    designation:
      "Revascularisation coronaire par un greffon artériel avec une anastomose distale, par thoracotomie sans CEC",
    tarifSecteur1Optam: "789.83",
    tarifHorsSecteur1HorsOptam: "673.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA015",
    designation:
      "Revascularisation coronaire par un greffon artériel avec une anastomose distale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "825.55",
    tarifHorsSecteur1HorsOptam: "825.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA023",
    designation:
      "Revascularisation coronaire par un greffon artériel avec 2 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1050.74",
    tarifHorsSecteur1HorsOptam: "912.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA017",
    designation:
      "Revascularisation coronaire par un greffon artériel avec 2 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1050.74",
    tarifHorsSecteur1HorsOptam: "1043.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA032",
    designation:
      "Revascularisation coronaire par un greffon artériel et par greffon veineux avec 2 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1064.21",
    tarifHorsSecteur1HorsOptam: "972.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA011",
    designation:
      "Revascularisation coronaire par un greffon artériel et par greffon veineux avec 2 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1159.95",
    tarifHorsSecteur1HorsOptam: "1159.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA029",
    designation:
      "Revascularisation coronaire par un greffon artériel et par greffon veineux avec 3 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1342.85",
    tarifHorsSecteur1HorsOptam: "1222.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA018",
    designation:
      "Revascularisation coronaire par un greffon artériel et par greffon veineux avec 3 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1368.95",
    tarifHorsSecteur1HorsOptam: "1368.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA038",
    designation:
      "Revascularisation coronaire par un greffon artériel et par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1610.85",
    tarifHorsSecteur1HorsOptam: "1377.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA021",
    designation:
      "Revascularisation coronaire par un greffon artériel et par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1610.85",
    tarifHorsSecteur1HorsOptam: "1509.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA026",
    designation:
      "Revascularisation coronaire par 2 greffons artériels avec 2 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1210.97",
    tarifHorsSecteur1HorsOptam: "1005.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA020",
    designation:
      "Revascularisation coronaire par 2 greffons artériels avec 2 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1210.97",
    tarifHorsSecteur1HorsOptam: "1136.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA031",
    designation:
      "Revascularisation coronaire par 2 greffons artériels avec 3 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1461.25",
    tarifHorsSecteur1HorsOptam: "1238.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA006",
    designation:
      "Revascularisation coronaire par 2 greffons artériels avec 3 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1461.25",
    tarifHorsSecteur1HorsOptam: "1369.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA033",
    designation:
      "Revascularisation coronaire par 2 greffons artériels avec 4 anastomoses distales ou plus, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1694.51",
    tarifHorsSecteur1HorsOptam: "1373.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA008",
    designation:
      "Revascularisation coronaire par 2 greffons artériels avec 4 anastomoses distales ou plus, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1694.51",
    tarifHorsSecteur1HorsOptam: "1505.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA022",
    designation:
      "Revascularisation coronaire par 2 greffons artériels et par greffon veineux avec 3 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1427.93",
    tarifHorsSecteur1HorsOptam: "1271.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA005",
    designation:
      "Revascularisation coronaire par 2 greffons artériels et par greffon veineux avec 3 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1427.93",
    tarifHorsSecteur1HorsOptam: "1403.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA034",
    designation:
      "Revascularisation coronaire par 2 greffons artériels et par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1684.58",
    tarifHorsSecteur1HorsOptam: "1420.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA009",
    designation:
      "Revascularisation coronaire par 2 greffons artériels et par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1684.58",
    tarifHorsSecteur1HorsOptam: "1552.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA030",
    designation:
      "Revascularisation coronaire par 3 greffons artériels avec 3 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1334.78",
    tarifHorsSecteur1HorsOptam: "1334.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA003",
    designation:
      "Revascularisation coronaire par 3 greffons artériels avec 3 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1627.86",
    tarifHorsSecteur1HorsOptam: "1466.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA035",
    designation:
      "Revascularisation coronaire par 3 greffons artériels avec 4 anastomoses distales ou plus, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1844.11",
    tarifHorsSecteur1HorsOptam: "1460.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA013",
    designation:
      "Revascularisation coronaire par 3 greffons artériels avec 4 anastomoses distales ou plus, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1844.11",
    tarifHorsSecteur1HorsOptam: "1591.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA036",
    designation:
      "Revascularisation coronaire par 3 greffons artériels et par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1892.32",
    tarifHorsSecteur1HorsOptam: "1540.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA012",
    designation:
      "Revascularisation coronaire par 3 greffons artériels et par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1892.32",
    tarifHorsSecteur1HorsOptam: "1672.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA028",
    designation:
      "Revascularisation coronaire par greffon veineux avec une anastomose distale, par thoracotomie sans CEC",
    tarifSecteur1Optam: "783.45",
    tarifHorsSecteur1HorsOptam: "682.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA007",
    designation:
      "Revascularisation coronaire par greffon veineux avec une anastomose distale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "856.9",
    tarifHorsSecteur1HorsOptam: "856.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA024",
    designation:
      "Revascularisation coronaire par greffon veineux avec 2 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1034.43",
    tarifHorsSecteur1HorsOptam: "915.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA019",
    designation:
      "Revascularisation coronaire par greffon veineux avec 2 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1065.9",
    tarifHorsSecteur1HorsOptam: "1065.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA027",
    designation:
      "Revascularisation coronaire par greffon veineux avec 3 anastomoses distales, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1272.66",
    tarifHorsSecteur1HorsOptam: "1141.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA016",
    designation:
      "Revascularisation coronaire par greffon veineux avec 3 anastomoses distales, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1274.9",
    tarifHorsSecteur1HorsOptam: "1274.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA037",
    designation:
      "Revascularisation coronaire par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1275.99",
    tarifHorsSecteur1HorsOptam: "1275.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDMA004",
    designation:
      "Revascularisation coronaire par greffon veineux avec 4 anastomoses distales ou plus, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1503.79",
    tarifHorsSecteur1HorsOptam: "1407.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDPF002",
    designation:
      "Recanalisation d'artère coronaire avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "909.73",
    tarifHorsSecteur1HorsOptam: "909.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDFF002",
    designation:
      "Athérectomie intraluminale d'artère coronaire, par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDFF001",
    designation:
      "Athérectomie intraluminale d'artère coronaire par méthode rotatoire [rotationnelle], par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDSF001",
    designation:
      "Embolisation ou fermeture d'une fistule ou d'un anévrisme coronaire, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDLF001",
    designation:
      "Injection in situ d'agent pharmacologique dans une branche d'artère coronaire pour réduction de l'épaisseur du septum interventriculaire, par voie artérielle transcutanée",
    tarifSecteur1Optam: "899.13",
    tarifHorsSecteur1HorsOptam: "899.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDAA002",
    designation:
      "Angioplastie d'élargissement du tronc commun de l'artère coronaire gauche, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1115.26",
    tarifHorsSecteur1HorsOptam: "954.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DCJB002",
    designation:
      "Évacuation d'une collection du péricarde, par voie transcutanée",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DCJB001",
    designation:
      "Drainage d'une collection du péricarde, par voie transcutanée",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DCJA001",
    designation:
      "Drainage d'une collection du péricarde, par thoracotomie ou par abord infraxiphoïdien",
    tarifSecteur1Optam: "210.57",
    tarifHorsSecteur1HorsOptam: "209.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DCMC001",
    designation: "Création d'une fenêtre péricardopleurale, par thoracoscopie",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "241.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DCMA001",
    designation:
      "Création d'une fenêtre péricardopéritonéale, par abord infraxiphoïdien",
    tarifSecteur1Optam: "298.49",
    tarifHorsSecteur1HorsOptam: "260.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DCFA001",
    designation: "Péricardectomie subtotale, par thoracotomie",
    tarifSecteur1Optam: "828.11",
    tarifHorsSecteur1HorsOptam: "699.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZFA001",
    designation:
      "Prélèvement du cœur, au cours d'un prélèvement multiorgane sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZFA002",
    designation:
      "Prélèvement du cœur pour préparation de greffe orificielle, au cours d'un prélèvement multiorgane sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZFA003",
    designation:
      "Prélèvement du bloc cœur-poumons, au cours d'un prélèvement multiorgane sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZEA002",
    designation:
      "Transplantation orthotopique du cœur, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZEA003",
    designation:
      "Transplantation hétérotopique du cœur, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZEA001",
    designation: "Transplantation itérative du cœur, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZEA004",
    designation:
      "Transplantation du bloc cœur-poumons, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZFA004",
    designation:
      "Exérèse d'un greffon de transplantation du cœur avec pose d'une assistance circulatoire mécanique biventriculaire interne, par thoracotomie avec CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQRP001",
    designation:
      "Séance de réentraînement cardiaque et/ou vasculaire progressif à l'effort sur machine",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DKRP001",
    designation:
      "Séance de réentraînement cardiaque et/ou vasculaire progressif à l'effort sur machine et à la réalisation de tâches de la vie quotidienne",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DKRP003",
    designation:
      "Séance de réentraînement cardiaque et/ou vasculaire progressif à l'effort sur machine et à l'activité sportive collective",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DKRP002",
    designation:
      "Séance de réentraînement cardiaque et/ou vasculaire progressif à l'effort sur machine, à la réalisation de tâches de la vie quotidienne et à l'activité sportive collective",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DKRP005",
    designation:
      "Séance de réentraînement d'un insuffisant cardiaque à l'effort sur machine",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGCA025",
    designation:
      "Suture latérale de plaie de l'aorte thoracique, par thoracotomie sans CEC",
    tarifSecteur1Optam: "858.6",
    tarifHorsSecteur1HorsOptam: "717.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA017",
    designation:
      "Suture latérale de plaie de l'aorte thoracique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1076.97",
    tarifHorsSecteur1HorsOptam: "975.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA002",
    designation:
      "Suture d'une rupture de l'isthme de l'aorte, par thoracotomie sans CEC",
    tarifSecteur1Optam: "890.97",
    tarifHorsSecteur1HorsOptam: "890.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA006",
    designation:
      "Suture d'une rupture de l'isthme de l'aorte, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1214.69",
    tarifHorsSecteur1HorsOptam: "1214.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAF001",
    designation:
      "Dilatation intraluminale de l'aorte thoracique sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGAF007",
    designation:
      "Dilatation intraluminale de l'aorte thoracique avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGLF003",
    designation:
      "Pose d'endoprothèse couverte dans l'aorte thoracique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGPA014",
    designation:
      "Désobstruction d'une prothèse de l'aorte thoracique, par thoracotomie",
    tarifSecteur1Optam: "263.78",
    tarifHorsSecteur1HorsOptam: "263.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA002",
    designation:
      "Thromboendartériectomie de l'aorte thoracique horizontale et des troncs supraaortiques, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1844.11",
    tarifHorsSecteur1HorsOptam: "1530.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA031",
    designation:
      "Pontage entre l'aorte thoracique ascendante et l'aorte thoracique descendante, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1006.07",
    tarifHorsSecteur1HorsOptam: "846.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA028",
    designation:
      "Pontage entre l'aorte thoracique ascendante et l'aorte thoracique descendante, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1037.75",
    tarifHorsSecteur1HorsOptam: "1037.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA015",
    designation:
      "Pontage entre l'aorte thoracique ascendante et l'aorte abdominale sans exclusion de l'aorte thoracique descendante, par thoracotomie et par laparotomie sans CEC",
    tarifSecteur1Optam: "1074.84",
    tarifHorsSecteur1HorsOptam: "921.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA027",
    designation:
      "Pontage entre l'aorte thoracique ascendante et l'aorte abdominale sans exclusion de l'aorte thoracique descendante, par thoracotomie et par laparotomie avec CEC",
    tarifSecteur1Optam: "1115.21",
    tarifHorsSecteur1HorsOptam: "1115.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA008",
    designation:
      "Pontage entre l'aorte thoracique ascendante et l'aorte abdominale avec exclusion de l'aorte thoracique descendante, par thoracotomie et par laparotomie sans CEC",
    tarifSecteur1Optam: "1047.28",
    tarifHorsSecteur1HorsOptam: "1047.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA023",
    designation:
      "Pontage entre l'aorte thoracique descendante ou l'aorte juxtadiaphragmatique et l'aorte abdominale infrarénale, par thoraco-phréno-laparotomie sans CEC",
    tarifSecteur1Optam: "1373.33",
    tarifHorsSecteur1HorsOptam: "1094.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA003",
    designation:
      "Pontage rétropéritonéal entre l'aorte thoracique descendante et les artères fémorales, par abord direct",
    tarifSecteur1Optam: "1252.09",
    tarifHorsSecteur1HorsOptam: "1055.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA013",
    designation:
      "Pontages multiples entre l'aorte et les troncs supraaortiques, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1465.5",
    tarifHorsSecteur1HorsOptam: "1223.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA011",
    designation:
      "Pontages multiples entre l'aorte et les troncs supraaortiques, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1671.82",
    tarifHorsSecteur1HorsOptam: "1474.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAA006",
    designation:
      "Angioplastie d'élargissement de l'aorte thoracique horizontale sans réimplantation des troncs supraaortiques, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1529.31",
    tarifHorsSecteur1HorsOptam: "1282.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAA003",
    designation:
      "Angioplastie d'élargissement de l'aorte thoracique horizontale avec réimplantation des troncs supraaortiques, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1474.39",
    tarifHorsSecteur1HorsOptam: "1474.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA019",
    designation:
      "Résection-anastomose de l'aorte thoracique descendante ou de l'aorte juxtadiaphragmatique, par thoracotomie sans dérivation vasculaire ni CEC",
    tarifSecteur1Optam: "841.62",
    tarifHorsSecteur1HorsOptam: "841.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA014",
    designation:
      "Résection-anastomose de l'aorte thoracique descendante ou de l'aorte juxtadiaphragmatique, par thoracotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "1157.09",
    tarifHorsSecteur1HorsOptam: "1022.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA017",
    designation:
      "Résection-anastomose de l'aorte thoracique descendante ou de l'aorte juxtadiaphragmatique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1157.09",
    tarifHorsSecteur1HorsOptam: "1022.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA025",
    designation:
      "Remplacement de l'aorte thoracique ascendante sans remplacement de la valve aortique, sans réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1028.76",
    tarifHorsSecteur1HorsOptam: "991.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA003",
    designation:
      "Remplacement de l'aorte thoracique ascendante sans remplacement de la valve aortique, avec réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2038.38",
    tarifHorsSecteur1HorsOptam: "1708.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA011",
    designation:
      "Remplacement de l'aorte thoracique ascendante avec remplacement de la valve aortique, sans réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1381.84",
    tarifHorsSecteur1HorsOptam: "1240.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA015",
    designation:
      "Remplacement de l'aorte thoracique ascendante avec remplacement de la valve aortique, avec réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1895.87",
    tarifHorsSecteur1HorsOptam: "1714.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA001",
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale sans remplacement de la valve aortique, sans réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1932.03",
    tarifHorsSecteur1HorsOptam: "1625.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA026",
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale sans remplacement de la valve aortique, avec réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2358.13",
    tarifHorsSecteur1HorsOptam: "2004.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA018",
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale avec remplacement de la valve aortique, sans réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2132.67",
    tarifHorsSecteur1HorsOptam: "1829.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA014",
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale avec remplacement de la valve aortique, avec réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2304.25",
    tarifHorsSecteur1HorsOptam: "2060.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"DGKA268',
  },
  {
    codeCCAM: "",
  },
  {
    codeCCAM: '"',
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale, avec montage en trompe d'éléphant renforcé, sans remplacement de la valve aortique, sans réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2451",
    tarifHorsSecteur1HorsOptam: "2451",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"DGKA209',
  },
  {
    codeCCAM: '"',
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale, avec montage en trompe d'éléphant renforcé, sans remplacement de la valve aortique, avec réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2888",
    tarifHorsSecteur1HorsOptam: "2888",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"DGKA263',
  },
  {
    codeCCAM: '"',
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale, avec montage en trompe d'éléphant renforcé, avec remplacement de la valve aortique, sans réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2523",
    tarifHorsSecteur1HorsOptam: "2523",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"DGKA698',
  },
  {
    codeCCAM: '"',
    designation:
      "Remplacement de l'aorte thoracique ascendante et de l'aorte horizontale, avec montage en trompe d'éléphant renforcé, avec remplacement de la valve aortique, avec réimplantation des artères coronaires, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2653",
    tarifHorsSecteur1HorsOptam: "2653",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA005",
    designation:
      "Remplacement de l'aorte thoracique horizontale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1846.95",
    tarifHorsSecteur1HorsOptam: "1575.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA002",
    designation:
      "Remplacement de l'isthme de l'aorte, par thoracotomie sans dérivation vasculaire ni CEC",
    tarifSecteur1Optam: "856.73",
    tarifHorsSecteur1HorsOptam: "856.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA019",
    designation:
      "Remplacement de l'isthme de l'aorte, par thoracotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "1142.2",
    tarifHorsSecteur1HorsOptam: "1142.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA023",
    designation:
      "Remplacement de l'isthme de l'aorte, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1288.25",
    tarifHorsSecteur1HorsOptam: "1142.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA009",
    designation:
      "Remplacement de l'aorte thoracique descendante, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1073.43",
    tarifHorsSecteur1HorsOptam: "885.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA007",
    designation:
      "Remplacement de l'aorte thoracique descendante, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1264.15",
    tarifHorsSecteur1HorsOptam: "1128.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA013",
    designation:
      "Remplacement de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie sans dérivation vasculaire ni CEC",
    tarifSecteur1Optam: "1824.97",
    tarifHorsSecteur1HorsOptam: "1431.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA016",
    designation:
      "Remplacement de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "2063.19",
    tarifHorsSecteur1HorsOptam: "1701.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA010",
    designation:
      "Remplacement de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie avec CEC",
    tarifSecteur1Optam: "2063.19",
    tarifHorsSecteur1HorsOptam: "1701.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA006",
    designation:
      "Remplacement de l'aorte thoracique descendante et de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie sans dérivation vasculaire ni CEC",
    tarifSecteur1Optam: "1958.26",
    tarifHorsSecteur1HorsOptam: "1508.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA020",
    designation:
      "Remplacement de l'aorte thoracique descendante et de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "2369.48",
    tarifHorsSecteur1HorsOptam: "1879.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA008",
    designation:
      "Remplacement de l'aorte thoracique descendante et de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie avec CEC",
    tarifSecteur1Optam: "2369.48",
    tarifHorsSecteur1HorsOptam: "1879.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA012",
    designation:
      "Remplacement de l'ensemble de l'aorte thoracique, par thoraco-phréno-laparotomie avec CEC",
    tarifSecteur1Optam: "2828.91",
    tarifHorsSecteur1HorsOptam: "2145.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA028",
    designation:
      "Remplacement de l'aorte thoracique ascendante pour rupture d'anévrisme, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2715.47",
    tarifHorsSecteur1HorsOptam: "1969.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA027",
    designation:
      "Remplacement de l'aorte thoracique descendante pour rupture d'anévrisme, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1807.95",
    tarifHorsSecteur1HorsOptam: "1443.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA029",
    designation:
      "Remplacement de l'aorte juxtadiaphragmatique pour rupture d'anévrisme, par thoraco-phréno-laparotomie avec CEC",
    tarifSecteur1Optam: "2215.82",
    tarifHorsSecteur1HorsOptam: "2215.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFAF002",
    designation:
      "Dilatation intraluminale d'une branche de l'artère pulmonaire sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFAF003",
    designation:
      "Dilatation intraluminale d'une branche de l'artère pulmonaire avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFAF004",
    designation:
      "Dilatation intraluminale de plusieurs branches de l'artère pulmonaire sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "532.95",
    tarifHorsSecteur1HorsOptam: "532.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFAF001",
    designation:
      "Dilatation intraluminale de plusieurs branches de l'artère pulmonaire avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "532.95",
    tarifHorsSecteur1HorsOptam: "532.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFNF001",
    designation:
      "Fibrinolyse in situ de l'artère pulmonaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFNF002",
    designation:
      "Thrombolyse mécanique ou thromboaspiration de l'artère pulmonaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFFA002",
    designation:
      "Embolectomie de l'artère pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "806.13",
    tarifHorsSecteur1HorsOptam: "687.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFFA001",
    designation:
      "Embolectomie de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "983.38",
    tarifHorsSecteur1HorsOptam: "921.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFFA003",
    designation:
      "Thromboendartériectomie du tronc et/ou des branches de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2127",
    tarifHorsSecteur1HorsOptam: "1584.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFSF001",
    designation:
      "Oblitération d'anévrisme sacculaire de l'artère pulmonaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECCA006",
    designation:
      "Suture de plaie du tronc artériel brachiocéphalique, par thoracotomie",
    tarifSecteur1Optam: "1031.6",
    tarifHorsSecteur1HorsOptam: "817.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECAF003",
    designation:
      "Dilatation intraluminale du tronc artériel brachiocéphalique ou de l'artère carotide commune intrathoracique sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECAF004",
    designation:
      "Dilatation intraluminale du tronc artériel brachiocéphalique ou de l'artère carotide commune intrathoracique avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECLF004",
    designation:
      "Pose d'endoprothèse couverte dans le tronc artériel brachiocéphalique ou l'artère carotide commune intrathoracique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECPF004",
    designation:
      "Recanalisation de l'artère subclavière en amont de l'ostium de l'artère vertébrale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECPF005",
    designation:
      "Recanalisation de l'artère subclavière en amont de l'ostium de l'artère vertébrale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECFA001",
    designation:
      "Thromboendartériectomie du tronc artériel brachiocéphalique, par thoracotomie",
    tarifSecteur1Optam: "1008.2",
    tarifHorsSecteur1HorsOptam: "804.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA032",
    designation:
      "Pontage entre l'aorte et le tronc artériel brachiocéphalique, par thoracotomie",
    tarifSecteur1Optam: "1052.16",
    tarifHorsSecteur1HorsOptam: "873.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECKA001",
    designation:
      "Remplacement du tronc artériel brachiocéphalique, par thoracotomie",
    tarifSecteur1Optam: "1031.6",
    tarifHorsSecteur1HorsOptam: "861.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECSA002",
    designation:
      "Ligature du tronc artériel brachiocéphalique, par thoracotomie",
    tarifSecteur1Optam: "524.57",
    tarifHorsSecteur1HorsOptam: "524.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA009",
    designation:
      "Suture de plaie de l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "458.72",
    tarifHorsSecteur1HorsOptam: "309.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA003",
    designation:
      "Suture de plaie de l'artère carotide commune, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "596.98",
    tarifHorsSecteur1HorsOptam: "565.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBAF004",
    designation:
      "Dilatation intraluminale de l'artère carotide commune cervicale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAF010",
    designation:
      "Dilatation intraluminale de l'artère carotide commune cervicale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBFA005",
    designation: "Thrombectomie de l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "424.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA003",
    designation:
      "Thromboendartériectomie de l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "632.43",
    tarifHorsSecteur1HorsOptam: "586.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA010",
    designation:
      "Thromboendartériectomie de l'artère carotide commune, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "777.77",
    tarifHorsSecteur1HorsOptam: "714.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA015",
    designation:
      "Pontage aortocarotidien, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "1065.63",
    tarifHorsSecteur1HorsOptam: "881.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA004",
    designation: "Pontage croisé intercarotidien, par cervicotomie",
    tarifSecteur1Optam: "796.92",
    tarifHorsSecteur1HorsOptam: "681.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA008",
    designation:
      "Pontage homolatéral carotidosubclavier ou carotidoaxillaire, par cervicotomie",
    tarifSecteur1Optam: "801.88",
    tarifHorsSecteur1HorsOptam: "684.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA001",
    designation:
      "Pontage croisé carotidosubclavier ou carotidoaxillaire, par cervicotomie",
    tarifSecteur1Optam: "850.09",
    tarifHorsSecteur1HorsOptam: "712.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA017",
    designation:
      "Pontage entre l'artère carotide commune et l'artère carotide interne homolatérale, par cervicotomie",
    tarifSecteur1Optam: "801.88",
    tarifHorsSecteur1HorsOptam: "684.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBEA005",
    designation:
      "Réimplantation de l'artère carotide commune dans l'artère subclavière, par cervicotomie",
    tarifSecteur1Optam: "826.69",
    tarifHorsSecteur1HorsOptam: "698.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBEA003",
    designation:
      "Réimplantation de l'artère subclavière dans l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "852.22",
    tarifHorsSecteur1HorsOptam: "713.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBKA001",
    designation: "Remplacement de l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "708.29",
    tarifHorsSecteur1HorsOptam: "630.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBKA003",
    designation:
      "Remplacement de l'artère carotide commune, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "848.1",
    tarifHorsSecteur1HorsOptam: "848.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA021",
    designation:
      "Résection-anastomose de l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA020",
    designation:
      "Résection-anastomose de l'artère carotide commune, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "621.79",
    tarifHorsSecteur1HorsOptam: "580.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA005",
    designation:
      "Occlusion progressive de l'artère carotide commune ou de l'artère carotide interne par pose de clamp, par cervicotomie",
    tarifSecteur1Optam: "177.96",
    tarifHorsSecteur1HorsOptam: "147.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA003",
    designation: "Ligature de l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "154.56",
    tarifHorsSecteur1HorsOptam: "133.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBAF009",
    designation:
      "Dilatation intraluminale de la bifurcation carotidienne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAF011",
    designation:
      "Dilatation intraluminale de la bifurcation carotidienne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAA002",
    designation:
      "Angioplastie d'élargissement de la bifurcation carotidienne sans thromboendartériectomie, par cervicotomie",
    tarifSecteur1Optam: "571.45",
    tarifHorsSecteur1HorsOptam: "550.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA012",
    designation:
      "Thromboendartériectomie de la bifurcation carotidienne sans angioplastie d'élargissement, par cervicotomie sans dérivation vasculaire",
    tarifSecteur1Optam: "627",
    tarifHorsSecteur1HorsOptam: "627",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA006",
    designation:
      "Thromboendartériectomie de la bifurcation carotidienne sans angioplastie d'élargissement, par cervicotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "627",
    tarifHorsSecteur1HorsOptam: "627",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA016",
    designation:
      "Thromboendartériectomie de la bifurcation carotidienne avec angioplastie d'élargissement, par cervicotomie sans dérivation vasculaire",
    tarifSecteur1Optam: "632.43",
    tarifHorsSecteur1HorsOptam: "630.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA002",
    designation:
      "Thromboendartériectomie de la bifurcation carotidienne avec angioplastie d'élargissement, par cervicotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "650.86",
    tarifHorsSecteur1HorsOptam: "640.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA008",
    designation:
      "Thromboendartériectomie de la bifurcation carotidienne par retournement, par cervicotomie sans dérivation vasculaire",
    tarifSecteur1Optam: "627",
    tarifHorsSecteur1HorsOptam: "627",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA015",
    designation:
      "Thromboendartériectomie de la bifurcation carotidienne par retournement, par cervicotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "627",
    tarifHorsSecteur1HorsOptam: "627",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA001",
    designation:
      "Exérèse d'une tumeur du glomus [corpuscule] carotidien avec conservation de l'axe carotidien, par cervicotomie",
    tarifSecteur1Optam: "840.87",
    tarifHorsSecteur1HorsOptam: "570.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA011",
    designation:
      "Exérèse d'une tumeur du glomus [corpuscule] carotidien avec reconstruction de l'axe carotidien, par cervicotomie",
    tarifSecteur1Optam: "1101.79",
    tarifHorsSecteur1HorsOptam: "902.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBKA004",
    designation:
      "Remplacement de la bifurcation carotidienne ou de l'artère carotide interne extracrânienne, par cervicotomie",
    tarifSecteur1Optam: "708.29",
    tarifHorsSecteur1HorsOptam: "674.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA007",
    designation:
      "Suture de plaie de l'artère carotide interne extracrânienne, par cervicotomie",
    tarifSecteur1Optam: "592.02",
    tarifHorsSecteur1HorsOptam: "387.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA006",
    designation:
      "Suture de plaie de l'artère carotide externe, par cervicotomie",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "190.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBAF003",
    designation:
      "Dilatation intraluminale de l'artère carotide interne extracrânienne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAF001",
    designation:
      "Dilatation intraluminale de l'artère carotide interne extracrânienne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAF005",
    designation:
      "Dilatation intraluminale de l'artère carotide externe sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAF006",
    designation:
      "Dilatation intraluminale de l'artère carotide externe avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBCA010",
    designation:
      "Pontage artériel extra-intracrânien sans autogreffe, par craniotomie et par cervicotomie",
    tarifSecteur1Optam: "526.22",
    tarifHorsSecteur1HorsOptam: "526.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA011",
    designation:
      "Pontage artériel extra-intracrânien avec autogreffe, par craniotomie et par cervicotomie",
    tarifSecteur1Optam: "750.12",
    tarifHorsSecteur1HorsOptam: "707.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA002",
    designation: "Pontage de l'artère carotide externe, par cervicotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA013",
    designation:
      "Pontage carotidohuméral ou subclaviohuméral, par abord direct",
    tarifSecteur1Optam: "623.92",
    tarifHorsSecteur1HorsOptam: "581.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA019",
    designation:
      "Résection-anastomose de l'artère carotide interne, par cervicotomie",
    tarifSecteur1Optam: "757.92",
    tarifHorsSecteur1HorsOptam: "659.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA014",
    designation:
      "Résection de l'artère carotide interne avec réimplantation dans l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "771.39",
    tarifHorsSecteur1HorsOptam: "666.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBNE002",
    designation:
      "Coagulation unilatérale de l'artère sphénopalatine, par endoscopie nasale",
    tarifSecteur1Optam: "150.15",
    tarifHorsSecteur1HorsOptam: "130.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBNE001",
    designation:
      "Coagulation bilatérale de l'artère sphénopalatine, par endoscopie nasale",
    tarifSecteur1Optam: "224.07",
    tarifHorsSecteur1HorsOptam: "195.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBSF003",
    designation:
      "Embolisation sélective ou hypersélective unilatérale ou bilatérale de branche de l'artère carotide externe, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBSF004",
    designation:
      "Embolisation suprasélective unilatérale ou bilatérale de branche de l'artère carotide externe, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBSA006",
    designation: "Ligature de l'artère carotide interne, par cervicotomie",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "163.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA010",
    designation:
      "Ligature du tronc ou de branche de l'artère carotide externe, par cervicotomie",
    tarifSecteur1Optam: "192.85",
    tarifHorsSecteur1HorsOptam: "155.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA001",
    designation:
      "Ligature de l'artère sphénopalatine ou de l'artère maxillaire, par abord transnasal ou transantral",
    tarifSecteur1Optam: "203.28",
    tarifHorsSecteur1HorsOptam: "161.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA008",
    designation:
      "Ligature unilatérale de l'artère ethmoïdale antérieure et/ou de l'artère ethmoïdale postérieure",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "140.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA004",
    designation:
      "Ligature bilatérale de l'artère ethmoïdale antérieure et/ou de l'artère ethmoïdale postérieure",
    tarifSecteur1Optam: "210.09",
    tarifHorsSecteur1HorsOptam: "210.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA009",
    designation:
      "Ligature de l'artère sphénopalatine et de l'artère ethmoïdale",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "210.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA009",
    designation:
      "Thromboendartériectomie de l'artère carotide externe, par cervicotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBKA002",
    designation: "Remplacement de l'artère carotide interne, par cervicotomie",
    tarifSecteur1Optam: "894.05",
    tarifHorsSecteur1HorsOptam: "738",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA016",
    designation:
      "Suture de plaie de l'artère vertébrale proximale, par cervicotomie",
    tarifSecteur1Optam: "708.29",
    tarifHorsSecteur1HorsOptam: "454.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBAF013",
    designation:
      "Dilatation intraluminale de l'artère vertébrale extracrânienne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBAF014",
    designation:
      "Dilatation intraluminale de l'artère vertébrale extracrânienne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAAF903",
    designation:
      "Dilatation intraluminale de l'artère vertébrale intracrânienne ou de l'artère basilaire sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAAF902",
    designation:
      "Dilatation intraluminale de l'artère vertébrale intracrânienne ou de l'artère basilaire avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBCA014",
    designation:
      "Pontage carotidovertébral proximal ou subclaviovertébral proximal, par cervicotomie",
    tarifSecteur1Optam: "808.26",
    tarifHorsSecteur1HorsOptam: "688.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA005",
    designation:
      "Pontage carotidovertébral distal ou subclaviovertébral distal, par cervicotomie",
    tarifSecteur1Optam: "1042.23",
    tarifHorsSecteur1HorsOptam: "823.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBEA002",
    designation:
      "Réimplantation de l'artère vertébrale proximale dans l'artère subclavière ou dans l'artère carotide commune, par cervicotomie",
    tarifSecteur1Optam: "867.11",
    tarifHorsSecteur1HorsOptam: "722.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBEA004",
    designation:
      "Réimplantation de l'artère vertébrale distale dans l'artère carotide interne ou dans l'artère carotide externe, par cervicotomie",
    tarifSecteur1Optam: "1057.83",
    tarifHorsSecteur1HorsOptam: "832.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA007",
    designation: "Ligature de l'artère vertébrale proximale, par cervicotomie",
    tarifSecteur1Optam: "200.57",
    tarifHorsSecteur1HorsOptam: "200.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA002",
    designation: "Ligature de l'artère vertébrale distale, par cervicotomie",
    tarifSecteur1Optam: "272.12",
    tarifHorsSecteur1HorsOptam: "272.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA018",
    designation:
      "Résection-anastomose ou remplacement de l'artère vertébrale proximale, par cervicotomie",
    tarifSecteur1Optam: "813.93",
    tarifHorsSecteur1HorsOptam: "691.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA017",
    designation:
      "Thromboendartériectomie de l'artère vertébrale proximale, par cervicotomie",
    tarifSecteur1Optam: "807.55",
    tarifHorsSecteur1HorsOptam: "687.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBPA003",
    designation:
      "Décompression de l'artère vertébrale intratransversaire, par cervicotomie",
    tarifSecteur1Optam: "659.45",
    tarifHorsSecteur1HorsOptam: "659.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACF002",
    designation:
      "Inversion du flux d'un anévrisme artériel intracrânien en dehors d'une période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EACF001",
    designation:
      "Inversion du flux d'un anévrisme artériel intracrânien en période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAAF004",
    designation:
      "Dilatation intraluminale du tronc de l'artère carotide interne intracrânienne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAAF002",
    designation:
      "Dilatation intraluminale du tronc de l'artère carotide interne intracrânienne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAAF901",
    designation:
      "Dilatation intraluminale de branche de l'artère carotide interne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAAF900",
    designation:
      "Dilatation intraluminale de branche de l'artère carotide interne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EANF002",
    designation:
      "Fibrinolyse in situ suprasélective d'artère intracrânienne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAJF341",
    designation:
      "Évacuation de thrombus d'artère intracrânienne par voie artérielle transcutanée",
    tarifSecteur1Optam: "942.2",
    tarifHorsSecteur1HorsOptam: "942.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAFA001",
    designation:
      "Embolectomie ou thromboendartériectomie de vaisseau intracrânien, par craniotomie",
    tarifSecteur1Optam: "535.9",
    tarifHorsSecteur1HorsOptam: "535.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EASF014",
    designation:
      "Embolisation sélective ou hypersélective unilatérale ou bilatérale de branche de l'artère carotide interne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF004",
    designation:
      "Embolisation suprasélective unilatérale ou bilatérale de branche de l'artère carotide interne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF011",
    designation:
      "Oblitération d'un anévrisme sacculaire artériel intracrânien en dehors d'une période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF010",
    designation:
      "Oblitération d'un anévrisme sacculaire artériel intracrânien en période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF001",
    designation:
      "Oblitération de plusieurs anévrismes sacculaires artériels intracrâniens en dehors d'une période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF013",
    designation:
      "Oblitération de plusieurs anévrismes sacculaires artériels intracrâniens en période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF008",
    designation:
      "Oblitération intraluminale d'une artère intracrânienne porteuse d'un anévrisme en dehors d'une période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF007",
    designation:
      "Oblitération intraluminale d'une artère intracrânienne porteuse d'un anévrisme en période aigüe hémorragique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EACA007",
    designation:
      "Exclusion d'un anévrisme artériel supratentoriel d'un diamètre inférieur ou égal à 20 mm, par craniotomie",
    tarifSecteur1Optam: "846.55",
    tarifHorsSecteur1HorsOptam: "754.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACA004",
    designation:
      "Exclusion d'un anévrisme artériel vertébrobasilaire d'un diamètre inférieur ou égal à 20 mm, par craniotomie",
    tarifSecteur1Optam: "1046.3",
    tarifHorsSecteur1HorsOptam: "1046.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACA003",
    designation:
      "Exclusion d'un anévrisme artériel intracrânien d'un diamètre supérieur à 20 mm, par craniotomie",
    tarifSecteur1Optam: "1349.94",
    tarifHorsSecteur1HorsOptam: "1046.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACA002",
    designation:
      "Exclusion d'anévrismes artériels intracrâniens multiples, par craniotomie unique",
    tarifSecteur1Optam: "1098.24",
    tarifHorsSecteur1HorsOptam: "944.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EABA001",
    designation:
      "Enrobage d'un anévrisme artériel intracrânien, par craniotomie",
    tarifSecteur1Optam: "730.89",
    tarifHorsSecteur1HorsOptam: "730.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA004",
    designation:
      "Suture de plaie d'une artère du membre supérieur, par abord direct",
    tarifSecteur1Optam: "216.25",
    tarifHorsSecteur1HorsOptam: "151.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA010",
    designation: "Suture de plaie de l'artère subclavière, par cervicotomie",
    tarifSecteur1Optam: "708.29",
    tarifHorsSecteur1HorsOptam: "454.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA005",
    designation: "Suture de plaie d’une artère, sur un rayon de la main",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA001",
    designation: "Suture de plaies de 2 artères, sur un rayon de la main",
    tarifSecteur1Optam: "365.75",
    tarifHorsSecteur1HorsOptam: "365.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECAF002",
    designation:
      "Dilatation intraluminale d'une artère du membre supérieur sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECAF001",
    designation:
      "Dilatation intraluminale d'une artère du membre supérieur avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECLF003",
    designation:
      "Pose d'endoprothèse couverte dans une artère du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECNF002",
    designation:
      "Fibrinolyse in situ sélective ou hypersélective d'une artère ou d'un pontage artériel du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECNF001",
    designation:
      "Fibrinolyse in situ suprasélective d'une artère ou d'un pontage artériel du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECPF003",
    designation:
      "Recanalisation d'une artère du membre supérieur sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECPF002",
    designation:
      "Recanalisation d'une artère du membre supérieur avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECPF001",
    designation:
      "Recanalisation d'une artère du membre supérieur avec pose d'endoprothèse couverte, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECJF001",
    designation:
      "Thromboaspiration d'artère ou de pontage artériel du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECFA002",
    designation:
      "Thrombectomie d'une artère du membre supérieur, par abord direct",
    tarifSecteur1Optam: "209.86",
    tarifHorsSecteur1HorsOptam: "209.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECFA004",
    designation:
      "Thromboendartériectomie de l'artère subclavière, par cervicotomie",
    tarifSecteur1Optam: "569.33",
    tarifHorsSecteur1HorsOptam: "549.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECFA003",
    designation:
      "Thromboendartériectomie de l'artère subclavière, par thoracotomie",
    tarifSecteur1Optam: "710.03",
    tarifHorsSecteur1HorsOptam: "710.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA007",
    designation: "Pontage homolatéral subclavioaxillaire, par abord direct",
    tarifSecteur1Optam: "525.37",
    tarifHorsSecteur1HorsOptam: "524.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA009",
    designation:
      "Pontage croisé intersubclavier, interaxillaire ou intersubclavioaxillaire, par cervicotomie",
    tarifSecteur1Optam: "850.09",
    tarifHorsSecteur1HorsOptam: "712.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA003",
    designation: "Pontage d'une artère du membre supérieur, par abord direct",
    tarifSecteur1Optam: "319.05",
    tarifHorsSecteur1HorsOptam: "316.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECCA002",
    designation: "Pontage d'une artère de la main, par abord direct",
    tarifSecteur1Optam: "462.27",
    tarifHorsSecteur1HorsOptam: "399.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECFA005",
    designation:
      "Résection-anastomose d'artère du membre supérieur, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECFA006",
    designation:
      "Résection-anastomose de l'artère subclavière, par cervicotomie",
    tarifSecteur1Optam: "758.63",
    tarifHorsSecteur1HorsOptam: "659.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECMA001",
    designation:
      "Reconstruction de l'artère et/ou de la veine subclavière avec résection de côte, par abord direct",
    tarifSecteur1Optam: "834.49",
    tarifHorsSecteur1HorsOptam: "729.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECKA002",
    designation: "Remplacement de l'artère subclavière, par cervicotomie",
    tarifSecteur1Optam: "808.97",
    tarifHorsSecteur1HorsOptam: "688.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECSF008",
    designation:
      "Embolisation sélective ou hypersélective d'une artère du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECSF002",
    designation:
      "Embolisation suprasélective d'une artère du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECNH001",
    designation:
      "Sclérose d'une tumeur ou d'une malformation vasculaire du membre supérieur, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage radiologique",
    tarifSecteur1Optam: "381.47",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECSF003",
    designation:
      "Oblitération d'un anévrisme sacculaire artériel du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECSF001",
    designation:
      "Oblitération de plusieurs anévrismes sacculaires artériels du membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "535.71",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECSA003",
    designation: "Ligature d'une artère du membre supérieur, par abord direct",
    tarifSecteur1Optam: "131.87",
    tarifHorsSecteur1HorsOptam: "102.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECSA001",
    designation: "Ligature de l'artère subclavière, par cervicotomie",
    tarifSecteur1Optam: "199.74",
    tarifHorsSecteur1HorsOptam: "199.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECLF005",
    designation:
      "Injection intraartérielle in situ sélective ou hypersélective d'agent pharmacologique anticancéreux au membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECLF006",
    designation:
      "Injection intraartérielle in situ suprasélective d'agent pharmacologique anticancéreux au membre supérieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBNF001",
    designation:
      "Fibrinolyse in situ sélective ou hypersélective d'une artère extracrânienne à destination cervicocéphalique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBNF002",
    designation:
      "Fibrinolyse in situ suprasélective d'une artère extracrânienne à destination cervicocéphalique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBFA013",
    designation:
      "Thrombectomie d'un pontage ou d'une thromboendartériectomie d'artère à destination cervicocéphalique, par cervicotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBFA004",
    designation:
      "Thrombectomie d'un pontage ou d'une thromboendartériectomie des troncs supraaortiques, par thoracotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBLF002",
    designation:
      "Injection intraartérielle cervicocéphalique in situ sélective ou hypersélective d'agent pharmacologique anticancéreux, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBLF003",
    designation:
      "Injection intraartérielle cervicocéphalique in situ suprasélective d'agent pharmacologique anticancéreux, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBLA001",
    designation:
      "Pose d'un cathéter dans une artère cervicocéphalique pour chimiothérapie, par cervicotomie",
    tarifSecteur1Optam: "145.35",
    tarifHorsSecteur1HorsOptam: "128.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBSA012",
    designation:
      "Hémostase secondaire à un acte sur les vaisseaux cervicocéphaliques, par cervicotomie",
    tarifSecteur1Optam: "315.51",
    tarifHorsSecteur1HorsOptam: "226.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA001",
    designation:
      "Suture de plaie de l'aorte abdominale, d'une artère iliaque commune et/ou d'une artère iliaque externe, par laparotomie",
    tarifSecteur1Optam: "571.45",
    tarifHorsSecteur1HorsOptam: "401.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAF008",
    designation:
      "Dilatation intraluminale de l'aorte abdominale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGAF005",
    designation:
      "Dilatation intraluminale de l'aorte abdominale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF002",
    designation:
      "Dilatation intraluminale de l'artère iliaque commune et/ou de l'artère iliaque externe sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF003",
    designation:
      "Dilatation intraluminale de l'artère iliaque commune et/ou de l'artère iliaque externe avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGPF001",
    designation:
      "Désobstruction de la bifurcation aortique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "512.05",
    tarifHorsSecteur1HorsOptam: "512.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGPF002",
    designation:
      "Recanalisation de la bifurcation aortique avec pose d'endoprothèse, par voie artérielle transcutanée bilatérale",
    tarifSecteur1Optam: "512.05",
    tarifHorsSecteur1HorsOptam: "512.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF008",
    designation:
      "Recanalisation de l'artère iliaque commune et/ou de l'artère iliaque externe sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF009",
    designation:
      "Recanalisation de l'artère iliaque commune et/ou de l'artère iliaque externe avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF006",
    designation:
      "Recanalisation de l'artère iliaque commune et/ou de l'artère iliaque externe avec pose d'endoprothèse couverte, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGFA010",
    designation:
      "Thrombectomie de l'aorte abdominale, de l'artère iliaque commune et/ou de l'artère iliaque externe, par laparotomie",
    tarifSecteur1Optam: "607.61",
    tarifHorsSecteur1HorsOptam: "549.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA004",
    designation:
      "Thrombectomie de l'aorte abdominale, de l'artère iliaque commune et/ou de l'artère iliaque externe, par abord inguinofémoral bilatéral",
    tarifSecteur1Optam: "548.63",
    tarifHorsSecteur1HorsOptam: "548.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA005",
    designation:
      "Thrombectomie d'un pontage ou d'une thromboendartériectomie de l'aorte abdominale ou de ses branches, par laparotomie",
    tarifSecteur1Optam: "625.34",
    tarifHorsSecteur1HorsOptam: "494.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA012",
    designation:
      "Thrombectomie d'un pontage ou d'une thromboendartériectomie de l'aorte abdominale ou de ses branches, par abord inguinofémoral",
    tarifSecteur1Optam: "490.63",
    tarifHorsSecteur1HorsOptam: "416.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA011",
    designation:
      "Thromboendartériectomie du tronc et de branche viscérale de l'aorte juxtadiaphragmatique, par thoraco-phréno-laparotomie sans CEC",
    tarifSecteur1Optam: "1645.59",
    tarifHorsSecteur1HorsOptam: "1327.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA001",
    designation:
      "Thromboendartériectomie du tronc de l'aorte abdominale, par laparotomie",
    tarifSecteur1Optam: "807.55",
    tarifHorsSecteur1HorsOptam: "687.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA009",
    designation:
      "Thromboendartériectomie aorto-iliaque unilatérale, par laparotomie",
    tarifSecteur1Optam: "763.59",
    tarifHorsSecteur1HorsOptam: "662.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA003",
    designation: "Thromboendartériectomie aortobisiliaque, par laparotomie",
    tarifSecteur1Optam: "1011.74",
    tarifHorsSecteur1HorsOptam: "915.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA007",
    designation:
      "Thromboendartériectomie aorto-ilio-fémorale unilatérale, par laparotomie",
    tarifSecteur1Optam: "879.16",
    tarifHorsSecteur1HorsOptam: "795.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA008",
    designation:
      "Thromboendartériectomie aorto-ilio-fémorale bilatérale, par laparotomie",
    tarifSecteur1Optam: "1264.86",
    tarifHorsSecteur1HorsOptam: "1117.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA006",
    designation: "Thromboendartériectomie iliaque, par laparotomie",
    tarifSecteur1Optam: "606.2",
    tarifHorsSecteur1HorsOptam: "571.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA003",
    designation: "Thromboendartériectomie iliofémorale, par laparotomie",
    tarifSecteur1Optam: "651.57",
    tarifHorsSecteur1HorsOptam: "597.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA007",
    designation:
      "Thromboendartériectomie iliofémorale, par abord inguinofémoral",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA012",
    designation:
      "Pontage aortoaortique infrarénal par laparotomie, avec clampage suprarénal",
    tarifSecteur1Optam: "894.76",
    tarifHorsSecteur1HorsOptam: "764.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA007",
    designation:
      "Pontage aortoaortique infrarénal par laparotomie, avec clampage infrarénal",
    tarifSecteur1Optam: "788.41",
    tarifHorsSecteur1HorsOptam: "702.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA005",
    designation: "Pontage aortohépatique, par laparotomie",
    tarifSecteur1Optam: "878.45",
    tarifHorsSecteur1HorsOptam: "728.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA016",
    designation:
      "Pontage antérograde aortomésentérique supérieur, par laparotomie",
    tarifSecteur1Optam: "916.03",
    tarifHorsSecteur1HorsOptam: "750.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA014",
    designation:
      "Pontage rétrograde aortomésentérique ou iliomésentérique supérieur, par laparotomie",
    tarifSecteur1Optam: "817.48",
    tarifHorsSecteur1HorsOptam: "693.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA018",
    designation:
      "Pontage aortorénal antérograde unilatéral, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "926.66",
    tarifHorsSecteur1HorsOptam: "756.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA024",
    designation:
      "Pontage aortorénal rétrograde unilatéral, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "801.88",
    tarifHorsSecteur1HorsOptam: "684.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA021",
    designation: "Pontage aortobirénal, par laparotomie",
    tarifSecteur1Optam: "1108.17",
    tarifHorsSecteur1HorsOptam: "971.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA019",
    designation:
      "Pontage aortofémoral unilatéral, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "891.92",
    tarifHorsSecteur1HorsOptam: "762.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA009",
    designation:
      "Pontage aortofémoral unilatéral, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "785.57",
    tarifHorsSecteur1HorsOptam: "700.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA026",
    designation:
      "Pontage bifurqué aortobisiliaque, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "1067.05",
    tarifHorsSecteur1HorsOptam: "973.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA022",
    designation:
      "Pontage bifurqué aortobisiliaque, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "960.7",
    tarifHorsSecteur1HorsOptam: "911.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA010",
    designation:
      "Pontage bifurqué aortobifémoral, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "1108.17",
    tarifHorsSecteur1HorsOptam: "971.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA004",
    designation:
      "Pontage bifurqué aortobifémoral, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "1001.82",
    tarifHorsSecteur1HorsOptam: "910.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA030",
    designation:
      "Pontage bifurqué aortobifémoral itératif [redux] sans ablation de prothèse, par laparotomie",
    tarifSecteur1Optam: "1302.43",
    tarifHorsSecteur1HorsOptam: "1084.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA020",
    designation:
      "Pontage bifurqué aorto-ilio-fémoral, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "1110.29",
    tarifHorsSecteur1HorsOptam: "998.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGCA029",
    designation:
      "Pontage bifurqué aorto-ilio-fémoral, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "1003.94",
    tarifHorsSecteur1HorsOptam: "937.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDCA003",
    designation:
      "Pontage artériel croisé ilio-iliaque, iliofémoral ou fémorofémoral, par abord direct",
    tarifSecteur1Optam: "551.6",
    tarifHorsSecteur1HorsOptam: "539.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA017",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal non rompu avec remplacement prothétique aortoaortique infrarénal, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "951.48",
    tarifHorsSecteur1HorsOptam: "796.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA005",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal non rompu avec remplacement prothétique aortoaortique infrarénal, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "845.13",
    tarifHorsSecteur1HorsOptam: "735.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA008",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal ou aortobisiliaque non rompu avec remplacement prothétique aortobisiliaque, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "1347.1",
    tarifHorsSecteur1HorsOptam: "1136.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA012",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal ou aortobisiliaque non rompu avec remplacement prothétique aortobisiliaque, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "1240.75",
    tarifHorsSecteur1HorsOptam: "1074.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA013",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal ou aortobisiliaque non rompu avec remplacement prothétique aortobifémoral, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "1295.34",
    tarifHorsSecteur1HorsOptam: "1106.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA010",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal ou aortobisiliaque non rompu avec remplacement prothétique aortobifémoral, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "1188.99",
    tarifHorsSecteur1HorsOptam: "1018.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA018",
    designation:
      "Mise à plat d'un anévrisme aortique infrarénal ou aortobisiliaque rompu avec remplacement prothétique, par laparotomie",
    tarifSecteur1Optam: "1644.88",
    tarifHorsSecteur1HorsOptam: "1308.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA001",
    designation:
      "Mise à plat d'un anévrisme aorto-ilio-fémoral avec remplacement prothétique bifurqué aorto-ilio-fémoral, par laparotomie avec clampage suprarénal",
    tarifSecteur1Optam: "1220.19",
    tarifHorsSecteur1HorsOptam: "1062.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGPA016",
    designation:
      "Mise à plat d'un anévrisme aorto-ilio-fémoral avec remplacement prothétique bifurqué aorto-ilio-fémoral, par laparotomie avec clampage infrarénal",
    tarifSecteur1Optam: "1113.84",
    tarifHorsSecteur1HorsOptam: "1000.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDPA005",
    designation:
      "Mise à plat d'un anévrisme iliaque avec remplacement prothétique aorto-iliaque ou aortofémoral unilatéral, par laparotomie",
    tarifSecteur1Optam: "856.47",
    tarifHorsSecteur1HorsOptam: "741.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDPA001",
    designation:
      "Mise à plat d'un anévrisme iliaque avec remplacement prothétique ilio-iliaque ou iliofémoral unilatéral, par laparotomie",
    tarifSecteur1Optam: "723.89",
    tarifHorsSecteur1HorsOptam: "664.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA015",
    designation:
      "Résection-anastomose de l'aorte abdominale ou de l'artère iliaque commune, par laparotomie",
    tarifSecteur1Optam: "604.78",
    tarifHorsSecteur1HorsOptam: "570.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA004",
    designation:
      "Remplacement de l'aorte abdominale ou de l'artère iliaque commune, par laparotomie",
    tarifSecteur1Optam: "665.04",
    tarifHorsSecteur1HorsOptam: "605.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGGA003",
    designation:
      "Ablation de prothèse de l'aorte abdominale avec pontage aortobisiliaque ou aortobifémoral, par laparotomie",
    tarifSecteur1Optam: "1738.47",
    tarifHorsSecteur1HorsOptam: "1396.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGGA002",
    designation:
      "Ablation de prothèse de l'aorte abdominale avec pontage axillobifémoral, par abord direct",
    tarifSecteur1Optam: "1896.58",
    tarifHorsSecteur1HorsOptam: "1429.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGLF012",
    designation:
      "Pose d’endoprothèse fenêtrée ou multibranche dans l’aorte abdominale pour anévrisme complexe, par voie artérielle transcutanée",
    tarifSecteur1Optam: "1693.09",
    tarifHorsSecteur1HorsOptam: "1693.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGLF005",
    designation:
      "Pose d'endoprothèse couverte rectiligne dans l'aorte abdominale infrarénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGLF002",
    designation:
      "Pose d'endoprothèse couverte aorto-uniiliaque, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGLF001",
    designation:
      "Pose d'endoprothèse couverte bifurquée aortobisiliaque, par voie artérielle transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF005",
    designation:
      "Pose d'endoprothèse couverte iliaque par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF004",
    designation:
      "Pose d'endoprothèse couverte dans l'artère iliaque commune et/ou l'artère iliaque externe avec embolisation de l'artère iliaque interne, par voie artérielle transcutanée",
    tarifSecteur1Optam: "574.75",
    tarifHorsSecteur1HorsOptam: "574.75",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGSA004",
    designation:
      "Fermeture de fistule aortodigestive ou iliodigestive avec pontage aortoaortique abdominal, par laparotomie",
    tarifSecteur1Optam: "1820.71",
    tarifHorsSecteur1HorsOptam: "1319.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGSA001",
    designation:
      "Fermeture de fistule aortodigestive ou iliodigestive avec pontage non anatomique de l'aorte abdominale, par abord direct",
    tarifSecteur1Optam: "1820.71",
    tarifHorsSecteur1HorsOptam: "1319.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGSA002",
    designation:
      "Fermeture de fistule artérioveineuse aortocave, iliocave ou ilio-iliaque avec pontage artériel, par laparotomie",
    tarifSecteur1Optam: "1161.6",
    tarifHorsSecteur1HorsOptam: "1161.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGSA005",
    designation:
      "Hémostase secondaire à un acte sur l'aorte abdominale ou ses branches, par laparotomie",
    tarifSecteur1Optam: "423.98",
    tarifHorsSecteur1HorsOptam: "316.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA006",
    designation:
      "Prélèvement de greffe artérielle aorto-iliaque par laparotomie, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDCC015",
    designation: "Suture de plaie d'artère digestive, par cœlioscopie",
    tarifSecteur1Optam: "772.55",
    tarifHorsSecteur1HorsOptam: "530.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDCA001",
    designation: "Suture de plaie d'artère digestive, par laparotomie",
    tarifSecteur1Optam: "743.74",
    tarifHorsSecteur1HorsOptam: "501.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDAF008",
    designation:
      "Dilatation intraluminale d'une artère digestive sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF005",
    designation:
      "Dilatation intraluminale d'une artère digestive avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDNF003",
    designation:
      "Fibrinolyse in situ d'une artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF003",
    designation:
      "Recanalisation d'une artère digestive sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF004",
    designation:
      "Recanalisation d'une artère digestive avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDJF002",
    designation:
      "Thromboaspiration d'artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "549.17",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDFA002",
    designation: "Thrombectomie d'artère digestive, par laparotomie",
    tarifSecteur1Optam: "650.86",
    tarifHorsSecteur1HorsOptam: "509.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA005",
    designation:
      "Thromboendartériectomie de l'artère mésentérique supérieure et/ou du tronc cœliaque ou de ses branches, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1329.38",
    tarifHorsSecteur1HorsOptam: "1025.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDKA003",
    designation: "Remplacement d'une artère digestive, par laparotomie",
    tarifSecteur1Optam: "866.4",
    tarifHorsSecteur1HorsOptam: "721.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDEA005",
    designation:
      "Réimplantation d'une artère digestive dans l'aorte, par laparotomie",
    tarifSecteur1Optam: "865.69",
    tarifHorsSecteur1HorsOptam: "721.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDLF006",
    designation:
      "Pose d'endoprothèse couverte dans une artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF008",
    designation:
      "Pose d'endoprothèse couverte dans plusieurs artères digestives, par voie artérielle transcutanée",
    tarifSecteur1Optam: "607.05",
    tarifHorsSecteur1HorsOptam: "532.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF009",
    designation:
      "Oblitération suprasélective d'un anévrisme sacculaire d'une artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF001",
    designation:
      "Oblitération suprasélective de plusieurs anévrismes sacculaires d'artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF005",
    designation:
      "Embolisation sélective ou hypersélective d'une artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF012",
    designation:
      "Embolisation sélective et/ou hypersélective de plusieurs artères digestives, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF006",
    designation:
      "Embolisation suprasélective d'une artère digestive, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF015",
    designation:
      "Embolisation suprasélective de plusieurs artères digestives, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSA001",
    designation: "Ligature d'une artère digestive, par laparotomie",
    tarifSecteur1Optam: "360.17",
    tarifHorsSecteur1HorsOptam: "279.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDLF014",
    designation:
      "Injection intraartérielle hépatique in situ sélective ou hypersélective d'agent pharmacologique anticancéreux non radio-isotopique sans embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF017",
    designation:
      "Injection intraartérielle hépatique in situ sélective ou hypersélective d'agent pharmacologique anticancéreux non radio-isotopique avec embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF015",
    designation:
      "Injection intraartérielle hépatique in situ suprasélective d'agent pharmacologique anticancéreux non radio-isotopique sans embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF016",
    designation:
      "Injection intraartérielle hépatique in situ suprasélective d'agent pharmacologique anticancéreux non radio-isotopique avec embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPA002",
    designation: "Libération d'une artère digestive, par laparotomie",
    tarifSecteur1Optam: "691.28",
    tarifHorsSecteur1HorsOptam: "471.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA010",
    designation: "Résection-anastomose d'une artère digestive, par laparotomie",
    tarifSecteur1Optam: "799.75",
    tarifHorsSecteur1HorsOptam: "683.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDLA001",
    designation:
      "Pose d'un cathéter dans une artère digestive, par laparotomie",
    tarifSecteur1Optam: "303.45",
    tarifHorsSecteur1HorsOptam: "246.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDCA002",
    designation:
      "Suture de plaie de l'artère rénale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "758.63",
    tarifHorsSecteur1HorsOptam: "510.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDAF007",
    designation:
      "Dilatation intraluminale sélective ou hypersélective de l'artère rénale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF001",
    designation:
      "Dilatation intraluminale sélective ou hypersélective de l'artère rénale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF009",
    designation:
      "Dilatation intraluminale suprasélective de l'artère rénale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF010",
    designation:
      "Dilatation intraluminale suprasélective de l'artère rénale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAA002",
    designation:
      "Angioplastie de l'artère rénale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "687.83",
    tarifHorsSecteur1HorsOptam: "687.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDNF002",
    designation:
      "Fibrinolyse in situ sélective ou hypersélective de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDNF001",
    designation:
      "Fibrinolyse in situ suprasélective de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF002",
    designation:
      "Recanalisation de l'artère rénale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF005",
    designation:
      "Recanalisation de l'artère rénale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDJF001",
    designation:
      "Thromboaspiration de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDFA008",
    designation:
      "Thrombectomie de l'artère rénale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "794.79",
    tarifHorsSecteur1HorsOptam: "592.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA001",
    designation:
      "Thromboendartériectomie de l'artère rénale par artériotomie rénale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "845.84",
    tarifHorsSecteur1HorsOptam: "710.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDFA004",
    designation:
      "Thromboendartériectomie des artères rénales par aortotomie, par laparotomie",
    tarifSecteur1Optam: "983.38",
    tarifHorsSecteur1HorsOptam: "899.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDKA002",
    designation:
      "Remplacement de l'artère rénale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "885.54",
    tarifHorsSecteur1HorsOptam: "733.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDEA004",
    designation:
      "Réimplantation de l'artère rénale dans l'aorte, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "859.31",
    tarifHorsSecteur1HorsOptam: "717.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDMA001",
    designation:
      "Revascularisation de l'artère rénale à partir d'une artère digestive, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "880.58",
    tarifHorsSecteur1HorsOptam: "730.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDMA003",
    designation:
      "Revascularisation des branches de l'artère rénale sur le rein in situ, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "1136.53",
    tarifHorsSecteur1HorsOptam: "878.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDMA004",
    designation:
      "Réparation de l'artère rénale et/ou de ses branches sur le rein ex situ avec autotransplantation du rein, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "1321.58",
    tarifHorsSecteur1HorsOptam: "1051.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDSF007",
    designation:
      "Oblitération sélective ou hypersélective d'un anévrisme sacculaire de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF013",
    designation:
      "Oblitération suprasélective d'un anévrisme sacculaire de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF003",
    designation:
      "Embolisation sélective ou hypersélective de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF008",
    designation:
      "Embolisation suprasélective de l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF020",
    designation:
      "Injection intraartérielle rénale in situ sélective ou hypersélective d'agent pharmacologique anticancéreux sans embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF019",
    designation:
      "Injection intraartérielle rénale in situ sélective ou hypersélective d'agent pharmacologique anticancéreux avec embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF021",
    designation:
      "Injection intraartérielle rénale in situ suprasélective d'agent pharmacologique anticancéreux sans embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF018",
    designation:
      "Injection intraartérielle rénale in situ suprasélective d'agent pharmacologique anticancéreux avec embolisation de particules, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ELSF001",
    designation:
      "Embolisation d'une fistule artérioveineuse rénale, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "464.37",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDLF013",
    designation:
      "Pose d'endoprothèse couverte dans l'artère rénale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDFA009",
    designation:
      "Résection-anastomose de l'artère rénale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "780.61",
    tarifHorsSecteur1HorsOptam: "672.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDAF004",
    designation:
      "Dilatation intraluminale de l'artère iliaque interne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDAF006",
    designation:
      "Dilatation intraluminale de l'artère iliaque interne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF007",
    designation:
      "Recanalisation de l'artère iliaque interne sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDPF001",
    designation:
      "Recanalisation de l'artère iliaque interne avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF002",
    designation:
      "Oblitération sélective ou hypersélective d'un anévrisme sacculaire de l'artère iliaque interne ou d'une branche extradigestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF010",
    designation:
      "Oblitération suprasélective d'un anévrisme sacculaire de l'artère iliaque interne ou d'une branche extradigestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF016",
    designation:
      "Embolisation sélective ou hypersélective de l'artère iliaque interne ou d'une branche extradigestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF014",
    designation:
      "Embolisation sélective et/ou hypersélective de plusieurs branches de l'artère iliaque interne ou de plusieurs branches extradigestives de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSF004",
    designation:
      "Embolisation suprasélective de branche de l'artère iliaque interne ou de branche extradigestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDSA003",
    designation: "Ligature des artères iliaques internes, par laparotomie",
    tarifSecteur1Optam: "372.23",
    tarifHorsSecteur1HorsOptam: "321.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELCA004",
    designation:
      "Anastomose entre l'artère ou la veine dorsale du pénis et l'artère épigastrique inférieure, par abord direct",
    tarifSecteur1Optam: "452.34",
    tarifHorsSecteur1HorsOptam: "350.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELCA003",
    designation:
      "Anastomose entre l'artère ou la veine dorsale du pénis et l'artère fémorale, par abord direct",
    tarifSecteur1Optam: "309.84",
    tarifHorsSecteur1HorsOptam: "309.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHCA007",
    designation:
      "Artérialisation des corps caverneux du pénis, par abord direct",
    tarifSecteur1Optam: "309.84",
    tarifHorsSecteur1HorsOptam: "309.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDSF618",
    designation:
      "Embolisation hypersélective des artères prostatiques, par voie artérielle transcutanée",
    tarifSecteur1Optam: "543.74",
    tarifHorsSecteur1HorsOptam: "543.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EDEA001",
    designation:
      "Réimplantation ou pontage de l'artère iliaque interne ou d'une de ses branches, par laparotomie",
    tarifSecteur1Optam: "796.92",
    tarifHorsSecteur1HorsOptam: "681.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDLF007",
    designation:
      "Pose d'endoprothèse couverte dans l'artère iliaque interne ou une branche extradigestive de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EECA009",
    designation:
      "Suture de plaie d'artère du membre inférieur, par abord direct",
    tarifSecteur1Optam: "211.28",
    tarifHorsSecteur1HorsOptam: "148.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEAF003",
    designation:
      "Dilatation intraluminale d'une artère du membre inférieur sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEAF004",
    designation:
      "Dilatation intraluminale d'une artère du membre inférieur avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEAF005",
    designation:
      "Dilatation intraluminale d'une artère du membre inférieur avec dilatation intraluminale de l'artère iliaque commune et/ou de l'artère iliaque externe homolatérale sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "491.15",
    tarifHorsSecteur1HorsOptam: "491.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEAF002",
    designation:
      "Dilatation intraluminale d'une artère du membre inférieur avec dilatation intraluminale de l'artère iliaque commune et/ou de l'artère iliaque externe homolatérale avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "491.15",
    tarifHorsSecteur1HorsOptam: "491.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEAF001",
    designation:
      "Dilatation intraluminale de plusieurs artères du membre inférieur sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEAF006",
    designation:
      "Dilatation intraluminale de plusieurs artères du membre inférieur avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "501.6",
    tarifHorsSecteur1HorsOptam: "501.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EENF002",
    designation:
      "Fibrinolyse in situ sélective ou hypersélective d'une artère ou d'un pontage artériel du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EENF001",
    designation:
      "Fibrinolyse in situ suprasélective d'une artère ou d'un pontage artériel du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEPF002",
    designation:
      "Recanalisation d'une artère du membre inférieur sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEPF001",
    designation:
      "Recanalisation d'une artère du membre inférieur avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEJF001",
    designation:
      "Thromboaspiration d'artère ou de pontage artériel du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEFA004",
    designation:
      "Thrombectomie d'artère du membre inférieur, par abord inguinofémoral",
    tarifSecteur1Optam: "228.3",
    tarifHorsSecteur1HorsOptam: "220.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEFA002",
    designation:
      "Thrombectomie d'artère du membre inférieur, par abord poplité",
    tarifSecteur1Optam: "270.13",
    tarifHorsSecteur1HorsOptam: "244.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEFA001",
    designation:
      "Thromboendartériectomie de l'artère fémorale et/ou de ses branches, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEFA003",
    designation:
      "Thromboendartériectomie de l'artère poplitée, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA007",
    designation:
      "Pontage artériel subclaviofémoral ou axillofémoral, par abord direct",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA006",
    designation:
      "Pontage artériel subclaviofémoral ou axillobifémoral, par abord direct",
    tarifSecteur1Optam: "661.5",
    tarifHorsSecteur1HorsOptam: "603.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDCA005",
    designation: "Pontage artériel iliofémoral homolatéral, par abord direct",
    tarifSecteur1Optam: "587.05",
    tarifHorsSecteur1HorsOptam: "585.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDCA004",
    designation:
      "Pontage artériel iliofémoral pour complication anastomotique sur prothèse de la bifurcation fémorale, par abord direct",
    tarifSecteur1Optam: "583.48",
    tarifHorsSecteur1HorsOptam: "583.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA002",
    designation: "Pontage artériel fémorofémoral homolatéral, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA001",
    designation:
      "Pontage artériel fémoropoplité au-dessus de l'interligne articulaire du genou, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA003",
    designation:
      "Pontage artériel fémoropoplité au-dessous de l'interligne articulaire du genou, par abord direct",
    tarifSecteur1Optam: "363.72",
    tarifHorsSecteur1HorsOptam: "342.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA008",
    designation:
      "Pontage artériel fémorotibial ou fémoropéronier sans collier veineux, par abord direct",
    tarifSecteur1Optam: "507.64",
    tarifHorsSecteur1HorsOptam: "451.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA010",
    designation:
      "Pontage artériel fémorotibial ou fémoropéronier avec collier veineux, par abord direct",
    tarifSecteur1Optam: "618.96",
    tarifHorsSecteur1HorsOptam: "516.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA005",
    designation: "Pontage d'une artère du pied, par abord direct",
    tarifSecteur1Optam: "749.41",
    tarifHorsSecteur1HorsOptam: "591.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EECA012",
    designation:
      "Pontage multiple étagé [séquentiel] ou bifurqué des artères des membres inférieurs, par abord direct",
    tarifSecteur1Optam: "798.33",
    tarifHorsSecteur1HorsOptam: "655.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEGA001",
    designation:
      "Ablation d'une prothèse artérielle du membre inférieur sans revascularisation, par abord direct",
    tarifSecteur1Optam: "336.07",
    tarifHorsSecteur1HorsOptam: "234.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEGA002",
    designation:
      "Ablation d'une prothèse artérielle du membre inférieur avec revascularisation, par abord direct",
    tarifSecteur1Optam: "746.58",
    tarifHorsSecteur1HorsOptam: "577.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EESF007",
    designation:
      "Embolisation sélective ou hypersélective d'une artère du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EESF004",
    designation:
      "Embolisation hypersélective de plusieurs artères du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EESF006",
    designation:
      "Embolisation suprasélective d'une artère du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EESF001",
    designation:
      "Oblitération d'un anévrisme sacculaire artériel du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EESF005",
    designation:
      "Oblitération de plusieurs anévrismes sacculaires artériels du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EESA001",
    designation:
      "Ligature d'une artère de la cuisse ou de la jambe, par abord direct",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EMNH001",
    designation:
      "Sclérose d'une tumeur ou d'une malformation vasculaire du membre inférieur, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage radiologique",
    tarifSecteur1Optam: "348.77",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EELF005",
    designation:
      "Injection intraartérielle in situ sélective ou hypersélective d'agent pharmacologique anticancéreux au membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EELF004",
    designation:
      "Injection intraartérielle in situ suprasélective d'agent pharmacologique anticancéreux au membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EELF002",
    designation:
      "Pose d'endoprothèse couverte dans une artère du membre inférieur, par voie artérielle transcutanée",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EEAA002",
    designation:
      "Angioplastie d'élargissement d'un pontage artériel du membre inférieur, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEFA006",
    designation:
      "Résection-anastomose d'une artère du membre inférieur, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEFA005",
    designation:
      "Résection d'un kyste adventiciel d'une artère du membre inférieur, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EMPA001",
    designation:
      "Libération d'une artère et/ou d'une veine poplitée piégée, par abord direct",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "194.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EEKA001",
    designation:
      "Remplacement ou mise à plat d'une artère du membre inférieur, par abord direct",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENAF002",
    designation:
      "Dilatation intraluminale d'un pontage artériel non anatomique des membres sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENAF001",
    designation:
      "Dilatation intraluminale d'un pontage artériel non anatomique des membres avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENNF001",
    designation:
      "Fibrinolyse in situ d'un pontage artériel non anatomique des membres, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENFF001",
    designation:
      "Thrombectomie ou embolectomie mécanique d'un pontage artériel non anatomique des membres, par voie artérielle transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENFA006",
    designation:
      "Thrombectomie de pontage artériel d'un membre sans réfection d'anastomose, par abord direct",
    tarifSecteur1Optam: "243.9",
    tarifHorsSecteur1HorsOptam: "229.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENFA001",
    designation:
      "Thrombectomie de pontage artériel d'un membre avec réfection d'anastomose sans prolongement du pontage, par abord direct",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENFA004",
    designation:
      "Thrombectomie de pontage artériel d'un membre avec réfection d'anastomose et prolongement du pontage, par abord direct",
    tarifSecteur1Optam: "577.84",
    tarifHorsSecteur1HorsOptam: "510.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENFA005",
    designation:
      "Changement d'un pontage artériel d'un membre avec thrombectomie de l'axe artériel, par abord direct",
    tarifSecteur1Optam: "557.27",
    tarifHorsSecteur1HorsOptam: "498.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDLF002",
    designation: "Pose d'un cathéter artériel ombilical",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZSA004",
    designation:
      "Hémostase secondaire à un acte sur les vaisseaux des membres, par abord direct",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "169.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENFA002",
    designation:
      "Prélèvement de greffe artérielle des membres, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EFCA001",
    designation:
      "Suture de plaie de veine profonde du membre supérieur ou du cou, par abord direct",
    tarifSecteur1Optam: "177.96",
    tarifHorsSecteur1HorsOptam: "138.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHCA003",
    designation:
      "Suture de plaie de la veine brachiocéphalique ou de la veine cave supérieure, par thoracotomie",
    tarifSecteur1Optam: "689.15",
    tarifHorsSecteur1HorsOptam: "619.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EFAF002",
    designation:
      "Dilatation intraluminale d'une veine du membre supérieur sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFAF001",
    designation:
      "Dilatation intraluminale d'une veine du membre supérieur avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFNF001",
    designation:
      "Fibrinolyse in situ de la veine subclavière et/ou de la veine brachiocéphalique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFPF002",
    designation:
      "Recanalisation d'une veine du membre supérieur sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFPF001",
    designation:
      "Recanalisation d'une veine du membre supérieur avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFLF001",
    designation:
      "Pose d'endoprothèse couverte dans une veine du membre supérieur, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFJF001",
    designation:
      "Thromboaspiration de la veine subclavière et/ou de la veine brachiocéphalique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EFFA001",
    designation:
      "Résection ou ligature d'une veine profonde du membre supérieur ou du cou, par abord direct",
    tarifSecteur1Optam: "117.69",
    tarifHorsSecteur1HorsOptam: "103.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EFFA002",
    designation: "Résection de la veine brachiocéphalique, par thoracotomie",
    tarifSecteur1Optam: "536.71",
    tarifHorsSecteur1HorsOptam: "530.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBEA001",
    designation:
      "Réimplantation de la veine jugulaire interne dans la veine axillaire, par abord direct",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EBCA012",
    designation: "Pontage veineux juguloaxillaire, par cervicotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHCA002",
    designation:
      "Pontage de la veine brachiocéphalique ou de la veine cave supérieure, par thoracotomie",
    tarifSecteur1Optam: "941.55",
    tarifHorsSecteur1HorsOptam: "809.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHAF003",
    designation:
      "Dilatation intraluminale de la veine cave supérieure sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHAF001",
    designation:
      "Dilatation intraluminale de la veine cave supérieure avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHNF005",
    designation:
      "Fibrinolyse in situ de la veine cave supérieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHPF001",
    designation:
      "Recanalisation de la veine cave supérieure sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHPF002",
    designation:
      "Recanalisation de la veine cave supérieure avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHNF001",
    designation:
      "Thrombolyse mécanique ou thromboaspiration de la veine cave supérieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "261.25",
    tarifHorsSecteur1HorsOptam: "261.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHPA001",
    designation: "Désobstruction de la veine cave supérieure, par thoracotomie",
    tarifSecteur1Optam: "655.12",
    tarifHorsSecteur1HorsOptam: "599.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJCA001",
    designation:
      "Suture de plaie de veine profonde de la jambe et/ou de la cuisse, par abord direct",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "179.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGCA002",
    designation:
      "Suture de plaie du tronc ou des branches viscérales de la veine iliaque et/ou de la veine cave inférieure infrahépatique, par laparotomie",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "363.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGAF003",
    designation:
      "Dilatation intraluminale de la veine iliaque externe et/ou de la veine iliaque commune sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGAF002",
    designation:
      "Dilatation intraluminale de la veine iliaque externe et/ou de la veine iliaque commune avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJNF001",
    designation:
      "Fibrinolyse in situ d'une veine du membre inférieur, par injection transcutanée dans une veine du dos du pied",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGNF002",
    designation:
      "Fibrinolyse in situ de la veine iliaque externe et/ou de la veine iliaque commune, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHNF002",
    designation:
      "Fibrinolyse in situ fémoro-ilio-cave, par voie veineuse transcutanée",
    tarifSecteur1Optam: "366.11",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHNF006",
    designation:
      "Fibrinolyse in situ fémoro-ilio-cave avec oblitération partielle de la veine cave inférieure [pose d'un filtre cave], par voie veineuse transcutanée",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGPF001",
    designation:
      "Recanalisation de la veine iliaque externe et/ou de la veine iliaque commune avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGJF002",
    designation:
      "Thromboaspiration de la veine iliaque externe et/ou de la veine iliaque commune, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGFA004",
    designation: "Thrombectomie veineuse fémoro-iliaque, par abord fémoral",
    tarifSecteur1Optam: "273.67",
    tarifHorsSecteur1HorsOptam: "246.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHFA003",
    designation:
      "Thrombectomie des veines iliaques et/ou de la veine cave inférieure, par laparotomie",
    tarifSecteur1Optam: "606.9",
    tarifHorsSecteur1HorsOptam: "536.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJCA002",
    designation: "Pontage veineux poplité-fémoral, par abord direct",
    tarifSecteur1Optam: "366.55",
    tarifHorsSecteur1HorsOptam: "344.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGCA003",
    designation:
      "Pontage veineux fémoro-iliaque homolatéral, fémorocave ou fémororénal, par abord direct",
    tarifSecteur1Optam: "573.58",
    tarifHorsSecteur1HorsOptam: "552.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJCA003",
    designation:
      "Pontage veineux croisé fémorofémoral ou fémoro-iliaque, par abord direct",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHCA004",
    designation: "Pontage veineux ilio-iliaque ou iliocave, par laparotomie",
    tarifSecteur1Optam: "579.25",
    tarifHorsSecteur1HorsOptam: "555.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJMA901",
    designation:
      "Valvuloplastie de veine superficielle du membre inférieur, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJMA900",
    designation:
      "Valvuloplastie de veine profonde du membre inférieur, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJBA001",
    designation:
      "Interposition d'un segment veineux valvulé sur la veine poplitée ou la veine fémorale, par abord direct",
    tarifSecteur1Optam: "405.55",
    tarifHorsSecteur1HorsOptam: "366.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJEA001",
    designation:
      "Réimplantation de la veine fémorale dans la veine grande saphène ou dans la veine profonde de la cuisse [veine fémorale profonde], par abord direct",
    tarifSecteur1Optam: "364.43",
    tarifHorsSecteur1HorsOptam: "343.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA009",
    designation:
      "Résection de la veine fémorale et/ou de la veine iliaque sans reconstruction, par abord direct",
    tarifSecteur1Optam: "648.74",
    tarifHorsSecteur1HorsOptam: "446.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA006",
    designation:
      "Résection de la veine fémorale et/ou de la veine iliaque avec reconstruction, par abord direct",
    tarifSecteur1Optam: "736.65",
    tarifHorsSecteur1HorsOptam: "558.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGPA001",
    designation: "Décompression de la veine iliaque, par laparotomie",
    tarifSecteur1Optam: "623.92",
    tarifHorsSecteur1HorsOptam: "432.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJFA007",
    designation:
      "Exérèse de la crosse de la grande veine saphène, par abord direct",
    tarifSecteur1Optam: "101.39",
    tarifHorsSecteur1HorsOptam: "85.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJFA004",
    designation:
      "Exérèse de la crosse de la petite veine saphène, par abord direct",
    tarifSecteur1Optam: "107.06",
    tarifHorsSecteur1HorsOptam: "88.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJFA006",
    designation:
      "Exérèse secondaire de la crosse de la grande veine saphène ou de la petite veine saphène, par abord direct",
    tarifSecteur1Optam: "285.73",
    tarifHorsSecteur1HorsOptam: "235.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJFA008",
    designation:
      "Exérèse de la grande veine saphène sans exérèse de la crosse, par abords directs multiples sous anesthésie locale",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJFA009",
    designation:
      "Exérèse de la petite veine saphène sans exérèse de la crosse, par abords directs multiples sous anesthésie locale",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJGA002",
    designation:
      "Extraction [Stripping] de la grande veine saphène, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJGA001",
    designation:
      "Extraction [Stripping] de la petite veine saphène, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJGA003",
    designation:
      "Extraction [Stripping] de la grande veine saphène et de la petite veine saphène, par abord direct",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "194",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJFB001",
    designation:
      "Séance d'exérèse de segment de varice ou de veine perforante du membre inférieur, par voie transcutanée sous anesthésie locale",
    tarifSecteur1Optam: "70.1",
    tarifHorsSecteur1HorsOptam: "66.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJFA002",
    designation:
      "Exérèses multiples de branches de la grande veine saphène et/ou de la petite veine saphène sous anesthésie générale ou locorégionale, par abord direct",
    tarifSecteur1Optam: "143.22",
    tarifHorsSecteur1HorsOptam: "122.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJNF002",
    designation:
      "Séance d'occlusion de veine du membre inférieur, par injection intraveineuse transcutanée de produit sclérosant sans guidage",
    tarifSecteur1Optam: "18.93",
    tarifHorsSecteur1HorsOptam: "18.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJNJ014",
    designation:
      "Occlusion de veine du membre inférieur par injection intraveineuse transcutanée de produit slérosant avec guidage échographique",
    tarifSecteur1Optam: "37.46",
    tarifHorsSecteur1HorsOptam: "37.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF007",
    designation:
      "Occlusion de la grande veine saphène par injection intraveineuse transcutanée de produit sclérosant avec guidage échographique",
    tarifSecteur1Optam: "94.64",
    tarifHorsSecteur1HorsOptam: "94.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF019",
    designation:
      "Occlusion de la petite veine saphène par injection intraveineuse transcutanée de produit sclérosant avec guidage échographique",
    tarifSecteur1Optam: "94.64",
    tarifHorsSecteur1HorsOptam: "94.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF021",
    designation:
      "Occlusion pour récidive d'insuffisance de la grande veine saphène au trigone fémoral [triangle de scarpa] et/ou de la petite veine saphène à la fosse poplitée [creux poplité] par injection intraveineuse transcutanée de produit sclérosant avec guidage échographique",
    tarifSecteur1Optam: "129.69",
    tarifHorsSecteur1HorsOptam: "129.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF466",
    designation:
      "Occlusion de la grande veine saphène au-dessus du tiers moyen de la jambe par laser, par voie veineuse transcutanée avec guidage échographique",
    tarifSecteur1Optam: "150.72",
    tarifHorsSecteur1HorsOptam: "150.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF418",
    designation:
      "Occlusion de la grande veine saphène au-dessus du tiers moyen de la jambe par laser, par voie veineuse transcutanée avec guidage échographique et phlébectomie homolatérale, et/ou ligature de veine perforante de la grande veine saphène homolatérale",
    tarifSecteur1Optam: "187.18",
    tarifHorsSecteur1HorsOptam: "187.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJSF475",
    designation:
      "Occlusion de la petite veine saphène au-dessus du tiers inférieur de la jambe par laser, par voie veineuse transcutanée avec guidage échographique",
    tarifSecteur1Optam: "157.02",
    tarifHorsSecteur1HorsOptam: "157.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF771",
    designation:
      "Occlusion de la petite veine saphène au-dessus du tiers inférieur de la jambe par laser, par voie veineuse transcutanée avec guidage échographique et phlébectomie homolatérale, et/ou ligature de veine perforante de la petite veine saphène homolatérale",
    tarifSecteur1Optam: "184.34",
    tarifHorsSecteur1HorsOptam: "184.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJSF008",
    designation:
      "Occlusion de la grande veine saphène au-dessus du tiers moyen de la jambe par radiofréquence, par voie veineuse transcutanée avec guidage échographique",
    tarifSecteur1Optam: "147.21",
    tarifHorsSecteur1HorsOptam: "107.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EJSF032",
    designation:
      "Occlusion de la grande veine saphène au-dessus du tiers moyen de la jambe par radiofréquence, par voie veineuse transcutanée avec guidage échographique et phlébectomie homolatérale, et/ou ligature de veine perforante de la grande veine saphène homolatérale",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "138.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJSA003",
    designation:
      "Ligature d'une veine profonde du membre inférieur, par abord direct",
    tarifSecteur1Optam: "138.96",
    tarifHorsSecteur1HorsOptam: "106.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJSC001",
    designation:
      "Ligature de veine perforante du membre inférieur, par vidéochirurgie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EJSA001",
    designation:
      "Ligature de plusieurs veines perforantes jambières, par abord direct",
    tarifSecteur1Optam: "185.05",
    tarifHorsSecteur1HorsOptam: "133.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQBP001",
    designation:
      "Contention veineuse fixe prenant au moins 2 segments de membre, par pose de bande collante ou cohésive",
    tarifSecteur1Optam: "14.02",
    tarifHorsSecteur1HorsOptam: "12.17",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHCA001",
    designation:
      "Suture de plaie de la veine cave inférieure rétrohépatique ou suprahépatique, ou des veines hépatiques, par laparotomie",
    tarifSecteur1Optam: "942.26",
    tarifHorsSecteur1HorsOptam: "765.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHAF002",
    designation:
      "Dilatation intraluminale de la veine cave inférieure sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHAF004",
    designation:
      "Dilatation intraluminale de la veine cave inférieure avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHNF004",
    designation:
      "Fibrinolyse in situ de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "366.11",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHNF003",
    designation:
      "Thrombolyse mécanique ou thromboaspiration de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "329.77",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHPF003",
    designation:
      "Recanalisation de la veine cave inférieure sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHFA002",
    designation:
      "Résection de la veine cave inférieure infrarénale sans reconstruction, par laparotomie",
    tarifSecteur1Optam: "740.2",
    tarifHorsSecteur1HorsOptam: "499.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHFA007",
    designation:
      "Résection de la veine cave inférieure infrarénale avec reconstruction, par laparotomie",
    tarifSecteur1Optam: "969.91",
    tarifHorsSecteur1HorsOptam: "782",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHFA004",
    designation:
      "Résection de la veine cave inférieure suprarénale infrahépatique sans reconstruction, par laparotomie",
    tarifSecteur1Optam: "962.82",
    tarifHorsSecteur1HorsOptam: "628.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHFA006",
    designation:
      "Résection de la veine cave inférieure suprarénale infrahépatique avec reconstruction, par laparotomie",
    tarifSecteur1Optam: "1260.6",
    tarifHorsSecteur1HorsOptam: "950.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHFA005",
    designation:
      "Résection de la veine cave inférieure suprarénale rétrohépatique et/ou suprahépatique sans reconstruction, par laparotomie",
    tarifSecteur1Optam: "1111",
    tarifHorsSecteur1HorsOptam: "714.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHFA001",
    designation:
      "Résection de la veine cave inférieure suprarénale rétrohépatique et/ou suprahépatique avec reconstruction, par laparotomie",
    tarifSecteur1Optam: "1454.16",
    tarifHorsSecteur1HorsOptam: "1062.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHSF001",
    designation:
      "Oblitération partielle temporaire de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHSF002",
    designation:
      "Oblitération partielle définitive de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHSA001",
    designation:
      "Oblitération partielle de la veine cave inférieure, par laparotomie",
    tarifSecteur1Optam: "285.02",
    tarifHorsSecteur1HorsOptam: "235.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHSA002",
    designation:
      "Ligature de la veine cave inférieure et/ou de l'une de ses branches viscérales, par laparotomie",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "267.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHGF001",
    designation:
      "Ablation d'un filtre temporaire de la veine cave inférieure, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHGA001",
    designation:
      "Ablation d'un filtre de la veine cave inférieure sans reconstruction de la veine, par laparotomie",
    tarifSecteur1Optam: "697.66",
    tarifHorsSecteur1HorsOptam: "474.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DHGA002",
    designation:
      "Ablation d'un filtre de la veine cave inférieure avec reconstruction de la veine, par laparotomie",
    tarifSecteur1Optam: "1011.74",
    tarifHorsSecteur1HorsOptam: "806.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGCA004",
    designation: "Anastomose saphénocaverneuse",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFC001",
    designation:
      "Résection ou ligature de la veine testiculaire, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA010",
    designation:
      "Résection ou ligature de la veine testiculaire, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA008",
    designation:
      "Résection ou ligature de la veine testiculaire par abord direct, avec embolisation intraveineuse",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "209.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGSA002",
    designation:
      "Ligature du plexus pampiniforme ou de la veine testiculaire, par abord scrotal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGSA001",
    designation:
      "Interruption des veines de drainage du pénis, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGSF002",
    designation:
      "Embolisation sélective ou hypersélective de la veine testiculaire ou ovarique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGSF001",
    designation:
      "Embolisation suprasélective de la veine testiculaire ou ovarique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGSF003",
    designation:
      "Embolisation des veines de drainage du pénis, par injection intraveineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGAF001",
    designation:
      "Dilatation intraluminale de la veine rénale sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "366.11",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGAF004",
    designation:
      "Dilatation intraluminale de la veine rénale avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGNF001",
    designation:
      "Fibrinolyse in situ de la veine rénale, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGJF001",
    designation:
      "Thromboaspiration de la veine rénale, par voie veineuse transcutanée",
    tarifSecteur1Optam: "549.17",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGFA005",
    designation: "Résection d'un paquet hémorroïdaire isolé",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA003",
    designation: "Hémorroïdectomie pédiculaire par résection sousmuqueuse",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "154.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA002",
    designation:
      "Hémorroïdectomie pédiculaire par résection sousmuqueuse avec anoplastie muqueuse postérieure et sphinctérotomie [léiomyotomie] interne",
    tarifSecteur1Optam: "219.08",
    tarifHorsSecteur1HorsOptam: "192.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA001",
    designation: "Hémorroïdectomie pédiculaire semifermée ou fermée",
    tarifSecteur1Optam: "229.72",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGLF002",
    designation: "Séance d'injection sclérosante des hémorroïdes",
    tarifSecteur1Optam: "24.45",
    tarifHorsSecteur1HorsOptam: "18.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGSP001",
    designation: "Séance de ligature élastique des hémorroïdes",
    tarifSecteur1Optam: "27.38",
    tarifHorsSecteur1HorsOptam: "23.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGNP001",
    designation:
      "Séance de traitement instrumental des hémorroïdes par procédé physique",
    tarifSecteur1Optam: "52.42",
    tarifHorsSecteur1HorsOptam: "52.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGJA001",
    designation: "Évacuation d'une thrombose hémorroïdaire externe",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGFA007",
    designation: "Excision d'une thrombose hémorroïdaire",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGED001",
    designation:
      "Réduction de procidence hémorroïdaire interne par agrafage circulaire, par voie anale",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "157.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDSD011",
    designation:
      "Ligature des artères hémorroïdaires avec guidage doppler, avec mucopexie, par voie anale",
    tarifSecteur1Optam: "266",
    tarifHorsSecteur1HorsOptam: "266",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA008",
    designation:
      "Suture de plaie de la veine porte et/ou de l'une de ses branches principales, par laparotomie",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "363.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHAF002",
    designation:
      "Dilatation intraluminale de la veine porte ou d'une anastomose portocave sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "450.91",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHAF001",
    designation:
      "Dilatation intraluminale de la veine porte ou d'une anastomose portocave avec pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHAF003",
    designation:
      "Dilatation intraluminale de veine hépatique [veine sushépatique] sans pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHAF004",
    designation:
      "Dilatation intraluminale d'une endoprothèse vasculaire intrahépatique pour court-circuit [shunt] vasculaire portosystémique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHAA001",
    designation: "Recalibrage d'une anastomose portocave, par laparotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHNF001",
    designation:
      "Fibrinolyse in situ de la veine porte et/ou de ses affluents ou d'un court-circuit [shunt] vasculaire portosystémique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHPF001",
    designation:
      "Recanalisation d'un court-circuit [shunt] vasculaire portosystémique, par voie veineuse transcutanée",
    tarifSecteur1Optam: "386.3",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHFA001",
    designation:
      "Thrombectomie de la veine porte et/ou de l'un de ses affluents principaux, par laparotomie",
    tarifSecteur1Optam: "694.11",
    tarifHorsSecteur1HorsOptam: "534.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCF002",
    designation:
      "Création d'un court-circuit [shunt] portosystémique intrahépatique par pose d'endoprothèse, par voie veineuse transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHCA003",
    designation:
      "Anastomose portocave tronculaire ou portorénale, par laparotomie",
    tarifSecteur1Optam: "816.06",
    tarifHorsSecteur1HorsOptam: "692.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA006",
    designation: "Anastomose mésentéricocave inférieure, par laparotomie",
    tarifSecteur1Optam: "924.54",
    tarifHorsSecteur1HorsOptam: "755.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA009",
    designation: "Anastomose mésentérico-ilio-cave, par laparotomie",
    tarifSecteur1Optam: "741.7",
    tarifHorsSecteur1HorsOptam: "741.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA007",
    designation:
      "Anastomose veineuse splénorénale centrale avec splénectomie, par laparotomie",
    tarifSecteur1Optam: "948.64",
    tarifHorsSecteur1HorsOptam: "813.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA004",
    designation:
      "Anastomose veineuse splénorénale distale sélective sans déconnexion portosystémique, par laparotomie",
    tarifSecteur1Optam: "791.46",
    tarifHorsSecteur1HorsOptam: "791.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA002",
    designation:
      "Anastomose veineuse splénorénale distale sélective avec déconnexion portosystémique, par laparotomie",
    tarifSecteur1Optam: "904.54",
    tarifHorsSecteur1HorsOptam: "904.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA005",
    designation:
      "Anastomose entre une branche du système porte autre que la veine splénique ou la veine mésentérique supérieure et le système cave inférieur, par laparotomie",
    tarifSecteur1Optam: "1052.16",
    tarifHorsSecteur1HorsOptam: "829.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA010",
    designation:
      "Anastomose entre une branche du système porte et le système cave supérieur, par abord direct",
    tarifSecteur1Optam: "841.62",
    tarifHorsSecteur1HorsOptam: "841.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHCA001",
    designation:
      "Pontage veineux mésentéricoatrial, par thoracotomie et par laparotomie",
    tarifSecteur1Optam: "807.91",
    tarifHorsSecteur1HorsOptam: "807.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EHBD001",
    designation: "Tamponnement de varices œsogastriques par sonde à ballonnet",
    tarifSecteur1Optam: "37.02",
    tarifHorsSecteur1HorsOptam: "37.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHSF001",
    designation:
      "Embolisation de varices œsogastriques ou des branches intrahépatiques de la veine porte, par voie veineuse transcutanée ou transpariétohépatique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHNE001",
    designation:
      "Séance de sclérose et/ou de ligature de varices œsogastriques en dehors de la période hémorragique, par endoscopie",
    tarifSecteur1Optam: "116.33",
    tarifHorsSecteur1HorsOptam: "116.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHNE002",
    designation:
      "Sclérose et/ou ligature de varices œsogastriques en période hémorragique, par endoscopie",
    tarifSecteur1Optam: "132.78",
    tarifHorsSecteur1HorsOptam: "132.78",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHLA001",
    designation:
      "Pose d'un système diffuseur implantable souscutané avec cathéter relié à une branche de la veine porte, par laparotomie",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPLF002",
    designation: "Pose d'un cathéter veineux central, par voie transcutanée",
    tarifSecteur1Optam: "63.2",
    tarifHorsSecteur1HorsOptam: "60.85",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBLA002",
    designation:
      "Pose d'un cathéter dans la veine jugulaire interne, par cervicotomie",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EGLF001",
    designation: "Pose d'un cathéter veineux ombilical",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DHLF001",
    designation: "Pose d'un cathéter épicutanéocave, par voie transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EALB002",
    designation:
      "Pose d'une voie d'abord vasculaire intraosseuse ou dans le sinus veineux sagittal [longitudinal] supérieur",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBLA003",
    designation:
      "Pose d'un cathéter relié à une veine profonde du membre supérieur ou du cou par voie transcutanée, avec pose d'un système diffuseur implantable souscutané",
    tarifSecteur1Optam: "138.96",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPPP002",
    designation:
      "Désobstruction de site implantable ou de voie veineuse centrale tunnellisée par agent thrombolytique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBGA001",
    designation:
      "Ablation d'un système diffuseur implanté et du cathéter relié à une veine profonde du membre supérieur ou du cou",
    tarifSecteur1Optam: "72.32",
    tarifHorsSecteur1HorsOptam: "59.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EFCA002",
    designation:
      "Suture de plaie de veine superficielle des membres ou du cou, par abord direct",
    tarifSecteur1Optam: "80.12",
    tarifHorsSecteur1HorsOptam: "64.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPJB015",
    designation:
      "Évacuation de thrombus de veine superficielle des membres ou du cou, par voie transcutanée",
    tarifSecteur1Optam: "23.51",
    tarifHorsSecteur1HorsOptam: "23.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPFA005",
    designation:
      "Exérèse de malformation veineuse cutanée et/ou souscutanée, sans reconstruction",
    tarifSecteur1Optam: "75.86",
    tarifHorsSecteur1HorsOptam: "57.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPFA004",
    designation:
      "Exérèse de malformation veineuse musculaire ou osseuse, sans reconstruction",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "328.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPFA001",
    designation:
      "Exérèse d'une malformation veineuse étendue, avec reconstruction tissulaire par greffe ou lambeau",
    tarifSecteur1Optam: "709",
    tarifHorsSecteur1HorsOptam: "482.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EFSA001",
    designation:
      "Ligature de veine superficielle des membres ou du cou, par abord direct",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPFA003",
    designation:
      "Prélèvement de greffe veineuse des membres, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPNF001",
    designation:
      "Séance de sclérose de varicosités et télangiectasies des veines, par injection intraveineuse transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBSF001",
    designation:
      "Occlusion d'une fistule artérioveineuse directe cervicale ou crânienne, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBSH001",
    designation:
      "Occlusion d'une malformation vasculaire cervicale et/ou faciale, par injection transcutanée intralésionnelle de colle avec guidage radiologique",
    tarifSecteur1Optam: "375.41",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBNH001",
    designation:
      "Sclérose d'une malformation vasculaire cervicale et/ou faciale, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage radiologique",
    tarifSecteur1Optam: "199.82",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBFA007",
    designation:
      "Exérèse d'une malformation vasculaire de la région parotidienne avec dissection du nerf facial, par abord direct",
    tarifSecteur1Optam: "591.31",
    tarifHorsSecteur1HorsOptam: "500.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA003",
    designation:
      "Exérèse de malformation artérioveineuse cérébrale superficielle supratentorielle d'un diamètre inférieur ou égal à 30 mm, par craniotomie",
    tarifSecteur1Optam: "912.48",
    tarifHorsSecteur1HorsOptam: "704.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA009",
    designation:
      "Exérèse de malformation artérioveineuse cérébrale superficielle supratentorielle d'un diamètre supérieur à 30 mm, par craniotomie",
    tarifSecteur1Optam: "1139.36",
    tarifHorsSecteur1HorsOptam: "924.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA008",
    designation:
      "Exérèse de malformation artérioveineuse cérébrale profonde supratentorielle, par craniotomie",
    tarifSecteur1Optam: "1150.71",
    tarifHorsSecteur1HorsOptam: "930.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA005",
    designation:
      "Exérèse de malformation artérioveineuse encéphalique infratentorielle, par craniotomie",
    tarifSecteur1Optam: "1028.76",
    tarifHorsSecteur1HorsOptam: "860.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA006",
    designation:
      "Exérèse d'une tumeur du glomus [corpuscule] tympanojugulaire par abord transpétreux, sans déroutement du nerf facial",
    tarifSecteur1Optam: "1164.24",
    tarifHorsSecteur1HorsOptam: "850.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA002",
    designation:
      "Exérèse d'une tumeur du glomus [corpuscule] tympanojugulaire par abord transpétreux, avec déroutement du nerf facial",
    tarifSecteur1Optam: "919.66",
    tarifHorsSecteur1HorsOptam: "919.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA004",
    designation:
      "Exérèse d'une tumeur du glomus [corpuscule] tympanojugulaire étendue à la fosse postérieure, par craniotomie",
    tarifSecteur1Optam: "1243.56",
    tarifHorsSecteur1HorsOptam: "1243.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAFA007",
    designation:
      "Exérèse d'une tumeur du glomus [corpuscule] tympanojugulaire étendue aux structures cervicales, par craniocervicotomie",
    tarifSecteur1Optam: "713.71",
    tarifHorsSecteur1HorsOptam: "713.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EASF006",
    designation:
      "Embolisation d'une fistule artérioveineuse durale cranioencéphalique unipédiculaire, par voie artérielle ou veineuse transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF009",
    designation:
      "Embolisation d'une fistule artérioveineuse durale cranioencéphalique unipédiculaire, par voie artérielle et par voie veineuse transcutanées",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF015",
    designation:
      "Embolisation d'une fistule artérioveineuse durale cranioencéphalique multipédiculaire, par voie artérielle ou veineuse transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF005",
    designation:
      "Embolisation d'une fistule artérioveineuse durale cranioencéphalique multipédiculaire, par voie artérielle et par voie veineuse transcutanées",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF002",
    designation:
      "Occlusion intraluminale d'un vaisseau intracrânien afférent à une tumeur, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF003",
    designation:
      "Occlusion intraluminale de plusieurs vaisseaux intracrâniens afférents à une tumeur, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASF012",
    designation:
      "Occlusion intraluminale d'un vaisseau intracrânien, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EASA001",
    designation:
      "Ligature de vaisseau intracrânien supratentoriel, par craniotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EASA002",
    designation:
      "Ligature de vaisseau intracrânien infratentoriel, par craniotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACA006",
    designation:
      "Exclusion de fistule artérioveineuse durale de la convexité, par craniotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACA005",
    designation:
      "Exclusion de fistule artérioveineuse d'un sinus veineux dural [fistule ostéodurale], par craniotomie",
    tarifSecteur1Optam: "563.66",
    tarifHorsSecteur1HorsOptam: "502.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EACA001",
    designation:
      "Exclusion de fistule artérioveineuse de la faux du cerveau ou de la tente du cervelet, par craniotomie",
    tarifSecteur1Optam: "711.84",
    tarifHorsSecteur1HorsOptam: "588.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EKSA002",
    designation:
      "Fermeture de fistule artérioveineuse traumatique du membre supérieur sans reconstruction vasculaire, par abord direct",
    tarifSecteur1Optam: "204.9",
    tarifHorsSecteur1HorsOptam: "189.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EKSA001",
    designation:
      "Fermeture de fistule artérioveineuse traumatique du membre supérieur avec reconstruction de l'axe artériel, par abord direct",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "459.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFSA002",
    designation:
      "Fermeture de fistule ou exclusion d'anévrisme artérioveineux pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "643.06",
    tarifHorsSecteur1HorsOptam: "592.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA001",
    designation:
      "Suture de plaie d'artère pulmonaire ou de veine pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "672.13",
    tarifHorsSecteur1HorsOptam: "609.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFSF002",
    designation:
      "Embolisation de fistule artérioveineuse intrathoracique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECSF004",
    designation:
      "Embolisation sélective ou hypersélective d'artère à destination bronchique ou pleuropulmonaire, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ECSF006",
    designation:
      "Embolisation suprasélective d'artère à destination bronchique ou pleuropulmonaire, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DFFA004",
    designation:
      "Exérèse de tumeur des vaisseaux pulmonaires, par thoracotomie sans CEC",
    tarifSecteur1Optam: "757.21",
    tarifHorsSecteur1HorsOptam: "702.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENNH001",
    designation:
      "Sclérose d'une malformation vasculaire ou d'une lésion vertébrale, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage radiologique",
    tarifSecteur1Optam: "394.79",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENSF001",
    designation:
      "Embolisation d'une malformation vasculaire ou d'une lésion vertébrale, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENSF002",
    designation:
      "Embolisation de malformation artérioveineuse intraparenchymateuse de la moelle épinière, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENFA007",
    designation:
      "Exérèse de malformation artérioveineuse intraparenchymateuse de la moelle épinière, par abord direct",
    tarifSecteur1Optam: "836",
    tarifHorsSecteur1HorsOptam: "836",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENSF003",
    designation:
      "Embolisation de malformation artérioveineuse durale spinale, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ENCA001",
    designation:
      "Exclusion de malformation artérioveineuse durale rachidienne, par abord direct",
    tarifSecteur1Optam: "419.02",
    tarifHorsSecteur1HorsOptam: "374.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELSC001",
    designation:
      "Ligature ou section du pédicule testiculaire [spermatique] pour cryptorchidie, par cœlioscopie",
    tarifSecteur1Optam: "199.23",
    tarifHorsSecteur1HorsOptam: "197.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELSA001",
    designation:
      "Ligature ou section du pédicule testiculaire [spermatique] pour cryptorchidie, par laparotomie",
    tarifSecteur1Optam: "198.52",
    tarifHorsSecteur1HorsOptam: "185.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EMSA002",
    designation:
      "Fermeture d'une fistule artérioveineuse traumatique du membre inférieur sans reconstruction vasculaire, par abord direct",
    tarifSecteur1Optam: "252.4",
    tarifHorsSecteur1HorsOptam: "216.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EMSA001",
    designation:
      "Fermeture d'une fistule artérioveineuse traumatique du membre inférieur avec reconstruction vasculaire, par abord direct",
    tarifSecteur1Optam: "479.28",
    tarifHorsSecteur1HorsOptam: "444.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPLA002",
    designation:
      "Pose d'un cathéter veineux central tunnellisé à double courant pour circulation extracorporelle, par abord direct",
    tarifSecteur1Optam: "146.76",
    tarifHorsSecteur1HorsOptam: "120.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPLF005",
    designation:
      "Pose d'un cathéter veineux central non tunnellisé pour circulation extracorporelle, par voie transcutanée",
    tarifSecteur1Optam: "61.59",
    tarifHorsSecteur1HorsOptam: "61.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPLF003",
    designation:
      "Pose d'un cathéter veineux central tunnellisé pour circulation extracorporelle, par voie transcutanée",
    tarifSecteur1Optam: "89.55",
    tarifHorsSecteur1HorsOptam: "76.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPLB002",
    designation:
      "Pose d'un cathéter central dans 2 veines différentes pour circulation extracorporelle, par voie transcutanée",
    tarifSecteur1Optam: "106.86",
    tarifHorsSecteur1HorsOptam: "98.27",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPLF004",
    designation:
      "Pose de 2 cathéters centraux tunnellisés dans une seule veine pour circulation extracorporelle, par voie transcutanée",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EMLA001",
    designation:
      "Pose d'une dérivation artérioveineuse fémorale, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EKLA001",
    designation:
      "Pose d'une dérivation artérioveineuse au membre supérieur, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZLA001",
    designation:
      "Implantation souscutanée d'un site d'accès vasculaire pour circulation extracorporelle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZKA001",
    designation:
      "Changement de la membrane d'un site d'accès vasculaire implanté pour circulation extracorporelle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZMA004",
    designation:
      "Création d'une fistule artérioveineuse pour accès vasculaire par abord direct sans superficialisation veineuse, chez un sujet de moins de 20 kg",
    tarifSecteur1Optam: "536.71",
    tarifHorsSecteur1HorsOptam: "442.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZMA001",
    designation:
      "Création d'une fistule artérioveineuse pour accès vasculaire par abord direct sans superficialisation veineuse, chez un sujet de plus de 20 kg",
    tarifSecteur1Optam: "220.5",
    tarifHorsSecteur1HorsOptam: "215.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZMA002",
    designation:
      "Création d'une fistule artérioveineuse pour accès vasculaire par abord direct avec superficialisation veineuse, chez un sujet de moins de 20 kg",
    tarifSecteur1Optam: "579.25",
    tarifHorsSecteur1HorsOptam: "458.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZMA003",
    designation:
      "Création d'une fistule artérioveineuse pour accès vasculaire par abord direct avec superficialisation veineuse, chez un sujet de plus de 20 kg",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZCA003",
    designation:
      "Pontage artérioveineux pour accès vasculaire, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZAF001",
    designation:
      "Dilatation intraluminale d'un accès vasculaire artérioveineux d'un membre sans pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZAF002",
    designation:
      "Dilatation intraluminale d'un accès vasculaire artérioveineux d'un membre avec pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZPP002",
    designation:
      "Désobstruction locale d'un dispositif de dérivation artérioveineuse des membres",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPPP003",
    designation:
      "Désobstruction locale d'un cathéter veineux pour circulation extracorporelle",
    tarifSecteur1Optam: "20.9",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZPF004",
    designation:
      "Désobstruction mécanique d'un accès vasculaire artérioveineux avec dilatation intraluminale sans pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZPF003",
    designation:
      "Désobstruction mécanique d'un accès vasculaire artérioveineux avec dilatation intraluminale et pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZNF004",
    designation:
      "Fibrinolyse in situ d'un accès vasculaire artérioveineux des membres, par injection intravasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZNF001",
    designation:
      "Fibrinolyse in situ d'un accès vasculaire artérioveineux d'un membre avec dilatation intraluminale sans pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZNF002",
    designation:
      "Fibrinolyse in situ d'un accès vasculaire artérioveineux d'un membre avec dilatation intraluminale et pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZJF002",
    designation:
      "Thromboaspiration d'un accès vasculaire artérioveineux d'un membre avec dilatation intraluminale sans pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZJF001",
    designation:
      "Thromboaspiration d'un accès vasculaire artérioveineux d'un membre avec dilatation intraluminale et pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPPF001",
    designation:
      "Recanalisation de la veine de drainage d'un accès vasculaire artérioveineux sans pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPPF003",
    designation:
      "Recanalisation de la veine de drainage d'un accès vasculaire artérioveineux avec pose d'endoprothèse, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZPA001",
    designation:
      "Désobstruction d'un accès vasculaire artérioveineux, par abord direct",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPCA001",
    designation:
      "Superficialisation veineuse après création d'une fistule artérioveineuse, par abord direct",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPCA002",
    designation:
      "Pontage ou angioplastie d'élargissement du tronc veineux axillosubclavier ou fémoral en aval d'un accès vasculaire artérioveineux, par abord direct",
    tarifSecteur1Optam: "367.97",
    tarifHorsSecteur1HorsOptam: "345.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZCA004",
    designation:
      "Pontage ou angioplastie d'élargissement d'un accès vasculaire artérioveineux, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZFA002",
    designation:
      "Exérèse d'un accès vasculaire artérioveineux sans reconstruction vasculaire",
    tarifSecteur1Optam: "179.38",
    tarifHorsSecteur1HorsOptam: "143.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZFA003",
    designation:
      "Exérèse d'un accès vasculaire artérioveineux avec reconstruction vasculaire",
    tarifSecteur1Optam: "377.19",
    tarifHorsSecteur1HorsOptam: "350.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZBA002",
    designation:
      "Réduction du volume d'un anévrisme d'un accès vasculaire artérioveineux",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZBA003",
    designation:
      "Réduction du débit d'un accès vasculaire artérioveineux par ligature ou réduction de calibre de l'artère",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZBA001",
    designation:
      "Réduction du débit d'un accès vasculaire artérioveineux avec réfection de l'anastomose artérielle",
    tarifSecteur1Optam: "277.22",
    tarifHorsSecteur1HorsOptam: "248.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZCA002",
    designation:
      "Exclusion d'un accès vasculaire artérioveineux avec pontage, par abord direct",
    tarifSecteur1Optam: "375.77",
    tarifHorsSecteur1HorsOptam: "349.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZSA001",
    designation:
      "Fermeture d'un accès vasculaire artérioveineux, par abord direct",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "121.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZSA003",
    designation:
      "Fermeture d'un faux anévrisme d'un accès vasculaire artérioveineux avec reconstruction des axes vasculaires, par abord direct",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZSA002",
    designation:
      "Hémostase d'un accès vasculaire artérioveineux, par abord direct",
    tarifSecteur1Optam: "228.3",
    tarifHorsSecteur1HorsOptam: "180.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZFA001",
    designation:
      "Exérèse de malformation artérioveineuse cutanée et/ou souscutanée",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "217.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZFA004",
    designation:
      "Exérèse de malformation artérioveineuse musculaire ou osseuse",
    tarifSecteur1Optam: "709",
    tarifHorsSecteur1HorsOptam: "511.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZSM001",
    designation:
      "Fermeture d'un faux anévrisme ou d'une fistule artérioveineuse, par compression transcutanée avec guidage échographique",
    tarifSecteur1Optam: "9.6",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DZSA002",
    designation:
      "Hémostase secondaire à un acte sur le cœur et/ou les vaisseaux intrathoraciques, par thoracotomie",
    tarifSecteur1Optam: "447.38",
    tarifHorsSecteur1HorsOptam: "347.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELGA001",
    designation:
      "Ablation d'un cathéter intravasculaire abdominal, par laparotomie",
    tarifSecteur1Optam: "174.41",
    tarifHorsSecteur1HorsOptam: "171.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAMF001",
    designation:
      "Création d'une communication interatriale, par voie veineuse transcutanée",
    tarifSecteur1Optam: "595.65",
    tarifHorsSecteur1HorsOptam: "595.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAMA003",
    designation: "Création d'une communication interatriale, par thoracotomie",
    tarifSecteur1Optam: "592.72",
    tarifHorsSecteur1HorsOptam: "563.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAAF003",
    designation:
      "Agrandissement d'une communication interatriale, par voie veineuse transcutanée",
    tarifSecteur1Optam: "595.65",
    tarifHorsSecteur1HorsOptam: "595.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASF004",
    designation:
      "Fermeture d'une communication interatriale, par voie veineuse transcutanée",
    tarifSecteur1Optam: "595.65",
    tarifHorsSecteur1HorsOptam: "595.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASF005",
    designation:
      "Fermeture d'un foramen ovale perméable, par voie veineuse transcutanée",
    tarifSecteur1Optam: "819.46",
    tarifHorsSecteur1HorsOptam: "819.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA003",
    designation:
      "Fermeture d'une communication interatriale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "731.5",
    tarifHorsSecteur1HorsOptam: "731.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAMA005",
    designation:
      "Correction d'une malformation sténosante intraatriale droite ou gauche congénitale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "947.93",
    tarifHorsSecteur1HorsOptam: "857.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAAA001",
    designation:
      "Agrandissement d'une communication interventriculaire ou du foramen bulboventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "978.75",
    tarifHorsSecteur1HorsOptam: "978.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASF003",
    designation:
      "Fermeture de communication interventriculaire, par voie veineuse transcutanée",
    tarifSecteur1Optam: "595.65",
    tarifHorsSecteur1HorsOptam: "595.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA012",
    designation:
      "Fermeture d'une communication interventriculaire sans pose d'un conduit extracardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1106.04",
    tarifHorsSecteur1HorsOptam: "948.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA001",
    designation:
      "Fermeture d'une communication interventriculaire avec pose d'un conduit extracardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2010.72",
    tarifHorsSecteur1HorsOptam: "1649.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA004",
    designation:
      "Fermeture d'une communication interventriculaire pour discordance atrioventriculaire et transposition ou malposition des gros vaisseaux, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1315.51",
    tarifHorsSecteur1HorsOptam: "1315.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA014",
    designation:
      "Fermeture d'une communication interventriculaire avec résection musculaire cardiaque intraventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1184.03",
    tarifHorsSecteur1HorsOptam: "1081.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA009",
    designation:
      "Fermeture d'une communication interventriculaire avec correction d'une sténose de l'aorte thoracique horizontale et/ou de l'isthme aortique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1480.37",
    tarifHorsSecteur1HorsOptam: "1480.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA007",
    designation:
      "Fermeture d'une communication interventriculaire avec correction d'une sténose de l'aorte thoracique horizontale et/ou de l'isthme aortique, avec commissurotomie ou valvectomie pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1605.16",
    tarifHorsSecteur1HorsOptam: "1605.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA011",
    designation:
      "Fermeture de communications interventriculaires multiples, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1444.94",
    tarifHorsSecteur1HorsOptam: "1233.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA010",
    designation:
      "Fermeture de communications interventriculaires multiples, avec résection musculaire cardiaque intraventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1615.1",
    tarifHorsSecteur1HorsOptam: "1419.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA002",
    designation:
      "Fermeture d'une communication atrioventriculaire sans geste sur le septum interventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1164.89",
    tarifHorsSecteur1HorsOptam: "1070.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA008",
    designation:
      "Fermeture d'une communication atrioventriculaire avec geste sur le septum interventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1672.53",
    tarifHorsSecteur1HorsOptam: "1452.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DASA013",
    designation:
      "Fermeture d'une communication atrioventriculaire, avec geste sur le septum interventriculaire et correction d'une sténose de la voie pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1597.61",
    tarifHorsSecteur1HorsOptam: "1597.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBPA003",
    designation:
      "Commissurotomie [Valvulotomie] ou valvectomie [valvulectomie] pulmonaire, par thoracotomie avec clampage cave",
    tarifSecteur1Optam: "618.03",
    tarifHorsSecteur1HorsOptam: "618.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBPA001",
    designation:
      "Commissurotomie [Valvulotomie] ou valvectomie [valvulectomie] pulmonaire avec fermeture d'une communication interatriale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "940.5",
    tarifHorsSecteur1HorsOptam: "940.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA007",
    designation:
      "Résection musculaire cardiaque intraventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1157.8",
    tarifHorsSecteur1HorsOptam: "978.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAFA010",
    designation:
      "Résection d'une sténose congénitale infraorificielle de l'aorte, par thoracotomie avec CEC",
    tarifSecteur1Optam: "843",
    tarifHorsSecteur1HorsOptam: "796.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAAA003",
    designation:
      "Plastie d'agrandissement de l'infundibulum pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "789.83",
    tarifHorsSecteur1HorsOptam: "765.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAAA002",
    designation:
      "Plastie d'agrandissement de l'infundibulum pulmonaire avec section de l'anneau pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "940.5",
    tarifHorsSecteur1HorsOptam: "940.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA012",
    designation:
      "Réparation à l'étage atrial de la transposition des gros vaisseaux, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1783.14",
    tarifHorsSecteur1HorsOptam: "1429.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA007",
    designation:
      "Réparation à l'étage artériel de la transposition des gros vaisseaux, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2031.29",
    tarifHorsSecteur1HorsOptam: "1660.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA006",
    designation:
      "Réparation à l'étage atrial d'une transposition ou d'une malposition des gros vaisseaux avec fermeture d'une communication interventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2088.71",
    tarifHorsSecteur1HorsOptam: "1606.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA004",
    designation:
      "Réparation à l'étage artériel d'une transposition ou d'une malposition des gros vaisseaux avec fermeture d'une communication interventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2212.08",
    tarifHorsSecteur1HorsOptam: "1765.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA001",
    designation:
      "Réparation d'une malposition des gros vaisseaux avec communication interventriculaire, sans conduit extracardiaque ni mobilisation de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2085.17",
    tarifHorsSecteur1HorsOptam: "1692.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA010",
    designation:
      "Réparation d'une malposition des gros vaisseaux avec communication interventriculaire, par pose de conduit extracardiaque ou mobilisation de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "2087.3",
    tarifHorsSecteur1HorsOptam: "1693.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA003",
    designation:
      "Réparation anatomique à l'étage artériel et atrial de la discordance atrioventriculaire et de la transposition ou malposition des gros vaisseaux avec communication interventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1857.08",
    tarifHorsSecteur1HorsOptam: "1857.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA002",
    designation:
      "Réparation anatomique de la discordance atrioventriculaire et de la transposition ou malposition des gros vaisseaux avec communication interventriculaire et sténose pulmonaire à l'étage ventriculaire et atrial, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1857.08",
    tarifHorsSecteur1HorsOptam: "1857.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA006",
    designation:
      "Anastomose entre le tronc de l'artère pulmonaire et l'aorte thoracique ascendante, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1579.65",
    tarifHorsSecteur1HorsOptam: "1267.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA012",
    designation:
      "Réparation d'une atrésie de l'artère pulmonaire avec fermeture d'une communication interventriculaire sans prothèse [tube valvé ou non], par thoracotomie avec CEC",
    tarifSecteur1Optam: "1302.31",
    tarifHorsSecteur1HorsOptam: "1302.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA011",
    designation:
      "Réparation d'une atrésie de l'artère pulmonaire avec fermeture d'une communication interventriculaire avec prothèse [tube valvé ou non], par thoracotomie avec CEC",
    tarifSecteur1Optam: "1863.25",
    tarifHorsSecteur1HorsOptam: "1497.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA002",
    designation:
      "Anastomose cavopulmonaire fonctionnellement terminoterminale, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1048.61",
    tarifHorsSecteur1HorsOptam: "827.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA008",
    designation:
      "Anastomose cavopulmonaire fonctionnellement terminoterminale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1081.45",
    tarifHorsSecteur1HorsOptam: "1081.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA005",
    designation:
      "Anastomose cavopulmonaire bidirectionnelle, par thoracotomie sans CEC",
    tarifSecteur1Optam: "884.81",
    tarifHorsSecteur1HorsOptam: "884.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA007",
    designation:
      "Anastomose cavopulmonaire bidirectionnelle, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1221.61",
    tarifHorsSecteur1HorsOptam: "1059.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA004",
    designation:
      "Anastomose cavopulmonaire ou atriopulmonaire totale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1615.81",
    tarifHorsSecteur1HorsOptam: "1419.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA005",
    designation:
      "Réparation de la tétralogie de Fallot sans section de l'anneau pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1499.54",
    tarifHorsSecteur1HorsOptam: "1352.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA011",
    designation:
      "Réparation de la tétralogie de Fallot avec section de l'anneau pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1460.54",
    tarifHorsSecteur1HorsOptam: "1329.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZMA009",
    designation:
      "Réparation du tronc artériel commun [truncus arteriosus], par thoracotomie avec CEC",
    tarifSecteur1Optam: "1958.26",
    tarifHorsSecteur1HorsOptam: "1618.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA010",
    designation:
      "Anastomose entre le tronc pulmonaire et l'aorte avec anastomose systémicopulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1691.22",
    tarifHorsSecteur1HorsOptam: "1691.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDSA002",
    designation:
      "Fermeture de fistule coronarocardiaque, par thoracotomie sans CEC",
    tarifSecteur1Optam: "638.48",
    tarifHorsSecteur1HorsOptam: "638.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDSA001",
    designation:
      "Fermeture de fistule coronarocardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "894.76",
    tarifHorsSecteur1HorsOptam: "826.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDEA001",
    designation:
      "Réimplantation d'une artère coronaire pour anomalie congénitale d'origine, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1386.1",
    tarifHorsSecteur1HorsOptam: "1286.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAF006",
    designation:
      "Dilatation intraluminale d'une coarctation de l'aorte thoracique sans pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGAF004",
    designation:
      "Dilatation intraluminale d'une coarctation de l'aorte thoracique avec pose d'endoprothèse, par voie artérielle transcutanée",
    tarifSecteur1Optam: "397.1",
    tarifHorsSecteur1HorsOptam: "397.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGMA009",
    designation:
      "Plastie d'une sténose supraorificielle de l'aorte, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1184.74",
    tarifHorsSecteur1HorsOptam: "1169.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGMA011",
    designation:
      "Correction d'une interruption de l'aorte thoracique horizontale sans prothèse, par thoracotomie sans CEC",
    tarifSecteur1Optam: "799.27",
    tarifHorsSecteur1HorsOptam: "799.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGMA001",
    designation:
      "Correction d'une interruption de l'aorte thoracique horizontale avec prothèse, par thoracotomie sans CEC",
    tarifSecteur1Optam: "843.16",
    tarifHorsSecteur1HorsOptam: "843.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGMA012",
    designation:
      "Correction d'une interruption de l'aorte thoracique horizontale sans prothèse, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1189.7",
    tarifHorsSecteur1HorsOptam: "1041.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGMA010",
    designation:
      "Correction d'une interruption de l'aorte thoracique horizontale avec prothèse, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1109.71",
    tarifHorsSecteur1HorsOptam: "1109.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGMA005",
    designation:
      "Correction d'une interruption de l'aorte thoracique horizontale avec réparation de lésion intracardiaque associée, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1400.28",
    tarifHorsSecteur1HorsOptam: "1229.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGMA002",
    designation:
      "Correction d'une interruption de l'aorte thoracique horizontale sans prothèse, avec réparation du tronc artériel commun [truncus arteriosus], par thoracotomie avec CEC",
    tarifSecteur1Optam: "1379.4",
    tarifHorsSecteur1HorsOptam: "1379.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAA005",
    designation:
      "Angioplastie d'élargissement ou résection-anastomose d'une sténose de l'aorte thoracique horizontale et de l'isthme aortique, par thoracotomie sans CEC",
    tarifSecteur1Optam: "888.38",
    tarifHorsSecteur1HorsOptam: "734.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAA004",
    designation:
      "Angioplastie d'élargissement ou résection-anastomose d'une sténose de l'aorte thoracique horizontale et de l'isthme aortique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "992.6",
    tarifHorsSecteur1HorsOptam: "970.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAA002",
    designation:
      "Angioplastie d'élargissement ou résection-anastomose d'une sténose de l'aorte thoracique horizontale et de l'isthme aortique avec réparation de lésion intracardiaque, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1317.32",
    tarifHorsSecteur1HorsOptam: "1268.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA020",
    designation:
      "Résection-anastomose de l'isthme de l'aorte, par thoracotomie sans CEC, avant l'âge de 3 ans",
    tarifSecteur1Optam: "604.07",
    tarifHorsSecteur1HorsOptam: "569.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA016",
    designation:
      "Résection-anastomose de l'isthme de l'aorte, par thoracotomie sans dérivation vasculaire ni CEC, après l'âge de 3 ans",
    tarifSecteur1Optam: "738.78",
    tarifHorsSecteur1HorsOptam: "647.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA013",
    designation:
      "Résection-anastomose de l'isthme de l'aorte, par thoracotomie avec dérivation vasculaire",
    tarifSecteur1Optam: "917.45",
    tarifHorsSecteur1HorsOptam: "751.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGFA018",
    designation:
      "Résection-anastomose de l'isthme de l'aorte, par thoracotomie avec CEC",
    tarifSecteur1Optam: "871.73",
    tarifHorsSecteur1HorsOptam: "871.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA022",
    designation:
      "Remplacement de l'isthme de l'aorte pour coarctation, par thoracotomie sans CEC",
    tarifSecteur1Optam: "752.28",
    tarifHorsSecteur1HorsOptam: "752.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA024",
    designation:
      "Remplacement de l'isthme de l'aorte pour coarctation, par thoracotomie avec CEC",
    tarifSecteur1Optam: "994.98",
    tarifHorsSecteur1HorsOptam: "994.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA017",
    designation:
      "Remplacement de l'aorte thoracique descendante pour sténose congénitale, par thoracotomie sans CEC",
    tarifSecteur1Optam: "752.28",
    tarifHorsSecteur1HorsOptam: "752.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGKA021",
    designation:
      "Remplacement de l'aorte thoracique descendante pour sténose congénitale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1081.23",
    tarifHorsSecteur1HorsOptam: "1022.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGAF003",
    designation:
      "Dilatation intraluminale d'une coarctation de l'aorte abdominale, par voie artérielle transcutanée",
    tarifSecteur1Optam: "850.67",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DASF001",
    designation:
      "Fermeture du conduit artériel, par voie vasculaire transcutanée",
    tarifSecteur1Optam: "595.65",
    tarifHorsSecteur1HorsOptam: "595.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAPC001",
    designation: "Interruption du conduit artériel, par thoracoscopie sans CEC",
    tarifSecteur1Optam: "627.79",
    tarifHorsSecteur1HorsOptam: "627.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DAPA001",
    designation: "Interruption du conduit artériel, par thoracotomie sans CEC",
    tarifSecteur1Optam: "538.13",
    tarifHorsSecteur1HorsOptam: "531.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFBA001",
    designation: "Cerclage de l'artère pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "645.19",
    tarifHorsSecteur1HorsOptam: "593.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFEA001",
    designation:
      "Réimplantation d'une artère pulmonaire pour anomalie d'origine, par thoracotomie",
    tarifSecteur1Optam: "1184.74",
    tarifHorsSecteur1HorsOptam: "906.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA009",
    designation:
      "Anastomose artérielle systémicopulmonaire sans prothèse, par thoracotomie sans CEC",
    tarifSecteur1Optam: "743.76",
    tarifHorsSecteur1HorsOptam: "743.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFCA003",
    designation:
      "Anastomose artérielle systémicopulmonaire avec prothèse, par thoracotomie sans CEC",
    tarifSecteur1Optam: "777.06",
    tarifHorsSecteur1HorsOptam: "714.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA003",
    designation:
      "Unifocalisation artérielle pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "1023.39",
    tarifHorsSecteur1HorsOptam: "1023.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA006",
    designation:
      "Unifocalisation artérielle pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1701.6",
    tarifHorsSecteur1HorsOptam: "1469.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFAA003",
    designation:
      "Angioplastie d'élargissement d'une sténose congénitale du tronc de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "836",
    tarifHorsSecteur1HorsOptam: "836",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFAA004",
    designation:
      "Angioplastie d'élargissement d'une sténose congénitale de la bifurcation de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1149.5",
    tarifHorsSecteur1HorsOptam: "1149.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFAA005",
    designation:
      "Angioplastie d'élargissement d'une sténose congénitale d'une branche de l'artère pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "779.19",
    tarifHorsSecteur1HorsOptam: "671.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFAA002",
    designation:
      "Angioplastie d'élargissement d'une sténose congénitale d'une branche de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1054.28",
    tarifHorsSecteur1HorsOptam: "962.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFSA001",
    designation:
      "Suppression de circulation systémique pulmonaire, par thoracotomie",
    tarifSecteur1Optam: "644.65",
    tarifHorsSecteur1HorsOptam: "644.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA008",
    designation:
      "Réparation du retour veineux pulmonaire anormal partiel, par thoracotomie avec CEC",
    tarifSecteur1Optam: "969.2",
    tarifHorsSecteur1HorsOptam: "913.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA004",
    designation:
      "Réparation du retour veineux pulmonaire anormal total, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1691.67",
    tarifHorsSecteur1HorsOptam: "1463.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA001",
    designation:
      "Réparation d'une obstruction du retour veineux pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1574.69",
    tarifHorsSecteur1HorsOptam: "1264.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBLA001",
    designation:
      "Pose d'un tube valvé ou non entre un ventricule et l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1074.84",
    tarifHorsSecteur1HorsOptam: "974.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBLA002",
    designation:
      "Pose d'un tube valvé entre un ventricule et l'aorte, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1123.77",
    tarifHorsSecteur1HorsOptam: "1002.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DBLA003",
    designation:
      "Pose d'un tube valvé entre le ventricule gauche et l'aorte [tube apicoaortique], par thoraco-phréno-laparotomie avec CEC",
    tarifSecteur1Optam: "1268.55",
    tarifHorsSecteur1HorsOptam: "1268.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGSA003",
    designation:
      "Fermeture d'une fenêtre [fistule] aortopulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1099.66",
    tarifHorsSecteur1HorsOptam: "988.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGDA001",
    designation: "Aortopexie rétrosternale",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ECPA003",
    designation:
      "Section-anastomose d'un vaisseau pour anomalie des arcs aortiques, par thoracotomie sans CEC",
    tarifSecteur1Optam: "762.88",
    tarifHorsSecteur1HorsOptam: "661.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPMA001",
    designation:
      "Correction d'une anomalie du retour veineux systémique sans prothèse, par thoracotomie avec CEC",
    tarifSecteur1Optam: "967.96",
    tarifHorsSecteur1HorsOptam: "967.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPMA002",
    designation:
      "Correction d'une anomalie du retour veineux systémique avec prothèse, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1011.84",
    tarifHorsSecteur1HorsOptam: "1011.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZSA005",
    designation:
      "Fermeture d'une communication artérioveineuse tronculaire congénitale [d'un shunt artérioveineux tronculaire congénital], par abord direct",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFGA003",
    designation: "Ablation d'un cerclage pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "637.39",
    tarifHorsSecteur1HorsOptam: "545.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFGA001",
    designation: "Ablation d'un cerclage pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "749.71",
    tarifHorsSecteur1HorsOptam: "749.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFGA004",
    designation:
      "Ablation d'un cerclage pulmonaire avec fermeture d'une communication interventriculaire unique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1302.43",
    tarifHorsSecteur1HorsOptam: "1150.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFGA002",
    designation:
      "Ablation d'un cerclage pulmonaire avec fermeture de communications interventriculaires multiples, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1444.94",
    tarifHorsSecteur1HorsOptam: "1320.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DFMA010",
    designation:
      "Démontage d'une anastomose cavopulmonaire totale, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1414.54",
    tarifHorsSecteur1HorsOptam: "1414.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DZSA001",
    designation:
      "Suppression d'une anastomose palliative pour cardiopathie congénitale, avec angioplastie d'agrandissement d'une branche de l'artère pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1154.96",
    tarifHorsSecteur1HorsOptam: "1021",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DKMD002",
    designation:
      "Ressuscitation cardiorespiratoire avec intubation trachéale, dans un bloc médicotechnique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DKMD001",
    designation:
      "Ressuscitation cardiorespiratoire avec intubation trachéale, en dehors d'un bloc médicotechnique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLF005",
    designation:
      "Administration intraveineuse continue d'agent pharmacologique vasoactif chez le nouveau-né pour suppléance hémodynamique, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLF001",
    designation:
      "Injection intraveineuse continue de dobutamine ou de dopamine à débit inférieur à 8 microgrammes par kilogramme par minute [µg/kg/min], ou de dopexamine en dehors de la période néonatale, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLF003",
    designation:
      "Injection intraveineuse continue de dobutamine ou de dopamine à débit supérieur à 8 microgrammes par kilogramme par minute [µg/kg/min], d'adrénaline ou de noradrénaline en dehors de la période néonatale, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLF002",
    designation:
      "Perfusion intraveineuse de produit de remplissage à un débit supérieur à 50 millilitres par kilogramme [ml/kg] en moins de 24 heures, chez l'adulte",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF004",
    designation:
      "Transfusion de concentré de globules rouges d'un volume supérieur à une demimasse sanguine chez l'adulte ou à 40 millilitres par kilogramme [ml/kg] chez le nouveau-né en moins de 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGLF006",
    designation:
      "Pose d'un ballon de contrepulsion diastolique intraaortique, par voie artérielle transcutanée",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGLA002",
    designation:
      "Pose d'un ballon de contrepulsion diastolique intraaortique, par abord artériel périphérique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGGA004",
    designation:
      "Ablation d'un dispositif de contrepulsion diastolique intraaortique avec rétablissement de la continuité artérielle, par abord artériel périphérique",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DGGA001",
    designation:
      "Ablation d'un dispositif de contrepulsion diastolique intraaortique, par thoracotomie",
    tarifSecteur1Optam: "271.18",
    tarifHorsSecteur1HorsOptam: "271.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQMP001",
    designation:
      "Suppléance hémodynamique par dispositif de contrepulsion diastolique intraaortique, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQMF003",
    designation:
      "Suppléance hémodynamique et ventilatoire d'un patient en état de mort encéphalique, en vue de prélèvement d'organe",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLA002",
    designation:
      "Pose d'une circulation extracorporelle en urgence pour assistance circulatoire, par abord vasculaire périphérique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA001",
    designation:
      "Pose d'une circulation extracorporelle pour assistance circulatoire de longue durée, par thoracotomie",
    tarifSecteur1Optam: "394.91",
    tarifHorsSecteur1HorsOptam: "360.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQGA001",
    designation:
      "Ablation d'une circulation extracorporelle [CEC] établie sur les vaisseaux périphériques",
    tarifSecteur1Optam: "236.81",
    tarifHorsSecteur1HorsOptam: "163.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQCF001",
    designation:
      "Circulation extracorporelle pour réchauffement d'une hypothermie accidentelle",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQGA002",
    designation:
      "Ablation d'une circulation extracorporelle [CEC] pour assistance circulatoire de longue durée, par thoracotomie",
    tarifSecteur1Optam: "394.91",
    tarifHorsSecteur1HorsOptam: "316.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQQP004",
    designation:
      "Suppléance hémodynamique par circulation extracorporelle, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLA003",
    designation:
      "Pose d'une assistance circulatoire mécanique monoventriculaire externe, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA004",
    designation:
      "Pose d'une assistance circulatoire mécanique monoventriculaire externe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA005",
    designation:
      "Pose d'une assistance circulatoire mécanique biventriculaire externe, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA006",
    designation:
      "Pose d'une assistance circulatoire mécanique biventriculaire externe, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA007",
    designation:
      "Pose d'une assistance circulatoire mécanique monoventriculaire interne, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA008",
    designation:
      "Pose d'une assistance circulatoire mécanique monoventriculaire interne, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA009",
    designation:
      "Pose d'une assistance circulatoire mécanique biventriculaire interne, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQLA010",
    designation:
      "Pose d'une prothèse mécanique biventriculaire orthotopique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQKA003",
    designation:
      "Changement d'un système d'assistance circulatoire mécanique ventriculaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQKA002",
    designation:
      "Changement d'un système d'assistance circulatoire mécanique ventriculaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQQP013",
    designation:
      "Suppléance hémodynamique par assistance circulatoire mécanique ventriculaire, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQLA665",
    designation:
      '"Pose d’un dispositif d’assistance circulatoire mécanique temporaire, monoventriculaire interne par abord vasculaire ',
  },
  {
    codeCCAM:
      "Indication : conforme aux indications figurant dans l'avis relatif au dispositif médical émis par la commission de la Haute Autorité de santé mentionnée à l'article L.165 - 1 du code de la sécurité sociale ",
  },
  {
    codeCCAM:
      "Son utilisation est limitée à un centre tertiaire disposant de comité pluridisciplinaire (cardiologues, chirurgiens cardiaques, anesthésistes réanimateurs, perfusionnistes, psychologue), dédié à l’assistance circulatoire mécanique (ACM), mis en place pour discuter de l’opportunité d’une escalade thérapeutique",
  },
  {
    codeCCAM:
      '                                                                                                                                                   "',
    designation: "1489",
    tarifSecteur1Optam: "1489",
    tarifHorsSecteur1HorsOptam: "ATM",
  },
  {
    codeCCAM: "EQLA011",
    designation:
      "Pose d'un dispositif interne d'assistance circulatoire, en dehors de la circulation extracorporelle [CEC] et de ventricule artificiel, par thoracotomie sans CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQGA214",
    designation:
      '"Ablation d’un dispositif d’assistance circulatoire mécanique temporaire, monoventriculaire interne, par abord vasculaire',
  },
  {
    codeCCAM: "",
  },
  {
    codeCCAM:
      '                                                                                                                                                   "',
    designation: "709",
    tarifSecteur1Optam: "709",
    tarifHorsSecteur1HorsOptam: "ATM",
  },
  {
    codeCCAM: "EQGA003",
    designation:
      "Ablation d'un dispositif d'assistance circulatoire interne ou externe, en dehors d'un dispositif de contrepulsion diastolique intraaortique, par thoracotomie sans CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQGA004",
    designation:
      "Ablation d'un dispositif d'assistance circulatoire interne ou externe, en dehors d'un dispositif de contrepulsion diastolique intraaortique, par thoracotomie avec CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCQK002",
    designation: "Radiographie des tissus mous du cou",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FCQH001",
    designation: "Lymphographie du membre supérieur",
    tarifSecteur1Optam: "133",
    tarifHorsSecteur1HorsOptam: "133",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FCQH002",
    designation: "Lymphographie des membres inférieurs",
    tarifSecteur1Optam: "133",
    tarifHorsSecteur1HorsOptam: "133",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LCQK001",
    designation:
      "Scanographie des tissus mous du cou, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LCQH001",
    designation:
      "Scanographie des tissus mous du cou, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LCQN001",
    designation:
      "Remnographie [IRM] des tissus mous du cou, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LCQJ001",
    designation:
      "Remnographie [IRM] des tissus mous du cou, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FCQL001",
    designation: "Lymphoscintigraphie",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FFQL001",
    designation:
      "Scintigraphie de la rate, par injection de traceur radio-isotopique spécifique",
    tarifSecteur1Optam: "162.76",
    tarifHorsSecteur1HorsOptam: "162.76",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FDQL001",
    designation: "Scintigraphie de la moelle osseuse",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL001",
    designation: "Mesure radio-isotopique de la durée de vie des hématies",
    tarifSecteur1Optam: "123.8",
    tarifHorsSecteur1HorsOptam: "123.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL005",
    designation: "Mesure radio-isotopique de la cinétique érythrocytaire",
    tarifSecteur1Optam: "123.8",
    tarifHorsSecteur1HorsOptam: "123.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL002",
    designation: "Mesure radio-isotopique de la durée de vie des plaquettes",
    tarifSecteur1Optam: "123.8",
    tarifHorsSecteur1HorsOptam: "123.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL007",
    designation: "Mesure radio-isotopique du volume sanguin",
    tarifSecteur1Optam: "56.65",
    tarifHorsSecteur1HorsOptam: "56.65",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL004",
    designation:
      "Mesure radio-isotopique de la disparition plasmatique du fer [fer rapide]",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL006",
    designation:
      "Recherche d'un foyer infectieux ou inflammatoire par injection de polynucléaires marqués, sans séparation des lymphocytes",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL011",
    designation:
      "Recherche d'un foyer infectieux ou inflammatoire par injection de polynucléaires marqués, avec séparation des lymphocytes",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL015",
    designation:
      "Recherche d'un foyer infectieux ou inflammatoire par injection d'anticorps ou de peptide marqué, ou de traceur radio-isotopique non spécifique",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FGRP007",
    designation:
      "Test allergologique épicutané en chambre close [Patch test] par batterie standard et/ou batterie complémentaire",
    tarifSecteur1Optam: "30.85",
    tarifHorsSecteur1HorsOptam: "25.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRP003",
    designation:
      "Test allergologique épicutané en chambre close [Patch test] par tests orientés",
    tarifSecteur1Optam: "37.64",
    tarifHorsSecteur1HorsOptam: "29.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRP005",
    designation:
      "Test allergologique épicutané en chambre close [Patch test] par batterie standard et tests orientés",
    tarifSecteur1Optam: "45.04",
    tarifHorsSecteur1HorsOptam: "38.22",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB003",
    designation:
      "Test allergologique par piqûre épidermique avec des substances administrées à concentration fixe",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB004",
    designation:
      "Test allergologique par piqûre épidermique avec des aliments natifs",
    tarifSecteur1Optam: "37.02",
    tarifHorsSecteur1HorsOptam: "33.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB005",
    designation:
      "Test allergologique par injection intradermique de substances administrées à concentration fixe",
    tarifSecteur1Optam: "33.94",
    tarifHorsSecteur1HorsOptam: "31.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB002",
    designation:
      "Test allergologique par injection intradermique d'une substance administrée à concentration croissante",
    tarifSecteur1Optam: "37.02",
    tarifHorsSecteur1HorsOptam: "33.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB001",
    designation:
      "Test allergologique par injection intradermique de plusieurs substances administrées à concentration croissante avec au moins 2 dilutions",
    tarifSecteur1Optam: "45.04",
    tarifHorsSecteur1HorsOptam: "38.22",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRD002",
    designation:
      "Test de provocation par ingestion d'un allergène à concentration fixe",
    tarifSecteur1Optam: "42.57",
    tarifHorsSecteur1HorsOptam: "36.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRD003",
    designation:
      "Test de provocation par ingestion d'un allergène administré à concentration croissante",
    tarifSecteur1Optam: "59.23",
    tarifHorsSecteur1HorsOptam: "46.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRP009",
    designation:
      "Test de provocation conjonctival par allergène administré à concentration croissante",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRD004",
    designation:
      "Test de provocation par allergène administré à concentration croissante par voie nasale",
    tarifSecteur1Optam: "33.94",
    tarifHorsSecteur1HorsOptam: "31.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRP006",
    designation:
      "Test allergologique de provocation par plusieurs aliments natifs administrés par contact labial",
    tarifSecteur1Optam: "33.94",
    tarifHorsSecteur1HorsOptam: "31.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB006",
    designation:
      "Test d'introduction ou de réintroduction d'un allergène par injection à concentration fixe",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRB007",
    designation:
      "Test d'introduction ou de réintroduction d'un allergène par injection à concentration croissante",
    tarifSecteur1Optam: "59.85",
    tarifHorsSecteur1HorsOptam: "46.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCHB001",
    designation:
      "Ponction ou cytoponction de nœud [ganglion] lymphatique, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "5.76",
    tarifHorsSecteur1HorsOptam: "5.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDHB001",
    designation:
      "Ponction de moelle osseuse pour myélogramme dans un territoire, par voie transcutanée",
    tarifSecteur1Optam: "9.6",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDHB005",
    designation:
      "Ponction de moelle osseuse pour myélogramme dans plusieurs territoires sous anesthésie générale, par voie transcutanée",
    tarifSecteur1Optam: "14.4",
    tarifHorsSecteur1HorsOptam: "14.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDHB006",
    designation:
      "Ponction de moelle osseuse pour myélogramme et analyses biologiques dans un territoire, par voie transcutanée",
    tarifSecteur1Optam: "9.6",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDHB003",
    designation:
      "Ponction de moelle osseuse pour myélogramme et analyses biologiques dans plusieurs territoires sous anesthésie générale, par voie transcutanée",
    tarifSecteur1Optam: "14.4",
    tarifHorsSecteur1HorsOptam: "14.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDHB002",
    designation: "Biopsie ostéomédullaire, par voie transcutanée",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDHB004",
    designation:
      "Ponction de moelle osseuse pour myélogramme et analyses biologiques avec biopsie ostéomédullaire dans le même territoire, par voie transcutanée",
    tarifSecteur1Optam: "67.5",
    tarifHorsSecteur1HorsOptam: "67.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEHB001",
    designation: "Prélèvement de sang artériel, par voie transcutanée",
    tarifSecteur1Optam: "9.6",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAHF001",
    designation:
      "Prélèvements sanguins hypersélectifs étagés des sinus pétreux, par voie veineuse transcutanée",
    tarifSecteur1Optam: "320.35",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHHF001",
    designation:
      "Prélèvements sanguins veineux étagés sélectifs ou hypersélectifs de vaisseau affluent de la veine porte, par voie veineuse transhépatique transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EHHF002",
    designation:
      "Prélèvements sanguins veineux étagés suprasélectifs de vaisseau affluent de la veine porte, par voie veineuse transhépatique transcutanée",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGHF001",
    designation:
      "Prélèvements sanguins bilatéraux étagés ou simultanés des veines rénales, par voie veineuse transcutanée",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGHF003",
    designation:
      "Prélèvements sanguins bilatéraux étagés ou simultanés des veines surrénales, par voie veineuse transcutanée",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EGHF002",
    designation:
      "Prélèvements sanguins bilatéraux étagés ou simultanés des veines gonadiques, par voie veineuse transcutanée",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZAQA001",
    designation:
      "Exploration du cou, par cervicotomie [Cervicotomie exploratrice]",
    tarifSecteur1Optam: "103.18",
    tarifHorsSecteur1HorsOptam: "77.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA012",
    designation:
      "Exérèse de nœud [ganglion] lymphatique du cou à visée diagnostique, par cervicotomie",
    tarifSecteur1Optam: "106.26",
    tarifHorsSecteur1HorsOptam: "79.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA021",
    designation:
      "Exérèse de nœud [ganglion] lymphatique des membres à visée diagnostique, par abord direct",
    tarifSecteur1Optam: "106.26",
    tarifHorsSecteur1HorsOptam: "79.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCQX028",
    designation:
      "Examen histopathologique de biopsie de nœud [ganglion] lymphatique",
    tarifSecteur1Optam: "71",
    tarifHorsSecteur1HorsOptam: "71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FDQX007",
    designation:
      "Examen histopathologique de biopsie de moelle osseuse avec coloration spéciale",
    tarifSecteur1Optam: "71",
    tarifHorsSecteur1HorsOptam: "71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX004",
    designation:
      "Examen anatomopathologique de pièce d'exérèse de nœud [ganglion] lymphatique",
    tarifSecteur1Optam: "55.3",
    tarifHorsSecteur1HorsOptam: "55.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX005",
    designation:
      "Examen anatomopathologique de pièce d'exérèse d'un nœud [ganglion] lymphatique sentinelle",
    tarifSecteur1Optam: "42.59",
    tarifHorsSecteur1HorsOptam: "42.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX008",
    designation:
      "Examen anatomopathologique de pièce d'exérèse de 2 nœuds [ganglions] lymphatiques sentinelles",
    tarifSecteur1Optam: "57.21",
    tarifHorsSecteur1HorsOptam: "57.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX007",
    designation:
      "Examen anatomopathologique de pièce d'exérèse de 3 nœuds [ganglions] lymphatiques sentinelles",
    tarifSecteur1Optam: "75.64",
    tarifHorsSecteur1HorsOptam: "75.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX006",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse d'un groupe lymphonodal [ganglionnaire lymphatique]",
    tarifSecteur1Optam: "38.14",
    tarifHorsSecteur1HorsOptam: "38.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX010",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièces d'exérèse de 2 ou 3 groupes lymphonodaux [ganglionnaires lymphatiques]",
    tarifSecteur1Optam: "66.11",
    tarifHorsSecteur1HorsOptam: "66.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX012",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièces d'exérèse de 4 à 6 groupes lymphonodaux [ganglionnaires lymphatiques]",
    tarifSecteur1Optam: "102.34",
    tarifHorsSecteur1HorsOptam: "102.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCQX011",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièces d'exérèse de 7 groupes lymphonodaux [ganglionnaires lymphatiques] ou plus",
    tarifSecteur1Optam: "124.58",
    tarifHorsSecteur1HorsOptam: "124.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FAQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'amygdalectomie ",
    tarifSecteur1Optam: "64.2",
    tarifHorsSecteur1HorsOptam: "64.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FBQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse du thymus ou de vestiges thymiques",
    tarifSecteur1Optam: "116.48",
    tarifHorsSecteur1HorsOptam: "116.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FFQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de splénectomie",
    tarifSecteur1Optam: "69.92",
    tarifHorsSecteur1HorsOptam: "69.92",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FAFA008",
    designation: "Adénoïdectomie",
    tarifSecteur1Optam: "44.66",
    tarifHorsSecteur1HorsOptam: "43.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA001",
    designation:
      "Adénoïdectomie avec pose unilatérale d'aérateur transtympanique",
    tarifSecteur1Optam: "78.5",
    tarifHorsSecteur1HorsOptam: "78.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA002",
    designation:
      "Adénoïdectomie avec pose bilatérale d'aérateur transtympanique",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA013",
    designation: "Adénoïdectomie avec myringotomie unilatérale ou bilatérale",
    tarifSecteur1Optam: "64.68",
    tarifHorsSecteur1HorsOptam: "59.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA014",
    designation: "Amygdalectomie par dissection",
    tarifSecteur1Optam: "88.55",
    tarifHorsSecteur1HorsOptam: "86.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA015",
    designation: "Amygdalectomie par dissection, avec adénoïdectomie",
    tarifSecteur1Optam: "91.63",
    tarifHorsSecteur1HorsOptam: "88.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA006",
    designation:
      "Amygdalectomie par dissection, avec adénoïdectomie et myringotomie unilatérale ou bilatérale",
    tarifSecteur1Optam: "112.4",
    tarifHorsSecteur1HorsOptam: "112.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA005",
    designation:
      "Amygdalectomie par dissection, avec adénoïdectomie et pose unilatérale d'aérateur transtympanique",
    tarifSecteur1Optam: "112.4",
    tarifHorsSecteur1HorsOptam: "112.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA010",
    designation:
      "Amygdalectomie par dissection, avec adénoïdectomie et pose bilatérale d'aérateur transtympanique",
    tarifSecteur1Optam: "120.89",
    tarifHorsSecteur1HorsOptam: "117.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FASD001",
    designation:
      "Hémostase secondaire à une amygdalectomie ou une adénoïdectomie",
    tarifSecteur1Optam: "70.07",
    tarifHorsSecteur1HorsOptam: "58.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAFA007",
    designation: "Exérèse de moignon amygdalien",
    tarifSecteur1Optam: "65.45",
    tarifHorsSecteur1HorsOptam: "64.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FAND001",
    designation:
      "Séance de destruction de tissu amygdalien pharyngien ou lingual",
    tarifSecteur1Optam: "46.97",
    tarifHorsSecteur1HorsOptam: "31.27",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FBFA001",
    designation: "Exérèse du thymus vestigial, par cervicotomie",
    tarifSecteur1Optam: "365.14",
    tarifHorsSecteur1HorsOptam: "343.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FBFC900",
    designation: "Exérèse du thymus vestigial, par thoracoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FBFA900",
    designation:
      "Exérèse du thymus vestigial, par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FBFA003",
    designation: "Exérèse du thymus vestigial, par thoracotomie",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "368.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCSA002",
    designation: "Ligature du conduit thoracique, par cervicotomie",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "193.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCSC001",
    designation:
      "Fermeture de fistule ou ligature du conduit thoracique, par thoracoscopie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCSA001",
    designation:
      "Fermeture de fistule ou ligature du conduit thoracique, par thoracotomie",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "368.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA028",
    designation:
      "Exérèse de nœud [ganglion] lymphatique du cou à visée thérapeutique, par cervicotomie",
    tarifSecteur1Optam: "106.26",
    tarifHorsSecteur1HorsOptam: "79.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA016",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical partiel unilatéral, par cervicotomie",
    tarifSecteur1Optam: "220.99",
    tarifHorsSecteur1HorsOptam: "180.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA020",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical partiel bilatéral, par cervicotomie",
    tarifSecteur1Optam: "343.42",
    tarifHorsSecteur1HorsOptam: "286.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA008",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical complet unilatéral, par cervicotomie",
    tarifSecteur1Optam: "340.34",
    tarifHorsSecteur1HorsOptam: "315.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA027",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical complet unilatéral, élargi aux muscles profonds et/ou aux nerfs du cou, à l'artère carotide externe, à la glande parotide, par cervicotomie",
    tarifSecteur1Optam: "593.67",
    tarifHorsSecteur1HorsOptam: "462.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA025",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical complet unilatéral avec curage partiel controlatéral, par cervicotomie",
    tarifSecteur1Optam: "558.25",
    tarifHorsSecteur1HorsOptam: "468.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA013",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical complet bilatéral, par cervicotomie",
    tarifSecteur1Optam: "644.49",
    tarifHorsSecteur1HorsOptam: "544.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA005",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical complet et médiastinorécurrentiel unilatéral, par cervicotomie",
    tarifSecteur1Optam: "468.93",
    tarifHorsSecteur1HorsOptam: "416.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA009",
    designation:
      "Curage lymphonodal [ganglionnaire] cervical complet et médiastinorécurrentiel bilatéral, par cervicotomie",
    tarifSecteur1Optam: "811.58",
    tarifHorsSecteur1HorsOptam: "615.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCNB001",
    designation:
      "Sclérose de lymphangiome cervical ou cervicofacial, par injection transcutanée intralésionnelle d'agent pharmacologique",
    tarifSecteur1Optam: "104.93",
    tarifHorsSecteur1HorsOptam: "76.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCFA001",
    designation: "Exérèse de lymphangiome cervical",
    tarifSecteur1Optam: "425.04",
    tarifHorsSecteur1HorsOptam: "316.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA003",
    designation:
      "Exérèse de lymphangiome cervical avec extension médiastinale, par cervicotomie",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "395.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA026",
    designation:
      "Exérèse de lymphangiome cervicofacial sans dissection du nerf facial",
    tarifSecteur1Optam: "539",
    tarifHorsSecteur1HorsOptam: "382.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA015",
    designation: "Exérèse de lymphangiome cervicoparotidien",
    tarifSecteur1Optam: "642.18",
    tarifHorsSecteur1HorsOptam: "442.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA014",
    designation:
      "Exérèse de lymphangiome cervicoparotidien avec extension buccopharyngée",
    tarifSecteur1Optam: "830.06",
    tarifHorsSecteur1HorsOptam: "586.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA023",
    designation:
      "Exérèse de lymphangiome cervicoparotidien avec extension buccopharyngée et médiastinale, par cervicotomie",
    tarifSecteur1Optam: "1094.94",
    tarifHorsSecteur1HorsOptam: "740.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFC002",
    designation:
      "Exérèse de nœud [ganglion] lymphatique du médiastin, par thoracoscopie ou par médiastinoscopie",
    tarifSecteur1Optam: "277.93",
    tarifHorsSecteur1HorsOptam: "248.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA002",
    designation:
      "Curage lymphonodal [ganglionnaire] médiastinal supérieur, par cervicotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA004",
    designation:
      "Curage lymphonodal [ganglionnaire] médiastinal unilatéral ou bilatéral, par thoracotomie",
    tarifSecteur1Optam: "327.56",
    tarifHorsSecteur1HorsOptam: "321.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFC003",
    designation:
      "Curage lymphonodal [ganglionnaire] pelvien, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "272.97",
    tarifHorsSecteur1HorsOptam: "258.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA006",
    designation: "Curage lymphonodal [ganglionnaire] pelvien, par laparotomie",
    tarifSecteur1Optam: "248.15",
    tarifHorsSecteur1HorsOptam: "231.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFC001",
    designation:
      "Curage lymphonodal [ganglionnaire] iliaque, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "319.05",
    tarifHorsSecteur1HorsOptam: "272.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA019",
    designation:
      "Curage lymphonodal [ganglionnaire] iliaque unilatéral ou bilatéral, par laparotomie",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "252.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFC005",
    designation:
      "Curage lymphonodal [ganglionnaire] lomboaortique, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "382.86",
    tarifHorsSecteur1HorsOptam: "353.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA010",
    designation:
      "Curage lymphonodal [ganglionnaire] lomboaortique, par laparotomie",
    tarifSecteur1Optam: "368.68",
    tarifHorsSecteur1HorsOptam: "345.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFC004",
    designation:
      "Curage lymphonodal [ganglionnaire] lomboaortique avec curage iliaque unilatéral ou bilatéral, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "623.21",
    tarifHorsSecteur1HorsOptam: "537.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA022",
    designation:
      "Curage lymphonodal [ganglionnaire] lomboaortique avec curage iliaque unilatéral ou bilatéral, par laparotomie",
    tarifSecteur1Optam: "459.43",
    tarifHorsSecteur1HorsOptam: "442.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA018",
    designation:
      "Exérèse de nœud [ganglion] lymphatique des membres à visée thérapeutique, par abord direct",
    tarifSecteur1Optam: "97.84",
    tarifHorsSecteur1HorsOptam: "74.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA029",
    designation:
      "Curage lymphonodal [ganglionnaire] axillaire, par abord direct",
    tarifSecteur1Optam: "209.16",
    tarifHorsSecteur1HorsOptam: "173.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA011",
    designation:
      "Curage lymphonodal [ganglionnaire] inguinal, par abord direct",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "163.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCPA001",
    designation:
      "Mise à plat d'une lymphocèle d'un membre avec lymphostase, par abord direct",
    tarifSecteur1Optam: "169.45",
    tarifHorsSecteur1HorsOptam: "168.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA007",
    designation:
      "Lymphangectomie partielle ou totale d'un membre, par abord direct",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "380.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCCA001",
    designation:
      "Dérivation lympholymphatique ou lymphoveineuse d'un membre, par abord direct",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "380.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCNH001",
    designation:
      "Sclérose de malformation lymphatique, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "73.32",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCFA024",
    designation: "Exérèse d'un lymphangiome superficiel",
    tarifSecteur1Optam: "314.09",
    tarifHorsSecteur1HorsOptam: "217.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCBP001",
    designation:
      "Séance de compression [pressothérapie] mercurielle d'un membre",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FCBP002",
    designation:
      "Séance de compression [pressothérapie] pneumatique unilatérale ou bilatérale d'un membre",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGLB002",
    designation:
      "Injection d'extrait allergénique à concentration croissante par voie souscutanée, jusqu'à obtention de la dose d'entretien pour désensibilisation spécifique par méthode semiaccélérée ou accélérée [semirush ou rush]",
    tarifSecteur1Optam: "85.15",
    tarifHorsSecteur1HorsOptam: "53.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGLB001",
    designation:
      "Séance d'injection d'extrait allergénique à concentration donnée pour désensibilisation spécifique, par voie souscutanée",
    tarifSecteur1Optam: "12.34",
    tarifHorsSecteur1HorsOptam: "12.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGLB004",
    designation:
      "Séance d'injection ou d'ingestion d'un médicament à concentration croissante pour accoutumance",
    tarifSecteur1Optam: "112.91",
    tarifHorsSecteur1HorsOptam: "67.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FFJC001",
    designation: "Évacuation de collection de la rate, par cœlioscopie",
    tarifSecteur1Optam: "272.97",
    tarifHorsSecteur1HorsOptam: "271",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFJA001",
    designation: "Évacuation de collection de la rate, par laparotomie",
    tarifSecteur1Optam: "259.49",
    tarifHorsSecteur1HorsOptam: "251.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFFC420",
    designation: "Splénectomie partielle, par cœlioscopie",
    tarifSecteur1Optam: "496",
    tarifHorsSecteur1HorsOptam: "496",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFFA002",
    designation: "Splénectomie partielle, par laparotomie",
    tarifSecteur1Optam: "350.96",
    tarifHorsSecteur1HorsOptam: "304.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFFC001",
    designation: "Splénectomie totale, par cœlioscopie",
    tarifSecteur1Optam: "484.25",
    tarifHorsSecteur1HorsOptam: "393.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFFA001",
    designation: "Splénectomie totale, par laparotomie",
    tarifSecteur1Optam: "305.58",
    tarifHorsSecteur1HorsOptam: "277.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFSC272",
    designation:
      "Hémostase splénique avec conservation de la rate, par cœlioscopie",
    tarifSecteur1Optam: "461",
    tarifHorsSecteur1HorsOptam: "461",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FFSA001",
    designation:
      "Hémostase splénique avec conservation de la rate, par laparotomie",
    tarifSecteur1Optam: "399.17",
    tarifHorsSecteur1HorsOptam: "332.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FDFB001",
    designation:
      "Prélèvement de cellules souches hématopoïétiques médullaires [Prélèvement de moelle osseuse], pour thérapie cellulaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF003",
    designation: "Saignée thérapeutique",
    tarifSecteur1Optam: "25.28",
    tarifHorsSecteur1HorsOptam: "18.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF006",
    designation:
      "Saignée thérapeutique, avec transfusion de concentré de globules rouges",
    tarifSecteur1Optam: "55.62",
    tarifHorsSecteur1HorsOptam: "42.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF008",
    designation:
      "Saignée thérapeutique avec transfusion de concentré de globules rouges, chez le nouveau-né",
    tarifSecteur1Optam: "60.3",
    tarifHorsSecteur1HorsOptam: "60.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF012",
    designation: "Exsanguinotransfusion d'épuration ou de substitution",
    tarifSecteur1Optam: "203.5",
    tarifHorsSecteur1HorsOptam: "182.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF005",
    designation:
      "Exsanguinotransfusion d'épuration ou de substitution, chez le nouveau-né",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF011",
    designation:
      "Transfusion de concentré de globules rouges d'un volume inférieur à une demimasse sanguine",
    tarifSecteur1Optam: "46.14",
    tarifHorsSecteur1HorsOptam: "34.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF007",
    designation:
      "Transfusion de concentré de globules rouges d'un volume inférieur à 40 ml/kg, chez le nouveau-né",
    tarifSecteur1Optam: "55.62",
    tarifHorsSecteur1HorsOptam: "40.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF008",
    designation: "Transfusion de concentré de globules rouges, à domicile",
    tarifSecteur1Optam: "61.22",
    tarifHorsSecteur1HorsOptam: "61.22",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF006",
    designation: "Transfusion de produit sanguin labile non érythrocytaire",
    tarifSecteur1Optam: "46.14",
    tarifHorsSecteur1HorsOptam: "34.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF002",
    designation:
      "Transfusion de produit sanguin labile non érythrocytaire, chez le nouveau-né",
    tarifSecteur1Optam: "55.62",
    tarifHorsSecteur1HorsOptam: "40.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF003",
    designation:
      "Administration intraveineuse simultanée de deux des produits sanguins suivants : plasma frais congelé, plaquettes, facteur antihémophilique, fibrinogène, antithrombine III pour suppléance de coagulopathie, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEPF003",
    designation:
      "Séance d'érythraphérèse thérapeutique avec transfusion de globules rouges [échange érythrocytaire]",
    tarifSecteur1Optam: "86.58",
    tarifHorsSecteur1HorsOptam: "62.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEPF005",
    designation:
      "Séance d'érythraphérèse thérapeutique avec perfusion [hémodilution normovolémique intentionnelle]",
    tarifSecteur1Optam: "64.46",
    tarifHorsSecteur1HorsOptam: "48.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEPF002",
    designation: "Séance de thrombocytaphérèse thérapeutique",
    tarifSecteur1Optam: "73.31",
    tarifHorsSecteur1HorsOptam: "50.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEPF001",
    designation:
      "Séance programmée de leucaphérèse thérapeutique pour affection chronique",
    tarifSecteur1Optam: "50.59",
    tarifHorsSecteur1HorsOptam: "50.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEPF004",
    designation:
      "Séance non programmée de leucaphérèse thérapeutique pour affection aigüe",
    tarifSecteur1Optam: "118.03",
    tarifHorsSecteur1HorsOptam: "118.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF004",
    designation:
      "Séance programmée de soustraction thérapeutique de plasma avec perfusion de produit de substitution [échange plasmatique] pour affection chronique",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF002",
    designation:
      "Séance non programmée de soustraction thérapeutique de plasma avec perfusion de produit de substitution [échange plasmatique] pour affection aigüe",
    tarifSecteur1Optam: "189.6",
    tarifHorsSecteur1HorsOptam: "174.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF005",
    designation:
      "Séance d'épuration chimique des lipoprotéines [LDLaphérèse] à partir du plasma ou du sang total",
    tarifSecteur1Optam: "189.6",
    tarifHorsSecteur1HorsOptam: "118.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF007",
    designation:
      "Séance d'épuration immunologique de substance plasmatique [immunoadsorption]",
    tarifSecteur1Optam: "136.36",
    tarifHorsSecteur1HorsOptam: "136.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF001",
    designation:
      "Séance d'épuration semisélective de substance plasmatique par double filtration [épuration en cascade]",
    tarifSecteur1Optam: "221.2",
    tarifHorsSecteur1HorsOptam: "136.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEJF009",
    designation: "Séance d'ultrafiltration plasmatique",
    tarifSecteur1Optam: "52.46",
    tarifHorsSecteur1HorsOptam: "44.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEFF001",
    designation:
      "Prélèvement de cellules mononucléées par cytaphérèse, pour thérapie cellulaire",
    tarifSecteur1Optam: "50.59",
    tarifHorsSecteur1HorsOptam: "50.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEFF438",
    designation:
      "Prélèvement de cellules mononucléées par cytaphérèse, pour production de médicament de thérapie génique autologue chez un patient de 20kg et plus",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEFF220",
    designation:
      "Prélèvement de cellules mononucléées par cytaphérèse, pour production de médicament de thérapie génique autologue chez un patient de moins de 20kg",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FEFF002",
    designation:
      "Prélèvement de cellules souches hématopoïétiques sanguines par cytaphérèse, pour thérapie cellulaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF010",
    designation:
      "Injection intraveineuse d'un produit de thérapie cellulaire pour autogreffe",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FELF009",
    designation:
      "Injection intraveineuse d'un produit de thérapie cellulaire pour allogreffe",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGLF671",
    designation:
      "Administration d'un médicament de thérapie génique autologue par voie veineuse",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FENL001",
    designation:
      "Irradiation interne pour polyglobulie, par injection intraveineuse de phosphore 32",
    tarifSecteur1Optam: "74.33",
    tarifHorsSecteur1HorsOptam: "74.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EBSA011",
    designation: "Hémostase secondaire, par reprise de cervicotomie",
    tarifSecteur1Optam: "199.43",
    tarifHorsSecteur1HorsOptam: "159.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AHQP006",
    designation:
      "Électromyographie de détection des muscles du larynx par électrode externe",
    tarifSecteur1Optam: "54.67",
    tarifHorsSecteur1HorsOptam: "53.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQE002",
    designation:
      "Électromyographie de détection des muscles du larynx par électrode aiguille, par laryngoscopie",
    tarifSecteur1Optam: "123.97",
    tarifHorsSecteur1HorsOptam: "93.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBQM001",
    designation:
      "Échographie unilatérale ou bilatérale du sinus maxillaire et/ou du sinus frontal",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "34.97",
    regroupement: "ADE",
  },
  {
    codeCCAM: "GFQM001",
    designation:
      "Échographie transthoracique du médiastin, du poumon et/ou de la cavité pleurale",
    tarifSecteur1Optam: "37.05",
    tarifHorsSecteur1HorsOptam: "37.05",
    regroupement: "ADE",
  },
  {
    codeCCAM: "GFQJ002",
    designation:
      "Échographie du médiastin et/ou du poumon, par voie œsophagienne ou par voie bronchique",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZBQK002",
    designation: "Radiographie du thorax",
    tarifSecteur1Optam: "21.28",
    tarifHorsSecteur1HorsOptam: "21.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LJQK002",
    designation:
      "Radiographie du thorax avec radiographie du squelette du thorax",
    tarifSecteur1Optam: "45.22",
    tarifHorsSecteur1HorsOptam: "45.22",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZBQK003",
    designation:
      "Examen radiologique dynamique du thorax, pour étude de la fonction respiratoire et/ou cardiaque",
    tarifSecteur1Optam: "21.28",
    tarifHorsSecteur1HorsOptam: "21.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GEQH001",
    designation: "Bronchographie",
    tarifSecteur1Optam: "33.25",
    tarifHorsSecteur1HorsOptam: "33.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZBQK001",
    designation:
      "Scanographie du thorax, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZBQH001",
    designation:
      "Scanographie du thorax, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZBQN001",
    designation:
      "Remnographie [IRM] du thorax, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZBQJ001",
    designation:
      "Remnographie [IRM] du thorax, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GFQL004",
    designation: "Scintigraphie pulmonaire de ventilation",
    tarifSecteur1Optam: "176.85",
    tarifHorsSecteur1HorsOptam: "176.85",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GFQL007",
    designation: "Scintigraphie pulmonaire de perfusion",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GFQL006",
    designation: "Scintigraphie pulmonaire de ventilation et de perfusion",
    tarifSecteur1Optam: "357.29",
    tarifHorsSecteur1HorsOptam: "357.29",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GFQL001",
    designation: "Tomoscintigraphie pulmonaire de ventilation",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GFQL005",
    designation: "Tomoscintigraphie pulmonaire de perfusion",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GFQL002",
    designation: "Tomoscintigraphie pulmonaire de ventilation et de perfusion",
    tarifSecteur1Optam: "534.15",
    tarifHorsSecteur1HorsOptam: "534.15",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GEQL001",
    designation: "Scintigraphie de l'épuration mucociliaire bronchique",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GLQL002",
    designation: "Mesure radio-isotopique de la perméabilité alvéolocapillaire",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "GAQD003",
    designation: "Mesure de la différence de potentiel des muqueuses nasales",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "FGRP010",
    designation: "Tests vasomoteurs de stimulation cutanée pour rhinite",
    tarifSecteur1Optam: "31.57",
    tarifHorsSecteur1HorsOptam: "26.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD007",
    designation: "Rhinomanométrie antérieure sans test de provocation",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD002",
    designation: "Rhinomanométrie antérieure avec test de provocation",
    tarifSecteur1Optam: "38.5",
    tarifHorsSecteur1HorsOptam: "34.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD005",
    designation: "Rhinomanométrie postérieure sans test de provocation",
    tarifSecteur1Optam: "37.73",
    tarifHorsSecteur1HorsOptam: "33.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD001",
    designation: "Rhinomanométrie postérieure avec test de provocation",
    tarifSecteur1Optam: "46.97",
    tarifHorsSecteur1HorsOptam: "39.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD004",
    designation: "Rhinométrie acoustique sans test de provocation",
    tarifSecteur1Optam: "30.8",
    tarifHorsSecteur1HorsOptam: "29.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD006",
    designation: "Rhinométrie acoustique avec test de provocation",
    tarifSecteur1Optam: "42.35",
    tarifHorsSecteur1HorsOptam: "36.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GJQP001",
    designation: "Olfactométrie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQD349",
    designation: "Mesure du monoxyde d'azote (NO) nasal",
    tarifSecteur1Optam: "57",
    tarifHorsSecteur1HorsOptam: "57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQP010",
    designation:
      "Bilan instrumental de la phonation avec enregistrement vidéoscopique et/ou photographique, et examen stroboscopique du larynx",
    tarifSecteur1Optam: "52.36",
    tarifHorsSecteur1HorsOptam: "38.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQP008",
    designation:
      "Analyse informatisée de la parole et/ou de la voix par électroglottographie",
    tarifSecteur1Optam: "26.18",
    tarifHorsSecteur1HorsOptam: "23.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQE001",
    designation:
      "Bilan phonétique de la parole avec fibroscopie du pharynx et du larynx par voie nasale",
    tarifSecteur1Optam: "61.6",
    tarifHorsSecteur1HorsOptam: "47.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQP006",
    designation: "Analyse informatisée acoustique de la parole",
    tarifSecteur1Optam: "46.2",
    tarifHorsSecteur1HorsOptam: "38.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQP001",
    designation: "Analyse informatisée acoustique de la voix",
    tarifSecteur1Optam: "40.04",
    tarifHorsSecteur1HorsOptam: "35.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKQP011",
    designation: "Analyse informatisée aérodynamique de la phonation",
    tarifSecteur1Optam: "40.04",
    tarifHorsSecteur1HorsOptam: "35.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBQD001",
    designation: "Manométrie des sinus paranasaux",
    tarifSecteur1Optam: "61.6",
    tarifHorsSecteur1HorsOptam: "47.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLHF001",
    designation:
      "Prélèvement de sang artériel avec gazométrie sanguine et mesure du pH, sans épreuve d'hyperoxie",
    tarifSecteur1Optam: "36.48",
    tarifHorsSecteur1HorsOptam: "36.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLHF002",
    designation:
      "Prélèvement de sang artériel avec gazométrie sanguine et mesure du pH, avec épreuve d'hyperoxie",
    tarifSecteur1Optam: "54.72",
    tarifHorsSecteur1HorsOptam: "54.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP005",
    designation:
      "Enregistrement continu de la saturation sanguine en oxygène par mesure transcutanée [SpO2] [Oxymétrie de pouls], pendant au moins 6 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP013",
    designation:
      "Mesure transcutanée de la tension partielle en oxygène [TcPO2] au repos",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP010",
    designation:
      "Mesure transcutanée de la tension partielle d'oxygène [TcPO2] en hyperhémie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP012",
    designation:
      "Mesure de la capacité vitale lente et de l'expiration forcée, avec enregistrement [Spirométrie standard]",
    tarifSecteur1Optam: "40.28",
    tarifHorsSecteur1HorsOptam: "37.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP008",
    designation:
      "Mesure de la capacité vitale lente et de l'expiration forcée, avec gazométrie sanguine artérielle [Spirométrie standard avec gaz du sang]",
    tarifSecteur1Optam: "67.2",
    tarifHorsSecteur1HorsOptam: "67.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP002",
    designation:
      "Mesure de la capacité vitale lente et de l'expiration forcée, avec mesure des volumes pulmonaires mobilisables et non mobilisables par pléthysmographie",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP009",
    designation:
      "Mesure de la capacité vitale et du volume courant par pléthysmographie d'inductance",
    tarifSecteur1Optam: "34.56",
    tarifHorsSecteur1HorsOptam: "34.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP003",
    designation:
      "Mesure de l'expiration forcée [Courbe débit-volume] avec enregistrement",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP014",
    designation:
      "Mesure du débit expiratoire maximal par technique de compression",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP011",
    designation:
      "Mesure des volumes pulmonaires mobilisables et non mobilisables par pléthysmographie",
    tarifSecteur1Optam: "67.64",
    tarifHorsSecteur1HorsOptam: "63.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP016",
    designation:
      "Mesure des résistances des voies aériennes ou de l'appareil respiratoire par interruption des débits ou des oscillations forcées",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQP001",
    designation: "Mesure de la compliance [de l'élasticité] pulmonaire",
    tarifSecteur1Optam: "64.6",
    tarifHorsSecteur1HorsOptam: "59.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQD002",
    designation:
      "Mesure des compliances [de l'élasticité] thoracopulmonaires avec mesure de la pression œsophagienne, avant l'âge de 3 ans",
    tarifSecteur1Optam: "117.8",
    tarifHorsSecteur1HorsOptam: "90.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQD006",
    designation:
      "Mesure de la pression d'occlusion à la bouche à 0,1 seconde d'inspiration [P 0,1]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LLQP002",
    designation:
      "Étude des muscles ventilatoires par mesure de pression à la bouche",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQD004",
    designation:
      "Mesure de la différence alvéoloartérielle des pressions partielles en oxygène par analyse des gaz expirés et des gaz du sang",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQD007",
    designation:
      "Mesure du volume capillaire pulmonaire par mesures de la capacité de transfert pulmonaire",
    tarifSecteur1Optam: "54.72",
    tarifHorsSecteur1HorsOptam: "47.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP006",
    designation:
      "Mesure de la réponse au dioxyde de carbone [CO2] par établissement d'une courbe réponse ventilatoire/concentration de CO2",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP007",
    designation: "Polygraphie respiratoire nocturne",
    tarifSecteur1Optam: "145.92",
    tarifHorsSecteur1HorsOptam: "119.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLRP003",
    designation:
      "Épreuve d'effort sur tapis roulant ou bicyclette ergométrique, avec mesure des gaz du sang [Épreuve d'effort simplifiée] [Gazométrie à l'effort]",
    tarifSecteur1Optam: "73.72",
    tarifHorsSecteur1HorsOptam: "70.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLRP004",
    designation:
      "Épreuve d'effort sur tapis roulant ou bicyclette ergométrique, avec mesure des gaz du sang et du débit d'oxygène consommé [VO2], et surveillance électrocardioscopique discontinue",
    tarifSecteur1Optam: "121.6",
    tarifHorsSecteur1HorsOptam: "114.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP003",
    designation:
      "Mesure de la distance de marche en terrain plat en 6 minutes, avec surveillance de la saturation en oxygène par mesure transcutanée",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EQQP002",
    designation:
      "Mesure de la distance de marche en terrain plat en 6 minutes, avec surveillance de la saturation en oxygène par mesure transcutanée et mesure du débit d'oxygène utile",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP017",
    designation:
      "Épreuve d’oxygénothérapie hyperbare [OHB] avec mesure transcutanée de la pression partielle en oxygène [PtcO2] ",
    tarifSecteur1Optam: "24.46",
    tarifHorsSecteur1HorsOptam: "22.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLB001",
    designation:
      "Pose d'un dispositif de mesure de la saturation jugulaire en oxygène, par voie veineuse transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP004",
    designation:
      "Surveillance continue transcutanée de la pression partielle sanguine en oxygène [PO2] et/ou en dioxyde de carbone [PCO2] chez le nouveau-né, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQP015",
    designation:
      "Surveillance continue de la saturation jugulaire en oxygène par dispositif intraveineux, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQE003",
    designation: "Endoscopie de la cavité nasale, par voie nasale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAQE001",
    designation:
      "Endoscopie de la cavité nasale et du rhinopharynx [cavum], par voie nasale",
    tarifSecteur1Optam: "30.8",
    tarifHorsSecteur1HorsOptam: "30.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCQE002",
    designation:
      "Endoscopie du rhinopharynx [cavum], par voie buccale sous anesthésie générale",
    tarifSecteur1Optam: "42.35",
    tarifHorsSecteur1HorsOptam: "36.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBQE002",
    designation: "Sinusoscopie frontale",
    tarifSecteur1Optam: "77.77",
    tarifHorsSecteur1HorsOptam: "73.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBQE001",
    designation: "Sinusoscopie sphénoïdale",
    tarifSecteur1Optam: "92.4",
    tarifHorsSecteur1HorsOptam: "81.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBQA002",
    designation:
      "Sinusoscopie maxillaire, par voie méatale inférieure et/ou par abord de la fosse canine [abord vestibulaire]",
    tarifSecteur1Optam: "57.75",
    tarifHorsSecteur1HorsOptam: "57.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCQE001",
    designation: "Fibroscopie du pharynx et du larynx, par voie nasale",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCQE003",
    designation:
      "Fibroscopie du pharynx et du larynx par voie nasale, avec examen stroboscopique du larynx",
    tarifSecteur1Optam: "33.11",
    tarifHorsSecteur1HorsOptam: "31.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQE005",
    designation: "Laryngoscopie directe [Laryngoscopie en suspension]",
    tarifSecteur1Optam: "73.92",
    tarifHorsSecteur1HorsOptam: "67.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQE003",
    designation: "Laryngoscopie indirecte au laryngoscope rigide",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQE002",
    designation:
      "Laryngoscopie indirecte au laryngoscope rigide, avec examen stroboscopique du larynx",
    tarifSecteur1Optam: "38.5",
    tarifHorsSecteur1HorsOptam: "34.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQE004",
    designation: "Fibroscopie du larynx et de la trachée",
    tarifSecteur1Optam: "44.66",
    tarifHorsSecteur1HorsOptam: "44.61",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQE001",
    designation: "Laryngo-trachéo-bronchoscopie au tube rigide",
    tarifSecteur1Optam: "83.93",
    tarifHorsSecteur1HorsOptam: "76.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE008",
    designation: "Bronchoscopie au tube rigide",
    tarifSecteur1Optam: "112.48",
    tarifHorsSecteur1HorsOptam: "89.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE001",
    designation:
      "Bronchoscopie au tube rigide, avec prélèvement médiastinal à l'aiguille par voie transbronchique",
    tarifSecteur1Optam: "121.6",
    tarifHorsSecteur1HorsOptam: "102.78",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE005",
    designation:
      "Bronchoscopie au tube rigide, avec biopsie pulmonaire par voie transbronchique sans guidage",
    tarifSecteur1Optam: "126.16",
    tarifHorsSecteur1HorsOptam: "105.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQH003",
    designation:
      "Bronchoscopie au tube rigide, avec biopsie pulmonaire par voie transbronchique avec guidage radiologique",
    tarifSecteur1Optam: "104.12",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE007",
    designation: "Fibroscopie bronchique",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE012",
    designation:
      "Fibroscopie bronchique, chez un patient intubé ou trachéotomisé",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE004",
    designation:
      "Fibroscopie bronchique, avec lavage bronchioloalvéolaire à visée diagnostique",
    tarifSecteur1Optam: "110.4",
    tarifHorsSecteur1HorsOptam: "110.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE009",
    designation:
      "Fibroscopie bronchique avec lavage bronchioloalvéolaire à visée diagnostique, chez un patient intubé ou trachéotomisé",
    tarifSecteur1Optam: "110.4",
    tarifHorsSecteur1HorsOptam: "110.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE002",
    designation:
      "Fibroscopie bronchique, avec prélèvement lymphonodal à l'aiguille par voie transbronchique",
    tarifSecteur1Optam: "104.88",
    tarifHorsSecteur1HorsOptam: "101.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE006",
    designation:
      "Fibroscopie bronchique, avec biopsie pulmonaire par voie transbronchique sans guidage",
    tarifSecteur1Optam: "110.96",
    tarifHorsSecteur1HorsOptam: "104.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQH002",
    designation:
      "Fibroscopie bronchique, avec biopsie pulmonaire par voie transbronchique avec guidage radiologique",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE010",
    designation:
      "Fibroscopie trachéobronchique avec photodétection de lésion par autofluorescence sans laser",
    tarifSecteur1Optam: "110.2",
    tarifHorsSecteur1HorsOptam: "104.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE003",
    designation:
      "Fibroscopie trachéobronchique avec photodétection de lésion par autofluorescence avec laser",
    tarifSecteur1Optam: "120.08",
    tarifHorsSecteur1HorsOptam: "109.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQE013",
    designation:
      "Examen panendoscopique des voies aérodigestives supérieures avec trachéoscopie et œsophagoscopie",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQJ237",
    designation:
      "Échoendoscopie bronchique, avec prélèvement médiastinal à l'aiguille par voie transbronchique",
    tarifSecteur1Optam: "171.76",
    tarifHorsSecteur1HorsOptam: "171.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGHB001",
    designation: "Ponction d'un épanchement pleural, sans guidage",
    tarifSecteur1Optam: "37.24",
    tarifHorsSecteur1HorsOptam: "31.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAHA001",
    designation: "Biopsie de la peau du nez et/ou de la muqueuse narinaire",
    tarifSecteur1Optam: "30.4",
    tarifHorsSecteur1HorsOptam: "25.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDHE001",
    designation: "Biopsie oro-pharyngo-laryngée, par laryngoscopie indirecte",
    tarifSecteur1Optam: "43.12",
    tarifHorsSecteur1HorsOptam: "37.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGHB002",
    designation: "Biopsie de la plèvre, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "79.04",
    tarifHorsSecteur1HorsOptam: "57.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEHD001",
    designation:
      "Prélèvement intrabronchique distal protégé sur sonde d'intubation ou sur trachéotomie, sans fibroscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQE007",
    designation:
      "Exploration de pli vocal [corde vocale], par laryngoscopie directe [Cordotomie exploratrice endoscopique]",
    tarifSecteur1Optam: "92.17",
    tarifHorsSecteur1HorsOptam: "77.65",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GHQA001",
    designation: "Exploration du médiastin, par cervicotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHQC001",
    designation: "Exploration du médiastin, par médiastinoscopie",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "171.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBQC001",
    designation:
      "Exploration du thorax sans biopsie du poumon, par thoracoscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBQA002",
    designation:
      "Exploration du thorax sans biopsie du poumon, par thoracotomie",
    tarifSecteur1Optam: "348.83",
    tarifHorsSecteur1HorsOptam: "290.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBQC002",
    designation:
      "Exploration du thorax avec biopsie du poumon, par thoracoscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBQA001",
    designation:
      "Exploration du thorax avec biopsie du poumon, par thoracotomie",
    tarifSecteur1Optam: "249.57",
    tarifHorsSecteur1HorsOptam: "232.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEQX002",
    designation:
      "Examen cytopathologique de produit de lavage bronchioloalvéolaire, non différencié, avec coloration spéciale",
    tarifSecteur1Optam: "28",
    tarifHorsSecteur1HorsOptam: "28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEQX001",
    designation:
      "Examen cytopathologique de produit de lavage bronchioloalvéolaire, différencié, avec coloration spéciale",
    tarifSecteur1Optam: "56",
    tarifHorsSecteur1HorsOptam: "56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX020",
    designation:
      "Examen histopathologique de biopsie de poumon avec coloration spéciale pour diagnostic d'affection non carcinologique",
    tarifSecteur1Optam: "60",
    tarifHorsSecteur1HorsOptam: "60",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de cavité nasale [fosse nasale] et/ou de sinus paranasal",
    tarifSecteur1Optam: "68.2",
    tarifHorsSecteur1HorsOptam: "68.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse du rhinopharynx",
    tarifSecteur1Optam: "66.45",
    tarifHorsSecteur1HorsOptam: "66.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de cordectomie laryngée",
    tarifSecteur1Optam: "70.27",
    tarifHorsSecteur1HorsOptam: "70.27",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de laryngectomie partielle et/ou de pharyngectomie partielle",
    tarifSecteur1Optam: "108.23",
    tarifHorsSecteur1HorsOptam: "108.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de laryngectomie partielle verticale ou de laryngectomie totale",
    tarifSecteur1Optam: "119.57",
    tarifHorsSecteur1HorsOptam: "119.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX006",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse pulmonaire non anatomique et/ou de segmentectomie, non différenciées, sans résection de côte ni de vertèbre",
    tarifSecteur1Optam: "68.45",
    tarifHorsSecteur1HorsOptam: "68.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX009",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse pulmonaire non anatomique et/ou de segmentectomie, différenciées, sans résection de côte ni de vertèbre",
    tarifSecteur1Optam: "99.72",
    tarifHorsSecteur1HorsOptam: "99.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX010",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse pulmonaire non anatomique et/ou de segmentectomie, avec résection de côte et/ou de vertèbre",
    tarifSecteur1Optam: "96.64",
    tarifHorsSecteur1HorsOptam: "96.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse d'une lobectomie pulmonaire, sans résection de côte ni de vertèbre",
    tarifSecteur1Optam: "116.35",
    tarifHorsSecteur1HorsOptam: "116.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pneumonectomie ou de lobectomies pulmonaires multiples, sans résection de côte ni de vertèbre",
    tarifSecteur1Optam: "132.6",
    tarifHorsSecteur1HorsOptam: "132.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse d'une lobectomie pulmonaire, avec résection de côte et/ou de vertèbre",
    tarifSecteur1Optam: "142.24",
    tarifHorsSecteur1HorsOptam: "142.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pneumonectomie ou de lobectomies pulmonaires multiples, avec résection de côte et/ou de vertèbre",
    tarifSecteur1Optam: "159.06",
    tarifHorsSecteur1HorsOptam: "159.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de tumeur de plèvre ",
    tarifSecteur1Optam: "70.71",
    tarifHorsSecteur1HorsOptam: "70.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX042",
    designation:
      "Test de détection des mutations génétiques de tumeur maligne de l'appareil respiratoire pour thérapie ciblée [Test compagnon]",
    tarifSecteur1Optam: "237.55",
    tarifHorsSecteur1HorsOptam: "237.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAJA002",
    designation: "Parage et/ou suture de plaie du nez",
    tarifSecteur1Optam: "53.88",
    tarifHorsSecteur1HorsOptam: "37.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAEA001",
    designation:
      "Repositionnement de fragment de nez amputé, sans anastomose vasculaire",
    tarifSecteur1Optam: "133.29",
    tarifHorsSecteur1HorsOptam: "103.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAEA002",
    designation:
      "Réimplantation de fragment de nez, avec anastomoses vasculaires",
    tarifSecteur1Optam: "475.03",
    tarifHorsSecteur1HorsOptam: "380.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA007",
    designation:
      "Exérèse non transfixiante de lésion de la peau du nez ou de la muqueuse narinaire",
    tarifSecteur1Optam: "49.13",
    tarifHorsSecteur1HorsOptam: "40.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA005",
    designation:
      "Exérèse transfixiante de lésion de la peau du nez et/ou de la muqueuse narinaire",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA002",
    designation:
      "Exérèse subtotale ou totale [amputation] de la pointe et/ou de l'aile du nez",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA008",
    designation: "Amputation de la pyramide nasale",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "162.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA004",
    designation:
      "Amputation de la pyramide nasale étendue au philtrum et/ou à la joue",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "162.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA003",
    designation: "Excision ou décortication d'un rhinophyma, sans laser",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "85.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA001",
    designation: "Excision ou décortication d'un rhinophyma, avec laser",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "85.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA019",
    designation:
      "Réparation de perte de substance du nez par autogreffe composée d'auricule",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA018",
    designation: "Réparation de perte de substance du nez par lambeau local",
    tarifSecteur1Optam: "191.24",
    tarifHorsSecteur1HorsOptam: "191.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA012",
    designation: "Réparation de perte de substance du nez par lambeau régional",
    tarifSecteur1Optam: "210.57",
    tarifHorsSecteur1HorsOptam: "202.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA002",
    designation:
      "Réparation de perte de substance du nez par lambeau frontal à pédicule inférieur",
    tarifSecteur1Optam: "342.45",
    tarifHorsSecteur1HorsOptam: "330.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA014",
    designation: "Réparation de perte de substance du nez par lambeau scalpant",
    tarifSecteur1Optam: "514.03",
    tarifHorsSecteur1HorsOptam: "429.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA006",
    designation:
      "Réparation de perte de substance du nez par lambeau scalpant doublé par lambeau en charnière, lambeau frontal, et/ou autogreffe",
    tarifSecteur1Optam: "599.81",
    tarifHorsSecteur1HorsOptam: "479.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA023",
    designation:
      "Réparation de perte de substance du nez par lambeau brachial ou antébrachial à pédicule transitoire",
    tarifSecteur1Optam: "479.56",
    tarifHorsSecteur1HorsOptam: "479.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA021",
    designation:
      "Reconstruction du nez, de l’héminez ou de la pointe de nez avec armature ostéochondrale, lambeau de couverture cutanée et lambeau de doublure muqueuse",
    tarifSecteur1Optam: "684.89",
    tarifHorsSecteur1HorsOptam: "542.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA008",
    designation: "Rhinoplastie primitive de la pointe",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA003",
    designation:
      "Rhinoplastie sans ostéotomie, avec autogreffe de cartilage de septum nasal",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "254.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA024",
    designation: "Rhinoplastie avec ostéotomie, sans autogreffe de cartilage",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "237.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA013",
    designation:
      "Rhinoplastie avec ostéotomie et autogreffe de cartilage de septum nasal",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "292.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA007",
    designation: "Septoplastie nasale",
    tarifSecteur1Optam: "175.56",
    tarifHorsSecteur1HorsOptam: "154.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA001",
    designation:
      "Rhinoseptoplastie sans ostéotomie, sans autogreffe de cartilage",
    tarifSecteur1Optam: "225.46",
    tarifHorsSecteur1HorsOptam: "218.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA020",
    designation:
      "Rhinoseptoplastie sans ostéotomie, avec autogreffe de cartilage de septum nasal",
    tarifSecteur1Optam: "282.89",
    tarifHorsSecteur1HorsOptam: "251.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA004",
    designation:
      "Rhinoseptoplastie avec ostéotomie, sans autogreffe de cartilage",
    tarifSecteur1Optam: "282.89",
    tarifHorsSecteur1HorsOptam: "251.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA016",
    designation:
      "Rhinoseptoplastie avec ostéotomie et autogreffe de cartilage de septum nasal",
    tarifSecteur1Optam: "347.41",
    tarifHorsSecteur1HorsOptam: "306.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA010",
    designation: "Rhinoseptoplastie avec autogreffe osseuse et cartilagineuse",
    tarifSecteur1Optam: "365.14",
    tarifHorsSecteur1HorsOptam: "317.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA005",
    designation: "Plastie ostéo-cutanéo-cartilagineuse pour sténose narinaire",
    tarifSecteur1Optam: "415.03",
    tarifHorsSecteur1HorsOptam: "310.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA015",
    designation: "Plastie narinaire pour collapsus de la valve nasale interne",
    tarifSecteur1Optam: "181.72",
    tarifHorsSecteur1HorsOptam: "175.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA009",
    designation:
      "Plastie narinaire pour collapsus de la valve nasale interne, avec septoplastie",
    tarifSecteur1Optam: "284.13",
    tarifHorsSecteur1HorsOptam: "261.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA017",
    designation: "Plastie des orifices piriformes du nez chez le nourrisson",
    tarifSecteur1Optam: "253.33",
    tarifHorsSecteur1HorsOptam: "217.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GASA001",
    designation: "Fermeture de perforation du septum nasal",
    tarifSecteur1Optam: "66.22",
    tarifHorsSecteur1HorsOptam: "64.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA022",
    designation:
      "Correction secondaire du résultat esthétique d'une rhinoplastie ou d'une rhinoseptoplastie",
    tarifSecteur1Optam: "143.93",
    tarifHorsSecteur1HorsOptam: "136.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAJA001",
    designation: "Évacuation de collection du septum nasal",
    tarifSecteur1Optam: "53.13",
    tarifHorsSecteur1HorsOptam: "39.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GALD002",
    designation: "Pose de conformateur d'orifice narinaire",
    tarifSecteur1Optam: "91.63",
    tarifHorsSecteur1HorsOptam: "79.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GALP001",
    designation: "Pose d'une épithèse nasale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAFD002",
    designation: "Polypectomie intranasale",
    tarifSecteur1Optam: "64.68",
    tarifHorsSecteur1HorsOptam: "49.61",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAFD001",
    designation:
      "Exérèse de lésion ou de malformation de la cavité nasale, par voie nasale",
    tarifSecteur1Optam: "197.89",
    tarifHorsSecteur1HorsOptam: "132.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAFA006",
    designation:
      "Exérèse de lésion ou de malformation de la cavité nasale, par abord paralatéronasal",
    tarifSecteur1Optam: "408.87",
    tarifHorsSecteur1HorsOptam: "324.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAND001",
    designation:
      "Cautérisation unilatérale ou bilatérale de la muqueuse du cornet inférieur",
    tarifSecteur1Optam: "34.65",
    tarifHorsSecteur1HorsOptam: "28.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAME001",
    designation:
      "Turbinoplastie ou turbinectomie inférieure et/ou moyenne unilatérale ou bilatérale, par endoscopie",
    tarifSecteur1Optam: "77",
    tarifHorsSecteur1HorsOptam: "61.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAME002",
    designation:
      "Plastie unilatérale ou bilatérale de la cavité nasale pour rhinite atrophique, par endoscopie",
    tarifSecteur1Optam: "206.36",
    tarifHorsSecteur1HorsOptam: "203.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAMA011",
    designation:
      "Reconstruction de la cavité nasale pour hypoplasie ou aplasie, chez l'enfant",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "430.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GALD001",
    designation:
      "Pose unilatérale ou bilatérale de prothèse de calibrage de la cavité nasale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAGD001",
    designation:
      "Ablation unilatérale ou bilatérale de prothèse de calibrage de la cavité nasale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GABD002",
    designation: "Tamponnement nasal antérieur",
    tarifSecteur1Optam: "27.72",
    tarifHorsSecteur1HorsOptam: "24.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GABD001",
    designation: "Tamponnement nasal antérieur et postérieur",
    tarifSecteur1Optam: "56.21",
    tarifHorsSecteur1HorsOptam: "40.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GASE001",
    designation:
      "Hémostase intranasale par cautérisation ou électrocoagulation, par endoscopie",
    tarifSecteur1Optam: "26.18",
    tarifHorsSecteur1HorsOptam: "23.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GASE002",
    designation:
      "Hémostase intranasale par injection unilatérale ou bilatérale transmuqueuse d'agent pharmacologique, par endoscopie",
    tarifSecteur1Optam: "94.71",
    tarifHorsSecteur1HorsOptam: "63",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GAGD002",
    designation: "Ablation de corps étranger de la cavité nasale",
    tarifSecteur1Optam: "35.42",
    tarifHorsSecteur1HorsOptam: "24.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBJD002",
    designation:
      "Évacuation de collection du sinus maxillaire, par voie méatale inférieure",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBPE003",
    designation: "Méatotomie nasale inférieure bilatérale, par endoscopie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBPE001",
    designation: "Méatotomie nasale moyenne, par endoscopie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBPA004",
    designation:
      "Sinusotomie maxillaire, par abord de la fosse canine [abord vestibulaire]",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBPA002",
    designation:
      "Sinusotomie maxillaire, par abord de la fosse canine [abord vestibulaire] et par voie méatale moyenne",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBPA001",
    designation:
      "Sinusotomie maxillaire par abord de la fosse canine [abord vestibulaire], avec sinusotomie ethmoïdale et/ou sphénoïdale",
    tarifSecteur1Optam: "287.21",
    tarifHorsSecteur1HorsOptam: "271.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBBA002",
    designation:
      "Comblement préimplantaire sousmuqueux unilatéral du sinus maxillaire",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "240.35",
    regroupement: "ASC",
  },
  {
    codeCCAM: "GBBA364",
    designation:
      "Comblement préimplantaire sousmuqueux bilatéral du sinus maxillaire",
    tarifSecteur1Optam: "360.53",
    tarifHorsSecteur1HorsOptam: "360.53",
    regroupement: "ASC",
  },
  {
    codeCCAM: "GBFA002",
    designation:
      "Exérèse de lésion du sinus ethmoïdal, par abord paralatéronasal ou par abord bilatéral de la fosse canine [abord bivestibulaire]",
    tarifSecteur1Optam: "270.74",
    tarifHorsSecteur1HorsOptam: "270.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA021",
    designation: "Ethmoïdectomie antérieure, par endoscopie",
    tarifSecteur1Optam: "223.3",
    tarifHorsSecteur1HorsOptam: "182.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA023",
    designation:
      "Ethmoïdectomie antérieure unilatérale avec septoplastie nasale, par endoscopie",
    tarifSecteur1Optam: "324.94",
    tarifHorsSecteur1HorsOptam: "267.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBFA008",
    designation:
      "Ethmoïdectomie antérieure bilatérale avec septoplastie nasale, par endoscopie",
    tarifSecteur1Optam: "487.41",
    tarifHorsSecteur1HorsOptam: "361.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA024",
    designation: "Ethmoïdectomie totale unilatérale, par endoscopie",
    tarifSecteur1Optam: "375.76",
    tarifHorsSecteur1HorsOptam: "270.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA016",
    designation:
      "Ethmoïdectomie totale unilatérale avec septoplastie nasale, par endoscopie",
    tarifSecteur1Optam: "394.24",
    tarifHorsSecteur1HorsOptam: "307.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA017",
    designation:
      "Ethmoïdectomie totale unilatérale avec sphénoïdotomie, par endoscopie",
    tarifSecteur1Optam: "472.78",
    tarifHorsSecteur1HorsOptam: "344.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA015",
    designation:
      "Ethmoïdectomie totale unilatérale avec septoplastie nasale et sphénoïdotomie, par endoscopie",
    tarifSecteur1Optam: "503.58",
    tarifHorsSecteur1HorsOptam: "388.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA022",
    designation: "Ethmoïdectomie totale bilatérale, par endoscopie",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "404.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA020",
    designation:
      "Ethmoïdectomie totale bilatérale avec septoplastie nasale, par endoscopie",
    tarifSecteur1Optam: "589.82",
    tarifHorsSecteur1HorsOptam: "421.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA018",
    designation:
      "Ethmoïdectomie totale bilatérale avec sphénoïdotomie, par endoscopie",
    tarifSecteur1Optam: "642.18",
    tarifHorsSecteur1HorsOptam: "477.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA019",
    designation:
      "Ethmoïdectomie totale bilatérale avec septoplastie nasale et sphénoïdotomie, par endoscopie",
    tarifSecteur1Optam: "776.93",
    tarifHorsSecteur1HorsOptam: "555.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBJE002",
    designation:
      "Évacuation de collection du sinus sphénoïdal, par endoscopie directe ou transethmoïdale",
    tarifSecteur1Optam: "159.39",
    tarifHorsSecteur1HorsOptam: "145.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBFA007",
    designation: "Exérèse de lésion du sinus sphénoïdal, par endoscopie",
    tarifSecteur1Optam: "246.4",
    tarifHorsSecteur1HorsOptam: "195.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBJB001",
    designation:
      "Évacuation de collection du sinus frontal, par voie transcutanée",
    tarifSecteur1Optam: "72.38",
    tarifHorsSecteur1HorsOptam: "54.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBJE001",
    designation:
      "Évacuation de collection du sinus frontal, par endoscopie nasale",
    tarifSecteur1Optam: "161.7",
    tarifHorsSecteur1HorsOptam: "105.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBJA002",
    designation: "Évacuation de collection du sinus frontal, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBJA001",
    designation:
      "Évacuation unilatérale ou bilatérale de collection du sinus frontal, par endoscopie nasale et par abord direct",
    tarifSecteur1Optam: "235.62",
    tarifHorsSecteur1HorsOptam: "206.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBPA003",
    designation:
      "Sinusotomie frontale unilatérale ou bilatérale avec résection du septum et du plancher nasaux, par endoscopie nasale ou par abord direct",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "430.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBFA001",
    designation:
      "Exérèse de lésion du sinus frontal, par abord frontosourcilier",
    tarifSecteur1Optam: "235.62",
    tarifHorsSecteur1HorsOptam: "206.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBFA003",
    designation: "Exérèse de lésion du sinus frontal, par abord coronal",
    tarifSecteur1Optam: "284.9",
    tarifHorsSecteur1HorsOptam: "235.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBCA001",
    designation:
      "Exclusion unilatérale ou bilatérale de sinus frontal par comblement",
    tarifSecteur1Optam: "331.87",
    tarifHorsSecteur1HorsOptam: "280.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBJD001",
    designation:
      "Séance de lavage de sinus paranasal par méthode de déplacement",
    tarifSecteur1Optam: "36.19",
    tarifHorsSecteur1HorsOptam: "25.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBGD001",
    designation:
      "Déméchage et/ou nettoyage postopératoire de sinus paranasal, sous anesthésie générale",
    tarifSecteur1Optam: "53.13",
    tarifHorsSecteur1HorsOptam: "42.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GBPE002",
    designation:
      "Marsupialisation de mucocèle du sinus ethmoïdal et/ou du sinus frontal, par endoscopie",
    tarifSecteur1Optam: "232.54",
    tarifHorsSecteur1HorsOptam: "205.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCFD001",
    designation:
      "Exérèse de lésion du rhinopharynx, par voie nasale et/ou par voie buccale sans laser",
    tarifSecteur1Optam: "135.52",
    tarifHorsSecteur1HorsOptam: "96.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCFD002",
    designation:
      "Exérèse de lésion du rhinopharynx, par voie nasale et/ou par voie buccale avec laser",
    tarifSecteur1Optam: "135.52",
    tarifHorsSecteur1HorsOptam: "96.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GCFA005",
    designation:
      "Exérèse de lésion du rhinopharynx, par abord transpalatin sans laser",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "214.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCFA003",
    designation:
      "Exérèse de lésion du rhinopharynx, par abord transpalatin avec laser",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "214.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCFA007",
    designation:
      "Exérèse de lésion du rhinopharynx et/ou de la fosse infratemporale, par abord transmaxillaire",
    tarifSecteur1Optam: "850.85",
    tarifHorsSecteur1HorsOptam: "651.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCFA004",
    designation:
      "Exérèse de lésion du rhinopharynx et/ou de la fosse infratemporale, par abord transmandibulaire",
    tarifSecteur1Optam: "850.85",
    tarifHorsSecteur1HorsOptam: "651.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCFA006",
    designation:
      "Exérèse de lésion du rhinopharynx et/ou de la fosse infratemporale, par abord transmaxillaire et par abord transmandibulaire",
    tarifSecteur1Optam: "1037.19",
    tarifHorsSecteur1HorsOptam: "759.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCFA001",
    designation:
      "Exérèse de lésion du rhinopharynx et/ou de la fosse infratemporale, par abord préauriculaire et/ou par abord transzygomatique",
    tarifSecteur1Optam: "759.58",
    tarifHorsSecteur1HorsOptam: "759.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCFA002",
    designation:
      "Exérèse de lésion du rhinopharynx et/ou de la fosse infratemporale, par abord transpétreux antérieur",
    tarifSecteur1Optam: "759.58",
    tarifHorsSecteur1HorsOptam: "759.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCCD001",
    designation:
      "Perforation [Discision] unilatérale ou bilatérale des choanes",
    tarifSecteur1Optam: "110.88",
    tarifHorsSecteur1HorsOptam: "90.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCME004",
    designation:
      "Plastie des choanes par endoscopie nasale sans laser, pour imperforation unilatérale",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCME003",
    designation:
      "Plastie des choanes par endoscopie nasale sans laser, pour imperforation bilatérale",
    tarifSecteur1Optam: "305",
    tarifHorsSecteur1HorsOptam: "305",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCME002",
    designation:
      "Plastie des choanes par endoscopie nasale avec laser, pour imperforation unilatérale",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCME001",
    designation:
      "Plastie des choanes par endoscopie nasale avec laser, pour imperforation bilatérale",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GCMA001",
    designation:
      "Plastie des choanes par abord transpalatin, pour imperforation unilatérale ou bilatérale",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDPE001",
    designation:
      "Désobstruction du larynx, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "182.49",
    tarifHorsSecteur1HorsOptam: "149.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE012",
    designation:
      "Dilatation et/ou résection de sténose du larynx, par endoscopie sans laser",
    tarifSecteur1Optam: "195.58",
    tarifHorsSecteur1HorsOptam: "157.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDFE016",
    designation:
      "Dilatation et/ou résection de sténose du larynx, par endoscopie avec laser",
    tarifSecteur1Optam: "195.58",
    tarifHorsSecteur1HorsOptam: "157.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDNE002",
    designation:
      "Destruction de lésion du larynx, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDNE003",
    designation:
      "Destruction de lésion du larynx, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDNE004",
    designation:
      "Coagulation d'un œdème du larynx, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "87.01",
    tarifHorsSecteur1HorsOptam: "74.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDNE001",
    designation:
      "Coagulation d'un œdème du larynx, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "87.01",
    tarifHorsSecteur1HorsOptam: "74.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE008",
    designation:
      "Épiglottectomie partielle, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "149.38",
    tarifHorsSecteur1HorsOptam: "110.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE009",
    designation:
      "Épiglottectomie partielle, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "149.38",
    tarifHorsSecteur1HorsOptam: "110.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE015",
    designation:
      "Exérèse du vestibule du larynx, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "182.49",
    tarifHorsSecteur1HorsOptam: "130.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE010",
    designation:
      "Exérèse du vestibule du larynx, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "182.49",
    tarifHorsSecteur1HorsOptam: "130.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE004",
    designation:
      "Exérèse de lésion ou épluchage de la muqueuse du plan glottique, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "109.34",
    tarifHorsSecteur1HorsOptam: "107.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE002",
    designation:
      "Exérèse de lésion ou épluchage de la muqueuse du plan glottique, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "110.88",
    tarifHorsSecteur1HorsOptam: "108.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE014",
    designation:
      "Exérèse de lésion sousmuqueuse du plan glottique, par laryngoscopie directe",
    tarifSecteur1Optam: "129.36",
    tarifHorsSecteur1HorsOptam: "118.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE007",
    designation: "Cordectomie laryngée, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "141.68",
    tarifHorsSecteur1HorsOptam: "126.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE003",
    designation: "Cordectomie laryngée, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "141.68",
    tarifHorsSecteur1HorsOptam: "126.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA004",
    designation: "Cordectomie laryngée unilatérale, par thyrotomie",
    tarifSecteur1Optam: "213.29",
    tarifHorsSecteur1HorsOptam: "211.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA002",
    designation: "Cordectomie laryngée bilatérale, par thyrotomie",
    tarifSecteur1Optam: "238.7",
    tarifHorsSecteur1HorsOptam: "226.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE001",
    designation: "Aryténoïdectomie, par laryngoscopie directe",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA010",
    designation: "Aryténoïdectomie ou aryténoïdopexie, par thyrotomie",
    tarifSecteur1Optam: "271.04",
    tarifHorsSecteur1HorsOptam: "227.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE005",
    designation:
      "Résection ou section des plis aryépiglottiques, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "110.88",
    tarifHorsSecteur1HorsOptam: "88.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE013",
    designation:
      "Résection ou section des plis aryépiglottiques, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "110.88",
    tarifHorsSecteur1HorsOptam: "88.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE011",
    designation:
      "Exérèse de lésion laryngée infraglottique, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "140.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFE006",
    designation:
      "Exérèse de lésion laryngée infraglottique, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "140.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA007",
    designation: "Exérèse de lésion laryngée infraglottique, par laryngotomie",
    tarifSecteur1Optam: "321.86",
    tarifHorsSecteur1HorsOptam: "274.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA015",
    designation:
      "Exérèse de lésion du larynx, par thyrotomie latérale extramuqueuse",
    tarifSecteur1Optam: "226.38",
    tarifHorsSecteur1HorsOptam: "219.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA001",
    designation: "Hyo-thyro-épiglottectomie",
    tarifSecteur1Optam: "471.24",
    tarifHorsSecteur1HorsOptam: "361.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA006",
    designation: "Laryngectomie partielle verticale",
    tarifSecteur1Optam: "400.4",
    tarifHorsSecteur1HorsOptam: "320.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA016",
    designation: "Laryngectomie frontale antérieure avec épiglottoplastie",
    tarifSecteur1Optam: "522.83",
    tarifHorsSecteur1HorsOptam: "391.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA014",
    designation: "Laryngectomie supraglottique",
    tarifSecteur1Optam: "522.83",
    tarifHorsSecteur1HorsOptam: "486.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA008",
    designation: "Laryngectomie supraglottique étendue à la base de langue",
    tarifSecteur1Optam: "555.94",
    tarifHorsSecteur1HorsOptam: "506.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA009",
    designation: "Laryngectomie supracricoïdienne avec cricohyoïdopexie",
    tarifSecteur1Optam: "592.13",
    tarifHorsSecteur1HorsOptam: "527.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA011",
    designation:
      "Laryngectomie supracricoïdienne avec crico-hyoïdo-épiglottopexie",
    tarifSecteur1Optam: "592.13",
    tarifHorsSecteur1HorsOptam: "527.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA013",
    designation: "Hémilaryngopharyngectomie",
    tarifSecteur1Optam: "535.15",
    tarifHorsSecteur1HorsOptam: "398.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA017",
    designation: "Laryngopharyngectomie supraglottique",
    tarifSecteur1Optam: "560.56",
    tarifHorsSecteur1HorsOptam: "508.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA012",
    designation:
      "Laryngopharyngectomie supracricoïdienne avec cricohyoïdopexie",
    tarifSecteur1Optam: "595.98",
    tarifHorsSecteur1HorsOptam: "529.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA003",
    designation: "Glottectomie horizontale",
    tarifSecteur1Optam: "485.87",
    tarifHorsSecteur1HorsOptam: "369.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA005",
    designation: "Laryngectomie totale",
    tarifSecteur1Optam: "493.57",
    tarifHorsSecteur1HorsOptam: "469.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDFA018",
    designation: "Laryngectomie totale étendue à la base de langue",
    tarifSecteur1Optam: "565.95",
    tarifHorsSecteur1HorsOptam: "511.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDCA001",
    designation: "Suture de plaie du larynx",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "188.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDMA003",
    designation: "Réparation de fracas du larynx",
    tarifSecteur1Optam: "328.5",
    tarifHorsSecteur1HorsOptam: "328.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDEA001",
    designation: "Médialisation du pli vocal [corde vocale], par cervicotomie",
    tarifSecteur1Optam: "231",
    tarifHorsSecteur1HorsOptam: "177.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDMA001",
    designation: "Laryngoplastie, par cervicotomie",
    tarifSecteur1Optam: "500.5",
    tarifHorsSecteur1HorsOptam: "378.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDMA002",
    designation: "Laryngotrachéoplastie, par cervicotomie",
    tarifSecteur1Optam: "649.11",
    tarifHorsSecteur1HorsOptam: "508.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDSA001",
    designation: "Fermeture d'un diastème laryngé congénital",
    tarifSecteur1Optam: "318.78",
    tarifHorsSecteur1HorsOptam: "255.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDSA002",
    designation: "Fermeture d'un diastème laryngotrachéal congénital",
    tarifSecteur1Optam: "491.63",
    tarifHorsSecteur1HorsOptam: "491.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDSA004",
    designation:
      "Fermeture d'une fente laryngo-trachéo-pharyngo-œsophagienne congénitale, par cervicotomie",
    tarifSecteur1Optam: "498.48",
    tarifHorsSecteur1HorsOptam: "498.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDSA003",
    designation:
      "Fermeture d'une fente laryngo-trachéo-pharyngo-œsophagienne congénitale, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "711.62",
    tarifHorsSecteur1HorsOptam: "711.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDLB001",
    designation:
      "Injection dans le pli vocal [corde vocale], par voie transcutanée",
    tarifSecteur1Optam: "103.18",
    tarifHorsSecteur1HorsOptam: "94.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDLE004",
    designation:
      "Injection de substance hétérologue dans le pli vocal [corde vocale], par laryngoscopie directe",
    tarifSecteur1Optam: "93.94",
    tarifHorsSecteur1HorsOptam: "89.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDLE003",
    designation:
      "Injection de graisse dans le pli vocal [corde vocale], par laryngoscopie directe",
    tarifSecteur1Optam: "93.94",
    tarifHorsSecteur1HorsOptam: "89.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GDLE001",
    designation:
      "Pose ou changement de prothèse de calibrage du larynx, par laryngoscopie directe",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "120.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDGE001",
    designation:
      "Ablation de corps étranger du larynx, par laryngoscopie directe",
    tarifSecteur1Optam: "182.49",
    tarifHorsSecteur1HorsOptam: "123.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDPE002",
    designation: "Cordotomie postérieure, par laryngoscopie directe sans laser",
    tarifSecteur1Optam: "100.1",
    tarifHorsSecteur1HorsOptam: "82.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDPE003",
    designation: "Cordotomie postérieure, par laryngoscopie directe avec laser",
    tarifSecteur1Optam: "116.27",
    tarifHorsSecteur1HorsOptam: "91.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDCA002",
    designation: "Exclusion du larynx",
    tarifSecteur1Optam: "216.37",
    tarifHorsSecteur1HorsOptam: "169.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GECA003",
    designation:
      "Suture de plaie ou de rupture de la trachée, par cervicotomie",
    tarifSecteur1Optam: "407.68",
    tarifHorsSecteur1HorsOptam: "280.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GECA002",
    designation:
      "Suture de plaie ou de rupture de la trachée, par cervicothoracotomie ou par thoracotomie",
    tarifSecteur1Optam: "596.98",
    tarifHorsSecteur1HorsOptam: "521.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GECA001",
    designation: "Suture de plaie ou de rupture de bronche, par thoracotomie",
    tarifSecteur1Optam: "671.42",
    tarifHorsSecteur1HorsOptam: "564.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELF001",
    designation:
      "Pose de cathéter laryngé ou de cathéter trachéal, par voie transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEPA004",
    designation: "Trachéotomie, par cervicotomie",
    tarifSecteur1Optam: "142.45",
    tarifHorsSecteur1HorsOptam: "126.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GDLD001",
    designation: "Pose d'un masque laryngé ou d'un Combitube®",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELD004",
    designation: "Intubation trachéale",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELD002",
    designation: "Intubation trachéale en dehors d'un bloc médicotechnique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE004",
    designation:
      "Intubation trachéale, par fibroscopie ou dispositif laryngé particulier",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELD003",
    designation: "Intubation trachéale avec instillation de surfactant exogène",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE009",
    designation:
      "Pose de guide intrabronchique pour curiethérapie [endobrachytherapy], par fibroscopie",
    tarifSecteur1Optam: "113.49",
    tarifHorsSecteur1HorsOptam: "113.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE003",
    designation:
      "Pose intrabronchique de matériel à visée hémostatique, par fibroscopie",
    tarifSecteur1Optam: "148.2",
    tarifHorsSecteur1HorsOptam: "126.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE007",
    designation:
      "Pose intrabronchique de matériel à visée hémostatique, par bronchoscopie au tube rigide",
    tarifSecteur1Optam: "148.96",
    tarifHorsSecteur1HorsOptam: "126.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE008",
    designation: "Pose d'une endoprothèse trachéobronchique, par endoscopie",
    tarifSecteur1Optam: "193.04",
    tarifHorsSecteur1HorsOptam: "182.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELE006",
    designation: "Pose de tuteur trachéal, par endoscopie",
    tarifSecteur1Optam: "296.36",
    tarifHorsSecteur1HorsOptam: "212.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELA001",
    designation: "Pose de tuteur trachéal, par cervicotomie",
    tarifSecteur1Optam: "295.65",
    tarifHorsSecteur1HorsOptam: "211.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELE133",
    designation: "Pose de spirales intrabronchiques par endoscopie",
    tarifSecteur1Optam: "304",
    tarifHorsSecteur1HorsOptam: "304",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELE308",
    designation: "Pose de valve intrabronchique par endoscopie",
    tarifSecteur1Optam: "271.32",
    tarifHorsSecteur1HorsOptam: "271.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEGE001",
    designation:
      "Ablation de corps étranger intratrachéal et/ou intrabronchique, par bronchoscopie au tube rigide",
    tarifSecteur1Optam: "171",
    tarifHorsSecteur1HorsOptam: "169.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEGE003",
    designation:
      "Ablation de corps étranger intratrachéal et/ou intrabronchique, par fibroscopie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEGA001",
    designation: "Ablation de corps étranger intrabronchique, par thoracotomie",
    tarifSecteur1Optam: "526.79",
    tarifHorsSecteur1HorsOptam: "393.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEME121",
    designation:
      "Plastie des bronches par radiofréquence par endoscopie [Thermoplastie]",
    tarifSecteur1Optam: "136.8",
    tarifHorsSecteur1HorsOptam: "136.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEMA001",
    designation:
      "Plastie d'agrandissement d'un orifice de trachéostomie ou de trachéotomie, par abord direct",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "155.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GESA002",
    designation: "Fermeture de trachéotomie",
    tarifSecteur1Optam: "153.23",
    tarifHorsSecteur1HorsOptam: "132.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GESA003",
    designation: "Fermeture de fistule bronchique, par thoracotomie",
    tarifSecteur1Optam: "622.5",
    tarifHorsSecteur1HorsOptam: "562.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEMA002",
    designation:
      "Plastie de la trachée par autogreffe ou lambeau, par cervicotomie ou par thoracotomie",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEKA001",
    designation:
      "Remplacement de la trachée par prothèse, par cervicotomie ou par thoracotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GENE001",
    designation:
      "Destruction mécanique de lésion de l'arbre trachéobronchique, par bronchoscopie au tube rigide",
    tarifSecteur1Optam: "177.84",
    tarifHorsSecteur1HorsOptam: "143.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GENE008",
    designation:
      "Destruction mécanique de lésion de l'arbre trachéobronchique, par fibroscopie",
    tarifSecteur1Optam: "138.32",
    tarifHorsSecteur1HorsOptam: "120.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GENE006",
    designation:
      "Destruction de lésion de l'arbre trachéobronchique par cryothérapie ou thermocoagulation, par bronchoscopie au tube rigide",
    tarifSecteur1Optam: "158.84",
    tarifHorsSecteur1HorsOptam: "132.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GENE005",
    designation:
      "Destruction de lésion de l'arbre trachéobronchique par cryothérapie ou thermocoagulation, par fibroscopie",
    tarifSecteur1Optam: "123.12",
    tarifHorsSecteur1HorsOptam: "111.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GENE004",
    designation:
      "Destruction de lésion de l'arbre trachéobronchique avec laser, par bronchoscopie au tube rigide",
    tarifSecteur1Optam: "163.4",
    tarifHorsSecteur1HorsOptam: "135.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GENE002",
    designation:
      "Destruction de lésion de l'arbre trachéobronchique avec laser, par fibroscopie",
    tarifSecteur1Optam: "136.04",
    tarifHorsSecteur1HorsOptam: "119.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GENE003",
    designation:
      "Destruction tissulaire photodynamique de lésion de l'arbre trachéobronchique avec laser, par fibroscopie",
    tarifSecteur1Optam: "138.32",
    tarifHorsSecteur1HorsOptam: "120.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFE002",
    designation:
      "Séance de résection et/ou de dilatation de sténose de la trachée, par endoscopie sans laser",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "188.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEFE001",
    designation:
      "Séance de résection et/ou de dilatation de sténose de la trachée, par endoscopie avec laser",
    tarifSecteur1Optam: "248.71",
    tarifHorsSecteur1HorsOptam: "188.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEFA010",
    designation: "Résection-anastomose thyrotrachéale, par cervicotomie",
    tarifSecteur1Optam: "771.39",
    tarifHorsSecteur1HorsOptam: "648.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA002",
    designation: "Résection-anastomose cricotrachéale, par cervicotomie",
    tarifSecteur1Optam: "771.39",
    tarifHorsSecteur1HorsOptam: "648.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA012",
    designation:
      "Résection-anastomose de la trachée sans abaissement du larynx, par cervicotomie",
    tarifSecteur1Optam: "633.14",
    tarifHorsSecteur1HorsOptam: "568.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA008",
    designation:
      "Résection-anastomose de la trachée sans abaissement du larynx, par cervicothoracotomie",
    tarifSecteur1Optam: "782.03",
    tarifHorsSecteur1HorsOptam: "654.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA006",
    designation:
      "Résection-anastomose de la trachée sans abaissement du larynx, par thoracotomie",
    tarifSecteur1Optam: "823.86",
    tarifHorsSecteur1HorsOptam: "679.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA007",
    designation:
      "Résection-anastomose de la trachée avec abaissement du larynx, par cervicotomie",
    tarifSecteur1Optam: "838.04",
    tarifHorsSecteur1HorsOptam: "687.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA003",
    designation:
      "Résection-anastomose de la trachée avec abaissement du larynx, par cervicothoracotomie",
    tarifSecteur1Optam: "822.98",
    tarifHorsSecteur1HorsOptam: "822.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA001",
    designation:
      "Résection-anastomose de la trachée sans mobilisation viscérale cardiopulmonaire par thoracotomie, avec abaissement du larynx par cervicotomie",
    tarifSecteur1Optam: "1027.34",
    tarifHorsSecteur1HorsOptam: "862.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA005",
    designation:
      "Résection-anastomose de la trachée avec mobilisation viscérale cardiopulmonaire par thoracotomie, avec abaissement du larynx par cervicotomie",
    tarifSecteur1Optam: "968.85",
    tarifHorsSecteur1HorsOptam: "968.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA009",
    designation:
      "Résection-anastomose de la trachée avec mobilisation viscérale cardiopulmonaire sans abaissement du larynx, par thoracotomie",
    tarifSecteur1Optam: "788.29",
    tarifHorsSecteur1HorsOptam: "788.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA004",
    designation:
      "Résection-anastomose de la bifurcation trachéale, par thoracotomie",
    tarifSecteur1Optam: "1005.36",
    tarifHorsSecteur1HorsOptam: "828.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA013",
    designation:
      "Résection-anastomose de la trachée pour sténose congénitale de la trachée, par thoracotomie avec CEC",
    tarifSecteur1Optam: "1701.6",
    tarifHorsSecteur1HorsOptam: "1418.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEFA011",
    designation: "Résection-anastomose bronchique, par thoracotomie",
    tarifSecteur1Optam: "752.96",
    tarifHorsSecteur1HorsOptam: "637.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELD001",
    designation:
      "Instillation de surfactant exogène chez un nouveau-né en ventilation mécanique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELD005",
    designation:
      "Nébulisation d'agent thérapeutique à destination bronchique [aérosol] avec surveillance de la saturation en oxygène par mesure transcutanée [SpO2] et surveillance de la fréquence cardiaque, pendant au moins 2 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE002",
    designation:
      "Instillation intrabronchique d'agent pharmacologique à visée thérapeutique, par bronchoscopie au tube rigide",
    tarifSecteur1Optam: "117.8",
    tarifHorsSecteur1HorsOptam: "114.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEJD002",
    designation:
      "Lavage pulmonaire unilatéral à visée thérapeutique pour protéinose alvéolaire, par sonde d'intubation à double lumière",
    tarifSecteur1Optam: "153.92",
    tarifHorsSecteur1HorsOptam: "153.92",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GELE005",
    designation:
      "Instillation intrabronchique d'agent pharmacologique à visée thérapeutique, par fibroscopie",
    tarifSecteur1Optam: "110.4",
    tarifHorsSecteur1HorsOptam: "110.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEJE002",
    designation:
      "Lavage pulmonaire unilatéral à visée thérapeutique pour protéinose alvéolaire, par endoscopie",
    tarifSecteur1Optam: "185.44",
    tarifHorsSecteur1HorsOptam: "153.92",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEJE001",
    designation:
      "Aspiration intrabronchique à visée thérapeutique, par fibroscopie [Fibroaspiration bronchique]",
    tarifSecteur1Optam: "81.32",
    tarifHorsSecteur1HorsOptam: "63.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GEJE003",
    designation:
      "Aspiration intrabronchique à visée thérapeutique chez un patient intubé ou trachéotomisé, par fibroscopie",
    tarifSecteur1Optam: "76",
    tarifHorsSecteur1HorsOptam: "60.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEME002",
    designation:
      "Création d'une fistule œsotrachéale avec pose d'implant phonatoire, par endoscopie",
    tarifSecteur1Optam: "126.28",
    tarifHorsSecteur1HorsOptam: "113.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GKGD002",
    designation:
      "Ablation d'un implant phonatoire œsotrachéal, sans endoscopie",
    tarifSecteur1Optam: "30.03",
    tarifHorsSecteur1HorsOptam: "25.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKGE002",
    designation:
      "Ablation d'implant phonatoire œsotrachéal ou de tube de calibrage intralaryngé, par endoscopie",
    tarifSecteur1Optam: "57.75",
    tarifHorsSecteur1HorsOptam: "57.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKKD002",
    designation:
      "Changement d'un implant phonatoire œsotrachéal, sans endoscopie",
    tarifSecteur1Optam: "97.79",
    tarifHorsSecteur1HorsOptam: "80.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKKE002",
    designation: "Changement d'implant phonatoire œsotrachéal, par endoscopie",
    tarifSecteur1Optam: "98.56",
    tarifHorsSecteur1HorsOptam: "81.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HESA010",
    designation:
      "Fermeture de fistule œsotrachéale après implant phonatoire, par cervicotomie",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GKRP001",
    designation:
      "Séance de rééducation de la voix, de la parole et/ou du langage, avec contrôle instrumental",
    tarifSecteur1Optam: "53.9",
    tarifHorsSecteur1HorsOptam: "36.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFFC002",
    designation:
      "Exérèse partielle non anatomique du poumon, par thoracoscopie",
    tarifSecteur1Optam: "412.88",
    tarifHorsSecteur1HorsOptam: "412.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA017",
    designation:
      "Exérèse partielle non anatomique unique du poumon, par thoracotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA021",
    designation:
      "Exérèses partielles non anatomiques multiples du poumon, par thoracotomie",
    tarifSecteur1Optam: "486.37",
    tarifHorsSecteur1HorsOptam: "465.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFC006",
    designation: "Résection de bulle pulmonaire, par thoracoscopie",
    tarifSecteur1Optam: "412.88",
    tarifHorsSecteur1HorsOptam: "412.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFC005",
    designation:
      "Résection de bulle pulmonaire avec abrasion ou exérèse de la plèvre pariétale, par thoracoscopie",
    tarifSecteur1Optam: "447.63",
    tarifHorsSecteur1HorsOptam: "447.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA005",
    designation: "Résection de bulle d'emphysème pulmonaire, par thoracotomie",
    tarifSecteur1Optam: "416.89",
    tarifHorsSecteur1HorsOptam: "399.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA032",
    designation:
      "Résection de bulle d'emphysème avec réduction de volume pulmonaire, par thoracotomie",
    tarifSecteur1Optam: "509.06",
    tarifHorsSecteur1HorsOptam: "452.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFBA004",
    designation:
      "Réduction de volume pulmonaire, par thoracoscopie ou par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "555.86",
    tarifHorsSecteur1HorsOptam: "495.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFBA002",
    designation: "Réduction unilatérale de volume pulmonaire, par thoracotomie",
    tarifSecteur1Optam: "555.86",
    tarifHorsSecteur1HorsOptam: "479.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFBA001",
    designation:
      "Réduction bilatérale de volume pulmonaire, par thoracotomie unique",
    tarifSecteur1Optam: "810.39",
    tarifHorsSecteur1HorsOptam: "671.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFBA003",
    designation:
      "Réduction bilatérale de volume pulmonaire, par thoracotomie bilatérale",
    tarifSecteur1Optam: "728.1",
    tarifHorsSecteur1HorsOptam: "728.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA014",
    designation: "Exérèse de kyste hydatique du poumon, par thoracotomie",
    tarifSecteur1Optam: "556.57",
    tarifHorsSecteur1HorsOptam: "480.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA029",
    designation:
      "Segmentectomie pulmonaire unique ou multiple, par thoracotomie",
    tarifSecteur1Optam: "680.64",
    tarifHorsSecteur1HorsOptam: "578.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA009",
    designation:
      "Lobectomie pulmonaire, par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "838.75",
    tarifHorsSecteur1HorsOptam: "686.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA013",
    designation: "Lobectomie pulmonaire, par thoracotomie",
    tarifSecteur1Optam: "743.03",
    tarifHorsSecteur1HorsOptam: "614.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA026",
    designation:
      "Lobectomie pulmonaire avec résection-anastomose de la bifurcation trachéale, par thoracotomie",
    tarifSecteur1Optam: "1004.65",
    tarifHorsSecteur1HorsOptam: "862.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA004",
    designation:
      "Lobectomie pulmonaire avec résection-anastomose ou réimplantation de bronche, par thoracotomie",
    tarifSecteur1Optam: "939.43",
    tarifHorsSecteur1HorsOptam: "825.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA027",
    designation:
      "Lobectomie pulmonaire avec résection de la paroi thoracique, par thoracotomie",
    tarifSecteur1Optam: "824.57",
    tarifHorsSecteur1HorsOptam: "767.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA016",
    designation:
      "Lobectomie pulmonaire avec résection-anastomose de la veine cave supérieure ou résection de l'oreillette gauche, par thoracotomie",
    tarifSecteur1Optam: "1032.3",
    tarifHorsSecteur1HorsOptam: "953.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA006",
    designation:
      "Lobectomie pulmonaire avec résection et remplacement prothétique de la veine cave supérieure, par thoracotomie",
    tarifSecteur1Optam: "1000.13",
    tarifHorsSecteur1HorsOptam: "1000.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA022",
    designation:
      "Lobectomie pulmonaire avec résection d'organe et/ou de structure de voisinage, par thoracotomie",
    tarifSecteur1Optam: "944.39",
    tarifHorsSecteur1HorsOptam: "869.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA008",
    designation:
      "Lobectomie pulmonaire supérieure avec résection de côte et libération du plexus brachial, par thoracotomie",
    tarifSecteur1Optam: "1042.23",
    tarifHorsSecteur1HorsOptam: "915.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA030",
    designation:
      "Lobectomie pulmonaire supérieure avec résection de côte et libération du plexus brachial, par cervicothoracotomie",
    tarifSecteur1Optam: "1178.36",
    tarifHorsSecteur1HorsOptam: "994.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA033",
    designation:
      "Lobectomie pulmonaire supérieure avec résection de côte, de vertèbre, de vaisseau subclavier, exérèse de nœud [ganglion] lymphatique cervical et libération du plexus brachial, par cervicothoracotomie",
    tarifSecteur1Optam: "1338.59",
    tarifHorsSecteur1HorsOptam: "1087.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA019",
    designation:
      "Exérèse de lobe pulmonaire restant [Totalisation de pneumonectomie], par thoracotomie",
    tarifSecteur1Optam: "996.15",
    tarifHorsSecteur1HorsOptam: "761.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA018",
    designation: "Bilobectomie pulmonaire, par thoracotomie",
    tarifSecteur1Optam: "827.4",
    tarifHorsSecteur1HorsOptam: "663.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA023",
    designation:
      "Bilobectomie pulmonaire avec résection-anastomose ou réimplantation de bronche, par thoracotomie",
    tarifSecteur1Optam: "1040.1",
    tarifHorsSecteur1HorsOptam: "883.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA010",
    designation:
      "Bilobectomie pulmonaire avec résection de la paroi thoracique, par thoracotomie",
    tarifSecteur1Optam: "918.16",
    tarifHorsSecteur1HorsOptam: "821.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA031",
    designation:
      "Bilobectomie pulmonaire avec résection-anastomose de la veine cave supérieure ou résection de l'oreillette gauche, par thoracotomie",
    tarifSecteur1Optam: "1057.12",
    tarifHorsSecteur1HorsOptam: "967.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA015",
    designation:
      "Bilobectomie pulmonaire avec résection et remplacement prothétique de la veine cave supérieure, par thoracotomie",
    tarifSecteur1Optam: "1004.92",
    tarifHorsSecteur1HorsOptam: "1004.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA034",
    designation:
      "Bilobectomie pulmonaire avec résection d'organe et/ou de structure de voisinage, par thoracotomie",
    tarifSecteur1Optam: "984.8",
    tarifHorsSecteur1HorsOptam: "893.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA012",
    designation:
      "Pneumonectomie, par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "874.91",
    tarifHorsSecteur1HorsOptam: "680.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA024",
    designation: "Pneumonectomie, par thoracotomie",
    tarifSecteur1Optam: "776.36",
    tarifHorsSecteur1HorsOptam: "607.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA011",
    designation:
      "Pneumonectomie avec résection-anastomose de la bifurcation trachéale, par thoracotomie",
    tarifSecteur1Optam: "1066.34",
    tarifHorsSecteur1HorsOptam: "885.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA001",
    designation:
      "Pneumonectomie avec exérèse totale de la plèvre [Pleuropneumonectomie], par thoracotomie",
    tarifSecteur1Optam: "985.51",
    tarifHorsSecteur1HorsOptam: "882.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA025",
    designation:
      "Pneumonectomie avec résection de la paroi thoracique, par thoracotomie",
    tarifSecteur1Optam: "962.11",
    tarifHorsSecteur1HorsOptam: "846.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA028",
    designation:
      "Pneumonectomie avec résection-anastomose de la veine cave supérieure ou résection de l'oreillette gauche, par thoracotomie",
    tarifSecteur1Optam: "1052.87",
    tarifHorsSecteur1HorsOptam: "965.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA002",
    designation:
      "Pneumonectomie avec résection et remplacement prothétique de la veine cave supérieure, par thoracotomie",
    tarifSecteur1Optam: "1153.54",
    tarifHorsSecteur1HorsOptam: "1000.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA007",
    designation:
      "Pneumonectomie avec résection d'organe et/ou de structure de voisinage, par thoracotomie",
    tarifSecteur1Optam: "1038.69",
    tarifHorsSecteur1HorsOptam: "924.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA020",
    designation:
      "Prélèvement de poumon au cours d'un prélèvement multiorgane, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA005",
    designation:
      "Transplantation de lobe pulmonaire, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA002",
    designation:
      "Transplantation de lobe pulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA003",
    designation: "Transplantation d'un poumon, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA007",
    designation: "Transplantation d'un poumon, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA004",
    designation:
      "Transplantation séquentielle des 2 poumons, par thoracotomie sans CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA001",
    designation:
      "Transplantation séquentielle des 2 poumons, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFEA006",
    designation: "Transplantation bipulmonaire, par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGJB002",
    designation:
      "Évacuation d'un épanchement de la cavité pleurale, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "47.88",
    tarifHorsSecteur1HorsOptam: "37.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGJB001",
    designation:
      "Drainage d'un épanchement de la cavité pleurale, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "64.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGLB006",
    designation:
      "Pose d'un drain thoracique pour lavage pleural, par voie transcutanée",
    tarifSecteur1Optam: "73.72",
    tarifHorsSecteur1HorsOptam: "58.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGJB005",
    designation:
      "Pose de plusieurs drains thoraciques pour irrigation-lavage pleurale, par voie transcutanée",
    tarifSecteur1Optam: "86.64",
    tarifHorsSecteur1HorsOptam: "66.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGJB007",
    designation: "Séance de lavage pleural, par un dispositif implanté",
    tarifSecteur1Optam: "34.2",
    tarifHorsSecteur1HorsOptam: "29.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGJB006",
    designation: "Séance d'irrigation-lavage pleurale",
    tarifSecteur1Optam: "40.28",
    tarifHorsSecteur1HorsOptam: "33.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGJA002",
    designation:
      "Évacuation de collection de la cavité pleurale, par thoracotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGJA004",
    designation:
      "Évacuation de collection de la cavité pleurale avec résection costale, par thoracotomie",
    tarifSecteur1Optam: "221.21",
    tarifHorsSecteur1HorsOptam: "216.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGJC001",
    designation:
      "Évacuation de collection septique de la cavité pleurale avec débridement, par thoracoscopie",
    tarifSecteur1Optam: "254.53",
    tarifHorsSecteur1HorsOptam: "235.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGJA001",
    designation:
      "Évacuation de collection septique de la cavité pleurale avec débridement, par thoracotomie",
    tarifSecteur1Optam: "354.5",
    tarifHorsSecteur1HorsOptam: "293.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGJC002",
    designation: "Évacuation d'un hémothorax, par thoracoscopie",
    tarifSecteur1Optam: "254.53",
    tarifHorsSecteur1HorsOptam: "235.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGJA003",
    designation: "Décaillotage de la cavité pleurale, par thoracotomie",
    tarifSecteur1Optam: "294.94",
    tarifHorsSecteur1HorsOptam: "258.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBSA001",
    designation:
      "Hémostase secondaire à un acte intrathoracique, par thoracotomie",
    tarifSecteur1Optam: "447.38",
    tarifHorsSecteur1HorsOptam: "347.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGLA001",
    designation:
      "Pose d'un cathéter intrapleural par voie transcutanée, avec pose d'un système diffuseur ou d'une pompe implantable souscutané",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "126.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGLB002",
    designation:
      "Injection intrapleurale d'agent pharmacologique, par voie transcutanée",
    tarifSecteur1Optam: "41.04",
    tarifHorsSecteur1HorsOptam: "33.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGLB001",
    designation:
      "Séance d'injection intrapleurale d'agent pharmacologique anticancéreux, par voie transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGLB008",
    designation:
      "Séance d'administration intrapleurale d'agent pharmacologique anticancéreux, par un dispositif implanté",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGLB009",
    designation:
      "Administration intrapleurale d'agent pharmacologique au long cours",
    tarifSecteur1Optam: "128.44",
    tarifHorsSecteur1HorsOptam: "92.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGLC001",
    designation:
      "Instillation intrapleurale de substance irritante, par thoracoscopie",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "135.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGPA001",
    designation: "Décortication pleuropulmonaire, par thoracotomie",
    tarifSecteur1Optam: "738.07",
    tarifHorsSecteur1HorsOptam: "585.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGPA002",
    designation: "Libération du poumon [Pneumolyse] pour symphyse pleurale",
    tarifSecteur1Optam: "453.76",
    tarifHorsSecteur1HorsOptam: "350.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGNC001",
    designation:
      "Abrasion ou exérèse de la plèvre pariétale, par thoracoscopie",
    tarifSecteur1Optam: "289.27",
    tarifHorsSecteur1HorsOptam: "284.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGNA001",
    designation: "Abrasion ou exérèse de la plèvre pariétale, par thoracotomie",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "254.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGFA001",
    designation:
      "Exérèse de tumeur de la plèvre, par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "411.22",
    tarifHorsSecteur1HorsOptam: "355.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGFA003",
    designation: "Exérèse de tumeur de la plèvre, par thoracotomie",
    tarifSecteur1Optam: "436.04",
    tarifHorsSecteur1HorsOptam: "353.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GGCA001",
    designation:
      "Pleurostomie cutanée avec résection costale, par thoracotomie [Thoracostomie en gueule de four]",
    tarifSecteur1Optam: "393.5",
    tarifHorsSecteur1HorsOptam: "316",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFC004",
    designation: "Exérèse de kyste bronchogénique, par thoracoscopie",
    tarifSecteur1Optam: "440.29",
    tarifHorsSecteur1HorsOptam: "403.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA003",
    designation: "Exérèse de kyste bronchogénique, par thoracotomie",
    tarifSecteur1Optam: "421.15",
    tarifHorsSecteur1HorsOptam: "375.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFC003",
    designation: "Exérèse de séquestration extralobaire, par thoracoscopie",
    tarifSecteur1Optam: "392.06",
    tarifHorsSecteur1HorsOptam: "392.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFFA035",
    designation: "Exérèse de séquestration extralobaire, par thoracotomie",
    tarifSecteur1Optam: "375.93",
    tarifHorsSecteur1HorsOptam: "375.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBGC001",
    designation:
      "Ablation de corps étranger intrathoracique, par thoracoscopie",
    tarifSecteur1Optam: "231.84",
    tarifHorsSecteur1HorsOptam: "222.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZBGA001",
    designation: "Ablation de corps étranger intrathoracique, par thoracotomie",
    tarifSecteur1Optam: "346.7",
    tarifHorsSecteur1HorsOptam: "288.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFNH174",
    designation:
      "Destruction d'1 tumeur bronchopulmonaire par radiofréquence, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFNH214",
    designation:
      "Destruction de 2 tumeurs bronchopulmonaires ou plus par radiofréquence, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "307.2",
    tarifHorsSecteur1HorsOptam: "307.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GFCC001",
    designation: "Suture de plaie pleuropulmonaire, par thoracoscopie",
    tarifSecteur1Optam: "324.01",
    tarifHorsSecteur1HorsOptam: "275.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GFCA001",
    designation: "Suture de plaie pleuropulmonaire, par thoracotomie",
    tarifSecteur1Optam: "445.25",
    tarifHorsSecteur1HorsOptam: "346.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHJA001",
    designation: "Évacuation de collection du médiastin, par cervicotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHJA002",
    designation: "Évacuation de collection du médiastin, par thoracotomie",
    tarifSecteur1Optam: "432.49",
    tarifHorsSecteur1HorsOptam: "382.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHFA001",
    designation: "Exérèse de tumeur du médiastin, par cervicotomie",
    tarifSecteur1Optam: "474.32",
    tarifHorsSecteur1HorsOptam: "406.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHFA002",
    designation:
      "Exérèse de tumeur du médiastin, par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "497.72",
    tarifHorsSecteur1HorsOptam: "436.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHFA004",
    designation: "Exérèse de tumeur du médiastin, par thoracotomie",
    tarifSecteur1Optam: "662.92",
    tarifHorsSecteur1HorsOptam: "516.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GHFA003",
    designation:
      "Exérèse de tumeur du médiastin avec résection d'organe et/ou de structure de voisinage, par thoracotomie",
    tarifSecteur1Optam: "984.09",
    tarifHorsSecteur1HorsOptam: "834.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GLQP001",
    designation:
      "Réglage du débit d'oxygène par surveillance transcutanée de la saturation en oxygène, pour instauration ou adaptation d'une oxygénothérapie de débit défini chez l'enfant, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQF001",
    designation:
      "Réglage du débit d'oxygène par mesures répétées des gaz du sang, pour instauration ou adaptation d'une oxygénothérapie de débit défini, par 24 heures",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLMF001",
    designation:
      "Adaptation des réglages d'une ventilation non effractive par mesures répétées des gaz du sang, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLMP001",
    designation:
      "Adaptation d'une pression positive continue [PPC] ventilatoire par système de pression autoadaptable avec enregistrement de la pression au masque, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD019",
    designation:
      "Ventilation barométrique ou volumétrique non effractive au masque facial pendant au moins 2 heures cumulées au cours des 12 heures, pour insuffisance respiratoire aigüe",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD003",
    designation:
      "Ventilation spontanée au masque facial, par canule nasale ou par sonde nasopharyngée, sans aide inspiratoire, avec pression expiratoire positive [VS-PEP] [Continuous positive airway pressure] [CPAP], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD006",
    designation:
      "Ventilation spontanée sur sonde d'intubation trachéale au cours du sevrage d'une ventilation mécanique, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD013",
    designation:
      "Ventilation spontanée sur trachéotomie au cours du sevrage d'une ventilation mécanique, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD002",
    designation:
      "Ventilation mécanique discontinue au masque facial ou par embout buccal pour kinésithérapie, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD012",
    designation:
      "Ventilation mécanique continue au masque facial pour suppléance ventilatoire, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD015",
    designation:
      "Ventilation mécanique intratrachéale avec pression expiratoire positive [PEP] inférieure ou égale à 6 et FiO2 inférieure ou égale à 60%, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD008",
    designation:
      "Ventilation mécanique intratrachéale avec pression expiratoire positive [PEP] supérieure à 6 et/ou FiO2 supérieure à 60%, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD004",
    designation:
      "Ventilation mécanique intratrachéale avec pression expiratoire positive [PEP] supérieure à 6 et/ou FiO2 supérieure à 60%, avec technique de décubitus ventral alterné par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD007",
    designation: "Ventilation mécanique à poumons séparés, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD009",
    designation:
      "Ventilation par oscillations à haute fréquence, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLJF010",
    designation:
      "Épuration extracorporelle du dioxyde de carbone [CO2], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD017",
    designation:
      "Oxygénothérapie avec surveillance continue de l'oxymétrie, en dehors de la ventilation mécanique, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLP006",
    designation:
      "Oxygénothérapie hyperbare avec inhalation d’oxygène pur d'une durée minimale de 60 minutes à une pression de 200 à 280 kilopascals [kPa] chez un patient en ventilation spontanée, pour affection en dehors de la phase aigüe",
    tarifSecteur1Optam: "36.7",
    tarifHorsSecteur1HorsOptam: "33.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLP007",
    designation:
      "Oxygénothérapie hyperbare avec inhalation d’oxygène pur d'une durée minimale de 60 minutes à une pression de 200 à 280 kilopascals [kPa] chez un patient en ventilation spontanée, pour affection en phase aigüe",
    tarifSecteur1Optam: "61.16",
    tarifHorsSecteur1HorsOptam: "55.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD021",
    designation:
      "Oxygénothérapie hyperbare avec inhalation d’oxygène pur d'une durée minimale de 60 minutes à une pression de 200 à 280 kilopascals [kPa], chez un patient en ventilation mécanique",
    tarifSecteur1Optam: "122.32",
    tarifHorsSecteur1HorsOptam: "110.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLBP002",
    designation:
      "Compression thérapeutique avec inhalation de mélange suroxygéné ou d’oxygène pur à une pression initiale égale ou supérieure à 280 kilopascals [kPa], d’une durée de 1 à 3 heures ",
    tarifSecteur1Optam: "141.89",
    tarifHorsSecteur1HorsOptam: "127.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLBP001",
    designation:
      "Compression thérapeutique avec inhalation de mélange suroxygéné ou d’oxygène pur à une pression initiale égale ou supérieure à 280 kilopascals [kPa], d’une durée de 3 à 7 heures ",
    tarifSecteur1Optam: "259.32",
    tarifHorsSecteur1HorsOptam: "233.39",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLBP003",
    designation:
      "Compression thérapeutique avec inhalation de mélange suroxygéné ou d’oxygène pur à une pression initiale égale ou supérieure à 280 kilopascals [kPa], d’une durée de 7 heures ou plus ",
    tarifSecteur1Optam: "293.57",
    tarifHorsSecteur1HorsOptam: "264.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLRP002",
    designation:
      "Séance de réentraînement à l'exercice d'un insuffisant respiratoire chronique, sur machine",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLRP001",
    designation:
      "Séance de réentraînement à l'exercice d'un enfant asthmatique, sur machine",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLP003",
    designation: "Pose d'un masque facial de ventilation nasale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLKP001",
    designation:
      "Changement du revêtement interne d'un masque facial de ventilation nasale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GKLD001",
    designation: "Pose d'une prothèse phonatoire vélopalatine",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQD004",
    designation: "Électromyographie du côlon sigmoïde",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQD003",
    designation: "Manométrie œsophagienne",
    tarifSecteur1Optam: "79.84",
    tarifHorsSecteur1HorsOptam: "79.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQD001",
    designation: "Manométrie œsophagienne sur 24 heures, avec mesure du pH",
    tarifSecteur1Optam: "91.75",
    tarifHorsSecteur1HorsOptam: "91.75",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFQD001",
    designation:
      "Manométrie gastro-duodéno-jéjunale avec enregistrement de l'activité antro-pyloro-duodénale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQD001",
    designation: "Manométrie duodénojéjunale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQD901",
    designation: "Manométrie pancolique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQD001",
    designation: "Manométrie du côlon sigmoïde",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HTQD002",
    designation: "Manométrie anorectale",
    tarifSecteur1Optam: "71.69",
    tarifHorsSecteur1HorsOptam: "71.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCQM001",
    designation: "Échographie transcutanée des glandes salivaires",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "34.97",
    regroupement: "ADE",
  },
  {
    codeCCAM: "HLQM001",
    designation: "Échographie transcutanée du foie et des conduits biliaires",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM006",
    designation: "Échographie transcutanée de l'étage supérieur de l'abdomen",
    tarifSecteur1Optam: "54.02",
    tarifHorsSecteur1HorsOptam: "54.02",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM004",
    designation:
      "Échographie transcutanée de l'étage supérieur de l'abdomen avec échographie-doppler des vaisseaux digestifs",
    tarifSecteur1Optam: "54.02",
    tarifHorsSecteur1HorsOptam: "54.02",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM010",
    designation:
      "Échographie transcutanée de l'étage supérieur de l'abdomen et du petit bassin [pelvis]",
    tarifSecteur1Optam: "74.1",
    tarifHorsSecteur1HorsOptam: "74.1",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM011",
    designation:
      "Échographie transcutanée de l'étage supérieur de l'abdomen et du petit bassin [pelvis] avec échographie-doppler des vaisseaux digestifs",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "HZQM001",
    designation: "Échographie transcutanée du tube digestif et/ou du péritoine",
    tarifSecteur1Optam: "56.7",
    tarifHorsSecteur1HorsOptam: "56.7",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM008",
    designation: "Échographie transcutanée de l'abdomen",
    tarifSecteur1Optam: "54.02",
    tarifHorsSecteur1HorsOptam: "54.02",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM001",
    designation:
      "Échographie transcutanée de l'abdomen, avec échographie-doppler des vaisseaux digestifs",
    tarifSecteur1Optam: "54.02",
    tarifHorsSecteur1HorsOptam: "54.02",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM005",
    designation:
      "Échographie transcutanée de l'abdomen, avec échographie transcutanée du petit bassin [pelvis]",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM002",
    designation:
      "Échographie transcutanée de l'abdomen, avec échographie transcutanée du petit bassin [pelvis] et échographie-doppler des vaisseaux digestifs",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "HJQJ003",
    designation:
      "Échographie du rectum et de l'anus, par voie rectale et/ou vaginale [par voie cavitaire]",
    tarifSecteur1Optam: "56.7",
    tarifHorsSecteur1HorsOptam: "56.7",
    regroupement: "ADE",
  },
  {
    codeCCAM: "HBQK389",
    designation:
      "Radiographie intrabuccale rétroalvéolaire et/ou rétrocoronaire d'un secteur de 1 à 3 dents contigües",
    tarifSecteur1Optam: "7.98",
    tarifHorsSecteur1HorsOptam: "7.98",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK191",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 2 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "15.96",
    tarifHorsSecteur1HorsOptam: "15.96",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK331",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 3 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "23.94",
    tarifHorsSecteur1HorsOptam: "23.94",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK443",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 4 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "31.92",
    tarifHorsSecteur1HorsOptam: "31.92",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK428",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 5 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK480",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 6 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "47.88",
    tarifHorsSecteur1HorsOptam: "47.88",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK430",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 7 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "55.86",
    tarifHorsSecteur1HorsOptam: "55.86",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK142",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 8 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "63.84",
    tarifHorsSecteur1HorsOptam: "63.84",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK046",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 9 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "71.82",
    tarifHorsSecteur1HorsOptam: "71.82",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK065",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 10 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "79.8",
    tarifHorsSecteur1HorsOptam: "79.8",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK424",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 11 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "87.78",
    tarifHorsSecteur1HorsOptam: "87.78",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK476",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 12 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "95.76",
    tarifHorsSecteur1HorsOptam: "95.76",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK093",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 13 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "103.74",
    tarifHorsSecteur1HorsOptam: "103.74",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK041",
    designation:
      "Radiographies intrabuccales rétroalvéolaires et/ou rétrocoronaires de 14 secteurs distincts de 1 à 3 dents contigües",
    tarifSecteur1Optam: "111.72",
    tarifHorsSecteur1HorsOptam: "111.72",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK002",
    designation: "Radiographie panoramique dentomaxillaire",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK001",
    designation: "Radiographie pelvibuccale [occlusale]",
    tarifSecteur1Optam: "7.98",
    tarifHorsSecteur1HorsOptam: "7.98",
    regroupement: "AID",
  },
  {
    codeCCAM: "HCQH001",
    designation: "Sialographie",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HCQH002",
    designation: "Sialographie avec scanographie des glandes salivaires",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQK002",
    designation: "Radiographie de l'abdomen sans préparation",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HPMP002",
    designation:
      "Contrôle radiologique secondaire de position et/ou de fonctionnement d'un drain péritonéal, d'un cathéter de dialyse péritonéale ou d'une dérivation péritonéojugulaire, avec opacification par produit de contraste",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HFMP002",
    designation:
      "Contrôle radiologique secondaire de position et/ou de fonctionnement d'un anneau ajustable périgastrique, avec opacification par produit de contraste",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HZMP002",
    designation:
      "Contrôle radiologique secondaire de position et/ou de fonctionnement d'une sonde digestive, d'un drain biliaire ou d'une endoprothèse biliaire avec opacification par produit de contraste",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HQQH002",
    designation:
      "Étude radiologique dynamique de la déglutition, avec enregistrement [Pharyngographie dynamique]",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HEQH001",
    designation:
      "Radiographie de l'œsophage avec opacification par produit de contraste [Transit œsophagien]",
    tarifSecteur1Optam: "53.2",
    tarifHorsSecteur1HorsOptam: "53.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HEQH002",
    designation:
      "Radiographie œso-gastro-duodénale avec opacification par produit de contraste [Transit œso-gastro-duodénal]",
    tarifSecteur1Optam: "104.57",
    tarifHorsSecteur1HorsOptam: "104.57",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HGQH002",
    designation:
      "Radiographie de l'intestin grêle avec ingestion de produit de contraste [Transit du grêle]",
    tarifSecteur1Optam: "119.7",
    tarifHorsSecteur1HorsOptam: "119.7",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HGQH001",
    designation:
      "Radiographie de l'intestin grêle avec administration de produit de contraste par une sonde nasoduodénale [entéroclyse]",
    tarifSecteur1Optam: "119.7",
    tarifHorsSecteur1HorsOptam: "119.7",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HHQH001",
    designation:
      "Radiographie du côlon avec opacification par produit de contraste",
    tarifSecteur1Optam: "119.7",
    tarifHorsSecteur1HorsOptam: "119.7",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HTQH002",
    designation: "Défécographie [Rectographie dynamique]",
    tarifSecteur1Optam: "86.45",
    tarifHorsSecteur1HorsOptam: "86.45",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQH006",
    designation:
      "Cholangiographie, par injection de produit de contraste dans un drain biliaire externe",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQH004",
    designation:
      "Cholangiographie, par injection de produit de contraste dans les conduits biliaires par voie transcutanée, avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQH007",
    designation: "Cholangiographie rétrograde, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "200.89",
    tarifHorsSecteur1HorsOptam: "200.89",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQH003",
    designation:
      "Cholangiographie rétrograde avec infundibulotomie [ponction diathermique de l'infundibulum biliaire] ou précoupe de la papille duodénale majeure, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "313.75",
    tarifHorsSecteur1HorsOptam: "313.75",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HNQH004",
    designation:
      "Pancréatographie, par injection de produit de contraste par voie transcutanée, avec guidage échographique",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HNQH003",
    designation:
      "Pancréatographie rétrograde par cathétérisme de la papille duodénale majeure, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HNQH001",
    designation:
      "Pancréatographie rétrograde par cathétérisme de la papille duodénale mineure [papille accessoire], par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "236.63",
    tarifHorsSecteur1HorsOptam: "236.63",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQH005",
    designation:
      "Cholangiopancréatographie rétrograde sans manométrie oddienne, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "207.7",
    tarifHorsSecteur1HorsOptam: "207.7",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQH002",
    designation:
      "Cholangiopancréatographie rétrograde avec manométrie oddienne, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "258.19",
    tarifHorsSecteur1HorsOptam: "258.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQK005",
    designation:
      "Scanographie de l'abdomen ou du petit bassin [pelvis], sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQH002",
    designation:
      "Scanographie de l'abdomen ou du petit bassin [pelvis], avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQK004",
    designation:
      "Scanographie de l'abdomen et du petit bassin [pelvis], sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQH001",
    designation:
      "Scanographie de l'abdomen et du petit bassin [pelvis], avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "50.54",
    tarifHorsSecteur1HorsOptam: "50.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HHQK484",
    designation:
      "Scanographie du côlon avec insufflation [coloscopie virtuelle], sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "52.8",
    tarifHorsSecteur1HorsOptam: "52.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HHQH365",
    designation:
      "Scanographie du côlon avec insufflation [coloscopie virtuelle], et injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "72",
    tarifHorsSecteur1HorsOptam: "72",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQN002",
    designation:
      "Remnographie [IRM] de l'abdomen ou du petit bassin [pelvis], sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQJ004",
    designation:
      "Remnographie [IRM] de l'abdomen ou du petit bassin [pelvis], avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQN001",
    designation:
      "Remnographie [IRM] de l'abdomen et du petit bassin [pelvis], sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQJ005",
    designation:
      "Remnographie [IRM] de l'abdomen et du petit bassin [pelvis], avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HCQL001",
    designation: "Scintigraphie des glandes salivaires",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HEQL002",
    designation:
      "Scintigraphie du transit œsophagien par substance solide ou liquide",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HEQL003",
    designation:
      "Scintigraphie du transit œsophagien par substances solide et liquide",
    tarifSecteur1Optam: "397.92",
    tarifHorsSecteur1HorsOptam: "397.92",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HEQL001",
    designation: "Recherche radio-isotopique d'un reflux gastroœsophagien",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HFQL002",
    designation:
      "Scintigraphie du transit gastrique ou duodénal par substance solide ou liquide sans épreuve pharmacologique",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HFQL003",
    designation:
      "Scintigraphie du transit gastrique ou duodénal par substance solide ou liquide avec épreuve pharmacologique",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HFQL004",
    designation:
      "Scintigraphie du transit gastrique ou duodénal par substances solide et liquide sans épreuve pharmacologique",
    tarifSecteur1Optam: "397.92",
    tarifHorsSecteur1HorsOptam: "397.92",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HFQL001",
    designation:
      "Scintigraphie du transit gastrique ou duodénal par substances solide et liquide avec épreuve pharmacologique",
    tarifSecteur1Optam: "397.92",
    tarifHorsSecteur1HorsOptam: "397.92",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HGQL001",
    designation: "Recherche radio-isotopique d'un diverticule de Meckel",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HLQL001",
    designation:
      "Scintigraphie du foie et de la rate par un traceur du système réticuloendothélial",
    tarifSecteur1Optam: "162.76",
    tarifHorsSecteur1HorsOptam: "162.76",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HMQL001",
    designation: "Scintigraphie des conduits biliaires",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HPQL001",
    designation: "Scintigraphie péritonéale",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HPML001",
    designation: "Contrôle radio-isotopique d'un cathéter intrapéritonéal",
    tarifSecteur1Optam: "106.11",
    tarifHorsSecteur1HorsOptam: "106.11",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL006",
    designation: "Recherche radio-isotopique de sang dans les selles",
    tarifSecteur1Optam: "53.06",
    tarifHorsSecteur1HorsOptam: "53.06",
    regroupement: "ADI",
  },
  {
    codeCCAM: "FEQL003",
    designation:
      "Recherche topographique d'une déperdition sanguine digestive, par méthode radio-isotopique",
    tarifSecteur1Optam: "270.66",
    tarifHorsSecteur1HorsOptam: "270.66",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KGRL001",
    designation:
      "Test radio-isotopique d'absorption digestive de la vitamine B12",
    tarifSecteur1Optam: "53.06",
    tarifHorsSecteur1HorsOptam: "53.06",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HDQP002",
    designation:
      "Exploration du flux aérien bucco-naso-pharyngé par débitmétrie, pour étude de la fonction vélopalatine",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBMP003",
    designation: "Réalisation de moulage d'étude des arcades dentaires",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBQP001",
    designation:
      "Enregistrement des rapports maxillomandibulaires en vue de la programmation d'un articulateur",
    tarifSecteur1Optam: "32.64",
    tarifHorsSecteur1HorsOptam: "32.64",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBMP001",
    designation:
      "Simulation des objectifs thérapeutiques sur moulages des arcades dentaires et/ou sur logiciel",
    tarifSecteur1Optam: "97.92",
    tarifHorsSecteur1HorsOptam: "97.92",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBMP002",
    designation: "Montage directeur sur moulage d'étude des arcades dentaires",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBMD014",
    designation:
      "Modélisation occlusale par la technique de la cire ajoutée sur une dent",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBQK002",
    designation:
      "Bilan de dysmorphose dento-maxillo-faciale avec tracé et analyse céphalométrique de profil",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBQK004",
    designation:
      "Bilan de dysmorphose dento-maxillo-faciale avec tracé et analyse céphalométrique tridimensionnelle, ou tracé et analyse architecturale craniofaciale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBQK003",
    designation:
      "Bilan de dysmorphose dento-maxillo-faciale avec tracé et analyse céphalométrique tridimensionnelle ou analyse architecturale craniofaciale, et simulation des objectifs de traitement sur moulage et/ou sur tracé céphalométrique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HQQE001",
    designation:
      "Bilan fonctionnel de la déglutition avec fibroscopie du pharynx et du larynx par voie nasale, essai alimentaire et enregistrement vidéo",
    tarifSecteur1Optam: "52.36",
    tarifHorsSecteur1HorsOptam: "52.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HQQP001",
    designation: "Analyse informatisée de la déglutition",
    tarifSecteur1Optam: "59.24",
    tarifHorsSecteur1HorsOptam: "59.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HERD001",
    designation:
      "Test de provocation douloureuse pour recherche d'une affection de l'œsophage",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQD002",
    designation: "pH-métrie œsophagienne et/ou gastrique sur 24 heures",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFHD001",
    designation:
      "Prélèvement de liquide gastrique par tubage, sans épreuve pharmacologique",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFHD002",
    designation:
      "Prélèvement de liquide gastrique par tubage, avec épreuve pharmacologique",
    tarifSecteur1Optam: "36.19",
    tarifHorsSecteur1HorsOptam: "29.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGHD001",
    designation:
      "Prélèvement de liquide duodénal par tubage, sans épreuve pharmacologique",
    tarifSecteur1Optam: "35.86",
    tarifHorsSecteur1HorsOptam: "35.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGHD003",
    designation:
      "Prélèvement de liquide duodénal par tubage, avec épreuve pharmacologique",
    tarifSecteur1Optam: "35.86",
    tarifHorsSecteur1HorsOptam: "35.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KGQP001",
    designation:
      "Mesure de la production respiratoire d'hydrogène [Breath test]",
    tarifSecteur1Optam: "22.24",
    tarifHorsSecteur1HorsOptam: "22.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQL008",
    designation: "Mesure de la production respiratoire d'isotope stable",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HRQP001",
    designation:
      "Surveillance tonométrique continue du pH intramuqueux gastrique ou colique et/ou du gradient gastroartériel ou coloartériel de la PCO2, par 24 heures",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HTRD003",
    designation: "Test de continence anorectale aux liquides",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCQE427",
    designation: "Sialendoscopie diagnostique",
    tarifSecteur1Optam: "61.6",
    tarifHorsSecteur1HorsOptam: "61.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HDQE001",
    designation: "Orohypopharyngoscopie avec laryngoscopie directe",
    tarifSecteur1Optam: "82.39",
    tarifHorsSecteur1HorsOptam: "71.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQE263",
    designation:
      "Endoscopie œsophagienne avec biopsie guidée par endomicroscopie confocale par laser [ECL]",
    tarifSecteur1Optam: "150",
    tarifHorsSecteur1HorsOptam: "150",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQE001",
    designation: "Œsophagoscopie au tube rigide",
    tarifSecteur1Optam: "70.21",
    tarifHorsSecteur1HorsOptam: "70.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQE002",
    designation: "Endoscopie œso-gastro-duodénale",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQE003",
    designation:
      "Endoscopie œso-gastro-duodénale avec test à l'uréase, avant l'âge de 6 ans",
    tarifSecteur1Optam: "100.45",
    tarifHorsSecteur1HorsOptam: "100.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQE005",
    designation:
      "Endoscopie œso-gastro-duodénale avec test à l'uréase, après l'âge de 6 ans",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQE002",
    designation: "Duodénoscopie par appareil à vision latérale",
    tarifSecteur1Optam: "99.88",
    tarifHorsSecteur1HorsOptam: "99.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQE003",
    designation: "Entéroscopie jéjunale [Jéjunoscopie]",
    tarifSecteur1Optam: "148.1",
    tarifHorsSecteur1HorsOptam: "148.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQE005",
    designation: "Entéroscopie iléale [Iléoscopie]",
    tarifSecteur1Optam: "144.12",
    tarifHorsSecteur1HorsOptam: "144.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQE001",
    designation: "Entéroscopie jéjunale avec entéroscopie iléale",
    tarifSecteur1Optam: "246.8",
    tarifHorsSecteur1HorsOptam: "246.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQE004",
    designation: "Coloscopie partielle au delà du côlon sigmoïde",
    tarifSecteur1Optam: "73.54",
    tarifHorsSecteur1HorsOptam: "73.54",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQE005",
    designation:
      "Coloscopie totale avec visualisation du bas-fond cæcal, sans franchissement de l'orifice iléocolique",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQE002",
    designation:
      "Coloscopie totale, avec franchissement de l'orifice iléocolique",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQE003",
    designation:
      "Exploration complète du côlon après colectomie droite, par endoscopie",
    tarifSecteur1Optam: "93.96",
    tarifHorsSecteur1HorsOptam: "93.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJQE001",
    designation: "Rectosigmoïdoscopie",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJQE002",
    designation: "Rectoscopie au tube rigide",
    tarifSecteur1Optam: "21.68",
    tarifHorsSecteur1HorsOptam: "21.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HKQE001",
    designation: "Anuscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JZQE001",
    designation: "Exploration endoscopique d'une malformation cloacale",
    tarifSecteur1Optam: "179.95",
    tarifHorsSecteur1HorsOptam: "168.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQD002",
    designation:
      "Exploration de la lumière de l'intestin grêle par vidéocapsule ingérée",
    tarifSecteur1Optam: "112.47",
    tarifHorsSecteur1HorsOptam: "112.47",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQD445",
    designation:
      "Exploration de la lumière du colon par vidéocapsule ingérée [capsule colique]",
    tarifSecteur1Optam: "107.58",
    tarifHorsSecteur1HorsOptam: "107.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQH001",
    designation: "Cholangioscopie, par voie buccale",
    tarifSecteur1Optam: "321.71",
    tarifHorsSecteur1HorsOptam: "321.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQG002",
    designation:
      "Cholangioscopie, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "261.13",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQE001",
    designation:
      "Cholangioscopie par un orifice de drainage biliaire externe déjà en place",
    tarifSecteur1Optam: "133.01",
    tarifHorsSecteur1HorsOptam: "101.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNQH002",
    designation: "Pancréaticoscopie par voie buccale",
    tarifSecteur1Optam: "410.18",
    tarifHorsSecteur1HorsOptam: "410.18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQJ001",
    designation: "Échoendoscopie œsogastrique sans biopsie",
    tarifSecteur1Optam: "160.67",
    tarifHorsSecteur1HorsOptam: "160.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQJ002",
    designation:
      "Échoendoscopie œsogastrique avec biopsie transœsogastrique guidée",
    tarifSecteur1Optam: "193.57",
    tarifHorsSecteur1HorsOptam: "193.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQJ002",
    designation: "Échoendoscopie duodénale sans biopsie",
    tarifSecteur1Optam: "185.62",
    tarifHorsSecteur1HorsOptam: "185.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQJ001",
    designation: "Échoendoscopie duodénale avec biopsie transduodénale guidée",
    tarifSecteur1Optam: "193.57",
    tarifHorsSecteur1HorsOptam: "193.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQJ002",
    designation: "Échoendoscopie colique sans biopsie",
    tarifSecteur1Optam: "188.08",
    tarifHorsSecteur1HorsOptam: "188.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJQJ002",
    designation: "Échoendoscopie anorectale sans biopsie",
    tarifSecteur1Optam: "128.64",
    tarifHorsSecteur1HorsOptam: "128.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJQJ001",
    designation:
      "Échoendoscopie anorectale avec biopsie transanorectale guidée",
    tarifSecteur1Optam: "137.41",
    tarifHorsSecteur1HorsOptam: "137.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQJ001",
    designation: "Échoendoscopie biliopancréatique sans biopsie",
    tarifSecteur1Optam: "245.94",
    tarifHorsSecteur1HorsOptam: "245.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQJ002",
    designation:
      "Échoendoscopie biliopancréatique avec biopsie transbiliopancréatique guidée",
    tarifSecteur1Optam: "274.87",
    tarifHorsSecteur1HorsOptam: "274.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHJ004",
    designation:
      "Ponction de collection hépatique, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHH002",
    designation:
      "Ponction de collection hépatique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHB001",
    designation: "Biopsie du foie, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "61.61",
    tarifHorsSecteur1HorsOptam: "43.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHJ003",
    designation:
      "Biopsie non ciblée du foie, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHJ006",
    designation:
      "Biopsie du foie sur une cible, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "102.94",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHH006",
    designation:
      "Biopsie du foie sur une cible, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHJ005",
    designation:
      "Biopsie du foie sur plusieurs cibles, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "127.16",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHH007",
    designation:
      "Biopsie du foie sur plusieurs cibles, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "142.9",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHH001",
    designation:
      "Biopsie du foie par voie jugulaire avec guidage échographique et/ou radiologique, sans prise de pression intraveineuse",
    tarifSecteur1Optam: "196.18",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLHH005",
    designation:
      "Biopsie du foie par voie jugulaire avec guidage échographique et/ou radiologique, avec prise de pression intraveineuse",
    tarifSecteur1Optam: "214.35",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCHB001",
    designation:
      "Ponction ou cytoponction de glande salivaire, par voie transcutanée",
    tarifSecteur1Optam: "41.58",
    tarifHorsSecteur1HorsOptam: "28.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAHD001",
    designation: "Frottis et/ou prélèvement intrabuccal",
    tarifSecteur1Optam: "10.64",
    tarifHorsSecteur1HorsOptam: "10.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAHA002",
    designation: "Biopsie de lèvre",
    tarifSecteur1Optam: "29.19",
    tarifHorsSecteur1HorsOptam: "20.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAHD003",
    designation:
      "Biopsie de la cavité orale et/ou de l'oropharynx, sans coloration vitale",
    tarifSecteur1Optam: "26.95",
    tarifHorsSecteur1HorsOptam: "19.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAHD002",
    designation:
      "Biopsie de la cavité orale et/ou de l'oropharynx, avec guidage par des colorations vitales",
    tarifSecteur1Optam: "33.11",
    tarifHorsSecteur1HorsOptam: "23.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCHA001",
    designation: "Biopsie de glande salivaire majeure [principale]",
    tarifSecteur1Optam: "43.89",
    tarifHorsSecteur1HorsOptam: "29.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCHA002",
    designation:
      "Biopsie des petites glandes salivaires [glandes salivaires mineures] [glandes salivaires accessoires]",
    tarifSecteur1Optam: "33.88",
    tarifHorsSecteur1HorsOptam: "23.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGHD002",
    designation: "Biopsie entérale à l'aveugle à la sonde, par voie nasale",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJHD002",
    designation: "Biopsie de la musculeuse du rectum, par voie anale",
    tarifSecteur1Optam: "77.26",
    tarifHorsSecteur1HorsOptam: "60.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJHD001",
    designation: "Biopsie rectale par aspiration de la muqueuse par sonde",
    tarifSecteur1Optam: "24.45",
    tarifHorsSecteur1HorsOptam: "22.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HKHA001",
    designation: "Biopsie de lésion de la région périanale et/ou du canal anal",
    tarifSecteur1Optam: "39.12",
    tarifHorsSecteur1HorsOptam: "26.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPHB003",
    designation: "Ponction d'un épanchement péritonéal, par voie transcutanée",
    tarifSecteur1Optam: "28.36",
    tarifHorsSecteur1HorsOptam: "24.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPHB002",
    designation:
      "Ponction du cul-de-sac recto-utérin [de Douglas] [Culdocentèse], par voie transvaginale sans guidage",
    tarifSecteur1Optam: "29.06",
    tarifHorsSecteur1HorsOptam: "24.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPHJ001",
    designation:
      "Ponction du cul-de-sac recto-utérin [de Douglas] [Culdocentèse], par voie transvaginale avec guidage échographique",
    tarifSecteur1Optam: "21.45",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPHB001",
    designation:
      "Ponction-lavage du péritoine, avec pose de cathéter intrapéritonéal par voie transcutanée",
    tarifSecteur1Optam: "96.42",
    tarifHorsSecteur1HorsOptam: "96.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCQC002",
    designation:
      "Exploration de la cavité abdominale, par cœlioscopie [Cœlioscopie exploratrice]",
    tarifSecteur1Optam: "123.18",
    tarifHorsSecteur1HorsOptam: "95.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCQC001",
    designation:
      "Exploration de la cavité abdominale par cœlioscopie, avec hystéroscopie",
    tarifSecteur1Optam: "160.54",
    tarifHorsSecteur1HorsOptam: "131.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCQA001",
    designation:
      "Exploration de la cavité abdominale, par laparotomie [Laparotomie exploratrice]",
    tarifSecteur1Optam: "169.45",
    tarifHorsSecteur1HorsOptam: "142.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFPA003",
    designation: "Gastrotomie exploratrice, par laparotomie",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "179.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPA005",
    designation: "Duodénotomie exploratrice, par laparotomie",
    tarifSecteur1Optam: "244.61",
    tarifHorsSecteur1HorsOptam: "194.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPA003",
    designation: "Entérotomie exploratrice, par laparotomie",
    tarifSecteur1Optam: "230.43",
    tarifHorsSecteur1HorsOptam: "186.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHPC001",
    designation: "Colotomie exploratrice, par cœlioscopie",
    tarifSecteur1Optam: "275.09",
    tarifHorsSecteur1HorsOptam: "224.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHPA002",
    designation: "Colotomie exploratrice, par laparotomie",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "182.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLQX013",
    designation:
      "Examen histopathologique de biopsie de foie avec coloration spéciale pour diagnostic d'affection non carcinologique",
    tarifSecteur1Optam: "60",
    tarifHorsSecteur1HorsOptam: "60",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de lèvre, de commissure labiale et/ou de muqueuse buccale",
    tarifSecteur1Optam: "48.13",
    tarifHorsSecteur1HorsOptam: "48.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAQX017",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de glossectomie, de pelvectomie buccale, de pelviglossectomie ou d'oropharyngectomie sans mandibulectomie",
    tarifSecteur1Optam: "69.12",
    tarifHorsSecteur1HorsOptam: "69.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pelvimandibulectomie, de pelvi-glosso-mandibulectomie ou d'oropharyngectomie avec mandibulectomie",
    tarifSecteur1Optam: "89.57",
    tarifHorsSecteur1HorsOptam: "89.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de glande salivaire",
    tarifSecteur1Optam: "91.37",
    tarifHorsSecteur1HorsOptam: "91.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HDQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pharyngolaryngectomie totale",
    tarifSecteur1Optam: "122.97",
    tarifHorsSecteur1HorsOptam: "122.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse endoscopique de l'œsophage",
    tarifSecteur1Optam: "51.15",
    tarifHorsSecteur1HorsOptam: "51.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'œsophagectomie partielle ou totale",
    tarifSecteur1Optam: "106.37",
    tarifHorsSecteur1HorsOptam: "106.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'œsophago-pharyngo-laryngectomie ou d'œsophagogastrectomie ",
    tarifSecteur1Optam: "124.03",
    tarifHorsSecteur1HorsOptam: "124.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse endoscopique de l'estomac",
    tarifSecteur1Optam: "66.06",
    tarifHorsSecteur1HorsOptam: "66.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de gastrectomie partielle",
    tarifSecteur1Optam: "90.41",
    tarifHorsSecteur1HorsOptam: "90.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de gastrectomie totale ou de dégastrogastrectomie",
    tarifSecteur1Optam: "114.74",
    tarifHorsSecteur1HorsOptam: "114.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQX003",
    designation:
      "Examen anatomopathologique de pièce d'exérèse intestinale pour maladie inflammatoire chronique de l'intestin [MICI]",
    tarifSecteur1Optam: "56.2",
    tarifHorsSecteur1HorsOptam: "56.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse endoscopique de l'intestin grêle",
    tarifSecteur1Optam: "53.16",
    tarifHorsSecteur1HorsOptam: "53.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse segmentaire de l'intestin grêle",
    tarifSecteur1Optam: "81.71",
    tarifHorsSecteur1HorsOptam: "81.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'appendicectomie",
    tarifSecteur1Optam: "43.05",
    tarifHorsSecteur1HorsOptam: "43.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse endoscopique de 1 ou 2 lésions du côlon et/ou du rectum",
    tarifSecteur1Optam: "51.53",
    tarifHorsSecteur1HorsOptam: "51.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse endoscopique de 3 à 5 lésions du côlon et/ou du rectum",
    tarifSecteur1Optam: "75.29",
    tarifHorsSecteur1HorsOptam: "75.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse endoscopique de 6 lésions ou plus du côlon et/ou du rectum",
    tarifSecteur1Optam: "93.51",
    tarifHorsSecteur1HorsOptam: "93.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX006",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de colectomie partielle ou de rectosigmoidectomie sans résection du mésorectum",
    tarifSecteur1Optam: "102.93",
    tarifHorsSecteur1HorsOptam: "102.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX010",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de colectomie totale",
    tarifSecteur1Optam: "124.14",
    tarifHorsSecteur1HorsOptam: "124.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQX011",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de coloprotectomie totale",
    tarifSecteur1Optam: "129.69",
    tarifHorsSecteur1HorsOptam: "129.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de rectosigmoidectomie, ou d'exérèse partielle ou totale de rectum, emportant le mésorectum",
    tarifSecteur1Optam: "110.11",
    tarifHorsSecteur1HorsOptam: "110.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HKQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse du canal anal et/ou de la marge anale ",
    tarifSecteur1Optam: "56.7",
    tarifHorsSecteur1HorsOptam: "56.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'une hépatectomie partielle",
    tarifSecteur1Optam: "85.3",
    tarifHorsSecteur1HorsOptam: "85.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièces de plusieurs hépatectomies partielles ",
    tarifSecteur1Optam: "104.69",
    tarifHorsSecteur1HorsOptam: "104.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'hépatectomie totale",
    tarifSecteur1Optam: "138.74",
    tarifHorsSecteur1HorsOptam: "138.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de cholécystectomie ",
    tarifSecteur1Optam: "84.54",
    tarifHorsSecteur1HorsOptam: "84.54",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de conduit biliaire extrahépatique",
    tarifSecteur1Optam: "76.77",
    tarifHorsSecteur1HorsOptam: "76.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de la papille duodénale majeure [ampullectomie]",
    tarifSecteur1Optam: "68.98",
    tarifHorsSecteur1HorsOptam: "68.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pancréatectomie partielle, sans splénectomie",
    tarifSecteur1Optam: "105.98",
    tarifHorsSecteur1HorsOptam: "105.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pancréatectomie partielle avec splénectomie",
    tarifSecteur1Optam: "138.79",
    tarifHorsSecteur1HorsOptam: "138.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNQX006",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de duodénopancréatectomie partielle ou totale ",
    tarifSecteur1Optam: "172.24",
    tarifHorsSecteur1HorsOptam: "172.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de tumeur du grand omentun, du péritoine et/ou de repli péritonéal [méso]",
    tarifSecteur1Optam: "60.58",
    tarifHorsSecteur1HorsOptam: "60.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX484",
    designation:
      "Test de détection des mutations génétiques de tumeur maligne du côlon et/ou du rectum pour thérapie ciblée [Test compagnon]",
    tarifSecteur1Optam: "368.72",
    tarifHorsSecteur1HorsOptam: "368.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HBQD001",
    designation: "Bilan parodontal",
    tarifSecteur1Optam: "50",
    tarifHorsSecteur1HorsOptam: "50",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HLQM002",
    designation:
      "Mesure de l'élasticité du foie par élastographie impulsionnelle ultrasonore",
    tarifSecteur1Optam: "31.29",
    tarifHorsSecteur1HorsOptam: "31.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJQD001",
    designation: "Examen du rectum sous anesthésie générale, par voie anale",
    tarifSecteur1Optam: "24.45",
    tarifHorsSecteur1HorsOptam: "22.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAJA003",
    designation:
      "Parage et/ou suture de plaie cutanée non transfixiante de lèvre",
    tarifSecteur1Optam: "34.03",
    tarifHorsSecteur1HorsOptam: "26.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAJA006",
    designation: "Parage et/ou suture de plaie transfixiante de lèvre",
    tarifSecteur1Optam: "55.3",
    tarifHorsSecteur1HorsOptam: "38.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HANP002",
    designation:
      "Abrasion de la muqueuse des lèvres et de la peau péribuccale avec laser",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HANP001",
    designation: "Destruction de lésion du vermillon labial avec laser CO2",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAFA008",
    designation: "Exérèse non transfixiante de lésion de lèvre",
    tarifSecteur1Optam: "60.97",
    tarifHorsSecteur1HorsOptam: "47.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA020",
    designation:
      "Exérèse transfixiante de lésion de lèvre, ou exérèse d'une commissure labiale",
    tarifSecteur1Optam: "92.17",
    tarifHorsSecteur1HorsOptam: "75.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA031",
    designation:
      "Exérèse d'une lèvre débordant sur une ou plusieurs unités adjacentes",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA014",
    designation:
      "Vermillonectomie avec reconstruction de lèvre par lambeau muqueux rétrolabial",
    tarifSecteur1Optam: "136.13",
    tarifHorsSecteur1HorsOptam: "131.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA028",
    designation: "Résection de cicatrice de lèvre",
    tarifSecteur1Optam: "54.59",
    tarifHorsSecteur1HorsOptam: "49.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA027",
    designation:
      "Réparation de perte de substance de lèvre par lambeau cutané local",
    tarifSecteur1Optam: "175.12",
    tarifHorsSecteur1HorsOptam: "171.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA016",
    designation:
      "Réparation de perte de substance de lèvre par lambeau hétérolabial",
    tarifSecteur1Optam: "192.85",
    tarifHorsSecteur1HorsOptam: "182.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA029",
    designation:
      "Réparation de perte de substance de lèvre par lambeau unilatéral de lèvre ou de joue",
    tarifSecteur1Optam: "192.85",
    tarifHorsSecteur1HorsOptam: "182.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA002",
    designation:
      "Réparation de perte de substance de lèvre par lambeau bilatéral de lèvre ou de joue",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA023",
    designation:
      "Réparation de perte de substance de lèvre par lambeau nasogénien unilatéral et lambeau controlatéral de lèvre et/ou de joue",
    tarifSecteur1Optam: "272.26",
    tarifHorsSecteur1HorsOptam: "263.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA010",
    designation:
      "Réparation de perte de substance de la lèvre inférieure par lambeau de glissement ou de rotation mentonnier",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA020",
    designation:
      "Réparation de perte de substance de lèvre par lambeau de front et/ou de cuir chevelu",
    tarifSecteur1Optam: "338.9",
    tarifHorsSecteur1HorsOptam: "301.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA011",
    designation:
      "Réparation de perte de substance de lèvre par lambeau cutané deltopectoral",
    tarifSecteur1Optam: "374.35",
    tarifHorsSecteur1HorsOptam: "322.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA014",
    designation:
      "Réparation de perte de substance de lèvre par lambeau musculocutané pédiculé",
    tarifSecteur1Optam: "455.89",
    tarifHorsSecteur1HorsOptam: "369.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA021",
    designation:
      "Réparation de perte de substance de lèvre par lambeau hétérolabial et lambeau unilatéral de lèvre et/ou de joue",
    tarifSecteur1Optam: "281.47",
    tarifHorsSecteur1HorsOptam: "268.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA018",
    designation:
      "Réparation de perte de substance de lèvre par lambeau hétérolabial et lambeau bilatéral de lèvre et/ou de joue",
    tarifSecteur1Optam: "317.63",
    tarifHorsSecteur1HorsOptam: "289.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA005",
    designation: "Réparation de perte de substance de lèvre par lambeau libre",
    tarifSecteur1Optam: "821.73",
    tarifHorsSecteur1HorsOptam: "546.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA003",
    designation:
      "Réparation de perte de substance du vermillon labial par lambeau muqueux hétérolabial [lambeau en rideau]",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA004",
    designation:
      "Réparation de perte de substance du vermillon labial par lambeau de langue",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA008",
    designation:
      "Réparation de perte de substance du vermillon labial par lambeau muqueux rétrolabial ou avancement du vermillon restant",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA030",
    designation:
      "Résection de lèvre, pour traitement palliatif de la paralysie faciale",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA015",
    designation:
      "Réanimation de la commissure labiale par transfert musculaire pédiculé, pour traitement palliatif de la paralysie faciale",
    tarifSecteur1Optam: "409.8",
    tarifHorsSecteur1HorsOptam: "307.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HABA001",
    designation:
      "Cerclage des lèvres par greffe tendineuse ou aponévrotique et/ou suspension de la commissure labiale, pour traitement palliatif de la paralysie faciale",
    tarifSecteur1Optam: "308.42",
    tarifHorsSecteur1HorsOptam: "249.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMB001",
    designation:
      "Réfection de l'aspect du vermillon labial par dermopigmentation",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAMA028",
    designation: "Correction de rétraction de lèvre par autoplastie locale",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA030",
    designation: "Commissuroplastie labiale",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA024",
    designation:
      "Fermeture d'une macrostomie congénitale de moins de 3 cm, avec reconstruction de la commissure labiale",
    tarifSecteur1Optam: "192.35",
    tarifHorsSecteur1HorsOptam: "192.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA011",
    designation:
      "Fermeture d'une macrostomie congénitale de 3 cm ou plus, avec reconstruction de la commissure labiale",
    tarifSecteur1Optam: "230.6",
    tarifHorsSecteur1HorsOptam: "230.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAPA002",
    designation:
      "Section du pédicule [Sevrage] d'un lambeau hétérolabial ou d'un lambeau de langue",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HBLD052",
    designation:
      "Pose d’un dispositif unimaxillaire de contention extracoronaire par attelle composite collée, sur 1 à 6 dents",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBLD050",
    designation:
      "Pose d’un dispositif unimaxillaire de contention extracoronaire par attelle composite collée, sur 7 dents ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBLD053",
    designation:
      "Pose d’un dispositif unimaxillaire de contention extracoronaire par attelle métallique coulée et collée, sur 1 à 6 dents",
    tarifSecteur1Optam: "96.4",
    tarifHorsSecteur1HorsOptam: "96.4",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBLD051",
    designation:
      "Pose d’un dispositif unimaxillaire de contention extracoronaire par attelle métallique coulée et collée, sur 7 dents ou plus",
    tarifSecteur1Optam: "96.4",
    tarifHorsSecteur1HorsOptam: "96.4",
    regroupement: "TDS",
  },
  {
    codeCCAM: "LBGD001",
    designation:
      "Ablation de moyen de contention maxillaire et/ou mandibulaire intrabuccal",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBED011",
    designation: "Réduction de luxation d'une dent",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED016",
    designation: "Réduction de luxation de plusieurs dents",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED009",
    designation: "Réduction de fracture alvéolaire en denture permanente",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED015",
    designation:
      "Réduction de fracture alvéolaire en denture mixte ou incomplète",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED001",
    designation: "Réimplantation d'1 dent permanente expulsée",
    tarifSecteur1Optam: "96.4",
    tarifHorsSecteur1HorsOptam: "96.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED003",
    designation: "Réimplantation de 2 dents permanentes expulsées",
    tarifSecteur1Optam: "192.8",
    tarifHorsSecteur1HorsOptam: "192.8",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED021",
    designation: "Réimplantation de 3 dents permanentes expulsées, ou plus",
    tarifSecteur1Optam: "289.2",
    tarifHorsSecteur1HorsOptam: "289.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED022",
    designation:
      "Autogreffe d'un germe ou d'une dent retenue, dans un site naturel ou préparé chirurgicalement",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED005",
    designation:
      "Autogreffe d'une dent sur arcade, dans un site naturel ou préparé chirurgicalement",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBLD004",
    designation: "Application topique intrabuccale de fluorures",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBLD009",
    designation: "Application d’un topique pour hypersensibilité dentinaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBLD045",
    designation: "Application de vernis fluoré sur les deux arcades dentaires ",
    tarifSecteur1Optam: "25",
    tarifHorsSecteur1HorsOptam: "25",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD005",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 1 dent",
    tarifSecteur1Optam: "26.03",
    tarifHorsSecteur1HorsOptam: "26.03",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD006",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 2 dents",
    tarifSecteur1Optam: "52.06",
    tarifHorsSecteur1HorsOptam: "52.06",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD007",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 3 dents",
    tarifSecteur1Optam: "78.08",
    tarifHorsSecteur1HorsOptam: "78.08",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD004",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 4 dents",
    tarifSecteur1Optam: "104.11",
    tarifHorsSecteur1HorsOptam: "104.11",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD039",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 5 dents",
    tarifSecteur1Optam: "119.3",
    tarifHorsSecteur1HorsOptam: "119.3",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD404",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 6 dents",
    tarifSecteur1Optam: "143.15",
    tarifHorsSecteur1HorsOptam: "143.15",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD098",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 7 dents",
    tarifSecteur1Optam: "167.01",
    tarifHorsSecteur1HorsOptam: "167.01",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBBD427",
    designation:
      "Comblement [Scellement] prophylactique des puits, sillons et fissures sur 8 dents",
    tarifSecteur1Optam: "190.87",
    tarifHorsSecteur1HorsOptam: "190.87",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBJD001",
    designation: "Détartrage et polissage des dents",
    tarifSecteur1Optam: "28.92",
    tarifHorsSecteur1HorsOptam: "28.92",
    regroupement: "AXI",
  },
  {
    codeCCAM: "HBFD010",
    designation: "Parage de plaie de la pulpe d'une dent avec coiffage direct",
    tarifSecteur1Optam: "60",
    tarifHorsSecteur1HorsOptam: "60",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD351",
    designation:
      "Restauration d’une dent sur 2 faces ou plus par matériau incrusté [inlay-onlay] composite ou en alliage non précieux ",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "IN1",
  },
  {
    codeCCAM: "HBMD460",
    designation:
      "Restauration d’une dent sur 2 faces ou plus par matériau incrusté [inlay-onlay] céramique ou en alliage précieux",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "INO",
  },
  {
    codeCCAM: "HBMD058",
    designation:
      "Restauration d’une dent d’un secteur incisivocanin sur 1 face par matériau inséré en phase plastique, sans ancrage radiculaire",
    tarifSecteur1Optam: "29.3",
    tarifHorsSecteur1HorsOptam: "29.3",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD050",
    designation:
      "Restauration d’une dent d’un secteur incisivocanin sur 2 faces par matériau inséré en phase plastique sans ancrage radiculaire",
    tarifSecteur1Optam: "50",
    tarifHorsSecteur1HorsOptam: "50",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD054",
    designation:
      "Restauration d’une dent d’un secteur incisivocanin sur 3 faces ou plus par matériau inséré en phase plastique sans ancrage radiculaire",
    tarifSecteur1Optam: "65.5",
    tarifHorsSecteur1HorsOptam: "65.5",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD044",
    designation:
      "Restauration d'une dent d’un secteur incisivocanin sur 1 angle par matériau inséré en phase plastique, sans ancrage radiculaire",
    tarifSecteur1Optam: "65.5",
    tarifHorsSecteur1HorsOptam: "65.5",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD047",
    designation:
      "Restauration d'une dent d’un secteur incisivocanin sur 2 angles par matériau inséré en phase plastique, sans ancrage radiculaire",
    tarifSecteur1Optam: "94.6",
    tarifHorsSecteur1HorsOptam: "94.6",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD053",
    designation:
      "Restauration d’une dent d’un secteur prémolomolaire sur 1 face par matériau inséré en phase plastique, sans ancrage radiculaire",
    tarifSecteur1Optam: "29.3",
    tarifHorsSecteur1HorsOptam: "29.3",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD049",
    designation:
      "Restauration d’une dent d’un secteur prémolomolaire sur 2 faces par matériau inséré en phase plastique sans ancrage radiculaire",
    tarifSecteur1Optam: "50",
    tarifHorsSecteur1HorsOptam: "50",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBMD038",
    designation:
      "Restauration d’une dent d’un secteur prémolomolaire sur 3 faces ou plus par matériau inséré en phase plastique sans ancrage radiculaire",
    tarifSecteur1Optam: "65.5",
    tarifHorsSecteur1HorsOptam: "65.5",
    regroupement: "SDE",
  },
  {
    codeCCAM: '"HBMD042',
  },
  {
    codeCCAM: '[9]"',
    designation:
      "Restauration d'une dent par matériau inséré en phase plastique avec ancrage radiculaire",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "SDE",
  },
  {
    codeCCAM: "HBFD006",
    designation:
      "Exérèse de la pulpe camérale [Biopulpotomie] d'une dent temporaire",
    tarifSecteur1Optam: "23",
    tarifHorsSecteur1HorsOptam: "23",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD017",
    designation:
      "Exérèse de la pulpe vivante d’une incisive ou d’une canine temporaire",
    tarifSecteur1Optam: "40",
    tarifHorsSecteur1HorsOptam: "40",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD019",
    designation: "Exérèse de la pulpe vivante d’une molaire temporaire",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD032",
    designation:
      "Exérèse partielle de la pulpe vivante d'une dent permanente immature pour apexogénèse",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD033",
    designation:
      "Exérèse de la pulpe vivante d’une incisive ou d’une canine permanente",
    tarifSecteur1Optam: "40",
    tarifHorsSecteur1HorsOptam: "40",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD021",
    designation:
      "Exérèse de la pulpe vivante d’une première prémolaire maxillaire",
    tarifSecteur1Optam: "61",
    tarifHorsSecteur1HorsOptam: "61",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD035",
    designation:
      "Exérèse de la pulpe vivante d’une prémolaire autre que la première prémolaire maxillaire",
    tarifSecteur1Optam: "61",
    tarifHorsSecteur1HorsOptam: "61",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD008",
    designation: "Exérèse de la pulpe vivante d’une molaire permanente",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD015",
    designation:
      "Exérèse du contenu canalaire non vivant d'une incisive ou d'une canine temporaire",
    tarifSecteur1Optam: "47",
    tarifHorsSecteur1HorsOptam: "47",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD474",
    designation:
      "Exérèse du contenu canalaire non vivant d'une molaire temporaire",
    tarifSecteur1Optam: "110",
    tarifHorsSecteur1HorsOptam: "110",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD458",
    designation:
      "Exérèse du contenu canalaire non vivant d’une incisive ou d'une canine permanente immature",
    tarifSecteur1Optam: "47",
    tarifHorsSecteur1HorsOptam: "47",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD395",
    designation:
      "Exérèse du contenu canalaire non vivant d’une première prémolaire maxillaire immature",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD326",
    designation:
      "Exérèse du contenu canalaire non vivant d’une prémolaire immature autre que la première prémolaire maxillaire ",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD150",
    designation:
      "Exérèse du contenu canalaire non vivant d’une molaire permanente immature",
    tarifSecteur1Optam: "110",
    tarifHorsSecteur1HorsOptam: "110",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD001",
    designation:
      "Exérèse du contenu canalaire non vivant d’une incisive ou d’une canine permanente",
    tarifSecteur1Optam: "47",
    tarifHorsSecteur1HorsOptam: "47",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD297",
    designation:
      "Exérèse du contenu canalaire non vivant d’une première prémolaire maxillaire",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD003",
    designation:
      "Exérèse du contenu canalaire non vivant d’une prémolaire autre que la première prémolaire maxillaire",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "END",
  },
  {
    codeCCAM: "HBFD024",
    designation:
      "Exérèse du contenu canalaire non vivant d’une molaire permanente",
    tarifSecteur1Optam: "110",
    tarifHorsSecteur1HorsOptam: "110",
    regroupement: "END",
  },
  {
    codeCCAM: "HBGD030",
    designation: "Désobturation endodontique d'une incisive ou d'une canine",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBGD233",
    designation:
      "Désobturation endodontique d’une première prémolaire maxillaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBGD001",
    designation:
      "Désobturation endodontique d’une prémolaire autre que la première prémolaire maxillaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBGD033",
    designation: "Désobturation endodontique d'une molaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBGD012",
    designation:
      "Ablation de corps étranger  d'un canal radiculaire d'une dent",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBMD003",
    designation:
      "Séance de renouvellement de l'obturation radiculaire d'une dent permanente immature à l'hydroxyde de calcium",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBBD003",
    designation:
      "Obturation radiculaire d'une incisive ou d'une canine après apexification",
    tarifSecteur1Optam: "47",
    tarifHorsSecteur1HorsOptam: "47",
    regroupement: "END",
  },
  {
    codeCCAM: "HBBD234",
    designation:
      "Obturation radiculaire d'une première prémolaire maxillaire  après apexification",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "END",
  },
  {
    codeCCAM: "HBBD001",
    designation:
      "Obturation radiculaire d'une prémolaire autre que la première prémolaire maxillaire après apexification",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "END",
  },
  {
    codeCCAM: "HBBD002",
    designation: "Obturation radiculaire d'une molaire après apexification",
    tarifSecteur1Optam: "105",
    tarifHorsSecteur1HorsOptam: "105",
    regroupement: "END",
  },
  {
    codeCCAM: "HBBA001",
    designation:
      "Obturation d’une résorption radiculaire dentaire externe, par abord parodontal",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBPD002",
    designation:
      "Dégagement d'une dent retenue ou incluse avec pose d'un dispositif de traction orthodontique sans aménagement parodontal",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBPA001",
    designation:
      "Dégagement d'une dent retenue ou incluse, avec pose d'un dispositif de traction orthodontique et aménagement parodontal par greffe ou lambeau",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBPD001",
    designation:
      "Dégagement de plusieurs dents retenues ou incluses avec pose de dispositif de traction orthodontique",
    tarifSecteur1Optam: "156.75",
    tarifHorsSecteur1HorsOptam: "156.75",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD035",
    designation: "Avulsion d'1 dent temporaire sur arcade",
    tarifSecteur1Optam: "25",
    tarifHorsSecteur1HorsOptam: "25",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD037",
    designation: "Avulsion de 2 dents temporaires sur arcade",
    tarifSecteur1Optam: "37",
    tarifHorsSecteur1HorsOptam: "37",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD309",
    designation: "Avulsion de 3 dents temporaires sur arcade",
    tarifSecteur1Optam: "49",
    tarifHorsSecteur1HorsOptam: "49",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD284",
    designation: "Avulsion de 4 dents temporaires sur arcade",
    tarifSecteur1Optam: "61",
    tarifHorsSecteur1HorsOptam: "61",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD065",
    designation: "Avulsion de 5 dents temporaires sur arcade",
    tarifSecteur1Optam: "73",
    tarifHorsSecteur1HorsOptam: "73",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD462",
    designation: "Avulsion de 6 dents temporaires sur arcade",
    tarifSecteur1Optam: "85",
    tarifHorsSecteur1HorsOptam: "85",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD464",
    designation: "Avulsion de 7 dents temporaires sur arcade",
    tarifSecteur1Optam: "95",
    tarifHorsSecteur1HorsOptam: "95",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD263",
    designation: "Avulsion de 8 dents temporaires sur arcade",
    tarifSecteur1Optam: "105",
    tarifHorsSecteur1HorsOptam: "105",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD280",
    designation: "Avulsion de 9 dents temporaires sur arcade",
    tarifSecteur1Optam: "115",
    tarifHorsSecteur1HorsOptam: "115",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD093",
    designation: "Avulsion de 10 dents temporaires sur arcade",
    tarifSecteur1Optam: "125",
    tarifHorsSecteur1HorsOptam: "125",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD362",
    designation: "Avulsion de 11 dents temporaires sur arcade",
    tarifSecteur1Optam: "135",
    tarifHorsSecteur1HorsOptam: "135",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD054",
    designation: "Avulsion de 12 dents temporaires sur arcade",
    tarifSecteur1Optam: "145",
    tarifHorsSecteur1HorsOptam: "145",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD111",
    designation: "Avulsion de 13 dents temporaires sur arcade",
    tarifSecteur1Optam: "155",
    tarifHorsSecteur1HorsOptam: "155",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD174",
    designation: "Avulsion de 14 dents temporaires sur arcade",
    tarifSecteur1Optam: "165",
    tarifHorsSecteur1HorsOptam: "165",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD057",
    designation: "Avulsion de 15 dents temporaires sur arcade",
    tarifSecteur1Optam: "175",
    tarifHorsSecteur1HorsOptam: "175",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD133",
    designation: "Avulsion de 16 dents temporaires sur arcade",
    tarifSecteur1Optam: "185",
    tarifHorsSecteur1HorsOptam: "185",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD123",
    designation: "Avulsion de 17 dents temporaires sur arcade",
    tarifSecteur1Optam: "190",
    tarifHorsSecteur1HorsOptam: "190",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD468",
    designation: "Avulsion de 18 dents temporaires sur arcade",
    tarifSecteur1Optam: "195",
    tarifHorsSecteur1HorsOptam: "195",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD282",
    designation: "Avulsion de19 dents temporaires sur arcade",
    tarifSecteur1Optam: "200",
    tarifHorsSecteur1HorsOptam: "200",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD201",
    designation: "Avulsion de 20 dents temporaires sur arcade",
    tarifSecteur1Optam: "205",
    tarifHorsSecteur1HorsOptam: "205",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD042",
    designation: "Avulsion d’1 dent temporaire retenue, incluse ou réincluse",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD026",
    designation:
      "Avulsion de 2 dents temporaires retenues, incluses ou réincluses",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD036",
    designation: "Avulsion d'1 dent permanente sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "39",
    tarifHorsSecteur1HorsOptam: "39",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD043",
    designation:
      "Avulsion de 2 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "57",
    tarifHorsSecteur1HorsOptam: "57",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD319",
    designation:
      "Avulsion de 3 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "75",
    tarifHorsSecteur1HorsOptam: "75",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD489",
    designation:
      "Avulsion de 4 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "93",
    tarifHorsSecteur1HorsOptam: "93",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD497",
    designation:
      "Avulsion de 5 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "108",
    tarifHorsSecteur1HorsOptam: "108",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD106",
    designation:
      "Avulsion de 6 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "123",
    tarifHorsSecteur1HorsOptam: "123",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD076",
    designation:
      "Avulsion de 7 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "138",
    tarifHorsSecteur1HorsOptam: "138",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD422",
    designation:
      "Avulsion de 8 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "153",
    tarifHorsSecteur1HorsOptam: "153",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD420",
    designation:
      "Avulsion de 9 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "168",
    tarifHorsSecteur1HorsOptam: "168",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD064",
    designation:
      "Avulsion de 10 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "183.92",
    tarifHorsSecteur1HorsOptam: "183.92",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD356",
    designation:
      "Avulsion de 11 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "200.64",
    tarifHorsSecteur1HorsOptam: "200.64",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD146",
    designation:
      "Avulsion de 12 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "217.36",
    tarifHorsSecteur1HorsOptam: "217.36",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD382",
    designation:
      "Avulsion de 13 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "234.08",
    tarifHorsSecteur1HorsOptam: "234.08",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD247",
    designation:
      "Avulsion de 14 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD197",
    designation:
      "Avulsion de 15 dents permanente sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "267.52",
    tarifHorsSecteur1HorsOptam: "267.52",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD333",
    designation:
      "Avulsion de 16 dents permanente sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "284.24",
    tarifHorsSecteur1HorsOptam: "284.24",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD261",
    designation:
      "Avulsion de 17 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "300.96",
    tarifHorsSecteur1HorsOptam: "300.96",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD499",
    designation:
      "Avulsion de 18 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "317.68",
    tarifHorsSecteur1HorsOptam: "317.68",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD461",
    designation:
      "Avulsion de 19 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD278",
    designation:
      "Avulsion de 20 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "351.12",
    tarifHorsSecteur1HorsOptam: "351.12",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD258",
    designation:
      "Avulsion de 21 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "367.84",
    tarifHorsSecteur1HorsOptam: "367.84",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD311",
    designation:
      "Avulsion de 22 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "384.56",
    tarifHorsSecteur1HorsOptam: "384.56",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD235",
    designation:
      "Avulsion de 23 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "401.28",
    tarifHorsSecteur1HorsOptam: "401.28",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD374",
    designation:
      "Avulsion de 24 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD475",
    designation:
      "Avulsion de 25 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "434.72",
    tarifHorsSecteur1HorsOptam: "434.72",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD285",
    designation:
      "Avulsion de 26 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "451.44",
    tarifHorsSecteur1HorsOptam: "451.44",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD338",
    designation:
      "Avulsion de 27 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "468.16",
    tarifHorsSecteur1HorsOptam: "468.16",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD193",
    designation:
      "Avulsion de 28 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "484.88",
    tarifHorsSecteur1HorsOptam: "484.88",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD345",
    designation:
      "Avulsion de 29 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "501.6",
    tarifHorsSecteur1HorsOptam: "501.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD414",
    designation:
      "Avulsion de 30 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "518.32",
    tarifHorsSecteur1HorsOptam: "518.32",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD245",
    designation:
      "Avulsion de 31 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "535.04",
    tarifHorsSecteur1HorsOptam: "535.04",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD283",
    designation:
      "Avulsion de 32 dents permanentes sur arcade sans alvéolectomie",
    tarifSecteur1Optam: "551.76",
    tarifHorsSecteur1HorsOptam: "551.76",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD022",
    designation: "Avulsion d'1 dent permanente sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "43",
    tarifHorsSecteur1HorsOptam: "43",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD034",
    designation:
      "Avulsion de 2 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "61",
    tarifHorsSecteur1HorsOptam: "61",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD287",
    designation:
      "Avulsion de 3 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "79",
    tarifHorsSecteur1HorsOptam: "79",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD078",
    designation:
      "Avulsion de 4 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD060",
    designation:
      "Avulsion de 5 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "100.32",
    tarifHorsSecteur1HorsOptam: "100.32",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD312",
    designation:
      "Avulsion de 6 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "117.04",
    tarifHorsSecteur1HorsOptam: "117.04",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD142",
    designation:
      "Avulsion de 7 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "133.76",
    tarifHorsSecteur1HorsOptam: "133.76",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD231",
    designation:
      "Avulsion de 8 dents à 10 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD349",
    designation:
      "Avulsion de 11 dents à 13 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "217.36",
    tarifHorsSecteur1HorsOptam: "217.36",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD067",
    designation:
      "Avulsion de 14 dents à 16 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "267.52",
    tarifHorsSecteur1HorsOptam: "267.52",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD418",
    designation:
      "Avulsion de 17 dents à 19 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "317.68",
    tarifHorsSecteur1HorsOptam: "317.68",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD441",
    designation:
      "Avulsion de 20 dents à 22 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "367.84",
    tarifHorsSecteur1HorsOptam: "367.84",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD105",
    designation:
      "Avulsion de 23 dents à 25 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD191",
    designation:
      "Avulsion de 26 dents à 28 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "468.16",
    tarifHorsSecteur1HorsOptam: "468.16",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD262",
    designation:
      "Avulsion de 29 dents à 32 dents permanentes sur arcade avec alvéolectomie",
    tarifSecteur1Optam: "526.68",
    tarifHorsSecteur1HorsOptam: "526.68",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD031",
    designation:
      "Avulsion d'1 dent permanente sur arcade avec séparation des racines",
    tarifSecteur1Optam: "43",
    tarifHorsSecteur1HorsOptam: "43",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD032",
    designation:
      "Avulsion de 2 dents permanentes sur arcade avec séparation des racines",
    tarifSecteur1Optam: "61",
    tarifHorsSecteur1HorsOptam: "61",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD415",
    designation:
      "Avulsion de 3 dents permanentes sur arcade avec séparation des racines",
    tarifSecteur1Optam: "66.88",
    tarifHorsSecteur1HorsOptam: "66.88",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD169",
    designation:
      "Avulsion de 4 dents permanentes sur arcade avec séparation des racines",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD039",
    designation:
      "Avulsion d'1 dent ankylosée sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "43",
    tarifHorsSecteur1HorsOptam: "43",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD002",
    designation:
      "Avulsion de 2 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "50.16",
    tarifHorsSecteur1HorsOptam: "50.16",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD453",
    designation:
      "Avulsion de 3 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "66.88",
    tarifHorsSecteur1HorsOptam: "66.88",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD218",
    designation:
      "Avulsion de 4 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD480",
    designation:
      "Avulsion de 5 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "100.32",
    tarifHorsSecteur1HorsOptam: "100.32",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD206",
    designation:
      "Avulsion de 6 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "117.04",
    tarifHorsSecteur1HorsOptam: "117.04",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD396",
    designation:
      "Avulsion de 7 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "133.76",
    tarifHorsSecteur1HorsOptam: "133.76",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD113",
    designation:
      "Avulsion de 8 à 10 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD438",
    designation:
      "Avulsion de 11 à 13 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "217.36",
    tarifHorsSecteur1HorsOptam: "217.36",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD122",
    designation:
      "Avulsion de 14 à 16 dents ankylosées sur arcade, avec section coronoradiculaire et séparation des racines",
    tarifSecteur1Optam: "267.52",
    tarifHorsSecteur1HorsOptam: "267.52",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD028",
    designation:
      "Avulsion d'1 incisive permanente retenue ou à l'état de germe",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD126",
    designation:
      "Avulsion de 2 incisives permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD062",
    designation:
      "Avulsion de 3 incisives permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD430",
    designation:
      "Avulsion de 4 incisives permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD372",
    designation:
      "Avulsion de 5 à 6 incisives permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD148",
    designation:
      "Avulsion de 7 à 8 incisives permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD014",
    designation: "Avulsion d'1 canine permanente retenue ou à l'état de germe",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD015",
    designation:
      "Avulsion de 2 canines permanentes  retenues ou à l'état de germe",
    tarifSecteur1Optam: "156.75",
    tarifHorsSecteur1HorsOptam: "156.75",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD145",
    designation:
      "Avulsion de 3 canines permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD416",
    designation:
      "Avulsion de 4 canines permanentes  retenues ou à l'état de germe",
    tarifSecteur1Optam: "261.25",
    tarifHorsSecteur1HorsOptam: "261.25",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD459",
    designation: "Avulsion d'1 prémolaire retenue ou à l'état de germe ",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD386",
    designation: "Avulsion de 2 prémolaires retenues ou à l'état de germe",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD279",
    designation: "Avulsion de 3 prémolaires retenues ou à l'état de germe ",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD199",
    designation: "Avulsion de 4 prémolaires retenues ou à l'état de germe",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD385",
    designation: "Avulsion de 5 à 6 prémolaires retenues ou à l'état de germe ",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD359",
    designation: "Avulsion de 7 à 8 prémolaires retenues ou à l'état de germe",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD047",
    designation:
      "Avulsion d'1 première ou d'1 deuxième molaire permanente retenue ou à l'état de germe",
    tarifSecteur1Optam: "96.14",
    tarifHorsSecteur1HorsOptam: "96.14",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD492",
    designation:
      "Avulsion de 2 premières ou deuxièmes molaires permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD316",
    designation:
      "Avulsion de 3 premières ou deuxièmes molaires permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD117",
    designation:
      "Avulsion de 4 premières ou deuxièmes molaires permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD181",
    designation:
      "Avulsion de 5 à 6 premières ou deuxièmes molaires permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD210",
    designation:
      "Avulsion de 7 à 8 premières ou deuxièmes molaires permanentes retenues ou à l'état de germe",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD018",
    designation:
      "Avulsion d'1 troisième molaire maxillaire retenue ou à l'état de germe",
    tarifSecteur1Optam: "96.14",
    tarifHorsSecteur1HorsOptam: "96.14",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD004",
    designation:
      "Avulsion d'1 troisième molaire mandibulaire retenue ou à l'état de germe",
    tarifSecteur1Optam: "96.14",
    tarifHorsSecteur1HorsOptam: "96.14",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD025",
    designation:
      "Avulsion de 2 troisièmes molaires retenues ou à l'état de germe",
    tarifSecteur1Optam: "144.21",
    tarifHorsSecteur1HorsOptam: "144.21",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD021",
    designation:
      "Avulsion de 3 troisièmes molaires retenues ou à l'état de germe",
    tarifSecteur1Optam: "192.28",
    tarifHorsSecteur1HorsOptam: "192.28",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD038",
    designation:
      "Avulsion de 4 troisièmes molaires retenues ou à l'état de germe",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "240.35",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD044",
    designation:
      "Avulsion d'1 dent à couronne sousmuqueuse ou en désinclusion muqueuse",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD322",
    designation:
      "Avulsion de 2 dents à couronne sousmuqueuse ou en désinclusion muqueuse",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD160",
    designation:
      "Avulsion de 3 dents à couronne sousmuqueuse ou en désinclusion muqueuse",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD403",
    designation:
      "Avulsion de 4 dents à couronne sousmuqueuse ou en désinclusion muqueuse",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD300",
    designation:
      "Avulsion d'une dent en désinclusion avec couronne sous muqueuse en position palatine ou linguale",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD358",
    designation:
      '"Avulsion de 2 dents en désinclusion avec couronnes sous muqueuses  en position palatine et/ou linguale',
  },
  {
    codeCCAM: '"',
    designation: "156.75",
    tarifSecteur1Optam: "156.75",
    tarifHorsSecteur1HorsOptam: "ASC",
  },
  {
    codeCCAM: "HBGD003",
    designation:
      "Avulsion d'1 odontoïde inclus ou d'1 dent surnuméraire à l'état de germe",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD402",
    designation:
      "\"Avulsion de 2 odontoïdes inclus ou de 2 dents surnuméraires à l'état de germe",
  },
  {
    codeCCAM: '"',
    designation: "125.4",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "ASC",
  },
  {
    codeCCAM: "HBGD281",
    designation:
      "Avulsion de 3 odontoïdes inclus ou de 3 dents surnuméraires à l'état de germe",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD171",
    designation:
      "\"Avulsion de 4 odontoïdes inclus ou de 4 dents surnuméraires à l'état de germe",
  },
  {
    codeCCAM: '"',
    designation: "209",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "ASC",
  },
  {
    codeCCAM: "HBGD016",
    designation: "Avulsion d'1 racine incluse",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD466",
    designation: "Avulsion de 2 racines incluses",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD299",
    designation: "Avulsion de 3 racines incluses",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD102",
    designation: "Avulsion de 4 racines incluses",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD159",
    designation: "Avulsion de 5 racines incluses",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD465",
    designation: "Avulsion de 6 racines incluses",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD017",
    designation: "Avulsion d'1 dent ectopique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD190",
    designation: "Avulsion de 2 dents ectopiques",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD397",
    designation: "Avulsion de 3 dents ectopiques",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGD080",
    designation: "Avulsion de 4 dents ectopiques",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBFD014",
    designation:
      "Amputation et/ou séparation radiculaire ou coronoradiculaire d'une dent",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBMD001",
    designation: "Eclaircissement de dent dépulpée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBMD005",
    designation: "Eclaircissement des dents pulpées",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBLD006",
    designation: "Pose d’un mainteneur d’espace interdentaire unitaire scellé",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBLD002",
    designation: "Pose d’un mainteneur d’espace interdentaire amovible passif",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBLD001",
    designation:
      "Pose d’un arc de maintien d’espace interdentaire sans dent prothétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBLD003",
    designation:
      "Pose d’un arc de maintien d’espace interdentaire avec dent prothétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBMD061",
    designation: "Séance d'ajustement occlusal par coronoplastie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD020",
    designation: "Pose d'un appareil de posture mandibulaire [cale]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD018",
    designation: "Pose d'un plan de libération occlusale",
    tarifSecteur1Optam: "172.8",
    tarifHorsSecteur1HorsOptam: "172.8",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD019",
    designation:
      "Pose d'un plan de guidage des mouvements antéropostérieurs mandibulaires",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD015",
    designation:
      "Pose d'une coiffe de recouvrement d'une racine dentaire [Coping]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD090",
    designation:
      "Pose d'une infrastructure coronoradiculaire [Inlay core] sous une couronne ou un pilier de bridge dentoportés sans reste à charge ",
    tarifSecteur1Optam: "90",
    tarifHorsSecteur1HorsOptam: "90",
    regroupement: "IC0",
  },
  {
    codeCCAM: "HBLD745",
    designation:
      "Pose d'une infrastructure coronoradiculaire [Inlay core] sous une couronne ou un pilier de bridge dentoportés à entente directe limitée ",
    tarifSecteur1Optam: "90",
    tarifHorsSecteur1HorsOptam: "90",
    regroupement: "IC1",
  },
  {
    codeCCAM: "HBLD245",
    designation:
      "Pose d'une infrastructure coronoradiculaire [Inlay core] sous une couronne ou un pilier de bridge dentoportés à tarif libre",
    tarifSecteur1Optam: "90",
    tarifHorsSecteur1HorsOptam: "90",
    regroupement: "ICO",
  },
  {
    codeCCAM: "HBLD012",
    designation: "Pose d'une infrastructure coronaire sur 1 implant",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD017",
    designation: "Pose d'infrastructure coronaire sur 2 implants",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD021",
    designation: "Pose d'infrastructure coronaire sur 3 implants",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD013",
    designation: "Pose d'infrastructure coronaire sur 4 implants",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD005",
    designation: "Pose d'infrastructure coronaire sur 5 implants ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD008",
    designation: "Pose d'un attachement coronoradiculaire sur une dent",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD490",
    designation:
      "Pose d'une couronne dentaire transitoire pour couronne dentoportée sans reste à charge ",
    tarifSecteur1Optam: "10",
    tarifHorsSecteur1HorsOptam: "10",
    regroupement: "CT0",
  },
  {
    codeCCAM: "HBLD724",
    designation:
      "Pose d'une couronne dentaire transitoire pour une couronne dentoportée à entente directe limitée",
    tarifSecteur1Optam: "10",
    tarifHorsSecteur1HorsOptam: "10",
    regroupement: "CT1",
  },
  {
    codeCCAM: "HBLD486",
    designation:
      "Pose d'une couronne dentaire transitoire pour une couronne dentoportée à tarif libre",
    tarifSecteur1Optam: "10",
    tarifHorsSecteur1HorsOptam: "10",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBLD610",
    designation:
      "Pose d'une couronne dentaire transitoire unitaire sur implant ou sur pilier de bridge dento-porté ou implantoporté",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBLD038",
    designation:
      "Pose d'une couronne dentaire dentoportée en alliage non précieux ",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "CM0",
  },
  {
    codeCCAM: "HBLD634",
    designation:
      "Pose d'une couronne dentaire dentoportée céramométallique sur une incisive, une canine ou une première prémolaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "PF0",
  },
  {
    codeCCAM: "HBLD491",
    designation:
      "Pose d'une couronne dentaire dentoportée céramométallique sur une deuxième prémolaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "PF1",
  },
  {
    codeCCAM: "HBLD734",
    designation:
      "Pose d'une couronne dentaire dentoportée céramométallique sur une molaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD350",
    designation:
      "Pose d'une couronne dentaire dentoportée céramique-monolithique zircone sur une dent autre qu’une molaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "CZ0",
  },
  {
    codeCCAM: "HBLD073",
    designation:
      "Pose d'une couronne dentaire dentoportée céramique-monolithique zircone sur une molaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "CZ1",
  },
  {
    codeCCAM: "HBLD680",
    designation:
      "Pose d'une couronne dentaire dentoportée céramique monolithique autre que zircone sur une incisive, une canine ou une première prémolaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "PF0",
  },
  {
    codeCCAM: "HBLD158",
    designation:
      "Pose d'une couronne dentaire dentoportée céramique monolithique  autre que zircone sur une deuxième prémolaire ou une molaire",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "PF1",
  },
  {
    codeCCAM: "HBLD403",
    designation: "Pose d'une couronne dentaire dentoportée céramocéramique ",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "120",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD318",
    designation:
      "\"Pose d'une couronne dentaire dentoportée en alliage précieux ",
  },
  {
    codeCCAM: 'Avec ou sans recouvrement céramique"',
    designation: "120",
    tarifSecteur1Optam: "120",
    tarifHorsSecteur1HorsOptam: "PFC",
  },
  {
    codeCCAM: "HBLD418",
    designation: "Pose d'une couronne dentaire implantoportée",
    tarifSecteur1Optam: "107.5",
    tarifHorsSecteur1HorsOptam: "107.5",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD132",
    designation:
      "Pose d’une prothèse amovible supra-implantaire à plaque résine comportant moins de 9 dents ",
    tarifSecteur1Optam: "102.125",
    tarifHorsSecteur1HorsOptam: "102.125",
    regroupement: "PAR",
  },
  {
    codeCCAM: "HBLD492",
    designation:
      "Pose d'une prothèse amovible supra-implantaire à plaque base résine, comportant de 9 à 13 dents",
    tarifSecteur1Optam: "150.5",
    tarifHorsSecteur1HorsOptam: "150.5",
    regroupement: "PAR",
  },
  {
    codeCCAM: "HBLD118",
    designation:
      "Pose d'une prothèse amovible supra-implantaire complète unimaxillaire à plaque base résine",
    tarifSecteur1Optam: "182.75",
    tarifHorsSecteur1HorsOptam: "182.75",
    regroupement: "PAR",
  },
  {
    codeCCAM: "HBLD199",
    designation:
      "Pose d'une prothèse amovible supra-implantaire complète bimaxillaire à plaque base résine",
    tarifSecteur1Optam: "365.5",
    tarifHorsSecteur1HorsOptam: "365.5",
    regroupement: "PAR",
  },
  {
    codeCCAM: "HBLD240",
    designation:
      "Pose d’une prothèse amovible supra-implantaire à chassis métallique  comportant moins de 9 dents ",
    tarifSecteur1Optam: "236.5",
    tarifHorsSecteur1HorsOptam: "236.5",
    regroupement: "PAM",
  },
  {
    codeCCAM: "HBLD236",
    designation:
      "Pose d’une prothèse amovible supra-implantaire à chassis métallique  comportant de 9 à 13 dents ",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PAM",
  },
  {
    codeCCAM: "HBLD217",
    designation:
      "Pose d’une prothèse amovible supra-implantaire complète unimaxillaire à chassis métallique",
    tarifSecteur1Optam: "311.75",
    tarifHorsSecteur1HorsOptam: "311.75",
    regroupement: "PAM",
  },
  {
    codeCCAM: "HBLD171",
    designation:
      "Pose d’une prothèse amovible supra-implantaire complète bimaxillaire à chassis métallique ",
    tarifSecteur1Optam: "623.5",
    tarifHorsSecteur1HorsOptam: "623.5",
    regroupement: "PAM",
  },
  {
    codeCCAM: "HBLD364",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 1 à 3 dents",
    tarifSecteur1Optam: "64.5",
    tarifHorsSecteur1HorsOptam: "64.5",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD476",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 4 dents",
    tarifSecteur1Optam: "75.25",
    tarifHorsSecteur1HorsOptam: "75.25",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD224",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 5 dents",
    tarifSecteur1Optam: "86",
    tarifHorsSecteur1HorsOptam: "86",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD371",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 6 dents",
    tarifSecteur1Optam: "96.75",
    tarifHorsSecteur1HorsOptam: "96.75",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD123",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 7 dents",
    tarifSecteur1Optam: "107.5",
    tarifHorsSecteur1HorsOptam: "107.5",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD270",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 8 dents",
    tarifSecteur1Optam: "118.25",
    tarifHorsSecteur1HorsOptam: "118.25",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD148",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 9 dents",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD231",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 10 dents",
    tarifSecteur1Optam: "139.75",
    tarifHorsSecteur1HorsOptam: "139.75",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD215",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 11 dents",
    tarifSecteur1Optam: "150.5",
    tarifHorsSecteur1HorsOptam: "150.5",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD262",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 12 dents",
    tarifSecteur1Optam: "161.25",
    tarifHorsSecteur1HorsOptam: "161.25",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD232",
    designation:
      "Pose d'une prothèse amovible de transition à plaque base résine, comportant 13 dents",
    tarifSecteur1Optam: "172",
    tarifHorsSecteur1HorsOptam: "172",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD032",
    designation:
      "Pose d'une prothèse amovible de transition complète unimaxillaire à plaque base résine",
    tarifSecteur1Optam: "182.75",
    tarifHorsSecteur1HorsOptam: "182.75",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD259",
    designation:
      "Pose d'une prothèse amovible de transition complète bimaxillaire à plaque base résine",
    tarifSecteur1Optam: "365.5",
    tarifHorsSecteur1HorsOptam: "365.5",
    regroupement: "PT0",
  },
  {
    codeCCAM: "HBLD101",
    designation:
      "Pose d'une prothèse amovible définitive à plaque base résine, comportant 9 dents",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD138",
    designation:
      "Pose d'une prothèse amovible définitive à plaque base résine, comportant 10 dents",
    tarifSecteur1Optam: "139.75",
    tarifHorsSecteur1HorsOptam: "139.75",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD083",
    designation:
      "Pose d'une prothèse amovible définitive à plaque base résine, comportant 11 dents",
    tarifSecteur1Optam: "150.5",
    tarifHorsSecteur1HorsOptam: "150.5",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD370",
    designation:
      "Pose d'une prothèse amovible définitive à plaque base résine, comportant 12 dents",
    tarifSecteur1Optam: "161.25",
    tarifHorsSecteur1HorsOptam: "161.25",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD349",
    designation:
      "Pose d'une prothèse amovible définitive à plaque base résine, comportant 13 dents",
    tarifSecteur1Optam: "172",
    tarifHorsSecteur1HorsOptam: "172",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD031",
    designation:
      "Pose d'une prothèse amovible définitive complète unimaxillaire à plaque base résine",
    tarifSecteur1Optam: "182.75",
    tarifHorsSecteur1HorsOptam: "182.75",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD035",
    designation:
      "Pose d'une prothèse amovible définitive complète bimaxillaire à plaque base résine",
    tarifSecteur1Optam: "365.5",
    tarifHorsSecteur1HorsOptam: "365.5",
    regroupement: "PA0",
  },
  {
    codeCCAM: "HBLD131",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 1 à 3 dents",
    tarifSecteur1Optam: "193.5",
    tarifHorsSecteur1HorsOptam: "193.5",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD332",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 4 dents",
    tarifSecteur1Optam: "204.25",
    tarifHorsSecteur1HorsOptam: "204.25",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD452",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 5 dents",
    tarifSecteur1Optam: "215",
    tarifHorsSecteur1HorsOptam: "215",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD474",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 6 dents",
    tarifSecteur1Optam: "225.75",
    tarifHorsSecteur1HorsOptam: "225.75",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD075",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 7 dents",
    tarifSecteur1Optam: "236.5",
    tarifHorsSecteur1HorsOptam: "236.5",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD470",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 8 dents",
    tarifSecteur1Optam: "247.25",
    tarifHorsSecteur1HorsOptam: "247.25",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD435",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 9 dents",
    tarifSecteur1Optam: "258",
    tarifHorsSecteur1HorsOptam: "258",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD079",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 10 dents",
    tarifSecteur1Optam: "268.75",
    tarifHorsSecteur1HorsOptam: "268.75",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD203",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 11 dents",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD112",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 12 dents",
    tarifSecteur1Optam: "290.25",
    tarifHorsSecteur1HorsOptam: "290.25",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD308",
    designation:
      "Pose d'une prothèse amovible définitive à châssis métallique, comportant 13 dents",
    tarifSecteur1Optam: "301",
    tarifHorsSecteur1HorsOptam: "301",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD047",
    designation:
      "Pose d'une prothèse amovible définitive complète unimaxillaire à châssis métallique",
    tarifSecteur1Optam: "311.75",
    tarifHorsSecteur1HorsOptam: "311.75",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD046",
    designation:
      "Pose d'une prothèse amovible définitive complète bimaxillaire à châssis métallique",
    tarifSecteur1Optam: "623.5",
    tarifHorsSecteur1HorsOptam: "623.5",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBLD048",
    designation:
      "Pose d'une prothèse amovible définitive complète unimaxillaire à plaque base résine et d'une prothèse amovible définitive complète unimaxillaire à châssis métallique",
    tarifSecteur1Optam: "494.5",
    tarifHorsSecteur1HorsOptam: "494.5",
    regroupement: "PA1",
  },
  {
    codeCCAM: "HBMD048",
    designation:
      "Pose d'une facette céramique, en équivalents minéraux ou composite sur une dent d’un secteur incisivocanin ou prémolaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD034",
    designation:
      "Pose d’une prothèse dentaire plurale transitoire [bridge transitoire]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "PDT",
  },
  {
    codeCCAM: "HBLD040",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 1 pilier d'ancrage métallique, 1 pilier d'ancrage céramométallique, et 1 élément intermédiaire métallique",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "BR1",
  },
  {
    codeCCAM: "HBLD043",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 1 pilier d'ancrage métallique, 1 pilier d'ancrage céramométallique, et 1 élément intermédiaire céramométallique ",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PF1",
  },
  {
    codeCCAM: "HBLD033",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 2 piliers d'ancrage métalliques et 1 élément intermédiaire métallique",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "CM0",
  },
  {
    codeCCAM: "HBLD785",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 2 piliers d'ancrage céramométalliques et 1 élément intermédiaire céramométallique pour le remplacement d'une incisive",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PF0",
  },
  {
    codeCCAM: "HBLD227",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 2 piliers d'ancrage céramométalliques et 1 élément intermédiaire céramométallique pour le remplacement d'une dent autre qu'une incisive",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PF1",
  },
  {
    codeCCAM: "HBLD425",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 2 piliers d'ancrage céramocéramiques ou céramiques monolithiques (zircone ou hors zircone) et 1 élément intermédiaire céramocéramique ou céramique monolithique (zircone ou hors zircone)",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD178",
    designation:
      "Pose d'une prothèse plurale [bridge] comportant 2 piliers d'ancrage en alliage précieux et 1 élément intermédiaire en alliage précieux",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD088",
    designation:
      "Pose d’une prothèse plurale en extension comportant 1 pilier d’ancrage céramométallique ou en équivalents minéraux et 1 élément en extension céramométallique ou en équivalents minéraux [bridge cantilever 2 éléments], pour le remplacement d’une incisive permanente",
    tarifSecteur1Optam: "172",
    tarifHorsSecteur1HorsOptam: "172",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD750",
    designation:
      "Pose d’une prothèse plurale en extension comportant 2 piliers d’ancrage céramométalliques ou en équivalents minéraux et 1 élément en extension céramométallique ou en équivalents minéraux  [bridge cantilever 3 éléments], pour le remplacement d’une incisive permanente ou d’une prémolaire",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD411",
    designation:
      "Pose d’une prothèse plurale en extension comportant 2 piliers d’ancrage métallique et 1 élément en extension métallique [bridge cantilever 3 éléments],  pour le remplacement d’une incisive permanente ou d’une prémolaire",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBLD321",
    designation:
      "Pose d’une prothèse plurale en extension comportant 1 pilier d’ancrage métallique, 1 pilier d'ancrage céramométallique  et 1 élément en extension  céramométallique [bridge cantilever 3 éléments], pour le remplacement d’une incisive permanente ou d’une prémolaire",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD465",
    designation:
      "Pose d’une prothèse plurale en extension comportant 2 piliers d’ancrage métalliques et 1 élément en extension céramométallique [bridge cantilever 3 éléments],  pour le remplacement d’une incisive permanente ou d’une prémolaire",
    tarifSecteur1Optam: "279.5",
    tarifHorsSecteur1HorsOptam: "279.5",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBLD466",
    designation:
      "Pose d’une prothèse plurale collée [bridge collé]  comportant 2 ancrages coronaires partiels ou plus et 1 élément intermédiaire céramométallique ou en équivalents minéraux, pour le remplacement d’une incisive permanente",
    tarifSecteur1Optam: "103.06",
    tarifHorsSecteur1HorsOptam: "103.06",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD414",
    designation:
      "Pose d’une prothèse plurale collée [bridge collé] comportant 2 ancrages coronaires partiels ou plus et 1 élément intermédiaire céramométallique ou en équivalents minéraux,  pour le remplacement d’une prémolaire ou d'une molaire permanente",
    tarifSecteur1Optam: "103.06",
    tarifHorsSecteur1HorsOptam: "103.06",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD179",
    designation:
      "Pose d’une prothèse plurale collée [bridge collé] comportant 2 ancrages coronaires partiels ou plus et 1 élément intermédiaire métallique, pour le remplacement d’une prémolaire ou d'une molaire permanente",
    tarifSecteur1Optam: "103.06",
    tarifHorsSecteur1HorsOptam: "103.06",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBLD453",
    designation:
      "Pose d’une prothèse plurale collée [bridge collé]  comportant 2 ancrages coronaires partiels ou plus et 2 éléments intermédiaires céramométalliques ou en équivalents minéraux, pour le remplacement de 2 incisives mandibulaires permanentes",
    tarifSecteur1Optam: "103.06",
    tarifHorsSecteur1HorsOptam: "103.06",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD093",
    designation:
      "Pose d’une prothèse plurale en extension, collée comportant 1 ancrage coronaire partiel  et 1 élément en extension céramométallique ou en équivalents minéraux [bridge cantilever collé], pour le remplacement d’une incisive permanente",
    tarifSecteur1Optam: "83.78",
    tarifHorsSecteur1HorsOptam: "83.78",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBLD030",
    designation:
      "\"Pose d'une prothèse dentaire complète transvissée implantoportée",
  },
  {
    codeCCAM: '"',
    designation: "182.75",
    tarifSecteur1Optam: "182.75",
    tarifHorsSecteur1HorsOptam: "PFC",
  },
  {
    codeCCAM: "HBMD017",
    designation:
      "Adjonction ou changement d'1 élément d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "21.5",
    tarifHorsSecteur1HorsOptam: "21.5",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD114",
    designation:
      "Adjonction ou changement de 2 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "32.25",
    tarifHorsSecteur1HorsOptam: "32.25",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD322",
    designation:
      "Adjonction ou changement de 3 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "43",
    tarifHorsSecteur1HorsOptam: "43",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD404",
    designation:
      "Adjonction ou changement de 4 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "53.75",
    tarifHorsSecteur1HorsOptam: "53.75",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD245",
    designation:
      "Adjonction ou changement de 5 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "64.5",
    tarifHorsSecteur1HorsOptam: "64.5",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD198",
    designation:
      "Adjonction ou changement de 6 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "75.25",
    tarifHorsSecteur1HorsOptam: "75.25",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD373",
    designation:
      "Adjonction ou changement de 7 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "86",
    tarifHorsSecteur1HorsOptam: "86",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD228",
    designation:
      "Adjonction ou changement de 8 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "96.75",
    tarifHorsSecteur1HorsOptam: "96.75",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD286",
    designation:
      "Adjonction ou changement de 9 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "107.5",
    tarifHorsSecteur1HorsOptam: "107.5",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD329",
    designation:
      "Adjonction ou changement de 10 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "118.25",
    tarifHorsSecteur1HorsOptam: "118.25",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD226",
    designation:
      "Adjonction ou changement de 11 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD387",
    designation:
      "Adjonction ou changement de 12 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "139.75",
    tarifHorsSecteur1HorsOptam: "139.75",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD134",
    designation:
      "Adjonction ou changement de 13 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "150.5",
    tarifHorsSecteur1HorsOptam: "150.5",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD174",
    designation:
      "Adjonction ou changement de 14 éléments d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "161.25",
    tarifHorsSecteur1HorsOptam: "161.25",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBKD396",
    designation: "Changement d'1 facette d'une prothèse dentaire amovible",
    tarifSecteur1Optam: "17.2",
    tarifHorsSecteur1HorsOptam: "17.2",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD431",
    designation: "Changement de 2 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "34.4",
    tarifHorsSecteur1HorsOptam: "34.4",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD300",
    designation: "Changement de 3 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "51.6",
    tarifHorsSecteur1HorsOptam: "51.6",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD212",
    designation: "Changement de 4 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "68.8",
    tarifHorsSecteur1HorsOptam: "68.8",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD462",
    designation: "Changement de 5 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "86",
    tarifHorsSecteur1HorsOptam: "86",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD213",
    designation: "Changement de 6 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "103.2",
    tarifHorsSecteur1HorsOptam: "103.2",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD140",
    designation: "Changement de 7 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "120.4",
    tarifHorsSecteur1HorsOptam: "120.4",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD244",
    designation: "Changement de 8 facettes d'une prothèse dentaire amovible ",
    tarifSecteur1Optam: "137.6",
    tarifHorsSecteur1HorsOptam: "137.6",
    regroupement: "RF0",
  },
  {
    codeCCAM: "HBKD005",
    designation:
      "Changement de dispositif d'attachement d'une prothèse dentaire amovible supra implantaire",
    tarifSecteur1Optam: "32.25",
    tarifHorsSecteur1HorsOptam: "32.25",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD249",
    designation:
      "Adjonction ou changement d'1 élément soudé sur une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "43",
    tarifHorsSecteur1HorsOptam: "43",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD292",
    designation:
      "Adjonction ou changement de 2 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "86",
    tarifHorsSecteur1HorsOptam: "86",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD188",
    designation:
      "Adjonction ou changement de 3 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD432",
    designation:
      "Adjonction ou changement de 4 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "172",
    tarifHorsSecteur1HorsOptam: "172",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD283",
    designation:
      "Adjonction ou changement de 5 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "215",
    tarifHorsSecteur1HorsOptam: "215",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD439",
    designation:
      "Adjonction ou changement de 6 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "258",
    tarifHorsSecteur1HorsOptam: "258",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD425",
    designation:
      "Adjonction ou changement de 7 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "301",
    tarifHorsSecteur1HorsOptam: "301",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD444",
    designation:
      "Adjonction ou changement de 8 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "344",
    tarifHorsSecteur1HorsOptam: "344",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD485",
    designation:
      "Adjonction ou changement de 9 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "387",
    tarifHorsSecteur1HorsOptam: "387",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD410",
    designation:
      "Adjonction ou changement de 10 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "430",
    tarifHorsSecteur1HorsOptam: "430",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD429",
    designation:
      "Adjonction ou changement de 11 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "473",
    tarifHorsSecteur1HorsOptam: "473",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD281",
    designation:
      "Adjonction ou changement de 12 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "516",
    tarifHorsSecteur1HorsOptam: "516",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD200",
    designation:
      "Adjonction ou changement de 13 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "559",
    tarifHorsSecteur1HorsOptam: "559",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD298",
    designation:
      "Adjonction ou changement de 14 éléments soudés d'une prothèse dentaire amovible sur châssis métallique",
    tarifSecteur1Optam: "602",
    tarifHorsSecteur1HorsOptam: "602",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD020",
    designation:
      "Réparation d'une prothèse dentaire amovible en résine sans renfort métallique, fêlée ou fracturée",
    tarifSecteur1Optam: "21.5",
    tarifHorsSecteur1HorsOptam: "21.5",
    regroupement: "RS0",
  },
  {
    codeCCAM: "HBMD356",
    designation:
      "Réparation d'une prothèse dentaire amovible en résine avec renfort métallique, fêlée ou fracturée",
    tarifSecteur1Optam: "21.5",
    tarifHorsSecteur1HorsOptam: "21.5",
    regroupement: "RA0",
  },
  {
    codeCCAM: "HBMD008",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, sans démontage d'éléments",
    tarifSecteur1Optam: "32.25",
    tarifHorsSecteur1HorsOptam: "32.25",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD002",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage d'1 élément",
    tarifSecteur1Optam: "38.7",
    tarifHorsSecteur1HorsOptam: "38.7",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD488",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 2 éléments",
    tarifSecteur1Optam: "45.15",
    tarifHorsSecteur1HorsOptam: "45.15",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD469",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 3 éléments",
    tarifSecteur1Optam: "51.6",
    tarifHorsSecteur1HorsOptam: "51.6",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD110",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 4 éléments",
    tarifSecteur1Optam: "58.05",
    tarifHorsSecteur1HorsOptam: "58.05",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD349",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 5 éléments",
    tarifSecteur1Optam: "64.5",
    tarifHorsSecteur1HorsOptam: "64.5",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD386",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 6 éléments",
    tarifSecteur1Optam: "70.95",
    tarifHorsSecteur1HorsOptam: "70.95",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD339",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 7 éléments",
    tarifSecteur1Optam: "77.4",
    tarifHorsSecteur1HorsOptam: "77.4",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD459",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 8 éléments",
    tarifSecteur1Optam: "83.85",
    tarifHorsSecteur1HorsOptam: "83.85",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD438",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 9 éléments",
    tarifSecteur1Optam: "90.3",
    tarifHorsSecteur1HorsOptam: "90.3",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD481",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 10 éléments",
    tarifSecteur1Optam: "96.75",
    tarifHorsSecteur1HorsOptam: "96.75",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD449",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 11 éléments",
    tarifSecteur1Optam: "103.2",
    tarifHorsSecteur1HorsOptam: "103.2",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD312",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 12 éléments",
    tarifSecteur1Optam: "109.65",
    tarifHorsSecteur1HorsOptam: "109.65",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD289",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 13 éléments",
    tarifSecteur1Optam: "116.1",
    tarifHorsSecteur1HorsOptam: "116.1",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD400",
    designation:
      "Réparation d'une fracture de châssis métallique de prothèse dentaire amovible, avec remontage de 14 éléments",
    tarifSecteur1Optam: "122.55",
    tarifHorsSecteur1HorsOptam: "122.55",
    regroupement: "RE1",
  },
  {
    codeCCAM: "HBMD076",
    designation:
      "Réparation de l'artifice cosmétique d'une dent prothétique par technique directe",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD079",
    designation:
      "Réparation de l'artifice cosmétique d'une dent prothétique par technique indirecte",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD007",
    designation:
      "Réfection des bords et/ou de l'intrados d'une prothèse dentaire amovible partielle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD004",
    designation:
      "Réfection de la base d'une prothèse dentaire amovible complète",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD016",
    designation:
      "Rescellement et/ou recollage d’une ou deux couronnes ou d'un ou deux ancrages d'une prothèse dentaire fixée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD009",
    designation:
      "Rescellement et/ou recollage de 3 couronnes ou plus ou de 3 ancrages ou plus d'une prothèse dentaire fixée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBMD019",
    designation: "Révision des piliers implantoportés d'une prothèse dentaire",
    tarifSecteur1Optam: "18.81",
    tarifHorsSecteur1HorsOptam: "18.81",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBGD005",
    designation: "Ablation d’un ancrage coronoradiculaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBGD011",
    designation:
      "Ablation  d'un bloc métallique coulé ou d'une prothèse dentaire à tenon radiculaire scellé",
    tarifSecteur1Optam: "38.7",
    tarifHorsSecteur1HorsOptam: "38.7",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBGD027",
    designation: "Ablation  d'une prothèse dentaire scellée unitaire",
    tarifSecteur1Optam: "38.7",
    tarifHorsSecteur1HorsOptam: "38.7",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBGD009",
    designation: "Ablation  d'une prothèse dentaire implantoportée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "RPN",
  },
  {
    codeCCAM: "HBED017",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par un dispositif amovible sur 1 arcade",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED019",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par un dispositif amovible sur 2 arcades",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED010",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe bibague sur 1 arcade",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED008",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe bibague sur 2 arcades",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED020",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache sectoriel",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED012",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 1 arcade, sans adjonction de dispositif associé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED026",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 1 arcade sans adjonction de dispositif associé, avec contention de l'autre arcade par dispositif amovible après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED028",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 1 arcade sans adjonction de dispositif associé, avec contention de l'autre arcade par dispositif fixe après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED013",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 1 arcade, avec adjonction de dispositif associé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED027",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 1 arcade avec adjonction de dispositif associé, avec contention de l'autre arcade par dispositif amovible après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED025",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 1 arcade avec adjonction de dispositif associé, avec contention de l'autre arcade par dispositif fixe après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED014",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 2 arcades, sans adjonction de dispositif associé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED002",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 2 arcades, avec adjonction d'un dispositif associé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED018",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 2 arcades, avec adjonction de 2 dispositifs associés",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBED007",
    designation:
      "Réduction d'une malocclusion et/ou d'une malposition alvéolodentaire par dispositif fixe multiattache total sur 2 arcades, avec adjonction de 3 dispositifs associés",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD002",
    designation:
      "Contention d'une arcade dentaire par dispositif amovible, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD009",
    designation:
      "Contention des arcades dentaires par dispositif amovible, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD011",
    designation:
      "Contention des arcades dentaires par dispositif amovible bimaxillaire monobloc [tooth positioner], après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD017",
    designation:
      "Contention des arcades dentaires par dispositif amovible bimaxillaire monobloc [tooth positioner] et dispositif fixe sur 1 arcade, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD018",
    designation:
      "Contention des arcades dentaires par dispositif amovible bimaxillaire monobloc [tooth positioner] et dispositif fixe sur 2 arcades, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD001",
    designation:
      "Contention d'une arcade dentaire par dispositif fixe, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD010",
    designation:
      "Contention des arcades dentaires par dispositif fixe, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBDD013",
    designation:
      "Contention des arcades dentaires par dispositif fixe sur l'une et dispositif amovible sur l'autre, après traitement orthodontique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBGB001",
    designation: "Curetage d'alvéole dentaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGB005",
    designation:
      "Curetage périapical avec résection de l'apex d'une racine dentaire endodontiquement traitée",
    tarifSecteur1Optam: "50.16",
    tarifHorsSecteur1HorsOptam: "50.16",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGB003",
    designation:
      "Curetage périapical avec résection de l'apex et obturation radiculaire d'une incisive ou d'une canine",
    tarifSecteur1Optam: "83.9",
    tarifHorsSecteur1HorsOptam: "83.9",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGB002",
    designation:
      "Curetage périapical avec résection de l'apex et obturation radiculaire d'une prémolaire",
    tarifSecteur1Optam: "98.36",
    tarifHorsSecteur1HorsOptam: "98.36",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBGB004",
    designation:
      "Curetage périapical avec résection de l'apex et obturation radiculaire d'une molaire",
    tarifSecteur1Optam: "132.1",
    tarifHorsSecteur1HorsOptam: "132.1",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBFA006",
    designation: "Gingivectomie sur un secteur de 1 à 3 dents",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA007",
    designation: "Gingivectomie sur un secteur de 4 à 6 dents",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA008",
    designation: "Gingivectomie  sur un secteur de 7 dents ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA005",
    designation:
      "Ostéoplastie soustractive de l'arcade alvéolaire sur un secteur de 1 à 3 dents",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA004",
    designation:
      "Ostéoplastie soustractive de l'arcade alvéolaire sur un secteur de 4 à 6 dents",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA003",
    designation:
      "Ostéoplastie soustractive de l'arcade alvéolaire sur un secteur de 7 dents ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA013",
    designation:
      "Exérèse d'hypertrophie gingivale ou de crête flottante localisée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBFA012",
    designation:
      "Exérèse d'hypertrophie gingivale ou de crête flottante sur une arcade maxillaire ou mandibulaire complète",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBAA338",
    designation: "Allongement coronaire par gingivectomie sur une dent",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBMA004",
    designation: "Régénération parodontale ",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBED023",
    designation:
      "Greffe épithélioconjonctive ou conjonctive sur la gencive, sur un secteur de 1 à 3 dents",
    tarifSecteur1Optam: "79.42",
    tarifHorsSecteur1HorsOptam: "79.42",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBED024",
    designation:
      "Greffe épithélioconjonctive ou conjonctive sur la gencive, sur un sextant",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBMA006",
    designation:
      "Ostéoplastie d'une alvéole dentaire avec comblement par biomatériau",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBMA003",
    designation:
      "Ostéoplastie d'une alvéole dentaire avec comblement par autogreffe osseuse",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBBA003",
    designation:
      "Ostéoplastie additive de l'arcade alvéolaire sur un secteur de 1 à 3 dents",
    tarifSecteur1Optam: "85.69",
    tarifHorsSecteur1HorsOptam: "85.69",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBBA002",
    designation:
      "Ostéoplastie additive de l'arcade alvéolaire sur un secteur de 4 à 6 dents",
    tarifSecteur1Optam: "269.61",
    tarifHorsSecteur1HorsOptam: "269.61",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBBA004",
    designation:
      "Ostéoplastie additive de l'arcade alvéolaire sur un secteur de 7 dents ou plus",
    tarifSecteur1Optam: "298.87",
    tarifHorsSecteur1HorsOptam: "298.87",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBJB001",
    designation: "Évacuation d'abcès parodontal",
    tarifSecteur1Optam: "40",
    tarifHorsSecteur1HorsOptam: "40",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBGB006",
    designation: "Surfaçage radiculaire dentaire sur un sextant",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBJA003",
    designation:
      "Assainissement parodontal [détartrage-surfaçage radiculaire] [DSR] sur 1 sextant",
    tarifSecteur1Optam: "80",
    tarifHorsSecteur1HorsOptam: "80",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBJA171",
    designation:
      "Assainissement parodontal [détartrage-surfaçage radiculaire] [DSR] sur 2 sextants ",
    tarifSecteur1Optam: "160",
    tarifHorsSecteur1HorsOptam: "160",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBJA634",
    designation:
      "Assainissement parodontal [détartrage-surfaçage radiculaire] [DSR] sur 3 sextants ou plus",
    tarifSecteur1Optam: "240",
    tarifHorsSecteur1HorsOptam: "240",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBJA247",
    designation: "Assainissement parodontal par lambeau sur 1 sextant",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBMA001",
    designation:
      "Plastie mucogingivale par lambeau déplacé latéralement, coronairement ou apicalement",
    tarifSecteur1Optam: "75.24",
    tarifHorsSecteur1HorsOptam: "75.24",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HBMD018",
    designation:
      "Séance de préparation [mise en condition] tissulaire des surfaces d'appui muqueux d'une prothèse dentaire, sur une arcade",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "TDS",
  },
  {
    codeCCAM: "HAJA010",
    designation: "Parage et/ou suture d'une plaie muqueuse intrabuccale",
    tarifSecteur1Optam: "32.61",
    tarifHorsSecteur1HorsOptam: "23.3",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAJA007",
    designation:
      "Parage et/ou suture de plaies muqueuses intrabuccales multiples",
    tarifSecteur1Optam: "44.67",
    tarifHorsSecteur1HorsOptam: "32.49",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAJA009",
    designation: "Parage et/ou suture de plaie de la langue",
    tarifSecteur1Optam: "41.58",
    tarifHorsSecteur1HorsOptam: "28.5",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAJA008",
    designation: "Parage et/ou suture de plaie du voile du palais",
    tarifSecteur1Optam: "51.59",
    tarifHorsSecteur1HorsOptam: "36.51",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LCJA004",
    designation:
      "Évacuation de collection de la région des muscles masticateurs, par abord intrabuccal",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCJA002",
    designation:
      "Évacuation de collection de la région des muscles masticateurs, par abord intrabuccal et par abord facial",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCJA003",
    designation:
      "Évacuation de collection périmaxillaire ou périmandibulaire, par abord intrabuccal",
    tarifSecteur1Optam: "20.9",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAJA002",
    designation:
      "Évacuation de collection pelvilinguale, par abord intrabuccal",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAJD004",
    designation: "Évacuation de collection de la base de la langue",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAPA004",
    designation: "Marsupialisation d'un kyste du plancher de la bouche",
    tarifSecteur1Optam: "63.1",
    tarifHorsSecteur1HorsOptam: "54.16",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAPD001",
    designation: "Section de bride muqueuse ou de frein intrabuccal",
    tarifSecteur1Optam: "20.9",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HAPA001",
    designation:
      "Section de bride muqueuse ou de frein intrabuccal avec plastie par lambeau local",
    tarifSecteur1Optam: "35.45",
    tarifHorsSecteur1HorsOptam: "29.34",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAPA003",
    designation: "Libération musculaire et muqueuse d'une ankyloglossie",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA001",
    designation: "Glossoplastie de réduction",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HADA001",
    designation: "Labioglossopexie",
    tarifSecteur1Optam: "154",
    tarifHorsSecteur1HorsOptam: "141.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA026",
    designation: "Pelviglossoplastie",
    tarifSecteur1Optam: "175.56",
    tarifHorsSecteur1HorsOptam: "154.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HADA002",
    designation: "Hyomandibulopexie",
    tarifSecteur1Optam: "173.25",
    tarifHorsSecteur1HorsOptam: "153.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAAA002",
    designation:
      "Approfondissement du vestibule oral [sillon gingivojugal] par section mucopériostée",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAAA001",
    designation:
      "Approfondissement du vestibule oral [sillon gingivojugal] par section mucopériostée avec greffe cutanée ou muqueuse",
    tarifSecteur1Optam: "119.82",
    tarifHorsSecteur1HorsOptam: "104.61",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HAAA003",
    designation:
      "Approfondissement du plancher de la bouche par section musculaire",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDAA002",
    designation: "Véloplastie d'allongement avec lambeau palatin",
    tarifSecteur1Optam: "251.02",
    tarifHorsSecteur1HorsOptam: "233.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA010",
    designation: "Véloplastie secondaire intravélaire",
    tarifSecteur1Optam: "251.02",
    tarifHorsSecteur1HorsOptam: "233.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAND002",
    designation:
      "Destruction de lésion de la muqueuse de la bouche ou de l'oropharynx de moins de 2 cm de grand axe, par voie buccale sans laser",
    tarifSecteur1Optam: "31.2",
    tarifHorsSecteur1HorsOptam: "22.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAND004",
    designation:
      "Destruction de lésion de la muqueuse de la bouche ou de l'oropharynx de moins de 2 cm de grand axe, par voie buccale avec laser",
    tarifSecteur1Optam: "31.33",
    tarifHorsSecteur1HorsOptam: "22.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAND001",
    designation:
      "Destruction de lésion de la muqueuse de la bouche ou de l'oropharynx de 2 cm à 4 cm de grand axe, par voie buccale sans laser",
    tarifSecteur1Optam: "44.67",
    tarifHorsSecteur1HorsOptam: "29.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAND006",
    designation:
      "Destruction de lésion de la muqueuse de la bouche ou de l'oropharynx de 2 cm à 4 cm de grand axe, par voie buccale avec laser",
    tarifSecteur1Optam: "44.86",
    tarifHorsSecteur1HorsOptam: "30.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HAND003",
    designation:
      "Destruction de lésion de la muqueuse de la bouche ou de l'oropharynx de plus de 4 cm de grand axe, par voie buccale sans laser",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAND005",
    designation:
      "Destruction de lésion de la muqueuse de la bouche ou de l'oropharynx de plus de 4 cm de grand axe, par voie buccale avec laser",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA015",
    designation:
      "Exérèse de lésion de la muqueuse de la bouche ou de l'oropharynx de moins de 2 cm de grand axe, par abord intrabuccal",
    tarifSecteur1Optam: "41.12",
    tarifHorsSecteur1HorsOptam: "37.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA032",
    designation:
      "Exérèse de lésion de la muqueuse de la bouche ou de l'oropharynx de 2 cm à 4 cm de grand axe, par abord intrabuccal",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA019",
    designation:
      "Exérèse de lésion de la muqueuse de la bouche ou de l'oropharynx de plus de 4 cm de grand axe, par abord intrabuccal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA021",
    designation:
      "Exérèse non transfixiante de lésion infiltrante de la joue, par abord intrabuccal",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA022",
    designation: "Exérèse transfixiante de lésion de la joue, par abord facial",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA034",
    designation:
      "Exérèse de kyste du plancher de la bouche, par abord intrabuccal",
    tarifSecteur1Optam: "114.15",
    tarifHorsSecteur1HorsOptam: "83.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA009",
    designation:
      "Exérèse de kyste du plancher de la bouche, par abord intrabuccal et par abord facial",
    tarifSecteur1Optam: "174.41",
    tarifHorsSecteur1HorsOptam: "171.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA020",
    designation: "Uvulectomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA022",
    designation: "Uvulovélectomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA021",
    designation: "Uvulovélectomie avec palatectomie partielle",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA023",
    designation:
      "Glossectomie partielle de langue mobile, par abord intrabuccal",
    tarifSecteur1Optam: "116.27",
    tarifHorsSecteur1HorsOptam: "111.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA001",
    designation: "Glossectomie partielle de base, par cervicotomie",
    tarifSecteur1Optam: "326.48",
    tarifHorsSecteur1HorsOptam: "259.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA005",
    designation:
      "Exérèse partielle de la base de la langue et/ou du sillon amygdaloglosse, par abord intrabuccal",
    tarifSecteur1Optam: "193.27",
    tarifHorsSecteur1HorsOptam: "182.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA013",
    designation: "Glossectomie totale de langue mobile, par abord intrabuccal",
    tarifSecteur1Optam: "212.52",
    tarifHorsSecteur1HorsOptam: "193.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA003",
    designation:
      "Glossectomie totale de langue mobile, par abord cervicofacial",
    tarifSecteur1Optam: "341.11",
    tarifHorsSecteur1HorsOptam: "268.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA012",
    designation: "Glossectomie totale de base",
    tarifSecteur1Optam: "472.78",
    tarifHorsSecteur1HorsOptam: "344.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA029",
    designation: "Glossectomie totale de base de langue et de langue mobile",
    tarifSecteur1Optam: "529.76",
    tarifHorsSecteur1HorsOptam: "377.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA016",
    designation: "Glossectomie totale avec pharyngolaryngectomie totale",
    tarifSecteur1Optam: "736.89",
    tarifHorsSecteur1HorsOptam: "690.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA007",
    designation: "Pelvectomie orale partielle, par abord intrabuccal",
    tarifSecteur1Optam: "193.27",
    tarifHorsSecteur1HorsOptam: "182.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA006",
    designation: "Pelviglossectomie de langue mobile, par abord intrabuccal",
    tarifSecteur1Optam: "234.85",
    tarifHorsSecteur1HorsOptam: "206.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA027",
    designation: "Pelviglossectomie de langue mobile, par abord cervicofacial",
    tarifSecteur1Optam: "391.16",
    tarifHorsSecteur1HorsOptam: "297.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA033",
    designation:
      "Pelvimandibulectomie non interruptrice, par abord intrabuccal",
    tarifSecteur1Optam: "310.31",
    tarifHorsSecteur1HorsOptam: "272.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA024",
    designation:
      "Pelvimandibulectomie non interruptrice, par abord cervicofacial",
    tarifSecteur1Optam: "475.09",
    tarifHorsSecteur1HorsOptam: "367.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA004",
    designation: "Pelvimandibulectomie interruptrice, par abord cervicofacial",
    tarifSecteur1Optam: "558.25",
    tarifHorsSecteur1HorsOptam: "464.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA002",
    designation:
      "Pelvi-glosso-mandibulectomie non interruptrice, par abord intrabuccal",
    tarifSecteur1Optam: "338.8",
    tarifHorsSecteur1HorsOptam: "288.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA026",
    designation:
      "Pelvi-glosso-mandibulectomie non interruptrice, par abord cervicofacial",
    tarifSecteur1Optam: "475.09",
    tarifHorsSecteur1HorsOptam: "367.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA017",
    designation:
      "Pelvi-glosso-mandibulectomie interruptrice, par abord cervicofacial",
    tarifSecteur1Optam: "586.74",
    tarifHorsSecteur1HorsOptam: "480.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA018",
    designation:
      "Fermeture d'une communication buccosinusienne par lambeau de glissement vestibulojugal, par abord intrabuccal",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA025",
    designation:
      "Fermeture d'une communication buccosinusienne par lambeau de transposition du corps adipeux de la joue, par abord intrabuccal",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA013",
    designation:
      "Fermeture d'une communication buccosinusienne et/ou bucconasale par lambeau palatin, par abord intrabuccal",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA009",
    designation:
      "Fermeture d'une communication buccosinusienne et/ou bucconasale par lambeau de muscle temporal, par abord intrabuccal et par abord facial",
    tarifSecteur1Optam: "219.45",
    tarifHorsSecteur1HorsOptam: "219.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA012",
    designation: "Fermeture d'orostome ou de pharyngostome",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA021",
    designation: "Fermeture d'une fente labiale supérieure médiane",
    tarifSecteur1Optam: "359.46",
    tarifHorsSecteur1HorsOptam: "261.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA005",
    designation: "Fermeture d'une fente labiale inférieure médiane",
    tarifSecteur1Optam: "234.84",
    tarifHorsSecteur1HorsOptam: "234.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA015",
    designation:
      "Fermeture primaire unilatérale d'une fente labiale sans fente osseuse alvéolomaxillaire, sans réparation complète de la déformation nasale [Chéiloplastie primaire unilatérale pour fente labiale]",
    tarifSecteur1Optam: "275.09",
    tarifHorsSecteur1HorsOptam: "212.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA023",
    designation:
      "Fermeture primaire unilatérale d'une fente labiale sans fente osseuse alvéolomaxillaire, avec réparation complète de la déformation nasale [Chéilorhinoplastie primaire unilatérale pour fente labiale]",
    tarifSecteur1Optam: "347.41",
    tarifHorsSecteur1HorsOptam: "298.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA008",
    designation:
      "Fermeture primaire bilatérale d'une fente labiale sans fente osseuse alvéolomaxillaire, sans réparation complète de la déformation nasale [Chéiloplastie primaire bilatérale pour fente labiale]",
    tarifSecteur1Optam: "433.91",
    tarifHorsSecteur1HorsOptam: "330.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA014",
    designation:
      "Fermeture primaire bilatérale d'une fente labiale sans fente osseuse alvéolomaxillaire, avec réparation complète de la déformation nasale [Chéilorhinoplastie primaire bilatérale pour fente labiale]",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "384.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA019",
    designation:
      "Fermeture labiale primaire unilatérale d'une fente labio-alvéolo-maxillaire, sans correction complète de la déformation nasale [Chéiloplastie unilatérale pour fente labio-alvéolo-maxillaire]",
    tarifSecteur1Optam: "281.59",
    tarifHorsSecteur1HorsOptam: "281.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA001",
    designation:
      "Fermeture labiale primaire unilatérale d'une fente labio-alvéolo-maxillaire, avec correction complète de la déformation nasale [Chéilorhinoplastie unilatérale pour fente labio-alvéolo-maxillaire]",
    tarifSecteur1Optam: "418.31",
    tarifHorsSecteur1HorsOptam: "347.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA006",
    designation:
      "Fermeture labiale primaire bilatérale d'une fente labio-alvéolo-maxillaire, sans correction complète de la déformation nasale [Chéiloplastie bilatérale pour fente labio-alvéolo-maxillaire]",
    tarifSecteur1Optam: "465.1",
    tarifHorsSecteur1HorsOptam: "375.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA020",
    designation:
      "Fermeture labiale primaire bilatérale d'une fente labio-alvéolo-maxillaire, avec correction complète de la déformation nasale [Chéilorhinoplastie bilatérale pour fente labio-alvéolo-maxillaire]",
    tarifSecteur1Optam: "553.02",
    tarifHorsSecteur1HorsOptam: "426.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA006",
    designation:
      "Correction primaire unilatérale complète d'une fente labioalvéolaire ou labiomaxillaire",
    tarifSecteur1Optam: "304.16",
    tarifHorsSecteur1HorsOptam: "246.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA017",
    designation:
      "Correction primaire bilatérale complète d'une fente labioalvéolaire ou labiomaxillaire",
    tarifSecteur1Optam: "309.71",
    tarifHorsSecteur1HorsOptam: "309.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HACA001",
    designation:
      "Adhésion labiale pour fente labio-maxillo-palatine unilatérale",
    tarifSecteur1Optam: "159.53",
    tarifHorsSecteur1HorsOptam: "145.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HACA002",
    designation:
      "Adhésion labiale pour fente labio-maxillo-palatine bilatérale",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "234.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASD006",
    designation:
      "Fermeture primaire d'une fente du palais osseux [Uranoplastie pour fente orofaciale]",
    tarifSecteur1Optam: "249.57",
    tarifHorsSecteur1HorsOptam: "232.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDSD002",
    designation:
      "Fermeture primaire d'une fente vélaire [Staphylorraphie pour fente orofaciale]",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "237.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASD007",
    designation:
      "Fermeture primaire d'une fente palatovélaire [Uranostaphylorraphie pour fente orofaciale]",
    tarifSecteur1Optam: "334.65",
    tarifHorsSecteur1HorsOptam: "281.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA017",
    designation:
      "Fermeture unilatérale de la lèvre et du palais osseux d'une fente labio-maxillo-palatine, en un temps [Chéilo-rhino-uranoplastie unilatérale pour fente orofaciale]",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "442.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA004",
    designation:
      "Fermeture bilatérale de la lèvre et du palais osseux d'une fente labio-maxillo-palatine, en un temps [Chéilo-rhino-uranoplastie bilatérale pour fente orofaciale]",
    tarifSecteur1Optam: "529.34",
    tarifHorsSecteur1HorsOptam: "529.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA022",
    designation:
      "Fermeture unilatérale de la lèvre et du voile du palais d'une fente labio-maxillo-palatine, en un temps [Chéilorhinoplastie unilatérale avec staphylorraphie pour fente orofaciale]",
    tarifSecteur1Optam: "508.35",
    tarifHorsSecteur1HorsOptam: "444.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA016",
    designation:
      "Fermeture bilatérale de la lèvre et du voile du palais d'une fente labio-maxillo-palatine, en un temps [Chéilorhinoplastie bilatérale avec staphylorraphie pour fente orofaciale]",
    tarifSecteur1Optam: "643.06",
    tarifHorsSecteur1HorsOptam: "530.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA007",
    designation:
      "Fermeture unilatérale complète d'une fente labio-maxillo-palatine, en un temps [Chéilo-rhino-urano-staphylorraphie unilatérale pour fente orofaciale]",
    tarifSecteur1Optam: "535.3",
    tarifHorsSecteur1HorsOptam: "459.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA010",
    designation:
      "Fermeture bilatérale complète d'une fente labio-maxillo-palatine, en un temps [Chéilo-rhino-urano-staphylorraphie bilatérale pour fente orofaciale]",
    tarifSecteur1Optam: "670.01",
    tarifHorsSecteur1HorsOptam: "546.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA012",
    designation:
      "Plastie cutanéomuqueuse de symétrisation labiale pour séquelle d'une fente labio-maxillo-palatine",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "150.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA025",
    designation:
      "Correction de séquelle narinaire d'une fente labio-maxillo-palatine unilatérale",
    tarifSecteur1Optam: "209.86",
    tarifHorsSecteur1HorsOptam: "191.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAMA026",
    designation:
      "Correction de séquelle narinaire d'une fente labio-maxillo-palatine bilatérale",
    tarifSecteur1Optam: "248.15",
    tarifHorsSecteur1HorsOptam: "214.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA024",
    designation:
      "Correction de séquelle labionarinaire d'une fente labio-maxillo-palatine unilatérale",
    tarifSecteur1Optam: "334.65",
    tarifHorsSecteur1HorsOptam: "299.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAMA025",
    designation:
      "Correction de séquelle labionarinaire d'une fente labio-maxillo-palatine bilatérale",
    tarifSecteur1Optam: "410.51",
    tarifHorsSecteur1HorsOptam: "378.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASD002",
    designation:
      "Fermeture secondaire unilatérale d'une fente alvéolaire par gingivopériostoplastie ou lambeau muqueux",
    tarifSecteur1Optam: "235.39",
    tarifHorsSecteur1HorsOptam: "224.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASD004",
    designation:
      "Fermeture secondaire bilatérale d'une fente alvéolaire par gingivopériostoplastie ou lambeau muqueux",
    tarifSecteur1Optam: "317.63",
    tarifHorsSecteur1HorsOptam: "315.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASD005",
    designation:
      "Fermeture secondaire unilatérale d'une fente alvéolaire par gingivopériostoplastie ou lambeau muqueux, avec autogreffe osseuse",
    tarifSecteur1Optam: "294.94",
    tarifHorsSecteur1HorsOptam: "293.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASD001",
    designation:
      "Fermeture secondaire bilatérale d'une fente alvéolaire par gingivopériostoplastie ou lambeau muqueux, avec autogreffe osseuse",
    tarifSecteur1Optam: "397.04",
    tarifHorsSecteur1HorsOptam: "361.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA002",
    designation:
      "Fermeture d'une fistule bucconasale séquellaire d'une fente orofaciale par plastie locale",
    tarifSecteur1Optam: "238.7",
    tarifHorsSecteur1HorsOptam: "199.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HASA003",
    designation:
      "Fermeture d'une fistule bucconasale séquellaire d'une fente orofaciale par plastie locorégionale ou lambeau de langue",
    tarifSecteur1Optam: "275.66",
    tarifHorsSecteur1HorsOptam: "221.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA006",
    designation:
      "Pharyngoplastie ou sphinctéroplastie pour correction de séquelle phonétique d'une fente palatine",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA028",
    designation:
      "Ostéotomie maxillaire de type Le Fort I pour séquelle d'une fente orofaciale, par abord intrabuccal",
    tarifSecteur1Optam: "475.74",
    tarifHorsSecteur1HorsOptam: "433.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA024",
    designation:
      "Ostéotomie d'un segment du maxillaire pour correction d'une malposition fragmentaire séquellaire d'une fente orofaciale, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GALP002",
    designation:
      "Pose préopératoire d'un appareillage orthopédique de correction d'une déformation nasale, pour fente orofaciale",
    tarifSecteur1Optam: "268.8",
    tarifHorsSecteur1HorsOptam: "268.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HALD004",
    designation:
      "Pose d'une plaque palatine non active [passive] pour fente orofaciale ou division palatine",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HALD003",
    designation:
      "Pose d'une plaque palatine active [orthopédique] pour fente orofaciale",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HAJD003",
    designation:
      "Pansement intrabuccal sous anesthésie générale, dans les suites d'un acte sur la cavité orale",
    tarifSecteur1Optam: "43.96",
    tarifHorsSecteur1HorsOptam: "38.66",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LCGA002",
    designation:
      "Ablation de corps étranger projeté dans les tissus mous buccofaciaux, par abord intrabuccal",
    tarifSecteur1Optam: "79.41",
    tarifHorsSecteur1HorsOptam: "63.61",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HASD003",
    designation: "Hémostase secondaire à une électrocoagulation intrabuccale",
    tarifSecteur1Optam: "48.92",
    tarifHorsSecteur1HorsOptam: "36.44",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBSD001",
    designation:
      "Hémostase gingivoalvéolaire secondaire à une avulsion dentaire",
    tarifSecteur1Optam: "24.11",
    tarifHorsSecteur1HorsOptam: "22.05",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD012",
    designation:
      "Pose de dispositif intrabuccal d'expansion tissulaire pour rehaussement de crête alvéolaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HALD001",
    designation: "Pose de dispositif intrabuccal d'expansion labiojugale",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HALD005",
    designation:
      "Pose d'une prothèse intrabuccale pour perte de substance du palais dur et du palais mou",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HALD002",
    designation: "Pose d'un appareillage antiautomorsure",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HCLD001",
    designation: "Cathétérisme de canal salivaire sans sialographie",
    tarifSecteur1Optam: "43.89",
    tarifHorsSecteur1HorsOptam: "37.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCAE201",
    designation:
      "Dilatation de sténose du conduit d'une glande salivaire par endoscopie [sialendoscopie] ",
    tarifSecteur1Optam: "105.49",
    tarifHorsSecteur1HorsOptam: "105.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCAE192",
    designation:
      "Dilatation unilatérale ou bilatérale de sténose du conduit de plusieurs glandes salivaires par endoscopie [sialendoscopie]",
    tarifSecteur1Optam: "127.82",
    tarifHorsSecteur1HorsOptam: "127.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCCA002",
    designation: "Suture de plaie de conduit salivaire",
    tarifSecteur1Optam: "132.44",
    tarifHorsSecteur1HorsOptam: "103.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCEA001",
    designation:
      "Transposition unilatérale ou bilatérale des conduits submandibulaires [de Wharton]",
    tarifSecteur1Optam: "166.32",
    tarifHorsSecteur1HorsOptam: "149.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCCA001",
    designation:
      "Dérivation du conduit parotidien [de Sténon] pour suppléance lacrymale",
    tarifSecteur1Optam: "220.97",
    tarifHorsSecteur1HorsOptam: "215.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCCD001",
    designation:
      "Dérivation oropharyngée bilatérale du conduit parotidien [de Sténon], par voie buccale",
    tarifSecteur1Optam: "319.76",
    tarifHorsSecteur1HorsOptam: "303.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCJA001",
    designation: "Évacuation de collection salivaire, par abord direct",
    tarifSecteur1Optam: "63.91",
    tarifHorsSecteur1HorsOptam: "45.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCPA001",
    designation: "Marsupialisation de kyste de glande salivaire",
    tarifSecteur1Optam: "63.91",
    tarifHorsSecteur1HorsOptam: "45.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCSA001",
    designation:
      "Fermeture de fistule salivaire parenchymateuse ou juxtaglandulaire",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGE188",
    designation:
      "Ablation de calcul canalaire d’une glande salivaire par endoscopie [sialendoscopie]",
    tarifSecteur1Optam: "231",
    tarifHorsSecteur1HorsOptam: "231",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCGE271",
    designation:
      "Ablation unilatérale ou bilatérale de calcul canalaire de plusieurs glandes salivaires par endoscopie [sialendoscopie]",
    tarifSecteur1Optam: "257.95",
    tarifHorsSecteur1HorsOptam: "257.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCGA003",
    designation:
      "Ablation de calcul du bassinet de la glande submandibulaire, par abord intrabuccal sans guidage endoscopique",
    tarifSecteur1Optam: "107.06",
    tarifHorsSecteur1HorsOptam: "88.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGA223",
    designation:
      "Ablation de calcul du bassinet de la glande submandibulaire, par abord intrabuccal avec guidage endoscopique",
    tarifSecteur1Optam: "231",
    tarifHorsSecteur1HorsOptam: "231",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGA001",
    designation:
      "Ablation de calcul canalaire d'une glande salivaire, par abord intrabuccal sans guidage endoscopique",
    tarifSecteur1Optam: "70.07",
    tarifHorsSecteur1HorsOptam: "49.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGA002",
    designation:
      "Ablation de calcul canalaire d'une glande salivaire, par abord cervicofacial sans guidage endoscopique",
    tarifSecteur1Optam: "167.86",
    tarifHorsSecteur1HorsOptam: "141.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGA130",
    designation:
      "Ablation de calcul canalaire de la glande parotide par abord cervicofacial ou intrabuccal avec guidage endoscopique",
    tarifSecteur1Optam: "269.5",
    tarifHorsSecteur1HorsOptam: "269.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGA374",
    designation:
      "Ablation unilatérale de calcul canalaire de la glande submandibulaire par abord intrabuccal avec guidage endoscopique",
    tarifSecteur1Optam: "202.51",
    tarifHorsSecteur1HorsOptam: "202.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCGA109",
    designation:
      "Ablation bilatérale de calcul canalaire de la glande submandibulaire par abord intrabuccal avec guidage endoscopique",
    tarifSecteur1Optam: "263.34",
    tarifHorsSecteur1HorsOptam: "263.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCNM900",
    designation: "Lithotritie de la glande parotide",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCNM901",
    designation: "Lithotritie de la glande submandibulaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HCFA007",
    designation:
      "Exérèse de petite glande salivaire [glande salivaire accessoire]",
    tarifSecteur1Optam: "117.04",
    tarifHorsSecteur1HorsOptam: "111.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA003",
    designation: "Exérèse de glande sublinguale, par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA011",
    designation:
      "Exérèse de glande submandibulaire [Submandibulectomie] [Sousmaxillectomie], par abord cervicofacial",
    tarifSecteur1Optam: "187.11",
    tarifHorsSecteur1HorsOptam: "152.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA001",
    designation:
      "Exérèse de glande submandibulaire [Submandibulectomie] [Sousmaxillectomie] élargie à sa loge, par abord cervicofacial",
    tarifSecteur1Optam: "230.23",
    tarifHorsSecteur1HorsOptam: "203.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA004",
    designation: "Parotidectomie partielle sans dissection du nerf facial",
    tarifSecteur1Optam: "254.1",
    tarifHorsSecteur1HorsOptam: "230.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA008",
    designation:
      "Parotidectomie partielle avec dissection et conservation du nerf facial",
    tarifSecteur1Optam: "483.56",
    tarifHorsSecteur1HorsOptam: "437.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA009",
    designation:
      "Parotidectomie totale avec dissection et conservation du nerf facial",
    tarifSecteur1Optam: "589.05",
    tarifHorsSecteur1HorsOptam: "498.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA002",
    designation: "Parotidectomie totale avec résection du nerf facial",
    tarifSecteur1Optam: "435.82",
    tarifHorsSecteur1HorsOptam: "410.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA010",
    designation:
      "Parotidectomie totale avec résection du nerf facial et réparation immédiate par suture ou greffe",
    tarifSecteur1Optam: "609.07",
    tarifHorsSecteur1HorsOptam: "550.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA006",
    designation:
      "Parotidectomie totale élargie au méat acoustique externe [conduit auditif externe] et/ou à la mandibule",
    tarifSecteur1Optam: "609.07",
    tarifHorsSecteur1HorsOptam: "536.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA005",
    designation:
      "Parotidectomie totale avec exérèse du prolongement [processus] parapharyngien, par abord cervicofacial",
    tarifSecteur1Optam: "662.2",
    tarifHorsSecteur1HorsOptam: "541.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA013",
    designation:
      "Parotidectomie totale par abord cervicofacial, avec exérèse du prolongement [processus] parapharyngien par abord pharyngé",
    tarifSecteur1Optam: "703.78",
    tarifHorsSecteur1HorsOptam: "565.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HCFA012",
    designation:
      "Exérèse de récidive de tumeur de la glande parotide avec dissection et conservation du nerf facial",
    tarifSecteur1Optam: "703.78",
    tarifHorsSecteur1HorsOptam: "565.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDJD001",
    designation: "Évacuation de collection rétrostylienne, par voie buccale",
    tarifSecteur1Optam: "43.89",
    tarifHorsSecteur1HorsOptam: "43.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDJD002",
    designation:
      "Évacuation de collection péritonsillaire [périamygdalienne] et/ou rétropharyngienne, par voie buccale",
    tarifSecteur1Optam: "46.2",
    tarifHorsSecteur1HorsOptam: "44.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDJA001",
    designation:
      "Évacuation de collection péritonsillaire [périamygdalienne] et/ou rétropharyngienne, par abord intrabuccal",
    tarifSecteur1Optam: "56.98",
    tarifHorsSecteur1HorsOptam: "50.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDGE002",
    designation:
      "Ablation de corps étranger de l'oropharynx, par laryngoscopie indirecte",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HDGE001",
    designation:
      "Ablation de corps étranger de l'oropharynx, par endoscopie rigide",
    tarifSecteur1Optam: "66.22",
    tarifHorsSecteur1HorsOptam: "55.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDGA001",
    designation:
      "Ablation de corps étranger pharyngoœsophagien, par cervicotomie",
    tarifSecteur1Optam: "233.68",
    tarifHorsSecteur1HorsOptam: "233.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDPE002",
    designation:
      "Myotomie du faisceau cricopharyngien du muscle constricteur inférieur du pharynx, par endoscopie sans laser",
    tarifSecteur1Optam: "171.71",
    tarifHorsSecteur1HorsOptam: "169.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDPE001",
    designation:
      "Myotomie du faisceau cricopharyngien du muscle constricteur inférieur du pharynx, par endoscopie avec laser",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDPA001",
    designation:
      "Myotomie du faisceau cricopharyngien du muscle constricteur inférieur du pharynx, par cervicotomie",
    tarifSecteur1Optam: "200.97",
    tarifHorsSecteur1HorsOptam: "186.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA007",
    designation: "Vélopharyngoplastie ou pharyngoplastie avec lambeau pharyngé",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA009",
    designation:
      "Vélopharyngoplastie ou pharyngoplastie avec lambeau pharyngé et lambeau palatin",
    tarifSecteur1Optam: "370.37",
    tarifHorsSecteur1HorsOptam: "346.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA008",
    designation: "Pharyngoplastie pour sténose vélo-oro-rhino-pharyngée",
    tarifSecteur1Optam: "301.84",
    tarifHorsSecteur1HorsOptam: "262.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA005",
    designation: "Uvulopharyngoplastie sans laser",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA001",
    designation: "Uvulopharyngoplastie sans laser avec turbinectomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA004",
    designation: "Uvulopharyngoplastie sans laser avec septoplastie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDMA002",
    designation: "Uvulopharyngoplastie avec laser",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDAA003",
    designation: "Plastie d'élargissement de l'hypopharynx",
    tarifSecteur1Optam: "267.96",
    tarifHorsSecteur1HorsOptam: "208.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDNE002",
    designation:
      "Destruction de lésion de l'hypopharynx sans laser, par endoscopie",
    tarifSecteur1Optam: "103.18",
    tarifHorsSecteur1HorsOptam: "92.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDNE001",
    designation:
      "Destruction de lésion de l'hypopharynx avec laser, par endoscopie",
    tarifSecteur1Optam: "101.64",
    tarifHorsSecteur1HorsOptam: "91.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFE002",
    designation: "Exérèse de lésion de l'hypopharynx, par endoscopie",
    tarifSecteur1Optam: "93.94",
    tarifHorsSecteur1HorsOptam: "86.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA018",
    designation: "Exérèse de lésion du pharynx, par pharyngotomie",
    tarifSecteur1Optam: "239.47",
    tarifHorsSecteur1HorsOptam: "226.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFE003",
    designation:
      "Résection et/ou dilatation de sténose du pharynx, par endoscopie sans laser",
    tarifSecteur1Optam: "106.26",
    tarifHorsSecteur1HorsOptam: "101.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFE001",
    designation:
      "Résection et/ou dilatation de sténose du pharynx, par endoscopie avec laser",
    tarifSecteur1Optam: "123.97",
    tarifHorsSecteur1HorsOptam: "112.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA015",
    designation:
      "Résection de diverticule pharyngoœsophagien avec myotomie extramuqueuse, par cervicotomie",
    tarifSecteur1Optam: "318.01",
    tarifHorsSecteur1HorsOptam: "272.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA014",
    designation:
      "Résection de récidive de diverticule pharyngoœsophagien, par cervicotomie",
    tarifSecteur1Optam: "309.54",
    tarifHorsSecteur1HorsOptam: "267.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA002",
    designation:
      "Oropharyngectomie latérale avec amygdalectomie, par abord intrabuccal",
    tarifSecteur1Optam: "193.27",
    tarifHorsSecteur1HorsOptam: "182.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA008",
    designation: "Oropharyngectomie latérale, par cervicotomie",
    tarifSecteur1Optam: "409.64",
    tarifHorsSecteur1HorsOptam: "356.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA009",
    designation: "Oropharyngectomie postérieure, par abord intrabuccal",
    tarifSecteur1Optam: "187.88",
    tarifHorsSecteur1HorsOptam: "179.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA017",
    designation: "Oropharyngectomie postérieure, par cervicotomie",
    tarifSecteur1Optam: "527.45",
    tarifHorsSecteur1HorsOptam: "424.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA019",
    designation: "Oropharyngectomie par mandibulotomie",
    tarifSecteur1Optam: "613.69",
    tarifHorsSecteur1HorsOptam: "474.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA007",
    designation:
      "Oropharyngectomie par mandibulotomie avec pharyngolaryngectomie totale",
    tarifSecteur1Optam: "908.6",
    tarifHorsSecteur1HorsOptam: "761.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA004",
    designation:
      "Oropharyngectomie avec mandibulectomie partielle non interruptrice, par abord direct",
    tarifSecteur1Optam: "390.39",
    tarifHorsSecteur1HorsOptam: "344.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA011",
    designation:
      "Oropharyngectomie avec mandibulectomie partielle interruptrice, par abord cervicofacial",
    tarifSecteur1Optam: "558.25",
    tarifHorsSecteur1HorsOptam: "494.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA013",
    designation:
      "Oropharyngectomie avec mandibulectomie partielle interruptrice et pharyngolaryngectomie totale",
    tarifSecteur1Optam: "908.6",
    tarifHorsSecteur1HorsOptam: "761.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA016",
    designation: "Pharyngectomie latérale, par cervicotomie",
    tarifSecteur1Optam: "354.97",
    tarifHorsSecteur1HorsOptam: "324.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA012",
    designation: "Pharyngectomie postérieure, par cervicotomie",
    tarifSecteur1Optam: "504.35",
    tarifHorsSecteur1HorsOptam: "411.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA005",
    designation: "Pharyngolaryngectomie totale",
    tarifSecteur1Optam: "526.68",
    tarifHorsSecteur1HorsOptam: "494.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA003",
    designation: "Pharyngolaryngectomie totale étendue à la base de langue",
    tarifSecteur1Optam: "592.13",
    tarifHorsSecteur1HorsOptam: "567.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDFA010",
    designation: "Pharyngolaryngectomie totale circulaire",
    tarifSecteur1Optam: "569.03",
    tarifHorsSecteur1HorsOptam: "518.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDCA002",
    designation: "Suture de plaie du pharynx, par cervicotomie",
    tarifSecteur1Optam: "272.58",
    tarifHorsSecteur1HorsOptam: "175.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HDCA001",
    designation: "Pharyngostomie cutanée",
    tarifSecteur1Optam: "231",
    tarifHorsSecteur1HorsOptam: "186.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HQRP001",
    designation:
      "Séance de rééducation de la déglutition, avec contrôle instrumental",
    tarifSecteur1Optam: "46.19",
    tarifHorsSecteur1HorsOptam: "46.19",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEAH001",
    designation: "Dilatation de l'œsophage, avec guidage radiologique",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEAE002",
    designation: "Dilatation antérograde de l'œsophage, par endoscopie rigide",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEAE003",
    designation: "Dilatation antérograde de l'œsophage, par fibroscopie",
    tarifSecteur1Optam: "122",
    tarifHorsSecteur1HorsOptam: "122",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HELH001",
    designation:
      "Pose d'une endoprothèse de l'œsophage, avec guidage radiologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HELE002",
    designation: "Pose d'une endoprothèse de l'œsophage, par endoscopie",
    tarifSecteur1Optam: "170.63",
    tarifHorsSecteur1HorsOptam: "170.63",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEGE003",
    designation: "Ablation d'une endoprothèse de l'œsophage, par endoscopie",
    tarifSecteur1Optam: "184.81",
    tarifHorsSecteur1HorsOptam: "184.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEKE001",
    designation: "Changement d'une endoprothèse de l'œsophage, par endoscopie",
    tarifSecteur1Optam: "216.57",
    tarifHorsSecteur1HorsOptam: "216.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEGE001",
    designation:
      "Ablation de corps étranger de l'œsophage, par endoscopie rigide",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HENE494",
    designation:
      "Destruction localisée de la muqueuse de l’œsophage par radiofréquence, par œso-gastro-duodénoscopie ",
    tarifSecteur1Optam: "210.3",
    tarifHorsSecteur1HorsOptam: "210.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HENE900",
    designation:
      "Séance de destruction d'une hétérotopie de la muqueuse de l'œsophage inférieur, par endoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HELE900",
    designation:
      "Séance d'injection de toxine botulique au niveau du sphincter inférieur de l'œsophage, par endoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HELE001",
    designation:
      "Pose de guide intraœsophagien pour curiethérapie [endobrachytherapy], par fibroscopie",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEME900",
    designation: "Correction d'un reflux gastroœsophagien, par endoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HECA001",
    designation:
      "Suture de plaie ou de perforation de l'œsophage, par cervicotomie",
    tarifSecteur1Optam: "250.99",
    tarifHorsSecteur1HorsOptam: "215.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HECC001",
    designation:
      "Suture de plaie ou de perforation de l'œsophage, par thoracoscopie",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "328.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HECA002",
    designation:
      "Suture de plaie ou de perforation de l'œsophage, par thoracotomie",
    tarifSecteur1Optam: "373.64",
    tarifHorsSecteur1HorsOptam: "348.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HECA004",
    designation:
      "Suture de plaie ou de perforation de l'œsophage, par laparotomie",
    tarifSecteur1Optam: "330.39",
    tarifHorsSecteur1HorsOptam: "323.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA005",
    designation: "Fermeture de fistule cutanée de l'œsophage, par cervicotomie",
    tarifSecteur1Optam: "219.08",
    tarifHorsSecteur1HorsOptam: "197.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA001",
    designation: "Fermeture de fistule œsotrachéale acquise, par cervicotomie",
    tarifSecteur1Optam: "618.96",
    tarifHorsSecteur1HorsOptam: "490.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA008",
    designation:
      "Fermeture de fistule œsotrachéale acquise avec résection-anastomose de la trachée, par cervicotomie",
    tarifSecteur1Optam: "771.39",
    tarifHorsSecteur1HorsOptam: "683.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA004",
    designation:
      "Fermeture de fistule œsotrachéale acquise, par cervicothoracotomie ou par thoracotomie",
    tarifSecteur1Optam: "663.62",
    tarifHorsSecteur1HorsOptam: "621.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA007",
    designation:
      "Fermeture de fistule œsotrachéale acquise avec résection de la trachée, par cervicothoracotomie ou par thoracotomie",
    tarifSecteur1Optam: "869.87",
    tarifHorsSecteur1HorsOptam: "869.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HECA005",
    designation: "Œsophagostomie cutanée, par cervicotomie",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "193.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HECA003",
    designation:
      "Exclusion bipolaire de l'œsophage, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "581.38",
    tarifHorsSecteur1HorsOptam: "433.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA003",
    designation: "Fermeture d'œsophagostomie cutanée, par abord direct",
    tarifSecteur1Optam: "228.54",
    tarifHorsSecteur1HorsOptam: "228.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPC001",
    designation:
      "Œso-cardio-myotomie extramuqueuse sans réalisation de procédé antireflux, par thoracoscopie",
    tarifSecteur1Optam: "377.58",
    tarifHorsSecteur1HorsOptam: "377.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA001",
    designation:
      "Œso-cardio-myotomie extramuqueuse sans réalisation de procédé antireflux, par thoracotomie",
    tarifSecteur1Optam: "341.39",
    tarifHorsSecteur1HorsOptam: "341.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPC002",
    designation:
      "Œso-cardio-myotomie extramuqueuse sans réalisation de procédé antireflux, par cœlioscopie",
    tarifSecteur1Optam: "373.64",
    tarifHorsSecteur1HorsOptam: "360.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA006",
    designation:
      "Œso-cardio-myotomie extramuqueuse sans réalisation de procédé antireflux, par laparotomie",
    tarifSecteur1Optam: "343.16",
    tarifHorsSecteur1HorsOptam: "330.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPC004",
    designation:
      "Œso-cardio-myotomie extramuqueuse avec réalisation de procédé antireflux, par thoracoscopie",
    tarifSecteur1Optam: "416.23",
    tarifHorsSecteur1HorsOptam: "416.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA003",
    designation:
      "Œso-cardio-myotomie extramuqueuse avec réalisation de procédé antireflux, par thoracotomie",
    tarifSecteur1Optam: "396.09",
    tarifHorsSecteur1HorsOptam: "396.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPC003",
    designation:
      "Œso-cardio-myotomie extramuqueuse avec réalisation de procédé antireflux, par cœlioscopie",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "436.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA002",
    designation:
      "Œso-cardio-myotomie extramuqueuse avec réalisation de procédé antireflux, par laparotomie",
    tarifSecteur1Optam: "426.11",
    tarifHorsSecteur1HorsOptam: "378.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEDA001",
    designation: "Diverticulopexie de l'œsophage, par cervicotomie",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "213.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFC001",
    designation: "Résection de diverticule de l'œsophage, par thoracoscopie",
    tarifSecteur1Optam: "383.57",
    tarifHorsSecteur1HorsOptam: "354.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA015",
    designation: "Résection de diverticule de l'œsophage, par thoracotomie",
    tarifSecteur1Optam: "365.14",
    tarifHorsSecteur1HorsOptam: "343.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFC002",
    designation:
      "Excision de tumeur de l'œsophage sans interruption de la continuité, par thoracoscopie",
    tarifSecteur1Optam: "402.71",
    tarifHorsSecteur1HorsOptam: "365.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA019",
    designation:
      "Excision de tumeur de l'œsophage sans interruption de la continuité, par thoracotomie",
    tarifSecteur1Optam: "401.29",
    tarifHorsSecteur1HorsOptam: "364.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA020",
    designation:
      "Œsophagectomie totale sans rétablissement de la continuité, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "1086.9",
    tarifHorsSecteur1HorsOptam: "875.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA004",
    designation:
      "Œsophagectomie totale avec œsophagogastroplastie, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "1236.5",
    tarifHorsSecteur1HorsOptam: "962.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA006",
    designation:
      "Œsophagectomie totale avec œsophagocoloplastie, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "1489.61",
    tarifHorsSecteur1HorsOptam: "1109.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA022",
    designation:
      "Œsophagectomie totale sans rétablissement de la continuité, par thoracotomie",
    tarifSecteur1Optam: "1086.9",
    tarifHorsSecteur1HorsOptam: "875.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA001",
    designation:
      "Œsophagectomie avec œsophagogastroplastie, par thoracophrénotomie gauche",
    tarifSecteur1Optam: "1264.86",
    tarifHorsSecteur1HorsOptam: "978.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA013",
    designation:
      "Œsophagectomie avec œsophagogastroplastie, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1289.67",
    tarifHorsSecteur1HorsOptam: "993.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA003",
    designation:
      "Œsophagectomie avec œsophagogastroplastie, par thoracotomie et par cœlioscopie",
    tarifSecteur1Optam: "1351.35",
    tarifHorsSecteur1HorsOptam: "1040.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA012",
    designation:
      "Œsophagectomie avec œsophagogastroplastie, par thoracotomie et par laparotomie",
    tarifSecteur1Optam: "1338.59",
    tarifHorsSecteur1HorsOptam: "1021.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA018",
    designation:
      "Œsophagectomie avec œsophagogastroplastie, par cervicotomie, thoracotomie et cœlioscopie",
    tarifSecteur1Optam: "1521.51",
    tarifHorsSecteur1HorsOptam: "1139.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA002",
    designation:
      "Œsophagectomie avec œsophagogastroplastie, par cervicotomie, thoracotomie et laparotomie",
    tarifSecteur1Optam: "1459.83",
    tarifHorsSecteur1HorsOptam: "1091.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA016",
    designation:
      "Œsophagectomie avec œsophagocoloplastie, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1060.92",
    tarifHorsSecteur1HorsOptam: "1060.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA009",
    designation:
      "Œsophagectomie avec œsophagocoloplastie, par thoracotomie et par laparotomie",
    tarifSecteur1Optam: "1392.48",
    tarifHorsSecteur1HorsOptam: "1052.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA007",
    designation:
      "Œsophagectomie avec œsophagocoloplastie, par cervicotomie, thoracotomie et laparotomie",
    tarifSecteur1Optam: "1513.72",
    tarifHorsSecteur1HorsOptam: "1123.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA005",
    designation:
      "Œsophagectomie avec œsophagojéjunostomie, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1364.83",
    tarifHorsSecteur1HorsOptam: "1036.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA011",
    designation:
      "Œsophagectomie avec œsophagojéjunostomie, par thoracotomie et par laparotomie",
    tarifSecteur1Optam: "1356.32",
    tarifHorsSecteur1HorsOptam: "1031.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA007",
    designation:
      "Plastie de sténose anastomotique de l'œsophage, par cervicotomie",
    tarifSecteur1Optam: "221.97",
    tarifHorsSecteur1HorsOptam: "221.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA009",
    designation:
      "Œsophagogastroplastie rétrosternale sans œsophagectomie, avec anastomose pharyngogastrique, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "794.04",
    tarifHorsSecteur1HorsOptam: "794.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA008",
    designation:
      "Œsophagogastroplastie rétrosternale sans œsophagectomie, avec anastomose œsophagogastrique cervicale, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "794.04",
    tarifHorsSecteur1HorsOptam: "794.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA005",
    designation:
      "Œsophagocoloplastie rétrosternale sans œsophagectomie, avec anastomose pharyngocolique, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "1171.98",
    tarifHorsSecteur1HorsOptam: "924.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA001",
    designation:
      "Œsophagocoloplastie rétrosternale sans œsophagectomie, avec anastomose œsophagocolique cervicale, par cervicotomie et par laparotomie",
    tarifSecteur1Optam: "1171.98",
    tarifHorsSecteur1HorsOptam: "924.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA006",
    designation:
      "Reconstruction de l'œsophage par lambeau libre de segment digestif, avec anastomoses vasculaires et digestives",
    tarifSecteur1Optam: "1027.34",
    tarifHorsSecteur1HorsOptam: "840.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA005",
    designation:
      "Transsection de l'œsophage, par thoracotomie ou par laparotomie",
    tarifSecteur1Optam: "370.59",
    tarifHorsSecteur1HorsOptam: "370.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA004",
    designation:
      "Transsection de l'œsophage avec splénectomie et déconnexion portosystémique, par laparotomie",
    tarifSecteur1Optam: "661.57",
    tarifHorsSecteur1HorsOptam: "661.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEPA007",
    designation:
      "Transsection de l'œsophage avec splénectomie et déconnexion portosystémique, par thoracotomie et par laparotomie",
    tarifSecteur1Optam: "992.6",
    tarifHorsSecteur1HorsOptam: "807.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA021",
    designation:
      "Résection-anastomose de rétrécissement congénital de l'œsophage, par thoracotomie",
    tarifSecteur1Optam: "583.48",
    tarifHorsSecteur1HorsOptam: "583.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA006",
    designation:
      "Fermeture d'une fistule œsotrachéale congénitale sans atrésie de l'œsophage, par cervicotomie",
    tarifSecteur1Optam: "308.74",
    tarifHorsSecteur1HorsOptam: "308.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA002",
    designation:
      "Fermeture d'une fistule œsotrachéale congénitale sans atrésie de l'œsophage, par cervicotomie et par thoracotomie",
    tarifSecteur1Optam: "405.14",
    tarifHorsSecteur1HorsOptam: "405.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESA009",
    designation:
      "Fermeture de la fistule œsotrachéale d'une atrésie de l'œsophage, par thoracotomie",
    tarifSecteur1Optam: "583.48",
    tarifHorsSecteur1HorsOptam: "583.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA004",
    designation:
      "Reconstruction de l'œsophage en un temps pour atrésie sans fistule, par thoracotomie",
    tarifSecteur1Optam: "610.64",
    tarifHorsSecteur1HorsOptam: "610.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA003",
    designation:
      "Reconstruction de l'œsophage et fermeture de la fistule en un temps pour atrésie avec fistule, par thoracotomie",
    tarifSecteur1Optam: "657.51",
    tarifHorsSecteur1HorsOptam: "657.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEMA002",
    designation:
      "Reconstruction secondaire de l'œsophage pour atrésie avec fistule, par thoracotomie",
    tarifSecteur1Optam: "641.06",
    tarifHorsSecteur1HorsOptam: "641.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFC003",
    designation: "Exérèse de duplication de l'œsophage, par thoracoscopie",
    tarifSecteur1Optam: "417.46",
    tarifHorsSecteur1HorsOptam: "417.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA014",
    designation: "Exérèse de duplication de l'œsophage, par thoracotomie",
    tarifSecteur1Optam: "401.02",
    tarifHorsSecteur1HorsOptam: "401.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFA010",
    designation:
      "Exérèse de duplication de l'œsophage, par thoracotomie et par laparotomie",
    tarifSecteur1Optam: "485.32",
    tarifHorsSecteur1HorsOptam: "485.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFAH001",
    designation: "Dilatation du pylore, avec guidage radiologique",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFAE001",
    designation: "Dilatation du pylore, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "112.36",
    tarifHorsSecteur1HorsOptam: "112.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFLH002",
    designation:
      "Pose d'une endoprothèse de l'estomac ou du duodénum, avec guidage radiologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGLE001",
    designation:
      "Pose d'une endoprothèse du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "235.29",
    tarifHorsSecteur1HorsOptam: "235.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGGE001",
    designation:
      "Ablation d'une endoprothèse du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "249.47",
    tarifHorsSecteur1HorsOptam: "249.47",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGKE001",
    designation:
      "Changement d'une endoprothèse du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "281.23",
    tarifHorsSecteur1HorsOptam: "281.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFCC001",
    designation:
      "Suture de plaie ou de perforation de l'estomac ou du duodénum, par cœlioscopie",
    tarifSecteur1Optam: "314.8",
    tarifHorsSecteur1HorsOptam: "277.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCA003",
    designation:
      "Suture de plaie ou de perforation de l'estomac ou du duodénum, par laparotomie",
    tarifSecteur1Optam: "274.38",
    tarifHorsSecteur1HorsOptam: "242.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCH001",
    designation:
      "Gastrostomie, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "154.52",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFCB001",
    designation:
      "Gastrostomie, par voie transcutanée avec guidage endoscopique",
    tarifSecteur1Optam: "125.84",
    tarifHorsSecteur1HorsOptam: "125.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFCC002",
    designation: "Gastrostomie cutanée, par cœlioscopie",
    tarifSecteur1Optam: "209.16",
    tarifHorsSecteur1HorsOptam: "186.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCA002",
    designation: "Gastrostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "162.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFAD001",
    designation:
      "Dilatation du trajet d'une gastrostomie cutanée, avec pose d'un bouton de gastrostomie",
    tarifSecteur1Optam: "60.27",
    tarifHorsSecteur1HorsOptam: "38.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFKD001",
    designation:
      "Changement d'une sonde de gastrostomie, par voie externe sans guidage",
    tarifSecteur1Optam: "42.54",
    tarifHorsSecteur1HorsOptam: "28.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFKH001",
    designation:
      "Changement d'une sonde de gastrostomie ou de gastrojéjunostomie, par voie externe avec guidage radiologique",
    tarifSecteur1Optam: "37.16",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFKE001",
    designation:
      "Changement d'une sonde de gastrostomie ou de gastrojéjunostomie, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "104.16",
    tarifHorsSecteur1HorsOptam: "104.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFSA001",
    designation: "Fermeture de gastrostomie cutanée, par abord direct",
    tarifSecteur1Optam: "158.82",
    tarifHorsSecteur1HorsOptam: "144.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA001",
    designation:
      "Confection d'une valve tubérositaire sans libération de la grande courbure gastrique, par thoracotomie",
    tarifSecteur1Optam: "369.39",
    tarifHorsSecteur1HorsOptam: "345.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMC004",
    designation:
      "Confection d'une valve tubérositaire sans libération de la grande courbure gastrique, par cœlioscopie",
    tarifSecteur1Optam: "457.31",
    tarifHorsSecteur1HorsOptam: "409",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA003",
    designation:
      "Confection d'une valve tubérositaire sans libération de la grande courbure gastrique, par laparotomie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "324.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA007",
    designation:
      "Confection d'une valve tubérositaire avec libération de la grande courbure gastrique, par thoracotomie",
    tarifSecteur1Optam: "412.64",
    tarifHorsSecteur1HorsOptam: "371",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMC001",
    designation:
      "Confection d'une valve tubérositaire avec libération de la grande courbure gastrique, par cœlioscopie",
    tarifSecteur1Optam: "545.93",
    tarifHorsSecteur1HorsOptam: "460.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA008",
    designation:
      "Confection d'une valve tubérositaire avec libération de la grande courbure gastrique, par laparotomie",
    tarifSecteur1Optam: "394.2",
    tarifHorsSecteur1HorsOptam: "360.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCC022",
    designation:
      "Gastrojéjunostomie de dérivation [Gastro-entéro-anastomose sans résection gastrique], par cœlioscopie",
    tarifSecteur1Optam: "242.92",
    tarifHorsSecteur1HorsOptam: "240.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCA004",
    designation:
      "Gastrojéjunostomie de dérivation [Gastro-entéro-anastomose sans résection gastrique], par laparotomie",
    tarifSecteur1Optam: "214.12",
    tarifHorsSecteur1HorsOptam: "211.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA006",
    designation:
      "Dérivation des sécrétions duodénales et biliopancréatiques par réfection du montage après gastrectomie [Diversion du duodénum], par laparotomie",
    tarifSecteur1Optam: "645.19",
    tarifHorsSecteur1HorsOptam: "575.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFPA001",
    designation: "Gastrotomie à visée thérapeutique, par laparotomie",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "209.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPA001",
    designation:
      "Duodénotomie à visée thérapeutique ou duodénectomie partielle, par laparotomie",
    tarifSecteur1Optam: "244.61",
    tarifHorsSecteur1HorsOptam: "224.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMC003",
    designation: "Pyloroplastie ou duodénoplastie, par cœlioscopie",
    tarifSecteur1Optam: "209.16",
    tarifHorsSecteur1HorsOptam: "203.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA004",
    designation: "Pyloroplastie ou duodénoplastie, par laparotomie",
    tarifSecteur1Optam: "187.18",
    tarifHorsSecteur1HorsOptam: "178.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFLC900",
    designation:
      "Implantation souscutanée d'un stimulateur gastrique avec pose d'une sonde pariétale gastrique pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFGC900",
    designation:
      "Ablation d'une sonde de stimulation pariétale gastrique pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCC027",
    designation:
      "Court-circuit biliopancréatique ou intestinal pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "850.8",
    tarifHorsSecteur1HorsOptam: "682.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA009",
    designation:
      "Court-circuit biliopancréatique ou intestinal pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "1106.04",
    tarifHorsSecteur1HorsOptam: "887.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCC003",
    designation:
      "Court-circuit gastrique avec anse montée en Y [Bypass gastrique en Y] pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "591.31",
    tarifHorsSecteur1HorsOptam: "474.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFCA001",
    designation:
      "Court-circuit gastrique avec anse montée en Y [Bypass gastrique en Y] pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "562.95",
    tarifHorsSecteur1HorsOptam: "458.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC018",
    designation:
      "Gastrectomie longitudinale [Sleeve gastrectomy] pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "511.19",
    tarifHorsSecteur1HorsOptam: "410.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA011",
    designation:
      "Gastrectomie longitudinale [Sleeve gastrectomy] pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "478.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC004",
    designation:
      "Gastrectomie avec court-circuit biliopancréatique ou intestinal pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "625.34",
    tarifHorsSecteur1HorsOptam: "494.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA001",
    designation:
      "Gastrectomie avec court-circuit biliopancréatique ou intestinal pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "477.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMC006",
    designation:
      "Gastroplastie verticale calibrée pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "452.34",
    tarifHorsSecteur1HorsOptam: "394.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA010",
    designation:
      "Gastroplastie verticale calibrée pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "314.8",
    tarifHorsSecteur1HorsOptam: "314.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMC007",
    designation:
      "Gastroplastie par pose d'anneau ajustable périgastrique pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "452.34",
    tarifHorsSecteur1HorsOptam: "394.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA009",
    designation:
      "Gastroplastie par pose d'anneau ajustable périgastrique pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "314.8",
    tarifHorsSecteur1HorsOptam: "314.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFKC001",
    designation:
      "Changement d'un anneau ajustable périgastrique pour obésité morbide, par cœlioscopie",
    tarifSecteur1Optam: "587.76",
    tarifHorsSecteur1HorsOptam: "403.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFKA002",
    designation:
      "Changement d'un anneau ajustable périgastrique pour obésité morbide, par laparotomie",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "299.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMC008",
    designation:
      "Repositionnement ou ablation d'un anneau ajustable périgastrique, par cœlioscopie",
    tarifSecteur1Optam: "587.76",
    tarifHorsSecteur1HorsOptam: "403.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA011",
    designation:
      "Repositionnement ou ablation d'un anneau ajustable périgastrique, par laparotomie",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "299.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFKA001",
    designation:
      "Changement ou repositionnement du dispositif d'accès d'un anneau ajustable périgastrique pour obésité morbide, par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFDC002",
    designation: "Gastropexie postérieure [Cardiopexie], par cœlioscopie",
    tarifSecteur1Optam: "342.3",
    tarifHorsSecteur1HorsOptam: "342.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFDA002",
    designation: "Gastropexie postérieure [Cardiopexie], par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFDC001",
    designation: "Gastropexie chez l'enfant, par cœlioscopie",
    tarifSecteur1Optam: "342.3",
    tarifHorsSecteur1HorsOptam: "342.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFDA001",
    designation: "Gastropexie chez l'enfant, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC001",
    designation:
      "Résection partielle atypique de la paroi de l'estomac n'interrompant pas la continuité, par cœlioscopie",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "253.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA009",
    designation:
      "Résection partielle atypique de la paroi de l'estomac n'interrompant pas la continuité, par laparotomie",
    tarifSecteur1Optam: "230.43",
    tarifHorsSecteur1HorsOptam: "221.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA003",
    designation:
      "Gastrectomie partielle supérieure [polaire supérieure] avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "670.71",
    tarifHorsSecteur1HorsOptam: "546.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC012",
    designation:
      "Gastrectomie partielle inférieure avec anastomose gastroduodénale, par cœlioscopie",
    tarifSecteur1Optam: "447.1",
    tarifHorsSecteur1HorsOptam: "428.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA002",
    designation:
      "Gastrectomie partielle inférieure avec anastomose gastroduodénale, par laparotomie",
    tarifSecteur1Optam: "418.31",
    tarifHorsSecteur1HorsOptam: "399.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC002",
    designation:
      "Gastrectomie partielle inférieure avec anastomose gastrojéjunale, par cœlioscopie",
    tarifSecteur1Optam: "470.78",
    tarifHorsSecteur1HorsOptam: "442.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA006",
    designation:
      "Gastrectomie partielle inférieure avec anastomose gastrojéjunale, par laparotomie",
    tarifSecteur1Optam: "423.98",
    tarifHorsSecteur1HorsOptam: "403.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC017",
    designation:
      "Gastrectomie totale avec rétablissement de la continuité, par cœlioscopie",
    tarifSecteur1Optam: "926.39",
    tarifHorsSecteur1HorsOptam: "750.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA005",
    designation:
      "Gastrectomie totale avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "897.59",
    tarifHorsSecteur1HorsOptam: "721.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA008",
    designation:
      "Dégastrogastrectomie partielle avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "780.61",
    tarifHorsSecteur1HorsOptam: "653.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFMA005",
    designation:
      "Totalisation secondaire de gastrectomie avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "816.06",
    tarifHorsSecteur1HorsOptam: "674.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFPC001",
    designation:
      "Pylorotomie extramuqueuse [Pyloromyotomie extramuqueuse], par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFPA002",
    designation:
      "Pylorotomie extramuqueuse [Pyloromyotomie extramuqueuse], par laparotomie",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "173.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFC003",
    designation: "Résection de duplication gastrique, par cœlioscopie",
    tarifSecteur1Optam: "403.28",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA010",
    designation: "Résection de duplication gastrique, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA007",
    designation:
      "Résection de duplication gastroduodénale sans dérivation du conduit biliaire commun, par laparotomie",
    tarifSecteur1Optam: "404.13",
    tarifHorsSecteur1HorsOptam: "391.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HFFA004",
    designation:
      "Résection de duplication gastroduodénale avec dérivation du conduit biliaire commun, par laparotomie",
    tarifSecteur1Optam: "632.43",
    tarifHorsSecteur1HorsOptam: "589.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA003",
    designation:
      "Anastomose duodénoduodénale pour atrésie ou sténose congénitale du duodénum, par laparotomie",
    tarifSecteur1Optam: "481.41",
    tarifHorsSecteur1HorsOptam: "367",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGNE001",
    designation:
      "Séance de destruction de lésion du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "137.32",
    tarifHorsSecteur1HorsOptam: "137.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGFE005",
    designation:
      "Exérèse de la papille duodénale majeure, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "389.2",
    tarifHorsSecteur1HorsOptam: "389.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA014",
    designation: "Exérèse de la papille duodénale majeure, par laparotomie",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "446.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA007",
    designation: "Exclusion du duodénum, par laparotomie",
    tarifSecteur1Optam: "331.1",
    tarifHorsSecteur1HorsOptam: "279.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGMA002",
    designation: "Remise en circuit secondaire du duodénum, par laparotomie",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "433.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HENE004",
    designation:
      "Séance de destruction de lésion de l'œsophage et/ou de l'estomac sans laser, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "125.41",
    tarifHorsSecteur1HorsOptam: "125.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HENE002",
    designation:
      "Séance de destruction de lésion de l'œsophage et/ou de l'estomac avec laser, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "116.9",
    tarifHorsSecteur1HorsOptam: "116.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HENE001",
    designation:
      "Séance de destruction photodynamique de lésion de l'œsophage et/ou de l'estomac avec laser, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEFE002",
    designation:
      "Exérèse de 1 à 3 polypes de moins de 1cm de diamètre de l'œsophage, de l'estomac et/ou du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "103.28",
    tarifHorsSecteur1HorsOptam: "103.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFE001",
    designation:
      "Exérèse d'un polype de 1cm et plus de diamètre ou de 4 polypes ou plus de l'œsophage, de l'estomac et/ou du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "135.62",
    tarifHorsSecteur1HorsOptam: "135.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFE003",
    designation:
      "Séance de mucosectomie de l'œsophage, de l'estomac ou du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "201.42",
    tarifHorsSecteur1HorsOptam: "201.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HEFE326",
    designation:
      "Dissection sousmuqueuse de l'œsophage et/ou de l'estomac, par œso-gastro-duodénoscopie ",
    tarifSecteur1Optam: "586.8",
    tarifHorsSecteur1HorsOptam: "586.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HESE002",
    designation:
      "Hémostase de lésion de l'œsophage, de l'estomac et/ou du duodénum sans laser, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "135.62",
    tarifHorsSecteur1HorsOptam: "135.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HESE001",
    designation:
      "Hémostase de lésion de l'œsophage, de l'estomac et/ou du duodénum avec laser, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "142.99",
    tarifHorsSecteur1HorsOptam: "142.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HEGE002",
    designation:
      "Ablation de corps étranger de l'œsophage, de l'estomac et/ou du duodénum, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "111.8",
    tarifHorsSecteur1HorsOptam: "111.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFLH001",
    designation:
      "Pose d'une sonde gastrique, duodénale ou jéjunale, avec guidage radiologique",
    tarifSecteur1Optam: "11.74",
    tarifHorsSecteur1HorsOptam: "5.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HFLE001",
    designation:
      "Pose d'une sonde gastrique, duodénale ou jéjunale, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGAE001",
    designation: "Dilatation d'une sténose iléale, par iléoscopie",
    tarifSecteur1Optam: "157.74",
    tarifHorsSecteur1HorsOptam: "157.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGFE004",
    designation:
      "Exérèse de 1 à 3 polypes de moins de 1cm de diamètre de l'intestin grêle, par jéjunoscopie",
    tarifSecteur1Optam: "179.29",
    tarifHorsSecteur1HorsOptam: "179.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFE002",
    designation:
      "Exérèse de 1 à 3 polypes de moins de 1cm de diamètre de l'intestin grêle, par iléoscopie",
    tarifSecteur1Optam: "219.61",
    tarifHorsSecteur1HorsOptam: "219.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFE003",
    designation:
      "Exérèse d'un polype de plus de 1cm de diamètre ou de 4 polypes ou plus de l'intestin grêle, par jéjunoscopie",
    tarifSecteur1Optam: "194.61",
    tarifHorsSecteur1HorsOptam: "194.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFE001",
    designation:
      "Exérèse d'un polype de plus de 1cm de diamètre ou de 4 polypes ou plus de l'intestin grêle, par iléoscopie",
    tarifSecteur1Optam: "234.93",
    tarifHorsSecteur1HorsOptam: "234.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGSE001",
    designation: "Hémostase de lésion intestinale sans laser, par jéjunoscopie",
    tarifSecteur1Optam: "162.84",
    tarifHorsSecteur1HorsOptam: "162.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGSE003",
    designation: "Hémostase de lésion intestinale sans laser, par iléoscopie",
    tarifSecteur1Optam: "220.18",
    tarifHorsSecteur1HorsOptam: "220.18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGSE002",
    designation: "Hémostase de lésion intestinale avec laser, par jéjunoscopie",
    tarifSecteur1Optam: "175.32",
    tarifHorsSecteur1HorsOptam: "175.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGSE004",
    designation: "Hémostase de lésion intestinale avec laser, par iléoscopie",
    tarifSecteur1Optam: "215.64",
    tarifHorsSecteur1HorsOptam: "215.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGCC031",
    designation:
      "Suture de plaie ou de perforation de l'intestin grêle, par cœlioscopie",
    tarifSecteur1Optam: "353",
    tarifHorsSecteur1HorsOptam: "353",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA002",
    designation:
      "Suture de plaie ou de perforation de l'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "302.03",
    tarifHorsSecteur1HorsOptam: "258.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCH001",
    designation:
      "Jéjunostomie, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "193.64",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGLA001",
    designation:
      "Pose d'une sonde de jéjunostomie pour alimentation entérale, par laparotomie",
    tarifSecteur1Optam: "175.12",
    tarifHorsSecteur1HorsOptam: "154.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCC026",
    designation: "Entérostomie cutanée, par cœlioscopie",
    tarifSecteur1Optam: "247.17",
    tarifHorsSecteur1HorsOptam: "208.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA008",
    designation: "Entérostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "179.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGMA001",
    designation:
      "Confection secondaire d'une iléostomie continente de Koch, par laparotomie",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "459.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGSA001",
    designation: "Fermeture d'entérostomie cutanée, par abord direct",
    tarifSecteur1Optam: "209.16",
    tarifHorsSecteur1HorsOptam: "173.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCC003",
    designation: "Entéroentérostomie de dérivation, par cœlioscopie",
    tarifSecteur1Optam: "284.74",
    tarifHorsSecteur1HorsOptam: "265.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA001",
    designation: "Entéroentérostomie de dérivation, par laparotomie",
    tarifSecteur1Optam: "255.95",
    tarifHorsSecteur1HorsOptam: "236.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCC015",
    designation:
      "Iléocolostomie de dérivation [Anastomose iléocolique sans exérèse intestinale], par cœlioscopie",
    tarifSecteur1Optam: "281.92",
    tarifHorsSecteur1HorsOptam: "263.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGCA005",
    designation:
      "Iléocolostomie de dérivation [Anastomose iléocolique sans exérèse intestinale], par laparotomie",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "234.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPC006",
    designation: "Entérotomie à visée thérapeutique, par cœlioscopie",
    tarifSecteur1Optam: "259.23",
    tarifHorsSecteur1HorsOptam: "188.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPA002",
    designation: "Entérotomie à visée thérapeutique, par laparotomie",
    tarifSecteur1Optam: "230.43",
    tarifHorsSecteur1HorsOptam: "186.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEH001",
    designation:
      "Réduction totale ou partielle d'invagination intestinale aigüe par lavement opaque ou insufflation, avec guidage radiologique",
    tarifSecteur1Optam: "144.11",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGEC009",
    designation: "Réduction d'invagination intestinale aigüe, par cœlioscopie",
    tarifSecteur1Optam: "242.22",
    tarifHorsSecteur1HorsOptam: "235.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEA003",
    designation: "Réduction d'invagination intestinale aigüe, par laparotomie",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "206.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA001",
    designation:
      "Résection de l'angle duodénojéjunal avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "321.89",
    tarifHorsSecteur1HorsOptam: "287.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA005",
    designation:
      "Résection segmentaire unique de l'intestin grêle pour occlusion, par laparotomie",
    tarifSecteur1Optam: "310.54",
    tarifHorsSecteur1HorsOptam: "280.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFC014",
    designation:
      "Résection segmentaire unique de l'intestin grêle sans rétablissement de la continuité, en dehors de l'occlusion, par cœlioscopie",
    tarifSecteur1Optam: "384.36",
    tarifHorsSecteur1HorsOptam: "347.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA003",
    designation:
      "Résection segmentaire unique de l'intestin grêle sans rétablissement de la continuité, en dehors de l'occlusion, par laparotomie",
    tarifSecteur1Optam: "297.09",
    tarifHorsSecteur1HorsOptam: "297.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFC021",
    designation:
      "Résection segmentaire unique de l'intestin grêle avec rétablissement de la continuité, en dehors de l'occlusion, par cœlioscopie",
    tarifSecteur1Optam: "279.08",
    tarifHorsSecteur1HorsOptam: "274.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA007",
    designation:
      "Résection segmentaire unique de l'intestin grêle avec rétablissement de la continuité, en dehors de l'occlusion, par laparotomie",
    tarifSecteur1Optam: "250.28",
    tarifHorsSecteur1HorsOptam: "245.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFC016",
    designation:
      "Résection segmentaire multiple de l'intestin grêle, par cœlioscopie",
    tarifSecteur1Optam: "484.32",
    tarifHorsSecteur1HorsOptam: "412.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA004",
    designation:
      "Résection segmentaire multiple de l'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "397.04",
    tarifHorsSecteur1HorsOptam: "361.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA013",
    designation: "Résection totale de l'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "309.83",
    tarifHorsSecteur1HorsOptam: "267.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA002",
    designation:
      "Prélèvement d'intestin grêle, chez un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEA005",
    designation: "Transplantation d'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEA002",
    designation:
      "Transplantation d'intestin grêle et de foie réduit, par laparotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEA004",
    designation:
      "Transplantation d'intestin grêle et de foie total, par laparotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPH001",
    designation:
      "Désobstruction de l'intestin grêle par lavement intestinal pour iléus méconial, avec contrôle radiologique",
    tarifSecteur1Optam: "121.95",
    tarifHorsSecteur1HorsOptam: "119.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGPA006",
    designation:
      "Désobstruction de l'intestin grêle pour iléus méconial, par laparotomie",
    tarifSecteur1Optam: "317.63",
    tarifHorsSecteur1HorsOptam: "315.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA012",
    designation:
      "Résection de l'intestin grêle et/ou du côlon pour péritonite néonatale, par laparotomie",
    tarifSecteur1Optam: "389.5",
    tarifHorsSecteur1HorsOptam: "389.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFC001",
    designation:
      "Exérèse de duplication de l'intestin grêle sans résection intestinale, par cœlioscopie",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "291.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA010",
    designation:
      "Exérèse de duplication de l'intestin grêle sans résection intestinale, par laparotomie",
    tarifSecteur1Optam: "290.69",
    tarifHorsSecteur1HorsOptam: "269.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA011",
    designation:
      "Exérèse de duplication de l'intestin grêle avec résection intestinale, par laparotomie",
    tarifSecteur1Optam: "336.07",
    tarifHorsSecteur1HorsOptam: "295.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA008",
    designation:
      "Résection segmentaire d'une atrésie unique de l'intestin grêle avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "350.25",
    tarifHorsSecteur1HorsOptam: "303.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA009",
    designation:
      "Résection segmentaire d'une atrésie étagée de l'intestin grêle avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "459.43",
    tarifHorsSecteur1HorsOptam: "367.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFC002",
    designation: "Résection du diverticule iléal [de Meckel], par cœlioscopie",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "188.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGFA006",
    designation: "Résection du diverticule iléal [de Meckel], par laparotomie",
    tarifSecteur1Optam: "224.75",
    tarifHorsSecteur1HorsOptam: "183.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGAA002",
    designation: "Plastie d'allongement de l'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "682.77",
    tarifHorsSecteur1HorsOptam: "597.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEC001",
    designation:
      "Détorsion intestinale ou section de bride péritonéale pour vice de rotation de l'anse intestinale primitive [mesenterium commune], par cœlioscopie",
    tarifSecteur1Optam: "358.05",
    tarifHorsSecteur1HorsOptam: "302.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGEA001",
    designation:
      "Détorsion intestinale ou section de bride péritonéale pour vice de rotation de l'anse intestinale primitive [mesenterium commune], par laparotomie",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "280",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGAC010",
    designation:
      "Plastie d'élargissement unique ou multiple de l'intestin grêle, par cœlioscopie",
    tarifSecteur1Optam: "507.78",
    tarifHorsSecteur1HorsOptam: "507.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGAA003",
    designation:
      "Plastie d'élargissement unique ou multiple de l'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGMA005",
    designation:
      "Rétablissement secondaire de la continuité digestive après résection de l'intestin grêle, par laparotomie",
    tarifSecteur1Optam: "255.24",
    tarifHorsSecteur1HorsOptam: "235.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA016",
    designation:
      "Appendicectomie, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "187.89",
    tarifHorsSecteur1HorsOptam: "183.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA011",
    designation: "Appendicectomie, par laparotomie",
    tarifSecteur1Optam: "176.79",
    tarifHorsSecteur1HorsOptam: "176.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA001",
    designation: "Appendicectomie, par abord de la fosse iliaque",
    tarifSecteur1Optam: "176.79",
    tarifHorsSecteur1HorsOptam: "176.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA025",
    designation:
      "Appendicectomie avec toilette péritonéale pour péritonite aigüe généralisée, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "277.22",
    tarifHorsSecteur1HorsOptam: "270.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA020",
    designation:
      "Appendicectomie avec toilette péritonéale pour péritonite aigüe généralisée, par laparotomie",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "263.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHJE001",
    designation: "Exsufflation du côlon, par endoscopie",
    tarifSecteur1Optam: "129.7",
    tarifHorsSecteur1HorsOptam: "129.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHEE001",
    designation: "Détorsion d'un volvulus du côlon, par endoscopie",
    tarifSecteur1Optam: "121.75",
    tarifHorsSecteur1HorsOptam: "121.75",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHAE001",
    designation:
      "Dilatation d'une sténose du côlon et/ou du rectum, par endoscopie",
    tarifSecteur1Optam: "112.93",
    tarifHorsSecteur1HorsOptam: "112.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHLH001",
    designation: "Pose d'une endoprothèse du côlon, avec guidage radiologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHLE005",
    designation: "Pose d'une endoprothèse du côlon, par coloscopie",
    tarifSecteur1Optam: "347.94",
    tarifHorsSecteur1HorsOptam: "347.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHGE010",
    designation: "Ablation d'une endoprothèse du côlon, par coloscopie",
    tarifSecteur1Optam: "108.31",
    tarifHorsSecteur1HorsOptam: "108.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHNE004",
    designation:
      "Séance de destruction de lésion du côlon et/ou du rectum sans laser, par rectosigmoïdoscopie ou par coloscopie partielle",
    tarifSecteur1Optam: "119.73",
    tarifHorsSecteur1HorsOptam: "119.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHNE001",
    designation:
      "Séance de destruction de lésion du côlon et/ou du rectum sans laser, par coloscopie totale",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHNE003",
    designation:
      "Séance de destruction de lésion du côlon et/ou du rectum avec laser, par rectosigmoïdoscopie ou par coloscopie partielle",
    tarifSecteur1Optam: "126.54",
    tarifHorsSecteur1HorsOptam: "126.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHNE002",
    designation:
      "Séance de destruction de lésion du côlon et/ou du rectum avec laser, par coloscopie totale",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFE001",
    designation:
      "Exérèse de 1 à 3 polypes de moins de 1cm de diamètre du côlon et/ou du rectum, par rectosigmoïdoscopie ou par coloscopie partielle",
    tarifSecteur1Optam: "103.28",
    tarifHorsSecteur1HorsOptam: "103.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFE002",
    designation:
      "Exérèse de 1 à 3 polypes de moins de 1cm de diamètre du côlon et/ou du rectum, par coloscopie totale",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFE005",
    designation:
      "Exérèse d'un polype de plus de 1cm de diamètre ou de 4 polypes ou plus du côlon et/ou du rectum, par rectosigmoïdoscopie ou par coloscopie partielle",
    tarifSecteur1Optam: "119.17",
    tarifHorsSecteur1HorsOptam: "119.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFE004",
    designation:
      "Exérèse d'un polype de plus de 1cm de diamètre ou de 4 polypes ou plus du côlon et/ou du rectum, par coloscopie totale",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFE006",
    designation: "Séance de mucosectomie rectocolique, par endoscopie",
    tarifSecteur1Optam: "202.03",
    tarifHorsSecteur1HorsOptam: "202.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHSE004",
    designation:
      "Hémostase de lésion du côlon sans laser, par rectosigmoïdoscopie ou par coloscopie partielle",
    tarifSecteur1Optam: "94.53",
    tarifHorsSecteur1HorsOptam: "94.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHSE002",
    designation:
      "Hémostase de lésion du côlon sans laser, par coloscopie totale",
    tarifSecteur1Optam: "171.72",
    tarifHorsSecteur1HorsOptam: "171.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHSE001",
    designation:
      "Hémostase de lésion du côlon avec laser, par rectosigmoïdoscopie ou par coloscopie partielle",
    tarifSecteur1Optam: "101.91",
    tarifHorsSecteur1HorsOptam: "101.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHSE003",
    designation:
      "Hémostase de lésion du côlon avec laser, par coloscopie totale",
    tarifSecteur1Optam: "172.86",
    tarifHorsSecteur1HorsOptam: "172.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHCC001",
    designation: "Suture de plaie ou de perforation du côlon, par cœlioscopie",
    tarifSecteur1Optam: "292.11",
    tarifHorsSecteur1HorsOptam: "264.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHCA001",
    designation: "Suture de plaie ou de perforation du côlon, par laparotomie",
    tarifSecteur1Optam: "274.38",
    tarifHorsSecteur1HorsOptam: "242.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHCC007",
    designation: "Colostomie cutanée, par cœlioscopie",
    tarifSecteur1Optam: "180.53",
    tarifHorsSecteur1HorsOptam: "169.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHCA002",
    designation: "Colostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "151.73",
    tarifHorsSecteur1HorsOptam: "140.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHAA002",
    designation: "Plastie d'élargissement de stomie cutanée intestinale",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "155.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHMC005",
    designation: "Réfection de stomie cutanée intestinale, par cœlioscopie",
    tarifSecteur1Optam: "335.81",
    tarifHorsSecteur1HorsOptam: "259.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHMA002",
    designation: "Réfection de stomie cutanée intestinale, par laparotomie",
    tarifSecteur1Optam: "307",
    tarifHorsSecteur1HorsOptam: "230.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHSA001",
    designation: "Fermeture de colostomie cutanée latérale, par abord direct",
    tarifSecteur1Optam: "234.68",
    tarifHorsSecteur1HorsOptam: "223.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHCC011",
    designation:
      "Colocolostomie de dérivation [Anastomose colocolique sans exérèse colique], par cœlioscopie",
    tarifSecteur1Optam: "298.22",
    tarifHorsSecteur1HorsOptam: "272.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHCA003",
    designation:
      "Colocolostomie de dérivation [Anastomose colocolique sans exérèse colique], par laparotomie",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "244.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHPC002",
    designation: "Colotomie à visée thérapeutique, par cœlioscopie",
    tarifSecteur1Optam: "275.09",
    tarifHorsSecteur1HorsOptam: "224.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHPA001",
    designation: "Colotomie à visée thérapeutique, par laparotomie",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "182.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFC296",
    designation:
      "Colectomie droite sans rétablissement de la continuité, par cœlioscopie",
    tarifSecteur1Optam: "312",
    tarifHorsSecteur1HorsOptam: "312",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA026",
    designation:
      "Colectomie droite sans rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "265.17",
    tarifHorsSecteur1HorsOptam: "259.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA009",
    designation:
      "Colectomie droite avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA008",
    designation:
      "Colectomie droite avec rétablissement de la continuité, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "475.03",
    tarifHorsSecteur1HorsOptam: "463.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA018",
    designation: "Colectomie transverse, par laparotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA023",
    designation:
      "Colectomie transverse, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "446.8",
    tarifHorsSecteur1HorsOptam: "446.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFC040",
    designation:
      "\"Colectomie gauche sans libération de l'angle colique gauche, sans ",
  },
  {
    codeCCAM: 'rétablissement de la continuité, par cœlioscopie"',
    designation: "445",
    tarifSecteur1Optam: "445",
    tarifHorsSecteur1HorsOptam: "ADC",
  },
  {
    codeCCAM: "HHFA014",
    designation:
      "Colectomie gauche sans libération de l'angle colique gauche, sans rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "399.88",
    tarifHorsSecteur1HorsOptam: "337.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA017",
    designation:
      "Colectomie gauche sans libération de l'angle colique gauche, avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "463.69",
    tarifHorsSecteur1HorsOptam: "444.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA010",
    designation:
      "Colectomie gauche sans libération de l'angle colique gauche, avec rétablissement de la continuité, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "565.07",
    tarifHorsSecteur1HorsOptam: "515.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA024",
    designation:
      "Colectomie gauche avec libération de l'angle colique gauche, sans rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "485.67",
    tarifHorsSecteur1HorsOptam: "387.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA006",
    designation:
      "Colectomie gauche avec libération de l'angle colique gauche, avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "640.94",
    tarifHorsSecteur1HorsOptam: "547.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA002",
    designation:
      "Colectomie gauche avec libération de l'angle colique gauche, avec rétablissement de la continuité, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "685.6",
    tarifHorsSecteur1HorsOptam: "585.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA021",
    designation:
      "Colectomie totale avec conservation du rectum, sans rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "718.93",
    tarifHorsSecteur1HorsOptam: "636.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA005",
    designation:
      "Colectomie totale avec conservation du rectum, sans rétablissement de la continuité, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "741.61",
    tarifHorsSecteur1HorsOptam: "661.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA022",
    designation:
      "Colectomie totale avec conservation du rectum, avec anastomose iléorectale, par laparotomie",
    tarifSecteur1Optam: "789.83",
    tarifHorsSecteur1HorsOptam: "721.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA004",
    designation:
      "Colectomie totale avec conservation du rectum, avec anastomose iléorectale, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "996.85",
    tarifHorsSecteur1HorsOptam: "853.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA030",
    designation:
      "Coloproctectomie totale sans rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "792.48",
    tarifHorsSecteur1HorsOptam: "792.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA029",
    designation:
      "Coloproctectomie totale sans rétablissement de la continuité, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "1150.71",
    tarifHorsSecteur1HorsOptam: "1000.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA031",
    designation:
      "Coloproctectomie totale avec anastomose iléoanale, par laparotomie",
    tarifSecteur1Optam: "1063.5",
    tarifHorsSecteur1HorsOptam: "993.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA028",
    designation:
      "Coloproctectomie totale avec anastomose iléoanale, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "1370.5",
    tarifHorsSecteur1HorsOptam: "1171.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHMC001",
    designation:
      "Rétablissement secondaire de la continuité digestive après colectomie, par cœlioscopie",
    tarifSecteur1Optam: "754.38",
    tarifHorsSecteur1HorsOptam: "650.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHMA003",
    designation:
      "Rétablissement secondaire de la continuité digestive après colectomie, par laparotomie",
    tarifSecteur1Optam: "662.21",
    tarifHorsSecteur1HorsOptam: "585.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA003",
    designation:
      "Résection du côlon pour malformation congénitale avec rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJND001",
    designation: "Destruction de lésion du rectum, par voie anale",
    tarifSecteur1Optam: "132.58",
    tarifHorsSecteur1HorsOptam: "93.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJFD002",
    designation: "Exérèse de tumeur du rectum, par voie anale",
    tarifSecteur1Optam: "296.36",
    tarifHorsSecteur1HorsOptam: "196.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA003",
    designation: "Exérèse de tumeur du rectum, par abord transsphinctérien",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "177.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFE429",
    designation: "Dissection sousmuqueuse de lésion du rectum, par endoscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA018",
    designation:
      "Exérèse de tumeur du rectum, par abord transsacrococcygien [de Kraske]",
    tarifSecteur1Optam: "483.54",
    tarifHorsSecteur1HorsOptam: "437.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHGE002",
    designation:
      "Ablation de corps étranger du côlon et/ou du rectum, par endoscopie",
    tarifSecteur1Optam: "108.31",
    tarifHorsSecteur1HorsOptam: "108.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJGD001",
    designation:
      "Ablation de corps étranger ou de fécalome intrarectal, par voie anale sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "56.72",
    tarifHorsSecteur1HorsOptam: "50.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJCD002",
    designation:
      "Suture de plaie du rectum par voie anale, sans réparation du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "168.74",
    tarifHorsSecteur1HorsOptam: "115.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJCD001",
    designation:
      "Suture de plaie du rectum par voie anale, avec réparation du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "299.91",
    tarifHorsSecteur1HorsOptam: "239.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJCC001",
    designation:
      "Suture de plaie ou de perforation intrapéritonéale du rectum, par cœlioscopie",
    tarifSecteur1Optam: "249.57",
    tarifHorsSecteur1HorsOptam: "239.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJCA001",
    designation:
      "Suture de plaie ou de perforation intrapéritonéale du rectum, par laparotomie",
    tarifSecteur1Optam: "273.67",
    tarifHorsSecteur1HorsOptam: "241.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJDC001",
    designation: "Rectopexie, par cœlioscopie",
    tarifSecteur1Optam: "442.42",
    tarifHorsSecteur1HorsOptam: "356.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJDA001",
    designation: "Rectopexie, par laparotomie",
    tarifSecteur1Optam: "341.03",
    tarifHorsSecteur1HorsOptam: "285.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJJA001",
    designation: "Drainage d'une fistule rectovaginale acquise",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJPA001",
    designation:
      "Mise à plat d'une fistule rectovaginale acquise, par périnéotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJSA001",
    designation:
      "Fermeture d'une fistule rectovaginale haute ou colovaginale acquise, par laparotomie",
    tarifSecteur1Optam: "346",
    tarifHorsSecteur1HorsOptam: "306.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA013",
    designation:
      "Résection d'une fistule rectovaginale acquise, avec fermeture en un temps par suture musculaire et lambeau d'avancement, par abord périnéal",
    tarifSecteur1Optam: "297.56",
    tarifHorsSecteur1HorsOptam: "277.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJMA002",
    designation:
      "Reconstruction de la paroi antérieure de l'anus et du rectum et de la paroi postérieure du vagin, avec sphinctéroplastie anale, par abord périnéal",
    tarifSecteur1Optam: "312.67",
    tarifHorsSecteur1HorsOptam: "286.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFC031",
    designation:
      "Résection rectosigmoïdienne dépassant le cul-de-sac de Douglas, sans rétablissement de la continuité, par cœlioscopie",
    tarifSecteur1Optam: "507.78",
    tarifHorsSecteur1HorsOptam: "507.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA011",
    designation:
      "Résection rectosigmoïdienne dépassant le cul-de-sac de Douglas, sans rétablissement de la continuité, par laparotomie",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA002",
    designation:
      "Résection rectosigmoïdienne avec anastomose colorectale infrapéritonéale, par laparotomie",
    tarifSecteur1Optam: "687.98",
    tarifHorsSecteur1HorsOptam: "687.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA004",
    designation:
      "Résection rectosigmoïdienne avec anastomose colorectale infrapéritonéale, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "753.67",
    tarifHorsSecteur1HorsOptam: "742.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA006",
    designation:
      "Résection rectosigmoïdienne par laparotomie, avec anastomose coloanale par voie anale ou par abord transsphinctérien",
    tarifSecteur1Optam: "818.19",
    tarifHorsSecteur1HorsOptam: "763.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA017",
    designation:
      "Résection rectosigmoïdienne par cœlioscopie ou par laparotomie avec préparation par cœlioscopie, avec anastomose coloanale par voie anale",
    tarifSecteur1Optam: "847.96",
    tarifHorsSecteur1HorsOptam: "796.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA001",
    designation:
      "Résection rectocolique avec abaissement colique rétrorectal par laparotomie, avec anastomose colorectale par voie anale",
    tarifSecteur1Optam: "805.42",
    tarifHorsSecteur1HorsOptam: "756.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA005",
    designation: "Amputation du rectum, par abord périnéal",
    tarifSecteur1Optam: "527.5",
    tarifHorsSecteur1HorsOptam: "463.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA007",
    designation: "Amputation du rectum, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "827.4",
    tarifHorsSecteur1HorsOptam: "681.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA019",
    designation:
      "Amputation du rectum, par cœlioscopie ou par laparotomie avec préparation par cœlioscopie et par abord périnéal",
    tarifSecteur1Optam: "945.81",
    tarifHorsSecteur1HorsOptam: "765.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA014",
    designation: "Exérèse de moignon rectal résiduel, par abord périnéal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFC023",
    designation:
      "Proctectomie secondaire par cœlioscopie avec anastomose iléoanale par voie transanale, après colectomie totale initiale",
    tarifSecteur1Optam: "911.51",
    tarifHorsSecteur1HorsOptam: "781.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA012",
    designation:
      "Proctectomie secondaire par laparotomie avec anastomose iléoanale par voie transanale, après colectomie totale initiale",
    tarifSecteur1Optam: "882.71",
    tarifHorsSecteur1HorsOptam: "800.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA027",
    designation:
      "Résection complète d'un prolapsus colorectal extériorisé, avec anastomose coloanale et myorraphie du plancher pelvien",
    tarifSecteur1Optam: "367.26",
    tarifHorsSecteur1HorsOptam: "256.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA008",
    designation:
      "Résection circonférentielle de la muqueuse d'un prolapsus rectal et plicature de la musculeuse, par abord périnéal",
    tarifSecteur1Optam: "256.66",
    tarifHorsSecteur1HorsOptam: "192.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA009",
    designation:
      "Résection circonférentielle de la muqueuse d'un prolapsus rectal et plicature de la musculeuse, avec réduction d'hédrocèle par abord périnéal",
    tarifSecteur1Optam: "282.18",
    tarifHorsSecteur1HorsOptam: "207.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFD004",
    designation:
      "Résection de la muqueuse rectale avec plicature hémicirconférentielle antérieure de la musculeuse, par voie anale",
    tarifSecteur1Optam: "230.43",
    tarifHorsSecteur1HorsOptam: "177.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFD001",
    designation:
      "Résection de la muqueuse rectale avec plicature hémicirconférentielle antérieure de la musculeuse par voie anale, avec anoplastie muqueuse postérieure",
    tarifSecteur1Optam: "253.82",
    tarifHorsSecteur1HorsOptam: "213.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFD005",
    designation:
      "Résection de la muqueuse rectale avec plicature hémicirconférentielle antérieure de la musculeuse par voie anale, avec hémorroïdectomie pédiculaire",
    tarifSecteur1Optam: "301.33",
    tarifHorsSecteur1HorsOptam: "240.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFC002",
    designation:
      "Résection rectosigmoïdienne pour aganglionose congénitale par cœlioscopie, avec rétablissement de la continuité par voie anale",
    tarifSecteur1Optam: "624.63",
    tarifHorsSecteur1HorsOptam: "575.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA020",
    designation:
      "Résection rectosigmoïdienne pour aganglionose congénitale par laparotomie, avec rétablissement de la continuité par voie anale",
    tarifSecteur1Optam: "536.71",
    tarifHorsSecteur1HorsOptam: "512.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFD003",
    designation:
      "Résection rectosigmoïdienne pour aganglionose congénitale avec rétablissement de la continuité, par voie anale",
    tarifSecteur1Optam: "536.71",
    tarifHorsSecteur1HorsOptam: "512.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFC001",
    designation:
      "Résection rectocolique subtotale pour aganglionose congénitale par cœlioscopie, avec rétablissement de la continuité par voie anale",
    tarifSecteur1Optam: "705.46",
    tarifHorsSecteur1HorsOptam: "622.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA016",
    designation:
      "Résection rectocolique subtotale pour aganglionose congénitale par laparotomie, avec rétablissement de la continuité par laparotomie ou par voie anale",
    tarifSecteur1Optam: "563.45",
    tarifHorsSecteur1HorsOptam: "563.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFC003",
    designation:
      "Résection rectocolique totale pour aganglionose congénitale par cœlioscopie, avec rétablissement de la continuité par voie anale",
    tarifSecteur1Optam: "782.03",
    tarifHorsSecteur1HorsOptam: "666.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA015",
    designation:
      "Résection rectocolique totale pour aganglionose congénitale par laparotomie, avec rétablissement de la continuité par laparotomie ou par voie anale",
    tarifSecteur1Optam: "692.69",
    tarifHorsSecteur1HorsOptam: "602.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJFA010",
    designation:
      "Exérèse de duplication du rectum, par abord transsacrococcygien [de Kraske]",
    tarifSecteur1Optam: "389.95",
    tarifHorsSecteur1HorsOptam: "383.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJBA001",
    designation:
      "Plicature de la paroi antérieure du rectum, par abord périnéal",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "227.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJSD001",
    designation: "Hémostase secondaire à un acte sur le rectum",
    tarifSecteur1Optam: "57.43",
    tarifHorsSecteur1HorsOptam: "50.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKLB001",
    designation: "Injection sclérosante périanale chez l'enfant",
    tarifSecteur1Optam: "90.75",
    tarifHorsSecteur1HorsOptam: "58.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HKLB002",
    designation: "Injection sousfissuraire et/ou intrasphinctérienne de l'anus",
    tarifSecteur1Optam: "21.27",
    tarifHorsSecteur1HorsOptam: "18.39",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HJAD001",
    designation: "Dilatation ou incision de sténose anorectale",
    tarifSecteur1Optam: "86.06",
    tarifHorsSecteur1HorsOptam: "66.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKCA004",
    designation:
      "Suture de plaie de l'anus, sans réparation du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "53.88",
    tarifHorsSecteur1HorsOptam: "48.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKCA003",
    designation:
      "Suture de plaie de l'anus, avec réparation du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "266.58",
    tarifHorsSecteur1HorsOptam: "220.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKMA001",
    designation: "Plastie cutanée de la marge de l'anus",
    tarifSecteur1Optam: "159.53",
    tarifHorsSecteur1HorsOptam: "136.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKCA005",
    designation: "Myorraphie préanale des muscles élévateurs de l'anus",
    tarifSecteur1Optam: "197.81",
    tarifHorsSecteur1HorsOptam: "158.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKCA002",
    designation: "Myorraphie rétroanale des muscles élévateurs de l'anus",
    tarifSecteur1Optam: "214.83",
    tarifHorsSecteur1HorsOptam: "168.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKCA001",
    designation:
      "Myorraphie préanale et rétroanale des muscles élévateurs de l'anus",
    tarifSecteur1Optam: "270.13",
    tarifHorsSecteur1HorsOptam: "200.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA006",
    designation: "Incision d'abcès de la région anale",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA005",
    designation:
      "Mise à plat d'abcès et/ou de fistule intersphinctérien haut [intramural] de l'anus",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "156.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA008",
    designation:
      "Mise à plat d'abcès et/ou de fistule haut de l'anus [transsphinctérien supérieur] ou à trajet complexe multiramifié, avec lambeau d'avancement",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "181.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA004",
    designation:
      "Mise à plat d'abcès et/ou de fistule bas de l'anus [transsphinctérien inférieur] en un temps, par fistulotomie ou fistulectomie",
    tarifSecteur1Optam: "157.4",
    tarifHorsSecteur1HorsOptam: "135.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA002",
    designation:
      "Mise à plat de fistule basse [transsphinctérienne inférieure] de l'anus avec résection d'un paquet hémorroïdaire isolé",
    tarifSecteur1Optam: "158.82",
    tarifHorsSecteur1HorsOptam: "148.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA001",
    designation:
      "Mise à plat de fistule basse [transsphinctérienne inférieure] de l'anus avec hémorroïdectomie pédiculaire par résection sousmuqueuse",
    tarifSecteur1Optam: "233.97",
    tarifHorsSecteur1HorsOptam: "201.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA003",
    designation: "Sphinctérotomie interne [Léiomyotomie] latérale de l'anus",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA009",
    designation: "Sphinctéromyectomie de l'anus, par abord anal",
    tarifSecteur1Optam: "187.18",
    tarifHorsSecteur1HorsOptam: "134.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKPA009",
    designation:
      "Sphinctérotomie, sphinctéromyotomie ou sphinctéromyectomie de l'anus, par abord transsacrococcygien [de Kraske]",
    tarifSecteur1Optam: "258.08",
    tarifHorsSecteur1HorsOptam: "176.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA002",
    designation: "Résection d'une fissure anale infectée",
    tarifSecteur1Optam: "96.42",
    tarifHorsSecteur1HorsOptam: "82.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA004",
    designation: "Excision d'une fissure anale [Fissurectomie anale]",
    tarifSecteur1Optam: "63.1",
    tarifHorsSecteur1HorsOptam: "62.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA005",
    designation:
      "Excision d'une fissure et/ou d'une sténose anale, avec anoplastie muqueuse",
    tarifSecteur1Optam: "138.26",
    tarifHorsSecteur1HorsOptam: "137.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA006",
    designation:
      "Excision d'une fissure anale, avec anoplastie muqueuse et résection d'un paquet hémorroïdaire isolé",
    tarifSecteur1Optam: "160.94",
    tarifHorsSecteur1HorsOptam: "150.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA008",
    designation:
      "Destruction et/ou exérèse de lésion superficielle non tumorale de l'anus",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKND001",
    designation: "Destruction d'une papillomatose extensive de l'anus",
    tarifSecteur1Optam: "70.9",
    tarifHorsSecteur1HorsOptam: "57.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA001",
    designation: "Destruction et/ou exérèse de tumeur bénigne du canal anal",
    tarifSecteur1Optam: "58.14",
    tarifHorsSecteur1HorsOptam: "41.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKFA007",
    designation: "Exérèse de tumeur maligne du canal anal et/ou de l'anus",
    tarifSecteur1Optam: "137.55",
    tarifHorsSecteur1HorsOptam: "87.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKMA003",
    designation:
      "Suture du muscle sphincter externe de l'anus [Sphinctérorraphie anale directe] pour incontinence anale",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "242.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKMA002",
    designation:
      "Suture du muscle sphincter externe de l'anus [Sphinctérorraphie anale directe] pour incontinence anale, avec myorraphie des muscles élévateurs de l'anus",
    tarifSecteur1Optam: "260.91",
    tarifHorsSecteur1HorsOptam: "239.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKMA004",
    designation:
      "Reconstruction du muscle sphincter externe de l'anus par transposition musculaire unilatérale",
    tarifSecteur1Optam: "489.21",
    tarifHorsSecteur1HorsOptam: "371.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKMA005",
    designation:
      "Reconstruction du muscle sphincter externe de l'anus par transposition musculaire bilatérale",
    tarifSecteur1Optam: "570.75",
    tarifHorsSecteur1HorsOptam: "418.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKLA002",
    designation: "Pose d'un sphincter anal artificiel",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKGA001",
    designation: "Ablation ou changement d'un sphincter anal artificiel",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKLA001",
    designation:
      "Implantation d'un électrostimulateur du muscle sphincter externe de l'anus ou d'un lambeau de plastie de ce muscle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKKA001",
    designation:
      "Changement d'un électrostimulateur du muscle sphincter externe de l'anus ou d'un lambeau de plastie de ce muscle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJEA003",
    designation:
      "Abaissement du rectum pour malformation anorectale haute, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "546.6",
    tarifHorsSecteur1HorsOptam: "546.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJEA002",
    designation:
      "Abaissement du rectum pour malformation anorectale haute ou intermédiaire, par abord transsacrococcygien [de Kraske] et par abord périnéal",
    tarifSecteur1Optam: "579.96",
    tarifHorsSecteur1HorsOptam: "537.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJEA004",
    designation:
      "Abaissement du rectum pour malformation anorectale haute ou intermédiaire, par abord périnéal médian",
    tarifSecteur1Optam: "526.03",
    tarifHorsSecteur1HorsOptam: "526.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJEA001",
    designation:
      "Abaissement du rectum avec cervicocystoplastie, urétroplastie et vaginoplastie pour malformation anorectale haute, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "699.57",
    tarifHorsSecteur1HorsOptam: "699.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HJMA001",
    designation: "Anoplastie pour malformation anorectale basse",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKMA006",
    designation:
      "Anoplastie pour malformation anorectale basse, avec transposition de l'anus",
    tarifSecteur1Optam: "467.23",
    tarifHorsSecteur1HorsOptam: "354.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HKEA001",
    designation: "Transposition postérieure de l'anus",
    tarifSecteur1Optam: "395.62",
    tarifHorsSecteur1HorsOptam: "312.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HTRD001",
    designation:
      "Séance de rééducation anorectale avec rétrocontrôle [biofeedback] manométrique intracavitaire, sans électrostimulation",
    tarifSecteur1Optam: "48.43",
    tarifHorsSecteur1HorsOptam: "33.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HTRD002",
    designation:
      "Séance de rééducation anorectale avec rétrocontrôle [biofeedback] manométrique intracavitaire et électrostimulation",
    tarifSecteur1Optam: "62.73",
    tarifHorsSecteur1HorsOptam: "42.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HKSD001",
    designation: "Hémostase secondaire à un acte sur l'anus",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCJD001",
    designation:
      "Irrigation-drainage d'une fistule d'un viscère abdominal, par 24 heures",
    tarifSecteur1Optam: "33.5",
    tarifHorsSecteur1HorsOptam: "22.65",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMCH001",
    designation:
      "Cholécystostomie, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "170.75",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMCA009",
    designation: "Cholécystostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "207.74",
    tarifHorsSecteur1HorsOptam: "173.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMAH001",
    designation:
      "Dilatation de conduit biliaire, par un drain transcutané déjà en place avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMAH002",
    designation:
      "Dilatation de conduit biliaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMAE002",
    designation:
      "Dilatation rétrograde de conduit biliaire, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "340.41",
    tarifHorsSecteur1HorsOptam: "340.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMAE001",
    designation:
      "Dilatation de l'ampoule hépatopancréatique [du sphincter d'Oddi], par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "266.13",
    tarifHorsSecteur1HorsOptam: "266.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMJH006",
    designation:
      "Drainage externe d'un conduit biliaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "204.66",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJH005",
    designation:
      "Drainage externe d'un conduit biliaire avec pose d'une endoprothèse, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "406.9",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJH003",
    designation:
      "Drainage externe de plusieurs conduits biliaires, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "354.82",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJH001",
    designation:
      "Drainage externe de plusieurs conduits biliaires avec pose d'endoprothèse, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "634.56",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJH004",
    designation:
      "Drainage externe de conduit biliaire, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "151.38",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJH002",
    designation:
      "Drainage internalisé d'un conduit biliaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "311.23",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJH007",
    designation:
      "Drainage internalisé de plusieurs conduits biliaires, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "491.67",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: '"HMJE127',
  },
  {
    codeCCAM: '"',
    designation:
      "Drainage biliaire avec pose d’endoprothèse transluminale d’apposition biliogastrique ou bilioduodénale, par oeso-gastroduodénoscopie, avec guidage échoendoscopique",
    tarifSecteur1Optam: "490.96",
    tarifHorsSecteur1HorsOptam: "490.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMMA001",
    designation:
      "Internalisation secondaire d'un drainage biliaire externe déjà en place",
    tarifSecteur1Optam: "164.7",
    tarifHorsSecteur1HorsOptam: "121.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMMA002",
    designation:
      "Internalisation secondaire de plusieurs drainages biliaires externes déjà en place",
    tarifSecteur1Optam: "205.87",
    tarifHorsSecteur1HorsOptam: "145.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMPE001",
    designation:
      "Section du versant biliaire du muscle sphincter de l'ampoule hépatopancréatique [sphincter d'Oddi], par œso-gastro-duodénoscopie [Sphinctérotomie biliaire endoscopique]",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMLH003",
    designation:
      "Pose d'une endoprothèse dans un conduit biliaire préalablement drainé, avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "132",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMLH002",
    designation:
      "Pose de plusieurs endoprothèses dans des conduits biliaires préalablement drainés, avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "198.6",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMLE002",
    designation:
      "Pose d'une endoprothèse biliaire, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "410.7",
    tarifHorsSecteur1HorsOptam: "410.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMLE003",
    designation:
      "Pose de plusieurs endoprothèses biliaires, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "486.06",
    tarifHorsSecteur1HorsOptam: "486.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMLC001",
    designation:
      "Pose d'endoprothèse biliaire par cholédochotomie, par cœlioscopie",
    tarifSecteur1Optam: "403.28",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMLA001",
    designation:
      "Pose d'endoprothèse biliaire par cholédochotomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMKH001",
    designation:
      "Changement de drain biliaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMGH002",
    designation:
      "Ablation ou changement d'endoprothèse biliaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "409.32",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMGH003",
    designation:
      "Ablation ou changement d'endoprothèse biliaire, par un drain transcutané déjà en place avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "240.99",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMGE001",
    designation:
      "Ablation d'endoprothèse biliaire et/ou pancréatique, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMKE001",
    designation:
      "Changement d'une endoprothèse biliaire, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "268.8",
    tarifHorsSecteur1HorsOptam: "268.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMKE002",
    designation:
      "Changement de plusieurs endoprothèses biliaires, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "383.39",
    tarifHorsSecteur1HorsOptam: "383.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLJC001",
    designation: "Évacuation de collection hépatique, par cœlioscopie",
    tarifSecteur1Optam: "282.89",
    tarifHorsSecteur1HorsOptam: "242.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLJA001",
    designation: "Évacuation de collection hépatique, par laparotomie",
    tarifSecteur1Optam: "259.49",
    tarifHorsSecteur1HorsOptam: "216.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLCC001",
    designation: "Fenestration de kystes biliaires hépatiques, par cœlioscopie",
    tarifSecteur1Optam: "343.16",
    tarifHorsSecteur1HorsOptam: "277.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLCA002",
    designation: "Fenestration de kystes biliaires hépatiques, par laparotomie",
    tarifSecteur1Optam: "285.02",
    tarifHorsSecteur1HorsOptam: "217.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCC003",
    designation:
      "Cholécystogastrostomie ou cholécystoduodénostomie, par cœlioscopie",
    tarifSecteur1Optam: "403.28",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA003",
    designation:
      "Cholécystogastrostomie ou cholécystoduodénostomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA010",
    designation:
      "Cholécystogastrostomie ou cholécystoduodénostomie avec gastrojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA001",
    designation:
      "Cholécystoduodénostomie pour atrésie des conduits biliaires extrahépatiques, par laparotomie",
    tarifSecteur1Optam: "479.28",
    tarifHorsSecteur1HorsOptam: "435.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA011",
    designation: "Cholécystojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCC002",
    designation: "Cholédochoduodénostomie, par cœlioscopie",
    tarifSecteur1Optam: "469.36",
    tarifHorsSecteur1HorsOptam: "441.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA002",
    designation: "Cholédochoduodénostomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCC013",
    designation:
      "Cholédochoduodénostomie avec gastrojéjunostomie, par cœlioscopie",
    tarifSecteur1Optam: "507.78",
    tarifHorsSecteur1HorsOptam: "507.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA004",
    designation:
      "Cholédochoduodénostomie avec gastrojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCC001",
    designation: "Cholédochojéjunostomie, par cœlioscopie",
    tarifSecteur1Optam: "484.25",
    tarifHorsSecteur1HorsOptam: "468.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA006",
    designation: "Cholédochojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "428.24",
    tarifHorsSecteur1HorsOptam: "423.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA008",
    designation:
      "Anastomose biliodigestive portant sur la convergence des conduits hépatiques, par laparotomie",
    tarifSecteur1Optam: "653.7",
    tarifHorsSecteur1HorsOptam: "624.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA007",
    designation:
      "Anastomose biliodigestive au-dessus de la convergence portant sur plusieurs conduits biliaires, par laparotomie",
    tarifSecteur1Optam: "860.73",
    tarifHorsSecteur1HorsOptam: "744.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMCA005",
    designation:
      "Anastomose biliodigestive intrahépatique portant sur un conduit biliaire segmentaire, par laparotomie",
    tarifSecteur1Optam: "698.37",
    tarifHorsSecteur1HorsOptam: "650.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMGH004",
    designation:
      "Ablation de calcul des voies biliaires, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "314.86",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMGE002",
    designation:
      "Ablation de calcul de la voie biliaire principale, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "330.2",
    tarifHorsSecteur1HorsOptam: "330.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMGC001",
    designation:
      "Ablation de calcul de la voie biliaire principale par cholédochotomie, par cœlioscopie",
    tarifSecteur1Optam: "406.26",
    tarifHorsSecteur1HorsOptam: "405",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMGA001",
    designation:
      "Ablation de calcul de la voie biliaire principale par cholédochotomie, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMNM001",
    designation: "Lithotritie extracorporelle des conduits biliaires",
    tarifSecteur1Optam: "265.04",
    tarifHorsSecteur1HorsOptam: "259.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMNE001",
    designation:
      "Lithotritie mécanique des conduits biliaires, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "346.09",
    tarifHorsSecteur1HorsOptam: "346.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMNE002",
    designation:
      "Lithotritie des conduits biliaires par ondes de choc, par cholangioscopie rétrograde",
    tarifSecteur1Optam: "479.96",
    tarifHorsSecteur1HorsOptam: "479.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMNJ002",
    designation:
      "Lithotritie des conduits biliaires par ondes de choc, par cholangioscopie par un drain transcutané déjà en place",
    tarifSecteur1Optam: "480.2",
    tarifHorsSecteur1HorsOptam: "383.85",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMNJ003",
    designation:
      "Lithotritie des conduits biliaires par ondes de choc, par cholangioscopie par voie transcutanée",
    tarifSecteur1Optam: "383.85",
    tarifHorsSecteur1HorsOptam: "383.85",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMFC004",
    designation: "Cholécystectomie, par cœlioscopie",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "272.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA007",
    designation: "Cholécystectomie, par laparotomie",
    tarifSecteur1Optam: "251.7",
    tarifHorsSecteur1HorsOptam: "251.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFC001",
    designation:
      "Cholécystectomie avec ablation transcystique de calcul de la voie biliaire principale, par cœlioscopie",
    tarifSecteur1Optam: "375.77",
    tarifHorsSecteur1HorsOptam: "323.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA004",
    designation:
      "Cholécystectomie avec ablation transcystique de calcul de la voie biliaire principale, par laparotomie",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "269.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFC002",
    designation:
      "Cholécystectomie avec ablation de calcul de la voie biliaire principale par cholédochotomie, par cœlioscopie",
    tarifSecteur1Optam: "471.49",
    tarifHorsSecteur1HorsOptam: "442.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA003",
    designation:
      "Cholécystectomie par cœlioscopie, avec ablation de calcul de la voie biliaire principale par cholédochotomie, par laparotomie",
    tarifSecteur1Optam: "439.58",
    tarifHorsSecteur1HorsOptam: "412.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA008",
    designation:
      "Cholécystectomie avec ablation de calcul de la voie biliaire principale par cholédochotomie, par laparotomie",
    tarifSecteur1Optam: "380.73",
    tarifHorsSecteur1HorsOptam: "378.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFC003",
    designation:
      "Cholécystectomie avec cholédochogastrostomie ou cholédochoduodénostomie, par cœlioscopie",
    tarifSecteur1Optam: "499.88",
    tarifHorsSecteur1HorsOptam: "499.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA006",
    designation:
      "Cholécystectomie par cœlioscopie, avec cholédochoduodénostomie par laparotomie",
    tarifSecteur1Optam: "499.88",
    tarifHorsSecteur1HorsOptam: "499.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA002",
    designation:
      "Cholécystectomie avec cholédochogastrostomie ou cholédochoduodénostomie, par laparotomie",
    tarifSecteur1Optam: "499.88",
    tarifHorsSecteur1HorsOptam: "499.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFC005",
    designation:
      "Cholécystectomie avec cholédochojéjunostomie, par cœlioscopie",
    tarifSecteur1Optam: "599.11",
    tarifHorsSecteur1HorsOptam: "575.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA005",
    designation:
      "Cholécystectomie par cœlioscopie, avec cholédochojéjunostomie par laparotomie",
    tarifSecteur1Optam: "564.36",
    tarifHorsSecteur1HorsOptam: "555.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA001",
    designation:
      "Cholécystectomie avec cholédochojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "543.4",
    tarifHorsSecteur1HorsOptam: "543.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA009",
    designation:
      "Résection de la voie biliaire principale pédiculaire avec anastomose biliodigestive, par laparotomie",
    tarifSecteur1Optam: "742.32",
    tarifHorsSecteur1HorsOptam: "675.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMFA010",
    designation:
      "Résection de la voie biliaire principale pédiculaire et intrapancréatique avec anastomose biliodigestive, par laparotomie",
    tarifSecteur1Optam: "913.19",
    tarifHorsSecteur1HorsOptam: "774.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLNM001",
    designation:
      "Destruction de tumeur hépatique par radiofréquence, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "169.54",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLNK001",
    designation:
      "Destruction de tumeur hépatique par radiofréquence, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLNN900",
    designation:
      "Destruction de tumeur hépatique par radiofréquence, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HLNC003",
    designation:
      "Destruction de tumeur hépatique avec courant de radiofréquence, par cœlioscopie",
    tarifSecteur1Optam: "333.23",
    tarifHorsSecteur1HorsOptam: "304,15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLNA007",
    designation:
      "Destruction de tumeur hépatique avec courant de radiofréquence, par laparotomie",
    tarifSecteur1Optam: "319.05",
    tarifHorsSecteur1HorsOptam: "291,21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA014",
    designation: "Séquestrectomie hépatique, par laparotomie",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "405.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA012",
    designation: "Kystectomie ou périkystectomie hépatique, par laparotomie",
    tarifSecteur1Optam: "479.28",
    tarifHorsSecteur1HorsOptam: "378.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA002",
    designation:
      "Résection du dôme saillant de kyste hydatique du foie, par laparotomie",
    tarifSecteur1Optam: "399.88",
    tarifHorsSecteur1HorsOptam: "332.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFC003",
    designation: "Résection atypique du foie, par cœlioscopie",
    tarifSecteur1Optam: "477.16",
    tarifHorsSecteur1HorsOptam: "406.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA019",
    designation: "Résection atypique du foie, par laparotomie",
    tarifSecteur1Optam: "365.84",
    tarifHorsSecteur1HorsOptam: "330.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFC004",
    designation: "Unisegmentectomie hépatique, par cœlioscopie",
    tarifSecteur1Optam: "477.16",
    tarifHorsSecteur1HorsOptam: "406.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA020",
    designation: "Unisegmentectomie hépatique, par laparotomie",
    tarifSecteur1Optam: "365.84",
    tarifHorsSecteur1HorsOptam: "330.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA003",
    designation:
      "Résection du lobe caudé [de Spigel] [segment I] du foie, par laparotomie",
    tarifSecteur1Optam: "827.4",
    tarifHorsSecteur1HorsOptam: "598.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFC027",
    designation: "Bisegmentectomie hépatique, par cœlioscopie",
    tarifSecteur1Optam: "723.96",
    tarifHorsSecteur1HorsOptam: "590.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA009",
    designation: "Bisegmentectomie hépatique, par laparotomie",
    tarifSecteur1Optam: "636.68",
    tarifHorsSecteur1HorsOptam: "540.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFC032",
    designation: "Trisegmentectomie hépatique, par cœlioscopie",
    tarifSecteur1Optam: "1101.84",
    tarifHorsSecteur1HorsOptam: "809.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA006",
    designation: "Trisegmentectomie hépatique, par laparotomie",
    tarifSecteur1Optam: "1014.58",
    tarifHorsSecteur1HorsOptam: "759.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFC002",
    designation: "Lobectomie hépatique gauche, par cœlioscopie",
    tarifSecteur1Optam: "875.62",
    tarifHorsSecteur1HorsOptam: "765.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA011",
    designation: "Lobectomie hépatique gauche, par laparotomie",
    tarifSecteur1Optam: "657.24",
    tarifHorsSecteur1HorsOptam: "626.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFC037",
    designation: "Hépatectomie gauche, par cœlioscopie",
    tarifSecteur1Optam: "827.86",
    tarifHorsSecteur1HorsOptam: "737.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA018",
    designation: "Hépatectomie gauche, par laparotomie",
    tarifSecteur1Optam: "799.04",
    tarifHorsSecteur1HorsOptam: "708.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA007",
    designation:
      "Hépatectomie gauche élargie au lobe caudé [de Spigel] [segment I], par laparotomie",
    tarifSecteur1Optam: "970.62",
    tarifHorsSecteur1HorsOptam: "808.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA017",
    designation: "Hépatectomie droite, par laparotomie",
    tarifSecteur1Optam: "1016.71",
    tarifHorsSecteur1HorsOptam: "834.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA004",
    designation:
      "Hépatectomie droite élargie au lobe caudé [de Spigel] [segment I], par laparotomie",
    tarifSecteur1Optam: "1199.63",
    tarifHorsSecteur1HorsOptam: "940.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA005",
    designation:
      "Lobectomie hépatique droite [Hépatectomie droite élargie au segment IV], par laparotomie",
    tarifSecteur1Optam: "1245.71",
    tarifHorsSecteur1HorsOptam: "967.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA010",
    designation: "Hépatectomie centrale, par laparotomie",
    tarifSecteur1Optam: "1191.83",
    tarifHorsSecteur1HorsOptam: "936.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA016",
    designation:
      "Prélèvement d'un greffon hépatique total, chez un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA008",
    designation:
      "Prélèvement d'un greffon hépatique total chez un sujet en état de mort encéphalique, avec hépatectomie de réduction",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA001",
    designation:
      "Prélèvement d'un greffon hépatique total chez un sujet en état de mort encéphalique, avec partage du foie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLFA015",
    designation: "Prélèvement d'un greffon hépatique, chez un sujet vivant",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLEA002",
    designation: "Transplantation de foie réduit",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLEA001",
    designation: "Transplantation de foie total",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLSC012",
    designation: "Hémostase de lésion du foie, par cœlioscopie",
    tarifSecteur1Optam: "540.7",
    tarifHorsSecteur1HorsOptam: "408.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLSA001",
    designation: "Hémostase de lésion du foie, par laparotomie",
    tarifSecteur1Optam: "511.9",
    tarifHorsSecteur1HorsOptam: "379.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HLCA001",
    designation:
      "Hépato-porto-cholécystostomie ou hépato-porto-entérostomie pour atrésie des conduits biliaires extrahépatiques, par laparotomie",
    tarifSecteur1Optam: "642.71",
    tarifHorsSecteur1HorsOptam: "642.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNAE001",
    designation:
      "Dilatation du conduit pancréatique, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "365.94",
    tarifHorsSecteur1HorsOptam: "365.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNLE001",
    designation:
      "Pose d'une endoprothèse du conduit pancréatique, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "424.31",
    tarifHorsSecteur1HorsOptam: "424.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNKE001",
    designation:
      "Changement d'une endoprothèse du conduit pancréatique, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "268.8",
    tarifHorsSecteur1HorsOptam: "268.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNPE002",
    designation:
      "Section du versant pancréatique du muscle sphincter de l'ampoule hépatopancréatique [sphincter d'Oddi], par œso-gastro-duodénoscopie [Sphinctérotomie pancréatique endoscopique]",
    tarifSecteur1Optam: "331.34",
    tarifHorsSecteur1HorsOptam: "331.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNPE003",
    designation:
      "Sphinctérotomie de la papille duodénale mineure [papille accessoire], par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "371.04",
    tarifHorsSecteur1HorsOptam: "371.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNGE001",
    designation:
      "Ablation de calcul pancréatique, par œso-gastro-duodénoscopie",
    tarifSecteur1Optam: "409.05",
    tarifHorsSecteur1HorsOptam: "409.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNNM001",
    designation: "Lithotritie extracorporelle du pancréas",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNJC001",
    designation: "Drainage externe de collection pancréatique, par cœlioscopie",
    tarifSecteur1Optam: "403.28",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNJA001",
    designation: "Drainage externe de collection pancréatique, par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCH001",
    designation:
      "Anastomose entre un faux kyste du pancréas et l'estomac ou le duodénum, par voie transcutanée avec guidage échographique et/ou radiologique [Kystogastrostomie ou kystoduodénostomie transcutanée]",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HNCE001",
    designation:
      "Anastomose entre un faux kyste du pancréas et l'estomac ou le duodénum, par œso-gastro-duodénoscopie sans guidage [Kystogastrostomie ou kystoduodénostomie endoscopique]",
    tarifSecteur1Optam: "258.3",
    tarifHorsSecteur1HorsOptam: "258.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCJ001",
    designation:
      "Anastomose entre une cavité collectée du pancréas et l'estomac ou le duodénum, par œso-gastro-duodénoscopie avec guidage échoendoscopique",
    tarifSecteur1Optam: "289.39",
    tarifHorsSecteur1HorsOptam: "289.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCC021",
    designation:
      "Anastomose entre un faux kyste du pancréas et l'estomac [Kystogastrostomie], par cœlioscopie",
    tarifSecteur1Optam: "403.28",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA008",
    designation:
      "Anastomose entre un faux kyste du pancréas et l'estomac [Kystogastrostomie], par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA001",
    designation:
      "Anastomose entre un faux kyste du pancréas et le duodénum [Kystoduodénostomie], par laparotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCC033",
    designation:
      "Anastomose entre un faux kyste du pancréas et le jéjunum [Kystojéjunostomie], par cœlioscopie",
    tarifSecteur1Optam: "440.73",
    tarifHorsSecteur1HorsOptam: "415.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA005",
    designation:
      "Anastomose entre un faux kyste du pancréas et le jéjunum [Kystojéjunostomie], par laparotomie",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "396.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA006",
    designation:
      "Suture de plaie du pancréas avec reconstruction du conduit pancréatique, par laparotomie",
    tarifSecteur1Optam: "479.99",
    tarifHorsSecteur1HorsOptam: "435.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA007",
    designation: "Anastomose pancréaticojéjunale, par laparotomie",
    tarifSecteur1Optam: "465.1",
    tarifHorsSecteur1HorsOptam: "427.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA002",
    designation:
      "Anastomose pancréaticojéjunale avec anastomose biliojéjunale, par laparotomie",
    tarifSecteur1Optam: "635.73",
    tarifHorsSecteur1HorsOptam: "635.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA004",
    designation:
      "Anastomose pancréaticojéjunale avec gastrojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "538.79",
    tarifHorsSecteur1HorsOptam: "538.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNCA003",
    designation:
      "Anastomose pancréaticojéjunale avec anastomose biliojéjunale et gastrojéjunostomie, par laparotomie",
    tarifSecteur1Optam: "727.43",
    tarifHorsSecteur1HorsOptam: "688.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFC027",
    designation: "Nécrosectomie pancréatique, par cœlioscopie",
    tarifSecteur1Optam: "409.52",
    tarifHorsSecteur1HorsOptam: "406.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA012",
    designation: "Nécrosectomie pancréatique, par laparotomie",
    tarifSecteur1Optam: "380.73",
    tarifHorsSecteur1HorsOptam: "378.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFC001",
    designation: "Exérèse de tumeur du pancréas, par cœlioscopie",
    tarifSecteur1Optam: "640.94",
    tarifHorsSecteur1HorsOptam: "541.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA005",
    designation: "Exérèse de tumeur du pancréas, par laparotomie",
    tarifSecteur1Optam: "508.35",
    tarifHorsSecteur1HorsOptam: "452.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFC028",
    designation:
      "Pancréatectomie gauche avec conservation de la rate, par cœlioscopie",
    tarifSecteur1Optam: "681.07",
    tarifHorsSecteur1HorsOptam: "564.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA008",
    designation:
      "Pancréatectomie gauche avec conservation de la rate, par laparotomie",
    tarifSecteur1Optam: "652.28",
    tarifHorsSecteur1HorsOptam: "535.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA002",
    designation:
      "Pancréatectomie gauche avec conservation de la rate, avec anastomose pancréatojéjunale ou pancréaticojéjunale, par laparotomie",
    tarifSecteur1Optam: "801.17",
    tarifHorsSecteur1HorsOptam: "621.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFC002",
    designation:
      "Pancréatectomie gauche avec splénectomie [Splénopancréatectomie gauche], par cœlioscopie",
    tarifSecteur1Optam: "701.91",
    tarifHorsSecteur1HorsOptam: "608.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA013",
    designation:
      "Pancréatectomie gauche avec splénectomie [Splénopancréatectomie gauche], par laparotomie",
    tarifSecteur1Optam: "584.22",
    tarifHorsSecteur1HorsOptam: "540.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA010",
    designation:
      "Pancréatectomie gauche avec splénectomie [Splénopancréatectomie gauche] avec anastomose pancréatojéjunale ou pancréaticojéjunale, par laparotomie",
    tarifSecteur1Optam: "794.08",
    tarifHorsSecteur1HorsOptam: "661.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA001",
    designation:
      "Isthmectomie pancréatique avec rétablissement de continuité du conduit pancréatique, par laparotomie",
    tarifSecteur1Optam: "721.05",
    tarifHorsSecteur1HorsOptam: "575.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA011",
    designation:
      "Pancréatectomie totale ou subtotale avec conservation du duodénum, sans splénectomie, par laparotomie",
    tarifSecteur1Optam: "628.17",
    tarifHorsSecteur1HorsOptam: "521.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA006",
    designation:
      "Pancréatectomie totale ou subtotale avec conservation du duodénum et splénectomie, par laparotomie",
    tarifSecteur1Optam: "908.94",
    tarifHorsSecteur1HorsOptam: "728.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA007",
    designation: "Duodénopancréatectomie céphalique, par laparotomie",
    tarifSecteur1Optam: "1263.44",
    tarifHorsSecteur1HorsOptam: "1021.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA004",
    designation:
      "Duodénopancréatectomie totale avec splénectomie [Splénopancréatectomie totale], par laparotomie",
    tarifSecteur1Optam: "1376.17",
    tarifHorsSecteur1HorsOptam: "1131.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNFA009",
    designation:
      "Prélèvement d'un greffon pancréatique, chez un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNEA900",
    designation: "Transplantation du pancréas, par laparotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNEA002",
    designation: "Transplantation du pancréas et du rein, par laparotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HNEH606",
    designation:
      "Transplantation d'îlots pancréatiques par voie intraportale transhépatique transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPLA004",
    designation:
      "Pose d'un cathéter intrapéritonéal par voie transcutanée, avec pose d'un système diffuseur implantable souscutané",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "126.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPLB002",
    designation:
      "Séance d'injection intrapéritonéale d'agent pharmacologique anticancéreux, par voie transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPLB007",
    designation:
      "Séance d'administration intrapéritonéale d'agent pharmacologique anticancéreux, par un dispositif implanté",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPLA423",
    designation:
      "Administration intrapéritonéale d'agent pharmacologique anticancéreux avec hyperthermie [Chimiohyperthermie anticancéreuse intrapéritonéale], sans cytoréduction péritonéale, sous anesthésie générale",
    tarifSecteur1Optam: "349.54",
    tarifHorsSecteur1HorsOptam: "349.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPLA335",
    designation:
      "Administration intrapéritonéale d'agent pharmacologique anticancéreux avec hyperthermie [Chimiohyperthermie anticancéreuse intrapéritonéale], avec cytoréduction péritonéale, sous anesthésie générale",
    tarifSecteur1Optam: "768.56",
    tarifHorsSecteur1HorsOptam: "768.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPMB001",
    designation:
      "Création d'un pneumopéritoine à visée thérapeutique, par voie transcutanée",
    tarifSecteur1Optam: "45.6",
    tarifHorsSecteur1HorsOptam: "34.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPLB004",
    designation:
      "Pose d'un cathéter intrapéritonéal pour dialyse péritonéale, par voie transcutanée",
    tarifSecteur1Optam: "85.37",
    tarifHorsSecteur1HorsOptam: "73.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPLC035",
    designation:
      "Pose ou repositionnement d'un cathéter intrapéritonéal pour dialyse péritonéale, par cœlioscopie",
    tarifSecteur1Optam: "146.42",
    tarifHorsSecteur1HorsOptam: "140.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPLA005",
    designation:
      "Pose ou repositionnement d'un cathéter intrapéritonéal pour dialyse péritonéale, par laparotomie",
    tarifSecteur1Optam: "117.61",
    tarifHorsSecteur1HorsOptam: "112.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPP002",
    designation: "Désobstruction d'un cathéter de dialyse péritonéale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPJP001",
    designation:
      "Pelage du manchon externe d'un cathéter de dialyse péritonéale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPPA004",
    designation:
      "Désenfouissement de l'extrémité souscutanée d'un cathéter de dialyse péritonéale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPC005",
    designation:
      "Ablation d'un cathéter de dialyse péritonéale, par cœlioscopie",
    tarifSecteur1Optam: "133.3",
    tarifHorsSecteur1HorsOptam: "133.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPGA001",
    designation:
      "Ablation d'un cathéter de dialyse péritonéale, par laparotomie",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPKB001",
    designation:
      "Changement d'un cathéter de dialyse péritonéale, par voie transcutanée",
    tarifSecteur1Optam: "74.63",
    tarifHorsSecteur1HorsOptam: "67.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPKC014",
    designation:
      "Changement d'un cathéter de dialyse péritonéale, par cœlioscopie",
    tarifSecteur1Optam: "209.09",
    tarifHorsSecteur1HorsOptam: "177.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPKA002",
    designation:
      "Changement d'un cathéter de dialyse péritonéale, par laparotomie",
    tarifSecteur1Optam: "180.29",
    tarifHorsSecteur1HorsOptam: "148.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPLA001",
    designation:
      "Pose d'un cathéter intrapéritonéal avec pose d'un système diffuseur implantable souscutané, pour insulinothérapie intrapéritonéale",
    tarifSecteur1Optam: "127.77",
    tarifHorsSecteur1HorsOptam: "127.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPC004",
    designation:
      "Libération de l'extrémité distale du cathéter d'un système diffuseur implanté pour insulinothérapie intrapéritonéale, par cœlioscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPKA001",
    designation:
      "Changement du cathéter intrapéritonéal d'un système diffuseur implanté pour insulinothérapie intrapéritonéale, par laparotomie",
    tarifSecteur1Optam: "147.1",
    tarifHorsSecteur1HorsOptam: "147.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPLA002",
    designation:
      "Pose d'un ballon intrapéritonéal avant irradiation pelvienne, par laparotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPGA002",
    designation: "Ablation d'un ballon intrapéritonéal, par laparotomie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPJB001",
    designation:
      "Évacuation d'un épanchement intrapéritonéal, par voie transcutanée",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCJC001",
    designation:
      "Évacuation de collection intraabdominale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "187.89",
    tarifHorsSecteur1HorsOptam: "187.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCJA005",
    designation: "Évacuation de collection intraabdominale, par thoracotomie",
    tarifSecteur1Optam: "343.16",
    tarifHorsSecteur1HorsOptam: "277.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCJA002",
    designation: "Évacuation d'une collection intraabdominale, par laparotomie",
    tarifSecteur1Optam: "187.24",
    tarifHorsSecteur1HorsOptam: "187.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCJA004",
    designation:
      "Évacuation de plusieurs collections intraabdominales, par laparotomie",
    tarifSecteur1Optam: "302.74",
    tarifHorsSecteur1HorsOptam: "254.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCJA003",
    designation: "Évacuation de collection intraabdominale, par colpotomie",
    tarifSecteur1Optam: "187.24",
    tarifHorsSecteur1HorsOptam: "187.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCJA001",
    designation: "Évacuation de collection intraabdominale, par rectotomie",
    tarifSecteur1Optam: "187.24",
    tarifHorsSecteur1HorsOptam: "187.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPCA001",
    designation: "Dérivation péritonéoveineuse",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCNH001",
    designation:
      "Sclérose d'un kyste intraabdominal par injection d'agent pharmacologique, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCNH004",
    designation:
      "Sclérose de plusieurs kystes intraabdominaux par injection d'agent pharmacologique, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCNH003",
    designation:
      "Sclérose de kyste intraabdominal par injection d'agent pharmacologique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCNH005",
    designation:
      "Sclérose de tumeur intraabdominale par injection intralésionnelle d'agent pharmacologique, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCNH002",
    designation:
      "Sclérose de tumeur intraabdominale par injection intralésionnelle d'agent pharmacologique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPPC001",
    designation:
      "Libération d'adhérences [Adhésiolyse] ni étendues ni serrées du péritoine pelvien pour stérilité chez la femme, par cœlioscopie",
    tarifSecteur1Optam: "168.85",
    tarifHorsSecteur1HorsOptam: "130.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPA001",
    designation:
      "Libération d'adhérences [Adhésiolyse] ni étendues ni serrées du péritoine pelvien pour stérilité chez la femme, par laparotomie",
    tarifSecteur1Optam: "147.4",
    tarifHorsSecteur1HorsOptam: "129.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPC002",
    designation:
      "Libération d'adhérences [Adhésiolyse] étendues et/ou serrées du péritoine pelvien pour stérilité chez la femme, par cœlioscopie",
    tarifSecteur1Optam: "268.5",
    tarifHorsSecteur1HorsOptam: "238.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPA003",
    designation:
      "Libération d'adhérences [Adhésiolyse] étendues et/ou serrées du péritoine pelvien pour stérilité chez la femme, par laparotomie",
    tarifSecteur1Optam: "268.5",
    tarifHorsSecteur1HorsOptam: "225.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPC003",
    designation:
      "Section de bride et/ou d'adhérences péritonéales pour occlusion intestinale aigüe, par cœlioscopie",
    tarifSecteur1Optam: "270.84",
    tarifHorsSecteur1HorsOptam: "252.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPPA002",
    designation:
      "Section de bride et/ou d'adhérences péritonéales pour occlusion intestinale aigüe, par laparotomie",
    tarifSecteur1Optam: "273.67",
    tarifHorsSecteur1HorsOptam: "241.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPC015",
    designation:
      "Libération étendue de l'intestin grêle [Entérolyse étendue] pour occlusion aigüe, par cœlioscopie",
    tarifSecteur1Optam: "384.01",
    tarifHorsSecteur1HorsOptam: "317.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGPA004",
    designation:
      "Libération étendue de l'intestin grêle [Entérolyse étendue] pour occlusion aigüe, par laparotomie",
    tarifSecteur1Optam: "355.21",
    tarifHorsSecteur1HorsOptam: "289.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPNC001",
    designation:
      "Destruction et/ou exérèse de lésion endométriosique superficielle du péritoine, par cœlioscopie",
    tarifSecteur1Optam: "159.16",
    tarifHorsSecteur1HorsOptam: "124.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPNA001",
    designation:
      "Destruction et/ou exérèse de lésion endométriosique superficielle du péritoine, par laparotomie",
    tarifSecteur1Optam: "149.47",
    tarifHorsSecteur1HorsOptam: "130.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPFC001",
    designation:
      "Exérèse de lésion d'un repli péritonéal [méso] sans résection intestinale, par cœlioscopie",
    tarifSecteur1Optam: "274.38",
    tarifHorsSecteur1HorsOptam: "215.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPFA003",
    designation:
      "Exérèse de lésion d'un repli péritonéal [méso] sans résection intestinale, par laparotomie",
    tarifSecteur1Optam: "272.97",
    tarifHorsSecteur1HorsOptam: "202.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPFC002",
    designation:
      "Résection du grand omentum [grand épiploon] [Omentectomie], par cœlioscopie",
    tarifSecteur1Optam: "238.93",
    tarifHorsSecteur1HorsOptam: "170.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPFA004",
    designation:
      "Résection du grand omentum [grand épiploon] [Omentectomie], par laparotomie",
    tarifSecteur1Optam: "197.81",
    tarifHorsSecteur1HorsOptam: "158.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPBA001",
    designation:
      "Réduction de volume tumoral d'une maladie gélatineuse du péritoine, par laparotomie",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "331.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPFC007",
    designation:
      "Résection ou cloisonnement du cul-de-sac recto-utérin [de Douglas], par cœlioscopie",
    tarifSecteur1Optam: "280.69",
    tarifHorsSecteur1HorsOptam: "218.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPFA001",
    designation:
      "Résection ou cloisonnement du cul-de-sac recto-utérin [de Douglas], par laparotomie",
    tarifSecteur1Optam: "251.89",
    tarifHorsSecteur1HorsOptam: "189.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPSC001",
    designation:
      "Ligature du processus vaginal du péritoine [canal péritonéovaginal], par cœlioscopie",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPSA001",
    designation:
      "Ligature du processus vaginal du péritoine [canal péritonéovaginal], par abord inguinal",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HSLF002",
    designation:
      "Alimentation parentérale avec apport de 20 à 35 kilocalories par kilogramme par jour [kcal/kg/jour], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HSLF003",
    designation:
      "Alimentation parentérale avec apport de plus de 35 kilocalories par kilogramme par jour [kcal/kg/jour], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HSLD001",
    designation:
      "Alimentation entérale par sonde avec apport de 20 à 35 kilocalories par kilogramme par jour [kcal/kg/jour], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HSLD002",
    designation:
      "Alimentation entérale par sonde avec apport de plus de 35 kilocalories par kilogramme par jour [kcal/kg/jour], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HSLF001",
    designation:
      "Alimentation entérale et parentérale, avec apport de plus de 35 kilocalories par kilogramme par jour [kcal/kg/jour], par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB008",
    designation:
      "Électromyographie analytique du périnée par électrode aiguille",
    tarifSecteur1Optam: "63.07",
    tarifHorsSecteur1HorsOptam: "58.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB003",
    designation:
      "Électromyographie analytique du périnée avec étude de la latence motrice du nerf pudendal [nerf honteux], par électrode aiguille",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB002",
    designation:
      "Électromyographie analytique du périnée avec étude de la latence du réflexe bulbocaverneux et des potentiels évoqués somesthésiques cérébraux par stimulation du nerf pudendal [nerf honteux]",
    tarifSecteur1Optam: "120.96",
    tarifHorsSecteur1HorsOptam: "120.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB028",
    designation:
      "Électromyographie analytique du périnée avec étude des latences des réflexes sacraux, par électrode aiguille",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQD001",
    designation:
      "Électromyographie analytique du périnée, avec étude des latences des réflexes sacraux et des latences distales du nerf pudendal [nerf honteux] par stimulation intrarectale",
    tarifSecteur1Optam: "120.96",
    tarifHorsSecteur1HorsOptam: "120.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQD002",
    designation:
      "Électromyographie analytique du périnée, avec étude des latences des réflexes sacraux, des latences distales du nerf pudendal [nerf honteux] par stimulation intrarectale et des potentiels évoqués somesthésiques cérébraux par stimulation du nerf pudendal",
    tarifSecteur1Optam: "120.96",
    tarifHorsSecteur1HorsOptam: "120.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB016",
    designation:
      "Électromyographie analytique du périnée, avec étude des latences des réflexes sacraux, de la vitesse de conduction sensitive du nerf dorsal du pénis, des potentiels évoqués somesthésiques cérébraux et des réponses cutanées sympathiques périnéales",
    tarifSecteur1Optam: "120.96",
    tarifHorsSecteur1HorsOptam: "120.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB020",
    designation:
      "Électromyographie de fibre unique du périnée avec étude de 20 paires de fibres, par électrode aiguille",
    tarifSecteur1Optam: "139.97",
    tarifHorsSecteur1HorsOptam: "102.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAQM003",
    designation:
      "Échographie transcutanée unilatérale ou bilatérale du rein et de la région lombale",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JAQM004",
    designation:
      "Échographie transcutanée unilatérale ou bilatérale du rein et de la région lombale, avec échographie transcutanée de la vessie",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JAQJ001",
    designation:
      "Échographie transcutanée unilatérale ou bilatérale du rein et de la région lombale, avec échographie transcutanée de la vessie et échographie de la prostate et des vésicules séminales par voie rectale",
    tarifSecteur1Optam: "56.7",
    tarifHorsSecteur1HorsOptam: "56.7",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JAQM001",
    designation:
      "Échographie transcutanée unilatérale ou bilatérale du rein et de la région lombale, avec échographie transcutanée du petit bassin [pelvis] féminin",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JDQM001",
    designation: "Échographie transcutanée de la vessie et/ou de la prostate",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JDQJ002",
    designation:
      "Échographie transcutanée et échographie par voie rectale et/ou vaginale [par voie cavitaire] de la vessie",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JDQJ001",
    designation:
      "Échographie de la vessie et de la prostate, par voie urétrale",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JDQJ003",
    designation: "Échographie de la vessie et de la prostate, par voie rectale",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JGQJ001",
    designation:
      "Échographie de la prostate et des vésicules séminales, par voie rectale",
    tarifSecteur1Optam: "56.7",
    tarifHorsSecteur1HorsOptam: "56.7",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JHQM001",
    designation:
      "Échographie transcutanée unilatérale ou bilatérale du contenu des bourses",
    tarifSecteur1Optam: "37.05",
    tarifHorsSecteur1HorsOptam: "37.05",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JHQM002",
    designation: "Échographie du pénis",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "34.97",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM003",
    designation: "Échographie transcutanée du petit bassin [pelvis] féminin",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQJ003",
    designation:
      "Échographie du petit bassin [pelvis] féminin, par voie rectale et/ou vaginale [par voie cavitaire]",
    tarifSecteur1Optam: "52.45",
    tarifHorsSecteur1HorsOptam: "52.45",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQJ006",
    designation:
      "Échographie transcutanée avec échographie par voie rectale et/ou vaginale [par voie cavitaire] du petit bassin [pelvis] féminin",
    tarifSecteur1Optam: "56.7",
    tarifHorsSecteur1HorsOptam: "56.7",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JKQJ001",
    designation: "Hystérosonographie",
    tarifSecteur1Optam: "69.72",
    tarifHorsSecteur1HorsOptam: "69.72",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JKQJ350",
    designation:
      "Épreuve de perméabilité des trompes utérines [de Fallope] au cours d’une hystérosalpingosonographie de contraste",
    tarifSecteur1Optam: "105.25",
    tarifHorsSecteur1HorsOptam: "105.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAQM002",
    designation:
      "Échographie-doppler transcutanée unilatérale ou bilatérale du rein et de ses vaisseaux",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQJ002",
    designation:
      "Échographie-doppler du petit bassin [pelvis] féminin, par voie rectale et/ou vaginale [par voie cavitaire]",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQJ001",
    designation:
      "Échographie-doppler transcutanée et échographie-doppler par voie rectale et/ou vaginale [par voie cavitaire] du petit bassin [pelvis] féminin",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JZQH002",
    designation:
      "Urographie intraveineuse sans urétrocystographie permictionnelle",
    tarifSecteur1Optam: "85.12",
    tarifHorsSecteur1HorsOptam: "85.12",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JZQH003",
    designation:
      "Urographie intraveineuse avec urétrocystographie permictionnelle",
    tarifSecteur1Optam: "85.12",
    tarifHorsSecteur1HorsOptam: "85.12",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQH003",
    designation:
      "Kystographie du rein, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JBQH003",
    designation:
      "Urétéropyélographie descendante, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JBQH001",
    designation:
      "Urétéropyélographie descendante, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JBQH002",
    designation: "Urétéropyélographie rétrograde [UPR]",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JDQH001",
    designation: "Urétrocystographie rétrograde",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JDQH002",
    designation: "Urétrocystographie, par une cystostomie déjà en place",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JDQH003",
    designation: "Urétrocystographie, par ponction transcutanée de la vessie",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JGQH003",
    designation:
      "Déférentovésiculographie unilatérale ou bilatérale, par cathétérisme endoscopique des conduits éjaculateurs",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JGQH004",
    designation:
      "Déférentovésiculographie, par ponction transcutanée du conduit déférent sans guidage",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JGQH001",
    designation:
      "Déférentovésiculographie, par ponction transcutanée du conduit déférent avec guidage échographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JHQH001",
    designation:
      "Cavernographie, par ponction transcutanée des corps caverneux du pénis",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JKQH001",
    designation: "Hystérosalpingographie",
    tarifSecteur1Optam: "73.15",
    tarifHorsSecteur1HorsOptam: "73.15",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JLQH002",
    designation: "Colpo-cysto-rectographie dynamique",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JZQH001",
    designation:
      "Exploration radiologique des anomalies du sinus urogénital [Génitographie externe]",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KGQL004",
    designation: "Mesure de la clairance radio-isotopique plasmatique",
    tarifSecteur1Optam: "134.56",
    tarifHorsSecteur1HorsOptam: "134.56",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KGQL001",
    designation:
      "Mesure de la clairance radio-isotopique plasmatique et urinaire",
    tarifSecteur1Optam: "134.56",
    tarifHorsSecteur1HorsOptam: "134.56",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL001",
    designation:
      "Scintigraphie rénale glomérulaire ou tubulaire [Néphrographie isotopique] sans épreuve pharmacologique",
    tarifSecteur1Optam: "327.3",
    tarifHorsSecteur1HorsOptam: "327.3",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL005",
    designation:
      "Scintigraphie rénale glomérulaire ou tubulaire [Néphrographie isotopique] sans épreuve pharmacologique, avec scintigraphie antérograde de la vessie",
    tarifSecteur1Optam: "380.36",
    tarifHorsSecteur1HorsOptam: "380.36",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL003",
    designation:
      "Scintigraphie rénale glomérulaire ou tubulaire [Néphrographie isotopique] avec épreuve pharmacologique",
    tarifSecteur1Optam: "327.3",
    tarifHorsSecteur1HorsOptam: "327.3",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL007",
    designation:
      "Scintigraphie rénale glomérulaire ou tubulaire [Néphrographie isotopique] avec épreuve pharmacologique et réinjection de produit radio-isotopique",
    tarifSecteur1Optam: "406.89",
    tarifHorsSecteur1HorsOptam: "406.89",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL004",
    designation:
      "Scintigraphie rénale glomérulaire ou tubulaire avec mesure de la clairance radio-isotopique plasmatique",
    tarifSecteur1Optam: "337.94",
    tarifHorsSecteur1HorsOptam: "337.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL006",
    designation:
      "Scintigraphie rénale glomérulaire ou tubulaire avec mesure des clairances radio-isotopiques plasmatique et urinaire",
    tarifSecteur1Optam: "337.94",
    tarifHorsSecteur1HorsOptam: "337.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JAQL002",
    designation: "Scintigraphie rénale corticale",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JBQL001",
    designation: "Scintigraphie de l'élimination pyélo-urétérale",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JDQL001",
    designation: "Scintigraphie de la vessie, par voie rétrograde",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JHQL001",
    designation:
      "Scintigraphie unilatérale ou bilatérale du testicule et/ou scintigraphie du pénis",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JVQP003",
    designation: "Étude du débit plasmatique rénal, avec recueil urinaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF006",
    designation:
      "Épreuve de filtration glomérulaire rénale par administration de substance exogène, sans recueil urinaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF002",
    designation:
      "Épreuve de filtration glomérulaire rénale par administration de substance exogène, avec recueil urinaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF003",
    designation:
      "Épreuve de filtration glomérulaire rénale par administration de substance exogène avec étude du débit plasmatique rénal, avec recueil urinaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF008",
    designation:
      "Épreuve de filtration glomérulaire rénale par administration de substance exogène, sans recueil urinaire, avec épreuve de stimulation",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF005",
    designation:
      "Épreuve de filtration glomérulaire rénale par administration de substance exogène, avec recueil urinaire, avec épreuve de stimulation",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVRP006",
    designation: "Épreuve de charge calcique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVRP003",
    designation: "Épreuve de dilution de l'urine",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVRP001",
    designation:
      "Épreuve de concentration de l'urine avec test à la 1-désamino-8-D-arginine-vasopressine [dDAVP]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF004",
    designation:
      "Étude de la sécrétion d'hormone antidiurétique [ADH] par perfusion de chlorure de sodium hypertonique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP006",
    designation: "Étude de la capacité d'acidification de l'urine",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP001",
    designation:
      "Étude du transfert maximal [Tm] du bicarbonate et de la sécrétion distale de proton",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP005",
    designation: "Étude du transfert maximal [Tm] du glucose",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP008",
    designation:
      "Étude du système rénine-angiotensine-aldostérone en clinostatisme et en orthostatisme",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF010",
    designation:
      "Étude de la régulation du système rénine-angiotensine-aldostérone par administration d'agent pharmacologique non radio-isotopique ou perfusion de chlorure de sodium isotonique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP007",
    designation:
      "Étude des transferts des électrolytes dans le tubule rénal et de leurs facteurs de régulation, sans administration de substance exogène",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP004",
    designation:
      "Étude des transferts des électrolytes dans le tubule rénal et de leurs facteurs de régulation, avec administration de substance exogène",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVRP005",
    designation: "Test à la déféroxamine",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JZQD001",
    designation: "Étude urodynamique du haut appareil urinaire",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "118.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD009",
    designation:
      "Cystomanométrie par cathétérisme urétral, sans mesure des pressions intrarectales ou intravaginales",
    tarifSecteur1Optam: "121.57",
    tarifHorsSecteur1HorsOptam: "102.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD008",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec mesure des pressions intrarectales ou intravaginales",
    tarifSecteur1Optam: "124.41",
    tarifHorsSecteur1HorsOptam: "104.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD003",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec mesure des pressions intrarectales ou intravaginales et débitmétrie mictionnelle simultanée [Étude pression-débit urinaire]",
    tarifSecteur1Optam: "142.84",
    tarifHorsSecteur1HorsOptam: "115.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD006",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec mesure des pressions intrarectales ou intravaginales et électromyographie du sphincter strié de l'urètre par électrode aiguille",
    tarifSecteur1Optam: "151.35",
    tarifHorsSecteur1HorsOptam: "128.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD004",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec mesure des pressions intrarectales ou intravaginales, débitmétrie mictionnelle, électromyographie des muscles du périnée et étude des latences des réflexes sacraux",
    tarifSecteur1Optam: "179",
    tarifHorsSecteur1HorsOptam: "144.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD005",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec mesure des pressions intrarectales ou intravaginales, débitmétrie mictionnelle, électromyographie des muscles du périnée, étude des latences des réflexes sacraux et des potentiels évoqués somesthésiques",
    tarifSecteur1Optam: "183.96",
    tarifHorsSecteur1HorsOptam: "159.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD001",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec profilométrie urétrale",
    tarifSecteur1Optam: "137.17",
    tarifHorsSecteur1HorsOptam: "117.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQD002",
    designation:
      "Cystomanométrie par cathétérisme urétral, avec profilométrie urétrale suivie de débitmétrie mictionnelle, avec électromyographie du sphincter strié de l'urètre par électrode aiguille",
    tarifSecteur1Optam: "144.26",
    tarifHorsSecteur1HorsOptam: "130.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQF001",
    designation: "Cystomanométrie, par ponction transcutanée de la vessie",
    tarifSecteur1Optam: "115.9",
    tarifHorsSecteur1HorsOptam: "99.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQF002",
    designation:
      "Cystomanométrie par ponction transcutanée de la vessie, avec profilométrie urétrale",
    tarifSecteur1Optam: "160.57",
    tarifHorsSecteur1HorsOptam: "131.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEQD001",
    designation: "Profilométrie urétrale",
    tarifSecteur1Optam: "80.45",
    tarifHorsSecteur1HorsOptam: "58.75",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEQD002",
    designation: "Sphinctérométrie par cathétérisme urétral",
    tarifSecteur1Optam: "80.45",
    tarifHorsSecteur1HorsOptam: "54.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JRQP001",
    designation: "Débitmétrie mictionnelle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JRQD001",
    designation:
      "Débitmétrie mictionnelle, avec cystomanométrie et sphinctérométrie par cathétérisme urétral",
    tarifSecteur1Optam: "137.88",
    tarifHorsSecteur1HorsOptam: "112.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQB001",
    designation:
      "Mesure de la rigidité du pénis avec injection de produit vasoactif",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQP001",
    designation: "Mesure nocturne de la tumescence et de la rigidité du pénis",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQB002",
    designation:
      "Mesure pression-débit, par ponction transcutanée des corps caverneux du pénis",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF007",
    designation:
      "Évaluation de la dose de dialyse péritonéale par prélèvement unique de sang et de dialysat",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQF001",
    designation:
      "Évaluation de la dose de dialyse péritonéale par prélèvements multiples de sang et de dialysat",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP002",
    designation:
      "Mesure des clairances péritonéales par recueil de dialysat sur 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVQP009",
    designation:
      "Mesure de la pression intrapéritonéale par le prolongateur du cathéter de dialyse péritonéale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JBQG002",
    designation: "Pyélocaliscopie, par voie transcutanée",
    tarifSecteur1Optam: "358.05",
    tarifHorsSecteur1HorsOptam: "325.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JBQG001",
    designation: "Urétéro-pyélo-caliscopie, par voie transcutanée",
    tarifSecteur1Optam: "398.46",
    tarifHorsSecteur1HorsOptam: "349.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JBQE001",
    designation: "Urétéro-pyélo-caliscopie rétrograde, par voie urétrale",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "315.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCQE003",
    designation: "Urétéroscopie rétrograde, par voie urétrale",
    tarifSecteur1Optam: "224.75",
    tarifHorsSecteur1HorsOptam: "213.39",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCQE001",
    designation: "Urétéroscopie par une urétérostomie cutanée directe",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCQE002",
    designation:
      "Urétéroscopie par une urétérostomie cutanée transintestinale non continente",
    tarifSecteur1Optam: "278.64",
    tarifHorsSecteur1HorsOptam: "244.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCQE004",
    designation:
      "Urétéroscopie par une urétérostomie cutanée transintestinale continente",
    tarifSecteur1Optam: "426.82",
    tarifHorsSecteur1HorsOptam: "330.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQE001",
    designation: "Fibroscopie urétrovésicale",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQE003",
    designation: "Urétrocystoscopie à l'endoscope rigide",
    tarifSecteur1Optam: "36",
    tarifHorsSecteur1HorsOptam: "36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQE002",
    designation: "Exploration endoscopique d'une néovessie intestinale",
    tarifSecteur1Optam: "52.47",
    tarifHorsSecteur1HorsOptam: "46.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQP002",
    designation: "Péniscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JJQC001",
    designation:
      "Exploration antérograde unilatérale ou bilatérale de la trompe utérine [Salpingoscopie gynécologique], par cœlioscopie",
    tarifSecteur1Optam: "160.54",
    tarifHorsSecteur1HorsOptam: "117.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKQE002",
    designation: "Hystéroscopie",
    tarifSecteur1Optam: "69.2",
    tarifHorsSecteur1HorsOptam: "66.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQE001",
    designation: "Hystéroscopie avec curetage de la cavité de l'utérus",
    tarifSecteur1Optam: "85.81",
    tarifHorsSecteur1HorsOptam: "76.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLQE002",
    designation: "Colposcopie",
    tarifSecteur1Optam: "49.82",
    tarifHorsSecteur1HorsOptam: "36.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLQE001",
    designation: "Vaginoscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JMQP001",
    designation: "Vulvoscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HPQC001",
    designation:
      "Exploration du petit bassin [pelvis] féminin, par pélycoscopie [culdoscopie] [cœlioscopie transvaginale]",
    tarifSecteur1Optam: "123.18",
    tarifHorsSecteur1HorsOptam: "95.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBHE001",
    designation:
      "Biopsie de la voie excrétrice supérieure, par endoscopie rétrograde",
    tarifSecteur1Optam: "318.34",
    tarifHorsSecteur1HorsOptam: "267.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDHE002",
    designation: "Biopsie de la vessie à la pince, par endoscopie",
    tarifSecteur1Optam: "44.67",
    tarifHorsSecteur1HorsOptam: "38.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDHE001",
    designation: "Biopsie de la vessie à l'anse coupante, par endoscopie",
    tarifSecteur1Optam: "87.21",
    tarifHorsSecteur1HorsOptam: "86.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDHE003",
    designation: "Biopsie randomisée de la vessie, par endoscopie",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "97.97",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEHE002",
    designation: "Biopsie de l'urètre à la pince, par endoscopie",
    tarifSecteur1Optam: "73.74",
    tarifHorsSecteur1HorsOptam: "54.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEHE001",
    designation: "Biopsie de l'urètre à l'anse coupante, par endoscopie",
    tarifSecteur1Optam: "79.41",
    tarifHorsSecteur1HorsOptam: "58.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHJ002",
    designation:
      "Ponction de kyste du rein, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHH001",
    designation:
      "Ponction de kyste du rein, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHJ005",
    designation:
      "Ponction des cavités du rein, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "92.88",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHJ004",
    designation:
      "Ponction des cavités du rein par voie transcutanée avec guidage échographique, avec enregistrement de la pression pyélique sous perfusion",
    tarifSecteur1Optam: "112.73",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHB001",
    designation: "Biopsie du rein, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "65.72",
    tarifHorsSecteur1HorsOptam: "65.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHJ006",
    designation:
      "Biopsie du rein sur une cible, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHJ007",
    designation:
      "Biopsie du rein sur plusieurs cibles, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHH002",
    designation:
      "Biopsie du rein, par voie jugulaire transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "102.68",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAHC001",
    designation: "Biopsie du rein, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "154.2",
    tarifHorsSecteur1HorsOptam: "154.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAHA001",
    designation: "Biopsie du rein, par abord direct",
    tarifSecteur1Optam: "133.29",
    tarifHorsSecteur1HorsOptam: "129.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCLE003",
    designation:
      "Pose d'une sonde urétérale à visée diagnostique, par endoscopie rétrograde",
    tarifSecteur1Optam: "79.41",
    tarifHorsSecteur1HorsOptam: "66.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEHD001",
    designation: "Frottis et/ou prélèvement urétral",
    tarifSecteur1Optam: "10.64",
    tarifHorsSecteur1HorsOptam: "8.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGHB001",
    designation:
      "Ponction-cytoaspiration de la prostate, par voie transrectale ou transpérinéale",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGHD001",
    designation:
      "Biopsie de la prostate, par voie transrectale avec guidage au doigt",
    tarifSecteur1Optam: "45.38",
    tarifHorsSecteur1HorsOptam: "42.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGHJ001",
    designation:
      "Biopsie de la prostate, par voie transrectale avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGHB002",
    designation:
      "Biopsie de la prostate, par voie transpérinéale avec guidage au doigt",
    tarifSecteur1Optam: "41.12",
    tarifHorsSecteur1HorsOptam: "39.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGHJ002",
    designation:
      "Biopsie de la prostate, par voie transpérinéale avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHCB001",
    designation: "Microponction du testicule, par voie transcutanée",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHHB002",
    designation: "Biopsie du testicule, par voie transcutanée",
    tarifSecteur1Optam: "43.96",
    tarifHorsSecteur1HorsOptam: "41.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHHA002",
    designation: "Biopsie unilatérale du testicule, par abord direct",
    tarifSecteur1Optam: "65.94",
    tarifHorsSecteur1HorsOptam: "54.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHHA003",
    designation: "Biopsie bilatérale du testicule, par abord direct",
    tarifSecteur1Optam: "90.75",
    tarifHorsSecteur1HorsOptam: "76.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHCF001",
    designation: "Microprélèvement de l'épididyme, par voie transcutanée",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHCA003",
    designation: "Microprélèvement de l'épididyme, par abord direct",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHHB001",
    designation: "Ponction des corps caverneux du pénis",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHHA001",
    designation: "Biopsie du pénis",
    tarifSecteur1Optam: "37.58",
    tarifHorsSecteur1HorsOptam: "25.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKGD003",
    designation: "Curetage de la cavité de l'utérus à visée diagnostique",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKHA001",
    designation: "Biopsie ou frottis de l'endomètre, sans hystéroscopie",
    tarifSecteur1Optam: "42.24",
    tarifHorsSecteur1HorsOptam: "42.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKHA002",
    designation: "Biopsie du col de l'utérus",
    tarifSecteur1Optam: "21.45",
    tarifHorsSecteur1HorsOptam: "16.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLHA001",
    designation: "Biopsie du vagin",
    tarifSecteur1Optam: "23.53",
    tarifHorsSecteur1HorsOptam: "17.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMHA001",
    designation: "Biopsie de la vulve",
    tarifSecteur1Optam: "24.91",
    tarifHorsSecteur1HorsOptam: "18.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKHD001",
    designation: "Prélèvement cervicovaginal",
    tarifSecteur1Optam: "12.46",
    tarifHorsSecteur1HorsOptam: "9.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JFQC001",
    designation:
      "Exploration de l'espace rétropéritonéal et de son contenu, par rétropéritonéoscopie [Rétropéritonéoscopie exploratrice]",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "248.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFQA001",
    designation:
      "Exploration de l'espace rétropéritonéal et de son contenu, par lombotomie [Lombotomie exploratrice]",
    tarifSecteur1Optam: "262.33",
    tarifHorsSecteur1HorsOptam: "196.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHQA002",
    designation:
      "Exploration du contenu scrotal sans biopsie, par abord scrotal",
    tarifSecteur1Optam: "75.15",
    tarifHorsSecteur1HorsOptam: "59.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHQA003",
    designation:
      "Exploration du contenu scrotal avec biopsie, par abord scrotal",
    tarifSecteur1Optam: "88.63",
    tarifHorsSecteur1HorsOptam: "67.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHQA001",
    designation: "Exploration du contenu scrotal, par abord inguinal",
    tarifSecteur1Optam: "107.06",
    tarifHorsSecteur1HorsOptam: "97.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPC002",
    designation: "Ovariotomie exploratrice, par cœlioscopie",
    tarifSecteur1Optam: "171.62",
    tarifHorsSecteur1HorsOptam: "131.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPA002",
    designation: "Ovariotomie exploratrice, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKQX347",
    designation:
      "Examen cytopathologique de prélèvement [frottis] du col de l'utérus pour dépistage individuel",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX261",
    designation:
      "Examen cytopathologique de prélèvement [frottis] du col de l'utérus pour dépistage organisé",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX027",
    designation:
      "Examen cytopathologique de contrôle de prélèvement [frottis] du col de l'utérus",
    tarifSecteur1Optam: "23",
    tarifHorsSecteur1HorsOptam: "23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX147",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de prélèvement [frottis] du col de l'utérus pour dépistage individuel",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX015",
    designation:
      "Examen cytopathologique de contrôle en phase liquide [technique monocouche] de prélèvement [frottis] du col de l'utérus",
    tarifSecteur1Optam: "23",
    tarifHorsSecteur1HorsOptam: "23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX426",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de prélèvement [frottis] du col de l'utérus pour dépistage organisé",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAQX006",
    designation:
      "Examen histopathologique de biopsie de rein avec coloration spéciale pour diagnostic d'affection non carcinologique",
    tarifSecteur1Optam: "60",
    tarifHorsSecteur1HorsOptam: "60",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQX002",
    designation:
      "Examen histopathologique de fragments d'exérèse endoscopique de la vessie",
    tarifSecteur1Optam: "33.6",
    tarifHorsSecteur1HorsOptam: "33.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGQX001",
    designation:
      "Examen histopathologique de fragments d'exérèse endoscopique de la prostate",
    tarifSecteur1Optam: "75.98",
    tarifHorsSecteur1HorsOptam: "75.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de néphrectomie totale ou de néphro-urétérectomie",
    tarifSecteur1Optam: "127.38",
    tarifHorsSecteur1HorsOptam: "127.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de néphrectomie partielle ",
    tarifSecteur1Optam: "87.85",
    tarifHorsSecteur1HorsOptam: "87.85",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'urétrectomie partielle ou totale",
    tarifSecteur1Optam: "53.65",
    tarifHorsSecteur1HorsOptam: "53.65",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de cystectomie partielle",
    tarifSecteur1Optam: "76.62",
    tarifHorsSecteur1HorsOptam: "76.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de cystectomie totale ",
    tarifSecteur1Optam: "122.46",
    tarifHorsSecteur1HorsOptam: "122.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'urétérectomie partielle ou totale ",
    tarifSecteur1Optam: "68.31",
    tarifHorsSecteur1HorsOptam: "68.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de vésiculoprostatectomie totale",
    tarifSecteur1Optam: "146.12",
    tarifHorsSecteur1HorsOptam: "146.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de vésiculo-prostato-cystectomie totale",
    tarifSecteur1Optam: "147.01",
    tarifHorsSecteur1HorsOptam: "147.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'orchidectomie partielle ",
    tarifSecteur1Optam: "65.58",
    tarifHorsSecteur1HorsOptam: "65.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'orchidectomie totale ",
    tarifSecteur1Optam: "116.45",
    tarifHorsSecteur1HorsOptam: "116.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'amputation partielle du pénis",
    tarifSecteur1Optam: "78.66",
    tarifHorsSecteur1HorsOptam: "78.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'amputation totale du pénis",
    tarifSecteur1Optam: "99.69",
    tarifHorsSecteur1HorsOptam: "99.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JJQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de salpingectomie ",
    tarifSecteur1Optam: "60.18",
    tarifHorsSecteur1HorsOptam: "60.18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JJQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'une ovariectomie partielle ou totale ",
    tarifSecteur1Optam: "120.56",
    tarifHorsSecteur1HorsOptam: "120.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JJQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de plusieurs ovariectomies partielle et/ou totale",
    tarifSecteur1Optam: "137.44",
    tarifHorsSecteur1HorsOptam: "137.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'hystérectomie, sans annexectomie",
    tarifSecteur1Optam: "87.74",
    tarifHorsSecteur1HorsOptam: "87.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'hystérectomie, avec annexectomie",
    tarifSecteur1Optam: "117.94",
    tarifHorsSecteur1HorsOptam: "117.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX020",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'hystérectomie, avec annexectomie et omentectomie",
    tarifSecteur1Optam: "133.31",
    tarifHorsSecteur1HorsOptam: "133.31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de conisation du col de l'utérus",
    tarifSecteur1Optam: "96.06",
    tarifHorsSecteur1HorsOptam: "96.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de colpectomie partielle ou totale",
    tarifSecteur1Optam: "61.18",
    tarifHorsSecteur1HorsOptam: "61.18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKQX006",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de trachélectomie [cervicectomie] ou de colpotrachélectomie ",
    tarifSecteur1Optam: "64.62",
    tarifHorsSecteur1HorsOptam: "64.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JMQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de vulvectomie partielle",
    tarifSecteur1Optam: "70.14",
    tarifHorsSecteur1HorsOptam: "70.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JMQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de vulvectomie totale",
    tarifSecteur1Optam: "102.59",
    tarifHorsSecteur1HorsOptam: "102.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JFQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pelvectomie antérieure",
    tarifSecteur1Optam: "144.99",
    tarifHorsSecteur1HorsOptam: "144.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JFQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de pelvectomie totale",
    tarifSecteur1Optam: "145.25",
    tarifHorsSecteur1HorsOptam: "145.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCQD001",
    designation:
      "Examen clinique du petit bassin [pelvis] et du périnée féminins, sous anesthésie générale",
    tarifSecteur1Optam: "42.9",
    tarifHorsSecteur1HorsOptam: "36.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JANH002",
    designation:
      "Sclérose d'un kyste du rein par injection d'agent pharmacologique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JANH001",
    designation:
      "Sclérose de plusieurs kystes du rein par injection d'agent pharmacologique, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: '"JANH798',
  },
  {
    codeCCAM: '"',
    designation:
      "Destruction de tumeur rénale par radiofréquence, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "204",
    tarifHorsSecteur1HorsOptam: "204",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAFC008",
    designation:
      "Résection de kyste non parasitaire du rein, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "365.84",
    tarifHorsSecteur1HorsOptam: "294.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA007",
    designation: "Résection de kyste non parasitaire du rein, par lombotomie",
    tarifSecteur1Optam: "243.9",
    tarifHorsSecteur1HorsOptam: "211.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA004",
    designation: "Résection de kyste parasitaire du rein, par lombotomie",
    tarifSecteur1Optam: "370.1",
    tarifHorsSecteur1HorsOptam: "284.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC005",
    designation:
      "Néphrectomie partielle, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "667.88",
    tarifHorsSecteur1HorsOptam: "517.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA030",
    designation:
      "Néphrectomie partielle sans dissection du pédicule vasculaire, par laparotomie",
    tarifSecteur1Optam: "478.58",
    tarifHorsSecteur1HorsOptam: "395.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA024",
    designation:
      "Néphrectomie partielle sans dissection du pédicule vasculaire, par lombotomie ou par abord lombal vertical",
    tarifSecteur1Optam: "473.61",
    tarifHorsSecteur1HorsOptam: "392.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA019",
    designation:
      "Néphrectomie partielle avec dissection du pédicule vasculaire, par laparotomie",
    tarifSecteur1Optam: "656.53",
    tarifHorsSecteur1HorsOptam: "498.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA008",
    designation:
      "Néphrectomie partielle avec dissection du pédicule vasculaire, par lombotomie ou par abord lombal postérieur",
    tarifSecteur1Optam: "593.43",
    tarifHorsSecteur1HorsOptam: "462.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA006",
    designation:
      "Néphrectomie partielle ex situ avec autotransplantation du rein, par abord direct",
    tarifSecteur1Optam: "1098.95",
    tarifHorsSecteur1HorsOptam: "922.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC002",
    designation:
      "Héminéphrectomie avec urétérectomie partielle, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "609.74",
    tarifHorsSecteur1HorsOptam: "523.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA001",
    designation:
      "Héminéphrectomie avec urétérectomie partielle, par abord direct",
    tarifSecteur1Optam: "554.44",
    tarifHorsSecteur1HorsOptam: "478.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC007",
    designation:
      "Héminéphrectomie avec urétérectomie totale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "803.3",
    tarifHorsSecteur1HorsOptam: "635.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA003",
    designation: "Héminéphrectomie avec urétérectomie totale, par abord direct",
    tarifSecteur1Optam: "730.27",
    tarifHorsSecteur1HorsOptam: "580.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA026",
    designation: "Héminéphrectomie pour fusion rénale, par abord direct",
    tarifSecteur1Optam: "470.56",
    tarifHorsSecteur1HorsOptam: "470.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC006",
    designation:
      "Néphrectomie totale unilatérale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "665.04",
    tarifHorsSecteur1HorsOptam: "498.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA023",
    designation: "Néphrectomie totale unilatérale, par laparotomie",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "337.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA010",
    designation: "Néphrectomie totale unilatérale, par abord lombal vertical",
    tarifSecteur1Optam: "421.15",
    tarifHorsSecteur1HorsOptam: "344.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA002",
    designation: "Néphrectomie totale, par lombotomie",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "337.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA012",
    designation:
      "Néphrectomie totale par laparotomie, avec extraction endoscopique rétrograde de l'uretère [stripping de l'uretère]",
    tarifSecteur1Optam: "663.62",
    tarifHorsSecteur1HorsOptam: "542.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC001",
    designation:
      "Néphro-urétérectomie totale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "793.37",
    tarifHorsSecteur1HorsOptam: "629.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA032",
    designation: "Néphro-urétérectomie totale, par abord direct",
    tarifSecteur1Optam: "665.75",
    tarifHorsSecteur1HorsOptam: "543.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA015",
    designation: "Transplantectomie rénale, par abord direct",
    tarifSecteur1Optam: "319.76",
    tarifHorsSecteur1HorsOptam: "303.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC004",
    designation: "Binéphrectomie, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "643.29",
    tarifHorsSecteur1HorsOptam: "643.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA016",
    designation: "Binéphrectomie, par laparotomie",
    tarifSecteur1Optam: "528.91",
    tarifHorsSecteur1HorsOptam: "451.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA027",
    designation: "Binéphrectomie, par abords lombaux verticaux",
    tarifSecteur1Optam: "494.42",
    tarifHorsSecteur1HorsOptam: "494.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA031",
    designation:
      "Néphrectomie totale élargie à la loge rénale, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "738.78",
    tarifHorsSecteur1HorsOptam: "585.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC019",
    designation:
      "Néphrectomie totale élargie à la loge rénale, par cœlioscopie ou rétropéritonéoscopie",
    tarifSecteur1Optam: "682.06",
    tarifHorsSecteur1HorsOptam: "537.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA009",
    designation:
      "Néphrectomie totale élargie à la loge rénale, par laparotomie ou par abord lomboabdominal",
    tarifSecteur1Optam: "532.46",
    tarifHorsSecteur1HorsOptam: "426.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA022",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec thrombectomie par cavotomie, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "902.56",
    tarifHorsSecteur1HorsOptam: "746.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA014",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec thrombectomie par cavotomie, par laparotomie ou par abord lomboabdominal",
    tarifSecteur1Optam: "835.91",
    tarifHorsSecteur1HorsOptam: "681.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA021",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec surrénalectomie, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "755.09",
    tarifHorsSecteur1HorsOptam: "595.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC010",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec surrénalectomie, par cœlioscopie ou rétropéritonéoscopie",
    tarifSecteur1Optam: "752.96",
    tarifHorsSecteur1HorsOptam: "593.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA029",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec surrénalectomie, par laparotomie ou par abord lomboabdominal",
    tarifSecteur1Optam: "589.89",
    tarifHorsSecteur1HorsOptam: "460.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA025",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec surrénalectomie et thrombectomie par cavotomie, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "960.7",
    tarifHorsSecteur1HorsOptam: "780.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA028",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec surrénalectomie et thrombectomie par cavotomie, par laparotomie ou par abord lomboabdominal",
    tarifSecteur1Optam: "894.05",
    tarifHorsSecteur1HorsOptam: "715.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA011",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec thrombectomie par cavotomie et contrôle intraatrial cardiaque, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1063.5",
    tarifHorsSecteur1HorsOptam: "839.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA005",
    designation:
      "Néphrectomie totale élargie à la loge rénale avec résection latérale de la veine cave inférieure, par abord direct",
    tarifSecteur1Optam: "964.95",
    tarifHorsSecteur1HorsOptam: "756.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JACA001",
    designation:
      "Suture de plaie ou de fracture du rein limitée au parenchyme, par abord direct",
    tarifSecteur1Optam: "330.39",
    tarifHorsSecteur1HorsOptam: "274.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAMA002",
    designation:
      "Réparation d'un traumatisme complexe du rein et de la voie excrétrice, par abord direct",
    tarifSecteur1Optam: "613.99",
    tarifHorsSecteur1HorsOptam: "491.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JASA001",
    designation:
      "Hémostase de plaie ou de fracture du rein par pose de filet périrénal, par abord direct",
    tarifSecteur1Optam: "349.54",
    tarifHorsSecteur1HorsOptam: "285.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA013",
    designation:
      "Prélèvement d'un rein sur un donneur vivant, par abord direct",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFC003",
    designation:
      "Prélèvement d'un rein sur un donneur vivant, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA018",
    designation:
      "Prélèvement d'un rein sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "445.25",
    tarifHorsSecteur1HorsOptam: "376.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA017",
    designation:
      "Prélèvement des 2 reins sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "544.61",
    tarifHorsSecteur1HorsOptam: "544.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAFA020",
    designation:
      "Prélèvement des 2 reins au cours d'un prélèvement multiorgane, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "406.69",
    tarifHorsSecteur1HorsOptam: "406.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAEA003",
    designation: "Transplantation du rein",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAEA002",
    designation: "Autotransplantation du rein, par abord direct",
    tarifSecteur1Optam: "830.24",
    tarifHorsSecteur1HorsOptam: "700.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JADA001",
    designation: "Néphropexie, par abord direct",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "206.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAPC018",
    designation:
      "Section de l'isthme d'un rein en fer à cheval, par cœlioscopie",
    tarifSecteur1Optam: "567.2",
    tarifHorsSecteur1HorsOptam: "374.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAPA001",
    designation:
      "Section de l'isthme d'un rein en fer à cheval, par abord direct",
    tarifSecteur1Optam: "538.13",
    tarifHorsSecteur1HorsOptam: "417.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCC003",
    designation:
      "Suture de plaie ou de rupture de l'uretère, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "536.71",
    tarifHorsSecteur1HorsOptam: "411.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA003",
    designation: "Suture de plaie ou de rupture de l'uretère, par abord direct",
    tarifSecteur1Optam: "263.75",
    tarifHorsSecteur1HorsOptam: "240.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCLE001",
    designation:
      "Pose d'une sonde urétérale à visée thérapeutique, par endoscopie rétrograde",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCLD001",
    designation:
      "Pose d'une endoprothèse urétérale, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "133.29",
    tarifHorsSecteur1HorsOptam: "113.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCLH001",
    designation:
      "Pose d'une endoprothèse urétérale, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "219.79",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCLE002",
    designation: "Pose d'une endoprothèse urétérale, par endoscopie rétrograde",
    tarifSecteur1Optam: "101.39",
    tarifHorsSecteur1HorsOptam: "95.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGE003",
    designation:
      "Ablation unilatérale ou bilatérale d'une sonde urétérale, par endoscopie rétrograde",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCGH002",
    designation:
      "Ablation d'une endoprothèse urétérale par une néphrostomie déjà en place, avec guidage radiologique",
    tarifSecteur1Optam: "56.01",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCGE004",
    designation:
      "Ablation d'une endoprothèse urétérale, par endoscopie rétrograde",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAKD001",
    designation: "Changement d'une sonde de néphrostomie ou de pyélostomie",
    tarifSecteur1Optam: "48.21",
    tarifHorsSecteur1HorsOptam: "48.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCKD001",
    designation: "Changement d'une sonde d'urétérostomie cutanée",
    tarifSecteur1Optam: "48.21",
    tarifHorsSecteur1HorsOptam: "48.12",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCKE001",
    designation: "Changement d'une sonde urétérale, par endoscopie rétrograde",
    tarifSecteur1Optam: "107.06",
    tarifHorsSecteur1HorsOptam: "98.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCKH001",
    designation:
      "Changement d’une endoprothèse urétérale par une néphrostomie déjà en place, avec guidage radiologique",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCKE002",
    designation:
      "Changement d’une endoprothèse urétérale, par endoscopie rétrograde",
    tarifSecteur1Optam: "90.75",
    tarifHorsSecteur1HorsOptam: "88.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JACH001",
    designation:
      "Néphrostomie, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAMA001",
    designation:
      "Internalisation secondaire d'une néphrostomie cutanée déjà en place",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAMB001",
    designation:
      "Internalisation d'une néphrostomie déjà en place, par voies transcutanée et rétrograde",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JACH002",
    designation:
      "Néphrostomie par voie transcutanée avec drainage internalisé de la voie excrétrice urinaire, avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JACH003",
    designation:
      "Néphrostomie, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JACA002",
    designation: "Néphrostomie cutanée, par abord direct",
    tarifSecteur1Optam: "204.9",
    tarifHorsSecteur1HorsOptam: "189.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBCA001",
    designation: "Pyélostomie cutanée, par abord direct",
    tarifSecteur1Optam: "241.06",
    tarifHorsSecteur1HorsOptam: "210.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCB001",
    designation: "Urétérostomie, par voie transcutanée",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCCC001",
    designation:
      "Urétérostomie cutanée, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "275.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA002",
    designation: "Urétérostomie cutanée, par abord direct",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "196.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA014",
    designation:
      "Urétérostomie cutanée avec anastomose urétéro-urétérale croisée, par abord direct",
    tarifSecteur1Optam: "575.71",
    tarifHorsSecteur1HorsOptam: "456.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCC002",
    designation:
      "Urétérostomie cutanée transintestinale par anse non détubulée, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "665.04",
    tarifHorsSecteur1HorsOptam: "599",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA006",
    designation:
      "Urétérostomie cutanée transintestinale par anse non détubulée, par abord direct",
    tarifSecteur1Optam: "577.13",
    tarifHorsSecteur1HorsOptam: "535.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA011",
    designation:
      "Urétérostomie cutanée transintestinale avec création d'un réservoir continent, par abord direct",
    tarifSecteur1Optam: "583.48",
    tarifHorsSecteur1HorsOptam: "583.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA005",
    designation: "Anastomose urétérocalicielle, par abord direct",
    tarifSecteur1Optam: "521.12",
    tarifHorsSecteur1HorsOptam: "390.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA007",
    designation:
      "Dérivation urinaire par anastomose urétéro-urétérale homolatérale, par abord direct",
    tarifSecteur1Optam: "333.94",
    tarifHorsSecteur1HorsOptam: "281.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA008",
    designation:
      "Dérivation urinaire par anastomose urétéro-urétérale croisée, par abord direct",
    tarifSecteur1Optam: "546.64",
    tarifHorsSecteur1HorsOptam: "439.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEA005",
    designation:
      "Réimplantation urétérovésicale sans création de montage antireflux, par abord direct",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEC006",
    designation:
      "Réimplantation urétérovésicale unilatérale avec création de montage antireflux, par cœlioscopie",
    tarifSecteur1Optam: "497.72",
    tarifHorsSecteur1HorsOptam: "403.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEC011",
    designation:
      "Réimplantation urétérovésicale bilatérale avec création de montage antireflux, par cœlioscopie",
    tarifSecteur1Optam: "618.5",
    tarifHorsSecteur1HorsOptam: "581.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEA002",
    designation:
      "Réimplantation urétérovésicale unilatérale avec création de montage antireflux, par abord direct",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEA001",
    designation:
      "Réimplantation urétérovésicale bilatérale avec création de montage antireflux, par abord direct",
    tarifSecteur1Optam: "531.23",
    tarifHorsSecteur1HorsOptam: "531.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEA003",
    designation:
      "Réimplantation urétérovésicale avec plastie de la vessie par mobilisation [vessie psoïque] ou allongement par lambeau pédiculé, par abord direct",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCMA004",
    designation:
      "Création d'un montage urétérovésical antireflux sans réimplantation urétérovésicale, par abord direct",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "284.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEA004",
    designation:
      "Réimplantation vésicale d'une duplicité urétérale, par abord direct",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA013",
    designation:
      "Anastomose urétérocolique ou urétérorectale directe, par abord direct",
    tarifSecteur1Optam: "413.35",
    tarifHorsSecteur1HorsOptam: "327.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA012",
    designation:
      "Anastomose urétérocolique ou urétérorectale avec confection d'un réservoir détubulé rectosigmoïdien ou iléo-recto-sigmoïdien, par abord direct",
    tarifSecteur1Optam: "743.05",
    tarifHorsSecteur1HorsOptam: "743.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCMA001",
    designation:
      "Transformation d'une urétérostomie cutanée en anastomose urétérocolique directe",
    tarifSecteur1Optam: "665.04",
    tarifHorsSecteur1HorsOptam: "473.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCMA002",
    designation:
      "Transformation d'une urétérostomie cutanée en dérivation interne avec confection d'un réservoir détubulé rectosigmoïdien ou iléo-recto-sigmoïdien",
    tarifSecteur1Optam: "757.03",
    tarifHorsSecteur1HorsOptam: "757.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCMA003",
    designation:
      "Transformation d'une urétérostomie cutanée en dérivation interne par réimplantation urétérovésicale",
    tarifSecteur1Optam: "519.57",
    tarifHorsSecteur1HorsOptam: "519.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCMA005",
    designation:
      "Transformation d'une urétérostomie cutanée en dérivation interne avec entérocystoplastie de remplacement [néovessie] orthotopique par anse intestinale détubulée",
    tarifSecteur1Optam: "1223.73",
    tarifHorsSecteur1HorsOptam: "998.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JANM002",
    designation:
      "Lithotritie extracorporelle du rein, avec guidage échographique",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JANM001",
    designation:
      "Lithotritie extracorporelle du rein, avec guidage radiologique",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCNM001",
    designation:
      "Lithotritie extracorporelle de l'uretère, avec guidage échographique",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCNM002",
    designation:
      "Lithotritie extracorporelle de l'uretère, avec guidage radiologique",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JANJ002",
    designation:
      "Fragmentation intrarénale de calcul par ondes de choc [Lithotritie intrarénale], par néphroscopie par une néphrostomie déjà en place",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JANE005",
    designation:
      "Fragmentation intrarénale de calcul avec ondes de choc ou laser [Lithotritie intrarénale], par urétéronéphroscopie",
    tarifSecteur1Optam: "428.95",
    tarifHorsSecteur1HorsOptam: "336.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JANE002",
    designation:
      "Fragmentation intrarénale de calcul caliciel inférieur avec ondes de choc ou laser [Lithotritie intrarénale], par urétéronéphroscopie",
    tarifSecteur1Optam: "471.49",
    tarifHorsSecteur1HorsOptam: "369.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGD001",
    designation:
      "Ablation de calcul du rein, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "540.26",
    tarifHorsSecteur1HorsOptam: "431.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGF002",
    designation: "Ablation d'un calcul simple du rein, par voie transcutanée",
    tarifSecteur1Optam: "471.49",
    tarifHorsSecteur1HorsOptam: "391.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAGD002",
    designation:
      "Ablation d'un calcul coralliforme ou complexe du rein, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "531.75",
    tarifHorsSecteur1HorsOptam: "426.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGF003",
    designation:
      "Ablation d'un calcul coralliforme ou complexe du rein, par voie transcutanée",
    tarifSecteur1Optam: "620.38",
    tarifHorsSecteur1HorsOptam: "477.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAGC001",
    designation:
      "Ablation d'un calcul du rein par pyélotomie, par rétropéritonéoscopie",
    tarifSecteur1Optam: "378.61",
    tarifHorsSecteur1HorsOptam: "337.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGA001",
    designation:
      "Ablation d'un calcul du rein par pyélotomie, par abord direct",
    tarifSecteur1Optam: "307.71",
    tarifHorsSecteur1HorsOptam: "296.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGA004",
    designation:
      "Ablation d'un calcul du rein par néphrotomie, par abord direct",
    tarifSecteur1Optam: "533.88",
    tarifHorsSecteur1HorsOptam: "427.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGA002",
    designation:
      "Ablation d'un calcul du rein par néphrotomie de la convexité, par abord direct",
    tarifSecteur1Optam: "638.1",
    tarifHorsSecteur1HorsOptam: "488.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGA006",
    designation:
      "Ablation d'un calcul du rein par pyélotomie et néphrotomie, par abord direct",
    tarifSecteur1Optam: "638.1",
    tarifHorsSecteur1HorsOptam: "488.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGF001",
    designation: "Ablation de calculs multiples du rein, par voie transcutanée",
    tarifSecteur1Optam: "620.38",
    tarifHorsSecteur1HorsOptam: "477.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JAGA003",
    designation:
      "Ablation de calculs multiples du rein par pyélotomie et/ou néphrotomie, par abord direct",
    tarifSecteur1Optam: "753.67",
    tarifHorsSecteur1HorsOptam: "555.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JAGA005",
    designation:
      "Ablation de calculs multiples du rein par néphrotomie de la convexité, par abord direct",
    tarifSecteur1Optam: "753.67",
    tarifHorsSecteur1HorsOptam: "555.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCEE001",
    designation:
      "Refoulement de calcul de l'uretère par sonde urétérale, par endoscopie rétrograde",
    tarifSecteur1Optam: "116.28",
    tarifHorsSecteur1HorsOptam: "103.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGH005",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère, par une néphrostomie déjà en place avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "308.42",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGH001",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "415.47",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCGE002",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère, par urétéroscopie antérograde par une néphrostomie déjà en place",
    tarifSecteur1Optam: "345.28",
    tarifHorsSecteur1HorsOptam: "283.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGG001",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère, par urétéroscopie antérograde par voie transcutanée",
    tarifSecteur1Optam: "618.96",
    tarifHorsSecteur1HorsOptam: "477.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCGH004",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère, par urétrocystoscopie avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGE006",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère lombal, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "392.08",
    tarifHorsSecteur1HorsOptam: "327.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGC003",
    designation:
      "Ablation de calcul de l'uretère lombal, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "287.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGA002",
    designation: "Ablation de calcul de l'uretère lombal, par abord direct",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "239.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGE005",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère iliaque, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "348.83",
    tarifHorsSecteur1HorsOptam: "285.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGC002",
    designation:
      "Ablation de calcul de l'uretère iliaque, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "287.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGA003",
    designation: "Ablation de calcul de l'uretère iliaque, par abord direct",
    tarifSecteur1Optam: "199.94",
    tarifHorsSecteur1HorsOptam: "198.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGE001",
    designation:
      "Ablation et/ou fragmentation de calcul de l'uretère pelvien, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "317.63",
    tarifHorsSecteur1HorsOptam: "267.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGC001",
    designation:
      "Ablation de calcul de l'uretère pelvien, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "305.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGA001",
    designation: "Ablation de calcul de l'uretère pelvien, par abord direct",
    tarifSecteur1Optam: "309.83",
    tarifHorsSecteur1HorsOptam: "280.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBPH001",
    designation: "Urétéropyélotomie ou urétérotomie, avec guidage radiologique",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JBPE002",
    designation:
      "Urétéropyélotomie ou urétérotomie, par néphroscopie par une néphrostomie déjà en place",
    tarifSecteur1Optam: "581.38",
    tarifHorsSecteur1HorsOptam: "429.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JBPG001",
    designation:
      "Urétéropyélotomie ou urétérotomie, par néphroscopie par voie transcutanée",
    tarifSecteur1Optam: "483.54",
    tarifHorsSecteur1HorsOptam: "372.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JBPE001",
    designation:
      "Urétéropyélotomie ou urétérotomie, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "314.8",
    tarifHorsSecteur1HorsOptam: "274.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBMC001",
    designation:
      "Plastie du bassinet et de la jonction pyélo-urétérale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "636.68",
    tarifHorsSecteur1HorsOptam: "481.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBMA001",
    designation:
      "Plastie du bassinet et de la jonction pyélo-urétérale, par abord direct",
    tarifSecteur1Optam: "348.83",
    tarifHorsSecteur1HorsOptam: "302.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBNE004",
    designation:
      "Destruction de tumeur calicielle et/ou pyélique avec laser, par urétéronéphroscopie",
    tarifSecteur1Optam: "471.49",
    tarifHorsSecteur1HorsOptam: "379.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCND001",
    designation:
      "Destruction de tumeur de l'uretère lombal, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "241.77",
    tarifHorsSecteur1HorsOptam: "223.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCNE004",
    designation:
      "Destruction de tumeur de l'uretère lombal, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "336.78",
    tarifHorsSecteur1HorsOptam: "278.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCNF001",
    designation:
      "Destruction de tumeur de l'uretère lombal, par voie transcutanée",
    tarifSecteur1Optam: "295.22",
    tarifHorsSecteur1HorsOptam: "295.22",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCNE003",
    designation:
      "Destruction de tumeur de l'uretère iliaque, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "309.83",
    tarifHorsSecteur1HorsOptam: "262.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCNE002",
    designation:
      "Destruction de tumeur de l'uretère pelvien, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "229.72",
    tarifHorsSecteur1HorsOptam: "216.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCNE001",
    designation:
      "Destruction d'un moignon de l'uretère, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBFE001",
    designation:
      "Exérèse de tumeur pyélique et/ou calicielle, par endoscopie rétrograde",
    tarifSecteur1Optam: "466.52",
    tarifHorsSecteur1HorsOptam: "388.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBFA001",
    designation:
      "Exérèse de tumeur pyélique et/ou calicielle, avec néphrostomie par voie transcutanée",
    tarifSecteur1Optam: "577.84",
    tarifHorsSecteur1HorsOptam: "453.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFD001",
    designation:
      "Exérèse de tumeur de l'uretère lombal, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "293.53",
    tarifHorsSecteur1HorsOptam: "253.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCFF001",
    designation: "Exérèse de tumeur de l'uretère lombal, par voie transcutanée",
    tarifSecteur1Optam: "472.19",
    tarifHorsSecteur1HorsOptam: "356.91",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCFE001",
    designation:
      "Exérèse de tumeur de l'uretère lombal, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "406.26",
    tarifHorsSecteur1HorsOptam: "318.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFE003",
    designation:
      "Exérèse de tumeur de l'uretère iliaque, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "380.02",
    tarifHorsSecteur1HorsOptam: "303.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFE002",
    designation:
      "Exérèse de tumeur de l'uretère pelvien, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "316.21",
    tarifHorsSecteur1HorsOptam: "266.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBFC001",
    designation:
      "Résection-anastomose du bassinet et de la jonction pyélo-urétérale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "776.36",
    tarifHorsSecteur1HorsOptam: "562.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JBFA002",
    designation:
      "Résection-anastomose du bassinet et de la jonction pyélo-urétérale, par abord direct",
    tarifSecteur1Optam: "426.82",
    tarifHorsSecteur1HorsOptam: "348.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA002",
    designation:
      "Urétérectomie segmentaire lombo-iliaque avec rétablissement de la continuité de l'uretère, par abord direct",
    tarifSecteur1Optam: "375.06",
    tarifHorsSecteur1HorsOptam: "305.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA003",
    designation:
      "Urétérectomie segmentaire pelvienne avec rétablissement de la continuité de l'uretère, par abord direct",
    tarifSecteur1Optam: "396.33",
    tarifHorsSecteur1HorsOptam: "317.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA009",
    designation:
      "Urétérectomie segmentaire pelvienne avec réimplantation urétérovésicale, par abord direct",
    tarifSecteur1Optam: "488.5",
    tarifHorsSecteur1HorsOptam: "440.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA008",
    designation:
      "Urétérectomie segmentaire pelvienne avec réimplantation urétérovésicale et montage antireflux, par abord direct",
    tarifSecteur1Optam: "573.58",
    tarifHorsSecteur1HorsOptam: "489.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA010",
    designation:
      "Urétérectomie segmentaire pelvienne avec réimplantation urétérovésicale et plastie de la vessie par mobilisation [vessie psoïque] ou allongement par lambeau pédiculé, par abord direct",
    tarifSecteur1Optam: "507.64",
    tarifHorsSecteur1HorsOptam: "451.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCPA003",
    designation: "Section-anastomose d'un uretère rétrocave, par abord direct",
    tarifSecteur1Optam: "476.45",
    tarifHorsSecteur1HorsOptam: "364.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA005",
    designation:
      "Résection longitudinale modelante d’un méga-uretère avec réimplantation urétérovésicale et montage antireflux, par abord direct",
    tarifSecteur1Optam: "577.84",
    tarifHorsSecteur1HorsOptam: "492.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA007",
    designation:
      "Exérèse d'une urétérocèle avec réimplantation urétérovésicale homolatérale, par abord direct",
    tarifSecteur1Optam: "406.97",
    tarifHorsSecteur1HorsOptam: "393.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA004",
    designation:
      "Exérèse d'une urétérocèle avec réimplantation urétérovésicale, héminéphrectomie et urétérectomie totale homolatérales, par abord direct",
    tarifSecteur1Optam: "663.12",
    tarifHorsSecteur1HorsOptam: "663.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA006",
    designation:
      "Exérèse unilatérale d'une urétérocèle avec réimplantation urétérovésicale bilatérale, par abord direct",
    tarifSecteur1Optam: "531.23",
    tarifHorsSecteur1HorsOptam: "531.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFC001",
    designation:
      "Urétérectomie totale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "305.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCFA001",
    designation: "Urétérectomie totale, par abord direct",
    tarifSecteur1Optam: "285.73",
    tarifHorsSecteur1HorsOptam: "266.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCKA001",
    designation:
      "Remplacement partiel de l'uretère par un segment digestif, par abord direct",
    tarifSecteur1Optam: "667.17",
    tarifHorsSecteur1HorsOptam: "531.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCKA002",
    designation:
      "Remplacement total de l'uretère par un segment digestif, par abord direct",
    tarifSecteur1Optam: "730.98",
    tarifHorsSecteur1HorsOptam: "568.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA010",
    designation:
      "Dérivation de l'urine par conduit rénovésical prothétique souscutané, sans néphrostomie",
    tarifSecteur1Optam: "319.3",
    tarifHorsSecteur1HorsOptam: "319.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCCA009",
    designation:
      "Dérivation de l'urine par conduit rénovésical prothétique souscutané, avec néphrostomie",
    tarifSecteur1Optam: "509.77",
    tarifHorsSecteur1HorsOptam: "308.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCAD001",
    designation: "Dilatation de l'uretère, par une néphrostomie déjà en place",
    tarifSecteur1Optam: "244.61",
    tarifHorsSecteur1HorsOptam: "224.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCAF001",
    designation: "Dilatation de l'uretère, par voie transcutanée",
    tarifSecteur1Optam: "402",
    tarifHorsSecteur1HorsOptam: "316.19",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCAE001",
    designation: "Dilatation de l'uretère, par urétéroscopie rétrograde",
    tarifSecteur1Optam: "284.31",
    tarifHorsSecteur1HorsOptam: "247.92",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCAH001",
    designation:
      "Dilatation de l'uretère, par urétrocystoscopie avec guidage radiologique",
    tarifSecteur1Optam: "166.62",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCPD001",
    designation: "Reperméation de l'uretère par forage intraluminal",
    tarifSecteur1Optam: "324.72",
    tarifHorsSecteur1HorsOptam: "271.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCPC002",
    designation:
      "Libération de l'uretère sans intrapéritonisation, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "287.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCPA002",
    designation:
      "Libération de l'uretère sans intrapéritonisation, par abord direct",
    tarifSecteur1Optam: "358.05",
    tarifHorsSecteur1HorsOptam: "290.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCPC001",
    designation:
      "Libération de l'uretère avec intrapéritonisation ou lambeau péritonéal, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "460.85",
    tarifHorsSecteur1HorsOptam: "362.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCPA001",
    designation:
      "Libération de l'uretère avec intrapéritonisation ou lambeau péritonéal, par abord direct",
    tarifSecteur1Optam: "428.24",
    tarifHorsSecteur1HorsOptam: "331.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCSA003",
    designation:
      "Fermeture de fistule urétérocutanée acquise, par abord direct",
    tarifSecteur1Optam: "360.17",
    tarifHorsSecteur1HorsOptam: "296.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCSA001",
    designation:
      "Fermeture de fistule urétérodigestive acquise, par abord direct",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "393.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCSA002",
    designation:
      "Fermeture de fistule urétéro-utérine acquise, par abord direct",
    tarifSecteur1Optam: "358.65",
    tarifHorsSecteur1HorsOptam: "358.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCSA005",
    designation:
      "Fermeture de fistule urétérovésicale acquise, par abord direct",
    tarifSecteur1Optam: "451.63",
    tarifHorsSecteur1HorsOptam: "367.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCSA004",
    designation:
      "Fermeture de fistule urétérovaginale acquise, par abord direct",
    tarifSecteur1Optam: "436.74",
    tarifHorsSecteur1HorsOptam: "358.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCLE004",
    designation:
      "Injection sousmuqueuse intra-urétérale de matériel hétérologue, par endoscopie",
    tarifSecteur1Optam: "110.6",
    tarifHorsSecteur1HorsOptam: "104.47",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JCPE002",
    designation: "Incision du méat urétéral, par endoscopie",
    tarifSecteur1Optam: "110.6",
    tarifHorsSecteur1HorsOptam: "100.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCPE001",
    designation: "Incision d'une urétérocèle, par endoscopie",
    tarifSecteur1Optam: "199.94",
    tarifHorsSecteur1HorsOptam: "152.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JCGH003",
    designation:
      "Ablation d'un fragment de matériel intra-urétéral par une néphrostomie déjà en place, avec guidage radiologique",
    tarifSecteur1Optam: "147.47",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDJD001",
    designation:
      "Évacuation de la vessie par cathétérisme transitoire [Sondage vésical évacuateur]",
    tarifSecteur1Optam: "21.98",
    tarifHorsSecteur1HorsOptam: "16.78",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDLD001",
    designation: "Pose d'une sonde urétrovésicale [Sondage vésical à demeure]",
    tarifSecteur1Optam: "18.43",
    tarifHorsSecteur1HorsOptam: "14.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDLF001",
    designation:
      "Pose d'un cathéter intravésical, par voie transcutanée suprapubienne",
    tarifSecteur1Optam: "63.81",
    tarifHorsSecteur1HorsOptam: "49.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDJD002",
    designation: "Décaillotage de la vessie par sonde, par voie urétrale",
    tarifSecteur1Optam: "48.21",
    tarifHorsSecteur1HorsOptam: "31.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDJE001",
    designation: "Décaillotage de la vessie, par endoscopie",
    tarifSecteur1Optam: "90.04",
    tarifHorsSecteur1HorsOptam: "88.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDCJ001",
    designation:
      "Cystostomie, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDCA002",
    designation: "Cystostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "128.33",
    tarifHorsSecteur1HorsOptam: "127.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDCA001",
    designation:
      "Cystostomie cutanée continente sans fermeture du col de la vessie, par laparotomie",
    tarifSecteur1Optam: "506.94",
    tarifHorsSecteur1HorsOptam: "381.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDCA004",
    designation:
      "Cystostomie cutanée continente avec fermeture du col de la vessie, par laparotomie",
    tarifSecteur1Optam: "653.7",
    tarifHorsSecteur1HorsOptam: "515.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDKD002",
    designation: "Changement d'une sonde urétrovésicale",
    tarifSecteur1Optam: "18.43",
    tarifHorsSecteur1HorsOptam: "14.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDKD001",
    designation: "Changement d'une sonde de cystostomie",
    tarifSecteur1Optam: "33.32",
    tarifHorsSecteur1HorsOptam: "23.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDGE001",
    designation:
      "Ablation de calcul ou de corps étranger de la vessie, par endoscopie",
    tarifSecteur1Optam: "139.67",
    tarifHorsSecteur1HorsOptam: "133.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDNM001",
    designation: "Lithotritie extracorporelle de la vessie",
    tarifSecteur1Optam: "185.76",
    tarifHorsSecteur1HorsOptam: "160.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDNE001",
    designation: "Destruction de lésion de la vessie, par endoscopie",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "106.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFE002",
    designation: "Résection de 1 à 3 tumeurs de la vessie, par endoscopie",
    tarifSecteur1Optam: "177.96",
    tarifHorsSecteur1HorsOptam: "173.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFE001",
    designation: "Résection de 4 tumeurs de la vessie ou plus, par endoscopie",
    tarifSecteur1Optam: "236.1",
    tarifHorsSecteur1HorsOptam: "207.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFC001",
    designation: "Exérèse de diverticule de la vessie, par cœlioscopie",
    tarifSecteur1Optam: "342.3",
    tarifHorsSecteur1HorsOptam: "342.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA002",
    designation: "Exérèse de diverticule de la vessie, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFC023",
    designation: "Cystectomie partielle, par cœlioscopie",
    tarifSecteur1Optam: "279.6",
    tarifHorsSecteur1HorsOptam: "279.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA011",
    designation: "Cystectomie partielle, par laparotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA014",
    designation:
      "Cystectomie partielle avec implantation de matériel pour irradiation interstitielle de la vessie, par laparotomie",
    tarifSecteur1Optam: "554.44",
    tarifHorsSecteur1HorsOptam: "453.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA017",
    designation:
      "Cystectomie partielle avec réimplantation urétérovésicale, par laparotomie",
    tarifSecteur1Optam: "565.07",
    tarifHorsSecteur1HorsOptam: "537.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA004",
    designation:
      "Cystectomie supratrigonale avec entérocystoplastie détubulée d'agrandissement, par laparotomie",
    tarifSecteur1Optam: "1134.4",
    tarifHorsSecteur1HorsOptam: "955.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA015",
    designation:
      "Cystectomie supratrigonale avec entérocystoplastie détubulée d'agrandissement et réimplantation urétérovésicale, par laparotomie",
    tarifSecteur1Optam: "1418",
    tarifHorsSecteur1HorsOptam: "1133.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA005",
    designation: "Cystectomie totale, par laparotomie",
    tarifSecteur1Optam: "645.9",
    tarifHorsSecteur1HorsOptam: "575.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA001",
    designation:
      "Cystectomie totale avec urétérostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "863.56",
    tarifHorsSecteur1HorsOptam: "702.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA008",
    designation:
      "Cystectomie totale avec urétérostomie cutanée transintestinale par anse non détubulée, par laparotomie",
    tarifSecteur1Optam: "1065.63",
    tarifHorsSecteur1HorsOptam: "907.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA003",
    designation:
      "Cystectomie totale avec urétérostomie cutanée transintestinale par anse détubulée continente, par laparotomie",
    tarifSecteur1Optam: "1197.5",
    tarifHorsSecteur1HorsOptam: "983.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA009",
    designation:
      "Cystectomie totale avec anastomose urétérocolique directe, par laparotomie",
    tarifSecteur1Optam: "940.13",
    tarifHorsSecteur1HorsOptam: "790.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA006",
    designation:
      "Cystectomie totale avec anastomose urétérocolique et confection d'un réservoir détubulé rectosigmoïdien ou iléo-recto-sigmoïdien, par laparotomie",
    tarifSecteur1Optam: "1240.04",
    tarifHorsSecteur1HorsOptam: "1008.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA016",
    designation:
      "Cystectomie totale avec entérocystoplastie de remplacement [néovessie] orthotopique par anse détubulée, par laparotomie",
    tarifSecteur1Optam: "1498.12",
    tarifHorsSecteur1HorsOptam: "1157.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDC002",
    designation:
      "Cervicocystopexie indirecte au ligament pectinéal [de Cooper], par cœlioscopie",
    tarifSecteur1Optam: "258.08",
    tarifHorsSecteur1HorsOptam: "232.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA002",
    designation:
      "Cervicocystopexie indirecte au ligament pectinéal [de Cooper], par laparotomie",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA008",
    designation:
      "Cervicocystopexie indirecte par abord vaginal et par voie abdominale, avec guidage endoscopique",
    tarifSecteur1Optam: "207.29",
    tarifHorsSecteur1HorsOptam: "207.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA007",
    designation:
      "Cervicocystopexie indirecte par bandelette vaginale pédiculée, par laparotomie et par abord vaginal",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA005",
    designation:
      "Cervicocystopexie directe par bandelette prothétique infra-urétrale, par laparotomie",
    tarifSecteur1Optam: "255.95",
    tarifHorsSecteur1HorsOptam: "231.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA004",
    designation:
      "Cervicocystopexie directe par bandelette aponévrotique infra-urétrale, par laparotomie",
    tarifSecteur1Optam: "310.54",
    tarifHorsSecteur1HorsOptam: "263.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA006",
    designation:
      "Cervicocystopexie directe par bandelette infra-urétrale, par laparotomie et par voie transvaginale",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDA003",
    designation:
      "Cervicocystopexie par bandelette par abord vaginal et par voie transcutanée, avec guidage endoscopique",
    tarifSecteur1Optam: "216.25",
    tarifHorsSecteur1HorsOptam: "212.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDB005",
    designation:
      "Soutènement vésical par bandelette synthétique infra-urétrale, par voie transvaginale et par voie transobturatrice",
    tarifSecteur1Optam: "207.29",
    tarifHorsSecteur1HorsOptam: "207.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDDB007",
    designation:
      "Soutènement vésical par bandelette synthétique infra-urétrale, par voie transvaginale et par voie transrétropubienne, avec contrôle endoscopique",
    tarifSecteur1Optam: "207.29",
    tarifHorsSecteur1HorsOptam: "207.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMBA001",
    designation: "Plicature infra-urétrale, par abord vaginal",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMDA001",
    designation:
      "Soutènement vésical par myoplastie infra-urétrale, par abord vaginal",
    tarifSecteur1Optam: "216.25",
    tarifHorsSecteur1HorsOptam: "208.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRPA001",
    designation:
      "Section d'une bandelette synthétique infra-urétrale, par abord vaginal",
    tarifSecteur1Optam: "99.26",
    tarifHorsSecteur1HorsOptam: "83.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRGA001",
    designation:
      "Ablation d'une bandelette synthétique infra-urétrale, par abord vaginal",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "188.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRGC001",
    designation:
      "Ablation partielle d'une bandelette synthétique infra-urétrale, par cœlioscopie",
    tarifSecteur1Optam: "241.06",
    tarifHorsSecteur1HorsOptam: "217.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRGA002",
    designation:
      "Ablation partielle d'une bandelette synthétique infra-urétrale, par laparotomie",
    tarifSecteur1Optam: "241.06",
    tarifHorsSecteur1HorsOptam: "205.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRGA004",
    designation:
      "Ablation totale d'une bandelette synthétique infra-urétrale, par cœlioscopie et par abord vaginal",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "259.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRGA003",
    designation:
      "Ablation totale d'une bandelette synthétique infra-urétrale, par laparotomie et par abord vaginal",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "239.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDCC016",
    designation: "Suture de plaie ou de rupture de vessie, par cœlioscopie",
    tarifSecteur1Optam: "226.49",
    tarifHorsSecteur1HorsOptam: "226.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDCA003",
    designation: "Suture de plaie ou de rupture de vessie, par laparotomie",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDMA001",
    designation:
      "Entérocystoplastie d'agrandissement par anse détubulée, par laparotomie",
    tarifSecteur1Optam: "583.48",
    tarifHorsSecteur1HorsOptam: "583.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDMA002",
    designation:
      "Entérocystoplastie d'agrandissement avec réimplantation urétérovésicale bilatérale, par laparotomie",
    tarifSecteur1Optam: "740.23",
    tarifHorsSecteur1HorsOptam: "740.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA008",
    designation: "Fermeture de fistule vésicocutanée acquise, par abord direct",
    tarifSecteur1Optam: "110.6",
    tarifHorsSecteur1HorsOptam: "99.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA011",
    designation:
      "Fermeture de fistule vésicodigestive acquise, par laparotomie",
    tarifSecteur1Optam: "567.2",
    tarifHorsSecteur1HorsOptam: "447.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA005",
    designation: "Fermeture de fistule vésico-utérine acquise, par laparotomie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSC024",
    designation: "Fermeture de fistule vésicovaginale acquise, par cœlioscopie",
    tarifSecteur1Optam: "366.27",
    tarifHorsSecteur1HorsOptam: "342.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA006",
    designation:
      "Fermeture de fistule vésicovaginale acquise, par abord vaginal",
    tarifSecteur1Optam: "337.48",
    tarifHorsSecteur1HorsOptam: "313.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA002",
    designation: "Fermeture de fistule vésicovaginale acquise, par laparotomie",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "386.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA007",
    designation:
      "Résection d'un kyste ou d'une fistule du ligament ombilical médian [ouraque]",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "193.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA012",
    designation:
      "Exérèse de la plaque d'une exstrophie vésicale avec dérivation urinaire, sans ostéotomie du bassin",
    tarifSecteur1Optam: "606.2",
    tarifHorsSecteur1HorsOptam: "439.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA013",
    designation:
      "Exérèse de la plaque d'une exstrophie vésicale avec dérivation urinaire et cure unilatérale ou bilatérale de hernie de l'aine, sans ostéotomie du bassin",
    tarifSecteur1Optam: "565.89",
    tarifHorsSecteur1HorsOptam: "565.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA003",
    designation:
      "Fermeture d'une exstrophie vésicale avec allongement du pénis, sans ostéotomie du bassin",
    tarifSecteur1Optam: "543.59",
    tarifHorsSecteur1HorsOptam: "543.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA007",
    designation:
      "Fermeture d'une exstrophie vésicale avec allongement du pénis et cure unilatérale ou bilatérale de hernie de l'aine, sans ostéotomie du bassin",
    tarifSecteur1Optam: "600.75",
    tarifHorsSecteur1HorsOptam: "600.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA004",
    designation:
      "Fermeture d'une exstrophie vésicale avec allongement de l'urètre chez la fille, sans ostéotomie du bassin",
    tarifSecteur1Optam: "505.34",
    tarifHorsSecteur1HorsOptam: "505.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDPA001",
    designation:
      "Myotomie du corps de la vessie pour agrandissement, par laparotomie",
    tarifSecteur1Optam: "273.67",
    tarifHorsSecteur1HorsOptam: "211.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDLD002",
    designation:
      "Instillation vésicale d'agent pharmacologique par cathétérisme urétral",
    tarifSecteur1Optam: "39.7",
    tarifHorsSecteur1HorsOptam: "32.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDLD659",
    designation:
      "Instillation vésicale d’agent pharmacologique anticancéreux avec hyperthermie par radiofréquences, par voie urétrale ",
    tarifSecteur1Optam: "108",
    tarifHorsSecteur1HorsOptam: "108",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDAD001",
    designation: "Distension hydrostatique de la vessie",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDLE332",
    designation:
      "Injection de toxine botulique dans la musculeuse vésicale, par urétrocystoscopie",
    tarifSecteur1Optam: "127.62",
    tarifHorsSecteur1HorsOptam: "127.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDPE001",
    designation:
      "Incision de collet de diverticule de la vessie, par urétrocystoscopie",
    tarifSecteur1Optam: "199.94",
    tarifHorsSecteur1HorsOptam: "152.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JDPA002",
    designation: "Cystotomie à visée thérapeutique, par laparotomie",
    tarifSecteur1Optam: "128.33",
    tarifHorsSecteur1HorsOptam: "127.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDLA001",
    designation:
      "Implantation vésicale de vecteur pour curiethérapie interstitielle de la vessie, par laparotomie",
    tarifSecteur1Optam: "474.32",
    tarifHorsSecteur1HorsOptam: "327.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JRRD001",
    designation:
      "Séance de rééducation vésicosphinctérienne avec apprentissage des modes de déclenchement mictionnel ou de l'autosondage",
    tarifSecteur1Optam: "50.02",
    tarifHorsSecteur1HorsOptam: "36.27",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JELA004",
    designation:
      "Pose d'une prothèse sphinctérienne urinaire péricervicale avec abord rétrovésical chez l'homme, par laparotomie",
    tarifSecteur1Optam: "665.04",
    tarifHorsSecteur1HorsOptam: "491.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELA001",
    designation:
      "Pose d'une prothèse sphinctérienne urinaire péricervicale chez la femme, par laparotomie",
    tarifSecteur1Optam: "433.91",
    tarifHorsSecteur1HorsOptam: "357.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELA003",
    designation:
      "Pose d'une prothèse sphinctérienne urinaire périurétroprostatique, par laparotomie",
    tarifSecteur1Optam: "433.91",
    tarifHorsSecteur1HorsOptam: "357.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELA002",
    designation:
      "Pose d'une prothèse sphinctérienne urinaire périurétrale pénienne ou bulbomembranacée [bulbomembraneuse], par abord direct",
    tarifSecteur1Optam: "374.35",
    tarifHorsSecteur1HorsOptam: "322.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELB003",
    designation:
      "Pose de prothèse sphinctérienne ajustable périurétrale chez l'homme, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "250.28",
    tarifHorsSecteur1HorsOptam: "215.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGA004",
    designation:
      "Ablation d'une prothèse sphinctérienne urinaire péricervicale ou périurétroprostatique chez l'homme, par laparotomie",
    tarifSecteur1Optam: "277.22",
    tarifHorsSecteur1HorsOptam: "243.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGA005",
    designation:
      "Ablation d'une prothèse sphinctérienne urinaire péricervicale chez la femme, par laparotomie",
    tarifSecteur1Optam: "277.22",
    tarifHorsSecteur1HorsOptam: "243.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGA002",
    designation:
      "Ablation d'une prothèse sphinctérienne urinaire périurétrale pénienne ou bulbomembranacée [bulbomembraneuse], par abord direct",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGB005",
    designation:
      "Ablation de prothèse sphinctérienne ajustable périurétrale chez l'homme, par voie transcutanée",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "93.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA007",
    designation:
      "Changement du manchon gonflable d'une prothèse sphinctérienne urinaire péricervicale ou périurétroprostatique chez l'homme, par laparotomie",
    tarifSecteur1Optam: "354.5",
    tarifHorsSecteur1HorsOptam: "310.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA006",
    designation:
      "Changement du manchon gonflable d'une prothèse sphinctérienne urinaire péricervicale chez la femme, par laparotomie",
    tarifSecteur1Optam: "354.5",
    tarifHorsSecteur1HorsOptam: "310.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA005",
    designation:
      "Changement du manchon gonflable d'une prothèse sphinctérienne périurétrale pénienne ou bulbomembranacée [bulbomembraneuse], par abord direct",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "206.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA010",
    designation:
      "Changement d'élément ou repressurisation d'une prothèse sphinctérienne urinaire, par abord direct",
    tarifSecteur1Optam: "276.51",
    tarifHorsSecteur1HorsOptam: "243.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA008",
    designation:
      "Changement complet d'une prothèse sphinctérienne urinaire péricervicale ou périurétroprostatique chez l'homme, par laparotomie",
    tarifSecteur1Optam: "564.36",
    tarifHorsSecteur1HorsOptam: "432.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA009",
    designation:
      "Changement complet d'une prothèse sphinctérienne urinaire péricervicale chez la femme, par laparotomie",
    tarifSecteur1Optam: "564.36",
    tarifHorsSecteur1HorsOptam: "432.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKA002",
    designation:
      "Changement complet d'une prothèse sphinctérienne urinaire périurétrale pénienne ou bulbomembranacée [bulbomembraneuse], par abord direct",
    tarifSecteur1Optam: "486.37",
    tarifHorsSecteur1HorsOptam: "387.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKB006",
    designation:
      "Changement de prothèse sphinctérienne ajustable périurétrale chez l'homme, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "193.95",
    tarifHorsSecteur1HorsOptam: "193.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELE002",
    designation: "Pose d'une endoprothèse de l'urètre, par endoscopie",
    tarifSecteur1Optam: "120.53",
    tarifHorsSecteur1HorsOptam: "110.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELE003",
    designation:
      "Pose d'une endoprothèse de l'urètre cervicoprostatique, par endoscopie",
    tarifSecteur1Optam: "132.58",
    tarifHorsSecteur1HorsOptam: "117.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGE002",
    designation:
      "Ablation d'une endoprothèse temporaire de l'urètre, par endoscopie",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGE004",
    designation:
      "Ablation d'une endoprothèse définitive de l'urètre, par endoscopie",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEKE001",
    designation: "Changement d'une endoprothèse de l'urètre, par endoscopie",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "135.72",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEAD001",
    designation: "Dilatation de sténose de l’urètre à l'aveugle",
    tarifSecteur1Optam: "48.21",
    tarifHorsSecteur1HorsOptam: "36.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEAH001",
    designation:
      "Dilatation de sténose de l'urètre, avec contrôle radiologique",
    tarifSecteur1Optam: "84.37",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEPE154",
    designation:
      "Décompression de l’urètre par pose de dispositif intraprostatique [implant intraprostatique], par urétrocystoscopie",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "106.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEGE001",
    designation: "Ablation de corps étranger de l'urètre, par urétroscopie",
    tarifSecteur1Optam: "95.72",
    tarifHorsSecteur1HorsOptam: "91.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JEGA003",
    designation: "Ablation de corps étranger de l'urètre, par abord direct",
    tarifSecteur1Optam: "155.98",
    tarifHorsSecteur1HorsOptam: "143.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDPE002",
    designation: "Incision cervicale ou cervicoprostatique, par endoscopie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPE001",
    designation: "Incision du sphincter strié de l'urètre, par endoscopie",
    tarifSecteur1Optam: "111.31",
    tarifHorsSecteur1HorsOptam: "104.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPH001",
    designation:
      "Incision de valve de l'urètre par endoscopie, avec contrôle radiologique",
    tarifSecteur1Optam: "121.95",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JECE001",
    designation: "Ouverture de diverticule de l'urètre, par endoscopie",
    tarifSecteur1Optam: "99.26",
    tarifHorsSecteur1HorsOptam: "97.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPD001",
    designation: "Urétrotomie interne à l'aveugle",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPD002",
    designation: "Urétrotomie interne méatique ou rétroméatique à l'aveugle",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPE002",
    designation: "Urétrotomie, par endoscopie",
    tarifSecteur1Optam: "98.55",
    tarifHorsSecteur1HorsOptam: "97.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPA001",
    designation: "Mise à plat de sténose de l'urètre postérieur",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "193.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPA006",
    designation: "Mise à plat de sténose de l'urètre antérieur",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "193.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JENE001",
    designation: "Destruction de lésion de l'urètre, par endoscopie",
    tarifSecteur1Optam: "116.99",
    tarifHorsSecteur1HorsOptam: "108.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEGE003",
    designation: "Épilation de l'urètre, par endoscopie",
    tarifSecteur1Optam: "109.9",
    tarifHorsSecteur1HorsOptam: "104.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFE003",
    designation: "Résection du col de la vessie, par endoscopie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA018",
    designation: "Résection ou incision du col de la vessie, par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFE003",
    designation: "Résection du sphincter strié de l'urètre, par endoscopie",
    tarifSecteur1Optam: "130.46",
    tarifHorsSecteur1HorsOptam: "115.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFE001",
    designation: "Résection de tumeur de l'urètre, par endoscopie",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "141.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFE004",
    designation:
      "Résection de sténose de l'urètre à l'anse coupante, par endoscopie",
    tarifSecteur1Optam: "153.14",
    tarifHorsSecteur1HorsOptam: "129.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFE005",
    designation: "Résection de valve de l'urètre, par endoscopie",
    tarifSecteur1Optam: "121.95",
    tarifHorsSecteur1HorsOptam: "111.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA002",
    designation: "Exérèse de diverticule de l'urètre, par abord direct",
    tarifSecteur1Optam: "221.92",
    tarifHorsSecteur1HorsOptam: "198.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA003",
    designation:
      "Exérèse de lésion du méat urétral chez la femme, par abord direct",
    tarifSecteur1Optam: "70.9",
    tarifHorsSecteur1HorsOptam: "67.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA004",
    designation: "Urétrectomie partielle avec urétrostomie cutanée",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "176.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA010",
    designation:
      "Résection-anastomose de l'urètre postérieur, par laparotomie sans abord transsymphysaire",
    tarifSecteur1Optam: "550.18",
    tarifHorsSecteur1HorsOptam: "446.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA012",
    designation:
      "Résection-anastomose de l'urètre postérieur, par laparotomie avec abord transsymphysaire",
    tarifSecteur1Optam: "513.01",
    tarifHorsSecteur1HorsOptam: "513.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA009",
    designation:
      "Résection-anastomose de l'urètre membranacé [membraneux], par abord périnéal",
    tarifSecteur1Optam: "477.87",
    tarifHorsSecteur1HorsOptam: "382.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA011",
    designation:
      "Résection-anastomose de l'urètre bulbaire, par abord périnéal",
    tarifSecteur1Optam: "477.87",
    tarifHorsSecteur1HorsOptam: "382.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA008",
    designation:
      "Résection-anastomose de l'urètre antérieur, par abord pénoscrotal",
    tarifSecteur1Optam: "256.66",
    tarifHorsSecteur1HorsOptam: "219.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA007",
    designation: "Urétrectomie totale",
    tarifSecteur1Optam: "268",
    tarifHorsSecteur1HorsOptam: "225.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA001",
    designation: "Résection et modelage d'une urétrocèle après urétroplastie",
    tarifSecteur1Optam: "241.06",
    tarifHorsSecteur1HorsOptam: "210.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEPA007",
    designation: "Libération de l'urètre [Urétrolyse], par abord direct",
    tarifSecteur1Optam: "221.92",
    tarifHorsSecteur1HorsOptam: "198.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEEE001",
    designation:
      "Alignement de rupture traumatique de l’urètre, par endoscopie",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "147.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JECA002",
    designation: "Suture de plaie de l'urètre périnéal",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "217.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDMA003",
    designation: "Reconstruction du col de la vessie, par laparotomie",
    tarifSecteur1Optam: "304.16",
    tarifHorsSecteur1HorsOptam: "259.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA002",
    designation: "Urétrocervicoplastie de continence, par laparotomie",
    tarifSecteur1Optam: "482.83",
    tarifHorsSecteur1HorsOptam: "385.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA018",
    designation:
      "Urétrocervicoplastie de continence avec réimplantation urétérovésicale et création de montage antireflux, par laparotomie",
    tarifSecteur1Optam: "642.35",
    tarifHorsSecteur1HorsOptam: "564.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA001",
    designation:
      "Urétrocervicoplastie de continence avec entérocystoplastie d'agrandissement, par laparotomie",
    tarifSecteur1Optam: "722.47",
    tarifHorsSecteur1HorsOptam: "699.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA023",
    designation: "Plastie de l'urètre postérieur par lambeau libre",
    tarifSecteur1Optam: "489.92",
    tarifHorsSecteur1HorsOptam: "389.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA005",
    designation: "Plastie de l'urètre postérieur par lambeau pédiculé",
    tarifSecteur1Optam: "489.92",
    tarifHorsSecteur1HorsOptam: "389.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA010",
    designation: "Plastie de l'urètre antérieur par lambeau libre",
    tarifSecteur1Optam: "489.92",
    tarifHorsSecteur1HorsOptam: "389.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA007",
    designation: "Plastie de l'urètre antérieur par lambeau pédiculé",
    tarifSecteur1Optam: "489.92",
    tarifHorsSecteur1HorsOptam: "389.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA022",
    designation: "Reconstruction de l'urètre postérieur après mise à plat",
    tarifSecteur1Optam: "260.91",
    tarifHorsSecteur1HorsOptam: "256.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA015",
    designation: "Reconstruction de l'urètre antérieur après mise à plat",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "258.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA009",
    designation: "Urétroplastie chez la femme",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "173.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA012",
    designation: "Urétroplastie balanique ou rétroméatique",
    tarifSecteur1Optam: "190.72",
    tarifHorsSecteur1HorsOptam: "180.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA011",
    designation: "Méatoplastie de l'urètre par abord direct, chez le garçon",
    tarifSecteur1Optam: "80.12",
    tarifHorsSecteur1HorsOptam: "64.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA013",
    designation: "Méatoplastie de l'urètre par abord direct, chez la fille",
    tarifSecteur1Optam: "66.65",
    tarifHorsSecteur1HorsOptam: "56.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA017",
    designation:
      "Méatoplastie ou méatotomie urétrale sans lambeau, chez l'adulte",
    tarifSecteur1Optam: "56.01",
    tarifHorsSecteur1HorsOptam: "50.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA016",
    designation:
      "Méatoplastie de l'urètre avec lambeau préputial ou cutané, chez l'adulte",
    tarifSecteur1Optam: "127.49",
    tarifHorsSecteur1HorsOptam: "127.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA003",
    designation: "Retouche de cicatrice cutanée après urétroplastie",
    tarifSecteur1Optam: "88.63",
    tarifHorsSecteur1HorsOptam: "86.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JELD001",
    designation:
      "Injection sousmuqueuse périurétrocervicale de matériel hétérologue par voie transvaginale, avec guidage endoscopique",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JELE001",
    designation:
      "Injection sousmuqueuse intra-urétrale de matériel hétérologue, par endoscopie",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JESA003",
    designation: "Fermeture de fistule cutanée de l'urètre",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JESA005",
    designation:
      "Fermeture de fistule urétro-prostato-rectale, par abord anal transsphinctérien",
    tarifSecteur1Optam: "730.27",
    tarifHorsSecteur1HorsOptam: "528.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JESA004",
    designation:
      "Fermeture de fistule urétro-prostato-rectale, par laparotomie",
    tarifSecteur1Optam: "555.86",
    tarifHorsSecteur1HorsOptam: "427.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JESA001",
    designation:
      "Fermeture de fistule urétro-prostato-rectale, par abord périnéal",
    tarifSecteur1Optam: "776.36",
    tarifHorsSecteur1HorsOptam: "555.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDSA010",
    designation:
      "Fermeture du col de la vessie pour cystostomie cutanée continente",
    tarifSecteur1Optam: "325.34",
    tarifHorsSecteur1HorsOptam: "325.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JECA001",
    designation: "Urétrostomie cutanée",
    tarifSecteur1Optam: "180.09",
    tarifHorsSecteur1HorsOptam: "157.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEFA005",
    designation: "Exérèse d'un urètre surnuméraire",
    tarifSecteur1Optam: "268",
    tarifHorsSecteur1HorsOptam: "260.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA004",
    designation: "Urétroplastie pour méga-urètre congénital",
    tarifSecteur1Optam: "291.4",
    tarifHorsSecteur1HorsOptam: "274.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEAA001",
    designation: "Allongement intravésical de l'urètre",
    tarifSecteur1Optam: "379.32",
    tarifHorsSecteur1HorsOptam: "325.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA006",
    designation:
      "Urétroplastie pour hypospadias périnéoscrotal avec redressement du pénis",
    tarifSecteur1Optam: "572.87",
    tarifHorsSecteur1HorsOptam: "432.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA020",
    designation:
      "Urétroplastie pour hypospadias pénien postérieur ou moyen sans redressement du pénis",
    tarifSecteur1Optam: "425.4",
    tarifHorsSecteur1HorsOptam: "347.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA019",
    designation:
      "Urétroplastie pour hypospadias pénien postérieur ou moyen avec redressement du pénis",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "393.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA021",
    designation:
      "Urétroplastie pour hypospadias balanique ou pénien antérieur, sans reconstruction du prépuce",
    tarifSecteur1Optam: "225.46",
    tarifHorsSecteur1HorsOptam: "213.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JEMA014",
    designation:
      "Urétroplastie pour hypospadias balanique ou pénien antérieur, avec reconstruction du prépuce",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "238.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGNE003",
    designation:
      "Destruction d'une hypertrophie de la prostate par radiofréquence, par urétrocystoscopie avec guidage échographique",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "141.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGNE171",
    designation:
      "Destruction d’une hypertrophie de la prostate par laser [photovaporisation], par urétrocystoscopie",
    tarifSecteur1Optam: "333.94",
    tarifHorsSecteur1HorsOptam: "333.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGNJ900",
    designation:
      "Destruction de lésion de la prostate par ultrasons focalisés de haute intensité, par voie rectale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGND002",
    designation: "Cryothérapie de la prostate",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGNE403",
    designation:
      "Destruction d'une hypertrophie bénigne de la prostate par vapeur d'eau à convection, avec guidage par urétrocystoscopie",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "141.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGJB001",
    designation:
      "Évacuation de collection de la prostate, par voie transrectale ou transpérinéale avec guidage au doigt",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGFA016",
    designation:
      "Résection ou marsupialisation de collection de la prostate ou de diverticule de l'urètre, par urétrocystoscopie",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "141.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFE004",
    designation:
      "Résection et/ou désobstruction des conduits éjaculateurs, par urétrocystoscopie",
    tarifSecteur1Optam: "133.29",
    tarifHorsSecteur1HorsOptam: "117.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFE023",
    designation:
      "Résection d'une hypertrophie de la prostate sans laser, par urétrocystoscopie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFE365",
    designation:
      "Résection d'une hypertrophie de la prostate avec laser, par urétrocystoscopie",
    tarifSecteur1Optam: "370.1",
    tarifHorsSecteur1HorsOptam: "370.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA014",
    designation:
      "Résection palliative de la prostate [Recalibrage de l'urètre], par urétrocystoscopie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA005",
    designation: "Adénomectomie transvésicale de la prostate, par laparotomie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA009",
    designation:
      "Adénomectomie rétropubienne ou transcapsulaire de la prostate, par laparotomie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA007",
    designation:
      "Exérèse rétrovésicale ou transvésicale de l'utricule de la prostate, par laparotomie",
    tarifSecteur1Optam: "402",
    tarifHorsSecteur1HorsOptam: "351.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFC045",
    designation: "Exérèse des vésicules séminales, par cœlioscopie",
    tarifSecteur1Optam: "472.63",
    tarifHorsSecteur1HorsOptam: "391.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA008",
    designation: "Exérèse des vésicules séminales, par laparotomie",
    tarifSecteur1Optam: "443.83",
    tarifHorsSecteur1HorsOptam: "362.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFC001",
    designation: "Vésiculoprostatectomie totale, par cœlioscopie",
    tarifSecteur1Optam: "957.15",
    tarifHorsSecteur1HorsOptam: "777.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA006",
    designation: "Vésiculoprostatectomie totale, par laparotomie",
    tarifSecteur1Optam: "832.37",
    tarifHorsSecteur1HorsOptam: "692.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGFA011",
    designation: "Vésiculoprostatectomie totale, par abord périnéal",
    tarifSecteur1Optam: "829.53",
    tarifHorsSecteur1HorsOptam: "691.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA024",
    designation: "Vésiculo-prostato-cystectomie totale, par laparotomie",
    tarifSecteur1Optam: "886.96",
    tarifHorsSecteur1HorsOptam: "829.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA023",
    designation:
      "Vésiculo-prostato-cystectomie totale avec urétérostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "921.7",
    tarifHorsSecteur1HorsOptam: "832.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA025",
    designation:
      "Vésiculo-prostato-cystectomie totale avec urétérostomie cutanée transintestinale par anse non détubulée, par laparotomie",
    tarifSecteur1Optam: "1120.22",
    tarifHorsSecteur1HorsOptam: "986.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA020",
    designation:
      "Vésiculo-prostato-cystectomie totale avec urétérostomie cutanée transintestinale par anse détubulée continente, par laparotomie",
    tarifSecteur1Optam: "1308.81",
    tarifHorsSecteur1HorsOptam: "1096.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA019",
    designation:
      "Vésiculo-prostato-cystectomie totale avec anastomose urétérocolique directe, par laparotomie",
    tarifSecteur1Optam: "871.81",
    tarifHorsSecteur1HorsOptam: "871.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA022",
    designation:
      "Vésiculo-prostato-cystectomie totale avec anastomose urétérocolique et confection d'un réservoir détubulé rectosigmoïdien ou iléo-recto-sigmoïdien, par laparotomie",
    tarifSecteur1Optam: "1292.51",
    tarifHorsSecteur1HorsOptam: "1130.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JDFA021",
    designation:
      "Vésiculo-prostato-cystectomie totale avec entérocystoplastie de remplacement [néovessie] orthotopique par anse détubulée, par laparotomie",
    tarifSecteur1Optam: "1608.01",
    tarifHorsSecteur1HorsOptam: "1331.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JGLJ060",
    designation:
      "Injection de produit espaceur, par voie transpérinéale avec guidage échographique",
    tarifSecteur1Optam: "89.33",
    tarifHorsSecteur1HorsOptam: "89.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHFA012",
    designation: "Exérèse de lésion du testicule, par abord scrotal",
    tarifSecteur1Optam: "95.72",
    tarifHorsSecteur1HorsOptam: "90.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA010",
    designation:
      "Pulpectomie testiculaire unilatérale ou bilatérale, par abord scrotal",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA017",
    designation: "Orchidectomie, par abord scrotal",
    tarifSecteur1Optam: "92.88",
    tarifHorsSecteur1HorsOptam: "88.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA005",
    designation: "Orchidectomie, par abord inguinal",
    tarifSecteur1Optam: "99.26",
    tarifHorsSecteur1HorsOptam: "92.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA006",
    designation:
      "Orchidectomie avec pose de prothèse testiculaire, par abord inguinal",
    tarifSecteur1Optam: "123.37",
    tarifHorsSecteur1HorsOptam: "106.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA008",
    designation:
      "Orchidectomie élargie au cordon spermatique [Orchiépididymectomie], par abord inguinal",
    tarifSecteur1Optam: "134.71",
    tarifHorsSecteur1HorsOptam: "130.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA001",
    designation:
      "Exérèse d'un kyste du cordon spermatique chez l'adulte, par abord inguinal",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA004",
    designation: "Exérèse d'un kyste de l’épididyme, par abord scrotal",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA019",
    designation:
      "Résection d'une hydrocèle abdominoscrotale, par laparotomie ou par abord inguinal",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "145.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA013",
    designation:
      "Résection de la tunique vaginale du testicule, par abord scrotal",
    tarifSecteur1Optam: "90.75",
    tarifHorsSecteur1HorsOptam: "87.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA014",
    designation: "Épididymectomie, par abord scrotal",
    tarifSecteur1Optam: "94.3",
    tarifHorsSecteur1HorsOptam: "89.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHLA005",
    designation: "Pose d'une prothèse de testicule, par abord inguinal",
    tarifSecteur1Optam: "84.37",
    tarifHorsSecteur1HorsOptam: "84.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHGA002",
    designation: "Ablation d'une prothèse de testicule",
    tarifSecteur1Optam: "54.59",
    tarifHorsSecteur1HorsOptam: "47.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHKA002",
    designation: "Changement d'une prothèse de testicule, par abord inguinal",
    tarifSecteur1Optam: "109.9",
    tarifHorsSecteur1HorsOptam: "98.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHCA006",
    designation: "Suture de rupture du testicule, par abord scrotal",
    tarifSecteur1Optam: "87.92",
    tarifHorsSecteur1HorsOptam: "86.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHCA005",
    designation: "Anastomose épididymodéférentielle, par abord scrotal",
    tarifSecteur1Optam: "350.96",
    tarifHorsSecteur1HorsOptam: "291.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHCA002",
    designation: "Anastomose déférentodéférentielle, par abord scrotal",
    tarifSecteur1Optam: "319.76",
    tarifHorsSecteur1HorsOptam: "273.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEP002",
    designation: "Détorsion manuelle du cordon spermatique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHEA009",
    designation:
      "Détorsion du cordon spermatique ou exérèse des annexes du testicule, par abord direct",
    tarifSecteur1Optam: "87.21",
    tarifHorsSecteur1HorsOptam: "85.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA010",
    designation:
      "Détorsion du cordon spermatique avec orchidopexie unilatérale ou bilatérale, par abord direct",
    tarifSecteur1Optam: "156.75",
    tarifHorsSecteur1HorsOptam: "156.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHDA001",
    designation: "Orchidopexie, par abord scrotal",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA012",
    designation: "Autotransplantation du testicule",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "206.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHSB001",
    designation:
      "Ligature, section ou résection unilatérale ou bilatérale du conduit déférent, par voie transcutanée",
    tarifSecteur1Optam: "65.23",
    tarifHorsSecteur1HorsOptam: "59.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHSA001",
    designation:
      "Ligature, section ou résection unilatérale ou bilatérale du conduit déférent, par abord scrotal",
    tarifSecteur1Optam: "60.27",
    tarifHorsSecteur1HorsOptam: "56.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHJB003",
    designation:
      "Évacuation d'hydrocèle sans injection de produit sclérosant, par voie transcutanée",
    tarifSecteur1Optam: "21.27",
    tarifHorsSecteur1HorsOptam: "20.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHJB001",
    designation:
      "Évacuation d'hydrocèle avec injection de produit sclérosant, par voie transcutanée",
    tarifSecteur1Optam: "38.29",
    tarifHorsSecteur1HorsOptam: "32.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHBA001",
    designation:
      "Plicature de la tunique vaginale du testicule, par abord scrotal",
    tarifSecteur1Optam: "86.5",
    tarifHorsSecteur1HorsOptam: "85.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA006",
    designation: "Confection d'une spermatocèle",
    tarifSecteur1Optam: "243.9",
    tarifHorsSecteur1HorsOptam: "229.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA002",
    designation: "Résection plastique ou exérèse du scrotum",
    tarifSecteur1Optam: "70.9",
    tarifHorsSecteur1HorsOptam: "58.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA005",
    designation: "Plastie du scrotum pour éléphantiasis",
    tarifSecteur1Optam: "175.12",
    tarifHorsSecteur1HorsOptam: "155.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHJA001",
    designation:
      "Évacuation ou mise à plat d'un abcès du scrotum, par abord direct",
    tarifSecteur1Optam: "47.5",
    tarifHorsSecteur1HorsOptam: "45.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFC001",
    designation: "Orchidectomie pour cryptorchidie abdominale, par cœlioscopie",
    tarifSecteur1Optam: "163.07",
    tarifHorsSecteur1HorsOptam: "142.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA003",
    designation: "Orchidectomie pour cryptorchidie abdominale, par laparotomie",
    tarifSecteur1Optam: "158.82",
    tarifHorsSecteur1HorsOptam: "127.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA004",
    designation:
      "Abaissement et fixation d'un testicule ectopique non palpable, par cœlioscopie et par abord inguinoscrotal",
    tarifSecteur1Optam: "216.95",
    tarifHorsSecteur1HorsOptam: "210.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA001",
    designation:
      "Abaissement et fixation d'un testicule ectopique non palpable, par abord inguinal et par abord scrotal",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "227.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA002",
    designation:
      "Abaissement et fixation d'un testicule ectopique palpable, par abord inguinal et par abord scrotal",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEP001",
    designation: "Réduction manuelle d'un paraphimosis",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHEA003",
    designation: "Réduction chirurgicale d'un paraphimosis",
    tarifSecteur1Optam: "53.88",
    tarifHorsSecteur1HorsOptam: "44.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHPA003",
    designation: "Libération d'adhérences du prépuce du pénis",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA009",
    designation: "Posthectomie",
    tarifSecteur1Optam: "75.15",
    tarifHorsSecteur1HorsOptam: "69.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHPA001",
    designation: "Section ou plastie du frein du prépuce du pénis",
    tarifSecteur1Optam: "46.09",
    tarifHorsSecteur1HorsOptam: "35.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA001",
    designation: "Plastie du prépuce du pénis [Posthoplastie]",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA003",
    designation: "Reconstruction du prépuce du pénis",
    tarifSecteur1Optam: "104.22",
    tarifHorsSecteur1HorsOptam: "95.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA002",
    designation: "Reconstruction de la peau du pénis par greffe cutanée",
    tarifSecteur1Optam: "214.83",
    tarifHorsSecteur1HorsOptam: "150.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHNP001",
    designation: "Destruction de lésion du gland et/ou du prépuce du pénis",
    tarifSecteur1Optam: "39",
    tarifHorsSecteur1HorsOptam: "34.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHFA007",
    designation: "Exérèse de lésion des corps caverneux du pénis",
    tarifSecteur1Optam: "165.91",
    tarifHorsSecteur1HorsOptam: "148.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA015",
    designation:
      "Exérèse de lésion de la peau du pénis, du gland et/ou du sillon balanopréputial, sans anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "49.63",
    tarifHorsSecteur1HorsOptam: "36.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA018",
    designation:
      "Exérèse de lésion de la peau du pénis, du gland et/ou du sillon balanopréputial, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "77.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA016",
    designation: "Amputation partielle du pénis",
    tarifSecteur1Optam: "211.99",
    tarifHorsSecteur1HorsOptam: "175.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFA011",
    designation: "Amputation totale du pénis",
    tarifSecteur1Optam: "399.17",
    tarifHorsSecteur1HorsOptam: "336.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHLB001",
    designation:
      "Injection thérapeutique d'agent pharmacologique vasoactif dans les corps caverneux du pénis, par voie transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHLB002",
    designation:
      "Infiltration de plaque de sclérose de l'albuginée des corps caverneux du pénis, par voie transcutanée",
    tarifSecteur1Optam: "32.61",
    tarifHorsSecteur1HorsOptam: "22.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHJB002",
    designation: "Lavage des corps caverneux du pénis, par voie transcutanée",
    tarifSecteur1Optam: "97.13",
    tarifHorsSecteur1HorsOptam: "72.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHCA004",
    designation: "Suture de rupture des corps caverneux du pénis",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHSA002",
    designation: "Fermeture de fistule spongiocaverneuse du pénis",
    tarifSecteur1Optam: "221.92",
    tarifHorsSecteur1HorsOptam: "172.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA005",
    designation: "Réimplantation du pénis",
    tarifSecteur1Optam: "921.7",
    tarifHorsSecteur1HorsOptam: "675.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMB001",
    designation: "Création de fistule spongiocaverneuse, par voie transcutanée",
    tarifSecteur1Optam: "75.15",
    tarifHorsSecteur1HorsOptam: "61.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JHCA001",
    designation: "Anastomose spongiocaverneuse, par abord direct",
    tarifSecteur1Optam: "186.47",
    tarifHorsSecteur1HorsOptam: "152.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHLA003",
    designation: "Pose d'une prothèse pénienne rigide ou semirigide",
    tarifSecteur1Optam: "238.22",
    tarifHorsSecteur1HorsOptam: "225.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHLA002",
    designation:
      "Pose d'une prothèse pénienne hydraulique sans composant extracaverneux",
    tarifSecteur1Optam: "299.2",
    tarifHorsSecteur1HorsOptam: "261.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHLA004",
    designation:
      "Pose d'une prothèse pénienne hydraulique avec composant extracaverneux",
    tarifSecteur1Optam: "299.2",
    tarifHorsSecteur1HorsOptam: "261.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHGA003",
    designation:
      "Ablation d'une prothèse pénienne sans composant extracaverneux",
    tarifSecteur1Optam: "100.68",
    tarifHorsSecteur1HorsOptam: "93.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHGA001",
    designation:
      "Ablation d'une prothèse pénienne hydraulique avec composant extracaverneux",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHKA003",
    designation:
      "Changement d'une prothèse pénienne hydraulique sans composant extracaverneux",
    tarifSecteur1Optam: "235.41",
    tarifHorsSecteur1HorsOptam: "235.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHKA004",
    designation:
      "Changement d'une prothèse pénienne hydraulique avec composant extracaverneux",
    tarifSecteur1Optam: "254.53",
    tarifHorsSecteur1HorsOptam: "235.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHPA002",
    designation: "Désenfouissement du pénis par fixation des fascias",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "200.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA011",
    designation: "Dérotation chirurgicale du pénis",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHAA003",
    designation: "Élargissement du pénis",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHAA005",
    designation: "Allongement du pénis par plastie des corps caverneux",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHAA004",
    designation: "Allongement du pénis par section du ligament suspenseur",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHAA002",
    designation: "Allongement du pénis sans urétroplastie, pour épispadias",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "293.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHAA001",
    designation: "Allongement du pénis avec urétroplastie, pour épispadias",
    tarifSecteur1Optam: "632.43",
    tarifHorsSecteur1HorsOptam: "467.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA006",
    designation:
      "Redressement du pénis par dissection ventrale et plicature dorsale",
    tarifSecteur1Optam: "303.45",
    tarifHorsSecteur1HorsOptam: "263.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA007",
    designation:
      "Redressement du pénis par plicature-excision des corps caverneux",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "211.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHEA008",
    designation:
      "Redressement du pénis avec excision de plaque de sclérose de l'albuginée des corps caverneux et greffe",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "241.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJJC001",
    designation:
      "Évacuation de collection de l'annexe ou du ligament large, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJJA001",
    designation:
      "Évacuation de collection de l'annexe ou du ligament large, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPE001",
    designation:
      "Interruption unilatérale ou bilatérale de la perméabilité des trompes utérines par insertion de dispositif intratubaire, par hystéroscopie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPC004",
    designation:
      "Interruption unilatérale ou bilatérale de la perméabilité des trompes utérines, par pélycoscopie [culdoscopie] [cœlioscopie transvaginale]",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPC003",
    designation:
      "Interruption unilatérale ou bilatérale de la perméabilité des trompes utérines, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPA004",
    designation:
      "Interruption unilatérale ou bilatérale de la perméabilité des trompes utérines, par abord vaginal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJPA003",
    designation:
      "Interruption unilatérale ou bilatérale de la perméabilité des trompes utérines, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC003",
    designation: "Kystectomie ovarienne intrapéritonéale, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA010",
    designation:
      "Kystectomie ovarienne transpariétale, par laparotomie avec préparation par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA008",
    designation: "Kystectomie ovarienne, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC004",
    designation: "Résection partielle de l'ovaire, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA002",
    designation: "Résection partielle de l'ovaire, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC008",
    designation: "Ovariectomie unilatérale, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA003",
    designation: "Ovariectomie unilatérale, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC009",
    designation: "Ovariectomie bilatérale, par cœlioscopie",
    tarifSecteur1Optam: "230.44",
    tarifHorsSecteur1HorsOptam: "215.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA005",
    designation: "Ovariectomie bilatérale, par laparotomie",
    tarifSecteur1Optam: "176.46",
    tarifHorsSecteur1HorsOptam: "172.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC006",
    designation: "Salpingectomie totale, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA007",
    designation: "Salpingectomie totale, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC010",
    designation: "Salpingoovariectomie [Annexectomie], par cœlioscopie",
    tarifSecteur1Optam: "209.68",
    tarifHorsSecteur1HorsOptam: "191.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA050",
    designation: "Salpingoovariectomie [Annexectomie], par abord vaginal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA004",
    designation: "Salpingoovariectomie [Annexectomie], par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCC001",
    designation: "Salpingonéostomie ou fimbrioplastie, par cœlioscopie",
    tarifSecteur1Optam: "237.8",
    tarifHorsSecteur1HorsOptam: "237.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCA001",
    designation: "Salpingonéostomie ou fimbrioplastie, par laparotomie",
    tarifSecteur1Optam: "214.52",
    tarifHorsSecteur1HorsOptam: "212.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCC004",
    designation:
      "Anastomose tubotubaire unilatérale ou bilatérale, par cœlioscopie",
    tarifSecteur1Optam: "389.95",
    tarifHorsSecteur1HorsOptam: "389.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCA003",
    designation:
      "Anastomose tubotubaire unilatérale ou bilatérale, par laparotomie",
    tarifSecteur1Optam: "342.26",
    tarifHorsSecteur1HorsOptam: "342.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJEC003",
    designation: "Transposition unilatérale de l'ovaire, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJEA003",
    designation: "Transposition unilatérale de l'ovaire, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJEC002",
    designation: "Transposition bilatérale de l'ovaire, par cœlioscopie",
    tarifSecteur1Optam: "208.29",
    tarifHorsSecteur1HorsOptam: "203.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJEA002",
    designation: "Transposition bilatérale de l'ovaire, par laparotomie",
    tarifSecteur1Optam: "199.99",
    tarifHorsSecteur1HorsOptam: "186.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJEC001",
    designation: "Détorsion de l'annexe, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJEA001",
    designation: "Détorsion de l'annexe, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCC003",
    designation:
      "Microperforations unilatérales ou bilatérales de l'ovaire, par cœlioscopie",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "141.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCC002",
    designation:
      "Microperforations unilatérales ou bilatérales de l'ovaire, par pélycoscopie [culdoscopie] [cœlioscopie transvaginale]",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "141.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJLJ002",
    designation:
      "Cathétérisme rétrograde unilatéral ou bilatéral de la trompe utérine, par voie vaginale avec guidage échographique",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJLE001",
    designation:
      "Cathétérisme rétrograde unilatéral ou bilatéral de la trompe utérine, par hystéroscopie",
    tarifSecteur1Optam: "152.24",
    tarifHorsSecteur1HorsOptam: "114.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJCA002",
    designation: "Suture de déchirure du ligament large, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC002",
    designation:
      "Exérèse de reliquat embryonnaire du ligament large, par cœlioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFA006",
    designation:
      "Exérèse de reliquat embryonnaire du ligament large, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKND001",
    designation:
      "Destruction de la muqueuse utérine par thermocontact, par voie vaginale",
    tarifSecteur1Optam: "89.27",
    tarifHorsSecteur1HorsOptam: "78.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNE001",
    designation:
      "Abrasion de la muqueuse de l'utérus [Endométrectomie], par hystéroscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKGD002",
    designation: "Curetage de la cavité de l'utérus à visée thérapeutique",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFE001",
    designation: "Exérèse de polype de l'utérus, par hystéroscopie",
    tarifSecteur1Optam: "110.72",
    tarifHorsSecteur1HorsOptam: "90.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFE002",
    designation: "Résection de myome de l'utérus, par hystéroscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFC004",
    designation: "Myomectomie de l'utérus sans hystérotomie, par cœlioscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFC001",
    designation: "Myomectomie de l'utérus avec hystérotomie, par cœlioscopie",
    tarifSecteur1Optam: "235.97",
    tarifHorsSecteur1HorsOptam: "224.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA033",
    designation: "Myomectomie de l'utérus, par abord vaginal",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA016",
    designation: "Myomectomie unique de l'utérus, par laparotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA022",
    designation: "Myomectomie multiple de l'utérus, par laparotomie",
    tarifSecteur1Optam: "226.28",
    tarifHorsSecteur1HorsOptam: "219.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFD001",
    designation:
      "Exérèse de lésion pédiculée de l'utérus accouchée par le col, par voie vaginale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKND004",
    designation:
      "Destruction de lésion du col de l'utérus par colposcopie, sans laser",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKND003",
    designation:
      "Destruction de lésion du col de l'utérus par colposcopie, avec laser",
    tarifSecteur1Optam: "51.21",
    tarifHorsSecteur1HorsOptam: "47.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKND002",
    designation:
      "Destruction de lésion du col de l'utérus, du vagin, de la vulve, du périnée et de la région périanale, avec laser",
    tarifSecteur1Optam: "77.5",
    tarifHorsSecteur1HorsOptam: "62.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKFD002",
    designation: "Exérèse de lésion du col de l'utérus, par voie vaginale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKFE003",
    designation: "Exérèse de lésion du col de l'utérus, par colposcopie",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKFA031",
    designation: "Conisation du col de l'utérus",
    tarifSecteur1Optam: "93.42",
    tarifHorsSecteur1HorsOptam: "89.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA008",
    designation:
      "Trachélectomie [Cervicectomie] [Amputation du col de l'utérus], par abord vaginal",
    tarifSecteur1Optam: "110.03",
    tarifHorsSecteur1HorsOptam: "98.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA011",
    designation:
      "Trachélectomie sur col restant de l'utérus, par abord vaginal",
    tarifSecteur1Optam: "137.71",
    tarifHorsSecteur1HorsOptam: "114.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA009",
    designation: "Trachélectomie sur col restant de l'utérus, par laparotomie",
    tarifSecteur1Optam: "229.74",
    tarifHorsSecteur1HorsOptam: "203.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA030",
    designation:
      "Colpotrachélectomie [Colpocervicectomie] élargie aux paramètres, par abord vaginal",
    tarifSecteur1Optam: "458.8",
    tarifHorsSecteur1HorsOptam: "423.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA019",
    designation:
      "Colpotrachélectomie [Colpocervicectomie] élargie aux paramètres, par laparotomie",
    tarifSecteur1Optam: "458.8",
    tarifHorsSecteur1HorsOptam: "423.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA003",
    designation:
      "Exérèse d'un hémiutérus malformé [Hémihystérectomie], par laparotomie",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFC002",
    designation: "Hystérectomie subtotale, par cœlioscopie",
    tarifSecteur1Optam: "253.96",
    tarifHorsSecteur1HorsOptam: "247.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA024",
    designation: "Hystérectomie subtotale, par laparotomie",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA014",
    designation:
      "Hystérectomie subtotale avec suspension postérieure du col de l'utérus [colposuspension], par laparotomie",
    tarifSecteur1Optam: "385.79",
    tarifHorsSecteur1HorsOptam: "385.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA012",
    designation:
      "Hystérectomie subtotale avec suspension postérieure du col de l'utérus [colposuspension] et cervicocystopexie indirecte au ligament pectinéal [de Cooper], par laparotomie",
    tarifSecteur1Optam: "385.79",
    tarifHorsSecteur1HorsOptam: "385.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFC006",
    designation:
      "Hystérectomie subtotale avec annexectomie unilatérale ou bilatérale, par cœlioscopie",
    tarifSecteur1Optam: "323.09",
    tarifHorsSecteur1HorsOptam: "323.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA032",
    designation:
      "Hystérectomie subtotale avec annexectomie unilatérale ou bilatérale, par laparotomie",
    tarifSecteur1Optam: "323.09",
    tarifHorsSecteur1HorsOptam: "323.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA001",
    designation:
      "Hystérectomie subtotale avec annexectomie unilatérale ou bilatérale et suspension postérieure du col de l'utérus [colposuspension], par laparotomie",
    tarifSecteur1Optam: "323.09",
    tarifHorsSecteur1HorsOptam: "323.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA029",
    designation:
      "Hystérectomie subtotale avec annexectomie unilatérale ou bilatérale, suspension postérieure du col de l'utérus [colposuspension] et cervicocystopexie indirecte au ligament pectinéal [de Cooper], par laparotomie",
    tarifSecteur1Optam: "388.9",
    tarifHorsSecteur1HorsOptam: "361.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFC005",
    designation: "Hystérectomie totale, par cœlioscopie",
    tarifSecteur1Optam: "328.01",
    tarifHorsSecteur1HorsOptam: "290.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA018",
    designation: "Hystérectomie totale, par cœlioscopie et par abord vaginal",
    tarifSecteur1Optam: "290.64",
    tarifHorsSecteur1HorsOptam: "285.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA026",
    designation: "Hystérectomie totale, par abord vaginal",
    tarifSecteur1Optam: "264.34",
    tarifHorsSecteur1HorsOptam: "253.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA015",
    designation: "Hystérectomie totale, par laparotomie",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA025",
    designation:
      "Hystérectomie totale avec colpopérinéorraphie antérieure ou postérieure, par abord vaginal",
    tarifSecteur1Optam: "344.62",
    tarifHorsSecteur1HorsOptam: "318.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA002",
    designation:
      "Hystérectomie totale avec colpopérinéorraphies antérieure et postérieure, par abord vaginal",
    tarifSecteur1Optam: "337.7",
    tarifHorsSecteur1HorsOptam: "331.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA013",
    designation:
      "Hystérectomie totale avec suspension postérieure du dôme du vagin, par laparotomie",
    tarifSecteur1Optam: "385.79",
    tarifHorsSecteur1HorsOptam: "385.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFC003",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale, par cœlioscopie",
    tarifSecteur1Optam: "339.08",
    tarifHorsSecteur1HorsOptam: "332.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA006",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale, par cœlioscopie et par abord vaginal",
    tarifSecteur1Optam: "328.7",
    tarifHorsSecteur1HorsOptam: "326.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA005",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale, par abord vaginal",
    tarifSecteur1Optam: "337",
    tarifHorsSecteur1HorsOptam: "331.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA028",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale, par laparotomie",
    tarifSecteur1Optam: "323.09",
    tarifHorsSecteur1HorsOptam: "323.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA021",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale et colpopérinéorraphie antérieure ou postérieure, par abord vaginal",
    tarifSecteur1Optam: "406.9",
    tarifHorsSecteur1HorsOptam: "371.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA007",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale et colpopérinéorraphies antérieure et postérieure, par abord vaginal",
    tarifSecteur1Optam: "429.04",
    tarifHorsSecteur1HorsOptam: "384.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA004",
    designation:
      "Hystérectomie totale avec annexectomie unilatérale ou bilatérale et suspension postérieure du dôme du vagin, par laparotomie",
    tarifSecteur1Optam: "362.61",
    tarifHorsSecteur1HorsOptam: "346.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA020",
    designation:
      "Colpohystérectomie totale élargie aux paramètres, par cœlioscopie et par abord vaginal",
    tarifSecteur1Optam: "484.4",
    tarifHorsSecteur1HorsOptam: "438.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA023",
    designation:
      "Colpohystérectomie totale élargie aux paramètres, par abord vaginal",
    tarifSecteur1Optam: "458.8",
    tarifHorsSecteur1HorsOptam: "423.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKFA027",
    designation:
      "Colpohystérectomie totale élargie aux paramètres, par laparotomie",
    tarifSecteur1Optam: "457.41",
    tarifHorsSecteur1HorsOptam: "422.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPJ002",
    designation:
      "Section ou résection de cloison utérine, par voie vaginale avec guidage échographique",
    tarifSecteur1Optam: "65.05",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPE001",
    designation: "Section ou résection de cloison utérine, par hystéroscopie",
    tarifSecteur1Optam: "150.16",
    tarifHorsSecteur1HorsOptam: "113.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPD002",
    designation:
      "Section et/ou destruction de synéchies de l'utérus, par voie vaginale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPE002",
    designation:
      "Section et/ou destruction de synéchies de l'utérus, par hystéroscopie",
    tarifSecteur1Optam: "114.87",
    tarifHorsSecteur1HorsOptam: "92.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPJ001",
    designation:
      "Section et/ou destruction de synéchies de l'utérus, par hystéroscopie avec guidage échographique",
    tarifSecteur1Optam: "114.87",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPC001",
    designation:
      "Section et/ou destruction de synéchies de l'utérus, par hystéroscopie avec guidage cœlioscopique",
    tarifSecteur1Optam: "172.31",
    tarifHorsSecteur1HorsOptam: "138.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKCA001",
    designation: "Hystérorraphie, par laparotomie",
    tarifSecteur1Optam: "168.16",
    tarifHorsSecteur1HorsOptam: "167.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKMA001",
    designation: "Hystéroplastie, par laparotomie",
    tarifSecteur1Optam: "222.13",
    tarifHorsSecteur1HorsOptam: "199.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKCD001",
    designation: "Suture de plaie du col de l'utérus",
    tarifSecteur1Optam: "83.04",
    tarifHorsSecteur1HorsOptam: "74.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKPD001",
    designation:
      "Libération d'une sténose récente et totale du col de l'utérus",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKMD001",
    designation: "Stomatoplastie du col de l'utérus",
    tarifSecteur1Optam: "71.97",
    tarifHorsSecteur1HorsOptam: "68.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKBA001",
    designation:
      "Cerclage de l'isthme utérin par abord vaginal, en dehors de la grossesse",
    tarifSecteur1Optam: "125.94",
    tarifHorsSecteur1HorsOptam: "99.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKBA002",
    designation:
      "Cerclage de l'isthme utérin par laparotomie, en dehors de la grossesse",
    tarifSecteur1Optam: "179.92",
    tarifHorsSecteur1HorsOptam: "174.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKDC015",
    designation: "Hystéropexie antérieure, par coelioscopie",
    tarifSecteur1Optam: "196",
    tarifHorsSecteur1HorsOptam: "196",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKDA042",
    designation: "Hystéropexie antérieure, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKDC001",
    designation: "Hystéropexie postérieure [Promontofixation], par cœlioscopie",
    tarifSecteur1Optam: "346.69",
    tarifHorsSecteur1HorsOptam: "331.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKDA003",
    designation: "Hystéropexie postérieure [Promontofixation], par laparotomie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKDA002",
    designation:
      "Hystéropexie postérieure [Promontofixation] avec cervicocystopexie indirecte au ligament pectinéal [de Cooper], par laparotomie",
    tarifSecteur1Optam: "366.76",
    tarifHorsSecteur1HorsOptam: "365.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKDA001",
    designation:
      "Hystéropexie postérieure [Promontofixation] avec cervicocystopexie directe au ligament pectinéal [de Cooper], par laparotomie",
    tarifSecteur1Optam: "366.76",
    tarifHorsSecteur1HorsOptam: "365.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKLD001",
    designation: "Pose d'un dispositif intra-utérin",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKGD004",
    designation: "Ablation d'un dispositif intra-utérin, par voie vaginale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKGD001",
    designation:
      "Ablation d'un dispositif intra-utérin par un matériel intra-utérin de préhension, par voie vaginale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKGE001",
    designation: "Ablation d'un dispositif intra-utérin, par hystéroscopie",
    tarifSecteur1Optam: "69.2",
    tarifHorsSecteur1HorsOptam: "66.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKGC001",
    designation: "Ablation d'un dispositif intra-utérin, par cœlioscopie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKGA001",
    designation: "Ablation d'un dispositif intra-utérin, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JKKD001",
    designation: "Changement d'un dispositif intra-utérin",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKGE002",
    designation: "Ablation de corps étranger de l'utérus, par hystéroscopie",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLJB001",
    designation:
      "Évacuation de collection de la paroi du vagin ou de la vulve, par voie transvaginale ou transcutanée",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLJA002",
    designation:
      "Évacuation de collection paravaginale, par colpotomie latérale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLJD001",
    designation: "Drainage d'un hématocolpos ou d'un hydrocolpos",
    tarifSecteur1Optam: "132.17",
    tarifHorsSecteur1HorsOptam: "103",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLND001",
    designation: "Destruction de lésion du vagin, sans laser",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLND002",
    designation: "Destruction de lésion du vagin, avec laser",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLFA002",
    designation: "Exérèse de lésion du vagin",
    tarifSecteur1Optam: "69.2",
    tarifHorsSecteur1HorsOptam: "66.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLFA004",
    designation: "Colpectomie subtotale ou totale, par abord vaginal",
    tarifSecteur1Optam: "238.05",
    tarifHorsSecteur1HorsOptam: "203.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLFA003",
    designation: "Colpectomie subtotale ou totale, par laparotomie",
    tarifSecteur1Optam: "458.8",
    tarifHorsSecteur1HorsOptam: "336.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLDC015",
    designation:
      "Suspension du dôme du vagin [Promontofixation du dôme vaginal], par cœlioscopie",
    tarifSecteur1Optam: "310.09",
    tarifHorsSecteur1HorsOptam: "310.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLDA002",
    designation:
      "Suspension du dôme du vagin [Promontofixation du dôme vaginal], par abord vaginal",
    tarifSecteur1Optam: "270.57",
    tarifHorsSecteur1HorsOptam: "239.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLDA001",
    designation:
      "Suspension du dôme du vagin [Promontofixation du dôme vaginal], par laparotomie",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLDA004",
    designation:
      "Suspension du dôme du vagin [Promontofixation du dôme vaginal], avec cervicocystopexie indirecte au ligament pectinéal [de Cooper], par laparotomie",
    tarifSecteur1Optam: "364.89",
    tarifHorsSecteur1HorsOptam: "364.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLDA003",
    designation:
      "Suspension du dôme du vagin [Promontofixation du dôme vaginal], avec cervicocystopexie directe par bandelette infra-urétrale, par laparotomie",
    tarifSecteur1Optam: "364.89",
    tarifHorsSecteur1HorsOptam: "364.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA004",
    designation: "Colpopérinéorraphie postérieure",
    tarifSecteur1Optam: "164.7",
    tarifHorsSecteur1HorsOptam: "130.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA009",
    designation:
      "Colpopérinéorraphie postérieure, avec résection d'élytrocèle par abord vaginal",
    tarifSecteur1Optam: "202.06",
    tarifHorsSecteur1HorsOptam: "200.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA002",
    designation:
      "Colpopérinéorraphie postérieure, avec réfection du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA001",
    designation:
      "Colpopérinéorraphie postérieure, avec réfection du canal et du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "264.34",
    tarifHorsSecteur1HorsOptam: "258.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA007",
    designation: "Colpopérinéorraphie antérieure",
    tarifSecteur1Optam: "184.76",
    tarifHorsSecteur1HorsOptam: "142.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA005",
    designation: "Colpopérinéorraphie antérieure et postérieure",
    tarifSecteur1Optam: "229.74",
    tarifHorsSecteur1HorsOptam: "216.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA003",
    designation:
      "Colpopérinéorraphie antérieure et postérieure avec trachélectomie",
    tarifSecteur1Optam: "274.03",
    tarifHorsSecteur1HorsOptam: "246.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLFD001",
    designation: "Hyménectomie ou hyménotomie",
    tarifSecteur1Optam: "65.05",
    tarifHorsSecteur1HorsOptam: "50.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLPA001",
    designation: "Incisions radiées de diaphragme du vagin",
    tarifSecteur1Optam: "84.42",
    tarifHorsSecteur1HorsOptam: "75.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLFA001",
    designation: "Excision de diaphragme du vagin",
    tarifSecteur1Optam: "141.86",
    tarifHorsSecteur1HorsOptam: "108.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLFD002",
    designation: "Résection de cloison longitudinale du vagin",
    tarifSecteur1Optam: "83.73",
    tarifHorsSecteur1HorsOptam: "74.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMC001",
    designation:
      "Création d'un néovagin par pose de dispositif de traction progressive, par cœlioscopie",
    tarifSecteur1Optam: "183.38",
    tarifHorsSecteur1HorsOptam: "176.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMA005",
    designation:
      "Création d'un néovagin par pose de dispositif de traction progressive, par laparotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMA002",
    designation:
      "Création d'un néovagin par clivage intervésicorectal, par abord périnéal",
    tarifSecteur1Optam: "346",
    tarifHorsSecteur1HorsOptam: "270.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMA003",
    designation:
      "Création d'un néovagin par clivage intervésicorectal, par cœlioscopie et par abord périnéal",
    tarifSecteur1Optam: "232.45",
    tarifHorsSecteur1HorsOptam: "232.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMA001",
    designation:
      "Création d'un néovagin par clivage intervésicorectal, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "216.32",
    tarifHorsSecteur1HorsOptam: "216.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMA004",
    designation:
      "Création d'un néovagin par transplantation intestinale, par laparotomie",
    tarifSecteur1Optam: "455.97",
    tarifHorsSecteur1HorsOptam: "455.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA006",
    designation:
      "Anastomose utérovaginale ou vaginovaginale, pour malformation utérovaginale",
    tarifSecteur1Optam: "259.5",
    tarifHorsSecteur1HorsOptam: "238.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLCA008",
    designation: "Suture de plaie du vagin",
    tarifSecteur1Optam: "69.2",
    tarifHorsSecteur1HorsOptam: "66.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLSD001",
    designation: "Fermeture du vagin",
    tarifSecteur1Optam: "183.38",
    tarifHorsSecteur1HorsOptam: "132.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLMD001",
    designation: "Réparation de l'hymen",
    tarifSecteur1Optam: "73.35",
    tarifHorsSecteur1HorsOptam: "55.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JLLD001",
    designation: "Pose de dispositif intravaginal",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLGD001",
    designation: "Ablation ou changement de dispositif intravaginal",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLAD001",
    designation: "Séance de dilatation vaginale par bougies",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLGE001",
    designation: "Ablation de corps étranger du vagin, par vaginoscopie",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JMPP001",
    designation:
      "Libération non chirurgicale de coalescence des petites lèvres de la vulve",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JMPA003",
    designation: "Libération chirurgicale d'adhérences de la vulve",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMPA005",
    designation: "Incision de collection vulvopérinéale",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMPA001",
    designation: "Incision de la glande vestibulaire majeure [de Bartholin]",
    tarifSecteur1Optam: "56.74",
    tarifHorsSecteur1HorsOptam: "50.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA006",
    designation: "Amputation du clitoris [Clitoridectomie]",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA001",
    designation: "Exérèse de la glande vestibulaire majeure [de Bartholin]",
    tarifSecteur1Optam: "92.04",
    tarifHorsSecteur1HorsOptam: "88.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA002",
    designation: "Exérèse de lésion vulvopérinéale",
    tarifSecteur1Optam: "43.6",
    tarifHorsSecteur1HorsOptam: "38.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA003",
    designation: "Vulvectomie partielle sans curage lymphonodal",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA008",
    designation:
      "Vulvectomie partielle avec curage lymphonodal inguinal unilatéral",
    tarifSecteur1Optam: "292.02",
    tarifHorsSecteur1HorsOptam: "269.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA007",
    designation: "Vulvectomie totale sans curage lymphonodal",
    tarifSecteur1Optam: "238.74",
    tarifHorsSecteur1HorsOptam: "203.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA005",
    designation:
      "Vulvectomie totale avec curage lymphonodal inguinal unilatéral",
    tarifSecteur1Optam: "458.8",
    tarifHorsSecteur1HorsOptam: "366.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA009",
    designation:
      "Vulvectomie totale avec curage lymphonodal inguinal bilatéral",
    tarifSecteur1Optam: "688.54",
    tarifHorsSecteur1HorsOptam: "517.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA010",
    designation:
      "Vulvectomie totale avec curage lymphonodal inguinal et iliaque unilatéral",
    tarifSecteur1Optam: "425.28",
    tarifHorsSecteur1HorsOptam: "425.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMFA004",
    designation:
      "Vulvectomie totale avec curage lymphonodal inguinal et iliaque bilatéral",
    tarifSecteur1Optam: "796.49",
    tarifHorsSecteur1HorsOptam: "580.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMCA005",
    designation: "Suture de plaie de la vulve",
    tarifSecteur1Optam: "48.44",
    tarifHorsSecteur1HorsOptam: "41.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMCA006",
    designation:
      "Suture de plaie de la vulve et de l'anus, sans suture du muscle sphincter externe de l'anus",
    tarifSecteur1Optam: "74.04",
    tarifHorsSecteur1HorsOptam: "60.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMMA002",
    designation: "Vulvopérinéoplastie",
    tarifSecteur1Optam: "127.49",
    tarifHorsSecteur1HorsOptam: "127.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMMA005",
    designation: "Nymphoplastie de réduction",
    tarifSecteur1Optam: "57.44",
    tarifHorsSecteur1HorsOptam: "46.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMPA002",
    designation:
      "Périnéotomie médiane sans lambeau cutané périnéal, pour élargissement de l'orifice du vagin",
    tarifSecteur1Optam: "58.82",
    tarifHorsSecteur1HorsOptam: "47.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMPA004",
    designation: "Périnéotomie médiane avec lambeau cutané périnéal",
    tarifSecteur1Optam: "170.92",
    tarifHorsSecteur1HorsOptam: "169.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCGD069",
    designation:
      "Ablation partielle d'implant de renfort  [mesh implant]  pour organes pelviens  par voie vaginale",
    tarifSecteur1Optam: "170.16",
    tarifHorsSecteur1HorsOptam: "170.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCGC718",
    designation:
      "Ablation partielle d’implant de renfort [mesh implant] pour organe pelvien, par coelioscopie",
    tarifSecteur1Optam: "268.71",
    tarifHorsSecteur1HorsOptam: "268.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCGA765",
    designation:
      "Ablation partielle d'implant de renfort  [mesh implant]  pour organes pelviens par laparotomie",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "283.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCGC025",
    designation:
      "Ablation totale d’implant de renfort [mesh implant] pour organes pelviens par cœlioscopie",
    tarifSecteur1Optam: "377.9",
    tarifHorsSecteur1HorsOptam: "377.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCGA760",
    designation:
      "Ablation totale d’implant de renfort [mesh implant] pour organes pelviens par abord vaginal",
    tarifSecteur1Optam: "274.38",
    tarifHorsSecteur1HorsOptam: "274.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCGA433",
    designation:
      "Ablation totale d’implant de renfort [mesh implant] pour organes pelviens par laparotomie",
    tarifSecteur1Optam: "400.59",
    tarifHorsSecteur1HorsOptam: "400.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JZNP002",
    designation: "Destruction de moins de 10 lésions périnéales",
    tarifSecteur1Optam: "34.89",
    tarifHorsSecteur1HorsOptam: "28.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JZNP001",
    designation: "Destruction de 10 à 50 lésions périnéales",
    tarifSecteur1Optam: "50.55",
    tarifHorsSecteur1HorsOptam: "45.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JZNP003",
    designation:
      "Destruction de 51 lésions périnéales ou plus, ou de lésion périnéale de plus de 30 cm²",
    tarifSecteur1Optam: "72.62",
    tarifHorsSecteur1HorsOptam: "58.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QBFA014",
    designation: "Excision d'une fasciite nécrosante périnéofessière",
    tarifSecteur1Optam: "228.3",
    tarifHorsSecteur1HorsOptam: "202.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFJC001",
    designation:
      "Évacuation de collection périrénale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "225.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFJA001",
    designation: "Évacuation de collection périrénale, par abord direct",
    tarifSecteur1Optam: "289.27",
    tarifHorsSecteur1HorsOptam: "220.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA006",
    designation:
      "Exérèse de lésion de l'espace rétropéritonéal sans dissection des gros vaisseaux, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1008.91",
    tarifHorsSecteur1HorsOptam: "808.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFC002",
    designation:
      "Exérèse de lésion de l'espace rétropéritonéal, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "729.56",
    tarifHorsSecteur1HorsOptam: "624.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA010",
    designation:
      "Exérèse de lésion de l'espace rétropéritonéal sans dissection des gros vaisseaux, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "649.44",
    tarifHorsSecteur1HorsOptam: "577.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA021",
    designation:
      "Exérèse de lésion de l'espace rétropéritonéal avec dissection des gros vaisseaux, par abord direct",
    tarifSecteur1Optam: "713.25",
    tarifHorsSecteur1HorsOptam: "614.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFC001",
    designation:
      "Exérèse de lésion endométriosique de la cloison rectovaginale, par cœlioscopie",
    tarifSecteur1Optam: "236.66",
    tarifHorsSecteur1HorsOptam: "219.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA012",
    designation:
      "Exérèse de lésion endométriosique de la cloison rectovaginale, par abord vaginal",
    tarifSecteur1Optam: "280.95",
    tarifHorsSecteur1HorsOptam: "233.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA014",
    designation:
      "Exérèse de lésion endométriosique de la cloison rectovaginale, par laparotomie",
    tarifSecteur1Optam: "280.95",
    tarifHorsSecteur1HorsOptam: "233.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA007",
    designation: "Exérèse d'une lipomatose pelvienne, par laparotomie",
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "311.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA020",
    designation:
      "Exérèse d'un tératome sacrococcygien de moins de 10 cm de diamètre, par abord transsacrococcygien [de Kraske]",
    tarifSecteur1Optam: "361.59",
    tarifHorsSecteur1HorsOptam: "315.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA017",
    designation:
      "Exérèse d'un tératome sacrococcygien de plus de 10 cm de diamètre, par abord transsacrococcygien [de Kraske]",
    tarifSecteur1Optam: "507.64",
    tarifHorsSecteur1HorsOptam: "399.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA015",
    designation:
      "Exérèse d'un tératome sacrococcygien, par laparotomie et par abord transsacrococcygien [de Kraske]",
    tarifSecteur1Optam: "633.85",
    tarifHorsSecteur1HorsOptam: "494.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA018",
    designation: "Pelvectomie antérieure, par laparotomie",
    tarifSecteur1Optam: "813.93",
    tarifHorsSecteur1HorsOptam: "717.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA001",
    designation:
      "Pelvectomie antérieure avec urétérostomie cutanée, par laparotomie",
    tarifSecteur1Optam: "1039.39",
    tarifHorsSecteur1HorsOptam: "883.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA003",
    designation:
      "Pelvectomie antérieure avec urétérostomie cutanée transintestinale par anse non détubulée, par laparotomie",
    tarifSecteur1Optam: "1215.94",
    tarifHorsSecteur1HorsOptam: "1038.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA016",
    designation:
      "Pelvectomie antérieure avec urétérostomie cutanée transintestinale par anse détubulée continente, par laparotomie",
    tarifSecteur1Optam: "1382.55",
    tarifHorsSecteur1HorsOptam: "1134.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA009",
    designation:
      "Pelvectomie antérieure avec anastomose urétérocolique directe, par laparotomie",
    tarifSecteur1Optam: "960.88",
    tarifHorsSecteur1HorsOptam: "960.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA019",
    designation:
      "Pelvectomie antérieure avec anastomose urétérocolique et confection d'un réservoir détubulé rectosigmoïdien ou iléo-recto-sigmoïdien, par laparotomie",
    tarifSecteur1Optam: "1178.72",
    tarifHorsSecteur1HorsOptam: "1178.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA022",
    designation:
      "Pelvectomie antérieure avec entérocystoplastie de remplacement [néovessie] orthotopique par anse détubulée, par laparotomie",
    tarifSecteur1Optam: "1603.05",
    tarifHorsSecteur1HorsOptam: "1328.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA005",
    designation:
      "Pelvectomie postérieure sans rétablissement de la continuité digestive, par laparotomie",
    tarifSecteur1Optam: "835.91",
    tarifHorsSecteur1HorsOptam: "751.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA004",
    designation:
      "Pelvectomie postérieure sans rétablissement de la continuité digestive, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "860.73",
    tarifHorsSecteur1HorsOptam: "766.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA002",
    designation:
      "Pelvectomie postérieure avec rétablissement de la continuité digestive, par laparotomie",
    tarifSecteur1Optam: "860.73",
    tarifHorsSecteur1HorsOptam: "854.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA008",
    designation:
      "Pelvectomie totale avec urétérostomie cutanée, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "1257.77",
    tarifHorsSecteur1HorsOptam: "1053.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA011",
    designation:
      "Pelvectomie totale avec urétérostomie cutanée transintestinale par anse non détubulée, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "1495.99",
    tarifHorsSecteur1HorsOptam: "1244.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JFFA013",
    designation:
      "Pelvectomie totale avec urétérostomie cutanée transintestinale par anse détubulée continente, par laparotomie et par abord périnéal",
    tarifSecteur1Optam: "1559.8",
    tarifHorsSecteur1HorsOptam: "1281.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMMA001",
    designation:
      "Vestibuloplastie avec enfouissement ou résection du clitoris, pour féminisation",
    tarifSecteur1Optam: "348.12",
    tarifHorsSecteur1HorsOptam: "272.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JZMA003",
    designation:
      "Urétroplastie et vestibuloplastie avec enfouissement ou réduction du clitoris, pour féminisation",
    tarifSecteur1Optam: "429.65",
    tarifHorsSecteur1HorsOptam: "389.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JZMA002",
    designation:
      "Urétroplastie, vaginoplastie et vestibuloplastie avec enfouissement ou réduction du clitoris, pour féminisation",
    tarifSecteur1Optam: "548.06",
    tarifHorsSecteur1HorsOptam: "458.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMEA002",
    designation: "Lambeau neurovasculaire pédiculé du clitoris",
    tarifSecteur1Optam: "175.12",
    tarifHorsSecteur1HorsOptam: "155.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMEA001",
    designation: "Transposition du clitoris",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "408.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JMMA004",
    designation: "Clitoridoplastie de réduction",
    tarifSecteur1Optam: "248.86",
    tarifHorsSecteur1HorsOptam: "197.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHLA001",
    designation: "Armature d'un néopénis",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA007",
    designation: "Phalloplastie par lambeau cutané libre",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA004",
    designation: "Phalloplastie par lambeau cutané tubulé pénien",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHMA008",
    designation: "Phalloplastie par lambeau inguinal pédiculé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JVRP004",
    designation: "Séance d'entraînement à l'hémodialyse",
    tarifSecteur1Optam: "48.74",
    tarifHorsSecteur1HorsOptam: "48.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVRP008",
    designation:
      "Séance d'entraînement à la dialyse péritonéale continue ambulatoire",
    tarifSecteur1Optam: "48.74",
    tarifHorsSecteur1HorsOptam: "48.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVRP007",
    designation: "Séance d'entraînement à la dialyse péritonéale automatisée",
    tarifSecteur1Optam: "48.74",
    tarifHorsSecteur1HorsOptam: "48.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF003",
    designation: "Séance d'épuration extrarénale par hémoperfusion",
    tarifSecteur1Optam: "131.34",
    tarifHorsSecteur1HorsOptam: "116.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF004",
    designation:
      "Séance d'épuration extrarénale par hémodialyse pour insuffisance rénale chronique",
    tarifSecteur1Optam: "38.35",
    tarifHorsSecteur1HorsOptam: "38.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF008",
    designation:
      "Séance d'épuration extrarénale par hémodiafiltration, hémofiltration ou biofiltration sans acétate pour insuffisance rénale chronique",
    tarifSecteur1Optam: "40.77",
    tarifHorsSecteur1HorsOptam: "40.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJB001",
    designation:
      "Séance d'épuration extrarénale par dialyse péritonéale pour insuffisance rénale chronique",
    tarifSecteur1Optam: "43.83",
    tarifHorsSecteur1HorsOptam: "43.83",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF002",
    designation:
      "Épuration extrarénale par hémodialyse, hémodiafiltration ou hémofiltration discontinue pour insuffisance rénale aigüe, par 24 heures",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF006",
    designation:
      "Épuration extrarénale par hémodialyse ou hémofiltration discontinue pour insuffisance rénale aigüe chez le nouveau-né, par 24 heures",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "194.27",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF005",
    designation:
      "Épuration extrarénale par hémodialyse, hémodiafiltration ou hémofiltration continue pour insuffisance rénale aigüe, par 24 heures",
    tarifSecteur1Optam: "192",
    tarifHorsSecteur1HorsOptam: "192",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJF007",
    designation:
      "Épuration extrarénale par hémodialyse ou hémofiltration continue pour insuffisance rénale aigüe chez le nouveau-né, par 24 heures",
    tarifSecteur1Optam: "209.67",
    tarifHorsSecteur1HorsOptam: "209.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JVJB002",
    designation:
      "Épuration extrarénale par dialyse péritonéale pour insuffisance rénale aigüe, par 24 heures",
    tarifSecteur1Optam: "113.03",
    tarifHorsSecteur1HorsOptam: "113.03",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JQQP001",
    designation:
      "Enregistrement du rythme cardiaque du fœtus d'une durée de plus de 20 minutes, en dehors du travail",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "ZCQM007",
    designation:
      "Échographie du petit bassin [pelvis] féminin pour surveillance de l'ovulation",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQM009",
    designation:
      "Échographie-doppler du petit bassin [pelvis] féminin pour surveillance de l'ovulation",
    tarifSecteur1Optam: "42.25",
    tarifHorsSecteur1HorsOptam: "42.25",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JNQM001",
    designation:
      "Échographie non morphologique de la grossesse avant 11 semaines d'aménorrhée",
    tarifSecteur1Optam: "35.65",
    tarifHorsSecteur1HorsOptam: "35.65",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM010",
    designation:
      "Échographie biométrique et morphologique d'une grossesse uniembryonnaire au 1er trimestre",
    tarifSecteur1Optam: "61.47",
    tarifHorsSecteur1HorsOptam: "48.35",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM015",
    designation:
      "Échographie biométrique et morphologique d'une grossesse multiembryonnaire au 1er trimestre",
    tarifSecteur1Optam: "71.57",
    tarifHorsSecteur1HorsOptam: "54.21",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM018",
    designation:
      "Échographie biométrique et morphologique d'une grossesse unifœtale au 2ème trimestre",
    tarifSecteur1Optam: "100.2",
    tarifHorsSecteur1HorsOptam: "81.92",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM016",
    designation:
      "Échographie biométrique et morphologique d'une grossesse unifœtale au 3ème trimestre",
    tarifSecteur1Optam: "100.2",
    tarifHorsSecteur1HorsOptam: "73.99",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM019",
    designation:
      "Échographie biométrique et morphologique d'une grossesse multifœtale au 2ème trimestre",
    tarifSecteur1Optam: "154.09",
    tarifHorsSecteur1HorsOptam: "137",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM017",
    designation:
      "Échographie biométrique et morphologique d'une grossesse multifœtale au 3ème trimestre",
    tarifSecteur1Optam: "154.09",
    tarifHorsSecteur1HorsOptam: "121.12",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM002",
    designation:
      "Échographie d'une grossesse unifœtale à partir du 2ème trimestre avec échographie-doppler des artères utérines de la mère et des vaisseaux du fœtus, pour souffrance fœtale",
    tarifSecteur1Optam: "92.19",
    tarifHorsSecteur1HorsOptam: "92.19",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM007",
    designation:
      "Échographie d'une grossesse multifœtale à partir du 2ème trimestre avec échographie-doppler des artères utérines de la mère et des vaisseaux des fœtus, pour souffrance fœtale",
    tarifSecteur1Optam: "133.81",
    tarifHorsSecteur1HorsOptam: "133.81",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM001",
    designation: "Échographie de surveillance de la croissance fœtale",
    tarifSecteur1Optam: "46.15",
    tarifHorsSecteur1HorsOptam: "46.15",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM003",
    designation:
      "Échographie de surveillance de la croissance fœtale avec échographie-doppler des artères utérines de la mère et des vaisseaux du fœtus",
    tarifSecteur1Optam: "75.6",
    tarifHorsSecteur1HorsOptam: "75.6",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQM008",
    designation:
      "Échographie et hémodynamique doppler du cœur et des vaisseaux intrathoraciques du fœtus",
    tarifSecteur1Optam: "137.02",
    tarifHorsSecteur1HorsOptam: "137.02",
    regroupement: "ADE",
  },
  {
    codeCCAM: "JQQJ037",
    designation:
      "Mesure de la longueur du canal cervical du col de l'utérus, par échographie par voie vaginale",
    tarifSecteur1Optam: "33.44",
    tarifHorsSecteur1HorsOptam: "33.44",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZCQK001",
    designation: "Pelvimétrie par radiographie",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JNQK001",
    designation: "Radiographie du contenu de l'utérus gravide [contenu utérin]",
    tarifSecteur1Optam: "21.28",
    tarifHorsSecteur1HorsOptam: "21.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZCQK003",
    designation: "Pelvimétrie par scanographie",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "JPQE001",
    designation: "Amnioscopie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JPHJ002",
    designation:
      "Amniocentèse sur un sac amniotique unique, avec guidage échographique",
    tarifSecteur1Optam: "68.58",
    tarifHorsSecteur1HorsOptam: "68.58",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JPHJ001",
    designation:
      "Amniocentèse sur plusieurs sacs amniotiques, avec guidage échographique",
    tarifSecteur1Optam: "68.58",
    tarifHorsSecteur1HorsOptam: "68.58",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JPQX007",
    designation:
      "Examen anatomopathologique d'un placenta complet avec cordon et membranes",
    tarifSecteur1Optam: "52.5",
    tarifHorsSecteur1HorsOptam: "52.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JPQX019",
    designation:
      "Examen anatomopathologique de plusieurs placentas complets avec cordons et membranes",
    tarifSecteur1Optam: "65.73",
    tarifHorsSecteur1HorsOptam: "65.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JQQX109",
    designation:
      "Examen anatomopathologique de produit d'avortement avant la 14e semaine d'aménorrhée",
    tarifSecteur1Optam: "33.6",
    tarifHorsSecteur1HorsOptam: "33.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JQQX005",
    designation:
      "Autopsie médicale d'un fœtus ou d'un nouveau-né de moins de 4 jours de vie, sans examen de l'encéphale",
    tarifSecteur1Optam: "61.6",
    tarifHorsSecteur1HorsOptam: "61.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JQQX003",
    designation:
      "Autopsie médicale d'un fœtus ou d'un nouveau-né de moins de 4 jours de vie, avec examen de l'encéphale",
    tarifSecteur1Optam: "61.6",
    tarifHorsSecteur1HorsOptam: "61.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JQQX002",
    designation: "Autopsie médicale de 2 fœtus",
    tarifSecteur1Optam: "123.2",
    tarifHorsSecteur1HorsOptam: "123.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JQQX004",
    designation: "Autopsie médicale de 3 fœtus ou plus",
    tarifSecteur1Optam: "184.8",
    tarifHorsSecteur1HorsOptam: "184.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKHD002",
    designation:
      "Prélèvement et examen de la glaire cervicale, sans examen de la mobilité des spermatozoïdes",
    tarifSecteur1Optam: "14.53",
    tarifHorsSecteur1HorsOptam: "10.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKHD003",
    designation:
      "Prélèvement et examen de la glaire cervicale, avec examen de la mobilité des spermatozoïdes [Test postcoïtal de Huhner]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JJFJ001",
    designation:
      "Prélèvement d'ovocytes sur un ou deux ovaires, par voie transvaginale avec guidage échographique",
    tarifSecteur1Optam: "97.67",
    tarifHorsSecteur1HorsOptam: "85.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JJFC011",
    designation:
      "Prélèvement d'ovocytes sur un ou deux ovaires, par cœlioscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JHFB001",
    designation:
      "Prélèvement de spermatozoïdes au niveau du testicule, de l'épididyme ou du conduit déférent, par voie transcutanée",
    tarifSecteur1Optam: "79.58",
    tarifHorsSecteur1HorsOptam: "78.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JSLD002",
    designation: "Insémination artificielle intracervicale",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JSLD001",
    designation: "Insémination artificielle intra-utérine",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JSEC001",
    designation: "Transfert intratubaire d'embryon, par cœlioscopie",
    tarifSecteur1Optam: "153.62",
    tarifHorsSecteur1HorsOptam: "121.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JSED001",
    designation: "Transfert intra-utérin d'embryon, par voie vaginale",
    tarifSecteur1Optam: "52.25",
    tarifHorsSecteur1HorsOptam: "52.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JNMD001",
    designation: "Révision de la cavité de l'utérus après avortement",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNJP001",
    designation:
      "Évacuation d'un utérus gravide par moyen médicamenteux, au 1er trimestre de la grossesse",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNJD002",
    designation:
      "Évacuation d'un utérus gravide par aspiration et/ou curetage, au 1er trimestre de la grossesse",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNJD001",
    designation:
      "Évacuation d'un utérus gravide, au 2ème trimestre de la grossesse avant la 22ème semaine d'aménorrhée",
    tarifSecteur1Optam: "100.34",
    tarifHorsSecteur1HorsOptam: "82.39",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD014",
    designation:
      "Extraction sélective de fœtus au cours d'une grossesse multiple",
    tarifSecteur1Optam: "125.18",
    tarifHorsSecteur1HorsOptam: "125.18",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJLJ001",
    designation:
      "Injection intraovulaire d'agent pharmacologique pour grossesse extra-utérine, par voie transvaginale avec guidage échographique",
    tarifSecteur1Optam: "70.58",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJJC002",
    designation:
      "Expression tubaire pour évacuation tuboabdominale de grossesse extra-utérine, par cœlioscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJJA002",
    designation:
      "Expression tubaire pour évacuation tuboabdominale de grossesse extra-utérine, par laparotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJPC001",
    designation:
      "Salpingotomie avec aspiration de grossesse extra-utérine, par cœlioscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJPA001",
    designation:
      "Salpingotomie avec aspiration de grossesse extra-utérine, par laparotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJFC001",
    designation:
      "Salpingectomie partielle ou totale pour grossesse extra-utérine, par cœlioscopie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JJFA001",
    designation:
      "Salpingectomie partielle ou totale pour grossesse extra-utérine, par laparotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGA001",
    designation:
      "Extraction de grossesse extra-utérine abdominale au delà de 13 semaines d'aménorrhée, par laparotomie",
    tarifSecteur1Optam: "229.74",
    tarifHorsSecteur1HorsOptam: "221.04",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQEP001",
    designation:
      "Version du fœtus par manœuvres obstétricales externes au cours de la grossesse, avec contrôle échographique et surveillance du rythme cardiaque du fœtus",
    tarifSecteur1Optam: "55.36",
    tarifHorsSecteur1HorsOptam: "41.58",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNBD001",
    designation:
      "Cerclage du col de l'utérus au cours de la grossesse, par voie transvaginale",
    tarifSecteur1Optam: "67.82",
    tarifHorsSecteur1HorsOptam: "63.52",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNGD002",
    designation: "Ablation de cerclage du col de l'utérus",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JPJB001",
    designation:
      "Évacuation de liquide amniotique pour hydramnios, par voie transcutanée",
    tarifSecteur1Optam: "67.2",
    tarifHorsSecteur1HorsOptam: "67.2",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD010",
    designation:
      "Accouchement céphalique unique par voie naturelle, chez une primipare",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD012",
    designation:
      "Accouchement céphalique unique par voie naturelle, chez une multipare",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD004",
    designation:
      "Accouchement unique par le siège par voie naturelle, chez une primipare",
    tarifSecteur1Optam: "385.44",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD001",
    designation:
      "Accouchement unique par le siège par voie naturelle, chez une multipare",
    tarifSecteur1Optam: "316.94",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD003",
    designation:
      "Accouchement unique par le siège par voie naturelle avec petite extraction, chez une primipare",
    tarifSecteur1Optam: "426.96",
    tarifHorsSecteur1HorsOptam: "379.31",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD008",
    designation:
      "Accouchement unique par le siège par voie naturelle avec petite extraction, chez une multipare",
    tarifSecteur1Optam: "353.61",
    tarifHorsSecteur1HorsOptam: "336.77",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD013",
    designation:
      "Accouchement unique par le siège par voie naturelle avec grande extraction, chez une primipare",
    tarifSecteur1Optam: "477.48",
    tarifHorsSecteur1HorsOptam: "408.61",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD005",
    designation:
      "Accouchement unique par le siège par voie naturelle avec grande extraction, chez une multipare",
    tarifSecteur1Optam: "477.48",
    tarifHorsSecteur1HorsOptam: "408.61",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD002",
    designation: "Accouchement multiple par voie naturelle, chez une primipare",
    tarifSecteur1Optam: "472.64",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD007",
    designation: "Accouchement multiple par voie naturelle, chez une multipare",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQQP099",
    designation:
      "Surveillance  du travail par un praticien différent de celui qui réalise l’extraction  ",
    tarifSecteur1Optam: "112",
    tarifHorsSecteur1HorsOptam: "112",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGA002",
    designation: "Accouchement par césarienne programmée, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGA004",
    designation:
      "Accouchement par césarienne en urgence en dehors du travail, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGA003",
    designation:
      "Accouchement par césarienne au cours du travail, par laparotomie",
    tarifSecteur1Optam: "362.61",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGA005",
    designation: "Accouchement par césarienne, par abord vaginal",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNCA002",
    designation:
      "Suture du corps de l'utérus [Hystérorraphie] pour rupture obstétricale, par laparotomie",
    tarifSecteur1Optam: "241.51",
    tarifHorsSecteur1HorsOptam: "210.3",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNCA001",
    designation:
      "Suture immédiate de déchirure obstétricale du col de l'utérus",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JMCA002",
    designation:
      "Suture immédiate de déchirure obstétricale du vagin, de la vulve et/ou du périnée [périnée simple]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JMCA003",
    designation:
      "Suture immédiate de déchirure obstétricale du périnée avec lésion du muscle sphincter externe de l'anus [périnée complet]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JMCA001",
    designation:
      "Suture immédiate de déchirure obstétricale du périnée avec lésion du rectum [périnée complet compliqué]",
    tarifSecteur1Optam: "157.08",
    tarifHorsSecteur1HorsOptam: "156.58",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JMCA004",
    designation:
      "Suture immédiate de déchirure obstétricale du périnée avec lésion de la vessie ou de l'urètre",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ACO",
  },
  {
    codeCCAM: "EDSF011",
    designation:
      "Embolisation des artères iliaques internes [hypogastriques] et/ou de ses branches pour hémorragie du post-partum, par voie artérielle transcutanée",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ACO",
  },
  {
    codeCCAM: "EDSA002",
    designation:
      "Ligature des artères iliaques internes [hypogastriques] pour hémorragie du post-partum, par laparotomie",
    tarifSecteur1Optam: "344.62",
    tarifHorsSecteur1HorsOptam: "270.1",
    regroupement: "ACO",
  },
  {
    codeCCAM: "ELSA002",
    designation:
      "Ligature des pédicules vasculaires de l'utérus pour hémorragie du post-partum, par laparotomie",
    tarifSecteur1Optam: "249.81",
    tarifHorsSecteur1HorsOptam: "215.11",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNFA001",
    designation:
      "Hystérectomie pour complications obstétricales, par laparotomie",
    tarifSecteur1Optam: "344.62",
    tarifHorsSecteur1HorsOptam: "300.46",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNBD002",
    designation:
      "Tamponnement intra-utérin ou intravaginal, pour hémorragie obstétricale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JLJA001",
    designation:
      "Évacuation d'hématome ou de thrombus du vagin, de la vulve et/ou du périnée d'origine obstétricale, par abord direct",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNED001",
    designation: "Réduction manuelle d'inversion utérine",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNPA002",
    designation: "Réduction d'inversion utérine par hystérotomie",
    tarifSecteur1Optam: "223.52",
    tarifHorsSecteur1HorsOptam: "199.86",
    regroupement: "ACO",
  },
  {
    codeCCAM: "GEJD001",
    designation:
      "Aspiration intratrachéale d'un nouveau-né à la naissance, en présence d'un liquide amniotique méconial",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD018",
    designation:
      "Ventilation manuelle d'un nouveau-né à la naissance, au masque facial",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLLD016",
    designation:
      "Ventilation manuelle d'un nouveau-né à la naissance, au masque facial, avec administration intraveineuse d'agent pharmacologique et/ou de soluté",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZEP004",
    designation:
      "Installation d'un nouveau-né en incubateur à la naissance, avec ventilation spontanée sans oxygénothérapie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZEP002",
    designation:
      "Installation d'un nouveau-né en incubateur à la naissance, avec ventilation spontanée avec oxygénothérapie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZEP003",
    designation:
      "Installation d'un nouveau-né en incubateur à la naissance, avec ventilation spontanée au masque facial avec pression expiratoire positive [VS-PEP] ou ventilation mécanique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCLA001",
    designation: "Pose d'un sac à viscères chez un nouveau-né à la naissance",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPLF001",
    designation:
      "Administration intraveineuse d'agent pharmacologique et/ou de soluté chez le nouveau-né à la naissance",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCQM001",
    designation: "Échographie transcutanée de la glande thyroïde",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "34.97",
    regroupement: "ADE",
  },
  {
    codeCCAM: "KDQM001",
    designation: "Échographie transcutanée des glandes parathyroïdes",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "KZQL003",
    designation: "Scintigraphie aux analogues de la somatostatine en 2 temps",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KZQL004",
    designation:
      "Scintigraphie aux analogues de la somatostatine en 2 temps, avec scintigraphie du corps entier complémentaire d'une image segmentaire",
    tarifSecteur1Optam: "401.51",
    tarifHorsSecteur1HorsOptam: "401.51",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KZQL002",
    designation:
      "Scintigraphie aux analogues de la somatostatine avec tomoscintigraphie complémentaire, scintigraphie du corps entier complémentaire d'une image segmentaire et scintigraphie du corps entier à 72 heures",
    tarifSecteur1Optam: "401.51",
    tarifHorsSecteur1HorsOptam: "401.51",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL003",
    designation: "Scintigraphie du corps entier à l'iode 131",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KCQL002",
    designation:
      "Mesure radio-isotopique de la fixation thyroïdienne de l'iode",
    tarifSecteur1Optam: "56.65",
    tarifHorsSecteur1HorsOptam: "56.65",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KCQL003",
    designation: "Scintigraphie de la glande thyroïde",
    tarifSecteur1Optam: "109.7",
    tarifHorsSecteur1HorsOptam: "109.7",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KCQL001",
    designation:
      "Scintigraphie de la glande thyroïde avec mesure radio-isotopique de la fixation thyroïdienne de l'iode",
    tarifSecteur1Optam: "162.76",
    tarifHorsSecteur1HorsOptam: "162.76",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KDQL001",
    designation: "Scintigraphie des glandes parathyroïdes",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KEQL002",
    designation: "Scintigraphie corticosurrénalienne",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KEQL001",
    designation: "Scintigraphie médullosurrénalienne",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KGQL002",
    designation: "Mesure radio-isotopique du métabolisme phosphocalcique",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KGQL003",
    designation: "Mesure radio-isotopique des compartiments biologiques",
    tarifSecteur1Optam: "127.39",
    tarifHorsSecteur1HorsOptam: "127.39",
    regroupement: "ADI",
  },
  {
    codeCCAM: "KGQP003",
    designation:
      "Mesure de la sécrétion d'insuline avec administration intraveineuse de glucose",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KGQP005",
    designation:
      "Mesure de la sensibilité à l'insuline avec administration intraveineuse de glucose et d'insuline, sans mesure de la production hépatique de glucose",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KGQP002",
    designation:
      "Mesure de la sensibilité à l'insuline avec administration intraveineuse de glucose et d'insuline, avec mesure de la production hépatique de glucose ",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCHB001",
    designation:
      "Ponction ou cytoponction de la glande thyroïde, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCHJ001",
    designation:
      "Ponction ou cytoponction d'une lésion de la glande thyroïde, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCHJ004",
    designation:
      "Ponction ou cytoponction de plusieurs lésions de la glande thyroïde, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "47.15",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCHJ003",
    designation:
      "Biopsie d'une lésion de la glande thyroïde, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCHJ002",
    designation:
      "Biopsie de plusieurs lésions de la glande thyroïde, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de lobectomie ou de lobo-isthmectomie thyroïdienne",
    tarifSecteur1Optam: "64.86",
    tarifHorsSecteur1HorsOptam: "64.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de thyroïdectomie subtotale ou totale",
    tarifSecteur1Optam: "99.26",
    tarifHorsSecteur1HorsOptam: "99.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KDQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de parathyroïdectomie",
    tarifSecteur1Optam: "60.35",
    tarifHorsSecteur1HorsOptam: "60.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KEQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce de surrénalectomie",
    tarifSecteur1Optam: "99.7",
    tarifHorsSecteur1HorsOptam: "99.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KANB001",
    designation: "Hypophysiolyse fonctionnelle, par voie transsphénoïdale",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KAFE900",
    designation:
      "Exérèse de lésion de la loge hypophysaire, par vidéochirurgie transsphénoïdale",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KAFA001",
    designation:
      "Exérèse de lésion de la loge hypophysaire, par abord transsphénoïdal",
    tarifSecteur1Optam: "623.21",
    tarifHorsSecteur1HorsOptam: "537.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KAFA002",
    designation: "Exérèse de lésion de la loge hypophysaire, par craniotomie",
    tarifSecteur1Optam: "820.31",
    tarifHorsSecteur1HorsOptam: "739.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KBFA001",
    designation: "Exérèse de lésion de la région pinéale, par craniotomie",
    tarifSecteur1Optam: "1034.43",
    tarifHorsSecteur1HorsOptam: "863.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCJB001",
    designation:
      "Évacuation de collection de la glande thyroïde et/ou de sa loge, par voie transcutanée",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCJA001",
    designation:
      "Évacuation de collection de la loge thyroïdienne, par cervicotomie",
    tarifSecteur1Optam: "136.84",
    tarifHorsSecteur1HorsOptam: "96.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCGA001",
    designation:
      "Énucléation de nodule de la glande thyroïde, par cervicotomie",
    tarifSecteur1Optam: "134",
    tarifHorsSecteur1HorsOptam: "130.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA004",
    designation: "Isthmectomie de la glande thyroïde, par cervicotomie",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA001",
    designation:
      "Lobectomie unilatérale de la glande thyroïde, par cervicotomie",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "236.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA008",
    designation:
      "Lobo-isthmectomie unilatérale de la glande thyroïde, par cervicotomie",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "273.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA010",
    designation: "Thyroïdectomie subtotale, par cervicotomie",
    tarifSecteur1Optam: "354.5",
    tarifHorsSecteur1HorsOptam: "314.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA009",
    designation: "Thyroïdectomie subtotale, par cervicothoracotomie",
    tarifSecteur1Optam: "567.2",
    tarifHorsSecteur1HorsOptam: "460.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA005",
    designation: "Thyroïdectomie totale, par cervicotomie",
    tarifSecteur1Optam: "460.85",
    tarifHorsSecteur1HorsOptam: "385.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA007",
    designation: "Thyroïdectomie totale, par cervicothoracotomie",
    tarifSecteur1Optam: "567.2",
    tarifHorsSecteur1HorsOptam: "460.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA002",
    designation:
      "Thyroïdectomie totale avec résection partielle de cartilage du larynx, par cervicotomie",
    tarifSecteur1Optam: "545.22",
    tarifHorsSecteur1HorsOptam: "447.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA003",
    designation:
      "Thyroïdectomie totale avec résection non interruptrice de la trachée, par cervicotomie",
    tarifSecteur1Optam: "601.94",
    tarifHorsSecteur1HorsOptam: "489.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA006",
    designation:
      "Thyroïdectomie totale avec résection interruptrice et anastomose de la trachée, par cervicotomie",
    tarifSecteur1Optam: "792.66",
    tarifHorsSecteur1HorsOptam: "713.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCMA001",
    designation: "Totalisation secondaire de thyroïdectomie, par cervicotomie",
    tarifSecteur1Optam: "481.41",
    tarifHorsSecteur1HorsOptam: "331.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCNL003",
    designation:
      "Irradiation interne de la glande thyroïde par administration d'iode 131 à une dose inférieure ou égale à 1 gigabecquerel [GBq]",
    tarifSecteur1Optam: "70.74",
    tarifHorsSecteur1HorsOptam: "70.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KCNL004",
    designation:
      "Irradiation interne de la glande thyroïde par administration d'iode 131 à une dose supérieure à 1 gigabecquerel [GBq]",
    tarifSecteur1Optam: "176.85",
    tarifHorsSecteur1HorsOptam: "176.85",
    regroupement: "ATM",
  },
  {
    codeCCAM: "KDQC900",
    designation:
      "Exploration des sites parathyroïdiens cervicaux, par cervicoscopie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDQA001",
    designation:
      "Exploration des sites parathyroïdiens cervicaux, par cervicotomie",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "394.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDQA003",
    designation:
      "Exploration des sites parathyroïdiens cervicaux et médiastinaux, par cervicothoracotomie",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "416.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDQC002",
    designation:
      "Exploration des sites parathyroïdiens médiastinaux, par vidéochirurgie",
    tarifSecteur1Optam: "512.61",
    tarifHorsSecteur1HorsOptam: "445.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDQA002",
    designation:
      "Exploration des sites parathyroïdiens médiastinaux, par thoracotomie",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "390.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDFC001",
    designation:
      "Parathyroïdectomie unique sans exploration des autres sites parathyroïdiens, par cervicoscopie",
    tarifSecteur1Optam: "263.75",
    tarifHorsSecteur1HorsOptam: "253.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDFA002",
    designation:
      "Parathyroïdectomie unique sans exploration des autres sites parathyroïdiens, par cervicotomie",
    tarifSecteur1Optam: "263.75",
    tarifHorsSecteur1HorsOptam: "253.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDFA001",
    designation:
      "Parathyroïdectomie totale avec réimplantation immédiate de parathyroïde",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "403.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDFA003",
    designation: "Exérèse de tissu parathyroïdien préalablement transplanté",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KDEA001",
    designation: "Réimplantation secondaire de parathyroïde",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KEFC002",
    designation:
      "Surrénalectomie partielle ou totale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "602.65",
    tarifHorsSecteur1HorsOptam: "518.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KEFA002",
    designation: "Surrénalectomie partielle ou totale, par abord direct",
    tarifSecteur1Optam: "520.41",
    tarifHorsSecteur1HorsOptam: "459.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KEFC001",
    designation:
      "Surrénalectomie totale pour phéochromocytome, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "886.25",
    tarifHorsSecteur1HorsOptam: "683.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KEFA001",
    designation:
      "Surrénalectomie totale pour phéochromocytome, par abord direct",
    tarifSecteur1Optam: "774.23",
    tarifHorsSecteur1HorsOptam: "606.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KZFC001",
    designation:
      "Exérèse de tumeur endocrinienne intraabdominale ectopique, par cœlioscopie",
    tarifSecteur1Optam: "405.55",
    tarifHorsSecteur1HorsOptam: "267.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KZFA001",
    designation:
      "Exérèse de tumeur endocrinienne intraabdominale ectopique, par laparotomie",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "240.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBQP002",
    designation: "Enregistrement électronique des mouvements de la mandibule",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ADQB001",
    designation:
      "Électromyographie de détection du muscle ptérygoïdien latéral, par électrode aiguille",
    tarifSecteur1Optam: "42.08",
    tarifHorsSecteur1HorsOptam: "42.08",
    regroupement: "ADM",
  },
  {
    codeCCAM: "ZAQP001",
    designation: "Photographies de la face",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "AID",
  },
  {
    codeCCAM: "LAQK003",
    designation:
      "Radiographie du crâne et/ou du massif facial selon 1 ou 2 incidences",
    tarifSecteur1Optam: "23.94",
    tarifHorsSecteur1HorsOptam: "23.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQK005",
    designation: "Radiographie du crâne selon 3 incidences ou plus ",
    tarifSecteur1Optam: "34.58",
    tarifHorsSecteur1HorsOptam: "34.58",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LBQK005",
    designation:
      "Radiographie unilatérale ou bilatérale de l'articulation temporomandibulaire",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQK012",
    designation:
      "Téléradiographie du crâne et du massif facial selon 1 incidence",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "AID",
  },
  {
    codeCCAM: "LAQK001",
    designation:
      "Téléradiographie du crâne et du massif facial selon 2 incidences",
    tarifSecteur1Optam: "26.6",
    tarifHorsSecteur1HorsOptam: "26.6",
    regroupement: "AID",
  },
  {
    codeCCAM: "LAQK008",
    designation:
      "Téléradiographie du crâne et du massif facial selon 3 incidences",
    tarifSecteur1Optam: "26.6",
    tarifHorsSecteur1HorsOptam: "26.6",
    regroupement: "AID",
  },
  {
    codeCCAM: "LAQK027",
    designation:
      "Radiographie volumique par faisceau conique [cone beam computerized tomography, CBCT] du maxillaire, de la mandibule et/ou d’arcade dentaire",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "AID",
  },
  {
    codeCCAM: "LAQK004",
    designation: "Analyse céphalométrique craniofaciale bidimensionnelle",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LAQK010",
    designation:
      "Analyse céphalométrique craniofaciale bidimensionnelle avec simulation des objectifs thérapeutiques",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LAQK007",
    designation: "Analyse céphalométrique craniofaciale tridimensionnelle",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LAQK006",
    designation: "Analyse céphalométrique architecturale craniofaciale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBQK001",
    designation:
      "Tomographie unilatérale ou bilatérale de l'articulation temporomandibulaire",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LBQH001",
    designation:
      "Arthrographie unilatérale de l'articulation temporomandibulaire",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LBQH003",
    designation:
      "Arthrographie bilatérale de l'articulation temporomandibulaire",
    tarifSecteur1Optam: "114.38",
    tarifHorsSecteur1HorsOptam: "114.38",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQK002",
    designation:
      "Scanographie unilatérale ou bilatérale de la partie pétreuse de l'os temporal [rocher] et de l'oreille moyenne",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQK011",
    designation:
      "Scanographie unilatérale ou bilatérale de l'angle pontocérébelleux et/ou du méat acoustique interne [conduit auditif interne]",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQK013",
    designation: "Scanographie de la face",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQK009",
    designation:
      "Scanographie de la face avec scanographie des tissus mous du cou",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LBQH002",
    designation:
      "Arthroscanographie unilatérale ou bilatérale de l'articulation temporomandibulaire",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQN001",
    designation:
      "Remnographie [IRM] de la face, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAQJ001",
    designation:
      "Remnographie [IRM] de la face, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LAHH004",
    designation:
      "Ponction ou cytoponction du crâne et/ou du massif facial, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "107.78",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAHH005",
    designation:
      "Ponction ou cytoponction du crâne et/ou du massif facial, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAHJ003",
    designation:
      "Ponction ou cytoponction du crâne et/ou du massif facial, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAHH002",
    designation:
      "Biopsie du crâne et/ou du massif facial, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "107.78",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAHH006",
    designation:
      "Biopsie du crâne et/ou du massif facial, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAHJ004",
    designation:
      "Biopsie du crâne et/ou du massif facial, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAHA001",
    designation: "Biopsie de la voûte du crâne [calvaria], par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAHA002",
    designation: "Biopsie d'un os de la face, par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA012",
    designation: "Ostéosynthèse d'une embarrure de la voûte du crâne",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA900",
    designation: "Craniectomie décompressive",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA011",
    designation: "Exérèse de tumeur de la voûte du crâne sans cranioplastie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA004",
    designation: "Exérèse de tumeur de la voûte du crâne avec cranioplastie",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA009",
    designation: "Cranioplastie de la voûte",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA008",
    designation: "Taille de volet crânien sans transposition",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA005",
    designation:
      "Taille et transposition de volet crânien n’intéressant pas les orbites, sans remodelage du pôle postérieur du crâne",
    tarifSecteur1Optam: "452.34",
    tarifHorsSecteur1HorsOptam: "437.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA016",
    designation:
      "Taille et transposition de volet crânien n’intéressant pas les orbites, avec remodelage du pôle postérieur du crâne",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "463.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA006",
    designation:
      "Taille et transposition de volet crânien intéressant les orbites",
    tarifSecteur1Optam: "650.86",
    tarifHorsSecteur1HorsOptam: "553.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA006",
    designation: "Remodelage du pôle postérieur du crâne, par abord direct",
    tarifSecteur1Optam: "465.81",
    tarifHorsSecteur1HorsOptam: "445.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LANC001",
    designation:
      "Meulage extracrânien de la voûte du crâne, par vidéochirurgie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA007",
    designation: "Ablation de matériel de cranioplastie de la voûte",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEA008",
    designation:
      "Réduction de fracture de la partie tympanique de l'os temporal [os tympanal]",
    tarifSecteur1Optam: "50.05",
    tarifHorsSecteur1HorsOptam: "45.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA001",
    designation:
      "Mise à plat de lésion infectieuse de la voûte du crâne [calvaria]",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA015",
    designation:
      "Mise à plat de lésion infectieuse postopératoire de la voûte du crâne [calvaria], par reprise de l'abord précédent",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA020",
    designation: "Ostéosynthèse de fracture craniofaciale",
    tarifSecteur1Optam: "275.01",
    tarifHorsSecteur1HorsOptam: "275.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA016",
    designation: "Ostéosynthèse de fracture cranioorbitaire",
    tarifSecteur1Optam: "392.79",
    tarifHorsSecteur1HorsOptam: "333.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA017",
    designation:
      "Ostéosynthèse de fracture cranioorbitaire avec fermeture de brèche ostéodurale",
    tarifSecteur1Optam: "550.22",
    tarifHorsSecteur1HorsOptam: "550.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA004",
    designation:
      "Ostéosynthèse de fracture unilatérale de la paroi antérieure du sinus frontal à foyer ouvert, par abord coronal",
    tarifSecteur1Optam: "285.73",
    tarifHorsSecteur1HorsOptam: "235.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA019",
    designation:
      "Ostéosynthèse de fracture unilatérale de la paroi antérieure du sinus frontal à foyer ouvert, par abord facial",
    tarifSecteur1Optam: "239.64",
    tarifHorsSecteur1HorsOptam: "209.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA014",
    designation:
      "Ostéosynthèse de fracture bilatérale de la paroi antérieure du sinus frontal à foyer ouvert, par abord coronal",
    tarifSecteur1Optam: "321.18",
    tarifHorsSecteur1HorsOptam: "256.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA015",
    designation:
      "Ostéosynthèse de fracture bilatérale de la paroi antérieure du sinus frontal à foyer ouvert, par abord facial",
    tarifSecteur1Optam: "231.42",
    tarifHorsSecteur1HorsOptam: "231.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA006",
    designation:
      "Ostéosynthèse de fracture frontonasale à foyer ouvert, par abord coronal",
    tarifSecteur1Optam: "243.35",
    tarifHorsSecteur1HorsOptam: "243.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA010",
    designation:
      "Ostéosynthèse de fracture frontonasale à foyer ouvert, par abord facial",
    tarifSecteur1Optam: "234.68",
    tarifHorsSecteur1HorsOptam: "206.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA011",
    designation:
      "Ostéosynthèse de fracture fronto-naso-maxillaire à foyer ouvert, par abord coronal",
    tarifSecteur1Optam: "313.19",
    tarifHorsSecteur1HorsOptam: "313.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA018",
    designation:
      "Ostéosynthèse de fracture fronto-naso-maxillaire à foyer ouvert par abord coronal, avec canthopexie",
    tarifSecteur1Optam: "390.91",
    tarifHorsSecteur1HorsOptam: "390.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA013",
    designation:
      "Ostéosynthèse de fracture fronto-naso-maxillaire à foyer ouvert, par abord facial",
    tarifSecteur1Optam: "264.25",
    tarifHorsSecteur1HorsOptam: "264.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA009",
    designation:
      "Ostéosynthèse de fracture fronto-naso-maxillaire à foyer ouvert par abord facial, avec canthopexie",
    tarifSecteur1Optam: "332.92",
    tarifHorsSecteur1HorsOptam: "332.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA005",
    designation:
      "Ostéosynthèse de fracture naso-ethmoïdo-maxillo-fronto-orbitaire, par abord coronal",
    tarifSecteur1Optam: "598.4",
    tarifHorsSecteur1HorsOptam: "447.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA005",
    designation: "Pétrectomie totale",
    tarifSecteur1Optam: "1245.09",
    tarifHorsSecteur1HorsOptam: "985.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBFA004",
    designation:
      "Résection totale unilatérale du sinus ethmoïdal avec exérèse de la lame criblée et de méninge, par abord paralatéronasal",
    tarifSecteur1Optam: "759.22",
    tarifHorsSecteur1HorsOptam: "598.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GBFA005",
    designation:
      "Résection totale unilatérale du sinus ethmoïdal avec sinusotomie sphénoïdale et maxillaire, par abord paralatéronasal",
    tarifSecteur1Optam: "508.97",
    tarifHorsSecteur1HorsOptam: "453.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA001",
    designation:
      "Résection fronto-ethmoïdo-sphénoïdo-maxillaire, par abord paralatéronasal",
    tarifSecteur1Optam: "727.65",
    tarifHorsSecteur1HorsOptam: "580.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA006",
    designation:
      "Résection fronto-ethmoïdo-sphénoïdo-maxillaire avec exérèse de la lame criblée et de méninge, par abord paralatéronasal",
    tarifSecteur1Optam: "947.87",
    tarifHorsSecteur1HorsOptam: "707.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LARA002",
    designation:
      "Mobilisation partielle d'un cadre de l'orbite, par abord extracrânien",
    tarifSecteur1Optam: "684.89",
    tarifHorsSecteur1HorsOptam: "485.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LARA001",
    designation:
      "Mobilisation partielle des cadres de l'orbite horizontalement ou par bipartition faciale, par abord extracrânien",
    tarifSecteur1Optam: "837.62",
    tarifHorsSecteur1HorsOptam: "837.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LARA004",
    designation: "Mobilisation totale d'un cadre de l'orbite, par craniotomie",
    tarifSecteur1Optam: "457.88",
    tarifHorsSecteur1HorsOptam: "457.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LARA003",
    designation:
      "Mobilisation totale des cadres de l'orbite horizontalement ou par bipartition faciale, par craniotomie",
    tarifSecteur1Optam: "864.27",
    tarifHorsSecteur1HorsOptam: "676.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEA002",
    designation:
      "Avancement frontoorbitaire symétrique ou asymétrique sans remodelage frontal, par craniotomie",
    tarifSecteur1Optam: "486.86",
    tarifHorsSecteur1HorsOptam: "486.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEA006",
    designation:
      "Avancement frontoorbitaire symétrique ou asymétrique avec remodelage frontal, par craniotomie",
    tarifSecteur1Optam: "951.48",
    tarifHorsSecteur1HorsOptam: "727.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LABA001",
    designation:
      "Apposition modelante de l'étage supérieur de la face, par abord coronal",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "237.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LABA003",
    designation:
      "Apposition modelante de l'étage supérieur de la face, par abord facial",
    tarifSecteur1Optam: "167.32",
    tarifHorsSecteur1HorsOptam: "167.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEP002",
    designation:
      "Réduction orthopédique de fracture de l'os nasal [des os propres du nez]",
    tarifSecteur1Optam: "63.91",
    tarifHorsSecteur1HorsOptam: "53.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEP003",
    designation: "Réduction orthopédique de traumatisme nasal obstétrical",
    tarifSecteur1Optam: "59.29",
    tarifHorsSecteur1HorsOptam: "50.51",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LAEP001",
    designation:
      "Réduction orthopédique de fracture de l'os zygomatique [malaire] ou de l'arcade zygomatique",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBED001",
    designation:
      "Réduction orthopédique de fracture occlusofaciale [fracture type Le Fort I, II ou III], avec contention par blocage maxillomandibulaire",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBED004",
    designation:
      "Réduction orthopédique de disjonction intermaxillaire ou de fracture du palais, avec contention par blocage maxillomandibulaire",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBEP009",
    designation:
      "Réduction orthopédique de disjonction intermaxillaire ou de fracture du palais, avec contention par appareillage personnalisé",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEA007",
    designation: "Réduction de fracture de l'os nasal, par abord direct",
    tarifSecteur1Optam: "58.52",
    tarifHorsSecteur1HorsOptam: "50.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEA001",
    designation:
      "Réduction de fracture du bord supraorbitaire sans interposition, par abord sourcilier",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEA003",
    designation:
      "Réduction de fracture du bord supraorbitaire avec interposition de matériau inerte et/ou autogreffe, par abord sourcilier",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAEB001",
    designation:
      "Réduction de fracture du plancher de l'orbite à foyer fermé, par ballonnet intrasinusien introduit par abord intrabuccal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA007",
    designation: "Réfection du plancher de l'orbite, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA005",
    designation: "Réfection de la paroi médiale de l'orbite, par abord coronal",
    tarifSecteur1Optam: "229.65",
    tarifHorsSecteur1HorsOptam: "229.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA003",
    designation: "Réfection de la paroi médiale de l'orbite, par abord facial",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA008",
    designation:
      "Réfection de paroi de l'orbite avec réparation d'un délabrement de la région orbitaire et de son contenu",
    tarifSecteur1Optam: "452.35",
    tarifHorsSecteur1HorsOptam: "350.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA001",
    designation:
      "Ostéosynthèse de fracture du bord infraorbitaire, à foyer ouvert",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACB002",
    designation:
      "Ostéosynthèse de fracture de l'os zygomatique [malaire] ou de l'arcade zygomatique, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA002",
    designation:
      "Ostéosynthèse de fracture de l'os zygomatique [malaire], à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA003",
    designation:
      "Ostéosynthèse de fracture de l'os zygomatique [malaire] avec réfection du plancher de l'orbite, par abord direct",
    tarifSecteur1Optam: "343.99",
    tarifHorsSecteur1HorsOptam: "343.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA008",
    designation:
      "Ostéosynthèse de fractures latérofaciales multiples à foyer ouvert, par abord coronal",
    tarifSecteur1Optam: "333.94",
    tarifHorsSecteur1HorsOptam: "294.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACA007",
    designation:
      "Ostéosynthèse de fractures latérofaciales multiples à foyer ouvert, par abord facial",
    tarifSecteur1Optam: "258.08",
    tarifHorsSecteur1HorsOptam: "250.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACB001",
    designation: "Ostéosynthèse de fracture maxillonasale, à foyer fermé",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA010",
    designation:
      "Ostéosynthèse de disjonction intermaxillaire ou de fracture du palais, par abord intrabuccal",
    tarifSecteur1Optam: "187.89",
    tarifHorsSecteur1HorsOptam: "179.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA003",
    designation:
      "Ostéosynthèse et/ou suspension faciale pour fracture occlusofaciale de type Le Fort I, par abord direct",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "200.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA009",
    designation:
      "Ostéosynthèse et/ou suspension faciale pour fracture occlusofaciale de type Le Fort II, par abord direct",
    tarifSecteur1Optam: "229.77",
    tarifHorsSecteur1HorsOptam: "229.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA005",
    designation:
      "Ostéosynthèse et/ou suspension faciale pour fracture occlusofaciale de type Le Fort III, par abord direct",
    tarifSecteur1Optam: "236.36",
    tarifHorsSecteur1HorsOptam: "236.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LACB003",
    designation:
      "Ostéosynthèse de fracture panfaciale instable par appareillage à ancrage péricrânien, à foyer fermé",
    tarifSecteur1Optam: "301.49",
    tarifHorsSecteur1HorsOptam: "301.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA012",
    designation:
      "Décompression de l'orbite par effondrement de la paroi inférieure et/ou de la paroi médiale, par abord direct",
    tarifSecteur1Optam: "281.98",
    tarifHorsSecteur1HorsOptam: "233.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA003",
    designation:
      "Décompression de l'orbite par effondrement de trois parois, par abord direct",
    tarifSecteur1Optam: "322.15",
    tarifHorsSecteur1HorsOptam: "257.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA013",
    designation:
      "Ostéotomie d'agrandissement du cadre de l'orbite, par abord coronal",
    tarifSecteur1Optam: "371.12",
    tarifHorsSecteur1HorsOptam: "371.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA011",
    designation:
      "Ostéotomie d'agrandissement du cadre de l'orbite, par abord facial",
    tarifSecteur1Optam: "441.71",
    tarifHorsSecteur1HorsOptam: "343.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA027",
    designation:
      "Ostéotomie maxillaire totale basse préimplantaire ou préprothétique avec interposition, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA029",
    designation:
      "Ostéotomie maxillaire type Le Fort I non segmentée avec avancée, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA015",
    designation:
      "Ostéotomie maxillaire type Le Fort I non segmentée avec recul ou déplacement vertical, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA035",
    designation:
      "Ostéotomie maxillaire type Le Fort I segmentée en 2 fragments, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA006",
    designation:
      "Ostéotomie maxillaire type Le Fort I segmentée en 3 fragments ou plus, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA022",
    designation:
      "Ostéotomie maxillaire type hémi-Le Fort I, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA017",
    designation:
      "Ostéotomie maxillaire d'élévation sans mobilisation du palais, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA031",
    designation:
      "Ostéotomie maxillaire segmentaire antérieure, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA009",
    designation:
      "Ostéotomie maxillaire segmentaire postérieure unilatérale, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA008",
    designation:
      "Ostéotomie maxillaire segmentaire postérieure bilatérale, par abord intrabuccal",
    tarifSecteur1Optam: "531.23",
    tarifHorsSecteur1HorsOptam: "531.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA012",
    designation:
      "Ostéotomie maxillaire interalvéolaire [Corticotomie alvéolodentaire] unique ou multiple, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA032",
    designation:
      "Ostéotomie sagittale intermaxillaire sans pose d'un disjoncteur fixe, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA043",
    designation:
      "Ostéotomie sagittale intermaxillaire avec pose d'un disjoncteur fixe, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA010",
    designation:
      "Ostéotomie maxillozygomatique sans mobilisation de la pyramide nasale, par abord direct",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA038",
    designation: "Ostéotomie maxillonasale type Le Fort II, par abord direct",
    tarifSecteur1Optam: "626.76",
    tarifHorsSecteur1HorsOptam: "564.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA002",
    designation: "Ostéotomie de l'os zygomatique [malaire], par abord facial",
    tarifSecteur1Optam: "275.8",
    tarifHorsSecteur1HorsOptam: "265.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA010",
    designation: "Ostéotomie du tiers latéral de la face, par abord coronal",
    tarifSecteur1Optam: "455.89",
    tarifHorsSecteur1HorsOptam: "369.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA009",
    designation:
      "Ostéotomie craniofaciale extracrânienne type Le Fort III, par abord intrabuccal et par abord coronal",
    tarifSecteur1Optam: "695.85",
    tarifHorsSecteur1HorsOptam: "695.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAPA014",
    designation:
      "Ostéotomie craniofaciale extracrânienne type Le Fort III avec bipartition faciale, par abord intrabuccal et par abord coronal",
    tarifSecteur1Optam: "1198.92",
    tarifHorsSecteur1HorsOptam: "800.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA006",
    designation: "Maxillectomie supérieure sans reconstruction",
    tarifSecteur1Optam: "475.09",
    tarifHorsSecteur1HorsOptam: "380.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA011",
    designation:
      "Maxillectomie supérieure, avec reconstruction du plancher de l'orbite par lambeau de septum nasal",
    tarifSecteur1Optam: "521.29",
    tarifHorsSecteur1HorsOptam: "451.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA033",
    designation: "Maxillectomie moyenne",
    tarifSecteur1Optam: "475.09",
    tarifHorsSecteur1HorsOptam: "380.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA024",
    designation: "Maxillectomie inférieure",
    tarifSecteur1Optam: "359.59",
    tarifHorsSecteur1HorsOptam: "313.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA005",
    designation: "Maxillectomie totale sans reconstruction",
    tarifSecteur1Optam: "595.98",
    tarifHorsSecteur1HorsOptam: "451.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA007",
    designation:
      "Maxillectomie totale, avec reconstruction du plancher de l'orbite par lambeau de septum nasal",
    tarifSecteur1Optam: "615.23",
    tarifHorsSecteur1HorsOptam: "506.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA023",
    designation:
      "Exérèse de lésion de l'os maxillaire et/ou du corps de la mandibule de moins de 2 cm de grand axe, par abord intrabuccal",
    tarifSecteur1Optam: "58.14",
    tarifHorsSecteur1HorsOptam: "46.89",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBFA030",
    designation:
      "Exérèse de lésion de l'os maxillaire et/ou du corps de la mandibule de 2 cm à 4 cm de grand axe, par abord intrabuccal",
    tarifSecteur1Optam: "80.83",
    tarifHorsSecteur1HorsOptam: "73.21",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBFA002",
    designation:
      "Exérèse de lésion de l'os maxillaire et/ou du corps de la mandibule de plus de 4 cm de grand axe, par abord intrabuccal",
    tarifSecteur1Optam: "121.95",
    tarifHorsSecteur1HorsOptam: "114.62",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBFA028",
    designation: "Exérèse d'un quadrant de la voûte palatine, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA014",
    designation: "Exérèse d'une hémivoûte palatine, par abord direct",
    tarifSecteur1Optam: "177.96",
    tarifHorsSecteur1HorsOptam: "173.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA001",
    designation:
      "Exérèse de la totalité de la voûte palatine, par abord direct",
    tarifSecteur1Optam: "229.01",
    tarifHorsSecteur1HorsOptam: "203.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA012",
    designation:
      "Résection modelante de l'étage moyen de la face, par abord intrabuccal",
    tarifSecteur1Optam: "197.81",
    tarifHorsSecteur1HorsOptam: "184.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LABA004",
    designation:
      "Apposition modelante de l'étage moyen de la face, par abord direct",
    tarifSecteur1Optam: "202.07",
    tarifHorsSecteur1HorsOptam: "187.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA012",
    designation:
      "Comblement d'une dépression de la région orbitofaciale, sans exentération de l'orbite, par matériau inerte ou autogreffe",
    tarifSecteur1Optam: "330.34",
    tarifHorsSecteur1HorsOptam: "323.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA004",
    designation:
      "Remodelage du rebord de l'orbite sans autogreffe, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA010",
    designation:
      "Remodelage du rebord de l'orbite avec autogreffe osseuse, par abord direct",
    tarifSecteur1Optam: "229.72",
    tarifHorsSecteur1HorsOptam: "221.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA001",
    designation:
      "Reconstruction du tiers latéral du massif facial pour syndrome malformatif, par abord facial et par abord coronal",
    tarifSecteur1Optam: "507.64",
    tarifHorsSecteur1HorsOptam: "426.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA011",
    designation:
      "Réparation d'une fente craniofaciale impliquant l’orbite sans abord de l’étage antérieur de la base du crâne",
    tarifSecteur1Optam: "1186.87",
    tarifHorsSecteur1HorsOptam: "820.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAMA002",
    designation:
      "Réparation d'une fente craniofaciale impliquant l’orbite avec abord de l’étage antérieur de la base du crâne",
    tarifSecteur1Optam: "1566.18",
    tarifHorsSecteur1HorsOptam: "1040.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBEP002",
    designation:
      "Réduction orthopédique de fracture du corps de la mandibule, avec contention par appareillage personnalisé",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBED002",
    designation:
      "Réduction orthopédique de fracture unifocale du corps de la mandibule, avec contention mandibulaire",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBED005",
    designation:
      "Réduction orthopédique de fracture plurifocale du corps de la mandibule, avec contention mandibulaire",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBED006",
    designation:
      "Réduction orthopédique de fracture extracondylaire unifocale de la mandibule, avec blocage maxillomandibulaire",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBED003",
    designation:
      "Réduction orthopédique de fracture extracondylaire plurifocale de la mandibule, avec blocage maxillomandibulaire",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCB002",
    designation:
      "Ostéosynthèse de fracture extracondylaire unifocale de la mandibule, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA006",
    designation:
      "Ostéosynthèse de fracture extracondylaire unifocale de la mandibule, à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCB001",
    designation:
      "Ostéosynthèse de fracture extracondylaire plurifocale de la mandibule, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA002",
    designation:
      "Ostéosynthèse de fracture extracondylaire plurifocale de la mandibule, à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA001",
    designation:
      "Ostéosynthèse de fracture extracondylaire bifocale de la mandibule, à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA004",
    designation:
      "Ostéosynthèse de fracture du condyle de la mandibule à foyer ouvert, sans dissection du nerf facial",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA007",
    designation:
      "Ostéosynthèse de fracture du condyle de la mandibule à foyer ouvert, avec dissection du nerf facial",
    tarifSecteur1Optam: "351.66",
    tarifHorsSecteur1HorsOptam: "304.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBCA008",
    designation:
      "Ostéosynthèse de fracture comminutive de la mandibule avec parage d'un délabrement des tissus mous de la face",
    tarifSecteur1Optam: "270.84",
    tarifHorsSecteur1HorsOptam: "270.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBBA006",
    designation:
      "Comblement de perte de substance mandibulaire interruptrice segmentaire antérieure limitée au corps, par abord intrabuccal ou facial",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBBA004",
    designation:
      "Comblement de perte de substance mandibulaire interruptrice segmentaire latérale respectant la région articulaire, par abord intrabuccal ou facial",
    tarifSecteur1Optam: "325.43",
    tarifHorsSecteur1HorsOptam: "320.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBBA003",
    designation:
      "Apposition modelante de la mandibule, par abord intrabuccal ou facial",
    tarifSecteur1Optam: "186.47",
    tarifHorsSecteur1HorsOptam: "178.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBBA005",
    designation: "Apposition modelante du menton, par abord intrabuccal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA034",
    designation:
      "Ostéotomie segmentaire antérieure préimplantaire ou préprothétique de la mandibule avec interposition, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA030",
    designation:
      "Ostéotomie verticale rétrolingulaire [rétrospigienne] du ramus de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "250.28",
    tarifHorsSecteur1HorsOptam: "232.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA007",
    designation:
      "Ostéotomie verticale rétrolingulaire [rétrospigienne] du ramus de la mandibule, par abord facial",
    tarifSecteur1Optam: "250.28",
    tarifHorsSecteur1HorsOptam: "215.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA021",
    designation:
      "Ostéotomie sagittale unilatérale du ramus de la mandibule [supralingulaire et préangulaire], par abord intrabuccal",
    tarifSecteur1Optam: "258.79",
    tarifHorsSecteur1HorsOptam: "237.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA042",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA004",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie maxillaire d'avancée type Le Fort I non segmentée, par abord intrabuccal",
    tarifSecteur1Optam: "695.53",
    tarifHorsSecteur1HorsOptam: "692.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA040",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie maxillaire d'avancée type Le Fort I non segmentée et ostéotomie de transposition du menton osseux, par abord intrabuccal",
    tarifSecteur1Optam: "808.97",
    tarifHorsSecteur1HorsOptam: "758.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA023",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie maxillaire type Le Fort I segmentée en 2 fragments, par abord intrabuccal",
    tarifSecteur1Optam: "718.93",
    tarifHorsSecteur1HorsOptam: "705.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA011",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie maxillaire type Le Fort I segmentée en 2 fragments et transposition du menton osseux, par abord intrabuccal",
    tarifSecteur1Optam: "882.71",
    tarifHorsSecteur1HorsOptam: "800.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA020",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie maxillaire type Le Fort I segmentée en 3 fragments ou plus, par abord intrabuccal",
    tarifSecteur1Optam: "836.62",
    tarifHorsSecteur1HorsOptam: "774.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA033",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie maxillaire type Le Fort I segmentée en 3 fragments ou plus et transposition du menton osseux, par abord intrabuccal",
    tarifSecteur1Optam: "956.44",
    tarifHorsSecteur1HorsOptam: "843.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA019",
    designation:
      "Ostéotomie sagittale bilatérale du ramus de la mandibule [supralingulaire et préangulaire], avec ostéotomie segmentaire alvéolaire de la mandibule en avant des trous mentonniers, par abord intrabuccal",
    tarifSecteur1Optam: "635.73",
    tarifHorsSecteur1HorsOptam: "635.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA041",
    designation:
      "Ostéotomie angulaire ou préangulaire de la mandibule, par abord facial",
    tarifSecteur1Optam: "218.68",
    tarifHorsSecteur1HorsOptam: "218.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA014",
    designation:
      "Ostéotomie interruptrice du corps de la mandibule, en arrière du trou mentonnier, par abord intrabuccal",
    tarifSecteur1Optam: "266.58",
    tarifHorsSecteur1HorsOptam: "242.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA005",
    designation:
      "Ostéotomie interruptrice du corps de la mandibule, en avant du trou mentonnier, par abord intrabuccal",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "217.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA037",
    designation:
      "Ostéotomie interruptrice de la symphyse mandibulaire, par abord intrabuccal",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA002",
    designation:
      "Ostéotomie sagittale du corps de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "272.26",
    tarifHorsSecteur1HorsOptam: "245.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA016",
    designation:
      "Ostéotomie segmentaire alvéolaire de la mandibule en arrière du trou mentonnier, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA026",
    designation:
      "Ostéotomie segmentaire alvéolaire de la mandibule en avant des trous mentonniers, par abord intrabuccal",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA039",
    designation:
      "Ostéotomie segmentaire alvéolaire de la mandibule en avant des trous mentonniers, avec ostéotomie de transposition du menton osseux, par abord intrabuccal",
    tarifSecteur1Optam: "426.73",
    tarifHorsSecteur1HorsOptam: "426.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA036",
    designation:
      "Ostéotomie segmentaire alvéolaire de la mandibule en avant des trous mentonniers, avec ostéotomie maxillaire segmentaire antérieure, par abord intrabuccal",
    tarifSecteur1Optam: "531.23",
    tarifHorsSecteur1HorsOptam: "531.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA018",
    designation:
      "Ostéotomie sousalvéolaire totale de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA013",
    designation:
      "Ostéotomie de transposition du menton osseux, par abord intrabuccal",
    tarifSecteur1Optam: "170.87",
    tarifHorsSecteur1HorsOptam: "142.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA001",
    designation:
      "Ostéotomie d'antépositionnement des épines mentonnières [apophyses geni] de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA025",
    designation:
      "Ostéotomie de la mandibule pour cal vicieux, par abord direct",
    tarifSecteur1Optam: "302.03",
    tarifHorsSecteur1HorsOptam: "262.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBAA001",
    designation:
      "Allongement osseux progressif de l'os maxillaire ou de la mandibule par distracteur, par abord direct",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBGA005",
    designation: "Évidement de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA015",
    designation:
      "Exérèse de lésion du ramus de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA018",
    designation: "Résection modelante de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "176.54",
    tarifHorsSecteur1HorsOptam: "172.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA010",
    designation: "Résection modelante du menton, par abord intrabuccal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA008",
    designation:
      "Résection non interruptrice extracondylaire de la mandibule, par abord direct",
    tarifSecteur1Optam: "142.51",
    tarifHorsSecteur1HorsOptam: "126.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA029",
    designation:
      "Résection interruptrice segmentaire antérieure du corps de la mandibule par abord direct, sans pose de dispositif intrafocal de contention ni comblement",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBFA020",
    designation:
      "Résection interruptrice segmentaire antérieure du corps de la mandibule par abord direct, avec pose de dispositif intrafocal de contention, sans comblement",
    tarifSecteur1Optam: "289.98",
    tarifHorsSecteur1HorsOptam: "273.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA009",
    designation:
      "Résection interruptrice segmentaire antérieure du corps de la mandibule avec comblement, par abord direct",
    tarifSecteur1Optam: "423.27",
    tarifHorsSecteur1HorsOptam: "385.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA004",
    designation:
      "Résection de la mandibule pour pseudarthrose avec comblement par autogreffe osseuse, par abord direct",
    tarifSecteur1Optam: "386.41",
    tarifHorsSecteur1HorsOptam: "368.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA036",
    designation:
      "Résection interruptrice extracondylaire segmentaire latérale de la mandibule par abord direct, sans pose de dispositif intrafocal de contention ni comblement",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA027",
    designation:
      "Résection interruptrice extracondylaire segmentaire latérale de la mandibule par abord direct, avec pose de dispositif intrafocal de contention, sans comblement",
    tarifSecteur1Optam: "285.02",
    tarifHorsSecteur1HorsOptam: "270.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA017",
    designation:
      "Résection interruptrice extracondylaire segmentaire latérale de la mandibule avec comblement, par abord direct",
    tarifSecteur1Optam: "428.95",
    tarifHorsSecteur1HorsOptam: "389.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA034",
    designation:
      "Résection interruptrice latéroterminale de la mandibule par abord direct, sans pose de dispositif latéroterminal ni comblement",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA037",
    designation:
      "Résection interruptrice latéroterminale de la mandibule par abord direct, avec pose de dispositif latéroterminal de contention, sans comblement",
    tarifSecteur1Optam: "350.42",
    tarifHorsSecteur1HorsOptam: "350.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA039",
    designation:
      "Résection interruptrice latéroterminale de la mandibule par abord direct, avec comblement par autogreffe chondrocostale",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA026",
    designation:
      "Résection interruptrice du processus condylaire de la mandibule par abord direct, sans pose de dispositif terminal ni comblement",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA022",
    designation:
      "Résection interruptrice du processus condylaire de la mandibule par abord direct, avec pose de dispositif terminal, sans comblement",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "284.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA038",
    designation:
      "Résection interruptrice du processus condylaire de la mandibule par abord direct, avec comblement par autogreffe chondrocostale",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBPA003",
    designation: "Décortication de la mandibule, par abord intrabuccal",
    tarifSecteur1Optam: "148.18",
    tarifHorsSecteur1HorsOptam: "129.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBDD001",
    designation:
      "Blocage maxillomandibulaire avec ablation de matériel d'ostéosynthèse, pour traitement de pseudarthrose mandibulaire",
    tarifSecteur1Optam: "343.99",
    tarifHorsSecteur1HorsOptam: "343.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBMA002",
    designation:
      "Reconstruction du ramus de la mandibule par autogreffe ostéochondrale pour syndrome malformatif, par abord direct",
    tarifSecteur1Optam: "392.38",
    tarifHorsSecteur1HorsOptam: "392.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJH004",
    designation:
      "Évacuation de collection de la face, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "107.78",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QAJH001",
    designation:
      "Évacuation de collection de la face, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QAJH002",
    designation:
      "Drainage de collection de la face, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "148.95",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ADM",
  },
  {
    codeCCAM: "QAJH003",
    designation:
      "Drainage de collection de la face, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "51.84",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBLD075",
    designation: "Pose d'1 implant intraosseux intrabuccal, chez l'enfant",
    tarifSecteur1Optam: "438.9",
    tarifHorsSecteur1HorsOptam: "438.9",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD066",
    designation: "Pose de 2 implants intraosseux intrabuccaux chez l'enfant",
    tarifSecteur1Optam: "806.74",
    tarifHorsSecteur1HorsOptam: "806.74",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD281",
    designation: "Pose de 3 implants intraosseux intrabuccaux chez l'enfant",
    tarifSecteur1Optam: "1174.58",
    tarifHorsSecteur1HorsOptam: "1174.58",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD117",
    designation: "Pose de 4 implants intraosseux intrabuccaux chez l'enfant",
    tarifSecteur1Optam: "1542.42",
    tarifHorsSecteur1HorsOptam: "1542.42",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD015",
    designation: "Pose d'1 implant intraosseux intrabuccal, chez l'adulte",
    tarifSecteur1Optam: "422.18",
    tarifHorsSecteur1HorsOptam: "422.18",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD010",
    designation: "Pose de 2 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "785.84",
    tarifHorsSecteur1HorsOptam: "785.84",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD013",
    designation: "Pose de 3 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "1149.5",
    tarifHorsSecteur1HorsOptam: "1149.5",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD004",
    designation: "Pose de 4 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "1513.16",
    tarifHorsSecteur1HorsOptam: "1513.16",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD020",
    designation: "Pose de 5 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "1876.82",
    tarifHorsSecteur1HorsOptam: "1876.82",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD025",
    designation: "Pose de 6 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "2240.48",
    tarifHorsSecteur1HorsOptam: "2240.48",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD026",
    designation: "Pose de 7 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "2604.14",
    tarifHorsSecteur1HorsOptam: "2604.14",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD038",
    designation: "Pose de 8 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "2967.8",
    tarifHorsSecteur1HorsOptam: "2967.8",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD200",
    designation: "Pose de 9 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "3331.46",
    tarifHorsSecteur1HorsOptam: "3331.46",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD294",
    designation: "Pose de 10 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "3695.12",
    tarifHorsSecteur1HorsOptam: "3695.12",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD261",
    designation: "Pose de 11 implants intraosseux intrabuccaux ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LALA469",
    designation:
      "Pose d’un appareillage auditif à conduction osseuse dans l’os temporal",
    tarifSecteur1Optam: "251.7",
    tarifHorsSecteur1HorsOptam: "251.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA381",
    designation:
      "Ablation d'un appareillage auditif à conduction osseuse implanté dans l'os temporal ",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "106.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LALA002",
    designation:
      "Pose d'un implant intraosseux crânien ou facial pour fixation d'épithèse ou d'appareillage auditif ostéo-intégré",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LALA001",
    designation:
      "Pose de plusieurs implants intraosseux crâniens et/ou faciaux pour fixation d'épithèse",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LALB001",
    designation: "Pose de moyen de liaison sur implants crâniens et/ou faciaux",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBLD019",
    designation:
      "Pose de moyen de liaison sur 1 implant préprothétique intraosseux intrabuccal",
    tarifSecteur1Optam: "160.93",
    tarifHorsSecteur1HorsOptam: "160.93",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD073",
    designation:
      "Pose de moyen de liaison sur 2 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "309.32",
    tarifHorsSecteur1HorsOptam: "309.32",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD086",
    designation:
      "Pose de moyen de liaison sur 3 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "457.71",
    tarifHorsSecteur1HorsOptam: "457.71",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD193",
    designation:
      "Pose de moyen de liaison sur 4 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "606.1",
    tarifHorsSecteur1HorsOptam: "606.1",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD447",
    designation:
      "Pose de moyen de liaison sur 5 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "754.49",
    tarifHorsSecteur1HorsOptam: "754.49",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD270",
    designation:
      "Pose de moyen de liaison sur 6 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "902.88",
    tarifHorsSecteur1HorsOptam: "902.88",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD143",
    designation:
      "Pose de moyen de liaison sur 7 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "1051.27",
    tarifHorsSecteur1HorsOptam: "1051.27",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD235",
    designation:
      "Pose de moyen de liaison sur 8 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "1199.66",
    tarifHorsSecteur1HorsOptam: "1199.66",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD311",
    designation:
      "Pose de moyen de liaison sur 9 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "1348.05",
    tarifHorsSecteur1HorsOptam: "1348.05",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD214",
    designation:
      "Pose de moyen de liaison sur 10 implants préprothétiques intraosseux intrabuccaux",
    tarifSecteur1Optam: "1496.44",
    tarifHorsSecteur1HorsOptam: "1496.44",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD420",
    designation:
      "Pose de moyen de liaison sur 11 implants préprothétiques intraosseux intrabuccaux ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD034",
    designation: "Pose de barre de conjonction entre 2 implants intrabuccaux",
    tarifSecteur1Optam: "96.14",
    tarifHorsSecteur1HorsOptam: "96.14",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD057",
    designation:
      "Pose de barre de conjonction entre 3 implants intrabuccaux ou plus",
    tarifSecteur1Optam: "144.21",
    tarifHorsSecteur1HorsOptam: "144.21",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LABA002",
    designation:
      "Désépaississement des tissus de recouvrement d'un site implantaire",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LAPB451",
    designation:
      "Dégagement et activation d'1 implant intraosseux intrabuccal, chez l'enfant",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB311",
    designation:
      "Dégagement et activation de 2 implants intraosseux intrabuccaux, chez l'enfant",
    tarifSecteur1Optam: "163.02",
    tarifHorsSecteur1HorsOptam: "163.02",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB459",
    designation:
      "Dégagement et activation de 3 implants intraosseux intrabuccaux, chez l'enfant",
    tarifSecteur1Optam: "231.99",
    tarifHorsSecteur1HorsOptam: "231.99",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB408",
    designation:
      "Dégagement et activation de 4 implants intraosseux intrabuccaux, chez l'enfant",
    tarifSecteur1Optam: "300.96",
    tarifHorsSecteur1HorsOptam: "300.96",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB002",
    designation:
      "Dégagement et activation d'1 implant intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "87.78",
    tarifHorsSecteur1HorsOptam: "87.78",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB004",
    designation:
      "Dégagement et activation de 2 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "154.66",
    tarifHorsSecteur1HorsOptam: "154.66",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB003",
    designation:
      "Dégagement et activation de 3 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "221.54",
    tarifHorsSecteur1HorsOptam: "221.54",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB001",
    designation:
      "Dégagement et activation de 4 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "288.42",
    tarifHorsSecteur1HorsOptam: "288.42",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB005",
    designation:
      "Dégagement et activation de 5 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB006",
    designation:
      "Dégagement et activation de 6 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "422.18",
    tarifHorsSecteur1HorsOptam: "422.18",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB007",
    designation:
      "Dégagement et activation de 7 implants intraosseux intra buccaux, chez l'adulte",
    tarifSecteur1Optam: "489.06",
    tarifHorsSecteur1HorsOptam: "489.06",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB047",
    designation:
      "Dégagement et activation de 8 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "555.94",
    tarifHorsSecteur1HorsOptam: "555.94",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB030",
    designation:
      "Dégagement et activation de 9 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "622.82",
    tarifHorsSecteur1HorsOptam: "622.82",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB122",
    designation:
      "Dégagement et activation de 10 implants intraosseux intrabuccaux, chez l'adulte",
    tarifSecteur1Optam: "689.7",
    tarifHorsSecteur1HorsOptam: "689.7",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAPB288",
    designation:
      "Dégagement et activation de 11 implants intraosseux intrabuccaux ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAGA004",
    designation:
      "Ablation de matériel externe d'ostéosynthèse ou de distraction du crâne et/ou du massif facial",
    tarifSecteur1Optam: "88.63",
    tarifHorsSecteur1HorsOptam: "68.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA003",
    designation:
      "Ablation de matériel interne d'ostéosynthèse ou de distraction du massif facial sur un site, par abord direct",
    tarifSecteur1Optam: "81.54",
    tarifHorsSecteur1HorsOptam: "64.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA005",
    designation:
      "Ablation de matériel interne d'ostéosynthèse ou de distraction du massif facial sur 2 sites, par abord direct",
    tarifSecteur1Optam: "110.6",
    tarifHorsSecteur1HorsOptam: "90.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA002",
    designation:
      "Ablation de matériel interne d'ostéosynthèse du massif facial sur 3 sites ou plus, par abord direct",
    tarifSecteur1Optam: "121.95",
    tarifHorsSecteur1HorsOptam: "97.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LZGA001",
    designation:
      "Ablation de biomatériau de la face et/ou du cou, par abord direct",
    tarifSecteur1Optam: "81.54",
    tarifHorsSecteur1HorsOptam: "64.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA006",
    designation:
      "Ablation d'un implant intraosseux crânien ou facial sans résection osseuse, par abord direct",
    tarifSecteur1Optam: "44.67",
    tarifHorsSecteur1HorsOptam: "43.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAGA001",
    designation:
      "Ablation de plusieurs implants intraosseux crâniens et/ou faciaux sans résection osseuse, par abord facial",
    tarifSecteur1Optam: "58.85",
    tarifHorsSecteur1HorsOptam: "51.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBGA280",
    designation:
      "Ablation d'1 implant intraosseux intrabuccal avec résection osseuse, chez l'enfant",
    tarifSecteur1Optam: "87.78",
    tarifHorsSecteur1HorsOptam: "87.78",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA441",
    designation:
      "Ablation de 2 implants intraosseux intrabuccaux  avec résection osseuse, chez l'enfant",
    tarifSecteur1Optam: "106.59",
    tarifHorsSecteur1HorsOptam: "106.59",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA354",
    designation:
      "Ablation de 3 implants intraosseux intrabuccaux avec résection osseuse, chez l'enfant",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA049",
    designation:
      "Ablation de 4 implants intraosseux intrabuccaux avec résection osseuse, chez l'enfant",
    tarifSecteur1Optam: "144.21",
    tarifHorsSecteur1HorsOptam: "144.21",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA004",
    designation:
      "Ablation d'1 implant intraosseux intrabuccal avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "71.06",
    tarifHorsSecteur1HorsOptam: "71.06",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA003",
    designation:
      "Ablation de 2 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "85.69",
    tarifHorsSecteur1HorsOptam: "85.69",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA002",
    designation:
      "Ablation de 3 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "100.32",
    tarifHorsSecteur1HorsOptam: "100.32",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA006",
    designation:
      "Ablation de 4 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "114.95",
    tarifHorsSecteur1HorsOptam: "114.95",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA007",
    designation:
      "Ablation de 5 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "129.58",
    tarifHorsSecteur1HorsOptam: "129.58",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA008",
    designation:
      "Ablation de 6 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "144.21",
    tarifHorsSecteur1HorsOptam: "144.21",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA009",
    designation:
      "Ablation de 7 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "158.84",
    tarifHorsSecteur1HorsOptam: "158.84",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA139",
    designation:
      "Ablation de 8 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "173.47",
    tarifHorsSecteur1HorsOptam: "173.47",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA052",
    designation:
      "Ablation de 9 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA168",
    designation:
      "Ablation de 10 implants intraosseux intrabuccaux avec résection osseuse, chez l'adulte",
    tarifSecteur1Optam: "202.73",
    tarifHorsSecteur1HorsOptam: "202.73",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBGA113",
    designation:
      "Ablation de 11 implants intraosseux intrabuccaux ou plus, avec résection osseuse",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBFA031",
    designation: "Résection d'hypertrophie osseuse intrabuccale",
    tarifSecteur1Optam: "36.87",
    tarifHorsSecteur1HorsOptam: "34.55",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LAFA013",
    designation:
      "Exérèse partielle d'un os de la face sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBLB002",
    designation:
      "Infiltration périarticulaire temporomandibulaire, par voie transcutanée",
    tarifSecteur1Optam: "17.02",
    tarifHorsSecteur1HorsOptam: "13.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBLB001",
    designation:
      "Infiltration et/ou lavage de l'articulation temporomandibulaire, par voie transcutanée",
    tarifSecteur1Optam: "32.61",
    tarifHorsSecteur1HorsOptam: "22.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBEP005",
    designation:
      "Réduction orthopédique d'une luxation temporomandibulaire unilatérale ou bilatérale",
    tarifSecteur1Optam: "19.14",
    tarifHorsSecteur1HorsOptam: "15.49",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBEA001",
    designation:
      "Réduction d'une luxation temporomandibulaire, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ASC",
  },
  {
    codeCCAM: "LBFA032",
    designation:
      "Éminencectomie [Condyloplastie temporale] ou création de butée temporomandibulaire",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA025",
    designation: "Coronoïdectomie mandibulaire, par abord intrabuccal",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA003",
    designation:
      "Résection partielle de la tête du condyle de la mandibule, par abord facial",
    tarifSecteur1Optam: "170.87",
    tarifHorsSecteur1HorsOptam: "169.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBMA006",
    designation: "Condyloplastie mandibulaire, par arthrotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBMA001",
    designation:
      "Reconstruction de l'articulation temporomandibulaire par prothèse",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBMA004",
    designation:
      "Reconstruction du condyle de la mandibule par greffe ostéochondrale",
    tarifSecteur1Optam: "379.09",
    tarifHorsSecteur1HorsOptam: "379.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA013",
    designation: "Discectomie temporomandibulaire, par arthrotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBDA001",
    designation: "Discopexie temporomandibulaire, par arthrotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBMA003",
    designation: "Reconstruction discale temporomandibulaire, par arthrotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA019",
    designation:
      "Résection de bloc d'ankylose temporomandibulaire latérale, par abord direct",
    tarifSecteur1Optam: "210.57",
    tarifHorsSecteur1HorsOptam: "209.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA035",
    designation:
      "Résection d'un bloc d'ankylose temporomandibulaire complète, par abord direct",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA016",
    designation:
      "Résection d'un bloc d'ankylose temporomandibulaire complète avec reconstruction de l'articulation par prothèse, par abord direct",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "417.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBFA012",
    designation:
      "Résection d'un bloc d'ankylose temporomandibulaire complète avec reconstruction du condyle de la mandibule par greffe ostéochondrale, par abord direct",
    tarifSecteur1Optam: "641.65",
    tarifHorsSecteur1HorsOptam: "495.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBGA001",
    designation:
      "Ablation de corps étranger de l'articulation temporomandibulaire, par arthrotomie",
    tarifSecteur1Optam: "143.22",
    tarifHorsSecteur1HorsOptam: "118.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCPA001",
    designation:
      "Libération d'une rétraction des tissus mous de la région masticatrice, par abord direct",
    tarifSecteur1Optam: "191.24",
    tarifHorsSecteur1HorsOptam: "191.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA001",
    designation:
      "Excision nasogénienne avec myotomie ou myectomie de symétrisation faciale pour traitement palliatif de la paralysie faciale",
    tarifSecteur1Optam: "223.3",
    tarifHorsSecteur1HorsOptam: "199.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA002",
    designation:
      "Excision du muscle frontal controlatéral sain pour traitement palliatif de la paralysie faciale",
    tarifSecteur1Optam: "211.75",
    tarifHorsSecteur1HorsOptam: "193.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCMA001",
    designation:
      "Réanimation de l'hémiface paralysée par lambeau musculaire libre avec anastomoses vasculaires et nerveuses",
    tarifSecteur1Optam: "405.18",
    tarifHorsSecteur1HorsOptam: "405.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCLB001",
    designation: "Séance d'injection de toxine botulique au niveau de la face",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBEP004",
    designation:
      "Réduction d'un décalage antéropostérieur des arcades dentaires par dispositif orthopédique et/ou dispositif fonctionnel",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBEP001",
    designation:
      "Réduction d'un décalage postéroantérieur des arcades dentaires par dispositif orthopédique et/ou dispositif fonctionnel",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBEP008",
    designation:
      "Réduction d'un décalage transversal des arcades dentaires par dispositif orthopédique et/ou dispositif fonctionnel",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBEP007",
    designation:
      "Réduction d'un décalage vertical des arcades dentaires par dispositif orthopédique et/ou dispositif fonctionnel",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBEP003",
    designation:
      "Réduction d'une insuffisance transversale de l'os maxillaire par dispositif orthopédique et/ou dispositif fonctionnel",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "LBEP006",
    designation:
      "Réduction d'une insuffisance transversale de la mandibule par dispositif orthopédique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "DEN",
  },
  {
    codeCCAM: "HBLD057",
    designation:
      "Pose d'une plaque base résine pour guide radiologique préimplantaire dentaire, pour 1 arcade",
    tarifSecteur1Optam: "140.16",
    tarifHorsSecteur1HorsOptam: "140.16",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD078",
    designation:
      "Pose d'une plaque base résine pour guide radiologique préimplantaire dentaire pour 2 arcades",
    tarifSecteur1Optam: "264.96",
    tarifHorsSecteur1HorsOptam: "264.96",
    regroupement: "ADM",
  },
  {
    codeCCAM: "HBLD056",
    designation:
      "Pose d'une plaque base résine pour guide chirurgical préimplantaire dentaire, pour 1 arcade",
    tarifSecteur1Optam: "94.08",
    tarifHorsSecteur1HorsOptam: "94.08",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBLD084",
    designation:
      "Pose d'une plaque base résine pour guide chirurgical préimplantaire dentaire pour 2 arcades",
    tarifSecteur1Optam: "174.72",
    tarifHorsSecteur1HorsOptam: "174.72",
    regroupement: "IMP",
  },
  {
    codeCCAM: "HBMP001",
    designation:
      "Transformation d'un guide radiologique préimplantaire en guide chirurgical",
    tarifSecteur1Optam: "30.72",
    tarifHorsSecteur1HorsOptam: "30.72",
    regroupement: "IMP",
  },
  {
    codeCCAM: "LBLD014",
    designation:
      "Pose d'une orthèse métallique recouvrant totalement ou partiellement une arcade dentaire",
    tarifSecteur1Optam: "172.8",
    tarifHorsSecteur1HorsOptam: "172.8",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD011",
    designation:
      "Pose d'un appareillage par guide mandibulaire, vestibulaire ou lingual, sur 1 arcade",
    tarifSecteur1Optam: "90.04",
    tarifHorsSecteur1HorsOptam: "84.48",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD009",
    designation:
      "Pose d'un appareillage par guide mandibulaire, vestibulaire ou lingual, sur 2 arcades",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD018",
    designation: "Pose d'un appareillage par guide mandibulaire sagittal",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD003",
    designation:
      "Pose d'une gouttière maxillaire ou mandibulaire pour hémostase ou portetopique",
    tarifSecteur1Optam: "86.4",
    tarifHorsSecteur1HorsOptam: "86.4",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD006",
    designation:
      "Pose de gouttières maxillaire et mandibulaire pour hémostase ou portetopique",
    tarifSecteur1Optam: "172.8",
    tarifHorsSecteur1HorsOptam: "172.8",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD022",
    designation: "Pose de gouttière plombée de protection dentoosseuse",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD007",
    designation: "Pose d'un appareillage de protection dentomaxillaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD024",
    designation:
      "Pose d'une attelle portegreffon ou d'une endoprothèse, après résection mandibulaire interruptrice",
    tarifSecteur1Optam: "271.7",
    tarifHorsSecteur1HorsOptam: "271.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LBLD016",
    designation:
      "Pose d'un appareillage obturateur pour perte de substance maxillaire, chez un patient denté",
    tarifSecteur1Optam: "151.73",
    tarifHorsSecteur1HorsOptam: "112.19",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD023",
    designation:
      "Pose d'un appareillage obturateur pour perte de substance maxillaire, chez un patient édenté total",
    tarifSecteur1Optam: "185.66",
    tarifHorsSecteur1HorsOptam: "185.66",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLA001",
    designation:
      "Pose d'une prothèse obturatrice à étage ou d'une balle obturatrice souple après résection de l'os maxillaire",
    tarifSecteur1Optam: "288",
    tarifHorsSecteur1HorsOptam: "288",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD002",
    designation:
      "Pose d'un appareillage de mobilisation en latéralité et/ou en propulsion pour fracture unicondylaire ou bicondylaire de la mandibule",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD008",
    designation:
      "Pose d'un appareillage de mobilisation mandibulaire en rotation pure",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBLD005",
    designation:
      "Pose d'un appareillage de mobilisation mandibulaire en rotation et en propulsion",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LBLD021",
    designation:
      "Pose d'un appareillage actif pur de rééducation de la cinétique mandibulaire",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ADM",
  },
  {
    codeCCAM: "LBLD017",
    designation: "Pose d'un appareillage en propulsion mandibulaire",
    tarifSecteur1Optam: "150",
    tarifHorsSecteur1HorsOptam: "150",
    regroupement: "PA1",
  },
  {
    codeCCAM: "LBLD001",
    designation:
      "Pose d'un châssis métallique support de prothèse maxillofaciale [prothèse obturatrice]",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "ADM",
  },
  {
    codeCCAM: "QALP001",
    designation:
      "Pose d'un appareillage de compression pour prévention et/ou correction de cicatrices vicieuses cervicofaciales",
    tarifSecteur1Optam: "153.6",
    tarifHorsSecteur1HorsOptam: "153.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZAMP001",
    designation: "Confection d'un moulage facial",
    tarifSecteur1Optam: "48.21",
    tarifHorsSecteur1HorsOptam: "48.21",
    regroupement: "ADM",
  },
  {
    codeCCAM: "ZALP001",
    designation:
      "Pose d'une prothèse souple utilisant les contredépouilles naturelles pour ancrage de prothèse faciale",
    tarifSecteur1Optam: "200.13",
    tarifHorsSecteur1HorsOptam: "200.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZALP002",
    designation: "Pose d'une épithèse faciale plurirégionale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADM",
  },
  {
    codeCCAM: "AHQP005",
    designation:
      "Électromyographie du diaphragme par électrodes de surface, sans épreuve de stimulation du nerf phrénique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQP007",
    designation:
      "Électromyographie du diaphragme par électrodes de surface, avec épreuve de stimulation du nerf phrénique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB030",
    designation:
      "Électromyographie du diaphragme par électrodes aiguilles, sans épreuve de stimulation du nerf phrénique",
    tarifSecteur1Optam: "51.84",
    tarifHorsSecteur1HorsOptam: "51.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQB031",
    designation:
      "Électromyographie du diaphragme par électrodes aiguilles, avec épreuve de stimulation du nerf phrénique",
    tarifSecteur1Optam: "178.85",
    tarifHorsSecteur1HorsOptam: "140.02",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LLQD001",
    designation:
      "Mesure de la pression transdiaphragmatique par double ballonnet, par voie œsophagienne",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDQK001",
    designation:
      "Radiographie du segment cervical de la colonne vertébrale selon 1 ou 2 incidences",
    tarifSecteur1Optam: "31",
    tarifHorsSecteur1HorsOptam: "31",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LDQK002",
    designation:
      "Radiographie du segment cervical de la colonne vertébrale selon 3 incidences ou plus",
    tarifSecteur1Optam: "31",
    tarifHorsSecteur1HorsOptam: "31",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LDQK004",
    designation:
      "Radiographie du segment cervical et du segment thoracique de la colonne vertébrale ",
    tarifSecteur1Optam: "62",
    tarifHorsSecteur1HorsOptam: "62",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LDQK005",
    designation:
      "Radiographie du segment cervical et du segment lombal de la colonne vertébrale",
    tarifSecteur1Optam: "91.77",
    tarifHorsSecteur1HorsOptam: "91.77",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LEQK001",
    designation: "Radiographie du segment thoracique de la colonne vertébrale",
    tarifSecteur1Optam: "31.92",
    tarifHorsSecteur1HorsOptam: "31.92",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LEQK002",
    designation:
      "Radiographie du segment thoracique et du segment lombal de la colonne vertébrale",
    tarifSecteur1Optam: "74.48",
    tarifHorsSecteur1HorsOptam: "74.48",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LFQK002",
    designation:
      "Radiographie du segment lombal de la colonne vertébrale selon 1 à 3 incidences",
    tarifSecteur1Optam: "40.6",
    tarifHorsSecteur1HorsOptam: "40.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LFQK001",
    designation:
      "Radiographie du segment lombal de la colonne vertébrale selon 4 incidences ou plus",
    tarifSecteur1Optam: "40.6",
    tarifHorsSecteur1HorsOptam: "40.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LGQK001",
    designation: "Radiographie du sacrum et/ou du coccyx",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQK007",
    designation: "Radiographie de la colonne vertébrale en totalité",
    tarifSecteur1Optam: "101.08",
    tarifHorsSecteur1HorsOptam: "101.08",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQK004",
    designation:
      "Téléradiographie de la colonne vertébrale en totalité selon 1 incidence",
    tarifSecteur1Optam: "43.06",
    tarifHorsSecteur1HorsOptam: "43.06",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQK002",
    designation:
      "Téléradiographie de la colonne vertébrale en totalité selon 2 incidences",
    tarifSecteur1Optam: "52.9",
    tarifHorsSecteur1HorsOptam: "52.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQK003",
    designation:
      "Téléradiographie de la colonne vertébrale en totalité selon 2 incidences avec incidence segmentaire supplémentaire",
    tarifSecteur1Optam: "73.82",
    tarifHorsSecteur1HorsOptam: "73.82",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQH001",
    designation: "Arthrographie d'articulation vertébrale postérieure",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQH003",
    designation: "Discographie intervertébrale unique, par voie transcutanée",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQH005",
    designation:
      "Discographie intervertébrale unique par voie transcutanée, avec scanographie de la colonne vertébrale [Discoscanner]",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQH004",
    designation: "Discographie intervertébrale multiple, par voie transcutanée",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LJQK001",
    designation: "Radiographie du squelette du thorax",
    tarifSecteur1Optam: "23.94",
    tarifHorsSecteur1HorsOptam: "23.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LJQK015",
    designation:
      "Radiographie du sternum et/ou des articulations sternoclaviculaires",
    tarifSecteur1Optam: "23.94",
    tarifHorsSecteur1HorsOptam: "23.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQK001",
    designation:
      "Scanographie d'un segment de la colonne vertébrale, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQH006",
    designation:
      "Scanographie d'un segment de la colonne vertébrale, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQK005",
    designation:
      "Scanographie de plusieurs segments de la colonne vertébrale, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQH002",
    designation:
      "Scanographie de plusieurs segments de la colonne vertébrale, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQN001",
    designation:
      "Remnographie [IRM] d'un ou de deux segments de la colonne vertébrale et de son contenu, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQJ001",
    designation:
      "Remnographie [IRM] d'un ou de deux segments de la colonne vertébrale et de son contenu, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQN002",
    designation:
      "Remnographie [IRM] de 3 segments de la colonne vertébrale ou plus et de son contenu, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHQJ002",
    designation:
      "Remnographie [IRM] de 3 segments de la colonne vertébrale ou plus et de son contenu, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "LHHH001",
    designation:
      "Ponction ou cytoponction osseuse et/ou discale de la colonne vertébrale, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "39.36",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHHH003",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "93.59",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHHH002",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "47.52",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDHA002",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par cervicotomie antérolatérale",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "198.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEHC001",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par thoracoscopie",
    tarifSecteur1Optam: "223.19",
    tarifHorsSecteur1HorsOptam: "223.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEHA002",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par costotransversectomie",
    tarifSecteur1Optam: "214.12",
    tarifHorsSecteur1HorsOptam: "168.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEHA003",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par thoracotomie",
    tarifSecteur1Optam: "279",
    tarifHorsSecteur1HorsOptam: "279",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEHA001",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "344.25",
    tarifHorsSecteur1HorsOptam: "344.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFHC001",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "235.28",
    tarifHorsSecteur1HorsOptam: "235.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFHA001",
    designation:
      "Biopsie osseuse et/ou discale de la colonne vertébrale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "198.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHHA006",
    designation: "Biopsie de l'arc vertébral, par abord postérieur",
    tarifSecteur1Optam: "131.87",
    tarifHorsSecteur1HorsOptam: "120.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHHA007",
    designation:
      "Biopsie du corps vertébral et/ou du disque intervertébral, par abord postérieur ou transpédiculaire",
    tarifSecteur1Optam: "189.3",
    tarifHorsSecteur1HorsOptam: "153.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDHA001",
    designation:
      "Biopsie osseuse et/ou articulaire de l'atlas et/ou de l'axis, par abord intrabuccal ou par abord prémastoïdien",
    tarifSecteur1Optam: "249.91",
    tarifHorsSecteur1HorsOptam: "249.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJHB001",
    designation:
      "Biopsie osseuse du sternum et/ou des côtes, par voie transcutanée",
    tarifSecteur1Optam: "71.61",
    tarifHorsSecteur1HorsOptam: "45.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LJHA001",
    designation: "Biopsie osseuse du sternum et/ou des côtes, par abord direct",
    tarifSecteur1Optam: "168.74",
    tarifHorsSecteur1HorsOptam: "141.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHLH001",
    designation:
      "Infiltration anesthésique d'articulation vertébrale postérieure avec guidage radiologique, avec évaluation diagnostique et pronostique",
    tarifSecteur1Optam: "36.02",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH002",
    designation:
      "Spondyloplastie d'1 vertèbre, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "160.8",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH228",
    designation:
      "Spondyloplastie expansive [cyphoplastie] d’1 vertèbre, par voie transcutanée, avec guidage radiologique",
    tarifSecteur1Optam: "361.59",
    tarifHorsSecteur1HorsOptam: "361.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH197",
    designation:
      "Spondyloplastie d’1 vertèbre par implant intracorporéal par voie transcutanée, avec guidage radiologique",
    tarifSecteur1Optam: "388",
    tarifHorsSecteur1HorsOptam: "388",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH005",
    designation:
      "Spondyloplastie d'1 vertèbre, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH027",
    designation:
      "Spondyloplastie expansive [cyphoplastie] d'1 vertèbre, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "244.8",
    tarifHorsSecteur1HorsOptam: "244.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH238",
    designation:
      "Spondyloplastie d'1 vertèbre, par implant intracorporéal par voie transcutanée, avec guidage scanographique",
    tarifSecteur1Optam: "267",
    tarifHorsSecteur1HorsOptam: "267",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH391",
    designation:
      "Spondyloplastie de 2 vertèbres par implant intracorporéal par voie transcutanée, avec guidage radiologique",
    tarifSecteur1Optam: "406",
    tarifHorsSecteur1HorsOptam: "406",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH648",
    designation:
      "Spondyloplastie de 2 vertèbres par implant intracorporéal par voie transcutanée, avec guidage scanographique",
    tarifSecteur1Optam: "279",
    tarifHorsSecteur1HorsOptam: "279",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH444",
    designation:
      "Spondyloplastie de 2 ou de 3 vertèbres, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "215.07",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH140",
    designation:
      "Spondyloplastie expansive [cyphoplastie] de 2 ou de 3 vertèbres, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "404.13",
    tarifHorsSecteur1HorsOptam: "404.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH705",
    designation:
      "Spondyloplastie de plus de 2 vertèbres par implant intracorporéal par voie transcutanée, avec guidage radiologique",
    tarifSecteur1Optam: "434",
    tarifHorsSecteur1HorsOptam: "434",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH182",
    designation:
      "Spondyloplastie de 2 ou de 3 vertèbres, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: '"LHMH454',
  },
  {
    codeCCAM: '"',
    designation:
      "Spondyloplastie expansive [cyphoplastie] de 2 ou de 3 vertèbres, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "273.6",
    tarifHorsSecteur1HorsOptam: "273.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMH780",
    designation:
      " Spondyloplastie de plus de 2 vertèbres par implant intracorporéal par voie transcutanée, avec guidage scanographique",
    tarifSecteur1Optam: "298",
    tarifHorsSecteur1HorsOptam: "298",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHLB001",
    designation:
      "Infiltration thérapeutique d'articulation vertébrale postérieure, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "34.17",
    tarifHorsSecteur1HorsOptam: "34.17",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHLH003",
    designation:
      "Infiltration thérapeutique d'articulation vertébrale postérieure, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "33.5",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHLH002",
    designation:
      "Infiltration thérapeutique d'articulation vertébrale postérieure, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "31.2",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHNH001",
    designation:
      "Thermocoagulation de facettes articulaires de vertèbre, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHCA011",
    designation: "Ostéosynthèse de la colonne vertébrale par fixateur externe",
    tarifSecteur1Optam: "483.54",
    tarifHorsSecteur1HorsOptam: "412.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHCA002",
    designation:
      "Ostéosynthèse postérieure de la colonne vertébrale sans exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "450.22",
    tarifHorsSecteur1HorsOptam: "392.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHCA016",
    designation:
      "Ostéosynthèse postérieure de la colonne vertébrale sans exploration du contenu canalaire avec arthrodèse, par abord postérieur",
    tarifSecteur1Optam: "471.49",
    tarifHorsSecteur1HorsOptam: "405.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHCA010",
    designation:
      "Ostéosynthèse postérieure de la colonne vertébrale avec exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "542.39",
    tarifHorsSecteur1HorsOptam: "490.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA002",
    designation:
      "Ostéosynthèse et/ou arthrodèse postérieure de la jonction occipitocervicale sans exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "400.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA003",
    designation:
      "Ostéosynthèse postérieure de la jonction occipitocervicale avec exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "528.21",
    tarifHorsSecteur1HorsOptam: "481.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA009",
    designation:
      "Ostéosynthèse transpédiculaire de l'axis, par abord postérieur",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "368.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA006",
    designation:
      "Ostéosynthèse transarticulaire et/ou transpédiculaire de l'atlas et de l'axis, par abord postérieur",
    tarifSecteur1Optam: "460.14",
    tarifHorsSecteur1HorsOptam: "398.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA005",
    designation:
      "Ostéosynthèse interlamaire de l'atlas et de l'axis, par abord postérieur",
    tarifSecteur1Optam: "413.35",
    tarifHorsSecteur1HorsOptam: "371.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA010",
    designation:
      "Ostéosynthèse interlamaire entre deux vertèbres de la colonne cervicale de C2 à C7, par abord postérieur",
    tarifSecteur1Optam: "382.15",
    tarifHorsSecteur1HorsOptam: "353.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFCA002",
    designation:
      "Ostéosynthèse de la jonction lombosacrale sans exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "438.16",
    tarifHorsSecteur1HorsOptam: "385.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFCA001",
    designation:
      "Ostéosynthèse de la jonction lombosacrale avec exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "522.53",
    tarifHorsSecteur1HorsOptam: "478.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGCA001",
    designation: "Ostéosynthèse de fracture du sacrum, par abord postérieur",
    tarifSecteur1Optam: "413.35",
    tarifHorsSecteur1HorsOptam: "371.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHDA001",
    designation:
      "Arthrodèse postérieure ou épiphysiodèse de la colonne vertébrale sans exploration du contenu canalaire, par abord postérieur",
    tarifSecteur1Optam: "415.47",
    tarifHorsSecteur1HorsOptam: "372.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA012",
    designation:
      "Arthrodèse intercorporéale ou épiphysiodèse de la colonne vertébrale lombale ou lombosacrale, par abord postérieur",
    tarifSecteur1Optam: "492.05",
    tarifHorsSecteur1HorsOptam: "460.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHDA002",
    designation:
      "Arthrodèse intercorporéale de la colonne vertébrale avec arthrodèse postérieure, par abord postérolatéral",
    tarifSecteur1Optam: "598.4",
    tarifHorsSecteur1HorsOptam: "522.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA004",
    designation:
      "Arthrodèse intercorporéale de la colonne vertébrale lombale ou lombosacrale avec arthrodèse postérolatérale, par abord postérieur",
    tarifSecteur1Optam: "542.39",
    tarifHorsSecteur1HorsOptam: "490.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDDA001",
    designation:
      "Arthrodèse antérieure de la jonction occipitocervicale, par abord intrabuccal ou par cervicotomie antérolatérale",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "444.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA008",
    designation:
      "Ostéosynthèse de la dent de l'axis [apophyse odontoïde de C2], par abord intrabuccal",
    tarifSecteur1Optam: "471.64",
    tarifHorsSecteur1HorsOptam: "471.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA004",
    designation:
      "Ostéosynthèse de la dent de l'axis [apophyse odontoïde de C2], par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "533.88",
    tarifHorsSecteur1HorsOptam: "485.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA001",
    designation:
      "Ostéosynthèse transarticulaire bilatérale de l'atlas et de l'axis, par cervicotomie antérieure ou par cervicotomie antérolatérale bilatérale",
    tarifSecteur1Optam: "559.4",
    tarifHorsSecteur1HorsOptam: "500.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA007",
    designation:
      "Ostéosynthèse de la colonne vertébrale cervicale, par abord antérieur avec mandibulotomie",
    tarifSecteur1Optam: "501.6",
    tarifHorsSecteur1HorsOptam: "501.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA011",
    designation:
      "Ostéosynthèse et/ou arthrodèse antérieure de la colonne vertébrale sans exploration du contenu canalaire, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "450.22",
    tarifHorsSecteur1HorsOptam: "436.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECC001",
    designation:
      "Ostéosynthèse et/ou arthrodèse antérieure ou épiphysiodèse de la colonne vertébrale, par thoracoscopie",
    tarifSecteur1Optam: "664.33",
    tarifHorsSecteur1HorsOptam: "572.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECA003",
    designation:
      "Ostéosynthèse et/ou arthrodèse antérieure ou épiphysiodèse de la colonne vertébrale sans exploration du contenu canalaire, par thoracotomie",
    tarifSecteur1Optam: "521.12",
    tarifHorsSecteur1HorsOptam: "477.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECA006",
    designation:
      "Ostéosynthèse et/ou arthrodèse antérieure de la colonne vertébrale sans exploration du contenu canalaire, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "627.47",
    tarifHorsSecteur1HorsOptam: "539.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFCC001",
    designation:
      "Ostéosynthèse et/ou arthrodèse antérieure de la colonne vertébrale, par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "679.22",
    tarifHorsSecteur1HorsOptam: "582.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFCA005",
    designation:
      "Ostéosynthèse et/ou arthrodèse antérieure de la colonne vertébrale sans exploration du contenu canalaire, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "577.84",
    tarifHorsSecteur1HorsOptam: "510.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA013",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par cervicotomie antérieure ou par cervicotomie antérolatérale",
    tarifSecteur1Optam: "554.44",
    tarifHorsSecteur1HorsOptam: "497.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECA005",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par thoracotomie",
    tarifSecteur1Optam: "643.77",
    tarifHorsSecteur1HorsOptam: "548.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECA001",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "721.76",
    tarifHorsSecteur1HorsOptam: "594.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFCA004",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "672.13",
    tarifHorsSecteur1HorsOptam: "565.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHCA001",
    designation:
      "Ostéosynthèse de la colonne vertébrale sans exploration du contenu canalaire, par abord antérieur et par abord postérieur",
    tarifSecteur1Optam: "916.74",
    tarifHorsSecteur1HorsOptam: "773.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDCA012",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par cervicotomie antérieure ou antérolatérale et par abord postérieur",
    tarifSecteur1Optam: "1035.85",
    tarifHorsSecteur1HorsOptam: "842.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECA002",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par thoracotomie et par abord postérieur",
    tarifSecteur1Optam: "1297.47",
    tarifHorsSecteur1HorsOptam: "993.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LECA004",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par thoraco-phréno-laparotomie et par abord postérieur",
    tarifSecteur1Optam: "993.93",
    tarifHorsSecteur1HorsOptam: "993.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFCA003",
    designation:
      "Ostéosynthèse de la colonne vertébrale avec exploration du contenu canalaire, par laparotomie ou lombotomie et par abord postérieur",
    tarifSecteur1Optam: "1245.71",
    tarifHorsSecteur1HorsOptam: "963.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA005",
    designation:
      "Arthrodèse postérieure et/ou postérolatérale d'un spondylolisthésis lombal sans réduction, sans libération radiculaire, sans ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "482.12",
    tarifHorsSecteur1HorsOptam: "455.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA007",
    designation:
      "Arthrodèse postérieure et/ou postérolatérale d'un spondylolisthésis lombal sans réduction, sans libération radiculaire, avec ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "648.03",
    tarifHorsSecteur1HorsOptam: "551.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA003",
    designation:
      "Arthrodèse postérieure et/ou postérolatérale d'un spondylolisthésis lombal sans réduction, avec libération radiculaire, sans ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "592.02",
    tarifHorsSecteur1HorsOptam: "540.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA001",
    designation:
      "Arthrodèse postérieure et/ou postérolatérale d'un spondylolisthésis lombal sans réduction, avec libération radiculaire et ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "662.92",
    tarifHorsSecteur1HorsOptam: "538.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA006",
    designation:
      "Arthrodèse postérieure et/ou postérolatérale d'un spondylolisthésis lombal avec réduction, avec ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "989.06",
    tarifHorsSecteur1HorsOptam: "793.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA009",
    designation:
      "Arthrodèse intercorporéale d'un spondylolisthésis lombal avec réduction, avec ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "1139.36",
    tarifHorsSecteur1HorsOptam: "880.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA014",
    designation:
      "Arthrodèse intercorporéale d'un spondylolisthésis lombal à grand déplacement sans réduction, avec ostéosynthèse, par abord postérieur translombosacral",
    tarifSecteur1Optam: "566.49",
    tarifHorsSecteur1HorsOptam: "460.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA002",
    designation:
      "Arthrodèse intercorporéale d'un spondylolisthésis lombal à grand déplacement avec réduction, avec ostéosynthèse, par abord postérieur translombosacral",
    tarifSecteur1Optam: "1130.86",
    tarifHorsSecteur1HorsOptam: "875.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA013",
    designation:
      "Arthrodèse d'un spondylolisthésis lombal sans réduction, par laparotomie",
    tarifSecteur1Optam: "499.14",
    tarifHorsSecteur1HorsOptam: "465.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA011",
    designation:
      "Arthrodèse d'un spondylolisthésis lombal sans réduction, par laparotomie et par abord postérieur",
    tarifSecteur1Optam: "916.03",
    tarifHorsSecteur1HorsOptam: "772.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA010",
    designation:
      "Arthrodèse d'un spondylolisthésis lombal à grand déplacement avec réduction, avec ostéosynthèse, par laparotomie",
    tarifSecteur1Optam: "812.51",
    tarifHorsSecteur1HorsOptam: "690.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFDA008",
    designation:
      "Arthrodèse d'un spondylolisthésis lombal à grand déplacement avec réduction, avec ostéosynthèse, par laparotomie et par abord postérieur",
    tarifSecteur1Optam: "1018.83",
    tarifHorsSecteur1HorsOptam: "810.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA011",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale sans arthrodèse, par abord postérieur",
    tarifSecteur1Optam: "938.01",
    tarifHorsSecteur1HorsOptam: "763.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA006",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 3 à 5 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "812.51",
    tarifHorsSecteur1HorsOptam: "712.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEMA002",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 3 à 5 vertèbres, par thoracotomie",
    tarifSecteur1Optam: "1052.87",
    tarifHorsSecteur1HorsOptam: "852.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEMA003",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 3 à 5 vertèbres, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1197.5",
    tarifHorsSecteur1HorsOptam: "935.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFMA001",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 3 à 5 vertèbres, par lombotomie",
    tarifSecteur1Optam: "982.67",
    tarifHorsSecteur1HorsOptam: "811.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEMA004",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 6 vertèbres ou plus, par thoracotomie",
    tarifSecteur1Optam: "956.25",
    tarifHorsSecteur1HorsOptam: "956.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEMA001",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 6 vertèbres ou plus, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1419.42",
    tarifHorsSecteur1HorsOptam: "1086.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA003",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 6 à 9 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "1003.24",
    tarifHorsSecteur1HorsOptam: "845.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA013",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 6 à 9 vertèbres par abord postérieur, avec résection de 3 côtes ou plus",
    tarifSecteur1Optam: "1087.61",
    tarifHorsSecteur1HorsOptam: "907.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA004",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 6 à 9 vertèbres, par abord postérolatéral",
    tarifSecteur1Optam: "1003.24",
    tarifHorsSecteur1HorsOptam: "845.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA015",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 10 vertèbres ou plus, par abord postérieur",
    tarifSecteur1Optam: "1221.61",
    tarifHorsSecteur1HorsOptam: "971.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA014",
    designation:
      "Correction instrumentale d'une déformation souple de la colonne vertébrale avec arthrodèse de 10 vertèbres ou plus par abord postérieur, avec résection de 3 côtes ou plus",
    tarifSecteur1Optam: "1265.57",
    tarifHorsSecteur1HorsOptam: "1010.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA001",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse et correction instrumentale, sur 3 à 5 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "1047.9",
    tarifHorsSecteur1HorsOptam: "849.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA029",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse et correction instrumentale, sur 6 à 9 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "1351.35",
    tarifHorsSecteur1HorsOptam: "1047.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA028",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse et correction instrumentale, sur 10 vertèbres ou plus, par abord postérieur",
    tarifSecteur1Optam: "1517.97",
    tarifHorsSecteur1HorsOptam: "1143.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA003",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse et correction instrumentale, sur 10 vertèbres ou plus, par abord postérieur, avec résection de 3 côtes ou plus",
    tarifSecteur1Optam: "1584.62",
    tarifHorsSecteur1HorsOptam: "1195.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA027",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse, correction instrumentale et ostéotomie antérieure transpédiculaire, sur 3 à 5 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "1224.44",
    tarifHorsSecteur1HorsOptam: "951.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA013",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse, correction instrumentale et ostéotomie antérieure transpédiculaire, sur 6 à 9 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "1399.57",
    tarifHorsSecteur1HorsOptam: "1075.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA025",
    designation:
      "Arthrectomie totale bilatérale et/ou ostéotomie postérieure pour déformation rigide de la colonne vertébrale avec arthrodèse, correction instrumentale et ostéotomie antérieure transpédiculaire, sur 10 vertèbres ou plus, par abord postérieur",
    tarifSecteur1Optam: "1574.69",
    tarifHorsSecteur1HorsOptam: "1176.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA002",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale avec arthrodèse, sans correction instrumentale, sur 1 à 3 vertèbres, par thoracotomie",
    tarifSecteur1Optam: "709.71",
    tarifHorsSecteur1HorsOptam: "631.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA001",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale avec arthrodèse, sans correction instrumentale, sur 1 à 3 vertèbres, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "771.39",
    tarifHorsSecteur1HorsOptam: "666.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA009",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale avec arthrodèse, sans correction instrumentale, sur 1 à 3 vertèbres, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "721.76",
    tarifHorsSecteur1HorsOptam: "638.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA006",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale avec arthrodèse, sans correction instrumentale, sur 4 vertèbres ou plus, par thoracotomie",
    tarifSecteur1Optam: "1136.53",
    tarifHorsSecteur1HorsOptam: "900.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA005",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale avec arthrodèse, sans correction instrumentale, sur 4 vertèbres ou plus, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1194.67",
    tarifHorsSecteur1HorsOptam: "934.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFPA003",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale avec arthrodèse, sans correction instrumentale, sur 4 vertèbres ou plus, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "1088.32",
    tarifHorsSecteur1HorsOptam: "872.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA008",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, par cervicotomie",
    tarifSecteur1Optam: "812.51",
    tarifHorsSecteur1HorsOptam: "690.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA010",
    designation:
      "Ostéotomie ou arthrectomie occipitoaxoïdienne pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, par abord intrabuccal ou par cervicotomie",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA009",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, par cervicothoracotomie",
    tarifSecteur1Optam: "1224.44",
    tarifHorsSecteur1HorsOptam: "951.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA003",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, sur 1 à 3 vertèbres, par thoracotomie",
    tarifSecteur1Optam: "916.03",
    tarifHorsSecteur1HorsOptam: "750.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA008",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, sur 1 à 3 vertèbres, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "977.71",
    tarifHorsSecteur1HorsOptam: "786.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFPA001",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, sur 1 à 3 vertèbres, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "874.91",
    tarifHorsSecteur1HorsOptam: "726.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA007",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, sur 4 vertèbres ou plus, par thoracotomie",
    tarifSecteur1Optam: "1322.99",
    tarifHorsSecteur1HorsOptam: "1008.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEPA004",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, sur 4 vertèbres ou plus, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1407.37",
    tarifHorsSecteur1HorsOptam: "1057.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFPA002",
    designation:
      "Ostéotomie antérieure ou discectomie totale pour déformation rigide de la colonne vertébrale, avec arthrodèse et correction instrumentale, sur 4 vertèbres ou plus, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "1272.66",
    tarifHorsSecteur1HorsOptam: "979.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA003",
    designation:
      "Décompression médullaire pour malformation de la jonction occipitocervicale, sans ouverture durale, par abord postérieur",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA001",
    designation:
      "Décompression médullaire pour malformation de la jonction occipitocervicale, avec ouverture durale, par abord postérieur",
    tarifSecteur1Optam: "484.25",
    tarifHorsSecteur1HorsOptam: "456.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA004",
    designation:
      "Décompression médullaire pour malformation de la jonction occipitocervicale, avec ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "492.05",
    tarifHorsSecteur1HorsOptam: "460.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA005",
    designation:
      "Décompression médullaire pour malformation de la jonction occipitocervicale, par abord intrabuccal",
    tarifSecteur1Optam: "694.11",
    tarifHorsSecteur1HorsOptam: "665.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA002",
    designation:
      "Décompression médullaire pour malformation de la jonction occipitocervicale, par cervicotomie antérolatérale",
    tarifSecteur1Optam: "693.4",
    tarifHorsSecteur1HorsOptam: "665.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA007",
    designation:
      "Laminoplastie vertébrale sans exploration du contenu intradural, par abord postérieur ou par abord postérolatéral",
    tarifSecteur1Optam: "324.72",
    tarifHorsSecteur1HorsOptam: "293.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHMA016",
    designation:
      "Laminoplastie vertébrale avec exploration du contenu intradural et plastie de la dure-mère, par abord postérieur ou par abord postérolatéral",
    tarifSecteur1Optam: "347.41",
    tarifHorsSecteur1HorsOptam: "333.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHPA003",
    designation:
      "Laminotomie vertébrale sans exploration du contenu intradural, par abord postérieur ou par abord postérolatéral",
    tarifSecteur1Optam: "300.62",
    tarifHorsSecteur1HorsOptam: "279.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHPA010",
    designation:
      "Laminotomie vertébrale avec exploration du contenu intradural sans plastie de la dure-mère, par abord postérieur ou par abord postérolatéral",
    tarifSecteur1Optam: "326.14",
    tarifHorsSecteur1HorsOptam: "320.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHPA006",
    designation:
      "Laminotomie vertébrale avec exploration du contenu intradural et plastie de la dure-mère, par abord postérieur ou par abord postérolatéral",
    tarifSecteur1Optam: "364.43",
    tarifHorsSecteur1HorsOptam: "343.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA016",
    designation:
      "Laminectomie vertébrale sans exploration du contenu intradural, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "316.92",
    tarifHorsSecteur1HorsOptam: "315.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA024",
    designation:
      "Laminectomie vertébrale avec exploration du contenu intradural sans plastie de la dure-mère, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "343.16",
    tarifHorsSecteur1HorsOptam: "330.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA019",
    designation:
      "Laminectomie vertébrale avec exploration du contenu intradural et plastie de la dure-mère, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "388.53",
    tarifHorsSecteur1HorsOptam: "357.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA005",
    designation:
      "Laminarthrectomie cervicale totale unilatérale sans ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "398.46",
    tarifHorsSecteur1HorsOptam: "362.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA004",
    designation:
      "Laminarthrectomie cervicale totale unilatérale avec ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "487.79",
    tarifHorsSecteur1HorsOptam: "458.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA003",
    designation:
      "Laminarthrectomie cervicale totale bilatérale, par abord postérieur",
    tarifSecteur1Optam: "523.95",
    tarifHorsSecteur1HorsOptam: "479.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA006",
    designation:
      "Laminarthrectomie lombale ou lombosacrale totale unilatérale sans ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "365.84",
    tarifHorsSecteur1HorsOptam: "343.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA005",
    designation:
      "Laminarthrectomie lombale ou lombosacrale totale unilatérale avec ostéosynthèse, par abord postérieur",
    tarifSecteur1Optam: "495.59",
    tarifHorsSecteur1HorsOptam: "463.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA001",
    designation:
      "Laminarthrectomie lombale ou lombosacrale totale bilatérale, par abord postérieur",
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "533.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDAA002",
    designation:
      "Recalibrage unilatéral de la colonne vertébrale cervicale, par abord postérieur",
    tarifSecteur1Optam: "361.59",
    tarifHorsSecteur1HorsOptam: "341.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFAA001",
    designation:
      "Recalibrage unilatéral de la colonne vertébrale lombale ou lombosacrale, par abord postérieur",
    tarifSecteur1Optam: "367.97",
    tarifHorsSecteur1HorsOptam: "345.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDAA001",
    designation:
      "Recalibrage bilatéral de la colonne vertébrale cervicale, par abord postérieur",
    tarifSecteur1Optam: "419.73",
    tarifHorsSecteur1HorsOptam: "375.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFAA002",
    designation:
      "Recalibrage bilatéral de la colonne vertébrale lombale ou lombosacrale, par abord postérieur",
    tarifSecteur1Optam: "467.94",
    tarifHorsSecteur1HorsOptam: "403.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA002",
    designation:
      "Uncectomie [Résection de l'uncus] ou foraminotomie unilatérale d'une vertèbre, par cervicotomie antérieure",
    tarifSecteur1Optam: "440.29",
    tarifHorsSecteur1HorsOptam: "387.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA006",
    designation:
      "Corporotomie [Somatotomie] d'une vertèbre pour décompression médullaire, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "487.08",
    tarifHorsSecteur1HorsOptam: "414.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDPA007",
    designation:
      "Corporotomie [Somatotomie] d'une vertèbre pour décompression médullaire, avec arthrodèse et/ou ostéosynthèse, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "598.4",
    tarifHorsSecteur1HorsOptam: "522.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHFA031",
    designation: "Exérèse totale de l'arc vertébral, par abord postérieur",
    tarifSecteur1Optam: "414.77",
    tarifHorsSecteur1HorsOptam: "372.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA009",
    designation:
      "Corporectomie vertébrale partielle, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "525.37",
    tarifHorsSecteur1HorsOptam: "480.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA010",
    designation: "Corporectomie vertébrale partielle, par thoracotomie",
    tarifSecteur1Optam: "613.99",
    tarifHorsSecteur1HorsOptam: "531.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA007",
    designation:
      "Corporectomie vertébrale partielle, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "677.1",
    tarifHorsSecteur1HorsOptam: "568.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA009",
    designation:
      "Corporectomie vertébrale partielle, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "626.05",
    tarifHorsSecteur1HorsOptam: "538.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA014",
    designation:
      "Corporectomie vertébrale partielle ou totale, par thoracoscopie ou par thoracotomie avec préparation par thoracoscopie",
    tarifSecteur1Optam: "581.38",
    tarifHorsSecteur1HorsOptam: "512.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA014",
    designation:
      "Corporectomie vertébrale partielle ou totale, par abord direct avec préparation par cœlioscopie ou par rétropéritonéoscopie",
    tarifSecteur1Optam: "573.38",
    tarifHorsSecteur1HorsOptam: "573.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA012",
    designation:
      "Corporectomie vertébrale totale, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "588.47",
    tarifHorsSecteur1HorsOptam: "560.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA006",
    designation: "Corporectomie vertébrale totale, par thoracotomie",
    tarifSecteur1Optam: "687.73",
    tarifHorsSecteur1HorsOptam: "618.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA004",
    designation:
      "Corporectomie vertébrale totale, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "774.23",
    tarifHorsSecteur1HorsOptam: "668.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA013",
    designation:
      "Corporectomie vertébrale totale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "692.69",
    tarifHorsSecteur1HorsOptam: "621.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA012",
    designation: "Corporectomie d'une vertèbre malformée, par thoracotomie",
    tarifSecteur1Optam: "777.89",
    tarifHorsSecteur1HorsOptam: "777.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA008",
    designation:
      "Corporectomie d'une vertèbre malformée, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "1079.81",
    tarifHorsSecteur1HorsOptam: "845.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA008",
    designation:
      "Corporectomie d'une vertèbre malformée, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "977.71",
    tarifHorsSecteur1HorsOptam: "786.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA001",
    designation: "Spondylectomie thoracique totale, par abord postérieur",
    tarifSecteur1Optam: "1235.08",
    tarifHorsSecteur1HorsOptam: "988.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA010",
    designation:
      "Spondylectomie totale, par cervicotomie antérieure ou antérolatérale et par abord postérieur",
    tarifSecteur1Optam: "1089.73",
    tarifHorsSecteur1HorsOptam: "904.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA005",
    designation:
      "Spondylectomie totale, par thoracotomie et par abord postérieur",
    tarifSecteur1Optam: "1331.5",
    tarifHorsSecteur1HorsOptam: "1044.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA009",
    designation:
      "Spondylectomie totale, par thoraco-phréno-laparotomie et par abord postérieur",
    tarifSecteur1Optam: "1051.79",
    tarifHorsSecteur1HorsOptam: "1051.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA012",
    designation:
      "Spondylectomie totale, par laparotomie ou lombotomie et par abord postérieur",
    tarifSecteur1Optam: "1281.87",
    tarifHorsSecteur1HorsOptam: "1015.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGFA002",
    designation:
      "Exérèse proximale du sacrum [Sacrectomie S1 et/ou S2], par abord antérieur ou par abord postérieur",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "441.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGFA006",
    designation:
      "Exérèse proximale du sacrum [Sacrectomie S1 et/ou S2], par abord antérieur et par abord postérieur",
    tarifSecteur1Optam: "1029.47",
    tarifHorsSecteur1HorsOptam: "825.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGFA001",
    designation:
      "Exérèse distale du sacrum [Sacrectomie respectant S1 et S2], par abord postérieur",
    tarifSecteur1Optam: "425.4",
    tarifHorsSecteur1HorsOptam: "352.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGFA003",
    designation:
      "Exérèse distale du sacrum [Sacrectomie respectant S1 et S2], par abord antérieur et par abord postérieur",
    tarifSecteur1Optam: "781.43",
    tarifHorsSecteur1HorsOptam: "781.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGFA004",
    designation:
      "Exérèse totale du sacrum [Sacrectomie totale], par abord antérieur et par abord postérieur",
    tarifSecteur1Optam: "1435.58",
    tarifHorsSecteur1HorsOptam: "1435.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGFA005",
    designation: "Exérèse du coccyx",
    tarifSecteur1Optam: "123.37",
    tarifHorsSecteur1HorsOptam: "80.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHGA007",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale sur 2 à 5 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "169.45",
    tarifHorsSecteur1HorsOptam: "133.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHGA006",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale sur 6 à 9 vertèbres, par abord postérieur",
    tarifSecteur1Optam: "220.5",
    tarifHorsSecteur1HorsOptam: "163",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHGA004",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale sur 10 vertèbres ou plus, par abord postérieur",
    tarifSecteur1Optam: "442.42",
    tarifHorsSecteur1HorsOptam: "291.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDGA002",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "188.59",
    tarifHorsSecteur1HorsOptam: "144.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDGA001",
    designation:
      "Ablation de matériel d'ostéosynthèse de l'atlas et/ou de l'axis, par cervicotomie",
    tarifSecteur1Optam: "316.92",
    tarifHorsSecteur1HorsOptam: "218.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEGA002",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale, par thoracotomie",
    tarifSecteur1Optam: "296.68",
    tarifHorsSecteur1HorsOptam: "296.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEGA001",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale, par thoraco-phréno-laparotomie",
    tarifSecteur1Optam: "421.86",
    tarifHorsSecteur1HorsOptam: "332.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFGA001",
    designation:
      "Ablation de matériel d'ostéosynthèse de la colonne vertébrale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "219.79",
    tarifHorsSecteur1HorsOptam: "171.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENNH002",
    designation:
      "Sclérose d'une lésion vasculaire vertébrale, par injection intralésionnelle d'agent pharmacologique par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "104.64",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHFH001",
    designation:
      "Tumorectomie osseuse vertébrale, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHMA008",
    designation:
      "Reconstruction de l'isthme interarticulaire d'une vertèbre, par abord postérieur",
    tarifSecteur1Optam: "367.26",
    tarifHorsSecteur1HorsOptam: "344.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHPA004",
    designation:
      "Mise à plat d'une lésion vertébrale infectieuse ou ossifluente, par abord postérieur",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHPH907",
    designation:
      "Exérèse d'un disque intervertébral, par voie transcutanée avec guidage radiologique [Nucléotomie transcutanée]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDFA001",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale cervicale, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "403.42",
    tarifHorsSecteur1HorsOptam: "365.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA002",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale thoracique, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "441.71",
    tarifHorsSecteur1HorsOptam: "387.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA003",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale thoracique avec ostéosynthèse et/ou arthrodèse, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "584.22",
    tarifHorsSecteur1HorsOptam: "514.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA002",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale lombale, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "285.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA003",
    designation:
      "Exérèse de plusieurs hernies discales de la colonne vertébrale lombale, par abord postérieur ou postérolatéral",
    tarifSecteur1Optam: "382.15",
    tarifHorsSecteur1HorsOptam: "339.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA004",
    designation:
      "Exérèse d'une récidive d'une hernie discale de la colonne vertébrale lombale préalablement opérée par abord direct, par abord postérieur",
    tarifSecteur1Optam: "419.02",
    tarifHorsSecteur1HorsOptam: "361.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA007",
    designation:
      "Exérèse d'une récidive d'une hernie discale de la colonne vertébrale lombale préalablement opérée par abord direct, avec ostéosynthèse et/ou arthrodèse, par abord postérieur",
    tarifSecteur1Optam: "588.47",
    tarifHorsSecteur1HorsOptam: "459.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA011",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "443.13",
    tarifHorsSecteur1HorsOptam: "388.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFC002",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale, par thoracoscopie",
    tarifSecteur1Optam: "539.49",
    tarifHorsSecteur1HorsOptam: "539.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA013",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale, par thoracotomie",
    tarifSecteur1Optam: "542.39",
    tarifHorsSecteur1HorsOptam: "490.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFC002",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale, par rétropéritonéoscopie",
    tarifSecteur1Optam: "531.75",
    tarifHorsSecteur1HorsOptam: "483.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA011",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA008",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale avec ostéosynthèse et/ou arthrodèse, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "521.12",
    tarifHorsSecteur1HorsOptam: "477.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LEFA011",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale avec ostéosynthèse et/ou arthrodèse, par thoracotomie",
    tarifSecteur1Optam: "606.9",
    tarifHorsSecteur1HorsOptam: "571.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFFA010",
    designation:
      "Exérèse d'une hernie discale de la colonne vertébrale avec ostéosynthèse et/ou arthrodèse, par laparotomie ou par lombotomie",
    tarifSecteur1Optam: "563.66",
    tarifHorsSecteur1HorsOptam: "546.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA006",
    designation:
      "Exérèse de plusieurs hernies discales de la colonne vertébrale, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "529.62",
    tarifHorsSecteur1HorsOptam: "438.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDFA007",
    designation:
      "Exérèse de plusieurs hernies discales de la colonne vertébrale avec ostéosynthèse et/ou arthrodèse, par cervicotomie antérieure ou antérolatérale",
    tarifSecteur1Optam: "557.27",
    tarifHorsSecteur1HorsOptam: "498.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDKA426",
    designation:
      "Remplacement d'un disque intervérterbral cervical par prothèse totale, par cervicotomie",
    tarifSecteur1Optam: "638.1",
    tarifHorsSecteur1HorsOptam: "638.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LDKA767",
    designation:
      "Remplacement d'un disque intervertébral cervical par prothèse, par cervicotomie",
    tarifSecteur1Optam: "850.8",
    tarifHorsSecteur1HorsOptam: "850.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LFKA001",
    designation:
      "Remplacement d'un disque intervertébral lombal par prothèse totale, par laparotomie ou lombotomie",
    tarifSecteur1Optam: "850.8",
    tarifHorsSecteur1HorsOptam: "850.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHEP005",
    designation:
      "Traction continue unipolaire de la colonne vertébrale par sangle ou collier",
    tarifSecteur1Optam: "123.37",
    tarifHorsSecteur1HorsOptam: "115.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHEP004",
    designation:
      "Traction continue bipolaire de la colonne vertébrale par sangles",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "167.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDEB002",
    designation:
      "Traction d'attente de la colonne vertébrale cervicale par halo crânien ou étrier",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHEP003",
    designation:
      "Réduction orthopédique extemporanée de lésion traumatique de la colonne vertébrale par manœuvres externes",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "139.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDEP002",
    designation:
      "Réduction orthopédique extemporanée de lésion traumatique de la colonne vertébrale cervicale par traction",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "282.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHEP002",
    designation:
      "Réduction orthopédique extemporanée d'une scoliose ou d'une cyphose par manœuvres externes, avec confection d'un corset rigide",
    tarifSecteur1Optam: "283.46",
    tarifHorsSecteur1HorsOptam: "196.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDEP003",
    designation:
      "Réduction orthopédique progressive de lésion traumatique de la colonne vertébrale cervicale par traction continue",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "167.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHEB001",
    designation:
      "Réduction orthopédique progressive d'une scoliose ou d'une cyphose, par traction continue de la colonne vertébrale par halo crânien",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "188.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHEB002",
    designation:
      "Réduction orthopédique progressive d'une scoliose ou d'une cyphose, par traction continue bipolaire de la colonne vertébrale par halo crânien et contrappui thoracique, pelvien ou fémoral",
    tarifSecteur1Optam: "237.87",
    tarifHorsSecteur1HorsOptam: "237.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LFEP001",
    designation:
      "Réduction orthopédique progressive d'un spondylolisthésis lombal, par hamac et/ou traction bipolaire",
    tarifSecteur1Optam: "232.55",
    tarifHorsSecteur1HorsOptam: "178.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDMP001",
    designation:
      "Confection d'un appareil rigide d'immobilisation de la colonne vertébrale cervicale sans appui occipitomentonnier ou fronto-occipito-mentonnier",
    tarifSecteur1Optam: "63.81",
    tarifHorsSecteur1HorsOptam: "63.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDMP002",
    designation:
      "Confection d'un appareil rigide d'immobilisation de la colonne vertébrale cervicale avec appui occipitomentonnier ou fronto-occipito-mentonnier",
    tarifSecteur1Optam: "77.99",
    tarifHorsSecteur1HorsOptam: "71.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDMP003",
    designation:
      "Confection d'un appareil rigide d'immobilisation de la colonne vertébrale cervicale et thoracique",
    tarifSecteur1Optam: "97.84",
    tarifHorsSecteur1HorsOptam: "83.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LFMP001",
    designation:
      "Confection d'un appareil rigide d'immobilisation de la colonne vertébrale lombale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LEMP001",
    designation:
      "Confection d'un appareil rigide d'immobilisation de la colonne vertébrale thoracique et lombale",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LDEP001",
    designation:
      "Séance d'élongation de la colonne vertébrale cervicale par traction sur table",
    tarifSecteur1Optam: "29.48",
    tarifHorsSecteur1HorsOptam: "22.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LFEP002",
    designation:
      "Séance d'élongation de la colonne vertébrale lombale par traction sur table",
    tarifSecteur1Optam: "30.15",
    tarifHorsSecteur1HorsOptam: "23.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHRP001",
    designation: "Séance de médecine manuelle de la colonne vertébrale",
    tarifSecteur1Optam: "33.5",
    tarifHorsSecteur1HorsOptam: "25.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LHLB002",
    designation:
      "Infiltration thérapeutique des ligaments périvertébraux, par voie transcutanée",
    tarifSecteur1Optam: "21.44",
    tarifHorsSecteur1HorsOptam: "16.47",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LJLH001",
    designation:
      "Infiltration thérapeutique d'articulation costovertébrale, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "41.54",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LJLH002",
    designation:
      "Infiltration thérapeutique d'articulation costovertébrale, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "51.59",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LJJA001",
    designation:
      "Évacuation de collection suppurée de la paroi thoracique, par abord direct",
    tarifSecteur1Optam: "162.36",
    tarifHorsSecteur1HorsOptam: "138.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJJA002",
    designation:
      "Évacuation de collection suppurée de la paroi thoracique étendue au médiastin",
    tarifSecteur1Optam: "416.89",
    tarifHorsSecteur1HorsOptam: "373.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJSA002",
    designation:
      "Fermeture secondaire de la paroi thoracique après chirurgie cardiovasculaire chez le nouveau-né",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJSA001",
    designation: "Fermeture d'une désunion pariétale de thoracotomie latérale",
    tarifSecteur1Optam: "265.17",
    tarifHorsSecteur1HorsOptam: "241.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJCA001",
    designation: "Ostéosynthèse de fracture du sternum",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "242.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJCA002",
    designation: "Ostéosynthèse d'un volet thoracique",
    tarifSecteur1Optam: "491.34",
    tarifHorsSecteur1HorsOptam: "372.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA010",
    designation: "Exérèse partielle de côte et/ou de sternum, par abord direct",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA006",
    designation: "Résection d'une côte",
    tarifSecteur1Optam: "189.3",
    tarifHorsSecteur1HorsOptam: "136.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA003",
    designation: "Résection d'une côte cervicale, par abord supraclaviculaire",
    tarifSecteur1Optam: "386.41",
    tarifHorsSecteur1HorsOptam: "276.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA002",
    designation:
      "Résection de la première côte, par abord supraclaviculaire ou par abord axillaire",
    tarifSecteur1Optam: "483.54",
    tarifHorsSecteur1HorsOptam: "333.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA007",
    designation:
      "Résection de la première côte et d'une côte cervicale, par abord supraclaviculaire",
    tarifSecteur1Optam: "557.98",
    tarifHorsSecteur1HorsOptam: "402.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA004",
    designation: "Résection de la paroi thoracique, sans prothèse",
    tarifSecteur1Optam: "348.12",
    tarifHorsSecteur1HorsOptam: "289.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA008",
    designation: "Résection de la paroi thoracique, avec prothèse",
    tarifSecteur1Optam: "444.54",
    tarifHorsSecteur1HorsOptam: "345.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA009",
    designation: "Résection du sternum",
    tarifSecteur1Optam: "760.05",
    tarifHorsSecteur1HorsOptam: "598.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJFA001",
    designation: "Gibbectomie, par abord direct",
    tarifSecteur1Optam: "484.25",
    tarifHorsSecteur1HorsOptam: "368.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJMA003",
    designation: "Thoracoplastie de 2 à 5 côtes",
    tarifSecteur1Optam: "621.08",
    tarifHorsSecteur1HorsOptam: "448.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJMA001",
    designation: "Thoracoplastie de 6 côtes ou plus",
    tarifSecteur1Optam: "760.05",
    tarifHorsSecteur1HorsOptam: "598.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJGA002",
    designation:
      "Ablation de matériel d'ostéosynthèse du sternum et/ou des côtes",
    tarifSecteur1Optam: "223.34",
    tarifHorsSecteur1HorsOptam: "147.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJGA001",
    designation: "Ablation de fils d'ostéosynthèse du sternum",
    tarifSecteur1Optam: "80.83",
    tarifHorsSecteur1HorsOptam: "64.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJMA002",
    designation:
      "Plastie de malformation du thorax sans malformation du sternum, sans ostéosynthèse",
    tarifSecteur1Optam: "482.83",
    tarifHorsSecteur1HorsOptam: "438.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJMA004",
    designation:
      "Plastie de malformation du thorax sans malformation du sternum, avec ostéosynthèse",
    tarifSecteur1Optam: "524.66",
    tarifHorsSecteur1HorsOptam: "462.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJMA005",
    designation: "Plastie de malformation sternocostale, sans ostéosynthèse",
    tarifSecteur1Optam: "616.83",
    tarifHorsSecteur1HorsOptam: "515.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LJMA006",
    designation: "Plastie de malformation sternocostale, avec ostéosynthèse",
    tarifSecteur1Optam: "774.94",
    tarifHorsSecteur1HorsOptam: "607.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCPA002",
    designation:
      "Ténotomie du muscle sterno-cléido-mastoïdien, pour torticolis congénital",
    tarifSecteur1Optam: "126.91",
    tarifHorsSecteur1HorsOptam: "86.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLFA003",
    designation:
      "Exérèse partielle du diaphragme sans pose de prothèse, par thoracotomie",
    tarifSecteur1Optam: "401.29",
    tarifHorsSecteur1HorsOptam: "390.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLFA013",
    designation:
      "Exérèse partielle du diaphragme avec pose de prothèse, par thoracotomie",
    tarifSecteur1Optam: "483.54",
    tarifHorsSecteur1HorsOptam: "437.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLCC001",
    designation:
      "Suture de plaie ou de rupture récente d'une coupole du diaphragme, par thoracoscopie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLCA003",
    designation:
      "Suture de plaie ou de rupture récente d'une coupole du diaphragme, par thoracotomie",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "396.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLCC003",
    designation:
      "Suture de plaie ou de rupture récente de la coupole du diaphragme, par cœlioscopie",
    tarifSecteur1Optam: "346.7",
    tarifHorsSecteur1HorsOptam: "344.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLCA005",
    designation:
      "Suture de plaie ou de rupture récente de la coupole du diaphragme, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMC003",
    designation:
      "Plastie d'une coupole du diaphragme pour éventration, par thoracoscopie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA003",
    designation:
      "Plastie d'une coupole du diaphragme pour éventration, par thoracotomie",
    tarifSecteur1Optam: "486.37",
    tarifHorsSecteur1HorsOptam: "439.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMC019",
    designation:
      "Plastie d'une coupole du diaphragme pour éventration, par cœlioscopie",
    tarifSecteur1Optam: "342.3",
    tarifHorsSecteur1HorsOptam: "342.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA008",
    designation:
      "Plastie d'une coupole du diaphragme pour éventration, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMC004",
    designation:
      "Cure d'une hernie hiatale sans pose de prothèse, par cœlioscopie",
    tarifSecteur1Optam: "397.04",
    tarifHorsSecteur1HorsOptam: "374.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA006",
    designation:
      "Cure d'une hernie hiatale sans pose de prothèse, par laparotomie",
    tarifSecteur1Optam: "367.97",
    tarifHorsSecteur1HorsOptam: "345.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA010",
    designation:
      "Cure d'une hernie hiatale avec pose de prothèse, par laparotomie",
    tarifSecteur1Optam: "409.8",
    tarifHorsSecteur1HorsOptam: "369.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMC001",
    designation: "Cure d'une hernie rétrocostoxiphoïdienne, par cœlioscopie",
    tarifSecteur1Optam: "342.3",
    tarifHorsSecteur1HorsOptam: "342.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA007",
    designation: "Cure d'une hernie rétrocostoxiphoïdienne, par laparotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMC005",
    designation:
      "Cure d'une hernie acquise de la coupole du diaphragme, par thoracoscopie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA001",
    designation:
      "Cure d'une hernie acquise de la coupole gauche du diaphragme, par thoracotomie",
    tarifSecteur1Optam: "472.9",
    tarifHorsSecteur1HorsOptam: "431.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA005",
    designation:
      "Cure d'une hernie acquise de la coupole droite du diaphragme, par abord direct",
    tarifSecteur1Optam: "470.78",
    tarifHorsSecteur1HorsOptam: "430.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA002",
    designation:
      "Cure d'une hernie hiatale congénitale avec agénésie des piliers du diaphragme, par laparotomie",
    tarifSecteur1Optam: "443.13",
    tarifHorsSecteur1HorsOptam: "388.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLCA002",
    designation:
      "Suture d'une coupole du diaphragme pour hernie congénitale, par thoracotomie",
    tarifSecteur1Optam: "404.01",
    tarifHorsSecteur1HorsOptam: "404.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLCA001",
    designation:
      "Suture d'une coupole du diaphragme pour hernie congénitale, par laparotomie",
    tarifSecteur1Optam: "425.4",
    tarifHorsSecteur1HorsOptam: "378.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA009",
    designation:
      "Plastie musculaire ou prothétique d'une coupole du diaphragme pour hernie congénitale, par thoracotomie",
    tarifSecteur1Optam: "491.34",
    tarifHorsSecteur1HorsOptam: "442.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLMA004",
    designation:
      "Plastie musculaire ou prothétique d'une coupole du diaphragme pour hernie congénitale, par laparotomie",
    tarifSecteur1Optam: "491.34",
    tarifHorsSecteur1HorsOptam: "416.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMFA003",
    designation: "Exérèse d'un bourgeon ombilical",
    tarifSecteur1Optam: "202.07",
    tarifHorsSecteur1HorsOptam: "173.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMFA001",
    designation:
      "Exérèse de tumeur de la paroi abdominale antérieure avec fermeture par suture, par abord direct",
    tarifSecteur1Optam: "202.07",
    tarifHorsSecteur1HorsOptam: "201.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMFA002",
    designation:
      "Exérèse de tumeur de la paroi abdominale antérieure avec fermeture par prothèse, par abord direct",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "238.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA011",
    designation:
      "Cure d'une hernie fémorale [crurale], par abord inguinofémoral",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMC003",
    designation:
      "Cure unilatérale d'une hernie de l'aine sans pose de prothèse, par vidéochirurgie",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA016",
    designation:
      "Cure unilatérale d'une hernie de l'aine sans pose de prothèse sous anesthésie locale, par abord inguinal",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA017",
    designation:
      "Cure unilatérale d'une hernie de l'aine sans pose de prothèse sous anesthésie générale ou locorégionale, par abord inguinal",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMC002",
    designation:
      "Cure unilatérale d'une hernie de l'aine avec pose de prothèse, par vidéochirurgie",
    tarifSecteur1Optam: "227.59",
    tarifHorsSecteur1HorsOptam: "216.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA012",
    designation:
      "Cure unilatérale d'une hernie de l'aine avec pose de prothèse, par abord inguinal",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA008",
    designation:
      "Cure unilatérale d'une hernie de l'aine avec pose de prothèse, par abord prépéritonéal",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMC004",
    designation:
      "Cure bilatérale d'une hernie de l'aine sans pose de prothèse, par vidéochirurgie",
    tarifSecteur1Optam: "287.56",
    tarifHorsSecteur1HorsOptam: "287.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA019",
    designation:
      "Cure bilatérale d'une hernie de l'aine sans pose de prothèse sous anesthésie locale, par abord inguinal",
    tarifSecteur1Optam: "287.56",
    tarifHorsSecteur1HorsOptam: "287.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA018",
    designation:
      "Cure bilatérale d'une hernie de l'aine sans pose de prothèse sous anesthésie générale ou locorégionale, par abord inguinal",
    tarifSecteur1Optam: "287.56",
    tarifHorsSecteur1HorsOptam: "287.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMC001",
    designation:
      "Cure bilatérale d'une hernie de l'aine avec pose de prothèse, par vidéochirurgie",
    tarifSecteur1Optam: "287.56",
    tarifHorsSecteur1HorsOptam: "287.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA001",
    designation:
      "Cure bilatérale d'une hernie de l'aine avec pose de prothèse, par abord inguinal",
    tarifSecteur1Optam: "287.56",
    tarifHorsSecteur1HorsOptam: "287.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA002",
    designation:
      "Cure bilatérale de hernie de l'aine avec pose de prothèse, par abord prépéritonéal unique",
    tarifSecteur1Optam: "287.56",
    tarifHorsSecteur1HorsOptam: "287.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA014",
    designation:
      "Cure de hernie de la paroi abdominale antérieure avant l'âge de 16 ans, par abord direct",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA009",
    designation:
      "Cure de hernie de la paroi abdominale antérieure après l'âge de 16 ans sans pose de prothèse, par abord direct",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMC020",
    designation:
      "Cure de hernie de la paroi abdominale antérieure après l'âge de 16 ans avec pose de prothèse, par cœlioscopie",
    tarifSecteur1Optam: "259.22",
    tarifHorsSecteur1HorsOptam: "247.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA006",
    designation:
      "Cure de hernie de la paroi abdominale antérieure après l'âge de 16 ans avec pose de prothèse, par abord direct",
    tarifSecteur1Optam: "230.43",
    tarifHorsSecteur1HorsOptam: "218.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMC015",
    designation:
      "Cure d'éventration postopératoire de la paroi abdominale antérieure avec pose de prothèse, par cœlioscopie",
    tarifSecteur1Optam: "280.76",
    tarifHorsSecteur1HorsOptam: "260,45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA010",
    designation:
      "Cure d'éventration postopératoire de la paroi abdominale antérieure sans pose de prothèse, par abord direct",
    tarifSecteur1Optam: "201.87",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA004",
    designation:
      "Cure d'éventration postopératoire de la paroi abdominale antérieure avec pose de prothèse, par abord direct",
    tarifSecteur1Optam: "280.06",
    tarifHorsSecteur1HorsOptam: "247.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA005",
    designation:
      "Réparation de perte de substance transfixiante de la paroi abdominale par lambeau libre et prothèse",
    tarifSecteur1Optam: "756.5",
    tarifHorsSecteur1HorsOptam: "526.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA003",
    designation: "Réfection de la paroi lombale sans prothèse",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMMA007",
    designation: "Réfection de la paroi lombale avec prothèse",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "241.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMSA002",
    designation: "Fermeture d'une éviscération abdominale, par abord direct",
    tarifSecteur1Optam: "241.06",
    tarifHorsSecteur1HorsOptam: "210.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMSA004",
    designation: "Fermeture d'une laparostomie",
    tarifSecteur1Optam: "264.46",
    tarifHorsSecteur1HorsOptam: "206.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMGA001",
    designation:
      "Ablation d'une prothèse de la paroi abdominale, par abord direct",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "232.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMSA001",
    designation:
      "Fermeture d'une omphalocèle ou d'un laparoschisis en un temps",
    tarifSecteur1Optam: "420.44",
    tarifHorsSecteur1HorsOptam: "331.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMSA005",
    designation:
      "Fermeture de paroi abdominale antérieure hypoplasique ou aplasique par plastie musculoaponévrotique",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "380.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAQK003",
    designation:
      "Radiographie de la ceinture scapulaire et/ou de l'épaule selon 1 ou 2 incidences",
    tarifSecteur1Optam: "30.65",
    tarifHorsSecteur1HorsOptam: "30.65",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MAQK001",
    designation:
      "Radiographie de la ceinture scapulaire et/ou de l'épaule selon 3 ou 4 incidences",
    tarifSecteur1Optam: "30.65",
    tarifHorsSecteur1HorsOptam: "30.65",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MAQK002",
    designation:
      "Radiographie de la ceinture scapulaire et/ou de l'épaule selon 5 incidences ou plus",
    tarifSecteur1Optam: "30.65",
    tarifHorsSecteur1HorsOptam: "30.65",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MBQK001",
    designation: "Radiographie du bras",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MFQK002",
    designation: "Radiographie du coude selon 1 ou 2 incidences",
    tarifSecteur1Optam: "23.23",
    tarifHorsSecteur1HorsOptam: "23.23",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MFQK001",
    designation: "Radiographie du coude selon 3 incidences ou plus",
    tarifSecteur1Optam: "23.23",
    tarifHorsSecteur1HorsOptam: "23.23",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MCQK001",
    designation: "Radiographie de l'avant-bras",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MGQK003",
    designation: "Radiographie du poignet selon 1 ou 2 incidences",
    tarifSecteur1Optam: "24.06",
    tarifHorsSecteur1HorsOptam: "24.06",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MGQK001",
    designation: "Radiographie du poignet selon 3 incidences ou plus",
    tarifSecteur1Optam: "24.06",
    tarifHorsSecteur1HorsOptam: "24.06",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MGQK002",
    designation:
      "Bilan radiographique dynamique du poignet pour entorse non dissociative selon 7 incidences spécifiques",
    tarifSecteur1Optam: "29.26",
    tarifHorsSecteur1HorsOptam: "29.26",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MDQK001",
    designation: "Radiographie de la main ou de doigt",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MDQK002",
    designation:
      "Radiographie bilatérale de la main et/ou du poignet, selon 1 incidence sur un seul cliché de face",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQK003",
    designation: "Radiographie de 2 segments du membre supérieur",
    tarifSecteur1Optam: "29.26",
    tarifHorsSecteur1HorsOptam: "29.26",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQK004",
    designation: "Radiographie de 3 segments du membre supérieur ou plus",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQK001",
    designation:
      "Téléradiographie unilatérale ou bilatérale du membre supérieur en totalité, de face",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MEQH001",
    designation: "Arthrographie de l'épaule",
    tarifSecteur1Optam: "79.8",
    tarifHorsSecteur1HorsOptam: "79.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MFQH001",
    designation: "Arthrographie du coude",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MGQH001",
    designation: "Arthrographie du poignet",
    tarifSecteur1Optam: "69.16",
    tarifHorsSecteur1HorsOptam: "69.16",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MHQH001",
    designation:
      "Arthrographie métacarpophalangienne ou interphalangienne de doigt",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQH001",
    designation:
      "Arthrographie du membre supérieur avec scanographie [Arthroscanner du membre supérieur]",
    tarifSecteur1Optam: "79.8",
    tarifHorsSecteur1HorsOptam: "79.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQK002",
    designation:
      "Scanographie unilatérale ou bilatérale de segment du membre supérieur, sans injection de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQH002",
    designation:
      "Scanographie unilatérale ou bilatérale de segment du membre supérieur, avec injection de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQN001",
    designation:
      "Remnographie [IRM] unilatérale ou bilatérale de segment du membre supérieur, sans injection de produit de contraste",
    tarifSecteur1Optam: "55",
    tarifHorsSecteur1HorsOptam: "55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQJ001",
    designation:
      "Remnographie [IRM] unilatérale ou bilatérale de segment du membre supérieur, avec injection de produit de contraste",
    tarifSecteur1Optam: "55",
    tarifHorsSecteur1HorsOptam: "55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "MZQP001",
    designation:
      "Bilan fonctionnel de l'articulation de l'épaule, du coude et/ou du poignet, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "39",
    tarifHorsSecteur1HorsOptam: "26.65",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHQP001",
    designation:
      "Bilan fonctionnel des articulations de la main, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "41.12",
    tarifHorsSecteur1HorsOptam: "27.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHB002",
    designation:
      "Ponction ou cytoponction d'une articulation du membre supérieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "28.81",
    tarifHorsSecteur1HorsOptam: "20.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHH001",
    designation:
      "Ponction ou cytoponction d'une articulation du membre supérieur, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "24.79",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHH002",
    designation:
      "Ponction ou cytoponction d'une articulation du membre supérieur, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "27.36",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHB001",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre supérieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "38.86",
    tarifHorsSecteur1HorsOptam: "28.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHH003",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre supérieur, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHH004",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre supérieur, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZHA001",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre supérieur, par abord direct",
    tarifSecteur1Optam: "138.26",
    tarifHorsSecteur1HorsOptam: "124.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDHA001",
    designation:
      "Biopsie d'un os et/ou d'une articulation de la main, par abord direct",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEQC001",
    designation:
      "Exploration de la bourse séreuse subacromiale, par arthroscopie [Bursoscopie sousacromiale]",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "155.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEQC002",
    designation: "Exploration de l'articulation de l'épaule, par arthroscopie",
    tarifSecteur1Optam: "207.03",
    tarifHorsSecteur1HorsOptam: "172.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFQC001",
    designation: "Exploration de l'articulation du coude, par arthroscopie",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "171.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGQC001",
    designation: "Exploration de l'articulation du poignet, par arthroscopie",
    tarifSecteur1Optam: "235.39",
    tarifHorsSecteur1HorsOptam: "189.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAEP001",
    designation: "Réduction orthopédique de fracture de la scapula",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MACB002",
    designation: "Ostéosynthèse de fracture de la scapula, à foyer fermé",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MACA003",
    designation: "Ostéosynthèse de fracture de la scapula, à foyer ouvert",
    tarifSecteur1Optam: "187.89",
    tarifHorsSecteur1HorsOptam: "152.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MACA002",
    designation:
      "Ostéosynthèse de fracture du col et/ou de la glène de la scapula, à foyer ouvert",
    tarifSecteur1Optam: "272.97",
    tarifHorsSecteur1HorsOptam: "202.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAPA003",
    designation:
      "Ostéotomie de l'acromion et/ou de l'épine de la scapula, par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAPA001",
    designation:
      "Ostéotomie intraarticulaire ou extraarticulaire de la glène de la scapula, par abord direct",
    tarifSecteur1Optam: "370.1",
    tarifHorsSecteur1HorsOptam: "319.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAFA006",
    designation:
      "Exérèse partielle de la scapula sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAGA003",
    designation: "Évidement de la scapula sans comblement, par abord direct",
    tarifSecteur1Optam: "208.45",
    tarifHorsSecteur1HorsOptam: "164.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAGA002",
    designation: "Évidement de la scapula avec comblement, par abord direct",
    tarifSecteur1Optam: "248.86",
    tarifHorsSecteur1HorsOptam: "236.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAFA003",
    designation:
      "Scapulectomie partielle respectant la glène, par abord direct",
    tarifSecteur1Optam: "341.74",
    tarifHorsSecteur1HorsOptam: "268.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAFA001",
    designation:
      "Scapulectomie partielle emportant la glène ou scapulectomie totale, par abord direct",
    tarifSecteur1Optam: "453.05",
    tarifHorsSecteur1HorsOptam: "332.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAGA001",
    designation:
      "Ablation de matériel d'ostéosynthèse de la scapula, par abord direct",
    tarifSecteur1Optam: "127.62",
    tarifHorsSecteur1HorsOptam: "91.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MADP001",
    designation:
      "Contention orthopédique unilatérale ou bilatérale de fracture de la clavicule",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MACB001",
    designation:
      "Ostéosynthèse de fracture de la clavicule par broche, à foyer fermé",
    tarifSecteur1Optam: "85.08",
    tarifHorsSecteur1HorsOptam: "66.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MACA004",
    designation:
      "Ostéosynthèse de fracture de la clavicule par fixateur externe",
    tarifSecteur1Optam: "109.9",
    tarifHorsSecteur1HorsOptam: "81.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MACA001",
    designation: "Ostéosynthèse de fracture de la clavicule, à foyer ouvert",
    tarifSecteur1Optam: "139.67",
    tarifHorsSecteur1HorsOptam: "116.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAPA002",
    designation: "Ostéotomie de la clavicule, par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAFA005",
    designation:
      "Résection segmentaire de la clavicule avec ostéosynthèse et autogreffe, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "303.05",
    tarifHorsSecteur1HorsOptam: "303.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAFA002",
    designation: "Résection de moins d'un tiers de la clavicule",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MAFA004",
    designation: "Résection d'un tiers de la clavicule ou plus",
    tarifSecteur1Optam: "289.27",
    tarifHorsSecteur1HorsOptam: "238",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBEP001",
    designation:
      "Réduction orthopédique extemporanée de fracture ou de décollement épiphysaire de l'extrémité proximale de l'humérus ou de fracture de la diaphyse de l'humérus",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBEP002",
    designation:
      "Réduction orthopédique extemporanée de fracture de l'extrémité distale de l'humérus",
    tarifSecteur1Optam: "85.08",
    tarifHorsSecteur1HorsOptam: "84.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBEP003",
    designation:
      "Réduction orthopédique progressive de fracture d'une extrémité et/ou de la diaphyse de l'humérus par traction continue non squelettique",
    tarifSecteur1Optam: "96.42",
    tarifHorsSecteur1HorsOptam: "91.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MBEB001",
    designation:
      "Réduction orthopédique progressive de fracture d'une extrémité et/ou de la diaphyse de l'humérus par traction continue squelettique",
    tarifSecteur1Optam: "129.04",
    tarifHorsSecteur1HorsOptam: "109.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MBCA009",
    designation:
      "Ostéosynthèse de fracture-luxation de l'extrémité proximale de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "323.09",
    tarifHorsSecteur1HorsOptam: "323.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA005",
    designation: "Ostéosynthèse de fracture céphalotubérositaire de l'humérus",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA004",
    designation:
      "Ostéosynthèse de fracture du tubercule majeur [trochiter] et/ou du tubercule mineur [trochin], à foyer ouvert",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA001",
    designation:
      "Ostéosynthèse de fracture extraarticulaire infratubérositaire de l'humérus",
    tarifSecteur1Optam: "204.9",
    tarifHorsSecteur1HorsOptam: "201.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA007",
    designation:
      "Ostéosynthèse de fracture de la diaphyse de l'humérus par fixateur externe",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCB002",
    designation:
      "Ostéosynthèse de fracture de la diaphyse de l'humérus par matériel centromédullaire, à foyer fermé",
    tarifSecteur1Optam: "245.31",
    tarifHorsSecteur1HorsOptam: "225.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA011",
    designation:
      "Ostéosynthèse de fracture de la diaphyse de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "302.74",
    tarifHorsSecteur1HorsOptam: "258.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCB001",
    designation:
      "Ostéosynthèse d'une fracture supracondylaire de l'humérus, à foyer fermé",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA003",
    designation:
      "Ostéosynthèse d'une fracture supracondylaire de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "249.57",
    tarifHorsSecteur1HorsOptam: "227.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA008",
    designation:
      "Ostéosynthèse d'une fracture supracondylaire et intercondylaire simple de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "265.17",
    tarifHorsSecteur1HorsOptam: "254.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA006",
    designation:
      "Ostéosynthèse d'une fracture supracondylaire et intercondylaire complexe de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "384.28",
    tarifHorsSecteur1HorsOptam: "341.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCB004",
    designation:
      "Ostéosynthèse d'une fracture du condyle médial ou du condyle latéral de l'humérus, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCB003",
    designation:
      "Ostéosynthèse d'une fracture de l'épicondyle médial ou de l'épicondyle latéral de l'humérus, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA010",
    designation:
      "Ostéosynthèse d'une fracture de l'épicondyle médial ou de l'épicondyle latéral de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA012",
    designation:
      "Ostéosynthèse de fracture intracapsulaire de l'extrémité distale de l'humérus, à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCB005",
    designation:
      "Ostéosynthèse préventive de l'humérus pour lésion ostéolytique, à foyer fermé",
    tarifSecteur1Optam: "245.31",
    tarifHorsSecteur1HorsOptam: "225.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBCA002",
    designation:
      "Ostéosynthèse préventive de l'humérus pour lésion ostéolytique, à foyer ouvert",
    tarifSecteur1Optam: "343.87",
    tarifHorsSecteur1HorsOptam: "282.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBPA002",
    designation:
      "Ostéotomie intraarticulaire de l'extrémité proximale de l'humérus",
    tarifSecteur1Optam: "304.87",
    tarifHorsSecteur1HorsOptam: "282.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBPA005",
    designation:
      "Ostéotomie extraarticulaire de l'extrémité proximale de l'humérus",
    tarifSecteur1Optam: "277.93",
    tarifHorsSecteur1HorsOptam: "266.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBPA004",
    designation: "Ostéotomie simple de la diaphyse de l'humérus",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "269.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBPA003",
    designation:
      "Ostéotomie complexe de la diaphyse ou de l'extrémité distale de l'humérus",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "284.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBPA006",
    designation: "Décortication de l'humérus pour pseudarthrose",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBPA001",
    designation:
      "Décortication de l'humérus avec ostéosynthèse pour pseudarthrose",
    tarifSecteur1Optam: "336.78",
    tarifHorsSecteur1HorsOptam: "313.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBGA002",
    designation:
      "Évidement de l'épiphyse proximale de l'humérus en présence d'un cartilage épiphysaire actif sans comblement, par abord direct",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "200.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBGA001",
    designation:
      "Évidement de l'épiphyse proximale de l'humérus en présence d'un cartilage épiphysaire actif avec comblement, par abord direct",
    tarifSecteur1Optam: "290.69",
    tarifHorsSecteur1HorsOptam: "260.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBFA002",
    designation:
      "Résection d'une extrémité et/ou de la diaphyse de l'humérus sans interruption de la continuité osseuse",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "213.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBFA001",
    designation:
      '"Résection ""en bloc"" d\'une extrémité et/ou de la diaphyse de l\'humérus"',
    tarifSecteur1Optam: "372.93",
    tarifHorsSecteur1HorsOptam: "286.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBMA001",
    designation:
      "Reconstruction d'une extrémité et/ou de la diaphyse de l'humérus par greffe ou matériau inerte non prothétique, après résection partielle",
    tarifSecteur1Optam: "194.98",
    tarifHorsSecteur1HorsOptam: "192.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBMA002",
    designation:
      '"Reconstruction d\'une extrémité et/ou de la diaphyse de l\'humérus après résection ""en bloc"", par greffe ou matériau inerte non prothétique"',
    tarifSecteur1Optam: "401.29",
    tarifHorsSecteur1HorsOptam: "311.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCEP002",
    designation:
      "Réduction orthopédique de fracture ou de décollement épiphysaire de l'extrémité proximale et/ou de fracture de la diaphyse d'un os ou des 2 os de l'avant-bras",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCEP001",
    designation:
      "Réduction orthopédique de fracture ou de décollement épiphysaire de l'extrémité distale d'un os ou des 2 os de l'avant-bras",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB001",
    designation:
      "Ostéosynthèse de fracture de l'extrémité proximale d'un os de l'avant-bras, à foyer fermé",
    tarifSecteur1Optam: "157.4",
    tarifHorsSecteur1HorsOptam: "143.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA001",
    designation:
      "Ostéosynthèse de fracture simple ou de décollement épiphysaire de l'extrémité proximale d'un os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "158.11",
    tarifHorsSecteur1HorsOptam: "144.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA011",
    designation:
      "Ostéosynthèse de fracture complexe de l'extrémité proximale d'un os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "164.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB005",
    designation:
      "Ostéosynthèse de fracture de l'extrémité proximale des 2 os de l'avant-bras, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA009",
    designation:
      "Ostéosynthèse de fracture de l'extrémité proximale des 2 os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "255.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB003",
    designation:
      "Ostéosynthèse de fracture de la diaphyse d'un os de l'avant-bras, à foyer fermé",
    tarifSecteur1Optam: "157.4",
    tarifHorsSecteur1HorsOptam: "143.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA004",
    designation:
      "Ostéosynthèse de fracture de la diaphyse d'un os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "168.03",
    tarifHorsSecteur1HorsOptam: "150.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB008",
    designation:
      "Ostéosynthèse de fracture de la diaphyse d'un os de l'avant-bras avec réduction d'une luxation de l'autre os au coude ou au poignet, à foyer fermé",
    tarifSecteur1Optam: "192.85",
    tarifHorsSecteur1HorsOptam: "171.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA003",
    designation:
      "Ostéosynthèse de fracture de la diaphyse d'un os de l'avant-bras avec réduction d'une luxation de l'autre os au coude ou au poignet, à foyer ouvert",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "240.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB007",
    designation:
      "Ostéosynthèse de fracture de la diaphyse des 2 os de l'avant-bras, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA008",
    designation:
      "Ostéosynthèse de fracture de la diaphyse des 2 os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "260.2",
    tarifHorsSecteur1HorsOptam: "251.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA007",
    designation:
      "Ostéosynthèse de fracture de la diaphyse des 2 os de l'avant-bras avec réduction de luxation au coude ou au poignet, à foyer ouvert",
    tarifSecteur1Optam: "349.22",
    tarifHorsSecteur1HorsOptam: "349.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB004",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité distale d'un os de l'avant-bras par broche, à foyer fermé",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB002",
    designation:
      "Ostéosynthèse de fracture de l'extrémité distale d'un os ou des 2 os de l'avant-bras par fixateur externe, à foyer fermé",
    tarifSecteur1Optam: "176.79",
    tarifHorsSecteur1HorsOptam: "176.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA005",
    designation:
      "Ostéosynthèse de fracture de l'extrémité distale d'un os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "149.6",
    tarifHorsSecteur1HorsOptam: "139.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA010",
    designation:
      "Ostéosynthèse de fracture de l'extrémité distale des 2 os de l'avant-bras, à foyer ouvert",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB009",
    designation:
      "Ostéosynthèse préventive d'un os de l'avant-bras pour lésion ostéolytique, à foyer fermé",
    tarifSecteur1Optam: "157.4",
    tarifHorsSecteur1HorsOptam: "143.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA002",
    designation:
      "Ostéosynthèse préventive d'un os de l'avant-bras pour lésion ostéolytique, à foyer ouvert",
    tarifSecteur1Optam: "194.98",
    tarifHorsSecteur1HorsOptam: "165.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCB006",
    designation:
      "Ostéosynthèse préventive des 2 os de l'avant-bras pour lésion ostéolytique, à foyer fermé",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCCA006",
    designation:
      "Ostéosynthèse préventive des 2 os de l'avant-bras pour lésion ostéolytique, à foyer ouvert",
    tarifSecteur1Optam: "297.78",
    tarifHorsSecteur1HorsOptam: "273.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA010",
    designation: "Ostéotomie simple de la diaphyse d'un os de l'avant-bras",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA006",
    designation: "Ostéotomie complexe de la diaphyse d'un os de l'avant-bras",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA004",
    designation:
      "Ostéotomie complexe de la diaphyse du radius et/ou de l'ulna avec résection de synostose radio-ulnaire acquise",
    tarifSecteur1Optam: "400.59",
    tarifHorsSecteur1HorsOptam: "372.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA007",
    designation: "Ostéotomie complexe de la diaphyse des 2 os de l'avant-bras",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA002",
    designation: "Ostéotomie de l'extrémité distale du radius",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "272.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA009",
    designation:
      "Ostéotomie de l'extrémité distale du radius avec résection partielle ou totale de l'extrémité distale de l'ulna",
    tarifSecteur1Optam: "334.4",
    tarifHorsSecteur1HorsOptam: "334.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA003",
    designation: "Ostéotomie de l'extrémité distale des 2 os de l'avant-bras",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA001",
    designation:
      "Ostéotomie de réorientation de l'avant-bras pour synostose radio-ulnaire congénitale",
    tarifSecteur1Optam: "252.4",
    tarifHorsSecteur1HorsOptam: "251.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA005",
    designation:
      "Ostéotomie de l'ulna avec arthrodèse de l'articulation radio-ulnaire distale",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA014",
    designation: "Décortication d'un os de l'avant-bras pour pseudarthrose",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA013",
    designation:
      "Décortication d'un os de l'avant-bras avec ostéosynthèse pour pseudarthrose",
    tarifSecteur1Optam: "216.25",
    tarifHorsSecteur1HorsOptam: "204.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA012",
    designation: "Décortication des 2 os de l'avant-bras pour pseudarthrose",
    tarifSecteur1Optam: "343.99",
    tarifHorsSecteur1HorsOptam: "343.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA011",
    designation:
      "Décortication des 2 os de l'avant-bras avec ostéosynthèse pour pseudarthrose",
    tarifSecteur1Optam: "386.41",
    tarifHorsSecteur1HorsOptam: "368.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCFA002",
    designation:
      "Résection partielle ou totale de l'extrémité distale d'un os de l'avant-bras",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCFA003",
    designation: "Résection de l'olécrâne, par arthrotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCFA001",
    designation: "Résection de synostose radio-ulnaire acquise",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "181.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCFA005",
    designation:
      "Résection d'une extrémité et/ou de la diaphyse d'un os de l'avant-bras, sans interruption de la continuité",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "213.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCFA004",
    designation: "Résection de la tête du radius, par arthrotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCFA006",
    designation:
      '"Résection ""en bloc"" d\'une extrémité et/ou de la diaphyse d\'un os de l\'avant-bras"',
    tarifSecteur1Optam: "314.09",
    tarifHorsSecteur1HorsOptam: "252.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCMA002",
    designation:
      "Reconstruction d'une extrémité et/ou de la diaphyse d'un os de l'avant-bras par greffe ou matériau inerte non prothétique, après résection partielle",
    tarifSecteur1Optam: "194.98",
    tarifHorsSecteur1HorsOptam: "183.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCMA001",
    designation:
      '"Reconstruction d\'une extrémité et/ou de la diaphyse d\'un os de l\'avant-bras après résection ""en bloc"", par greffe ou matériau inerte non prothétique"',
    tarifSecteur1Optam: "401.29",
    tarifHorsSecteur1HorsOptam: "302.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCPA008",
    designation:
      "Dissociation des deux os de l'avant-bras pour création d'une pince préhensile après amputation de main",
    tarifSecteur1Optam: "861.44",
    tarifHorsSecteur1HorsOptam: "578.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGEP002",
    designation:
      "Réduction orthopédique de fracture du carpe et/ou d'une luxation du poignet",
    tarifSecteur1Optam: "75.86",
    tarifHorsSecteur1HorsOptam: "57.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA013",
    designation: "Ostéosynthèse de fracture d'un os du carpe, à foyer ouvert",
    tarifSecteur1Optam: "197.81",
    tarifHorsSecteur1HorsOptam: "158.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA001",
    designation: "Ostéosynthèse de fracture de l'os scaphoïde, à foyer ouvert",
    tarifSecteur1Optam: "231.13",
    tarifHorsSecteur1HorsOptam: "186.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA006",
    designation:
      "Ostéosynthèse de fractures de plusieurs os du carpe, à foyer ouvert",
    tarifSecteur1Optam: "255.95",
    tarifHorsSecteur1HorsOptam: "234.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA005",
    designation:
      "Ostéosynthèse d'une pseudarthrose de l'os scaphoïde, à foyer ouvert",
    tarifSecteur1Optam: "232.55",
    tarifHorsSecteur1HorsOptam: "213.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDGA001",
    designation: "Évidement d'un os du carpe avec comblement, par abord direct",
    tarifSecteur1Optam: "224.75",
    tarifHorsSecteur1HorsOptam: "222.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA003",
    designation: "Résection partielle d'un os du carpe sans arthrodèse",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA006",
    designation:
      "Résection partielle des os du carpe pour centralisation du poignet avec ostéosynthèse du carpe et ostéotomie de la diaphyse de l'ulna, par abord direct",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA006",
    designation:
      "Résection totale d'un os du carpe avec arthrodèse intracarpienne",
    tarifSecteur1Optam: "307.71",
    tarifHorsSecteur1HorsOptam: "248.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA004",
    designation: "Résection de la première rangée du carpe",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "199.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA005",
    designation: '"Résection ""en bloc"" d\'os du carpe et/ou du métacarpe"',
    tarifSecteur1Optam: "260.91",
    tarifHorsSecteur1HorsOptam: "204",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDEA001",
    designation: "Autogreffe corticospongieuse encastrée de l'os scaphoïde",
    tarifSecteur1Optam: "266.48",
    tarifHorsSecteur1HorsOptam: "266.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDEA002",
    designation:
      "Autogreffe corticospongieuse intercalée de l'os scaphoïde avec ostéotomie",
    tarifSecteur1Optam: "329.18",
    tarifHorsSecteur1HorsOptam: "329.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDEA003",
    designation: "Autogreffe corticospongieuse pédiculée de l'os scaphoïde",
    tarifSecteur1Optam: "293.53",
    tarifHorsSecteur1HorsOptam: "271.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDEP002",
    designation: "Réduction orthopédique d'une fracture d'un os de la main",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDEP001",
    designation:
      "Réduction orthopédique de fractures de plusieurs os de la main",
    tarifSecteur1Optam: "73.15",
    tarifHorsSecteur1HorsOptam: "73.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA010",
    designation:
      "Ostéosynthèse de fracture extraarticulaire d'un os de la main par fixateur externe",
    tarifSecteur1Optam: "114.86",
    tarifHorsSecteur1HorsOptam: "92.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCB003",
    designation:
      "Ostéosynthèse de fracture extraarticulaire d'un os de la main par broche, à foyer fermé",
    tarifSecteur1Optam: "99.97",
    tarifHorsSecteur1HorsOptam: "84.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA011",
    designation:
      "Ostéosynthèse de fracture extraarticulaire d'un os de la main, à foyer ouvert",
    tarifSecteur1Optam: "120.53",
    tarifHorsSecteur1HorsOptam: "96.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA009",
    designation:
      "Ostéosynthèse de fractures extraarticulaires de plusieurs os de la main par fixateur externe",
    tarifSecteur1Optam: "155.98",
    tarifHorsSecteur1HorsOptam: "134.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCB004",
    designation:
      "Ostéosynthèse de fractures extraarticulaires de plusieurs os de la main par broche, à foyer fermé",
    tarifSecteur1Optam: "135.42",
    tarifHorsSecteur1HorsOptam: "122.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA007",
    designation:
      "Ostéosynthèse de fractures extraarticulaires de plusieurs os de la main, à foyer ouvert",
    tarifSecteur1Optam: "185.05",
    tarifHorsSecteur1HorsOptam: "151.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA003",
    designation:
      "Ostéosynthèse de fracture articulaire d'un os de la main par fixateur externe",
    tarifSecteur1Optam: "114.86",
    tarifHorsSecteur1HorsOptam: "92.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCB005",
    designation:
      "Ostéosynthèse de fracture articulaire d'un os de la main par broche, à foyer fermé",
    tarifSecteur1Optam: "71.61",
    tarifHorsSecteur1HorsOptam: "67.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA014",
    designation:
      "Ostéosynthèse de fracture articulaire d'un os de la main, à foyer ouvert",
    tarifSecteur1Optam: "153.85",
    tarifHorsSecteur1HorsOptam: "115.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA012",
    designation:
      "Ostéosynthèse de fracture de la base du premier métacarpien, à foyer ouvert",
    tarifSecteur1Optam: "139.67",
    tarifHorsSecteur1HorsOptam: "107.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA008",
    designation:
      "Ostéosynthèse de fractures articulaires de plusieurs os de la main par fixateur externe",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCB002",
    designation:
      "Ostéosynthèse de fractures articulaires de plusieurs os de la main par broche, à foyer fermé",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA004",
    designation:
      "Ostéosynthèse de fractures articulaires de plusieurs os de la main, à foyer ouvert",
    tarifSecteur1Optam: "202.07",
    tarifHorsSecteur1HorsOptam: "178.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCB001",
    designation:
      "Ostéosynthèse préventive d'un métacarpien pour lésion ostéolytique, à foyer fermé",
    tarifSecteur1Optam: "99.97",
    tarifHorsSecteur1HorsOptam: "84.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDCA002",
    designation:
      "Ostéosynthèse préventive d'un métacarpien pour lésion ostéolytique, à foyer ouvert",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "108.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDPA002",
    designation: "Ostéotomie d'un os de la main",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDPA004",
    designation:
      "Ostéotomie d'un os de la main, avec libération de tendon et/ou libération mobilisatrice articulaire",
    tarifSecteur1Optam: "303.05",
    tarifHorsSecteur1HorsOptam: "303.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDPA005",
    designation:
      "Ostéotomie du premier métacarpien pour malformation congénitale",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDPA003",
    designation: "Ostéotomie de plusieurs os de la main",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDPA001",
    designation:
      "Ostéotomie de plusieurs os de la main, avec libération de tendon et/ou libération mobilisatrice articulaire",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA002",
    designation:
      "Exérèse partielle d'un os de la main sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDGA003",
    designation:
      "Évidement d'un os de la main sans comblement, par abord direct",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "109.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDGA004",
    designation:
      "Évidement d'un os de la main avec comblement, par abord direct",
    tarifSecteur1Optam: "219.45",
    tarifHorsSecteur1HorsOptam: "219.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA001",
    designation:
      "Résection segmentaire d'une phalange ou d'une articulation interphalangienne du pouce avec rétablissement de la continuité, pour triphalangie",
    tarifSecteur1Optam: "250.99",
    tarifHorsSecteur1HorsOptam: "250.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDFA007",
    designation:
      "Résection complète d'une phalange du pouce avec rétablissement de la continuité, pour triphalangie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDMA001",
    designation:
      "Reconstruction d'un os de la main par greffe ou matériau inerte non prothétique",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "224.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDGB001",
    designation:
      "Ablation de matériel d'ostéosynthèse enfoui de la main, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MDGA002",
    designation:
      "Ablation de matériel d'ostéosynthèse de la main, par abord direct",
    tarifSecteur1Optam: "92.17",
    tarifHorsSecteur1HorsOptam: "71.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDGA005",
    designation: "Désépiphysiodèse de phalange triangulaire de doigt",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCAA001",
    designation:
      "Allongement extemporané de la diaphyse d'un os de l'avant-bras, avec autogreffe osseuse",
    tarifSecteur1Optam: "343.16",
    tarifHorsSecteur1HorsOptam: "326.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDAA001",
    designation:
      "Allongement osseux extemporané à la main, avec autogreffe osseuse",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "306.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MBAA002",
    designation:
      "Allongement osseux progressif au bras ou à l'avant-bras par système externe",
    tarifSecteur1Optam: "396.33",
    tarifHorsSecteur1HorsOptam: "335.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDAA002",
    designation: "Allongement osseux progressif à la main par système externe",
    tarifSecteur1Optam: "345.28",
    tarifHorsSecteur1HorsOptam: "305.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA006",
    designation:
      "Exérèse partielle d'un os du membre supérieur sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZGA003",
    designation:
      "Évidement d'un os du membre supérieur sans comblement, par abord direct",
    tarifSecteur1Optam: "180.09",
    tarifHorsSecteur1HorsOptam: "148.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZGA004",
    designation:
      "Évidement d'un os du membre supérieur avec comblement, par abord direct",
    tarifSecteur1Optam: "227.59",
    tarifHorsSecteur1HorsOptam: "224.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZMP002",
    designation:
      "Confection d'un appareil rigide thoracobrachial pour immobilisation initiale de fracture du membre supérieur, sans réduction",
    tarifSecteur1Optam: "64.52",
    tarifHorsSecteur1HorsOptam: "63.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZMP007",
    designation:
      "Confection d'un appareil rigide brachio-antébrachio-palmaire pour immobilisation initiale de fracture du membre supérieur, sans réduction",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZMP013",
    designation:
      "Confection d'un appareil rigide antébrachiopalmaire pour immobilisation initiale de fracture du membre supérieur, sans réduction",
    tarifSecteur1Optam: "34.03",
    tarifHorsSecteur1HorsOptam: "28.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZMP004",
    designation:
      "Confection d'un appareil rigide au poignet et/ou à la main pour immobilisation initiale de fracture du membre supérieur, sans réduction ",
    tarifSecteur1Optam: "29.07",
    tarifHorsSecteur1HorsOptam: "25.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MEEP001",
    designation:
      "Réduction orthopédique d'une luxation ou luxation-fracture acromioclaviculaire ou sternoclaviculaire",
    tarifSecteur1Optam: "35.45",
    tarifHorsSecteur1HorsOptam: "33.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MEEA004",
    designation:
      "Réduction d'une luxation acromioclaviculaire ou sternoclaviculaire, par abord direct",
    tarifSecteur1Optam: "139.67",
    tarifHorsSecteur1HorsOptam: "133.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA001",
    designation:
      "Arthroplastie sternoclaviculaire par résection de l'extrémité médiale de la clavicule, par arthrotomie",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "153.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMC001",
    designation:
      "Arthroplastie acromioclaviculaire par résection de l'extrémité latérale de la clavicule, par arthroscopie",
    tarifSecteur1Optam: "209.86",
    tarifHorsSecteur1HorsOptam: "174.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA011",
    designation:
      "Arthroplastie acromioclaviculaire par résection de l'extrémité latérale de la clavicule, par arthrotomie",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MERP001",
    designation:
      "Mobilisation de l'articulation scapulohumérale à visée thérapeutique, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "61.68",
    tarifHorsSecteur1HorsOptam: "43.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEEP002",
    designation: "Réduction orthopédique d'une luxation scapulohumérale",
    tarifSecteur1Optam: "39.7",
    tarifHorsSecteur1HorsOptam: "36.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEEP003",
    designation:
      "Réduction orthopédique d'une luxation scapulohumérale avec fracture de l'extrémité proximale de l'humérus homolatéral",
    tarifSecteur1Optam: "107.11",
    tarifHorsSecteur1HorsOptam: "107.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEEA001",
    designation:
      "Réduction orthopédique d'une luxation scapulohumérale, avec ostéosynthèse de fracture de l'extrémité proximale de l'humérus homolatéral",
    tarifSecteur1Optam: "247.44",
    tarifHorsSecteur1HorsOptam: "233.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEEA003",
    designation: "Réduction d'une luxation scapulohumérale, par arthrotomie",
    tarifSecteur1Optam: "185.05",
    tarifHorsSecteur1HorsOptam: "177.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEEA002",
    designation:
      "Réduction d'une luxation scapulohumérale par arthrotomie, avec ostéosynthèse de fracture homolatérale de la glène, du col de la scapula et/ou de tubercule huméral",
    tarifSecteur1Optam: "292.11",
    tarifHorsSecteur1HorsOptam: "263.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEJC001",
    designation:
      "Nettoyage de l'articulation scapulohumérale, par arthroscopie",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "220.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEJA001",
    designation: "Nettoyage de l'articulation scapulohumérale, par arthrotomie",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "188.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEDA001",
    designation: "Arthrodèse scapulohumérale, par arthrotomie",
    tarifSecteur1Optam: "514.73",
    tarifHorsSecteur1HorsOptam: "386.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA018",
    designation:
      "Confection d'une butée glénoïdale osseuse ou ostéomusculaire, par abord direct",
    tarifSecteur1Optam: "301.33",
    tarifHorsSecteur1HorsOptam: "275.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA005",
    designation:
      "Confection d'une butée glénoïdale par prélèvement coracoïdien, par abord direct",
    tarifSecteur1Optam: "313.38",
    tarifHorsSecteur1HorsOptam: "282.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMC003",
    designation: "Acromioplastie sans prothèse, par arthroscopie",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "280.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA006",
    designation: "Acromioplastie sans prothèse, par abord direct",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "211.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMC005",
    designation:
      "Acromioplastie sans prothèse avec arthroplastie acromioclaviculaire par résection de l'extrémité latérale de la clavicule, par arthroscopie",
    tarifSecteur1Optam: "392.79",
    tarifHorsSecteur1HorsOptam: "350.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA017",
    designation:
      "Acromioplastie sans prothèse avec arthroplastie acromioclaviculaire par résection de l'extrémité latérale de la clavicule, par abord direct",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA015",
    designation:
      "Arthroplastie scapulohumérale sans prothèse, par abord direct",
    tarifSecteur1Optam: "253.82",
    tarifHorsSecteur1HorsOptam: "235",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA010",
    designation:
      "Remplacement de l'articulation scapulohumérale par prothèse humérale unipolaire ou par cupule mobile",
    tarifSecteur1Optam: "352.37",
    tarifHorsSecteur1HorsOptam: "292.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA009",
    designation:
      "Remplacement de l'articulation scapulohumérale par prothèse humérale unipolaire ou par cupule mobile, avec ostéotomie de l'extrémité proximale de l'humérus",
    tarifSecteur1Optam: "419.73",
    tarifHorsSecteur1HorsOptam: "392.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA005",
    designation:
      "Remplacement de l'articulation scapulohumérale par prothèse humérale unipolaire ou à cupule mobile, avec réinsertion, suture et/ou autoplastie de la coiffe des rotateurs de l'épaule",
    tarifSecteur1Optam: "457.31",
    tarifHorsSecteur1HorsOptam: "427.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA006",
    designation:
      "Remplacement de l'articulation scapulohumérale par prothèse totale",
    tarifSecteur1Optam: "498.43",
    tarifHorsSecteur1HorsOptam: "447.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA007",
    designation:
      "Remplacement de l'articulation scapulohumérale par prothèse totale, avec ostéotomie de l'extrémité proximale de l'humérus",
    tarifSecteur1Optam: "545.22",
    tarifHorsSecteur1HorsOptam: "526.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA008",
    designation:
      "Remplacement de l'articulation scapulohumérale par prothèse totale, avec réinsertion, suture et/ou autoplastie de la coiffe des rotateurs de l'épaule",
    tarifSecteur1Optam: "574.29",
    tarifHorsSecteur1HorsOptam: "556.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEGA002",
    designation: "Ablation d'une prothèse de l'articulation scapulohumérale",
    tarifSecteur1Optam: "369.39",
    tarifHorsSecteur1HorsOptam: "297.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEGA001",
    designation:
      "Ablation d'une prothèse de l'articulation scapulohumérale, avec arthrodèse scapulohumérale",
    tarifSecteur1Optam: "823.15",
    tarifHorsSecteur1HorsOptam: "613.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA004",
    designation:
      "Changement d'une prothèse humérale ou de la pièce glénoïdale d'une prothèse totale scapulohumérale, sans reconstruction osseuse",
    tarifSecteur1Optam: "574.29",
    tarifHorsSecteur1HorsOptam: "468.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA002",
    designation:
      "Changement d'une prothèse humérale et/ou de la pièce glénoïdale d'une prothèse totale scapulohumérale, avec reconstruction osseuse",
    tarifSecteur1Optam: "692.69",
    tarifHorsSecteur1HorsOptam: "572.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA001",
    designation: "Changement d'une prothèse totale scapulohumérale",
    tarifSecteur1Optam: "628.88",
    tarifHorsSecteur1HorsOptam: "570.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEKA003",
    designation:
      "Changement d'une prothèse humérale pour une prothèse totale scapulohumérale",
    tarifSecteur1Optam: "587.05",
    tarifHorsSecteur1HorsOptam: "546.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MELA001",
    designation: "Repose d'une prothèse de l'articulation scapulohumérale",
    tarifSecteur1Optam: "458.01",
    tarifHorsSecteur1HorsOptam: "423.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEFA004",
    designation: "Huméroscapulectomie [Arthrectomie monobloc de l'épaule]",
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "454.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA003",
    designation:
      "Reconstruction osseuse de l'épaule avec arthrodèse, après résection segmentaire",
    tarifSecteur1Optam: "504.1",
    tarifHorsSecteur1HorsOptam: "415.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA009",
    designation:
      "Reconstruction de l'articulation de l'épaule par prothèse massive ou sur mesure, après résection segmentaire",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MELH001",
    designation:
      "Injection sous pression d'un agent pharmacologique dans l'articulation scapulohumérale pour libération capsulaire, par voie transcutanée avec guidage radiologique, avec mobilisation articulaire sous anesthésie locale",
    tarifSecteur1Optam: "42.21",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MEPC001",
    designation:
      "Libération mobilisatrice de l'articulation scapulohumérale et/ou subacromiale, par arthroscopie",
    tarifSecteur1Optam: "235.39",
    tarifHorsSecteur1HorsOptam: "206.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEPA001",
    designation:
      "Libération mobilisatrice de l'articulation scapulohumérale et/ou subacromiale, par arthrotomie",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "209.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEFC002",
    designation:
      "Synovectomie totale de l'articulation scapulohumérale, par arthroscopie",
    tarifSecteur1Optam: "315.51",
    tarifHorsSecteur1HorsOptam: "253.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEFA003",
    designation:
      "Synovectomie totale de l'articulation scapulohumérale, par arthrotomie",
    tarifSecteur1Optam: "280.06",
    tarifHorsSecteur1HorsOptam: "232.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMC002",
    designation:
      "Capsuloplastie antérieure ou postérieure de l'articulation scapulohumérale, par arthroscopie",
    tarifSecteur1Optam: "389.95",
    tarifHorsSecteur1HorsOptam: "313.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA012",
    designation:
      "Capsuloplastie antérieure ou postérieure de l'articulation scapulohumérale, par abord direct",
    tarifSecteur1Optam: "327.56",
    tarifHorsSecteur1HorsOptam: "277.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA014",
    designation:
      "Capsuloplastie antérieure et postérieure de l'articulation scapulohumérale, par abord direct",
    tarifSecteur1Optam: "398.46",
    tarifHorsSecteur1HorsOptam: "318.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA008",
    designation:
      "Capsuloplastie antérieure et postérieure de l'articulation scapulohumérale avec ostéotomie glénoïdale, par abord direct",
    tarifSecteur1Optam: "427.53",
    tarifHorsSecteur1HorsOptam: "397.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMA016",
    designation:
      "Capsuloplastie antérieure et postérieure de l'articulation scapulohumérale avec ostéotomie humérale, par abord direct",
    tarifSecteur1Optam: "438.87",
    tarifHorsSecteur1HorsOptam: "403.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEMC004",
    designation:
      "Réparation du bourrelet glénoïdal scapulohuméral, par arthroscopie",
    tarifSecteur1Optam: "312.67",
    tarifHorsSecteur1HorsOptam: "269.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEFC001",
    designation:
      "Résection du bourrelet glénoïdal scapulohuméral, par arthroscopie",
    tarifSecteur1Optam: "240.35",
    tarifHorsSecteur1HorsOptam: "227.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MEFA001",
    designation:
      "Résection du bourrelet glénoïdal scapulohuméral, par arthrotomie",
    tarifSecteur1Optam: "229.01",
    tarifHorsSecteur1HorsOptam: "220.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFRP001",
    designation:
      "Mobilisation de l'articulation du coude à visée thérapeutique, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "56.01",
    tarifHorsSecteur1HorsOptam: "40.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFEP001",
    designation: "Réduction orthopédique d'une pronation douloureuse du coude",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MFEP002",
    designation:
      "Réduction orthopédique d'une luxation ou d'une luxation-fracture du coude",
    tarifSecteur1Optam: "63.1",
    tarifHorsSecteur1HorsOptam: "62.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFEA002",
    designation:
      "Réduction orthopédique d'une luxation du coude, avec ostéosynthèse de fracture de l'épicondyle médial ou latéral de l'humérus à foyer ouvert",
    tarifSecteur1Optam: "182.88",
    tarifHorsSecteur1HorsOptam: "182.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFEB001",
    designation:
      "Réduction orthopédique d'une luxation du coude et/ou de l'articulation radio-ulnaire proximale, avec ostéosynthèse de fracture de l'extrémité proximale du radius ou de l'ulna à foyer fermé",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "162.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFEA001",
    designation: "Réduction d'une luxation du coude, par arthrotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFEA003",
    designation:
      "Réduction d'une luxation du coude et/ou de l'articulation radio-ulnaire proximale par arthrotomie, avec ostéosynthèse de fracture de l'extrémité proximale du radius ou de l'ulna à foyer ouvert",
    tarifSecteur1Optam: "220",
    tarifHorsSecteur1HorsOptam: "220",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFJC001",
    designation: "Nettoyage de l'articulation du coude, par arthroscopie",
    tarifSecteur1Optam: "217.66",
    tarifHorsSecteur1HorsOptam: "178.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFJA001",
    designation: "Nettoyage de l'articulation du coude, par arthrotomie",
    tarifSecteur1Optam: "184.34",
    tarifHorsSecteur1HorsOptam: "142.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFDA001",
    designation: "Arthrodèse du coude, par arthrotomie",
    tarifSecteur1Optam: "425.4",
    tarifHorsSecteur1HorsOptam: "334.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFDA002",
    designation:
      "Arthrorise du coude par butée, ténodèse ou capsulodèse, par abord direct",
    tarifSecteur1Optam: "298.49",
    tarifHorsSecteur1HorsOptam: "238.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFMA005",
    designation: "Arthroplastie du coude sans prothèse, par abord direct",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "326.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MCKA002",
    designation:
      "Remplacement de la tête radiale par prothèse, par abord direct",
    tarifSecteur1Optam: "225.46",
    tarifHorsSecteur1HorsOptam: "201",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFKA003",
    designation:
      "Remplacement de l'articulation du coude par prothèse totale, par abord direct",
    tarifSecteur1Optam: "531.75",
    tarifHorsSecteur1HorsOptam: "440.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFGA001",
    designation: "Ablation d'une prothèse totale du coude",
    tarifSecteur1Optam: "323.3",
    tarifHorsSecteur1HorsOptam: "270.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFKA001",
    designation:
      "Changement partiel ou total d'une prothèse articulaire du coude",
    tarifSecteur1Optam: "619.67",
    tarifHorsSecteur1HorsOptam: "539",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFFA001",
    designation:
      '"Résection ""en bloc"" du coude [Arthrectomie monobloc du coude]"',
    tarifSecteur1Optam: "473.61",
    tarifHorsSecteur1HorsOptam: "344.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFMA001",
    designation:
      "Reconstruction osseuse du coude avec arthrodèse, après résection segmentaire",
    tarifSecteur1Optam: "489.92",
    tarifHorsSecteur1HorsOptam: "407.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFMA003",
    designation:
      "Reconstruction de l'articulation du coude par prothèse massive ou sur mesure, après résection segmentaire",
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "346.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFPC001",
    designation:
      "Libération mobilisatrice de l'articulation du coude, par arthroscopie",
    tarifSecteur1Optam: "338.19",
    tarifHorsSecteur1HorsOptam: "266.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFPA002",
    designation:
      "Libération mobilisatrice de l'articulation du coude, par abord direct",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "249.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFPA003",
    designation:
      "Libération mobilisatrice de l'articulation du coude avec libération du nerf ulnaire, par abord direct",
    tarifSecteur1Optam: "342.45",
    tarifHorsSecteur1HorsOptam: "290.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFPA001",
    designation:
      "Libération mobilisatrice de l'articulation du coude avec résection d'ostéome synostosique, par abord direct",
    tarifSecteur1Optam: "348.83",
    tarifHorsSecteur1HorsOptam: "307.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFFA003",
    designation: "Exérèse de kyste synovial du coude, par abord direct",
    tarifSecteur1Optam: "85.08",
    tarifHorsSecteur1HorsOptam: "66.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFFC001",
    designation: "Synovectomie totale du coude, par arthroscopie",
    tarifSecteur1Optam: "321.18",
    tarifHorsSecteur1HorsOptam: "256.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFFA002",
    designation:
      "Synovectomie totale du coude, par arthrotomie antérieure et par arthrotomie postérieure",
    tarifSecteur1Optam: "306.29",
    tarifHorsSecteur1HorsOptam: "247.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MFCA001",
    designation:
      "Suture ou plastie de l'appareil capsuloligamentaire collatéral de l'articulation du coude, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGRP001",
    designation:
      "Mobilisation d'articulation du poignet et/ou de la main à visée thérapeutique, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "56.01",
    tarifHorsSecteur1HorsOptam: "40.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGEA002",
    designation: "Réduction d'une luxation du poignet, par arthrotomie",
    tarifSecteur1Optam: "238.22",
    tarifHorsSecteur1HorsOptam: "190.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGEA001",
    designation:
      "Réduction d'une luxation du poignet avec ostéosynthèse de fracture d'un os du carpe, par arthrotomie",
    tarifSecteur1Optam: "298.49",
    tarifHorsSecteur1HorsOptam: "249.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGJC001",
    designation: "Nettoyage des articulations du poignet, par arthroscopie",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "164.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGJA001",
    designation: "Nettoyage des articulations du poignet, par arthrotomie",
    tarifSecteur1Optam: "134.71",
    tarifHorsSecteur1HorsOptam: "113.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGDA002",
    designation: "Arthrodèse radiocarpienne, par arthrotomie",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "231.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGDA001",
    designation: "Arthrodèse intracarpienne, par arthrotomie",
    tarifSecteur1Optam: "250.99",
    tarifHorsSecteur1HorsOptam: "198.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGMA005",
    designation: "Création d'une butée ulnaire au poignet, par abord direct",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "166.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGMA003",
    designation: "Arthroplastie du poignet sans prothèse, pour radialisation",
    tarifSecteur1Optam: "336.78",
    tarifHorsSecteur1HorsOptam: "287.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGKA003",
    designation:
      "Remplacement de l'articulation du poignet par prothèse partielle, par abord direct",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "237.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGKA002",
    designation:
      "Remplacement de l'articulation radiocarpienne par prothèse totale, par abord direct",
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "320.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGGA002",
    designation: "Ablation d'une prothèse articulaire du poignet",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGGA001",
    designation: "Ablation d'une prothèse totale radiocarpienne",
    tarifSecteur1Optam: "219.08",
    tarifHorsSecteur1HorsOptam: "210.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGKA001",
    designation:
      "Changement partiel ou total d'une prothèse articulaire du poignet",
    tarifSecteur1Optam: "428.24",
    tarifHorsSecteur1HorsOptam: "384.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA001",
    designation:
      '"Résection ""en bloc"" du poignet [Arthrectomie monobloc du poignet]"',
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "285.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGMA002",
    designation:
      "Reconstruction osseuse du poignet avec arthrodèse, après résection segmentaire",
    tarifSecteur1Optam: "304.16",
    tarifHorsSecteur1HorsOptam: "272.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGMA006",
    designation:
      "Reconstruction de l'articulation du poignet par prothèse massive ou sur mesure, après résection segmentaire",
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "320.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGPA001",
    designation:
      "Libération mobilisatrice des articulations du poignet, par abord direct",
    tarifSecteur1Optam: "197.1",
    tarifHorsSecteur1HorsOptam: "166.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA007",
    designation: "Exérèse de kyste synovial du poignet, par abord direct",
    tarifSecteur1Optam: "81.54",
    tarifHorsSecteur1HorsOptam: "64.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA004",
    designation:
      "Exérèse de kyste synovial avec ténosynovectomie des extenseurs au poignet, par abord direct",
    tarifSecteur1Optam: "229.9",
    tarifHorsSecteur1HorsOptam: "229.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFC001",
    designation: "Synovectomie radio-ulnaire distale, par arthroscopie",
    tarifSecteur1Optam: "188.59",
    tarifHorsSecteur1HorsOptam: "162.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA002",
    designation: "Synovectomie radio-ulnaire distale, par arthrotomie",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFC002",
    designation:
      "Synovectomie radiocarpienne et/ou intracarpienne, par arthroscopie",
    tarifSecteur1Optam: "239.64",
    tarifHorsSecteur1HorsOptam: "191.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA005",
    designation:
      "Synovectomie radiocarpienne et/ou intracarpienne, par arthrotomie",
    tarifSecteur1Optam: "153.85",
    tarifHorsSecteur1HorsOptam: "141.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFC003",
    designation:
      "Synovectomie totale des articulations du poignet, par arthroscopie",
    tarifSecteur1Optam: "253.82",
    tarifHorsSecteur1HorsOptam: "226.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGFA003",
    designation:
      "Synovectomie totale des articulations du poignet, par arthrotomie",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "198.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGCC001",
    designation:
      "Suture et/ou réinsertion de ligament articulaire du poignet, par arthroscopie",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "165.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGCA001",
    designation:
      "Suture et/ou réinsertion de ligament articulaire du poignet, par arthrotomie",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "171.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MGMA004",
    designation:
      "Capsuloplastie d'une articulation du poignet, par abord direct",
    tarifSecteur1Optam: "165.91",
    tarifHorsSecteur1HorsOptam: "148.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHJA001",
    designation:
      "Évacuation de collection articulaire de la main, par arthrotomie",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "96.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHEP004",
    designation:
      "Réduction orthopédique d'une luxation ou d'une luxation-fracture carpométacarpienne",
    tarifSecteur1Optam: "71.61",
    tarifHorsSecteur1HorsOptam: "59.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHEP003",
    designation:
      "Réduction orthopédique d'une luxation ou d'une luxation-fracture trapézométacarpienne",
    tarifSecteur1Optam: "71.61",
    tarifHorsSecteur1HorsOptam: "59.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHEP002",
    designation:
      "Réduction orthopédique d'une luxation ou d'une luxation-fracture métacarpophalangienne ou interphalangienne d'un doigt",
    tarifSecteur1Optam: "33.44",
    tarifHorsSecteur1HorsOptam: "33.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHEP001",
    designation:
      "Réduction orthopédique de plusieurs luxations et/ou luxations-fractures métacarpophalangiennes et/ou interphalangiennes de doigt",
    tarifSecteur1Optam: "68.77",
    tarifHorsSecteur1HorsOptam: "66.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHEA004",
    designation:
      "Réduction d'une luxation ou luxation-fracture carpométacarpienne, par arthrotomie",
    tarifSecteur1Optam: "133.29",
    tarifHorsSecteur1HorsOptam: "129.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHEA002",
    designation:
      "Réduction d'une luxation ou luxation-fracture trapézométacarpienne, par arthrotomie",
    tarifSecteur1Optam: "168.03",
    tarifHorsSecteur1HorsOptam: "150.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHEA003",
    designation:
      "Réduction d'une luxation de l'articulation métacarpophalangienne ou interphalangienne d'un doigt, par arthrotomie",
    tarifSecteur1Optam: "107.77",
    tarifHorsSecteur1HorsOptam: "102.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHEA001",
    designation:
      "Réduction de luxation de plusieurs articulations métacarpophalangiennes et/ou interphalangiennes de doigt, par arthrotomie",
    tarifSecteur1Optam: "146.3",
    tarifHorsSecteur1HorsOptam: "146.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHDA002",
    designation:
      "Arthrodèse intermétacarpienne du premier espace interosseux, par arthrotomie",
    tarifSecteur1Optam: "211.99",
    tarifHorsSecteur1HorsOptam: "175.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHDA004",
    designation: "Arthrodèse carpométacarpienne, par arthrotomie",
    tarifSecteur1Optam: "184.34",
    tarifHorsSecteur1HorsOptam: "159.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHDA003",
    designation: "Arthrodèse trapézométacarpienne, par arthrotomie",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "182.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHDA005",
    designation:
      "Arthrodèse d'une articulation métacarpophalangienne ou interphalangienne d'un doigt, par arthrotomie",
    tarifSecteur1Optam: "153.85",
    tarifHorsSecteur1HorsOptam: "124.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHDA001",
    designation:
      "Arthrodèse de plusieurs articulations métacarpophalangiennes et/ou interphalangiennes de doigt, par arthrotomie",
    tarifSecteur1Optam: "190.72",
    tarifHorsSecteur1HorsOptam: "163.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHDB001",
    designation:
      "Arthrorise temporaire d'une articulation métacarpophalangienne ou interphalangienne d'un doigt par broche, par voie transcutanée",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMA005",
    designation: "Arthroplastie trapézométacarpienne",
    tarifSecteur1Optam: "236.81",
    tarifHorsSecteur1HorsOptam: "190.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHMA002",
    designation:
      "Arthroplastie d'une articulation métacarpophalangienne ou interphalangienne d'un doigt",
    tarifSecteur1Optam: "154.56",
    tarifHorsSecteur1HorsOptam: "133.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHMA001",
    designation:
      "Arthroplastie de plusieurs articulations métacarpophalangiennes et/ou interphalangiennes de doigt",
    tarifSecteur1Optam: "220.5",
    tarifHorsSecteur1HorsOptam: "184.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHMA004",
    designation:
      "Reconstruction d'une articulation de la main par transfert pédiculé",
    tarifSecteur1Optam: "378.61",
    tarifHorsSecteur1HorsOptam: "351.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHMA003",
    designation:
      "Reconstruction d'une articulation de la main par transfert libre avec anastomoses vasculaires",
    tarifSecteur1Optam: "748.7",
    tarifHorsSecteur1HorsOptam: "688.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHPA002",
    designation:
      "Libération mobilisatrice d'une articulation de la main, par abord direct",
    tarifSecteur1Optam: "141.09",
    tarifHorsSecteur1HorsOptam: "125.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHPA001",
    designation:
      "Libération mobilisatrice de plusieurs articulations de la main, par abord direct",
    tarifSecteur1Optam: "186.47",
    tarifHorsSecteur1HorsOptam: "160.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHPA004",
    designation:
      "Libération mobilisatrice d'une articulation de la main avec libération de tendon, par abord direct",
    tarifSecteur1Optam: "219.45",
    tarifHorsSecteur1HorsOptam: "219.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHPA003",
    designation:
      "Libération mobilisatrice de plusieurs articulations de la main avec libération de tendon, par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHFA002",
    designation:
      "Exérèse de kyste synovial ou mucoïde d'une articulation ou d'une gaine fibreuse de la main",
    tarifSecteur1Optam: "92.88",
    tarifHorsSecteur1HorsOptam: "71.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHFA001",
    designation:
      "Synovectomie d'une articulation métacarpophalangienne ou interphalangienne d'un doigt, par abord direct",
    tarifSecteur1Optam: "129.75",
    tarifHorsSecteur1HorsOptam: "119.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHFA003",
    designation:
      "Synovectomie de plusieurs articulations métacarpophalangiennes et/ou interphalangiennes de doigt, par abord direct",
    tarifSecteur1Optam: "167.32",
    tarifHorsSecteur1HorsOptam: "154.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHCA003",
    designation:
      "Suture ou plastie de l'appareil capsuloligamentaire d'une articulation métacarpophalangienne",
    tarifSecteur1Optam: "138.26",
    tarifHorsSecteur1HorsOptam: "124.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHCA002",
    designation:
      "Suture ou plastie de l'appareil capsuloligamentaire de l'articulation métacarpophalangienne du pouce, par abord direct",
    tarifSecteur1Optam: "166.62",
    tarifHorsSecteur1HorsOptam: "149.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MHCA001",
    designation:
      "Suture ou plastie de l'appareil capsuloligamentaire de plusieurs articulations métacarpophalangiennes",
    tarifSecteur1Optam: "183.63",
    tarifHorsSecteur1HorsOptam: "163.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZJB001",
    designation:
      "Évacuation de collection articulaire du membre supérieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZLB001",
    designation:
      "Injection thérapeutique d'agent pharmacologique dans une articulation ou une bourse séreuse du membre supérieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "26.13",
    tarifHorsSecteur1HorsOptam: "26.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZLH002",
    designation:
      "Injection thérapeutique d'agent pharmacologique dans une articulation ou une bourse séreuse du membre supérieur, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "18.76",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZLH001",
    designation:
      "Injection thérapeutique d'agent pharmacologique dans une articulation ou une bourse séreuse du membre supérieur, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "23.52",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MJEC001",
    designation:
      "Réinsertion ou suture d'un tendon de la coiffe des rotateurs de l'épaule, par arthroscopie",
    tarifSecteur1Optam: "367.97",
    tarifHorsSecteur1HorsOptam: "340.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA010",
    designation:
      "Réinsertion ou suture d'un tendon de la coiffe des rotateurs de l'épaule, par abord direct",
    tarifSecteur1Optam: "302.19",
    tarifHorsSecteur1HorsOptam: "302.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEC002",
    designation:
      "Réinsertion et/ou suture de plusieurs tendons de la coiffe des rotateurs de l'épaule, par arthroscopie",
    tarifSecteur1Optam: "425.4",
    tarifHorsSecteur1HorsOptam: "386.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA006",
    designation:
      "Réinsertion et/ou suture de plusieurs tendons de la coiffe des rotateurs de l'épaule, par abord direct",
    tarifSecteur1Optam: "355.92",
    tarifHorsSecteur1HorsOptam: "346.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA003",
    designation:
      "Réparation de la coiffe des rotateurs de l'épaule par autoplastie et/ou matériel prothétique, par abord direct",
    tarifSecteur1Optam: "384.99",
    tarifHorsSecteur1HorsOptam: "363.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA019",
    designation: "Réinsertion du muscle biceps brachial sur le radius",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "176.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA006",
    designation:
      "Désinsertion du muscle subscapulaire sans transfert musculaire",
    tarifSecteur1Optam: "219.08",
    tarifHorsSecteur1HorsOptam: "192.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA004",
    designation:
      "Désinsertion du muscle subscapulaire avec transfert musculaire",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "252.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA001",
    designation:
      "Désinsertion musculo-tendino-cutanée étendue pour surélévation congénitale de la scapula",
    tarifSecteur1Optam: "376.48",
    tarifHorsSecteur1HorsOptam: "336.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA012",
    designation:
      "Désinsertion ou allongement des muscles épicondyliens latéraux au coude, par abord direct",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA017",
    designation:
      "Transfert ostéomusculaire du processus coracoïde ou transfert du ligament coracoacromial pour stabilisation de la clavicule",
    tarifSecteur1Optam: "225.46",
    tarifHorsSecteur1HorsOptam: "196.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA016",
    designation:
      "Transposition musculotendineuse et/ou fixation de la scapula pour stabilisation",
    tarifSecteur1Optam: "332.52",
    tarifHorsSecteur1HorsOptam: "298.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA021",
    designation:
      "Transfert musculotendineux pour rétablissement de l'extension active du coude",
    tarifSecteur1Optam: "434.62",
    tarifHorsSecteur1HorsOptam: "357.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA012",
    designation:
      "Transfert musculotendineux pour rétablissement de la flexion active du coude",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "398.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFC001",
    designation:
      "Exérèse de calcification intratendineuse de la coiffe des rotateurs de l'épaule, par arthroscopie",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "232.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA001",
    designation:
      "Exérèse de calcification intratendineuse de la coiffe des rotateurs de l'épaule, par abord direct",
    tarifSecteur1Optam: "222.63",
    tarifHorsSecteur1HorsOptam: "194.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJGA001",
    designation:
      "Ablation de corps étranger de la coulisse bicipitale, par abord direct",
    tarifSecteur1Optam: "143.93",
    tarifHorsSecteur1HorsOptam: "101.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJDC001",
    designation:
      "Ténodèse et/ou résection de la portion articulaire du muscle long biceps brachial, par arthroscopie",
    tarifSecteur1Optam: "345.28",
    tarifHorsSecteur1HorsOptam: "337.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJDA001",
    designation:
      "Ténodèse et/ou résection de la portion articulaire du muscle long biceps brachial, par abord direct",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "160.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA005",
    designation: "Exérèse d'un hygroma du coude, par abord direct",
    tarifSecteur1Optam: "85.79",
    tarifHorsSecteur1HorsOptam: "62.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA012",
    designation:
      "Réparation de plaie de l'appareil extenseur d'un doigt par suture sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "106.35",
    tarifHorsSecteur1HorsOptam: "105.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA010",
    designation:
      "Réparation de plaie de l'appareil extenseur d'un doigt par greffe ou plastie d'allongement ou de retournement sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "146.76",
    tarifHorsSecteur1HorsOptam: "137.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA001",
    designation:
      "Suture de plaie d'un tendon d'un muscle fléchisseur des doigts sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "192.85",
    tarifHorsSecteur1HorsOptam: "164.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA002",
    designation:
      "Suture de plaies des 2 tendons des muscles fléchisseurs des doigts sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "236.81",
    tarifHorsSecteur1HorsOptam: "207.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA013",
    designation:
      "Reconstruction d'un tendon de la main par transplant libre, en un temps",
    tarifSecteur1Optam: "229.72",
    tarifHorsSecteur1HorsOptam: "203.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA009",
    designation:
      "Reconstruction d'un tendon de la main par transplant pédiculé, en un temps",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "338.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA004",
    designation:
      "Réinsertion transosseuse de tendon sur une phalange d'un doigt, par abord direct",
    tarifSecteur1Optam: "154.56",
    tarifHorsSecteur1HorsOptam: "137.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJAA002",
    designation:
      "Allongement des tendons et/ou désinsertion des muscles fléchisseurs de la main ou des doigts, par abord direct",
    tarifSecteur1Optam: "266.58",
    tarifHorsSecteur1HorsOptam: "259.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJAA001",
    designation:
      "Allongement des tendons et/ou des muscles extenseurs de la main ou des doigts, par abord direct",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "252.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA011",
    designation:
      "Libération de tendon au poignet avec ténosynovectomie, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA002",
    designation:
      "Libération des tendons des muscles fléchisseurs des doigts sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA009",
    designation:
      "Libération du tendon d'un muscle extenseur d'un doigt sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "146.76",
    tarifHorsSecteur1HorsOptam: "137.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA004",
    designation:
      "Ténosynovectomie des muscles extenseurs au poignet, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA014",
    designation:
      "Ténosynovectomie des muscles extenseurs au poignet avec synovectomie articulaire du poignet, par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA007",
    designation:
      "Ténosynovectomie des muscles extenseurs au poignet avec synovectomie articulaire du poignet, résection de l'extrémité distale de l'ulna et transfert tendineux, par abord direct",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA002",
    designation:
      "Ténosynovectomie des muscles extenseurs au poignet avec synovectomie articulaire du poignet, arthrodèse radio-ulnaire distale et ostéotomie de l'ulna, par abord direct",
    tarifSecteur1Optam: "355.3",
    tarifHorsSecteur1HorsOptam: "355.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA016",
    designation:
      "Ténosynovectomie des muscles extenseurs au poignet avec synovectomie articulaire du poignet et arthrodèse ou arthroplastie d'un doigt ou de 2 doigts, par abord direct",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "281.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA013",
    designation:
      "Ténosynovectomie des muscles extenseurs au poignet avec synovectomie articulaire du poignet et arthrodèse ou arthroplastie de 3 doigts ou plus, par abord direct",
    tarifSecteur1Optam: "339.61",
    tarifHorsSecteur1HorsOptam: "315.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA018",
    designation:
      "Ténosynovectomie des muscles fléchisseurs au poignet ou à la paume de la main, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA015",
    designation:
      "Ténosynovectomie des muscles fléchisseurs des doigts sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA012",
    designation:
      "Ténosynovectomie des muscles fléchisseurs des doigts sur plusieurs rayons de la main, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA005",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de l'extension active du poignet",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA008",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de l'extension active du poignet et de l'ouverture des doigts longs et du pouce",
    tarifSecteur1Optam: "321.18",
    tarifHorsSecteur1HorsOptam: "304.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA007",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de l'ouverture du pouce",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "179.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA002",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de l'ouverture des doigts longs",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "222.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA001",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de l'ouverture des doigts longs et du pouce",
    tarifSecteur1Optam: "305.58",
    tarifHorsSecteur1HorsOptam: "282.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA009",
    designation:
      "Transfert tendineux et/ou ténodèse pour rétablissement de la fermeture du pouce",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "214.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA018",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de la fermeture des doigts longs",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA015",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de la fermeture des doigts longs et du pouce",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA020",
    designation:
      "Transfert musculotendineux et/ou ténodèse pour rétablissement de la fermeture des doigts longs et du pouce et des fonctions intrinsèques des doigts",
    tarifSecteur1Optam: "385.7",
    tarifHorsSecteur1HorsOptam: "355.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA011",
    designation:
      "Transfert musculotendineux, ténodèse et/ou capsulodèse pour rétablissement des fonctions intrinsèques du pouce",
    tarifSecteur1Optam: "217.66",
    tarifHorsSecteur1HorsOptam: "196.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA013",
    designation:
      "Transfert musculotendineux, ténodèse et/ou capsulodèse pour rétablissement des fonctions intrinsèques des doigts longs",
    tarifSecteur1Optam: "275.8",
    tarifHorsSecteur1HorsOptam: "252.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA003",
    designation:
      "Transfert musculotendineux pour rétablissement des fonctions intrinsèques des doigts longs et du pouce",
    tarifSecteur1Optam: "385.7",
    tarifHorsSecteur1HorsOptam: "342.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJEA014",
    designation:
      "Transfert musculotendineux avec plastie du premier espace interosseux métacarpien pour hypoplasie du pouce",
    tarifSecteur1Optam: "256.66",
    tarifHorsSecteur1HorsOptam: "219.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA010",
    designation: "Incision ou excision d'un panaris superficiel",
    tarifSecteur1Optam: "38.29",
    tarifHorsSecteur1HorsOptam: "30.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA003",
    designation:
      "Excision d'un panaris profond de la pulpe des doigts [phlegmon pulpaire]",
    tarifSecteur1Optam: "72.32",
    tarifHorsSecteur1HorsOptam: "59.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJJA001",
    designation:
      "Évacuation d'un phlegmon de gaine synoviale digitale ou digitocarpienne, par abord direct",
    tarifSecteur1Optam: "141.09",
    tarifHorsSecteur1HorsOptam: "125.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJJA002",
    designation:
      "Évacuation d'un phlegmon de gaine synoviale digitale ou digitocarpienne avec ténosynovectomie, par abord direct",
    tarifSecteur1Optam: "115.57",
    tarifHorsSecteur1HorsOptam: "110.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJJA004",
    designation:
      "Évacuation d'un phlegmon de gaine synoviale digitale ou digitocarpienne étendu à la main et/ou à l'avant-bras, par abord direct",
    tarifSecteur1Optam: "224.75",
    tarifHorsSecteur1HorsOptam: "191.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJJA003",
    designation:
      "Évacuation de suppuration profonde de la main et/ou de l'avant-bras n'atteignant pas les gaines synoviales, par abord direct",
    tarifSecteur1Optam: "82.95",
    tarifHorsSecteur1HorsOptam: "65.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA009",
    designation:
      "Excision de fasciite nécrosante de l'avant-bras et/ou de la main",
    tarifSecteur1Optam: "160.94",
    tarifHorsSecteur1HorsOptam: "146.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZMA004",
    designation:
      "Suture de plaie de tendon avec suture de plaie du nerf médian, à la face antérieure du poignet",
    tarifSecteur1Optam: "387.11",
    tarifHorsSecteur1HorsOptam: "309.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZMA001",
    designation:
      "Réparation d'une hémisection antérieure du poignet avec ischémie complète de la main",
    tarifSecteur1Optam: "737.77",
    tarifHorsSecteur1HorsOptam: "737.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA006",
    designation:
      "Suture de plaies d'un tendon d'un muscle fléchisseur des doigts et d’un nerf digital palmaire, sur un rayon de la main",
    tarifSecteur1Optam: "265.88",
    tarifHorsSecteur1HorsOptam: "259.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA005",
    designation:
      "Suture de plaies d'un tendon d'un muscle fléchisseur des doigts et de 2 nerfs digitaux palmaires, sur un rayon de la main",
    tarifSecteur1Optam: "312.64",
    tarifHorsSecteur1HorsOptam: "312.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA008",
    designation:
      "Suture de plaies des 2 tendons des muscles fléchisseurs des doigts et d’un nerf digital palmaire, sur un rayon de la main",
    tarifSecteur1Optam: "303.45",
    tarifHorsSecteur1HorsOptam: "298.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA007",
    designation:
      "Suture de plaies des 2 tendons des muscles fléchisseurs des doigts et des 2 nerfs digitaux palmaires, sur un rayon de la main",
    tarifSecteur1Optam: "354.44",
    tarifHorsSecteur1HorsOptam: "354.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA003",
    designation:
      "Suture de plaies de tendon des muscles fléchisseurs des doigts, d'un nerf et d’une artère digitaux palmaires, sur un rayon de la main",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "400.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJCA010",
    designation:
      "Suture de plaies de tendon des muscles fléchisseurs des doigts, des 2 nerfs et d’une ou deux artères digitaux palmaires, sur un rayon de la main",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "438.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA002",
    designation:
      "Réparation de plaie de l'appareil extenseur d'un doigt avec suture de plaie d'une articulation, sur un rayon de la main",
    tarifSecteur1Optam: "190.72",
    tarifHorsSecteur1HorsOptam: "167.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA016",
    designation:
      "Réparation de plaie de l'appareil extenseur d'un doigt avec suture de plaie d'un nerf ou d'une artère digital palmaire, sur un rayon de la main",
    tarifSecteur1Optam: "276.07",
    tarifHorsSecteur1HorsOptam: "276.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA015",
    designation:
      "Réparation de plaie de l'appareil extenseur d'un doigt avec suture de plaies d'un nerf et d'une artère digitaux palmaires, sur un rayon de la main",
    tarifSecteur1Optam: "385.79",
    tarifHorsSecteur1HorsOptam: "385.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA007",
    designation:
      "Libération des tissus mous du premier espace interosseux métacarpien, par abord direct",
    tarifSecteur1Optam: "165.91",
    tarifHorsSecteur1HorsOptam: "162.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA008",
    designation:
      "Libération des tissus mous du premier espace interosseux métacarpien avec arthroplastie ou libération articulaire mobilisatrice, par abord direct",
    tarifSecteur1Optam: "254.53",
    tarifHorsSecteur1HorsOptam: "235.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA003",
    designation:
      "Libération des tissus mous du premier espace interosseux métacarpien avec arthrodèse de la colonne du pouce, par abord direct",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "239.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPB001",
    designation: "Fasciotomie [Aponévrotomie] palmaire, par voie transcutanée",
    tarifSecteur1Optam: "48.92",
    tarifHorsSecteur1HorsOptam: "41.54",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MJPA005",
    designation: "Fasciotomie [Aponévrotomie] palmaire, par abord direct",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA006",
    designation:
      "Fasciectomie [Aponévrectomie] palmodigitale sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA010",
    designation:
      "Fasciectomie [Aponévrectomie] palmodigitale sur plusieurs rayons de la main, par abord direct",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA013",
    designation:
      "Section ou plastie d'agrandissement de la partie cruciforme de la gaine fibreuse digitale [poulie de réflexion de tendon de muscle fléchisseur des doigts] sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJMA012",
    designation:
      "Reconstruction de la partie cruciforme de la gaine fibreuse digitale [poulie de réflexion de tendon de muscle fléchisseur des doigts] par autogreffe sur un rayon de la main, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZMA003",
    designation:
      "Reconstruction d'un doigt par transfert libre partiel vascularisé de doigt ou d'orteil",
    tarifSecteur1Optam: "940.84",
    tarifHorsSecteur1HorsOptam: "868.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZMA002",
    designation:
      "Reconstruction d'un doigt par transfert libre total de doigt ou d'orteil",
    tarifSecteur1Optam: "940.84",
    tarifHorsSecteur1HorsOptam: "868.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MDEA004",
    designation:
      "Reconstruction d'un doigt par transfert non vascularisé de phalange d'orteil, pour agénésie digitale",
    tarifSecteur1Optam: "216.25",
    tarifHorsSecteur1HorsOptam: "213.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA005",
    designation:
      "Translocation digitale et/ou carpo-métacarpo-digitale d'un rayon de la main",
    tarifSecteur1Optam: "401.29",
    tarifHorsSecteur1HorsOptam: "320.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA009",
    designation:
      "Translocation digitale et/ou carpo-métacarpo-digitale de deux rayons de la main",
    tarifSecteur1Optam: "394.86",
    tarifHorsSecteur1HorsOptam: "394.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA004",
    designation: "Pollicisation d'un moignon de doigt",
    tarifSecteur1Optam: "421.15",
    tarifHorsSecteur1HorsOptam: "402.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA008",
    designation: "Pollicisation d'un doigt complet",
    tarifSecteur1Optam: "511.9",
    tarifHorsSecteur1HorsOptam: "454.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA014",
    designation: "Amputation d'un pouce surnuméraire",
    tarifSecteur1Optam: "116.99",
    tarifHorsSecteur1HorsOptam: "76.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA015",
    designation:
      "Amputation d'un pouce surnuméraire, avec ostéotomie ou capsuloplastie",
    tarifSecteur1Optam: "253.94",
    tarifHorsSecteur1HorsOptam: "253.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA008",
    designation: "Amputation d'un doigt surnuméraire autre que le pouce",
    tarifSecteur1Optam: "101.39",
    tarifHorsSecteur1HorsOptam: "67.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA012",
    designation:
      "Amputation d'un doigt surnuméraire autre que le pouce avec résection osseuse",
    tarifSecteur1Optam: "115.57",
    tarifHorsSecteur1HorsOptam: "115.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJPA014",
    designation:
      "Séparation d'une syndactylie des doigts à squelette normal avec autoplastie cutanée locale et/ou greffe de peau",
    tarifSecteur1Optam: "245.31",
    tarifHorsSecteur1HorsOptam: "212.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZPA002",
    designation:
      "Séparation d'une syndactylie complexe des doigts avec ostéotomie ou résection osseuse, et autoplastie cutanée locale et/ou greffe de peau",
    tarifSecteur1Optam: "304.87",
    tarifHorsSecteur1HorsOptam: "264.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA017",
    designation:
      "Résection des tissus mous de la main avec plastie cutanée, sans résection nerveuse, pour mégadactylie",
    tarifSecteur1Optam: "220.5",
    tarifHorsSecteur1HorsOptam: "208.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA011",
    designation:
      "Résection des tissus mous de la main avec plastie cutanée et résection nerveuse, pour mégadactylie",
    tarifSecteur1Optam: "265.17",
    tarifHorsSecteur1HorsOptam: "251.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZMP001",
    designation:
      "Confection d'une contention souple d'une articulation du membre supérieur",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZMP015",
    designation:
      "Confection d'un appareil rigide thoracobrachial d'immobilisation du membre supérieur",
    tarifSecteur1Optam: "63.3",
    tarifHorsSecteur1HorsOptam: "63.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZMP011",
    designation:
      "Confection d'un appareil rigide d'immobilisation du membre supérieur prenant le coude",
    tarifSecteur1Optam: "34.57",
    tarifHorsSecteur1HorsOptam: "34.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZMP006",
    designation:
      "Confection d'un appareil rigide d'immobilisation de l'avant-bras, du poignet et/ou de la main ne prenant pas le coude",
    tarifSecteur1Optam: "26.23",
    tarifHorsSecteur1HorsOptam: "25.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MEMP001",
    designation:
      "Confection d'une attelle de posture ou de mobilisation de l'épaule",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MFMP001",
    designation:
      "Confection d'une attelle de posture ou de mobilisation du coude",
    tarifSecteur1Optam: "38.19",
    tarifHorsSecteur1HorsOptam: "35.32",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MGMP001",
    designation:
      "Confection d'une attelle de posture ou de mobilisation du poignet et/ou de la main",
    tarifSecteur1Optam: "44.89",
    tarifHorsSecteur1HorsOptam: "34.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP015",
    designation:
      "Confection unilatérale ou bilatérale de coussin d'abduction thoracobrachial",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP007",
    designation:
      "Confection d'une orthèse statique de posture antébrachiométacarpienne",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP001",
    designation: "Confection d'une orthèse statique antébrachiophalangienne",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP016",
    designation:
      "Confection d'une orthèse statique carpométacarpienne et/ou métacarpophalangienne",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMP007",
    designation: "Confection d'une orthèse statique d'un doigt",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMP002",
    designation: "Confection d'une orthèse statique de 2 doigts",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMP003",
    designation: "Confection d'une orthèse statique de 3 doigts ou plus",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP002",
    designation: "Confection d'une orthèse non articulée brachioantébrachiale",
    tarifSecteur1Optam: "32.83",
    tarifHorsSecteur1HorsOptam: "32.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP004",
    designation: "Confection d'une orthèse articulée brachioantébrachiale",
    tarifSecteur1Optam: "44.22",
    tarifHorsSecteur1HorsOptam: "38.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP008",
    designation:
      "Confection d'une orthèse dynamique antébrachiométacarpienne avec 1 élément moteur",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP009",
    designation:
      "Confection d'une orthèse dynamique antébrachiométacarpienne avec 2 éléments moteurs",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP012",
    designation:
      "Confection d'une orthèse dynamique antébrachiométacarpienne avec 3 éléments moteurs ou plus",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP010",
    designation:
      "Confection d'une orthèse dynamique antébrachiophalangienne avec 1 élément moteur",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP011",
    designation:
      "Confection d'une orthèse dynamique antébrachiophalangienne avec 2 éléments moteurs",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP018",
    designation:
      "Confection d'une orthèse dynamique antébrachiophalangienne avec 3 éléments moteurs ou plus",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP006",
    designation:
      "Confection d'une orthèse dynamique carpométacarpienne et/ou métacarpophalangienne avec 1 élément moteur",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP003",
    designation:
      "Confection d'une orthèse dynamique carpométacarpienne et/ou métacarpophalangienne avec 2 éléments moteurs",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZDMP005",
    designation:
      "Confection d'une orthèse dynamique carpométacarpienne et/ou métacarpophalangienne avec 3 éléments moteurs ou plus",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMP004",
    designation:
      "Confection d'une orthèse dynamique d'un doigt avec un élément moteur",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMP001",
    designation:
      "Confection d'une orthèse dynamique de 2 doigts avec 2 éléments moteurs",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MHMP006",
    designation:
      "Confection d'une orthèse dynamique de 3 doigts ou plus avec 3 éléments moteurs",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "MZFA009",
    designation: "Désarticulation interscapulothoracique",
    tarifSecteur1Optam: "448.72",
    tarifHorsSecteur1HorsOptam: "448.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA010",
    designation:
      "Désarticulation scapulohumérale [Désarticulation de l'épaule]",
    tarifSecteur1Optam: "658.66",
    tarifHorsSecteur1HorsOptam: "513.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA002",
    designation: "Amputation transhumérale",
    tarifSecteur1Optam: "389.95",
    tarifHorsSecteur1HorsOptam: "278.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA011",
    designation: "Désarticulation du coude",
    tarifSecteur1Optam: "309.83",
    tarifHorsSecteur1HorsOptam: "232.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA005",
    designation: "Amputation transradio-ulnaire",
    tarifSecteur1Optam: "411.22",
    tarifHorsSecteur1HorsOptam: "291.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA004",
    designation: "Désarticulation du poignet",
    tarifSecteur1Optam: "349.54",
    tarifHorsSecteur1HorsOptam: "255.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA001",
    designation: "Amputation complète d'un rayon de la main",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "195.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA003",
    designation: "Amputation complète de plusieurs rayons de la main",
    tarifSecteur1Optam: "328.98",
    tarifHorsSecteur1HorsOptam: "243.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA013",
    designation:
      "Amputation ou désarticulation d'un doigt, sans résection du métacarpien",
    tarifSecteur1Optam: "131.87",
    tarifHorsSecteur1HorsOptam: "85.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZFA007",
    designation:
      "Amputation et/ou désarticulation de plusieurs doigts, sans résection des métacarpiens",
    tarifSecteur1Optam: "151.73",
    tarifHorsSecteur1HorsOptam: "101.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA007",
    designation:
      "Réimplantation du membre supérieur sectionné au dessus du poignet",
    tarifSecteur1Optam: "1018.83",
    tarifHorsSecteur1HorsOptam: "913.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA011",
    designation: "Réimplantation de la main sectionnée au poignet",
    tarifSecteur1Optam: "1018.83",
    tarifHorsSecteur1HorsOptam: "913.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA002",
    designation: "Réimplantation de la main sectionnée au niveau du métacarpe",
    tarifSecteur1Optam: "1144.33",
    tarifHorsSecteur1HorsOptam: "986.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA010",
    designation: "Réimplantation d'un doigt",
    tarifSecteur1Optam: "736.73",
    tarifHorsSecteur1HorsOptam: "736.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA001",
    designation: "Réimplantation de 2 doigts",
    tarifSecteur1Optam: "1321.59",
    tarifHorsSecteur1HorsOptam: "1321.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA012",
    designation: "Réimplantation de 3 doigts",
    tarifSecteur1Optam: "1690.47",
    tarifHorsSecteur1HorsOptam: "1690.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MZEA003",
    designation: "Réimplantation de 4 ou 5 doigts",
    tarifSecteur1Optam: "1690.47",
    tarifHorsSecteur1HorsOptam: "1690.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEQM001",
    designation:
      "Échographie unilatérale ou bilatérale de la hanche du nouveau-né",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "NDQM900",
    designation: "Ostéodensitométrie du calcanéus par échographie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "NAQK015",
    designation:
      "Radiographie de la ceinture pelvienne [du bassin] selon 1 incidence",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NAQK007",
    designation:
      "Radiographie de la ceinture pelvienne [du bassin] selon 2 incidences",
    tarifSecteur1Optam: "33.25",
    tarifHorsSecteur1HorsOptam: "33.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NAQK023",
    designation:
      "Radiographie de la ceinture pelvienne [du bassin] selon 3 incidences ou plus",
    tarifSecteur1Optam: "46.55",
    tarifHorsSecteur1HorsOptam: "46.55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NAQK071",
    designation:
      "Radiographie de la ceinture pelvienne [du bassin] selon 1 incidence et radiographie unilatérale de l'articulation coxofémorale selon 1 ou 2 incidences",
    tarifSecteur1Optam: "23.47",
    tarifHorsSecteur1HorsOptam: "23.47",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NAQK049",
    designation:
      "Radiographie de la ceinture pelvienne [du bassin] selon 1 incidence et radiographie bilatérale de l'articulation coxofémorale selon 1 ou 2 incidences par côté",
    tarifSecteur1Optam: "31.29",
    tarifHorsSecteur1HorsOptam: "31.29",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NEQK010",
    designation:
      "Radiographie de l'articulation coxofémorale selon 1 ou 2 incidences",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NEQK035",
    designation:
      "Radiographie de l'articulation coxofémorale selon 3 incidences",
    tarifSecteur1Optam: "31.81",
    tarifHorsSecteur1HorsOptam: "31.81",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NEQK012",
    designation:
      "Radiographie de l'articulation coxofémorale selon 4 incidences ou plus",
    tarifSecteur1Optam: "34.44",
    tarifHorsSecteur1HorsOptam: "34.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NBQK001",
    designation: "Radiographie de la cuisse",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NFQK001",
    designation: "Radiographie unilatérale du genou selon 1 ou 2 incidences",
    tarifSecteur1Optam: "25.86",
    tarifHorsSecteur1HorsOptam: "25.86",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NFQK002",
    designation:
      "Radiographie bilatérale du genou selon 1 ou 2 incidences par côté",
    tarifSecteur1Optam: "25.86",
    tarifHorsSecteur1HorsOptam: "25.86",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NFQK003",
    designation: "Radiographie du genou selon 3 ou 4 incidences",
    tarifSecteur1Optam: "25.86",
    tarifHorsSecteur1HorsOptam: "25.86",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NFQK004",
    designation: "Radiographie du genou selon 5 incidences ou plus",
    tarifSecteur1Optam: "25.86",
    tarifHorsSecteur1HorsOptam: "25.86",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NCQK001",
    designation: "Radiographie de la jambe",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NGQK001",
    designation: "Radiographie de la cheville selon 1 à 3 incidences",
    tarifSecteur1Optam: "23.86",
    tarifHorsSecteur1HorsOptam: "23.86",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NGQK002",
    designation: "Radiographie de la cheville selon 4 incidences ou plus",
    tarifSecteur1Optam: "23.86",
    tarifHorsSecteur1HorsOptam: "23.86",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NDQK001",
    designation: "Radiographie unilatérale du pied selon 1 à 3 incidences",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NDQK002",
    designation:
      "Radiographie bilatérale du pied selon 1 à 3 incidences par côté",
    tarifSecteur1Optam: "23.94",
    tarifHorsSecteur1HorsOptam: "23.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NDQK003",
    designation: "Radiographie du pied selon 4 incidences ou plus",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NDQK004",
    designation:
      "Radiographie du pied selon 4 incidences ou plus, pour étude podométrique",
    tarifSecteur1Optam: "29.26",
    tarifHorsSecteur1HorsOptam: "29.26",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQK005",
    designation: "Radiographie de 2 segments du membre inférieur",
    tarifSecteur1Optam: "29.26",
    tarifHorsSecteur1HorsOptam: "29.26",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQK006",
    designation: "Radiographie de 3 segments du membre inférieur ou plus",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQK001",
    designation:
      "Téléradiographie unilatérale ou bilatérale du membre inférieur en totalité, de face en appui bipodal",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQK003",
    designation:
      "Téléradiographie bilatérale du membre inférieur en totalité, de face en appui monopodal l'un après l'autre",
    tarifSecteur1Optam: "45.22",
    tarifHorsSecteur1HorsOptam: "45.22",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NEQH002",
    designation: "Arthrographie de la hanche",
    tarifSecteur1Optam: "69.16",
    tarifHorsSecteur1HorsOptam: "69.16",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NFQH001",
    designation: "Arthrographie du genou",
    tarifSecteur1Optam: "106.4",
    tarifHorsSecteur1HorsOptam: "106.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NGQH001",
    designation: "Arthrographie de la cheville",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NHQH001",
    designation: "Arthrographie du pied et/ou des orteils",
    tarifSecteur1Optam: "39.9",
    tarifHorsSecteur1HorsOptam: "39.9",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQH002",
    designation:
      "Arthrographie du membre inférieur avec scanographie [Arthroscanner du membre inférieur]",
    tarifSecteur1Optam: "93.1",
    tarifHorsSecteur1HorsOptam: "93.1",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQK002",
    designation:
      "Scanographie unilatérale ou bilatérale de segment du membre inférieur, sans injection de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQH001",
    designation:
      "Scanographie unilatérale ou bilatérale de segment du membre inférieur, avec injection de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQH005",
    designation:
      "Scanographie de la hanche et du membre inférieur pour conception intégrée par ordinateur d'une prothèse ostéoarticulaire sur mesure",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQK004",
    designation: "Télémétrie des membres inférieurs par scanographie",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQN001",
    designation:
      "Remnographie [IRM] unilatérale ou bilatérale de segment du membre inférieur, sans injection de produit de contraste",
    tarifSecteur1Optam: "55",
    tarifHorsSecteur1HorsOptam: "55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NZQJ001",
    designation:
      "Remnographie [IRM] unilatérale ou bilatérale de segment du membre inférieur, avec injection de produit de contraste",
    tarifSecteur1Optam: "55",
    tarifHorsSecteur1HorsOptam: "55",
    regroupement: "ADI",
  },
  {
    codeCCAM: "NEQP002",
    designation:
      "Bilan fonctionnel de l'articulation coxofémorale, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "56.72",
    tarifHorsSecteur1HorsOptam: "36.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEQP001",
    designation:
      "Bilan fonctionnel de hanche instable non traumatique avec confection d'un appareillage rigide d'immobilisation externe, sous anesthésie générale",
    tarifSecteur1Optam: "124.78",
    tarifHorsSecteur1HorsOptam: "76.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEQH001",
    designation:
      "Bilan fonctionnel de hanche instable non traumatique avec arthrographie et confection d'un appareillage rigide d'immobilisation externe, sous anesthésie générale",
    tarifSecteur1Optam: "153.85",
    tarifHorsSecteur1HorsOptam: "69.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NFQP001",
    designation:
      "Bilan fonctionnel de l'articulation du genou, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "61.68",
    tarifHorsSecteur1HorsOptam: "39.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NFQP002",
    designation:
      "Bilan fonctionnel de l'articulation du genou avec confection d'un appareillage rigide d'immobilisation externe, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "85.79",
    tarifHorsSecteur1HorsOptam: "53.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NGQP001",
    designation:
      "Bilan fonctionnel de l'articulation de la cheville, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "43.96",
    tarifHorsSecteur1HorsOptam: "29.53",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHB002",
    designation:
      "Ponction ou cytoponction d'une articulation du membre inférieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "28.81",
    tarifHorsSecteur1HorsOptam: "22.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHH004",
    designation:
      "Ponction ou cytoponction d'une articulation du membre inférieur, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "24.79",
    tarifHorsSecteur1HorsOptam: "14.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHH001",
    designation:
      "Ponction ou cytoponction d'une articulation du membre inférieur, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "27.36",
    tarifHorsSecteur1HorsOptam: "14.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHB001",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre inférieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "52.93",
    tarifHorsSecteur1HorsOptam: "38.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NAHB001",
    designation: "Biopsie unicorticale de l'os coxal, par voie transcutanée",
    tarifSecteur1Optam: "64.32",
    tarifHorsSecteur1HorsOptam: "63.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NAHB002",
    designation:
      "Biopsie bicorticale de la crête iliaque, par voie transcutanée",
    tarifSecteur1Optam: "75.04",
    tarifHorsSecteur1HorsOptam: "69.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHH003",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre inférieur, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHH002",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre inférieur, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZHA001",
    designation:
      "Biopsie d'un os et/ou d'une articulation du membre inférieur, par abord direct",
    tarifSecteur1Optam: "167.32",
    tarifHorsSecteur1HorsOptam: "132.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAHA002",
    designation:
      "Biopsie de la corticale externe de l'os coxal, par abord direct",
    tarifSecteur1Optam: "170.87",
    tarifHorsSecteur1HorsOptam: "142.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAHA001",
    designation:
      "Biopsie de la corticale interne de l'os coxal, par abord direct",
    tarifSecteur1Optam: "250.99",
    tarifHorsSecteur1HorsOptam: "189.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEHA001",
    designation:
      "Biopsie d'une articulation de la ceinture pelvienne [du bassin], par abord direct",
    tarifSecteur1Optam: "221.92",
    tarifHorsSecteur1HorsOptam: "172.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEHA002",
    designation: "Biopsie de l'articulation coxofémorale, par abord direct",
    tarifSecteur1Optam: "171.58",
    tarifHorsSecteur1HorsOptam: "143.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEQC001",
    designation: "Exploration de l'articulation coxofémorale, par arthroscopie",
    tarifSecteur1Optam: "224.75",
    tarifHorsSecteur1HorsOptam: "183.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFQC001",
    designation: "Exploration de l'articulation du genou, par arthroscopie",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGQC001",
    designation:
      "Exploration de l'articulation de la cheville, par arthroscopie",
    tarifSecteur1Optam: "160.94",
    tarifHorsSecteur1HorsOptam: "146.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAEP002",
    designation:
      "Réduction orthopédique extemporanée de fracture et/ou de luxation de la ceinture pelvienne [du bassin]",
    tarifSecteur1Optam: "134.71",
    tarifHorsSecteur1HorsOptam: "108.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAEP001",
    designation:
      "Réduction orthopédique progressive de fracture et/ou de luxation de la ceinture pelvienne [du bassin], par traction continue ou suspension",
    tarifSecteur1Optam: "112.73",
    tarifHorsSecteur1HorsOptam: "96.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NACB001",
    designation:
      "Ostéosynthèse de fracture ou de fracture-luxation de la ceinture pelvienne [du bassin], à foyer fermé",
    tarifSecteur1Optam: "211.99",
    tarifHorsSecteur1HorsOptam: "193.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NACA001",
    designation:
      "Ostéosynthèse unifocale de fracture ou de fracture-luxation de la ceinture pelvienne [du bassin], à foyer ouvert",
    tarifSecteur1Optam: "336.78",
    tarifHorsSecteur1HorsOptam: "283.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NACA002",
    designation:
      "Ostéosynthèse plurifocale de fracture ou de fracture-luxation de la ceinture pelvienne [du bassin], à foyer ouvert",
    tarifSecteur1Optam: "397.04",
    tarifHorsSecteur1HorsOptam: "318.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NACA005",
    designation:
      "Ostéosynthèse de fracture de l'acétabulum, par abord postérieur",
    tarifSecteur1Optam: "383.57",
    tarifHorsSecteur1HorsOptam: "340.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NACA003",
    designation:
      "Ostéosynthèse de fracture de l'acétabulum, par abord antérieur",
    tarifSecteur1Optam: "404.84",
    tarifHorsSecteur1HorsOptam: "392.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NACA004",
    designation:
      "Ostéosynthèse de fracture des colonnes antérieure et postérieure de l'acétabulum, par un ou deux abords",
    tarifSecteur1Optam: "812.51",
    tarifHorsSecteur1HorsOptam: "689.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA004",
    designation: "Ostéotomie supraacétabulaire de l'os coxal par une section",
    tarifSecteur1Optam: "440.29",
    tarifHorsSecteur1HorsOptam: "387.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA003",
    designation:
      "Ostéotomie supraacétabulaire de l'os coxal par une section, avec ostéoplastie supraacétabulaire par butée",
    tarifSecteur1Optam: "497.01",
    tarifHorsSecteur1HorsOptam: "463.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA005",
    designation:
      "Ostéotomie supraacétabulaire de l'os coxal par une section, avec capsuloplastie",
    tarifSecteur1Optam: "511.19",
    tarifHorsSecteur1HorsOptam: "480.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA001",
    designation:
      "Ostéotomie supraacétabulaire de l'os coxal par une section, avec ostéotomie du fémur",
    tarifSecteur1Optam: "554.44",
    tarifHorsSecteur1HorsOptam: "519.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA007",
    designation:
      "Ostéotomie extraacétabulaire de l'os coxal par plusieurs sections",
    tarifSecteur1Optam: "484.96",
    tarifHorsSecteur1HorsOptam: "412.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA002",
    designation:
      "Ostéotomie extraacétabulaire de l'os coxal par plusieurs sections, avec capsuloplastie",
    tarifSecteur1Optam: "527.5",
    tarifHorsSecteur1HorsOptam: "490.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA008",
    designation:
      "Ostéotomie extraacétabulaire de l'os coxal par plusieurs sections, avec ostéotomie du fémur",
    tarifSecteur1Optam: "665.04",
    tarifHorsSecteur1HorsOptam: "583.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAPA006",
    designation: "Ostéotomie périacétabulaire de l'os coxal",
    tarifSecteur1Optam: "606.9",
    tarifHorsSecteur1HorsOptam: "483.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAFA002",
    designation:
      "Exérèse partielle de l'os coxal sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "203.48",
    tarifHorsSecteur1HorsOptam: "188.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAGA003",
    designation: "Évidement de l'os coxal sans comblement, par abord direct",
    tarifSecteur1Optam: "211.28",
    tarifHorsSecteur1HorsOptam: "166.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAGA002",
    designation: "Évidement de l'os coxal avec comblement, par abord direct",
    tarifSecteur1Optam: "258.79",
    tarifHorsSecteur1HorsOptam: "242.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAFA004",
    designation:
      "Résection partielle de l'aile iliaque ou du cadre obturateur n'interrompant pas la continuité de l'anneau pelvien",
    tarifSecteur1Optam: "286.44",
    tarifHorsSecteur1HorsOptam: "236.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAFA006",
    designation:
      '"Résection complète ""en bloc"" de l\'aile iliaque ou du cadre obturateur interrompant la continuité de l\'anneau pelvien"',
    tarifSecteur1Optam: "514.73",
    tarifHorsSecteur1HorsOptam: "430.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAFA003",
    designation:
      '"Résection complète ""en bloc"" partielle ou totale d\'un os coxal [hémibassin] emportant l\'acétabulum"',
    tarifSecteur1Optam: "1029.47",
    tarifHorsSecteur1HorsOptam: "794.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAFA001",
    designation:
      '"Résection ""en bloc"" de l\'os coxal ou du fémur avec arthrectomie monobloc de hanche"',
    tarifSecteur1Optam: "823.15",
    tarifHorsSecteur1HorsOptam: "674.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAFA005",
    designation: "Prélèvement d'un os coxal [hémibassin], sur un sujet décédé",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAMA002",
    designation:
      "Reconstruction de l'os coxal [hémibassin] après résection de la zone acétabulaire, sans prothèse coxale",
    tarifSecteur1Optam: "606.9",
    tarifHorsSecteur1HorsOptam: "545.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NAGA001",
    designation:
      "Ablation de matériel d'ostéosynthèse de l'acétabulum ou de l'os coxal, par abord direct",
    tarifSecteur1Optam: "200.65",
    tarifHorsSecteur1HorsOptam: "151.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBEP002",
    designation:
      "Réduction orthopédique extemporanée de fracture-décollement de l'épiphyse distale du fémur",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBEP001",
    designation:
      "Réduction orthopédique progressive de fracture du fémur, par traction continue collée",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NBEB001",
    designation:
      "Réduction orthopédique progressive de fracture du fémur, par traction continue transosseuse",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NBCA012",
    designation:
      "Ostéosynthèse de fracture parcellaire de la tête du fémur, par arthrotomie",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA005",
    designation:
      "Ostéosynthèse de fracture intracapsulaire du col [transcervicale] du fémur, de décollement épiphysaire ou d'épiphysiolyse de l'extrémité proximale du fémur",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA010",
    designation: "Ostéosynthèse de fracture extracapsulaire du col du fémur",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA008",
    designation: "Ostéosynthèse de fracture du grand trochanter",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA004",
    designation: "Ostéosynthèse du grand trochanter pour pseudarthrose",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA009",
    designation:
      "Ostéosynthèse de fractures homolatérales du col et de la diaphyse du fémur",
    tarifSecteur1Optam: "499.88",
    tarifHorsSecteur1HorsOptam: "499.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA006",
    designation:
      "Ostéosynthèse de fracture infratrochantérienne ou trochantérodiaphysaire du fémur",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCB001",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du fémur par fixateur externe ou broche, à foyer fermé",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCB002",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du fémur par matériel centromédullaire sans verrouillage distal, à foyer fermé",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCB004",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du fémur par matériel centromédullaire avec verrouillage distal, à foyer fermé",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "305.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA007",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du fémur, à foyer ouvert",
    tarifSecteur1Optam: "292.82",
    tarifHorsSecteur1HorsOptam: "287.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCB006",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité distale du fémur, à foyer fermé",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA014",
    designation:
      "Ostéosynthèse de fracture supracondylaire extraarticulaire du fémur, à foyer ouvert",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA015",
    designation:
      "Ostéosynthèse de fracture simple supracondylaire et intercondylaire du fémur, à foyer ouvert",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA003",
    designation:
      "Ostéosynthèse de fracture complexe supracondylaire et intercondylaire du fémur, à foyer ouvert",
    tarifSecteur1Optam: "442.42",
    tarifHorsSecteur1HorsOptam: "440.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA013",
    designation:
      "Ostéosynthèse de fracture d'un condyle fémoral, à foyer ouvert",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFDC001",
    designation:
      "Fixation de fragment ostéochondral intraarticulaire du genou, par arthroscopie",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "242.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFDA009",
    designation:
      "Fixation de fragment ostéochondral intraarticulaire du genou, par arthrotomie",
    tarifSecteur1Optam: "216.95",
    tarifHorsSecteur1HorsOptam: "213.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCB005",
    designation:
      "Ostéosynthèse préventive du fémur pour lésion ostéolytique, à foyer fermé",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA011",
    designation:
      "Ostéosynthèse préventive du fémur pour lésion ostéolytique, à foyer ouvert",
    tarifSecteur1Optam: "333.23",
    tarifHorsSecteur1HorsOptam: "311.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA019",
    designation: "Ostéotomie intraarticulaire du col du fémur",
    tarifSecteur1Optam: "606.9",
    tarifHorsSecteur1HorsOptam: "483.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA014",
    designation: "Ostéotomie du grand trochanter",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA020",
    designation:
      "Ostéotomie intertrochantérienne ou infratrochantérienne du fémur",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA013",
    designation: "Ostéotomie basicervicale du fémur",
    tarifSecteur1Optam: "504.1",
    tarifHorsSecteur1HorsOptam: "424.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA018",
    designation:
      "Ostéotomie unilatérale de valgisation-translation du col du fémur pour dysplasie congénitale",
    tarifSecteur1Optam: "392.79",
    tarifHorsSecteur1HorsOptam: "359.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA006",
    designation:
      "Ostéotomie bilatérale de valgisation-translation du col du fémur pour dysplasie congénitale",
    tarifSecteur1Optam: "581.38",
    tarifHorsSecteur1HorsOptam: "567.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA003",
    designation: "Ostéotomie simple unilatérale de la diaphyse du fémur",
    tarifSecteur1Optam: "321.18",
    tarifHorsSecteur1HorsOptam: "317.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA002",
    designation:
      "Ostéotomie simple unilatérale de la diaphyse du fémur, avec arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] et correction de vices architecturaux du pied",
    tarifSecteur1Optam: "508.35",
    tarifHorsSecteur1HorsOptam: "470.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA007",
    designation: "Ostéotomie simple bilatérale de la diaphyse du fémur",
    tarifSecteur1Optam: "548.63",
    tarifHorsSecteur1HorsOptam: "548.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA012",
    designation:
      "Ostéotomie simple bilatérale de la diaphyse du fémur, avec arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] et correction de vices architecturaux du pied",
    tarifSecteur1Optam: "794.2",
    tarifHorsSecteur1HorsOptam: "794.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA011",
    designation: "Ostéotomie complexe de la diaphyse du fémur",
    tarifSecteur1Optam: "458.01",
    tarifHorsSecteur1HorsOptam: "397.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA004",
    designation:
      "Ostéotomie simple unilatérale de la diaphyse du fémur et du tibia",
    tarifSecteur1Optam: "453.76",
    tarifHorsSecteur1HorsOptam: "447.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA015",
    designation:
      "Ostéotomie simple unilatérale de la diaphyse du fémur et du tibia, avec arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] et correction de vices architecturaux",
    tarifSecteur1Optam: "663.62",
    tarifHorsSecteur1HorsOptam: "569.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA001",
    designation:
      "Ostéotomie simple bilatérale de la diaphyse du fémur et du tibia",
    tarifSecteur1Optam: "728.14",
    tarifHorsSecteur1HorsOptam: "652.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA008",
    designation:
      "Ostéotomie simple bilatérale de la diaphyse du fémur et du tibia, avec arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] et correction de vices architecturaux",
    tarifSecteur1Optam: "925.39",
    tarifHorsSecteur1HorsOptam: "925.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA017",
    designation: "Ostéotomie simple de l'extrémité distale du fémur",
    tarifSecteur1Optam: "355.21",
    tarifHorsSecteur1HorsOptam: "337.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA010",
    designation: "Ostéotomie complexe de l'extrémité distale du fémur",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "390.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA009",
    designation:
      "Ostéotomie de dérotation de l'extrémité distale du fémur ou de l'extrémité proximale du tibia, avec abord vasculaire ou nerveux préalable",
    tarifSecteur1Optam: "455.89",
    tarifHorsSecteur1HorsOptam: "404.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA001",
    designation:
      "Exérèse partielle du fémur sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "174.41",
    tarifHorsSecteur1HorsOptam: "171.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA002",
    designation: "Évidement du fémur sans comblement, par abord direct",
    tarifSecteur1Optam: "194.98",
    tarifHorsSecteur1HorsOptam: "156.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA006",
    designation: "Évidement du fémur avec comblement, par abord direct",
    tarifSecteur1Optam: "241.77",
    tarifHorsSecteur1HorsOptam: "232.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA003",
    designation:
      "Évidement de l'extrémité proximale du fémur en présence d'un cartilage épiphysaire actif sans comblement, par abord direct",
    tarifSecteur1Optam: "307.71",
    tarifHorsSecteur1HorsOptam: "222.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA005",
    designation:
      "Évidement de l'extrémité proximale du fémur en présence d'un cartilage épiphysaire actif avec comblement, par abord direct",
    tarifSecteur1Optam: "347.41",
    tarifHorsSecteur1HorsOptam: "293.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA004",
    designation:
      "Évidement de l'extrémité distale du fémur et/ou de l'extrémité proximale du tibia en présence d'un cartilage épiphysaire actif sans comblement, par abord direct",
    tarifSecteur1Optam: "250.28",
    tarifHorsSecteur1HorsOptam: "210.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA001",
    designation:
      "Évidement de l'extrémité distale du fémur et/ou de l'extrémité proximale du tibia en présence d'un cartilage épiphysaire actif avec comblement, par abord direct",
    tarifSecteur1Optam: "299.91",
    tarifHorsSecteur1HorsOptam: "266.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA003",
    designation:
      "Résection partielle d'une extrémité et/ou de la diaphyse du fémur sans interruption de la continuité osseuse",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "222.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA004",
    designation:
      "Résection de la tête et du col du fémur sans interposition capsulaire ou aponévrotique",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "189.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA008",
    designation:
      "Résection de la tête et du col du fémur avec interposition capsulaire et/ou aponévrotique",
    tarifSecteur1Optam: "403.42",
    tarifHorsSecteur1HorsOptam: "339.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA002",
    designation:
      "Résection segmentaire du fémur avec ostéosynthèse, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "476.45",
    tarifHorsSecteur1HorsOptam: "473.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA006",
    designation:
      "Résection segmentaire du fémur avec ostéosynthèse et transfert axial progressif d'un fragment osseux, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "493.46",
    tarifHorsSecteur1HorsOptam: "483.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA007",
    designation:
      '"Résection ""en bloc"" d\'une extrémité et/ou de la diaphyse du fémur"',
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "309.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBMA003",
    designation:
      "Reconstruction du fémur par greffe ou matériau inerte non prothétique, après résection partielle d'une extrémité et/ou de la diaphyse",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBMA001",
    designation:
      '"Reconstruction du fémur par greffe ou matériau inerte non prothétique, après résection ""en bloc"" d\'une extrémité et/ou de la diaphyse"',
    tarifSecteur1Optam: "504.1",
    tarifHorsSecteur1HorsOptam: "424.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBDA004",
    designation: "Épiphysiodèse au genou, par un abord direct",
    tarifSecteur1Optam: "170.16",
    tarifHorsSecteur1HorsOptam: "133.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBDA013",
    designation: "Épiphysiodèse au genou, par plusieurs abords directs",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "164,11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA015",
    designation: "Désépiphysiodèse au fémur ou au tibia",
    tarifSecteur1Optam: "354.5",
    tarifHorsSecteur1HorsOptam: "306,20",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA010",
    designation:
      "Désépiphysiodèse centrale ou périphérique au fémur ou au tibia, avec ostéotomie",
    tarifSecteur1Optam: "333.16",
    tarifHorsSecteur1HorsOptam: "333.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBGA007",
    designation:
      "Ablation de matériel d'ostéosynthèse du fémur, par abord direct",
    tarifSecteur1Optam: "104.22",
    tarifHorsSecteur1HorsOptam: "95.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA016",
    designation: "Décortication du fémur pour pseudarthrose",
    tarifSecteur1Optam: "406.69",
    tarifHorsSecteur1HorsOptam: "406.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBPA005",
    designation: "Décortication du fémur avec ostéosynthèse pour pseudarthrose",
    tarifSecteur1Optam: "406.69",
    tarifHorsSecteur1HorsOptam: "406.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCB003",
    designation:
      "Perforation, forage, ou injection intraosseuse au niveau de la tête du fémur, par voie transcutanée",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "139.3",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NBCA002",
    designation:
      "Ostéosynthèse de fracture simple de la patelle, à foyer ouvert",
    tarifSecteur1Optam: "140.38",
    tarifHorsSecteur1HorsOptam: "138.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBCA001",
    designation:
      "Ostéosynthèse de fracture complexe de la patelle, à foyer ouvert",
    tarifSecteur1Optam: "194.98",
    tarifHorsSecteur1HorsOptam: "169.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA005",
    designation: "Patellectomie partielle, par abord direct",
    tarifSecteur1Optam: "185.05",
    tarifHorsSecteur1HorsOptam: "177.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBFA009",
    designation: "Patellectomie totale, par abord direct",
    tarifSecteur1Optam: "233.97",
    tarifHorsSecteur1HorsOptam: "205.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBMA002",
    designation:
      "Patelloplastie modelante ostéochondrale [Résection arthroplastique de la rotule]",
    tarifSecteur1Optam: "243.9",
    tarifHorsSecteur1HorsOptam: "211.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCEP002",
    designation:
      "Réduction orthopédique extemporanée de fracture d'une extrémité et/ou de la diaphyse du tibia ou des 2 os de la jambe",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "109.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCEP001",
    designation:
      "Réduction orthopédique progressive de fracture d'une extrémité et/ou de la diaphyse du tibia ou des 2 os de la jambe, par traction continue",
    tarifSecteur1Optam: "187.89",
    tarifHorsSecteur1HorsOptam: "152.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NCCB002",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité proximale du tibia ou des 2 os de la jambe, à foyer fermé",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCC001",
    designation:
      "Ostéosynthèse de fracture de l'éminence intercondylaire du tibia [des tubercules intercondylaires] [des épines tibiales], par arthroscopie",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "200.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA003",
    designation:
      "Ostéosynthèse de fracture de l'éminence intercondylaire du tibia [des tubercules intercondylaires] [des épines tibiales], par arthrotomie",
    tarifSecteur1Optam: "220.5",
    tarifHorsSecteur1HorsOptam: "198.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA007",
    designation:
      "Ostéosynthèse de fracture articulaire simple unicondylaire du tibia, à foyer ouvert",
    tarifSecteur1Optam: "219.08",
    tarifHorsSecteur1HorsOptam: "197.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA018",
    designation:
      "Ostéosynthèse de fracture articulaire complexe de l'extrémité proximale du tibia, à foyer ouvert",
    tarifSecteur1Optam: "360.17",
    tarifHorsSecteur1HorsOptam: "296.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA006",
    designation:
      "Ostéosynthèse de fracture extraarticulaire de l'extrémité proximale du tibia, à foyer ouvert",
    tarifSecteur1Optam: "225.46",
    tarifHorsSecteur1HorsOptam: "213.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA010",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du tibia par fixateur externe",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCB004",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du tibia par matériel centromédullaire sans verrouillage distal, à foyer fermé",
    tarifSecteur1Optam: "211.99",
    tarifHorsSecteur1HorsOptam: "205.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCB006",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du tibia par matériel centromédullaire avec verrouillage distal, à foyer fermé",
    tarifSecteur1Optam: "236.1",
    tarifHorsSecteur1HorsOptam: "219.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA002",
    designation:
      "Ostéosynthèse de fracture de la diaphyse du tibia, à foyer ouvert",
    tarifSecteur1Optam: "237.52",
    tarifHorsSecteur1HorsOptam: "220.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA014",
    designation:
      "Ostéosynthèse de fracture de la diaphyse de la fibula, à foyer ouvert",
    tarifSecteur1Optam: "112.02",
    tarifHorsSecteur1HorsOptam: "108.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCB005",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité distale d'un os ou des 2 os de la jambe par broche ou par vis, à foyer fermé",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA001",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité distale du tibia ou des 2 os de la jambe par fixateur externe",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCB001",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité distale du tibia ou des 2 os de la jambe par broche ou par vis et par fixateur externe, à foyer fermé",
    tarifSecteur1Optam: "236.1",
    tarifHorsSecteur1HorsOptam: "219.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCB007",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de l'extrémité distale du tibia par clou transplantaire",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA011",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire supramalléolaire du tibia, à foyer ouvert",
    tarifSecteur1Optam: "216.95",
    tarifHorsSecteur1HorsOptam: "208.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA019",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire supramalléolaire des 2 os de la jambe, à foyer ouvert",
    tarifSecteur1Optam: "250.28",
    tarifHorsSecteur1HorsOptam: "228.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA005",
    designation:
      "Ostéosynthèse de fracture ou de décollement épiphysaire de la malléole médiale [tibiale], à foyer ouvert",
    tarifSecteur1Optam: "182.21",
    tarifHorsSecteur1HorsOptam: "175.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA008",
    designation:
      "Ostéosynthèse de fracture simple du pilon tibial, à foyer ouvert",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "225.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA013",
    designation:
      "Ostéosynthèse de fracture complexe du pilon tibial par fixateur externe",
    tarifSecteur1Optam: "251.7",
    tarifHorsSecteur1HorsOptam: "233.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA004",
    designation:
      "Ostéosynthèse de fracture complexe du pilon tibial, à foyer ouvert",
    tarifSecteur1Optam: "323.3",
    tarifHorsSecteur1HorsOptam: "275.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA015",
    designation:
      "Ostéosynthèse de fracture triplane de l'extrémité distale du tibia, à foyer ouvert",
    tarifSecteur1Optam: "260.91",
    tarifHorsSecteur1HorsOptam: "239.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA012",
    designation:
      "Ostéosynthèse de fracture de l'extrémité distale de la fibula, à foyer ouvert",
    tarifSecteur1Optam: "160.23",
    tarifHorsSecteur1HorsOptam: "136.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA016",
    designation:
      "Ostéosynthèse de fracture bimalléolaire simple, à foyer ouvert",
    tarifSecteur1Optam: "199.23",
    tarifHorsSecteur1HorsOptam: "198.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA017",
    designation:
      "Ostéosynthèse de fracture bimalléolaire complexe, à foyer ouvert",
    tarifSecteur1Optam: "260.91",
    tarifHorsSecteur1HorsOptam: "234.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCB003",
    designation:
      "Ostéosynthèse préventive d'un os ou des 2 os de la jambe pour lésion ostéolytique, à foyer fermé",
    tarifSecteur1Optam: "211.99",
    tarifHorsSecteur1HorsOptam: "205.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCCA009",
    designation:
      "Ostéosynthèse préventive d'un os ou des 2 os de la jambe pour lésion ostéolytique, à foyer ouvert",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "232.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA001",
    designation:
      "Ostéotomie de la tubérosité du tibia [tubérosité tibiale antérieure] ou mobilisation du ligament patellaire [tendon rotulien] pour recentrage de la patelle, par arthrotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA002",
    designation:
      "Ostéotomie de la tubérosité du tibia [tubérosité tibiale antérieure] ou mobilisation du ligament patellaire [tendon rotulien] avec capsulo-myo-ténoplastie pour recentrage de la patelle, par arthrotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA003",
    designation:
      "Ostéotomie de la tubérosité du tibia [tubérosité tibiale antérieure] ou mobilisation du ligament patellaire [tendon rotulien] avec ostéotomie de la surface patellaire [trochlée] du fémur pour recentrage de la patelle, par arthrotomie",
    tarifSecteur1Optam: "334.65",
    tarifHorsSecteur1HorsOptam: "299.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA015",
    designation: "Ostéotomie simple de l'extrémité proximale du tibia",
    tarifSecteur1Optam: "314.8",
    tarifHorsSecteur1HorsOptam: "305.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA005",
    designation:
      "Ostéotomie simple de l'extrémité proximale du tibia avec reconstruction du ligament croisé antérieur",
    tarifSecteur1Optam: "477.87",
    tarifHorsSecteur1HorsOptam: "460.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA014",
    designation: "Ostéotomie complexe de l'extrémité proximale du tibia",
    tarifSecteur1Optam: "418.31",
    tarifHorsSecteur1HorsOptam: "365.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA013",
    designation: "Ostéotomie simple unilatérale de la diaphyse du tibia",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA004",
    designation:
      "Ostéotomie simple unilatérale de la diaphyse du tibia, avec arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] et correction de vices architecturaux",
    tarifSecteur1Optam: "483.54",
    tarifHorsSecteur1HorsOptam: "429.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA011",
    designation: "Ostéotomie simple bilatérale de la diaphyse du tibia",
    tarifSecteur1Optam: "438.9",
    tarifHorsSecteur1HorsOptam: "438.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA012",
    designation:
      "Ostéotomie simple bilatérale de la diaphyse du tibia, avec arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] et correction de vices architecturaux",
    tarifSecteur1Optam: "863.56",
    tarifHorsSecteur1HorsOptam: "685.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA016",
    designation: "Ostéotomie complexe de la diaphyse du tibia",
    tarifSecteur1Optam: "387.11",
    tarifHorsSecteur1HorsOptam: "347.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA009",
    designation: "Ostéotomie supramalléolaire du tibia",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA006",
    designation:
      "Ostéotomie supramalléolaire du tibia ou de l'arrière-pied, avec allongement et/ou transfert de tendon",
    tarifSecteur1Optam: "365.84",
    tarifHorsSecteur1HorsOptam: "356.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA007",
    designation: "Ostéotomie de la fibula",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA006",
    designation:
      "Exérèse partielle du tibia et/ou de la fibula sans interruption de la continuité, par abord unique",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA002",
    designation:
      "Exérèse partielle du tibia et/ou de la fibula sans interruption de la continuité, par abords multiples",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA003",
    designation:
      "Exérèse d'exostose intertibiofibulaire proximale, par abord direct",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "257.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCGA001",
    designation:
      "Évidement du tibia et/ou de la fibula sans comblement, par abord direct",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "164.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCGA002",
    designation:
      "Évidement du tibia et/ou de la fibula avec comblement, par abord direct",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA005",
    designation:
      "Résection partielle d'une extrémité et/ou de la diaphyse du tibia sans interruption de la continuité osseuse",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "213.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA004",
    designation:
      "Résection segmentaire du tibia et/ou de la fibula avec ostéosynthèse, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "400.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA007",
    designation:
      "Résection segmentaire du tibia et/ou de la fibula avec ostéosynthèse et transfert axial progressif d'un fragment osseux, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "454.47",
    tarifHorsSecteur1HorsOptam: "395.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA008",
    designation:
      '"Résection ""en bloc"" d\'une extrémité et/ou de la diaphyse du tibia"',
    tarifSecteur1Optam: "391.37",
    tarifHorsSecteur1HorsOptam: "310.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA009",
    designation:
      '"Résection ""en bloc"" d\'une extrémité et/ou de la diaphyse de la fibula"',
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCFA001",
    designation:
      '"Résection ""en bloc"" de l\'extrémité proximale des 2 os de la jambe"',
    tarifSecteur1Optam: "476.45",
    tarifHorsSecteur1HorsOptam: "381.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCMA002",
    designation:
      "Reconstruction du tibia par greffe ou matériau inerte non prothétique, après résection partielle d'une extrémité et/ou de la diaphyse",
    tarifSecteur1Optam: "226.17",
    tarifHorsSecteur1HorsOptam: "210.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCMA001",
    designation:
      '"Reconstruction du tibia par greffe ou matériau inerte non prothétique, après résection ""en bloc"" d\'une extrémité et/ou de la diaphyse"',
    tarifSecteur1Optam: "452.34",
    tarifHorsSecteur1HorsOptam: "341.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA010",
    designation:
      "Décortication de la diaphyse du tibia et/ou de la fibula pour pseudarthrose",
    tarifSecteur1Optam: "281.29",
    tarifHorsSecteur1HorsOptam: "281.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCPA008",
    designation:
      "Décortication de la diaphyse du tibia et/ou de la fibula avec ostéosynthèse, pour pseudarthrose",
    tarifSecteur1Optam: "343.99",
    tarifHorsSecteur1HorsOptam: "343.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NCEA001",
    designation: "Greffe osseuse intertibiofibulaire, par abord direct",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "292.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCB001",
    designation:
      "Ostéosynthèse de fracture du talus ou des os du médiopied, à foyer fermé",
    tarifSecteur1Optam: "170.16",
    tarifHorsSecteur1HorsOptam: "168.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDDC001",
    designation:
      "Fixation de fragment ostéochondral de la trochlée du talus, par arthroscopie",
    tarifSecteur1Optam: "235.39",
    tarifHorsSecteur1HorsOptam: "189.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDDA001",
    designation:
      "Fixation de fragment ostéochondral de la trochlée du talus, par arthrotomie",
    tarifSecteur1Optam: "214.12",
    tarifHorsSecteur1HorsOptam: "207.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCA005",
    designation:
      "Ostéosynthèse de fracture du corps ou du col du talus, à foyer ouvert",
    tarifSecteur1Optam: "231.13",
    tarifHorsSecteur1HorsOptam: "217.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCB004",
    designation: "Ostéosynthèse de fracture du calcanéus, à foyer fermé",
    tarifSecteur1Optam: "197.69",
    tarifHorsSecteur1HorsOptam: "197.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCA001",
    designation:
      "Ostéosynthèse de fracture simple ou de fracture-luxation du calcanéus, à foyer ouvert",
    tarifSecteur1Optam: "236.1",
    tarifHorsSecteur1HorsOptam: "219.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCA004",
    designation:
      "Ostéosynthèse de fracture complexe du calcanéus, à foyer ouvert",
    tarifSecteur1Optam: "328.98",
    tarifHorsSecteur1HorsOptam: "273.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCA006",
    designation:
      "Ostéosynthèse de fracture des os du médiopied, à foyer ouvert",
    tarifSecteur1Optam: "187.18",
    tarifHorsSecteur1HorsOptam: "152.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA010",
    designation: "Ostéotomie tibiotalienne intraarticulaire",
    tarifSecteur1Optam: "280.76",
    tarifHorsSecteur1HorsOptam: "268.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA003",
    designation: "Ostéotomie du calcanéus ou du talus",
    tarifSecteur1Optam: "268.71",
    tarifHorsSecteur1HorsOptam: "243.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA006",
    designation:
      "Ostéotomie du calcanéus avec désinsertion des muscles de la plante du pied",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "234.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA012",
    designation:
      "Ostéotomie de l'os cunéiforme médial, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA007",
    designation:
      "Ostéotomie de l'os cunéiforme médial et du premier métatarsien, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil",
    tarifSecteur1Optam: "189.3",
    tarifHorsSecteur1HorsOptam: "188.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA005",
    designation:
      "Ostéotomie de l'os cunéiforme médial, du métatarsien et de la phalange proximale du premier rayon du pied, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil",
    tarifSecteur1Optam: "226.88",
    tarifHorsSecteur1HorsOptam: "210.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA001",
    designation: "Talectomie avec arthrodèse tibiotarsienne",
    tarifSecteur1Optam: "405.55",
    tarifHorsSecteur1HorsOptam: "327.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA003",
    designation: "Talectomie avec création de néarthrose tibiopédieuse",
    tarifSecteur1Optam: "280.76",
    tarifHorsSecteur1HorsOptam: "206.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA004",
    designation:
      "Résection de synostose de l'arrière-pied ou du médiopied, par abord direct",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "176.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA008",
    designation:
      "Tarsectomie postérieure ou ostéotomie de l'arrière-pied, avec ostéotomie supramalléolaire",
    tarifSecteur1Optam: "380.02",
    tarifHorsSecteur1HorsOptam: "378.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA009",
    designation:
      "Tarsectomie postérieure ou ostéotomie de l'arrière-pied, avec ostéotomie supramalléolaire, avec allongement et/ou transfert de tendon",
    tarifSecteur1Optam: "454.47",
    tarifHorsSecteur1HorsOptam: "421.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA010",
    designation:
      "Tarsectomie antérieure ou ostéotomie extraarticulaire de l'arrière-pied ou du médiopied",
    tarifSecteur1Optam: "285.02",
    tarifHorsSecteur1HorsOptam: "253.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDEP001",
    designation:
      "Réduction orthopédique de fracture et/ou de luxation de l'avant-pied",
    tarifSecteur1Optam: "71.61",
    tarifHorsSecteur1HorsOptam: "59.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCB003",
    designation:
      "Ostéosynthèse de fracture d'un métatarsien ou d'une phalange d'orteil, à foyer fermé",
    tarifSecteur1Optam: "74.45",
    tarifHorsSecteur1HorsOptam: "69.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCA002",
    designation:
      "Ostéosynthèse de fracture d'un métatarsien ou d'une phalange d'orteil, à foyer ouvert",
    tarifSecteur1Optam: "92.17",
    tarifHorsSecteur1HorsOptam: "79.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCB002",
    designation:
      "Ostéosynthèse de fractures de plusieurs os de l'avant-pied, à foyer fermé",
    tarifSecteur1Optam: "96.42",
    tarifHorsSecteur1HorsOptam: "95.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDCA003",
    designation:
      "Ostéosynthèse de fractures de plusieurs os de l'avant-pied, à foyer ouvert",
    tarifSecteur1Optam: "142.51",
    tarifHorsSecteur1HorsOptam: "122.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA001",
    designation: "Ostéotomie du premier métatarsien",
    tarifSecteur1Optam: "160.94",
    tarifHorsSecteur1HorsOptam: "128.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA008",
    designation:
      "Ostéotomie du premier métatarsien, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil",
    tarifSecteur1Optam: "187.18",
    tarifHorsSecteur1HorsOptam: "178.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA011",
    designation:
      "Ostéotomie du métatarsien et de la phalange proximale du premier rayon du pied, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA002",
    designation:
      "Ostéotomie du métatarsien et de la phalange proximale du premier rayon du pied, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil et ostéotomie d'un métatarsien latéral",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA013",
    designation:
      "Ostéotomie du métatarsien et de la phalange proximale du premier rayon du pied, avec libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil et ostéotomie de plusieurs métatarsiens latéraux",
    tarifSecteur1Optam: "292.6",
    tarifHorsSecteur1HorsOptam: "292.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA009",
    designation:
      "Ostéotomie de la phalange proximale et libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA004",
    designation:
      "Ostéotomie d'un métatarsien latéral ou d'une phalange d'orteil, sur un rayon du pied",
    tarifSecteur1Optam: "111.31",
    tarifHorsSecteur1HorsOptam: "99.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDPA014",
    designation:
      "Ostéotomie d'un métatarsien latéral, avec libération de l'articulation métatarsophalangienne",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDMA001",
    designation:
      "Reconstruction osseuse du métatarse ou des orteils par greffe ou matériau inerte non prothétique, après résection segmentaire",
    tarifSecteur1Optam: "265.17",
    tarifHorsSecteur1HorsOptam: "232.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBAA006",
    designation:
      "Allongement osseux extemporané du fémur, avec autogreffe osseuse",
    tarifSecteur1Optam: "412.64",
    tarifHorsSecteur1HorsOptam: "385.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDAA001",
    designation:
      "Allongement osseux extemporané à l'avant-pied, avec autogreffe osseuse",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "284.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBAA003",
    designation:
      "Allongement osseux progressif du fémur ou du tibia par système interne, sans allongement tendineux",
    tarifSecteur1Optam: "406.97",
    tarifHorsSecteur1HorsOptam: "354.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBAA001",
    designation:
      "Allongement osseux progressif du fémur ou du tibia par système interne, avec allongement tendineux",
    tarifSecteur1Optam: "444.54",
    tarifHorsSecteur1HorsOptam: "415.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBAA005",
    designation:
      "Allongement osseux progressif du fémur ou du tibia par système externe, sans allongement tendineux",
    tarifSecteur1Optam: "423.98",
    tarifHorsSecteur1HorsOptam: "364.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NBAA004",
    designation:
      "Allongement osseux progressif du fémur ou du tibia par système externe, avec allongement tendineux",
    tarifSecteur1Optam: "449.51",
    tarifHorsSecteur1HorsOptam: "379.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDAA002",
    designation: "Allongement osseux progressif au pied, par système externe",
    tarifSecteur1Optam: "345.28",
    tarifHorsSecteur1HorsOptam: "305.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA005",
    designation: "Résection d'une exostose infra-unguéale d'un orteil",
    tarifSecteur1Optam: "76.57",
    tarifHorsSecteur1HorsOptam: "61.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA002",
    designation:
      "Exérèse partielle d'os du pied sans interruption de la continuité, par abord direct",
    tarifSecteur1Optam: "129.75",
    tarifHorsSecteur1HorsOptam: "101.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDGA001",
    designation: "Évidement d'un os du pied sans comblement, par abord direct",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "109.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDGA002",
    designation: "Évidement d'un os du pied avec comblement, par abord direct",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA006",
    designation:
      '"Résection ""en bloc"" d\'un ou plusieurs os du tarse et/ou du métatarse"',
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "194",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDGA003",
    designation: "Ablation de matériel d'ostéosynthèse du pied, à foyer ouvert",
    tarifSecteur1Optam: "82.95",
    tarifHorsSecteur1HorsOptam: "65.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZMP008",
    designation:
      "Confection d'un appareil rigide pelvicrural [pelvijambier] ou pelvipédieux pour immobilisation initiale de fracture du membre inférieur, sans réduction",
    tarifSecteur1Optam: "68.06",
    tarifHorsSecteur1HorsOptam: "65.81",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZMP006",
    designation:
      "Confection d'un appareil rigide fémorocrural [fémorojambier] ou fémoropédieux pour immobilisation initiale de fracture du membre inférieur, sans réduction",
    tarifSecteur1Optam: "42.54",
    tarifHorsSecteur1HorsOptam: "42.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZMP014",
    designation:
      "Confection d'un appareil rigide cruropédieux [jambopédieux] pour immobilisation initiale de fracture du membre inférieur, sans réduction ",
    tarifSecteur1Optam: "31.2",
    tarifHorsSecteur1HorsOptam: "27.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEJA003",
    designation:
      "Évacuation de collection ou synovectomie de l'articulation sacro-iliaque, par arthrotomie",
    tarifSecteur1Optam: "199.23",
    tarifHorsSecteur1HorsOptam: "168.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEDB454",
    designation:
      "Arthrodèse de l'articulation sacro-iliaque par voie transpariétale, avec guidage radiologique",
    tarifSecteur1Optam: "496.3",
    tarifHorsSecteur1HorsOptam: "496.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEDA003",
    designation: "Arthrodèse de l'articulation sacro-iliaque, par arthrotomie",
    tarifSecteur1Optam: "328.98",
    tarifHorsSecteur1HorsOptam: "278.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEFA002",
    designation: "Résection étendue de l'articulation sacro-iliaque",
    tarifSecteur1Optam: "1029.47",
    tarifHorsSecteur1HorsOptam: "728.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA019",
    designation:
      "Reconstruction de l'articulation sacro-iliaque après perte de substance osseuse segmentaire avec arthrodèse, par abord direct",
    tarifSecteur1Optam: "343.87",
    tarifHorsSecteur1HorsOptam: "322.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEJB001",
    designation:
      "Évacuation de collection de l'articulation coxofémorale, par voie transcutanée",
    tarifSecteur1Optam: "81.07",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEJA002",
    designation:
      "Évacuation de collection de l'articulation coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "239.49",
    tarifHorsSecteur1HorsOptam: "239.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEJA004",
    designation:
      "Évacuation de collection périprothétique de l'articulation coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "255.24",
    tarifHorsSecteur1HorsOptam: "235.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEP003",
    designation:
      "Réduction orthopédique progressive d'une hanche instable non traumatique par traction, avant l'âge de 12 mois",
    tarifSecteur1Optam: "403.42",
    tarifHorsSecteur1HorsOptam: "321.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEEP001",
    designation:
      "Réduction orthopédique progressive d'une hanche instable non traumatique par traction, après l'âge de 12 mois",
    tarifSecteur1Optam: "484.25",
    tarifHorsSecteur1HorsOptam: "368.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEEP006",
    designation:
      "Réduction orthopédique progressive d'une hanche instable non traumatique par harnais",
    tarifSecteur1Optam: "382.15",
    tarifHorsSecteur1HorsOptam: "309.43",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NEEP007",
    designation:
      "Réduction orthopédique d'une luxation traumatique de l'articulation coxofémorale, avec pose de traction continue",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEP004",
    designation:
      "Réduction orthopédique d'une luxation traumatique de l'articulation coxofémorale, avec contention par appareillage rigide pelvipédieux",
    tarifSecteur1Optam: "114.95",
    tarifHorsSecteur1HorsOptam: "114.95",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEP005",
    designation:
      "Réduction orthopédique d'une luxation traumatique de l'articulation coxofémorale, avec fracture ou décollement épiphysaire de la tête du fémur",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEP002",
    designation:
      "Réduction orthopédique d'une luxation de prothèse de l'articulation coxofémorale",
    tarifSecteur1Optam: "83.6",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEA004",
    designation:
      "Réduction d'une hanche instable non traumatique, par arthrotomie",
    tarifSecteur1Optam: "451.63",
    tarifHorsSecteur1HorsOptam: "393.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEA001",
    designation:
      "Réduction d'une luxation traumatique de l'articulation coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "236.1",
    tarifHorsSecteur1HorsOptam: "224.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEA003",
    designation:
      "Réduction d'une luxation traumatique de l'articulation coxofémorale avec ostéosynthèse de fracture de la tête du fémur, par arthrotomie",
    tarifSecteur1Optam: "478.98",
    tarifHorsSecteur1HorsOptam: "478.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEEA002",
    designation:
      "Réduction d'une luxation de prothèse de l'articulation coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEJC001",
    designation: "Nettoyage de l'articulation coxofémorale, par arthroscopie",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "171.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEJA001",
    designation: "Nettoyage de l'articulation coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "260.2",
    tarifHorsSecteur1HorsOptam: "251.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEDA001",
    designation: "Arthrodèse coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "575",
    tarifHorsSecteur1HorsOptam: "491.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA021",
    designation:
      "Ostéoplastie supraacétabulaire de l'os coxal par butée, ou acétabuloplastie",
    tarifSecteur1Optam: "377.19",
    tarifHorsSecteur1HorsOptam: "315.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA003",
    designation:
      "Ostéoplastie supraacétabulaire de l'os coxal par butée, avec ostéotomie extraarticulaire de l'extrémité proximale du fémur",
    tarifSecteur1Optam: "494.17",
    tarifHorsSecteur1HorsOptam: "462.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA017",
    designation:
      "Ostéoplastie supraacétabulaire de l'os coxal par butée, avec ostéotomie du grand trochanter",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEDA002",
    designation:
      "Stabilisation d'une prothèse totale de l'articulation coxofémorale par pose de butée supraacétabulaire en matériau inerte, abaissement du grand trochanter et/ou changement de la tête ou du col amovible",
    tarifSecteur1Optam: "418",
    tarifHorsSecteur1HorsOptam: "418",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA020",
    designation: "Acétabuloplastie avec ostéotomie du fémur",
    tarifSecteur1Optam: "607.61",
    tarifHorsSecteur1HorsOptam: "527.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA018",
    designation: "Arthroplastie coxofémorale par cupule fémorale",
    tarifSecteur1Optam: "385.7",
    tarifHorsSecteur1HorsOptam: "355.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA018",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse fémorale cervicocéphalique",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA011",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse fémorale cervicocéphalique et cupule mobile",
    tarifSecteur1Optam: "459.8",
    tarifHorsSecteur1HorsOptam: "459.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NELA003",
    designation:
      "Pose d'une pièce acétabulaire chez un patient porteur d'une prothèse fémorale cervicocéphalique homolatérale",
    tarifSecteur1Optam: "385.7",
    tarifHorsSecteur1HorsOptam: "355.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA020",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale",
    tarifSecteur1Optam: "459.8",
    tarifHorsSecteur1HorsOptam: "459.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA012",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale, avec reconstruction acétabulaire ou fémorale par greffe",
    tarifSecteur1Optam: "602.65",
    tarifHorsSecteur1HorsOptam: "577.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA014",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale, avec renfort métallique acétabulaire",
    tarifSecteur1Optam: "543.4",
    tarifHorsSecteur1HorsOptam: "543.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA010",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale, avec renfort métallique acétabulaire et reconstruction fémorale par greffe",
    tarifSecteur1Optam: "624.63",
    tarifHorsSecteur1HorsOptam: "590.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA016",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale, avec ostéotomie de la diaphyse du fémur",
    tarifSecteur1Optam: "616.55",
    tarifHorsSecteur1HorsOptam: "616.55",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA017",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale, avec abaissement de la tête du fémur dans le paléoacétabulum [paléocotyle]",
    tarifSecteur1Optam: "693.4",
    tarifHorsSecteur1HorsOptam: "639.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA021",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale, avec abaissement de la tête du fémur dans le paléoacétabulum [paléocotyle] et ostéotomie de réaxation ou d'alignement du fémur",
    tarifSecteur1Optam: "738.78",
    tarifHorsSecteur1HorsOptam: "687.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA015",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale après ostéosynthèse, ostéotomie ou prothèse cervicocéphalique du fémur",
    tarifSecteur1Optam: "667.08",
    tarifHorsSecteur1HorsOptam: "667.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA013",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale après arthrodèse coxofémorale",
    tarifSecteur1Optam: "635.97",
    tarifHorsSecteur1HorsOptam: "614.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA019",
    designation:
      "Remplacement de l'articulation coxofémorale par prothèse totale après arthrodèse coxofémorale, avec renfort métallique acétabulaire",
    tarifSecteur1Optam: "694.11",
    tarifHorsSecteur1HorsOptam: "648.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEGA004",
    designation:
      "Ablation de l'insert intermédiaire d'une prothèse de l'articulation coxofémorale, avec pose d'une pièce acétabulaire",
    tarifSecteur1Optam: "389.95",
    tarifHorsSecteur1HorsOptam: "357.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEGA005",
    designation: "Ablation d'une prothèse fémorale cervicocéphalique",
    tarifSecteur1Optam: "353.58",
    tarifHorsSecteur1HorsOptam: "353.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEGA002",
    designation: "Ablation d'une prothèse totale de hanche",
    tarifSecteur1Optam: "408.38",
    tarifHorsSecteur1HorsOptam: "385.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEGA003",
    designation:
      "Ablation d'une prothèse totale de hanche avec coaptation trochantéro-iliaque",
    tarifSecteur1Optam: "623.92",
    tarifHorsSecteur1HorsOptam: "563.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEGA001",
    designation:
      "Ablation d'une prothèse totale de hanche avec reconstruction osseuse de l'acétabulum et/ou du fémur",
    tarifSecteur1Optam: "537.42",
    tarifHorsSecteur1HorsOptam: "512.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA022",
    designation:
      "Changement d'une prothèse fémorale cervicocéphalique pour une prothèse totale de hanche",
    tarifSecteur1Optam: "667.08",
    tarifHorsSecteur1HorsOptam: "667.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA004",
    designation:
      "Changement de l'insert acétabulaire d'une prothèse totale de hanche",
    tarifSecteur1Optam: "667.08",
    tarifHorsSecteur1HorsOptam: "667.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA009",
    designation:
      "Changement de la pièce acétabulaire ou fémorale d'une prothèse totale de hanche, sans reconstruction osseuse",
    tarifSecteur1Optam: "551.6",
    tarifHorsSecteur1HorsOptam: "538.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA002",
    designation:
      "Changement de la pièce acétabulaire ou fémorale d'une prothèse totale de hanche, avec reconstruction osseuse de l'acétabulum ou du fémur",
    tarifSecteur1Optam: "545.22",
    tarifHorsSecteur1HorsOptam: "534.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA007",
    designation:
      "Changement de la pièce acétabulaire ou fémorale d'une prothèse totale de hanche, avec reconstruction osseuse par greffes compactées sans ostéosynthèse",
    tarifSecteur1Optam: "734.52",
    tarifHorsSecteur1HorsOptam: "644.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA005",
    designation:
      "Changement de la pièce acétabulaire ou fémorale d'une prothèse totale de hanche, avec ostéosynthèse de l'acétabulum ou du fémur",
    tarifSecteur1Optam: "672.84",
    tarifHorsSecteur1HorsOptam: "608.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA003",
    designation:
      "Changement des pièces acétabulaire et fémorale d'une prothèse totale de hanche, sans reconstruction osseuse",
    tarifSecteur1Optam: "740.91",
    tarifHorsSecteur1HorsOptam: "709.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA008",
    designation:
      "Changement des pièces acétabulaire et fémorale d'une prothèse totale de hanche, avec reconstruction ou ostéosynthèse de l'acétabulum ou du fémur",
    tarifSecteur1Optam: "802.59",
    tarifHorsSecteur1HorsOptam: "745.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA006",
    designation:
      "Changement des pièces acétabulaire et fémorale d'une prothèse totale de hanche, avec reconstruction et ostéosynthèse de l'acétabulum et/ou du fémur",
    tarifSecteur1Optam: "1001.11",
    tarifHorsSecteur1HorsOptam: "860.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEKA001",
    designation:
      "Changement des pièces acétabulaire et fémorale d'une prothèse totale de hanche, avec reconstruction par greffes compactées sans ostéosynthèse",
    tarifSecteur1Optam: "908.94",
    tarifHorsSecteur1HorsOptam: "807.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NELA002",
    designation:
      "Repose d'une prothèse totale de l'articulation coxofémorale, sans reconstruction osseuse",
    tarifSecteur1Optam: "544.51",
    tarifHorsSecteur1HorsOptam: "508.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NELA001",
    designation:
      "Repose d'une prothèse totale de l'articulation coxofémorale, avec reconstruction osseuse",
    tarifSecteur1Optam: "687.73",
    tarifHorsSecteur1HorsOptam: "627.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEFA001",
    designation:
      "Résection de l'articulation coxofémorale avec coaptation trochantéro-iliaque ou fémoro-iliaque",
    tarifSecteur1Optam: "482.83",
    tarifHorsSecteur1HorsOptam: "411.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA013",
    designation:
      '"Reconstruction osseuse de la hanche après résection ""en bloc"" de l\'os coxal [hémibassin], avec fixation du fémur à l\'os coxal"',
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "489.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEMA011",
    designation:
      "Reconstruction de l'articulation coxofémorale par prothèse massive ou sur mesure, après perte de substance segmentaire au niveau de la hanche ou de l'os coxal",
    tarifSecteur1Optam: "514.73",
    tarifHorsSecteur1HorsOptam: "491.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEPA001",
    designation:
      "Libération mobilisatrice de l'articulation coxofémorale, par arthrotomie",
    tarifSecteur1Optam: "289.98",
    tarifHorsSecteur1HorsOptam: "255.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEFC001",
    designation: "Synovectomie coxofémorale, par arthroscopie",
    tarifSecteur1Optam: "347.41",
    tarifHorsSecteur1HorsOptam: "289.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEFA004",
    designation: "Synovectomie coxofémorale, par arthrotomie par un abord",
    tarifSecteur1Optam: "255.95",
    tarifHorsSecteur1HorsOptam: "236.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NEFA003",
    designation:
      "Synovectomie coxofémorale, par arthrotomie par 2 abords dont un abord postérieur",
    tarifSecteur1Optam: "404.84",
    tarifHorsSecteur1HorsOptam: "322.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFRP001",
    designation:
      "Mobilisation de l'articulation du genou à visée thérapeutique, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "57.43",
    tarifHorsSecteur1HorsOptam: "41.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFJC002",
    designation:
      "Évacuation de collection de l'articulation du genou, par arthroscopie",
    tarifSecteur1Optam: "192.14",
    tarifHorsSecteur1HorsOptam: "164.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFJA002",
    designation:
      "Évacuation de collection de l'articulation du genou, par arthrotomie",
    tarifSecteur1Optam: "187.18",
    tarifHorsSecteur1HorsOptam: "174.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFEP001",
    designation: "Réduction orthopédique d'une luxation de la patelle",
    tarifSecteur1Optam: "44.67",
    tarifHorsSecteur1HorsOptam: "39.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NFEP002",
    designation:
      "Réduction orthopédique d'une luxation ou d'une luxation-fracture de l'articulation fémorotibiale",
    tarifSecteur1Optam: "104.93",
    tarifHorsSecteur1HorsOptam: "76.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFJC001",
    designation: "Nettoyage de l'articulation du genou, par arthroscopie",
    tarifSecteur1Optam: "175.12",
    tarifHorsSecteur1HorsOptam: "154.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFJA001",
    designation: "Nettoyage de l'articulation du genou, par arthrotomie",
    tarifSecteur1Optam: "165.2",
    tarifHorsSecteur1HorsOptam: "161.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFDA002",
    designation: "Arthrodèse fémorotibiale, par arthrotomie",
    tarifSecteur1Optam: "482.12",
    tarifHorsSecteur1HorsOptam: "367.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFDA003",
    designation: "Arthrodèse fibulotibiale proximale, par arthrotomie",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "148.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA009",
    designation:
      "Remplacement de l'articulation du genou par prothèse à charnière fixe ou rotatoire",
    tarifSecteur1Optam: "560.11",
    tarifHorsSecteur1HorsOptam: "544.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA006",
    designation:
      "Remplacement de l'articulation du genou par prothèse unicompartimentaire fémorotibiale ou fémoropatellaire",
    tarifSecteur1Optam: "446.67",
    tarifHorsSecteur1HorsOptam: "434.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA007",
    designation:
      "Remplacement de l'articulation du genou par prothèse tricompartimentaire sur une déformation inférieure ou égale à 10° dans le plan frontal",
    tarifSecteur1Optam: "578.54",
    tarifHorsSecteur1HorsOptam: "555",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA008",
    designation:
      "Remplacement de l'articulation du genou par prothèse tricompartimentaire sur une déformation supérieure à 10° dans le plan frontal",
    tarifSecteur1Optam: "659.37",
    tarifHorsSecteur1HorsOptam: "601.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFGA002",
    designation: "Ablation d'une prothèse du genou",
    tarifSecteur1Optam: "450.22",
    tarifHorsSecteur1HorsOptam: "344.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFGA001",
    designation: "Ablation d'une prothèse du genou avec arthrodèse",
    tarifSecteur1Optam: "568.62",
    tarifHorsSecteur1HorsOptam: "465.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA004",
    designation:
      "Changement de l'insert d’une prothèse unicompartimentaire ou tricompartimentaire du genou",
    tarifSecteur1Optam: "323.09",
    tarifHorsSecteur1HorsOptam: "323.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA003",
    designation: "Changement d'une prothèse unicompartimentaire du genou",
    tarifSecteur1Optam: "532.09",
    tarifHorsSecteur1HorsOptam: "532.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA005",
    designation:
      "Changement d'une prothèse unicompartimentaire du genou pour une prothèse tricompartimentaire",
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "577.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA001",
    designation:
      "Changement d'une prothèse tricompartimentaire du genou, sans reconstruction osseuse",
    tarifSecteur1Optam: "740.91",
    tarifHorsSecteur1HorsOptam: "649.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFKA002",
    designation:
      "Changement d'une prothèse tricompartimentaire du genou, avec reconstruction osseuse",
    tarifSecteur1Optam: "1029.47",
    tarifHorsSecteur1HorsOptam: "816.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFLA002",
    designation:
      "Repose d'une prothèse articulaire du genou, sans reconstruction osseuse",
    tarifSecteur1Optam: "617.54",
    tarifHorsSecteur1HorsOptam: "533.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFLA001",
    designation:
      "Repose d'une prothèse articulaire du genou, avec reconstruction osseuse",
    tarifSecteur1Optam: "717.51",
    tarifHorsSecteur1HorsOptam: "626.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA013",
    designation:
      "Reconstruction osseuse du genou après perte de substance segmentaire, avec arthrodèse et ostéosynthèse",
    tarifSecteur1Optam: "516.86",
    tarifHorsSecteur1HorsOptam: "422.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA006",
    designation:
      "Reconstruction de l'articulation du genou par prothèse massive ou sur mesure, après perte de substance segmentaire",
    tarifSecteur1Optam: "522.5",
    tarifHorsSecteur1HorsOptam: "522.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFPC002",
    designation:
      "Libération mobilisatrice de l'articulation du genou, par arthroscopie",
    tarifSecteur1Optam: "299.2",
    tarifHorsSecteur1HorsOptam: "243.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFPA001",
    designation:
      "Libération mobilisatrice de l'articulation du genou, par arthrotomie",
    tarifSecteur1Optam: "272.97",
    tarifHorsSecteur1HorsOptam: "228.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFPA003",
    designation:
      "Libération mobilisatrice de l'articulation du genou par arthroscopie ou arthrotomie, avec libération complète du quadriceps",
    tarifSecteur1Optam: "441",
    tarifHorsSecteur1HorsOptam: "378.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFC002",
    designation: "Synovectomie antérieure du genou, par arthroscopie",
    tarifSecteur1Optam: "230.43",
    tarifHorsSecteur1HorsOptam: "203.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFA004",
    designation: "Synovectomie antérieure du genou, par arthrotomie",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "189.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFA005",
    designation:
      "Synovectomie antérieure du genou par arthroscopie avec synovectomie postérieure par arthrotomie, sans changement de position",
    tarifSecteur1Optam: "341.03",
    tarifHorsSecteur1HorsOptam: "268.02",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFC001",
    designation:
      "Synovectomie du genou, par arthroscopie antérieure et par arthroscopie postérieure",
    tarifSecteur1Optam: "328.27",
    tarifHorsSecteur1HorsOptam: "260.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFA002",
    designation:
      "Synovectomie du genou, par arthrotomie antérieure et par arthrotomie postérieure sans changement de position du patient",
    tarifSecteur1Optam: "288.56",
    tarifHorsSecteur1HorsOptam: "237.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFA006",
    designation:
      "Synovectomie du genou, par arthrotomie antérieure et par arthrotomie postérieure avec changement de position du patient",
    tarifSecteur1Optam: "453.05",
    tarifHorsSecteur1HorsOptam: "332.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCC002",
    designation:
      "Suture ou réinsertion du ligament croisé antérieur du genou, par arthroscopie",
    tarifSecteur1Optam: "311.41",
    tarifHorsSecteur1HorsOptam: "311.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA001",
    designation:
      "Suture ou réinsertion du ligament croisé antérieur du genou, par arthrotomie",
    tarifSecteur1Optam: "311.41",
    tarifHorsSecteur1HorsOptam: "311.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA004",
    designation:
      "Suture ou réinsertion du ligament croisé antérieur et d'élément capsuloligamentaire périarticulaire du genou, par arthrotomie",
    tarifSecteur1Optam: "395.01",
    tarifHorsSecteur1HorsOptam: "395.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCC001",
    designation:
      "Suture ou réinsertion du ligament croisé postérieur du genou, par arthroscopie",
    tarifSecteur1Optam: "397.75",
    tarifHorsSecteur1HorsOptam: "361.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA006",
    designation:
      "Suture ou réinsertion du ligament croisé postérieur du genou, par arthrotomie",
    tarifSecteur1Optam: "319.76",
    tarifHorsSecteur1HorsOptam: "316.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA005",
    designation:
      "Suture ou réinsertion du ligament croisé postérieur et d'élément capsuloligamentaire périarticulaire du genou, par arthrotomie",
    tarifSecteur1Optam: "395.01",
    tarifHorsSecteur1HorsOptam: "395.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA003",
    designation:
      "Suture ou réinsertion des ligaments croisés et d'élément capsuloligamentaire périarticulaire du genou, par arthrotomie",
    tarifSecteur1Optam: "453.05",
    tarifHorsSecteur1HorsOptam: "428.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMC003",
    designation:
      "Reconstruction du ligament croisé antérieur du genou par autogreffe, par arthroscopie",
    tarifSecteur1Optam: "444.54",
    tarifHorsSecteur1HorsOptam: "388.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA004",
    designation:
      "Reconstruction du ligament croisé antérieur du genou par autogreffe, par arthrotomie",
    tarifSecteur1Optam: "368.68",
    tarifHorsSecteur1HorsOptam: "344.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMC002",
    designation:
      "Reconstruction du ligament croisé postérieur du genou par autogreffe sans renforcement synthétique, par arthroscopie",
    tarifSecteur1Optam: "523.95",
    tarifHorsSecteur1HorsOptam: "434.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA010",
    designation:
      "Reconstruction du ligament croisé postérieur du genou par autogreffe sans renforcement synthétique, par arthrotomie",
    tarifSecteur1Optam: "439.58",
    tarifHorsSecteur1HorsOptam: "385.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMC005",
    designation:
      "Reconstruction itérative du ligament croisé postérieur du genou par ligament synthétique, par arthroscopie",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "369.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA008",
    designation:
      "Reconstruction itérative du ligament croisé postérieur du genou par ligament synthétique, par arthrotomie",
    tarifSecteur1Optam: "350.25",
    tarifHorsSecteur1HorsOptam: "333.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMC001",
    designation:
      "Reconstruction des ligaments croisés du genou, par arthroscopie",
    tarifSecteur1Optam: "655.12",
    tarifHorsSecteur1HorsOptam: "510.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA011",
    designation:
      "Reconstruction des ligaments croisés du genou, par arthrotomie",
    tarifSecteur1Optam: "566.49",
    tarifHorsSecteur1HorsOptam: "459.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA002",
    designation:
      "Suture ou réinsertion de la capsule articulaire du genou, par abord direct",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "189.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA005",
    designation:
      "Capsulo-myo-ténoplastie du genou pour recentrage de la patelle, par arthrotomie",
    tarifSecteur1Optam: "228.3",
    tarifHorsSecteur1HorsOptam: "202.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMA002",
    designation:
      "Plastie ligamentaire extraarticulaire latérale du genou avec le fascia lata, par abord direct",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "194",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFPC001",
    designation:
      "Section du rétinaculum patellaire latéral [aileron rotulien externe], par arthroscopie",
    tarifSecteur1Optam: "134",
    tarifHorsSecteur1HorsOptam: "130.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFPA002",
    designation:
      "Section du rétinaculum patellaire latéral [aileron rotulien externe], par arthrotomie",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFPA004",
    designation:
      "Section du rétinaculum patellaire latéral [aileron rotulien externe] avec ostéotomie de la surface patellaire [trochlée] du fémur, par arthrotomie",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFC004",
    designation: "Méniscectomie latérale ou médiale du genou, par arthroscopie",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "181.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFA003",
    designation: "Méniscectomie latérale ou médiale du genou, par arthrotomie",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFC003",
    designation:
      "Méniscectomies latérale et médiale du genou, par arthroscopie",
    tarifSecteur1Optam: "270.13",
    tarifHorsSecteur1HorsOptam: "262.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFFA001",
    designation: "Méniscectomies latérale et médiale du genou, par arthrotomie",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFEC002",
    designation:
      "Réinsertion ou suture d'un ménisque du genou, par arthroscopie",
    tarifSecteur1Optam: "283.6",
    tarifHorsSecteur1HorsOptam: "234.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFEA002",
    designation:
      "Réinsertion ou suture d'un ménisque du genou, par arthrotomie",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "219.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFEC001",
    designation:
      "Réinsertion ou suture des 2 ménisques du genou, par arthroscopie",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "344.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFEA001",
    designation:
      "Réinsertion ou suture des 2 ménisques du genou, par arthrotomie",
    tarifSecteur1Optam: "338.19",
    tarifHorsSecteur1HorsOptam: "301.49",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCC408",
    designation:
      "Microperforations souschondrales du genou avec pose de membrane de collagène, par arthroscopie",
    tarifSecteur1Optam: "425",
    tarifHorsSecteur1HorsOptam: "425",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFCA132",
    designation:
      "Microperforations souschondrales du genou avec pose de membrane de collagène, par arthrotomie",
    tarifSecteur1Optam: "425",
    tarifHorsSecteur1HorsOptam: "425",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGRP001",
    designation:
      "Mobilisation de l'articulation de la cheville à visée thérapeutique, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "43.96",
    tarifHorsSecteur1HorsOptam: "33.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGJC002",
    designation:
      "Évacuation de collection de l'articulation tibiotalienne ou d'une articulation du pied, par arthroscopie",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "143.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGJA002",
    designation:
      "Évacuation de collection de l'articulation tibiotalienne ou d'une articulation du pied, par arthrotomie",
    tarifSecteur1Optam: "102.81",
    tarifHorsSecteur1HorsOptam: "94.74",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGEP001",
    designation:
      "Réduction orthopédique d'une luxation tibiotalienne ou d'une luxation-fracture du tarse",
    tarifSecteur1Optam: "69.48",
    tarifHorsSecteur1HorsOptam: "53.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGEA001",
    designation:
      "Réduction d'une luxation de l'articulation tibiotalienne, talocalcanéenne et/ou médiotarsienne, par arthrotomie",
    tarifSecteur1Optam: "192.85",
    tarifHorsSecteur1HorsOptam: "164.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGJC001",
    designation: "Nettoyage de l'articulation tibiotalienne, par arthroscopie",
    tarifSecteur1Optam: "212.7",
    tarifHorsSecteur1HorsOptam: "176.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGJA001",
    designation: "Nettoyage de l'articulation tibiotalienne, par arthrotomie",
    tarifSecteur1Optam: "143.22",
    tarifHorsSecteur1HorsOptam: "118.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGDC001",
    designation: "Arthrodèse tibiotalienne, par arthroscopie",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "257.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGDA002",
    designation: "Arthrodèse tibiotalienne, par arthrotomie",
    tarifSecteur1Optam: "379.32",
    tarifHorsSecteur1HorsOptam: "290.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGDA001",
    designation: "Arthrodèse tibio-talo-calcanéenne, par arthrotomie",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "326.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGDA003",
    designation:
      "Arthrodèses tibio-talo-calcanéenne et médiotarsienne, par arthrotomie",
    tarifSecteur1Optam: "484.96",
    tarifHorsSecteur1HorsOptam: "369.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGDA004",
    designation:
      "Arthrorise tibiotalienne ou talocalcanéenne, par abord direct",
    tarifSecteur1Optam: "211.99",
    tarifHorsSecteur1HorsOptam: "193.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGKA001",
    designation: "Remplacement de l'articulation tibiotalienne par prothèse",
    tarifSecteur1Optam: "472.19",
    tarifHorsSecteur1HorsOptam: "379.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGGA001",
    designation: "Ablation d'une prothèse tibiotalienne",
    tarifSecteur1Optam: "295.65",
    tarifHorsSecteur1HorsOptam: "254.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGGA002",
    designation: "Ablation d'une prothèse tibiotalienne avec arthrodèse",
    tarifSecteur1Optam: "492.05",
    tarifHorsSecteur1HorsOptam: "403.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGFA001",
    designation:
      '"Résection ""en bloc"" de l\'articulation de la cheville [Arthrectomie monobloc de la cheville]"',
    tarifSecteur1Optam: "370.81",
    tarifHorsSecteur1HorsOptam: "285.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGMA001",
    designation:
      "Reconstruction de l'articulation de la cheville avec arthrodèse, après perte de substance segmentaire",
    tarifSecteur1Optam: "411.93",
    tarifHorsSecteur1HorsOptam: "344.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGPC001",
    designation:
      "Libération mobilisatrice de l'articulation tibiotalienne et/ou synovectomie tibiotalienne, par arthroscopie",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "219.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGPA002",
    designation:
      "Libération mobilisatrice de l'articulation tibiotalienne et/ou synovectomie tibiotalienne, par arthrotomie",
    tarifSecteur1Optam: "258.79",
    tarifHorsSecteur1HorsOptam: "202.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGPA003",
    designation:
      "Libération mobilisatrice de l'articulation tibiotalienne et/ou synovectomie tibiotalienne avec allongement du tendon calcanéen [d'Achille], par abord direct",
    tarifSecteur1Optam: "309.12",
    tarifHorsSecteur1HorsOptam: "271.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGPA001",
    designation:
      "Libération mobilisatrice de l'articulation tibiotalienne et/ou synovectomie tibiotalienne avec allongement et/ou transfert de plusieurs tendons, par abord direct",
    tarifSecteur1Optam: "343.99",
    tarifHorsSecteur1HorsOptam: "343.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGCA001",
    designation:
      "Suture ou reconstruction de l'appareil capsuloligamentaire de l'articulation tibiotalienne et/ou de l'articulation talocalcanéenne, par abord direct",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHRP002",
    designation:
      "Mobilisation d'articulation du pied à visée thérapeutique, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "43.96",
    tarifHorsSecteur1HorsOptam: "33.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHRP001",
    designation:
      "Manipulation de pied pour déformation congénitale, avec confection d'un appareil rigide externe de réduction progressive",
    tarifSecteur1Optam: "80.83",
    tarifHorsSecteur1HorsOptam: "58.98",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NHRP003",
    designation:
      "Manipulation de pied bot varus équin ou de pied convexe, avec confection d'un appareil rigide externe de réduction progressive",
    tarifSecteur1Optam: "91.46",
    tarifHorsSecteur1HorsOptam: "65.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NHDA011",
    designation: "Arthrodèse talocalcanéenne [subtalienne], par arthrotomie",
    tarifSecteur1Optam: "317.63",
    tarifHorsSecteur1HorsOptam: "254.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA009",
    designation:
      "Arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne], par abord direct",
    tarifSecteur1Optam: "331.1",
    tarifHorsSecteur1HorsOptam: "279.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA003",
    designation:
      "Arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] avec correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "343.87",
    tarifHorsSecteur1HorsOptam: "287.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA010",
    designation:
      "Arthrodèse du couple de torsion du pied [talocalcanéenne et médiotarsienne] avec transfert tendineux, par abord direct",
    tarifSecteur1Optam: "367.26",
    tarifHorsSecteur1HorsOptam: "339.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA006",
    designation: "Arthrodèse d'un interligne du médiotarse, par arthrotomie",
    tarifSecteur1Optam: "252.4",
    tarifHorsSecteur1HorsOptam: "216.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA008",
    designation: "Arthrodèse complète du médiotarse, par arthrotomie",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "242.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA007",
    designation:
      "Arthrodèse d'une articulation tarsométatarsienne, par arthrotomie",
    tarifSecteur1Optam: "180.09",
    tarifHorsSecteur1HorsOptam: "174.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA005",
    designation:
      "Arthrodèse de plusieurs articulations tarsométatarsiennes, par arthrotomie",
    tarifSecteur1Optam: "295.65",
    tarifHorsSecteur1HorsOptam: "259.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA004",
    designation:
      "Arthrodèse de la première articulation métatarsophalangienne, par arthrotomie",
    tarifSecteur1Optam: "177.96",
    tarifHorsSecteur1HorsOptam: "173.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA001",
    designation:
      "Arthrodèse de la première articulation métatarsophalangienne, avec résection des têtes des métatarsiens latéraux",
    tarifSecteur1Optam: "260.91",
    tarifHorsSecteur1HorsOptam: "230.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHDA002",
    designation:
      "Arthrodèse de l'articulation interphalangienne du premier orteil, par arthrotomie",
    tarifSecteur1Optam: "123.37",
    tarifHorsSecteur1HorsOptam: "89.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHMA007",
    designation:
      "Arthroplastie métatarsophalangienne par résection d'un versant de l'articulation, sur le premier rayon du pied",
    tarifSecteur1Optam: "134.99",
    tarifHorsSecteur1HorsOptam: "134.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHMA006",
    designation:
      "Arthroplastie métatarsophalangienne par résection des deux versants de l'articulation avec résection d'ostéophytes, sur le premier rayon du pied",
    tarifSecteur1Optam: "149.6",
    tarifHorsSecteur1HorsOptam: "143.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHMA003",
    designation:
      "Arthroplastie métatarsophalangienne par résection de la tête métatarsienne sur un rayon latéral du pied",
    tarifSecteur1Optam: "134.99",
    tarifHorsSecteur1HorsOptam: "134.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHMA001",
    designation:
      "Arthroplastie métatarsophalangienne par résection de la tête métatarsienne sur plusieurs rayons latéraux du pied",
    tarifSecteur1Optam: "166.34",
    tarifHorsSecteur1HorsOptam: "166.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHMA002",
    designation:
      "Arthroplastie par résection de l'articulation ou arthrodèse interphalangienne d'un orteil latéral",
    tarifSecteur1Optam: "83.66",
    tarifHorsSecteur1HorsOptam: "66.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHKA001",
    designation:
      "Remplacement de l'articulation métatarsophalangienne par prothèse sur un rayon du pied",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "157.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHPA004",
    designation:
      "Libération mobilisatrice des articulations tarsométatarsiennes sur 5 rayons du pied",
    tarifSecteur1Optam: "215.54",
    tarifHorsSecteur1HorsOptam: "204.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHPA005",
    designation:
      "Libération mobilisatrice des articulations tarsométatarsiennes avec arthrorise talocalcanéenne",
    tarifSecteur1Optam: "342.45",
    tarifHorsSecteur1HorsOptam: "277.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHPA003",
    designation:
      "Libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil pour correction d'hallux varus",
    tarifSecteur1Optam: "170.87",
    tarifHorsSecteur1HorsOptam: "151.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHPA002",
    designation:
      "Libération mobilisatrice de l'articulation métatarsophalangienne du premier orteil avec transfert tendineux ou syndesmopexie",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHPA001",
    designation:
      "Libération mobilisatrice d'une articulation métatarsophalangienne, sur un rayon latéral du pied",
    tarifSecteur1Optam: "136.13",
    tarifHorsSecteur1HorsOptam: "131.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHPA006",
    designation:
      "Libération mobilisatrice de l'articulation métatarsophalangienne du cinquième rayon, avec transfert et/ou allongement de tendon, ostéotomie du métatarsien et/ou syndactylisation",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHFA001",
    designation: "Synovectomie articulaire de l'avant-pied",
    tarifSecteur1Optam: "138.96",
    tarifHorsSecteur1HorsOptam: "133.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NHMA008",
    designation:
      "Reconstruction de l'appareil capsuloligamentaire de l'articulation médiotarsienne",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZJB001",
    designation:
      "Évacuation de collection articulaire du membre inférieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "60.3",
    tarifHorsSecteur1HorsOptam: "47.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZLB001",
    designation:
      "Injection thérapeutique d'agent pharmacologique dans une articulation ou une bourse séreuse du membre inférieur, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "30.82",
    tarifHorsSecteur1HorsOptam: "30.82",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZLH002",
    designation:
      "Injection thérapeutique d'agent pharmacologique dans une articulation ou une bourse séreuse du membre inférieur, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "18.76",
    tarifHorsSecteur1HorsOptam: "14.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZLH001",
    designation:
      "Injection thérapeutique d'agent pharmacologique dans une articulation ou une bourse séreuse du membre inférieur, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "23.52",
    tarifHorsSecteur1HorsOptam: "14.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NJEA012",
    designation: "Transfert du psoas à travers ou en avant de l'aile iliaque",
    tarifSecteur1Optam: "307.71",
    tarifHorsSecteur1HorsOptam: "266.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA004",
    designation:
      "Transfert du psoas à travers ou en avant de l'aile iliaque, avec ostéotomie de l'os coxal ou du fémur",
    tarifSecteur1Optam: "594.85",
    tarifHorsSecteur1HorsOptam: "520.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA001",
    designation:
      "Transfert du psoas à travers ou en avant de l'aile iliaque, avec ostéotomie de l'os coxal et du fémur",
    tarifSecteur1Optam: "771.39",
    tarifHorsSecteur1HorsOptam: "644.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPB001",
    designation:
      "Section de tendon pour correction d'attitude vicieuse de la hanche, par voie transcutanée",
    tarifSecteur1Optam: "134.71",
    tarifHorsSecteur1HorsOptam: "90.23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NJPA035",
    designation:
      "Section ou allongement de tendon pour correction d'attitude vicieuse de la hanche, par abord unique",
    tarifSecteur1Optam: "231.13",
    tarifHorsSecteur1HorsOptam: "221.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA034",
    designation:
      "Section ou allongement des tendons du muscle psoas et d'autre muscle pour correction d'attitude vicieuse de la hanche, par abord unique",
    tarifSecteur1Optam: "271.55",
    tarifHorsSecteur1HorsOptam: "245.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA003",
    designation:
      "Section ou allongement de tendon pour correction d'attitude vicieuse de la hanche, par plusieurs abords",
    tarifSecteur1Optam: "294.94",
    tarifHorsSecteur1HorsOptam: "258.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA001",
    designation:
      "Résection d'ostéome paraarticulaire coxofémoral antérieur, postérieur ou médial",
    tarifSecteur1Optam: "324.01",
    tarifHorsSecteur1HorsOptam: "258.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA009",
    designation:
      "Résection d'ostéome paraarticulaire coxofémoral antérieur et postérieur",
    tarifSecteur1Optam: "514.73",
    tarifHorsSecteur1HorsOptam: "403.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA006",
    designation: "Exérèse d'un fragment osseux supratrochantérien",
    tarifSecteur1Optam: "92.88",
    tarifHorsSecteur1HorsOptam: "80.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA002",
    designation:
      "Réinsertion et/ou suture du tendon du muscle quadriceps fémoral",
    tarifSecteur1Optam: "185.05",
    tarifHorsSecteur1HorsOptam: "133.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA007",
    designation:
      "Réinsertion et/ou suture du ligament patellaire [tendon rotulien]",
    tarifSecteur1Optam: "182.92",
    tarifHorsSecteur1HorsOptam: "141.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA002",
    designation:
      "Reconstruction de l’appareil extenseur du genou par autogreffe ostéotendineuse prélevée sur le genou homolatéral",
    tarifSecteur1Optam: "290.69",
    tarifHorsSecteur1HorsOptam: "234.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA001",
    designation:
      "Reconstruction de l’appareil extenseur du genou par autogreffe ostéotendineuse prélevée sur le genou controlatéral",
    tarifSecteur1Optam: "371.52",
    tarifHorsSecteur1HorsOptam: "280.96",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJAA002",
    designation:
      "Allongement et/ou transfert de tendon sans capsulotomie pour correction d'attitude vicieuse du genou, par abord direct",
    tarifSecteur1Optam: "271.55",
    tarifHorsSecteur1HorsOptam: "249.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJAA004",
    designation:
      "Allongement et/ou transfert de plusieurs tendons avec capsulotomie pour correction d'attitude vicieuse du genou, par abord direct",
    tarifSecteur1Optam: "307",
    tarifHorsSecteur1HorsOptam: "283.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA032",
    designation:
      "Incisions axiales [Peignage] du ligament patellaire [tendon rotulien], sans plastie",
    tarifSecteur1Optam: "113.44",
    tarifHorsSecteur1HorsOptam: "100.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA016",
    designation:
      "Incisions axiales [Peignage] du ligament patellaire [tendon rotulien], avec plastie autologue",
    tarifSecteur1Optam: "143.93",
    tarifHorsSecteur1HorsOptam: "118.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJBA002",
    designation: "Raccourcissement du ligament patellaire [tendon rotulien]",
    tarifSecteur1Optam: "226.17",
    tarifHorsSecteur1HorsOptam: "196.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJBA001",
    designation:
      "Raccourcissement du ligament patellaire [tendon rotulien], avec section ou allongement du muscle droit fémoral",
    tarifSecteur1Optam: "255.95",
    tarifHorsSecteur1HorsOptam: "213.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA008",
    designation: "Exérèse d’un hygroma du genou, par abord direct",
    tarifSecteur1Optam: "92.88",
    tarifHorsSecteur1HorsOptam: "67.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA007",
    designation:
      "Correction progressive d'une déformation du genou par fixateur externe",
    tarifSecteur1Optam: "310.54",
    tarifHorsSecteur1HorsOptam: "293.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMB001",
    designation:
      "Réparation d'une rupture du tendon calcanéen [d'Achille], par voie transcutanée",
    tarifSecteur1Optam: "134.71",
    tarifHorsSecteur1HorsOptam: "113.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NJCA001",
    designation: "Suture d'un tendon de l'arrière-pied, par abord direct",
    tarifSecteur1Optam: "148.89",
    tarifHorsSecteur1HorsOptam: "121.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA003",
    designation: "Autoplastie d'un tendon de l'arrière-pied, par abord direct",
    tarifSecteur1Optam: "163.07",
    tarifHorsSecteur1HorsOptam: "160.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA003",
    designation:
      "Réinsertion du tendon calcanéen [d'Achille], par abord direct",
    tarifSecteur1Optam: "172.29",
    tarifHorsSecteur1HorsOptam: "165.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJAB001",
    designation:
      "Allongement du tendon calcanéen [d'Achille], par voie transcutanée",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NJAA003",
    designation: "Allongement d'un tendon de l'arrière-pied, par abord direct",
    tarifSecteur1Optam: "155.98",
    tarifHorsSecteur1HorsOptam: "155.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJAA001",
    designation:
      "Allongement de plusieurs tendons de l'arrière-pied, par abord direct",
    tarifSecteur1Optam: "249.94",
    tarifHorsSecteur1HorsOptam: "249.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA014",
    designation:
      "Aponévrotomie intramusculaire pour allongement du muscle triceps sural, par abord direct",
    tarifSecteur1Optam: "167.32",
    tarifHorsSecteur1HorsOptam: "162.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA018",
    designation:
      "Libération, incisions axiales ou ténosynovectomie d'un tendon de l'arrière-pied",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA005",
    designation: "Ténosynovectomie de plusieurs tendons de l'arrière-pied",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA009",
    designation:
      "Transfert tendineux et/ou ténodèse pour rétablissement de la flexion dorsale de la cheville",
    tarifSecteur1Optam: "253.82",
    tarifHorsSecteur1HorsOptam: "239.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA011",
    designation:
      "Transfert tendineux et/ou ténodèse pour rétablissement de la flexion plantaire de la cheville",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA006",
    designation:
      "Transfert tendineux et/ou ténodèse pour rétablissement de l'éversion ou de l'inversion du pied",
    tarifSecteur1Optam: "218.59",
    tarifHorsSecteur1HorsOptam: "218.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA010",
    designation:
      "Transfert tendineux pour rétablissement de la fonction intrinsèque du premier orteil et/ou des orteils latéraux",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "134.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA008",
    designation:
      "Transfert tendineux pour rétablissement de l'extension du premier orteil",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "134.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA025",
    designation:
      "Section ou allongement d'un tendon de muscle extenseur ou de muscle fléchisseur des orteils à l'avant-pied",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA029",
    designation:
      "Section ou allongement de plusieurs tendons pour correction d'attitude vicieuse du pied",
    tarifSecteur1Optam: "232.55",
    tarifHorsSecteur1HorsOptam: "226.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NGFA002",
    designation:
      "Exérèse d'un kyste synovial ou d'une bourse séreuse de la cheville ou du pied, par abord direct",
    tarifSecteur1Optam: "94.3",
    tarifHorsSecteur1HorsOptam: "72.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA023",
    designation:
      "Libération antérieure de la cheville et du pied, pour pied convexe",
    tarifSecteur1Optam: "403.42",
    tarifHorsSecteur1HorsOptam: "312.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA010",
    designation:
      "Libérations antérieure et postérieure de la cheville et du pied, pour pied convexe",
    tarifSecteur1Optam: "491.34",
    tarifHorsSecteur1HorsOptam: "363.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA002",
    designation:
      "Libération des tissus mous de la cheville ou de l'arrière-pied avec capsulotomie, par abord postérieur",
    tarifSecteur1Optam: "322.6",
    tarifHorsSecteur1HorsOptam: "266.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA019",
    designation:
      "Libération des tissus mous de la cheville ou de l'arrière-pied avec capsulotomie, par abord postérieur et par abord médial",
    tarifSecteur1Optam: "438.87",
    tarifHorsSecteur1HorsOptam: "333.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA009",
    designation:
      "Libération des tissus mous de la cheville ou de l'arrière-pied et du médiopied avec capsulotomie, par abord postérieur, abord médial et abord latéral",
    tarifSecteur1Optam: "466.52",
    tarifHorsSecteur1HorsOptam: "349.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA027",
    designation:
      "Libération des tissus mous de la cheville ou de l'arrière-pied et du médiopied avec capsulotomie et ostéotomie du tarse ou du métatarse, par abord postérieur, abord médial et abord latéral",
    tarifSecteur1Optam: "527.5",
    tarifHorsSecteur1HorsOptam: "437.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA007",
    designation:
      "Aponévrotomie ou aponévrectomie plantaire, ou désinsertion de l'aponévrose plantaire",
    tarifSecteur1Optam: "134",
    tarifHorsSecteur1HorsOptam: "121.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA015",
    designation:
      "Aponévrotomie et désinsertion musculaire à la plante du pied [Libération plantaire]",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "208.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA006",
    designation:
      "Aponévrotomie et désinsertion musculaire à la plante du pied [Libération plantaire] avec ostéotomie du métatarse",
    tarifSecteur1Optam: "245.31",
    tarifHorsSecteur1HorsOptam: "212.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJEA005",
    designation:
      "Alignement d'une camptodactylie d'orteil par libération des tissus mous",
    tarifSecteur1Optam: "161.65",
    tarifHorsSecteur1HorsOptam: "146.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDFA007",
    designation: "Exérèse d'un os sésamoïde du pied",
    tarifSecteur1Optam: "98.55",
    tarifHorsSecteur1HorsOptam: "74.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA004",
    designation:
      "Reconstruction du rétinaculum des muscles fibulaires [gaine des tendons péroniers] par plastie capsuloligamentaire, périostée et/ou osseuse",
    tarifSecteur1Optam: "184.34",
    tarifHorsSecteur1HorsOptam: "150.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA005",
    designation:
      "Correction progressive d'une déformation du pied par fixateur externe",
    tarifSecteur1Optam: "387.82",
    tarifHorsSecteur1HorsOptam: "330.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJMA006",
    designation:
      "Correction progressive d'une déformation du pied par fixateur externe, avec libération des tissus mous",
    tarifSecteur1Optam: "505.52",
    tarifHorsSecteur1HorsOptam: "424.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA012",
    designation:
      "Amputation d'un orteil surnuméraire, sans reconstruction articulaire",
    tarifSecteur1Optam: "175.83",
    tarifHorsSecteur1HorsOptam: "110.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA011",
    designation:
      "Amputation d'un orteil surnuméraire, avec reconstruction articulaire",
    tarifSecteur1Optam: "301.33",
    tarifHorsSecteur1HorsOptam: "235.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZMA002",
    designation:
      "Reconstruction de l'avant-pied pour malformation de type pied en fourche",
    tarifSecteur1Optam: "335.36",
    tarifHorsSecteur1HorsOptam: "277.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZMA001",
    designation:
      "Reconstruction et réalignement du premier rayon du pied pour malformation",
    tarifSecteur1Optam: "289.98",
    tarifHorsSecteur1HorsOptam: "247.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZEA004",
    designation: "Repositionnement du pied pour agénésie de la fibula",
    tarifSecteur1Optam: "471.49",
    tarifHorsSecteur1HorsOptam: "378.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZEA001",
    designation: "Repositionnement du pied pour agénésie du tibia",
    tarifSecteur1Optam: "411.28",
    tarifHorsSecteur1HorsOptam: "411.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA003",
    designation:
      "Résection des tissus mous pour hypertrophie et gigantisme du pied",
    tarifSecteur1Optam: "229.01",
    tarifHorsSecteur1HorsOptam: "211.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA004",
    designation:
      "Résection des tissus mous avec résection osseuse pour hypertrophie et gigantisme du pied avec dysplasie",
    tarifSecteur1Optam: "403.42",
    tarifHorsSecteur1HorsOptam: "312.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QDPA001",
    designation:
      "Séparation d'une syndactylie des orteils par plastie cutanée d'une ou plusieurs commissures",
    tarifSecteur1Optam: "200.65",
    tarifHorsSecteur1HorsOptam: "169.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA030",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche et du genou, par abord direct",
    tarifSecteur1Optam: "423.98",
    tarifHorsSecteur1HorsOptam: "416.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA011",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche et du genou, avec raccourcissement du ligament patellaire, par abord direct",
    tarifSecteur1Optam: "553.73",
    tarifHorsSecteur1HorsOptam: "491.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA036",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche et du genou, avec arthrodèse du couple de torsion du pied et correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "583.68",
    tarifHorsSecteur1HorsOptam: "583.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA024",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche, du genou et du pied, par abord direct",
    tarifSecteur1Optam: "538.13",
    tarifHorsSecteur1HorsOptam: "482.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA021",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec raccourcissement du ligament patellaire, par abord direct",
    tarifSecteur1Optam: "713.25",
    tarifHorsSecteur1HorsOptam: "584.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA004",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec arthrodèse du couple de torsion du pied et correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "766.43",
    tarifHorsSecteur1HorsOptam: "615.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA005",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec ostéotomie simple de la diaphyse du fémur, par abord direct",
    tarifSecteur1Optam: "855.76",
    tarifHorsSecteur1HorsOptam: "693.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA017",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec ostéotomie simple de la diaphyse du fémur et du tibia, par abord direct",
    tarifSecteur1Optam: "977",
    tarifHorsSecteur1HorsOptam: "763.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA031",
    designation:
      "Section, allongement ou transfert tendineux pour correction unilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec ostéotomie simple de la diaphyse du fémur, arthrodèse du couple de torsion du pied et correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "1065.63",
    tarifHorsSecteur1HorsOptam: "815.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA013",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche et du genou, par abord direct",
    tarifSecteur1Optam: "570.75",
    tarifHorsSecteur1HorsOptam: "528.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA026",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche et du genou, avec raccourcissement du ligament patellaire, par abord direct",
    tarifSecteur1Optam: "965.66",
    tarifHorsSecteur1HorsOptam: "757.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA012",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche et du genou, avec arthrodèse du couple de torsion du pied et correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "1069.17",
    tarifHorsSecteur1HorsOptam: "817.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA022",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche, du genou et du pied, par abord direct",
    tarifSecteur1Optam: "830.95",
    tarifHorsSecteur1HorsOptam: "679.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA028",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec raccourcissement du ligament patellaire, par abord direct",
    tarifSecteur1Optam: "1166.31",
    tarifHorsSecteur1HorsOptam: "873.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA008",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec arthrodèse du couple de torsion du pied et correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "1184.74",
    tarifHorsSecteur1HorsOptam: "884.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA020",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec ostéotomie simple de la diaphyse du fémur, par abord direct",
    tarifSecteur1Optam: "1063.97",
    tarifHorsSecteur1HorsOptam: "1063.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA001",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec ostéotomie simple de la diaphyse du fémur et du tibia, par abord direct",
    tarifSecteur1Optam: "1638.5",
    tarifHorsSecteur1HorsOptam: "1180.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJPA033",
    designation:
      "Section, allongement ou transfert tendineux pour correction bilatérale d'attitude vicieuse de la hanche, du genou et du pied, avec ostéotomie simple de la diaphyse du fémur, arthrodèse du couple de torsion du pied et correction de vices architecturaux, par abord direct",
    tarifSecteur1Optam: "1819.29",
    tarifHorsSecteur1HorsOptam: "1285.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NFMP001",
    designation: "Confection d'une contention souple du genou",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NGMP001",
    designation:
      "Confection d'une contention souple de la cheville et/ou du pied, ou confection d'une semelle plâtrée",
    tarifSecteur1Optam: "20.9",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZMP012",
    designation:
      "Confection d'un appareil rigide pelvicrural [pelvijambier] ou pelvipédieux d'immobilisation du membre inférieur",
    tarifSecteur1Optam: "64.47",
    tarifHorsSecteur1HorsOptam: "64.47",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP002",
    designation:
      "Confection d'un appareil rigide d'immobilisation pelvibifémoral, pelvibicrural [pelvibijambier] ou pelvibipédieux",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP009",
    designation:
      "Confection d'un appareil rigide d'immobilisation solidaire bifémorocrural [bifémorojambier] ou bifémoropédieux",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZMP007",
    designation:
      "Confection d'un appareil rigide d'immobilisation du membre inférieur prenant le genou",
    tarifSecteur1Optam: "35.45",
    tarifHorsSecteur1HorsOptam: "33.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZMP003",
    designation:
      "Confection d'un appareil rigide d'immobilisation de la jambe, de la cheville et/ou du pied ne prenant pas le genou",
    tarifSecteur1Optam: "27.57",
    tarifHorsSecteur1HorsOptam: "27.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NFMP002",
    designation:
      "Confection d'une attelle de posture ou de mobilisation du genou",
    tarifSecteur1Optam: "47.57",
    tarifHorsSecteur1HorsOptam: "40.76",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NGMP002",
    designation:
      "Confection d'une attelle de posture ou de mobilisation de la cheville",
    tarifSecteur1Optam: "40.87",
    tarifHorsSecteur1HorsOptam: "32.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP006",
    designation:
      "Confection d'une orthèse articulée cruropédieuse [jambopédieuse]",
    tarifSecteur1Optam: "48.24",
    tarifHorsSecteur1HorsOptam: "41.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP001",
    designation:
      "Confection d'une orthèse articulée fémorocrurale [fémorojambière]",
    tarifSecteur1Optam: "48.24",
    tarifHorsSecteur1HorsOptam: "41.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP003",
    designation:
      "Confection d'une orthèse non articulée cruropédieuse [jambopédieuse]",
    tarifSecteur1Optam: "34.84",
    tarifHorsSecteur1HorsOptam: "33.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP007",
    designation:
      "Confection d'une orthèse non articulée fémorocrurale [fémorojambière]",
    tarifSecteur1Optam: "34.84",
    tarifHorsSecteur1HorsOptam: "33.37",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP004",
    designation: "Confection d'une orthèse plantaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZEMP008",
    designation:
      "Confection d'une orthèse pour correction d'une déformation et/ou protection des orteils",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZEP002",
    designation:
      "Traction continue cutanée du membre inférieur d'une durée inférieure à 7 jours, pour lésion non traumatique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZEP001",
    designation:
      "Traction continue cutanée du membre inférieur d'une durée égale ou supérieure à 7 jours, pour lésion non traumatique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZEB001",
    designation:
      "Traction continue transosseuse du membre inférieur, pour lésion non traumatique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NZFA008",
    designation: "Désarticulation ou amputation interilioabdominale",
    tarifSecteur1Optam: "906.1",
    tarifHorsSecteur1HorsOptam: "744.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA006",
    designation:
      "Désarticulation ou amputation du membre inférieur à travers l'os coxal, l'articulation sacro-iliaque ou le sacrum",
    tarifSecteur1Optam: "822.71",
    tarifHorsSecteur1HorsOptam: "822.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA001",
    designation: "Désarticulation de la hanche",
    tarifSecteur1Optam: "752.96",
    tarifHorsSecteur1HorsOptam: "524.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA007",
    designation: "Amputation transfémorale",
    tarifSecteur1Optam: "287.85",
    tarifHorsSecteur1HorsOptam: "219.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA003",
    designation: "Désarticulation du genou",
    tarifSecteur1Optam: "511.9",
    tarifHorsSecteur1HorsOptam: "349.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA002",
    designation: "Amputation transtibiale",
    tarifSecteur1Optam: "284.31",
    tarifHorsSecteur1HorsOptam: "217.57",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA009",
    designation:
      "Amputation ou désarticulation à la cheville ou à l'arrière-pied",
    tarifSecteur1Optam: "411.22",
    tarifHorsSecteur1HorsOptam: "291.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA005",
    designation:
      "Amputation ou désarticulation au médiopied ou à l'avant-pied, sans stabilisation de l'arrière-pied",
    tarifSecteur1Optam: "246.73",
    tarifHorsSecteur1HorsOptam: "195.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA013",
    designation:
      "Amputation ou désarticulation du médiopied ou de l'avant-pied, avec stabilisation de l'arrière-pied",
    tarifSecteur1Optam: "482.83",
    tarifHorsSecteur1HorsOptam: "332.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA010",
    designation: "Amputation ou désarticulation d'un orteil",
    tarifSecteur1Optam: "107.06",
    tarifHorsSecteur1HorsOptam: "70.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZFA004",
    designation: "Amputation ou désarticulation de plusieurs orteils",
    tarifSecteur1Optam: "148.18",
    tarifHorsSecteur1HorsOptam: "101.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZEA006",
    designation: "Réimplantation du membre inférieur sectionné à la cuisse",
    tarifSecteur1Optam: "787.29",
    tarifHorsSecteur1HorsOptam: "787.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZEA002",
    designation: "Réimplantation du membre inférieur sectionné à la jambe",
    tarifSecteur1Optam: "846.92",
    tarifHorsSecteur1HorsOptam: "846.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZEA007",
    designation: "Réimplantation du pied",
    tarifSecteur1Optam: "846.92",
    tarifHorsSecteur1HorsOptam: "846.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NDEA002",
    designation: "Réimplantation du premier orteil",
    tarifSecteur1Optam: "737.77",
    tarifHorsSecteur1HorsOptam: "737.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NZEA003",
    designation:
      "Transplantation du pied à la cuisse avec conservation des pédicules vasculonerveux",
    tarifSecteur1Optam: "737.77",
    tarifHorsSecteur1HorsOptam: "737.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PBQM002",
    designation: "Échographie unilatérale ou bilatérale d'une articulation",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "34.97",
    regroupement: "ADE",
  },
  {
    codeCCAM: "PBQM003",
    designation:
      "Échographie unilatérale ou bilatérale d'une articulation et de son appareil capsuloligamentaire",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "PBQM001",
    designation:
      "Échographie unilatérale ou bilatérale de plusieurs articulations",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "PBQM004",
    designation:
      "Échographie unilatérale ou bilatérale de plusieurs articulations et de leur appareil capsuloligamentaire",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "PCQM001",
    designation: "Échographie de muscle et/ou de tendon",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "PAQK003",
    designation:
      "Radiographie du squelette complet, segment par segment, chez l'enfant",
    tarifSecteur1Optam: "159.6",
    tarifHorsSecteur1HorsOptam: "159.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK004",
    designation: "Radiographie du squelette complet, chez le nouveau-né décédé",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK005",
    designation:
      "Radiographie de l'hémisquelette pour calcul de l'âge osseux, avant l'âge de 2 ans",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK002",
    designation:
      "Radiographie du squelette pour calcul de l'âge osseux, après l'âge de 2 ans",
    tarifSecteur1Optam: "19.95",
    tarifHorsSecteur1HorsOptam: "19.95",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK001",
    designation:
      "Radiographie comparative des cartilages de conjugaison des os longs des membres",
    tarifSecteur1Optam: "27.93",
    tarifHorsSecteur1HorsOptam: "27.93",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL006",
    designation:
      "Scintigraphie osseuse segmentaire en un temps [temps tardif], sans acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "168.71",
    tarifHorsSecteur1HorsOptam: "168.71",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL004",
    designation:
      "Scintigraphie osseuse segmentaire en un temps [temps tardif], avec acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "168.71",
    tarifHorsSecteur1HorsOptam: "168.71",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL008",
    designation:
      "Scintigraphie osseuse segmentaire en plusieurs temps, sans acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "251.39",
    tarifHorsSecteur1HorsOptam: "251.39",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL007",
    designation:
      "Scintigraphie osseuse segmentaire en plusieurs temps, avec acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "251.39",
    tarifHorsSecteur1HorsOptam: "251.39",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL003",
    designation:
      "Scintigraphie osseuse du corps entier en un temps [temps tardif]",
    tarifSecteur1Optam: "168.71",
    tarifHorsSecteur1HorsOptam: "168.71",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL002",
    designation: "Scintigraphie osseuse du corps entier en plusieurs temps",
    tarifSecteur1Optam: "251.39",
    tarifHorsSecteur1HorsOptam: "251.39",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL009",
    designation:
      "Scintigraphie osseuse du corps entier segment par segment en un temps [temps tardif], sans acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "168.71",
    tarifHorsSecteur1HorsOptam: "168.71",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL001",
    designation:
      "Scintigraphie osseuse du corps entier segment par segment en un temps [temps tardif], avec acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "168.71",
    tarifHorsSecteur1HorsOptam: "168.71",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL005",
    designation:
      "Scintigraphie osseuse du corps entier segment par segment en plusieurs temps, sans acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "251.39",
    tarifHorsSecteur1HorsOptam: "251.39",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQL010",
    designation:
      "Scintigraphie osseuse du corps entier segment par segment en plusieurs temps, avec acquisition complémentaire par un collimateur sténopé",
    tarifSecteur1Optam: "251.39",
    tarifHorsSecteur1HorsOptam: "251.39",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PCQL002",
    designation:
      "Exploration radio-isotopique des masses musculaires squelettiques au repos",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PCQL001",
    designation:
      "Exploration radio-isotopique des masses musculaires squelettiques après effort",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK007",
    designation:
      "Ostéodensitométrie [Absorptiométrie osseuse] sur 2 sites, par méthode biphotonique",
    tarifSecteur1Optam: "39.96",
    tarifHorsSecteur1HorsOptam: "39.96",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK900",
    designation:
      "Ostéodensitométrie [Absorptiométrie osseuse] du corps entier par méthode biphotonique, pour affection osseuse autre que constitutionnelle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PAQK008",
    designation:
      "Ostéodensitométrie [Absorptiométrie osseuse] du corps entier par méthode biphotonique, pour affection osseuse constitutionnelle chez l'enfant",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PEQP004",
    designation:
      "Analyse métrologique de la posture, de la locomotion et/ou des gestuelles chez un patient monodéficient",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PEQP002",
    designation:
      "Analyse métrologique de la posture, de la locomotion et/ou des gestuelles chez un patient polydéficient",
    tarifSecteur1Optam: "65.11",
    tarifHorsSecteur1HorsOptam: "65.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NKQP003",
    designation:
      "Analyse tridimensionnelle de la marche sur plateforme de force",
    tarifSecteur1Optam: "156.42",
    tarifHorsSecteur1HorsOptam: "156.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NKQP002",
    designation: "Analyse baropodométrique de la marche",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NKQP001",
    designation: "Analyse instrumentale de la cinématique de la marche",
    tarifSecteur1Optam: "65.11",
    tarifHorsSecteur1HorsOptam: "65.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PEQP003",
    designation:
      "Mesure de la force, du travail et de la puissance musculaire de 1 ou 2 articulations, par dynamomètre informatisé et motorisé",
    tarifSecteur1Optam: "65.11",
    tarifHorsSecteur1HorsOptam: "65.11",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PDQB001",
    designation:
      "Mesure directe de la pression tissulaire dans les loges musculaires des membres, par voie transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "NJQP001",
    designation:
      "Mesure du volume du pied et/ou de la jambe par déplacement d'eau ou méthode optoélectronique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCHB001",
    designation: "Biopsie musculaire, par voie transcutanée",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PDHA001",
    designation: "Biopsie des tissus mous sousfasciaux, par abord direct",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PZQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'amputation partielle ou totale d'un doigt ou d'un orteil",
    tarifSecteur1Optam: "74.13",
    tarifHorsSecteur1HorsOptam: "74.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PZQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'amputation partielle ou totale de main, de pied ou de plusieurs doigts ou orteils",
    tarifSecteur1Optam: "84.77",
    tarifHorsSecteur1HorsOptam: "84.77",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PZQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'amputation partielle ou totale de membre",
    tarifSecteur1Optam: "152.57",
    tarifHorsSecteur1HorsOptam: "152.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de tumeur de l'os et/ou de cartilage",
    tarifSecteur1Optam: "144.45",
    tarifHorsSecteur1HorsOptam: "144.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PDQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de tumeur des tissus mous fasciaux et/ou sousfasciaux [aponévrotiques et/ou sousaponévrotiques]",
    tarifSecteur1Optam: "93.14",
    tarifHorsSecteur1HorsOptam: "93.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PACB001",
    designation:
      "Perforation ou forage d'un os, ou injection intraosseuse, par voie transcutanée",
    tarifSecteur1Optam: "87.1",
    tarifHorsSecteur1HorsOptam: "54.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAMH001",
    designation:
      "Cimentoplastie intraosseuse extrarachidienne, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "139.36",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PALB900",
    designation: "Injection intraosseuse de moelle",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PACC001",
    designation:
      "Perforation ou forage d'une épiphyse fertile, par arthroscopie",
    tarifSecteur1Optam: "218.37",
    tarifHorsSecteur1HorsOptam: "179.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PACA001",
    designation:
      "Perforation ou forage d'une épiphyse fertile, par arthrotomie",
    tarifSecteur1Optam: "217.66",
    tarifHorsSecteur1HorsOptam: "170.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA008",
    designation:
      "Prélèvement d'un os long complet, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA001",
    designation:
      "Prélèvement de plusieurs os longs complets sur un membre, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA002",
    designation:
      "Prélèvement de plusieurs os longs complets sur plusieurs membres, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA006",
    designation:
      "Prélèvement d'un os autre qu'un os long, sur un sujet en état de mort encéphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PADA003",
    designation:
      "Fixation ou exérèse de noyau d'ossification accessoire, par abord direct",
    tarifSecteur1Optam: "121.24",
    tarifHorsSecteur1HorsOptam: "114.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PADB002",
    designation:
      "Épiphysiodèse d'un cartilage de croissance, par voie transcutanée",
    tarifSecteur1Optam: "153.14",
    tarifHorsSecteur1HorsOptam: "123.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PADA001",
    designation:
      "Épiphysiodèse sur un cartilage de croissance, par abord direct",
    tarifSecteur1Optam: "161.65",
    tarifHorsSecteur1HorsOptam: "128.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PADB001",
    designation:
      "Épiphysiodèse de plusieurs cartilages de croissance, par voie transcutanée",
    tarifSecteur1Optam: "215.54",
    tarifHorsSecteur1HorsOptam: "177.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAPA003",
    designation:
      "Ostéotomies multiples d'alignement avec abord des deux extrémités d'un os long, par abord direct",
    tarifSecteur1Optam: "376.2",
    tarifHorsSecteur1HorsOptam: "376.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAPA001",
    designation:
      "Ostéotomies multiples d'alignement avec abord des deux extrémités de plusieurs os longs homolatéraux, par abord direct",
    tarifSecteur1Optam: "558.69",
    tarifHorsSecteur1HorsOptam: "482.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAPA002",
    designation:
      "Ostéotomie itérative pour cal prématuré au cours de l'allongement progressif d'un os long",
    tarifSecteur1Optam: "175.83",
    tarifHorsSecteur1HorsOptam: "172.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA006",
    designation:
      "Évidement d'une épiphyse active sans comblement, par arthrotomie",
    tarifSecteur1Optam: "204.9",
    tarifHorsSecteur1HorsOptam: "162.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA003",
    designation:
      "Évidement d'une épiphyse active avec comblement, par arthrotomie",
    tarifSecteur1Optam: "253.11",
    tarifHorsSecteur1HorsOptam: "238.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA005",
    designation: "Évidement d'une épiphyse, par abord extraarticulaire",
    tarifSecteur1Optam: "272.97",
    tarifHorsSecteur1HorsOptam: "202.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA005",
    designation:
      "Résection segmentaire d'un os avec ostéosynthèse, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "409.09",
    tarifHorsSecteur1HorsOptam: "395.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA007",
    designation:
      "Résection segmentaire d'un os avec ostéosynthèse et transfert axial progressif de fragment osseux, pour pseudarthrose congénitale",
    tarifSecteur1Optam: "503.45",
    tarifHorsSecteur1HorsOptam: "503.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA011",
    designation:
      "Ablation de matériel d'ostéosynthèse des membres sur un site, par abord direct",
    tarifSecteur1Optam: "112.73",
    tarifHorsSecteur1HorsOptam: "82.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA009",
    designation:
      "Ablation de matériel d'ostéosynthèse des membres sur plusieurs sites, par abord direct",
    tarifSecteur1Optam: "205.61",
    tarifHorsSecteur1HorsOptam: "158.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA010",
    designation:
      "Ablation de matériel d'ostéosynthèse centromédullaire des membres sur un site, par abord direct",
    tarifSecteur1Optam: "104.22",
    tarifHorsSecteur1HorsOptam: "86.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGA008",
    designation:
      "Ablation de matériel d'ostéosynthèse centromédullaire des membres sur plusieurs sites, par abord direct",
    tarifSecteur1Optam: "190.72",
    tarifHorsSecteur1HorsOptam: "150.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAGB003",
    designation:
      "Ablation d'un fixateur externe ou d'un système externe d'allongement progressif d'un os des membres",
    tarifSecteur1Optam: "66.65",
    tarifHorsSecteur1HorsOptam: "56.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAGB004",
    designation: "Ablation de broche d'ostéosynthèse non enfouie",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAGB002",
    designation:
      "Ablation de matériel d'ostéosynthèse enfoui, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "75.15",
    tarifHorsSecteur1HorsOptam: "61.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAGB001",
    designation:
      "Ablation de broche d'ostéosynthèse enfouie, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "51.05",
    tarifHorsSecteur1HorsOptam: "47.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAGH001",
    designation:
      "Ablation de matériel d'ostéosynthèse enfoui, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAKB002",
    designation:
      "Changement complet d'un système externe d'allongement osseux progressif",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAKB001",
    designation:
      "Changement de fiche ou broche d'un fixateur externe ou d'un système externe d'allongement osseux",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAMP001",
    designation:
      "Réglage secondaire et/ou modification des axes d'un fixateur externe ou d'un système externe d'allongement progressif, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "108.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAFH001",
    designation:
      "Tumorectomie ostéoarticulaire d'un membre, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PANH001",
    designation:
      "Destruction d'une tumeur osseuse bénigne par agent physique par voie transcutanée, avec guidage scanographique",
    tarifSecteur1Optam: "306.19",
    tarifHorsSecteur1HorsOptam: "254.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZNH002",
    designation:
      "Sclérose d'une lésion vasculaire ostéoarticulaire d'un membre, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage radiologique",
    tarifSecteur1Optam: "231.3",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EZNH001",
    designation:
      "Sclérose d'une lésion vasculaire ostéoarticulaire d'un membre, par injection transcutanée intralésionnelle d'agent pharmacologique avec guidage scanographique",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PANL001",
    designation:
      "Irradiation interne d'une lésion osseuse par injection intraveineuse d'agent pharmacologique radio-isotopique",
    tarifSecteur1Optam: "79.71",
    tarifHorsSecteur1HorsOptam: "79.71",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAGA007",
    designation: "Désépiphysiodèse d'un os long, par abord direct",
    tarifSecteur1Optam: "318.34",
    tarifHorsSecteur1HorsOptam: "267.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PBJH001",
    designation:
      "Évacuation de calcification articulaire d'un membre, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "63.65",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PBLB001",
    designation:
      "Injection d'agent pharmacologique dans l'appareil capsuloligamentaire d'une articulation, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "20.77",
    tarifHorsSecteur1HorsOptam: "16.08",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PBLB002",
    designation: "Synoviorthèse chimique d'un membre",
    tarifSecteur1Optam: "51.59",
    tarifHorsSecteur1HorsOptam: "37.99",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PAGA002",
    designation:
      "Ablation de greffon ou de matériau inerte non prothétique de la diaphyse d'os long",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "108.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PZMP001",
    designation:
      "Modification d'un appareillage de contention ou d'une orthèse des membres ou de la colonne vertébrale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PZMP002",
    designation: "Adaptation d'une orthèse modulaire articulée d'un membre",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCLB001",
    designation:
      "Infiltration de zone déclic [point gachette] par voie transcutanée, pour syndrome myofascial",
    tarifSecteur1Optam: "22.11",
    tarifHorsSecteur1HorsOptam: "16.86",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCLB002",
    designation:
      "Séance d'injection de toxine botulique dans les muscles striés par voie transcutanée, sans examen électromyographique de détection",
    tarifSecteur1Optam: "93.31",
    tarifHorsSecteur1HorsOptam: "58.15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCLB003",
    designation:
      "Séance d'injection de toxine botulique dans les muscles striés par voie transcutanée, avec examen électromyographique de détection",
    tarifSecteur1Optam: "116.64",
    tarifHorsSecteur1HorsOptam: "91.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCCA001",
    designation: "Suture de rupture de muscle",
    tarifSecteur1Optam: "134",
    tarifHorsSecteur1HorsOptam: "112.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCCA002",
    designation: "Suture de plaie ou de rupture de tendon",
    tarifSecteur1Optam: "133.29",
    tarifHorsSecteur1HorsOptam: "103.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCMA001",
    designation:
      "Plastie d'allongement ou de raccourcissement de tendon par dédoublement ou retournement, par abord direct",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCEA003",
    designation:
      "Transfert musculotendineux pédiculé unipolaire pour réanimation motrice, par abord direct",
    tarifSecteur1Optam: "269.42",
    tarifHorsSecteur1HorsOptam: "244.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCEA001",
    designation:
      "Transfert musculotendineux pédiculé bipolaire pour réanimation motrice, par abord direct",
    tarifSecteur1Optam: "317.63",
    tarifHorsSecteur1HorsOptam: "272.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCEA004",
    designation:
      "Lambeau libre musculotendineux pour réanimation motrice, avec anastomoses vasculaires et nerveuse",
    tarifSecteur1Optam: "1027.34",
    tarifHorsSecteur1HorsOptam: "850.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCPA003",
    designation: "Désinsertion musculotendineuse",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCPA001",
    designation:
      "Libération neuro-musculo-cutanée d'un membre pour avancement musculotendineux",
    tarifSecteur1Optam: "385.7",
    tarifHorsSecteur1HorsOptam: "341.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCPB002",
    designation: "Ténotomie, par voie transcutanée",
    tarifSecteur1Optam: "87.92",
    tarifHorsSecteur1HorsOptam: "64.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCPA005",
    designation: "Ténotomie, par abord direct",
    tarifSecteur1Optam: "109.9",
    tarifHorsSecteur1HorsOptam: "76.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCPB001",
    designation:
      "Fasciomyotomie pour décompression de loge d'un membre, par voie transcutanée",
    tarifSecteur1Optam: "123.37",
    tarifHorsSecteur1HorsOptam: "115.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PCPA004",
    designation:
      "Fasciomyotomie pour décompression de loge d'un membre, par abord direct",
    tarifSecteur1Optam: "128.33",
    tarifHorsSecteur1HorsOptam: "118.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCPA002",
    designation: "Aponévrotomie intramusculaire pour allongement",
    tarifSecteur1Optam: "143.93",
    tarifHorsSecteur1HorsOptam: "127.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCEA002",
    designation: "Réinsertion transosseuse de tendon, par abord direct",
    tarifSecteur1Optam: "169.45",
    tarifHorsSecteur1HorsOptam: "137.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCDA001",
    designation: "Ténodèse, par abord direct",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PCPA006",
    designation:
      "Section ou plastie d'agrandissement de poulie de réflexion de tendon, par abord direct",
    tarifSecteur1Optam: "125.49",
    tarifHorsSecteur1HorsOptam: "125.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PDAB001",
    designation:
      "Allongement progressif des tissus mous des membres par pose de fixateur externe, par voie transcutanée",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "PZMA002",
    designation:
      "Réfection d'un moignon d'amputation de membre avec régularisation osseuse",
    tarifSecteur1Optam: "294.94",
    tarifHorsSecteur1HorsOptam: "241.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PZMA001",
    designation:
      "Réfection d'un moignon d'amputation de membre avec section, résection ou libération de nerf",
    tarifSecteur1Optam: "294.24",
    tarifHorsSecteur1HorsOptam: "240.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PZMA003",
    designation:
      "Réfection d'un moignon d'amputation de membre avec régularisation osseuse et section, résection ou libération de nerf",
    tarifSecteur1Optam: "363.72",
    tarifHorsSecteur1HorsOptam: "281.18",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZQM001",
    designation: "Échographie de la peau, des ongles et/ou des tissus mous",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "PDQK001",
    designation:
      "Quantification des différents composants des tissus mous, par scanographie",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "PDQN001",
    designation:
      "Quantification des différents composants des tissus mous, par remnographie",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QZQP002",
    designation:
      "Exploration photobiologique de base par recherche de la dose érythémale minimum [DEM] et phototest itératif",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZRP001",
    designation: "Photoépidermotest par batterie de tests",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZHA001",
    designation: "Biopsie dermoépidermique, par abord direct",
    tarifSecteur1Optam: "20.56",
    tarifHorsSecteur1HorsOptam: "19.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZHA005",
    designation: "Biopsie des tissus souscutanés susfasciaux, par abord direct",
    tarifSecteur1Optam: "25.52",
    tarifHorsSecteur1HorsOptam: "20.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZHA003",
    designation: "Biopsie de la tablette, du repli et/ou du lit de l'ongle",
    tarifSecteur1Optam: "43.43",
    tarifHorsSecteur1HorsOptam: "34.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZHA002",
    designation: "Biopsie de la matrice unguéale au bistouri circulaire",
    tarifSecteur1Optam: "46.99",
    tarifHorsSecteur1HorsOptam: "36.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZHA004",
    designation: "Biopsie latérolongitudinale de l'appareil unguéal",
    tarifSecteur1Optam: "72.62",
    tarifHorsSecteur1HorsOptam: "51.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZQX107",
    designation:
      "Examen cytopathologique de l'étalement de produit de brossage, de grattage ou d'écouvillonnage de la peau ou de muqueuse",
    tarifSecteur1Optam: "28",
    tarifHorsSecteur1HorsOptam: "28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQX021",
    designation:
      "Examen histopathologique de biopsie de peau avec coloration spéciale pour diagnostic d'affection non carcinologique",
    tarifSecteur1Optam: "40",
    tarifHorsSecteur1HorsOptam: "40",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQX014",
    designation:
      "Examen histopathologique de biopsie d'ongle avec coloration spéciale",
    tarifSecteur1Optam: "30",
    tarifHorsSecteur1HorsOptam: "30",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique d'une pièce d'exérèse de peau et/ou de tissu mou susfascial [susaponévrotique] de moins de 5 cm² ",
    tarifSecteur1Optam: "47.55",
    tarifHorsSecteur1HorsOptam: "47.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de peau et/ou de tissu mou susfascial [susaponévrotique] de 5 cm² ou plus",
    tarifSecteur1Optam: "64.57",
    tarifHorsSecteur1HorsOptam: "64.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQX032",
    designation:
      "Examen anatomopathologique à visée carcinologique de plusieurs pièces d'exérèse de peau et/ou de tissu mou susfascial [susaponévrotique] de moins de 5 cm² ",
    tarifSecteur1Optam: "85.5",
    tarifHorsSecteur1HorsOptam: "85.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX784",
    designation:
      "Test de détection des mutations génétiques de tumeur maligne de la peau pour thérapie ciblée [Test compagnon]",
    tarifSecteur1Optam: "203.13",
    tarifHorsSecteur1HorsOptam: "203.13",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQP001",
    designation:
      "Dermatoscopie [dermoscopie] pour surveillance de lésion à haut risque",
    tarifSecteur1Optam: "54.1",
    tarifHorsSecteur1HorsOptam: "54.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QAQP001",
    designation: "Trichogramme",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZQA001",
    designation:
      "Exploration de la peau et des tissus mous sans dissection d'un gros tronc vasculaire ou nerveux, par abord direct",
    tarifSecteur1Optam: "35.45",
    tarifHorsSecteur1HorsOptam: "29.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEQM001",
    designation: "Échographie unilatérale ou bilatérale du sein",
    tarifSecteur1Optam: "41.58",
    tarifHorsSecteur1HorsOptam: "41.58",
    regroupement: "ADE",
  },
  {
    codeCCAM: "QELJ001",
    designation:
      "Pose de repère dans le sein, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQK005",
    designation: "Mammographie unilatérale",
    tarifSecteur1Optam: "37.26",
    tarifHorsSecteur1HorsOptam: "37.26",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQK001",
    designation: "Mammographie bilatérale",
    tarifSecteur1Optam: "66.42",
    tarifHorsSecteur1HorsOptam: "66.42",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQK004",
    designation: "Mammographie de dépistage",
    tarifSecteur1Optam: "66.42",
    tarifHorsSecteur1HorsOptam: "66.42",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQH204",
    designation: "Angiomammographie spectrale unilatérale ou bilatérale",
    tarifSecteur1Optam: "112.62",
    tarifHorsSecteur1HorsOptam: "112.62",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QELH001",
    designation:
      "Pose de repère dans le sein, par voie transcutanée avec guidage mammographique",
    tarifSecteur1Optam: "123.52",
    tarifHorsSecteur1HorsOptam: "82.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQH001",
    designation: "Galactographie",
    tarifSecteur1Optam: "30.59",
    tarifHorsSecteur1HorsOptam: "30.59",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQK003",
    designation:
      "Radiographie de contrôle de pièce opératoire d'exérèse mammaire",
    tarifSecteur1Optam: "18.62",
    tarifHorsSecteur1HorsOptam: "18.62",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQK006",
    designation:
      "Scanographie du sein, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQH002",
    designation:
      "Scanographie du sein, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQN001",
    designation:
      "Remnographie [IRM] du sein, sans injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEQJ001",
    designation:
      "Remnographie [IRM] du sein, avec injection intraveineuse de produit de contraste",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "QEHB001",
    designation:
      "Ponction ou cytoponction de lésion de la glande mammaire, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "21.45",
    tarifHorsSecteur1HorsOptam: "20.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHJ003",
    designation:
      "Ponction ou cytoponction d'une lésion de la glande mammaire, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "26.1",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHJ002",
    designation:
      "Ponction ou cytoponction de plusieurs lésions de la glande mammaire, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "47.15",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHH003",
    designation:
      "Ponction ou cytoponction de lésion de la glande mammaire, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "87.19",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHB002",
    designation:
      "Biopsie de lésion de la glande mammaire, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHJ001",
    designation:
      "Biopsie d'une lésion de la glande mammaire, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHJ005",
    designation:
      "Biopsie de plusieurs lésions de la glande mammaire, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHH001",
    designation:
      "Biopsie de lésion de la glande mammaire, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "107.78",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHJ006",
    designation:
      "Biopsie de lésion de la glande mammaire, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHJ004",
    designation:
      "Macrobiopsie sous vide de lésion de la glande mammaire, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "423.66",
    tarifHorsSecteur1HorsOptam: "423.66",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHH002",
    designation:
      "Macrobiopsie sous vide de lésion de la glande mammaire sur table dédiée, par voie transcutanée avec guidage mammographique",
    tarifSecteur1Optam: "511.68",
    tarifHorsSecteur1HorsOptam: "511.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHH015",
    designation:
      "Macrobiopsie sous vide de lésion de la glande mammaire sur système accessoire, par voie transcutanée avec guidage mammographique",
    tarifSecteur1Optam: "400",
    tarifHorsSecteur1HorsOptam: "400",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHA002",
    designation: "Biopsie de lésion de la glande mammaire, par abord direct",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEHA001",
    designation: "Biopsie de la plaque aréolomamelonnaire",
    tarifSecteur1Optam: "29.76",
    tarifHorsSecteur1HorsOptam: "25.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEQX016",
    designation: "Examen histopathologique d'une macrobiopsie de sein",
    tarifSecteur1Optam: "96.73",
    tarifHorsSecteur1HorsOptam: "96.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX037",
    designation: "Examen histopathologique de plusieurs macrobiopsies de sein",
    tarifSecteur1Optam: "96.73",
    tarifHorsSecteur1HorsOptam: "96.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX004",
    designation:
      "Examen anatomopathologique à visée carcinologique d'une pièce de mastectomie partielle ou totale avec lésion unifocale ",
    tarifSecteur1Optam: "108.18",
    tarifHorsSecteur1HorsOptam: "108.18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX005",
    designation:
      "Examen anatomopathologique à visée carcinologique de plusieurs pièces de mastectomie partielle et/ou totale avec lésion unifocale sur chaque pièce d'exérèse",
    tarifSecteur1Optam: "125.21",
    tarifHorsSecteur1HorsOptam: "125.21",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX008",
    designation:
      "Examen anatomopathologique à visée carcinologique d'une pièce de mastectomie partielle ou totale avec lésion infraclinique ou lésion plurifocale",
    tarifSecteur1Optam: "149.94",
    tarifHorsSecteur1HorsOptam: "149.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX007",
    designation:
      "Examen anatomopathologique à visée carcinologique de plusieurs pièces de mastectomie partielle et/ou totale avec lésion infraclinique ou lésion plurifocale sur chaque pièce d'exérèse",
    tarifSecteur1Optam: "164.9",
    tarifHorsSecteur1HorsOptam: "164.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX006",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse d'un conduit lactifère [pyramidectomie]",
    tarifSecteur1Optam: "101.07",
    tarifHorsSecteur1HorsOptam: "101.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEQX010",
    designation:
      "Examen anatomopathologique à visée carcinologique de pièce d'exérèse de plusieurs conduits lactifères [pyramidectomies]",
    tarifSecteur1Optam: "116.19",
    tarifHorsSecteur1HorsOptam: "116.19",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEHP002",
    designation: "Frottis unilatéral ou bilatéral d'un écoulement mamelonnaire",
    tarifSecteur1Optam: "15.92",
    tarifHorsSecteur1HorsOptam: "13.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZJB002",
    designation:
      "Évacuation de collection superficielle et/ou profonde de la peau et des tissus mous, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "25.52",
    tarifHorsSecteur1HorsOptam: "23.59",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZJA009",
    designation:
      "Évacuation de collection superficielle de la peau, par abord direct",
    tarifSecteur1Optam: "38.29",
    tarifHorsSecteur1HorsOptam: "26.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA011",
    designation:
      "Évacuation de collection profonde de la peau et des tissus mous, par abord direct",
    tarifSecteur1Optam: "47.5",
    tarifHorsSecteur1HorsOptam: "45.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA003",
    designation:
      "Évacuation d'un phlegmon cervicofacial et médiastinal diffus, par cervicotomie",
    tarifSecteur1Optam: "183.63",
    tarifHorsSecteur1HorsOptam: "124.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAPA002",
    designation: "Mise à plat de lésion infectieuse du cuir chevelu",
    tarifSecteur1Optam: "109.9",
    tarifHorsSecteur1HorsOptam: "70.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBPA001",
    designation: "Mise à plat d'un sinus pilonidal périnéofessier infecté",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA038",
    designation:
      "Excision de lésion infectieuse diffuse de la peau et des tissus mous sur moins de 50 cm²",
    tarifSecteur1Optam: "65.23",
    tarifHorsSecteur1HorsOptam: "55.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA023",
    designation:
      "Excision de lésion infectieuse diffuse de la peau et des tissus mous sur 50 cm² à 200 cm²",
    tarifSecteur1Optam: "112.02",
    tarifHorsSecteur1HorsOptam: "82.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA027",
    designation:
      "Excision de lésion infectieuse diffuse de la peau et des tissus mous sur plus de 200 cm²",
    tarifSecteur1Optam: "123.37",
    tarifHorsSecteur1HorsOptam: "89.11",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA007",
    designation: "Excision d'un sinus pilonidal périnéofessier",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA004",
    designation:
      "Excision d'une hidrosadénite suppurative périnéofessière [maladie de Verneuil] sur moins de 30 cm²",
    tarifSecteur1Optam: "130.46",
    tarifHorsSecteur1HorsOptam: "93.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA002",
    designation:
      "Excision d'une hidrosadénite suppurative périnéofessière [maladie de Verneuil] sur plus de 30 cm²",
    tarifSecteur1Optam: "198.52",
    tarifHorsSecteur1HorsOptam: "132.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA002",
    designation:
      "Parage et/ou suture de plaie superficielle de la peau de moins de 3 cm de grand axe, en dehors de la face",
    tarifSecteur1Optam: "25.52",
    tarifHorsSecteur1HorsOptam: "23.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA017",
    designation:
      "Parage et/ou suture de plaie superficielle de la peau de 3 cm à 10 cm de grand axe, en dehors de la face",
    tarifSecteur1Optam: "51.76",
    tarifHorsSecteur1HorsOptam: "38.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA015",
    designation:
      "Parage et/ou suture de plaie superficielle de la peau de plus de 10 cm de grand axe, en dehors de la face",
    tarifSecteur1Optam: "61.68",
    tarifHorsSecteur1HorsOptam: "44.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA013",
    designation:
      "Parage et/ou suture de plaie superficielle de la peau de la face de moins de 3 cm de grand axe",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA005",
    designation:
      "Parage et/ou suture de plaie superficielle de la peau de la face de 3 cm à 10 cm de grand axe",
    tarifSecteur1Optam: "74.45",
    tarifHorsSecteur1HorsOptam: "56.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA002",
    designation:
      "Parage et/ou suture de plaie superficielle de la peau de la face de plus de 10 cm de grand axe",
    tarifSecteur1Optam: "82.95",
    tarifHorsSecteur1HorsOptam: "61.28",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA016",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de moins de 3 cm de grand axe, en dehors de la face et de la main",
    tarifSecteur1Optam: "48.92",
    tarifHorsSecteur1HorsOptam: "45.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA012",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de 3 cm à 10 cm de grand axe, en dehors de la face et de la main",
    tarifSecteur1Optam: "61.68",
    tarifHorsSecteur1HorsOptam: "53.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA001",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de plus de 10 cm de grand axe, en dehors de la face et de la main",
    tarifSecteur1Optam: "85.08",
    tarifHorsSecteur1HorsOptam: "66.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA004",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de la face de moins de 3 cm de grand axe",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA006",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de la face de 3 cm à 10 cm de grand axe",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "81.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA012",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de la face de plus de 10 cm de grand axe",
    tarifSecteur1Optam: "120.53",
    tarifHorsSecteur1HorsOptam: "96.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA009",
    designation: "Parage initial d'un délabrement des tissus mous de la face",
    tarifSecteur1Optam: "214.12",
    tarifHorsSecteur1HorsOptam: "150.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCJA001",
    designation:
      "Parage et/ou suture de plaie profonde de la peau et des tissus mous de la main",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA013",
    designation:
      "Parage secondaire de lésion traumatique ou infectieuse de la peau et des tissus mous, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "60.27",
    tarifHorsSecteur1HorsOptam: "52.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA004",
    designation:
      "Ablation d'un corps étranger superficiel de la peau, en dehors du visage et des mains",
    tarifSecteur1Optam: "38.29",
    tarifHorsSecteur1HorsOptam: "30.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAGA003",
    designation:
      "Ablation d'un corps étranger superficiel de la peau du visage ou des mains",
    tarifSecteur1Optam: "47.5",
    tarifHorsSecteur1HorsOptam: "40.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA007",
    designation:
      "Ablation de plusieurs corps étrangers superficiels de la peau, en dehors du visage et des mains",
    tarifSecteur1Optam: "67.36",
    tarifHorsSecteur1HorsOptam: "52.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAGA002",
    designation:
      "Ablation de plusieurs corps étrangers superficiels de la peau du visage et/ou des mains",
    tarifSecteur1Optam: "75.86",
    tarifHorsSecteur1HorsOptam: "63.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA003",
    designation:
      "Ablation d'un corps étranger profond des tissus mous, en dehors du visage et des mains",
    tarifSecteur1Optam: "60.27",
    tarifHorsSecteur1HorsOptam: "52.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAGA004",
    designation:
      "Ablation d'un corps étranger profond des tissus mous du visage ou des mains",
    tarifSecteur1Optam: "80.83",
    tarifHorsSecteur1HorsOptam: "64.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA006",
    designation:
      "Ablation de plusieurs corps étrangers profonds de la peau et des tissus mous, en dehors du visage et des mains",
    tarifSecteur1Optam: "84.37",
    tarifHorsSecteur1HorsOptam: "75.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAGA001",
    designation:
      "Ablation de plusieurs corps étrangers profonds de la peau et des tissus mous du visage et/ou des mains",
    tarifSecteur1Optam: "107.06",
    tarifHorsSecteur1HorsOptam: "88.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJP001",
    designation:
      "Séance de détersion mécanique d'ulcération trophique de la peau et des tissus mous sur moins de 30 cm², sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "26.23",
    tarifHorsSecteur1HorsOptam: "20.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJP003",
    designation:
      "Séance de détersion mécanique d'ulcération trophique de la peau et des tissus mous sur 30 cm² à 200 cm², sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "36.16",
    tarifHorsSecteur1HorsOptam: "25.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJP002",
    designation:
      "Séance de détersion mécanique d'ulcération trophique de la peau et des tissus mous sur plus de 200 cm², sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "47.5",
    tarifHorsSecteur1HorsOptam: "32.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZNP006",
    designation: "Dermabrasion en dehors du visage",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP006",
    designation: "Dermabrasion du visage sur moins de 5 cm²",
    tarifSecteur1Optam: "32.75",
    tarifHorsSecteur1HorsOptam: "31.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP008",
    designation: "Dermabrasion du visage sur 5 cm² à 20 cm²",
    tarifSecteur1Optam: "54.11",
    tarifHorsSecteur1HorsOptam: "43.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QANP009",
    designation: "Dermabrasion du visage entier",
    tarifSecteur1Optam: "180.14",
    tarifHorsSecteur1HorsOptam: "157.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZNP013",
    designation:
      "Séance de destruction de 1 à 5 lésions cutanées superficielles, par électrocoagulation",
    tarifSecteur1Optam: "24.21",
    tarifHorsSecteur1HorsOptam: "22.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP025",
    designation:
      "Séance de destruction de 6 lésions cutanées superficielles ou plus, par électrocoagulation",
    tarifSecteur1Optam: "32.75",
    tarifHorsSecteur1HorsOptam: "27.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP004",
    designation:
      "Séance de destruction de 1 à 10 lésions cutanées superficielles par agent chimique ou par cryothérapie de contact, en dehors du visage",
    tarifSecteur1Optam: "22.78",
    tarifHorsSecteur1HorsOptam: "21.28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP012",
    designation:
      "Séance de destruction de 11 à 50 lésions cutanées superficielles par agent chimique ou par cryothérapie de contact, en dehors du visage",
    tarifSecteur1Optam: "29.9",
    tarifHorsSecteur1HorsOptam: "25.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP005",
    designation:
      "Séance de destruction de 51 lésions cutanées superficielles ou plus, par agent chimique ou par cryothérapie de contact, en dehors du visage",
    tarifSecteur1Optam: "38.45",
    tarifHorsSecteur1HorsOptam: "30.36",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP007",
    designation:
      "Séance de destruction de 1 à 10 lésions cutanées superficielles du visage, par agent chimique ou par cryothérapie de contact",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP005",
    designation:
      "Séance de destruction de 11 lésions cutanées superficielles du visage ou plus, par agent chimique ou par cryothérapie de contact",
    tarifSecteur1Optam: "34.89",
    tarifHorsSecteur1HorsOptam: "32.33",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP022",
    designation:
      "Destruction d'une lésion cutanée, par cryode de contact [cryochirurgie]",
    tarifSecteur1Optam: "39.87",
    tarifHorsSecteur1HorsOptam: "31.19",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP014",
    designation:
      "Destruction de plusieurs lésions cutanées, par cryode de contact [cryochirurgie]",
    tarifSecteur1Optam: "57.67",
    tarifHorsSecteur1HorsOptam: "41.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP026",
    designation:
      "Destruction de moins de 10 lésions cutanées, avec laser CO2 continu",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP018",
    designation:
      "Destruction de 10 lésions cutanées ou plus, avec laser CO2 continu",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QCNP003",
    designation:
      "Destruction d'une verrue palmaire, plantaire ou périunguéale, avec laser CO2 continu",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QCNP002",
    designation:
      "Destruction de 2 à 5 verrues palmaires, plantaires et/ou périunguéales, avec laser CO2 continu",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QCNP001",
    designation:
      "Destruction de 6 verrues palmaires, plantaires et/ou périunguéales ou plus, avec laser CO2 continu",
    tarifSecteur1Optam: "48.42",
    tarifHorsSecteur1HorsOptam: "45.63",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP020",
    designation:
      "Destruction de lésion cutanée sur moins de 5 cm², avec laser CO2 impulsionnel ou scanérisé, ou avec laser erbium Yag",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP003",
    designation:
      "Destruction de lésion cutanée sur 5 cm² à 20 cm², avec laser CO2 impulsionnel ou scanérisé, ou avec laser erbium Yag",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP011",
    designation:
      "Destruction de lésion cutanée sur 20 cm² à 100 cm², avec laser CO2 impulsionnel ou scanérisé, ou avec laser erbium Yag",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP007",
    designation:
      "Destruction de lésion cutanée sur plus de 100 cm², avec laser CO2 impulsionnel",
    tarifSecteur1Optam: "72.26",
    tarifHorsSecteur1HorsOptam: "72.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP001",
    designation:
      "Abrasion du visage entier, avec laser CO2 impulsionnel ou scanérisé, ou avec laser erbium Yag",
    tarifSecteur1Optam: "180.85",
    tarifHorsSecteur1HorsOptam: "122.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP001",
    designation:
      "Séance de destruction de lésion cutanée sur moins de 30 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP024",
    designation:
      "Séance de destruction de lésion cutanée sur 30 cm² à 60 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "72.48",
    tarifHorsSecteur1HorsOptam: "72.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP021",
    designation:
      "Séance de destruction de lésion cutanée sur 60 cm² à 100 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "90.95",
    tarifHorsSecteur1HorsOptam: "90.95",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP002",
    designation:
      "Séance de destruction de lésion cutanée sur 100 cm² à 200 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "132.17",
    tarifHorsSecteur1HorsOptam: "132.17",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP019",
    designation:
      "Séance de destruction de lésion cutanée sur 200 cm² à 350 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "153.48",
    tarifHorsSecteur1HorsOptam: "153.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP009",
    designation:
      "Séance de destruction de lésion cutanée sur 350 cm² à 500 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "179.06",
    tarifHorsSecteur1HorsOptam: "179.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP015",
    designation:
      "Séance de destruction de lésion cutanée sur 500 cm² à 700 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "217.44",
    tarifHorsSecteur1HorsOptam: "217.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP017",
    designation:
      "Séance de destruction de lésion cutanée sur plus de 700 cm², avec laser vasculaire ou avec lampe flash",
    tarifSecteur1Optam: "288.5",
    tarifHorsSecteur1HorsOptam: "288.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP023",
    designation:
      "Séance de destruction de lésion cutanée pigmentaire sur moins de 30 cm², avec laser cristal commuté [Q switched] ou avec lampe flash",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP016",
    designation:
      "Séance de destruction de lésion cutanée pigmentaire sur 30 cm² à 60 cm², avec laser cristal commuté [Q switched] ou avec lampe flash",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP008",
    designation:
      "Séance de destruction de lésion cutanée pigmentaire sur 60 cm² à 100 cm², avec laser cristal commuté [Q switched] ou avec lampe flash",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP010",
    designation:
      "Séance de destruction de lésion cutanée pigmentaire sur plus de 100 cm², avec laser cristal commuté [Q switched] ou avec lampe flash",
    tarifSecteur1Optam: "162.24",
    tarifHorsSecteur1HorsOptam: "162.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP003",
    designation:
      "Exfoliation épidermique [Peeling] du visage entier, par agent chimique",
    tarifSecteur1Optam: "32.75",
    tarifHorsSecteur1HorsOptam: "31.09",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP002",
    designation:
      "Exfoliation dermoépidermique moyenne du visage entier, par agent chimique",
    tarifSecteur1Optam: "57.67",
    tarifHorsSecteur1HorsOptam: "45.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QANP004",
    designation:
      "Exfoliation dermoépidermique profonde du visage entier, par agent chimique",
    tarifSecteur1Optam: "86.15",
    tarifHorsSecteur1HorsOptam: "62.07",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP086",
    designation:
      "Destruction de lésion cutanée par photothérapie dynamique après application topique de produit photosensibilisant, sur moins de 10 cm²",
    tarifSecteur1Optam: "49.84",
    tarifHorsSecteur1HorsOptam: "49.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP259",
    designation:
      "Destruction de lésion cutanée par photothérapie dynamique après application topique de produit photosensibilisant, sur 10 cm² ou plus",
    tarifSecteur1Optam: "87.58",
    tarifHorsSecteur1HorsOptam: "87.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZFA028",
    designation: "Exérèse tangentielle de 1 à 5 lésions cutanées",
    tarifSecteur1Optam: "29.19",
    tarifHorsSecteur1HorsOptam: "25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA021",
    designation: "Exérèse tangentielle de 6 lésions cutanées ou plus",
    tarifSecteur1Optam: "34.89",
    tarifHorsSecteur1HorsOptam: "28.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA010",
    designation: "Exérèse tangentielle de 1 à 20 molluscum contagiosum",
    tarifSecteur1Optam: "23.5",
    tarifHorsSecteur1HorsOptam: "21.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA015",
    designation: "Exérèse tangentielle de 21 molluscum contagiosum ou plus",
    tarifSecteur1Optam: "36.31",
    tarifHorsSecteur1HorsOptam: "29.13",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA013",
    designation: "Exérèse de 1 à 5 lésions cutanées, par curetage",
    tarifSecteur1Optam: "32.75",
    tarifHorsSecteur1HorsOptam: "23.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA022",
    designation: "Exérèse de 6 lésions cutanées ou plus, par curetage",
    tarifSecteur1Optam: "42.72",
    tarifHorsSecteur1HorsOptam: "28.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA036",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de moins de 5 cm²",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA031",
    designation:
      "Exérèse de lésion superficielle de la peau par excision de 2 à 5 zones cutanées de moins de 5 cm²",
    tarifSecteur1Optam: "42.72",
    tarifHorsSecteur1HorsOptam: "36.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA003",
    designation:
      "Exérèse de lésion superficielle de la peau par excision de 6 zones cutanées ou plus de moins de 5 cm²",
    tarifSecteur1Optam: "53.18",
    tarifHorsSecteur1HorsOptam: "42.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA004",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de 5 cm² à 10 cm²",
    tarifSecteur1Optam: "53.18",
    tarifHorsSecteur1HorsOptam: "42.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA011",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de 10 cm² à 50 cm²",
    tarifSecteur1Optam: "95.72",
    tarifHorsSecteur1HorsOptam: "67.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA035",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de 50 cm² à 200 cm²",
    tarifSecteur1Optam: "95.72",
    tarifHorsSecteur1HorsOptam: "67.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA030",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de plus de 200 cm²",
    tarifSecteur1Optam: "148.89",
    tarifHorsSecteur1HorsOptam: "98.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA008",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de moins de 5 cm², par technique chimiochirurgicale [technique de Mohs]",
    tarifSecteur1Optam: "90.04",
    tarifHorsSecteur1HorsOptam: "74.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA033",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de 5 cm² à 10 cm², par technique chimiochirurgicale [technique de Mohs]",
    tarifSecteur1Optam: "135.42",
    tarifHorsSecteur1HorsOptam: "104.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA026",
    designation:
      "Exérèse de lésion superficielle de la peau par excision d'une zone cutanée de plus de 10 cm², par technique chimiochirurgicale [technique de Mohs]",
    tarifSecteur1Optam: "252.4",
    tarifHorsSecteur1HorsOptam: "190.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA002",
    designation:
      "Exérèse d'une lésion souscutanée susfasciale de moins de 3 cm de grand axe",
    tarifSecteur1Optam: "54.11",
    tarifHorsSecteur1HorsOptam: "39.45",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA001",
    designation:
      "Exérèse de 2 à 5 lésions souscutanées susfasciales de moins de 3 cm de grand axe",
    tarifSecteur1Optam: "70.9",
    tarifHorsSecteur1HorsOptam: "53.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA005",
    designation:
      "Exérèse de 6 lésions souscutanées susfasciales ou plus de moins de 3 cm de grand axe",
    tarifSecteur1Optam: "88.63",
    tarifHorsSecteur1HorsOptam: "63.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA007",
    designation:
      "Exérèse de lésion souscutanée susfasciale de 3 cm à 10 cm de grand axe",
    tarifSecteur1Optam: "79.41",
    tarifHorsSecteur1HorsOptam: "58.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA024",
    designation:
      "Exérèse de lésion souscutanée susfasciale de plus de 10 cm de grand axe",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "67.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PDFA001",
    designation:
      "Exérèse de lésion fasciale et/ou sousfasciale des tissus mous, sans dissection d'un gros tronc vasculaire ou nerveux",
    tarifSecteur1Optam: "122.66",
    tarifHorsSecteur1HorsOptam: "97.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PDFA002",
    designation:
      "Exérèse de lésion fasciale et/ou sousfasciale des tissus mous, avec dissection de gros tronc vasculaire et/ou nerveux",
    tarifSecteur1Optam: "193.56",
    tarifHorsSecteur1HorsOptam: "164.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PDFA003",
    designation:
      "Exérèse de lésion fasciale et/ou sousfasciale des tissus mous de la racine d'un membre, du pli du coude ou du creux poplité",
    tarifSecteur1Optam: "127.62",
    tarifHorsSecteur1HorsOptam: "86.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "MJFA008",
    designation:
      "Exérèse de lésion fasciale et/ou sousfasciale des tissus mous de la paume de la main",
    tarifSecteur1Optam: "87.92",
    tarifHorsSecteur1HorsOptam: "69.14",
    regroupement: "ADC",
  },
  {
    codeCCAM: "NJFA002",
    designation:
      "Exérèse de lésion fasciale et/ou sousfasciale des tissus mous de la plante du pied",
    tarifSecteur1Optam: "87.92",
    tarifHorsSecteur1HorsOptam: "63.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA011",
    designation: "Exérèse d'une lipomatose cervicale [de Launois-Bensaude]",
    tarifSecteur1Optam: "415.03",
    tarifHorsSecteur1HorsOptam: "319.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA020",
    designation:
      "Autogreffe de peau mince ou demiépaisse, pleine ou en filet, sur une surface inférieure à 50 cm²",
    tarifSecteur1Optam: "107.77",
    tarifHorsSecteur1HorsOptam: "88.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA039",
    designation:
      "Autogreffe de peau mince ou demiépaisse, pleine ou en filet, sur une surface de 50 cm² à 200 cm²",
    tarifSecteur1Optam: "143.93",
    tarifHorsSecteur1HorsOptam: "140.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA027",
    designation:
      "Autogreffe de peau mince ou demiépaisse, pleine ou en filet, sur une surface de 200 cm² à 500 cm²",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "175.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA005",
    designation:
      "Autogreffe de peau mince ou demiépaisse, pleine ou en filet, sur une surface de 500 cm² à 1000 cm²",
    tarifSecteur1Optam: "242.48",
    tarifHorsSecteur1HorsOptam: "241.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA036",
    designation:
      "Autogreffe de peau mince ou demiépaisse, pleine ou en filet, sur une surface supérieure à 1000 cm²",
    tarifSecteur1Optam: "310.54",
    tarifHorsSecteur1HorsOptam: "294.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA031",
    designation:
      "Autogreffe de peau totale sur une localisation de surface inférieure à 10 cm²",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA024",
    designation:
      "Autogreffe de peau totale sur une localisation de surface égale ou supérieure à 10 cm²",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA006",
    designation: "Autogreffe de peau totale sur plusieurs localisations",
    tarifSecteur1Optam: "155.89",
    tarifHorsSecteur1HorsOptam: "155.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA015",
    designation: "Microgreffes de peau totale sur 1 à 5 cicatrices du visage",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA014",
    designation:
      "Microgreffes de peau totale sur 6 cicatrices du visage, ou plus",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA032",
    designation:
      "Autogreffe de peau en pastilles sur une surface inférieure à 10 cm²",
    tarifSecteur1Optam: "56.01",
    tarifHorsSecteur1HorsOptam: "45.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA019",
    designation:
      "Autogreffe de peau en pastilles sur une surface de 10 cm² à 50 cm²",
    tarifSecteur1Optam: "76.57",
    tarifHorsSecteur1HorsOptam: "70.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA026",
    designation:
      "Autogreffe de peau en pastilles sur une surface supérieure à 50 cm²",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZLB002",
    designation:
      "Séance d'injection intradermique ou hypodermique de produit de comblement de dépression cutanée",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZLB001",
    designation: "Injection souscutanée susfasciale de tissu adipeux",
    tarifSecteur1Optam: "250.8",
    tarifHorsSecteur1HorsOptam: "250.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZEA045",
    designation:
      "Autogreffe souscutanée susfasciale de tissu celluloadipeux pour comblement de dépression cutanée, par abord direct",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA044",
    designation:
      "Autogreffe souscutanée susfasciale de peau désépidermisée pour comblement de dépression cutanée, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA901",
    designation: "Autogreffe de mélanocytes de transfert",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA900",
    designation: "Autogreffe de mélanocytes de culture",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA001",
    designation:
      '"Réparation de perte de substance par lambeau local ou régional muqueux, cutané ou fasciocutané, à pédicule vasculonerveux non individualisé ou non individualisable [lambeau ""au hasard""], en dehors de l\'extrémité céphalique"',
    tarifSecteur1Optam: "175.12",
    tarifHorsSecteur1HorsOptam: "155.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA004",
    designation:
      "Réparation de perte de substance par lambeau local ou régional cutané, fascial, fasciocutané, septocutané, musculaire ou musculocutané, à pédicule vasculaire ou vasculonerveux anatomique, en dehors de l'extrémité céphalique",
    tarifSecteur1Optam: "462.98",
    tarifHorsSecteur1HorsOptam: "356.3",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA005",
    designation:
      "Réparation de perte de substance par lambeau local ou régional cutané, fasciocutané ou ostéocutané, à pédicule vasculaire ou vasculonerveux anatomique, disséqué en îlot",
    tarifSecteur1Optam: "460.85",
    tarifHorsSecteur1HorsOptam: "355.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA007",
    designation:
      "Réparation de perte de substance par lambeau local ou régional musculaire ou musculocutané, à pédicule vasculaire ou vasculonerveux anatomique, disséqué en îlot",
    tarifSecteur1Optam: "575",
    tarifHorsSecteur1HorsOptam: "465.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA003",
    designation:
      "Réparation de perte de substance par lambeau à distance cutané, fasciocutané ou musculocutané, à pédicule transitoire",
    tarifSecteur1Optam: "362.3",
    tarifHorsSecteur1HorsOptam: "297.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA009",
    designation:
      "Réparation de perte de substance par lambeau à distance cutané, fasciocutané, musculaire, musculocutané ou ostéo-musculo-cutané, à pédicule définitif",
    tarifSecteur1Optam: "267.29",
    tarifHorsSecteur1HorsOptam: "242.81",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPMA003",
    designation:
      "Réparation de perte de substance par lambeau pédiculé de grand omentum [épiploon], en situation extraabdominale",
    tarifSecteur1Optam: "606.9",
    tarifHorsSecteur1HorsOptam: "505.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA015",
    designation:
      "Réparation de perte de substance du cuir chevelu par lambeau pédiculé",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA002",
    designation:
      '"Réparation de perte de substance de l\'extrémité céphalique par lambeau local ou régional muqueux, cutané ou fasciocutané, à pédicule vasculonerveux non individualisé ou non individualisable [lambeau ""au hasard""]"',
    tarifSecteur1Optam: "191.24",
    tarifHorsSecteur1HorsOptam: "191.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA003",
    designation:
      "Réparation de perte de substance de l'extrémité céphalique par lambeau frontal à pédicule inférieur",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA008",
    designation:
      "Réparation de perte de substance de l'extrémité céphalique par lambeau scalpant",
    tarifSecteur1Optam: "382.15",
    tarifHorsSecteur1HorsOptam: "301.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA005",
    designation:
      "Réparation de perte de substance de l'extrémité céphalique par lambeau de fascia temporal",
    tarifSecteur1Optam: "257.37",
    tarifHorsSecteur1HorsOptam: "229.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA004",
    designation:
      "Réparation de perte de substance de l'extrémité céphalique par lambeau de fascia temporal avec autogreffe de peau",
    tarifSecteur1Optam: "298.49",
    tarifHorsSecteur1HorsOptam: "253.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA013",
    designation:
      "Réparation de perte de substance de l'extrémité céphalique par lambeau musculaire ou musculocutané",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA012",
    designation:
      "Réparation de perte de substance de l'extrémité céphalique par lambeau de muscle temporal avec autogreffe de peau",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PZMA004",
    designation:
      "Réparation par lambeau libre cutané, fascial, fasciocutané ou souscutané, musculaire, musculocutané, musculotendineux ou osseux avec anastomoses vasculaires",
    tarifSecteur1Optam: "874.2",
    tarifHorsSecteur1HorsOptam: "761.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PZMA005",
    designation:
      "Réparation par lambeau libre ostéocutané, ostéomusculaire ou ostéo-musculo-cutané, avec anastomoses vasculaires",
    tarifSecteur1Optam: "1028.76",
    tarifHorsSecteur1HorsOptam: "851.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPMA002",
    designation:
      "Réparation de perte de substance par lambeau libre de grand omentum [épiploon], avec anastomoses vasculaires",
    tarifSecteur1Optam: "874.2",
    tarifHorsSecteur1HorsOptam: "682.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA008",
    designation: "Autonomisation d'un lambeau",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA004",
    designation: "Section du pédicule d’un lambeau à distance",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA006",
    designation: "Modelage secondaire d'un lambeau",
    tarifSecteur1Optam: "116.99",
    tarifHorsSecteur1HorsOptam: "85.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA002",
    designation:
      "Révision d’un lambeau libre pour ischémie, sans réfection des anastomoses vasculaires",
    tarifSecteur1Optam: "173",
    tarifHorsSecteur1HorsOptam: "117.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZMA010",
    designation:
      "Réfection des anastomoses vasculaires d’un lambeau libre pour ischémie",
    tarifSecteur1Optam: "355.21",
    tarifHorsSecteur1HorsOptam: "293.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZLA001",
    designation:
      "Pose d'un dispositif d’expansion de la peau ou d'un implant souscutané définitif",
    tarifSecteur1Optam: "161.65",
    tarifHorsSecteur1HorsOptam: "146.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZLA002",
    designation:
      "Pose de plusieurs dispositifs d’expansion de la peau ou de plusieurs implants souscutanés définitifs",
    tarifSecteur1Optam: "209.16",
    tarifHorsSecteur1HorsOptam: "200.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA008",
    designation:
      "Ablation d’un dispositif d’expansion de la peau ou d'un implant souscutané définitif",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA001",
    designation:
      "Ablation de plusieurs dispositifs d’expansion de la peau ou de plusieurs implants souscutanés définitifs",
    tarifSecteur1Optam: "188.1",
    tarifHorsSecteur1HorsOptam: "188.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA014",
    designation: "Lissage [Lifting] temporal unilatéral, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA007",
    designation: "Lissage [Lifting] temporal bilatéral, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMC001",
    designation:
      "Lissage [Lifting] du tiers supérieur de la face, par vidéochirurgie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA016",
    designation:
      "Lissage [Lifting] du tiers supérieur de la face, par abord coronal",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA006",
    designation:
      "Lissage cervicofacial unilatéral [Hémilifting facial], par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA001",
    designation:
      "Lissage cervicofacial unilatéral [Hémilifting facial] avec platysmaplastie, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA010",
    designation: "Lissage [Lifting] cervicofacial bilatéral, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA009",
    designation:
      "Lissage [Lifting] cervicofacial bilatéral avec platysmaplastie, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAMA011",
    designation: "Lissage [Lifting] cervical, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA009",
    designation: "Dermolipectomie abdominale en quartier d'orange",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA011",
    designation: "Dermolipectomie abdominale sans transposition de l'ombilic",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA013",
    designation:
      "Dermolipectomie abdominale sans transposition de l'ombilic, avec lipoaspiration de l'abdomen",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA006",
    designation:
      "Dermolipectomie abdominale sans transposition de l'ombilic, avec fermeture de diastasis des muscles droits de l'abdomen",
    tarifSecteur1Optam: "305.58",
    tarifHorsSecteur1HorsOptam: "273.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA010",
    designation:
      "Dermolipectomie abdominale sans transposition de l'ombilic, avec lipoaspiration de l'abdomen et fermeture de diastasis des muscles droits de l'abdomen",
    tarifSecteur1Optam: "351.66",
    tarifHorsSecteur1HorsOptam: "300.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA005",
    designation: "Dermolipectomie abdominale avec transposition de l'ombilic",
    tarifSecteur1Optam: "299.2",
    tarifHorsSecteur1HorsOptam: "270.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA008",
    designation:
      "Dermolipectomie abdominale avec transposition de l'ombilic et lipoaspiration de l'abdomen",
    tarifSecteur1Optam: "333.94",
    tarifHorsSecteur1HorsOptam: "290.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA001",
    designation:
      "Dermolipectomie abdominale avec transposition de l'ombilic et fermeture de diastasis des muscles droits de l'abdomen",
    tarifSecteur1Optam: "346.7",
    tarifHorsSecteur1HorsOptam: "297.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA012",
    designation:
      "Dermolipectomie abdominale avec transposition de l'ombilic, lipoaspiration de l'abdomen et fermeture de diastasis des muscles droits de l'abdomen",
    tarifSecteur1Optam: "388.53",
    tarifHorsSecteur1HorsOptam: "321.91",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QBFA003",
    designation: "Dermolipectomie abdominale totale circulaire",
    tarifSecteur1Optam: "423.98",
    tarifHorsSecteur1HorsOptam: "342.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA014",
    designation: "Dermolipectomie des membres",
    tarifSecteur1Optam: "280.76",
    tarifHorsSecteur1HorsOptam: "233.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJB003",
    designation:
      "Lipoaspiration en dehors des régions inframentonnière, abdominale ou infratrochantérienne ou des genoux",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QAJB001",
    designation: "Lipoaspiration inframentonnière",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QBJB001",
    designation: "Lipoaspiration de l’abdomen",
    tarifSecteur1Optam: "181.5",
    tarifHorsSecteur1HorsOptam: "149.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QDJB001",
    designation: "Lipoaspiration des régions infratrochantériennes",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QDJB002",
    designation: "Lipoaspiration de la face médiale des genoux",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "LCFA003",
    designation:
      "Exérèse d'une fistule ou d'un kyste de la première fente branchiale, sans dissection du nerf facial",
    tarifSecteur1Optam: "209.16",
    tarifHorsSecteur1HorsOptam: "191.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA005",
    designation:
      "Exérèse d'une fistule ou d'un kyste de la première fente branchiale, avec dissection du nerf facial",
    tarifSecteur1Optam: "388.53",
    tarifHorsSecteur1HorsOptam: "317.52",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA008",
    designation:
      "Exérèse d'une fistule ou d'un kyste de la deuxième fente branchiale",
    tarifSecteur1Optam: "301.84",
    tarifHorsSecteur1HorsOptam: "245.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA006",
    designation:
      "Exérèse d'une fistule ou d'un kyste de la troisième ou quatrième fente branchiale",
    tarifSecteur1Optam: "301.07",
    tarifHorsSecteur1HorsOptam: "244.85",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BAFA019",
    designation: "Exérèse d'un kyste de la queue du sourcil",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HAFA011",
    designation: "Exérèse de kyste de lèvre",
    tarifSecteur1Optam: "37.58",
    tarifHorsSecteur1HorsOptam: "33.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA009",
    designation:
      "Exérèse d'une fistule congénitale de la columelle nasale ou de la lèvre supérieure [philtrum]",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GAFA011",
    designation:
      "Exérèse d'une fistule ou d'un kyste congénital du dos du nez, par abord facial",
    tarifSecteur1Optam: "177.25",
    tarifHorsSecteur1HorsOptam: "173.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA009",
    designation: "Exérèse de lésion congénitale du premier arc branchial",
    tarifSecteur1Optam: "167.2",
    tarifHorsSecteur1HorsOptam: "167.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA004",
    designation: "Exérèse d'un kyste congénital médian du cou",
    tarifSecteur1Optam: "215.6",
    tarifHorsSecteur1HorsOptam: "195.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "KCFA011",
    designation: "Exérèse d'un kyste du tractus thyréoglosse",
    tarifSecteur1Optam: "238.7",
    tarifHorsSecteur1HorsOptam: "208.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA010",
    designation: "Exérèse d'une fistule mentosternale",
    tarifSecteur1Optam: "187.89",
    tarifHorsSecteur1HorsOptam: "179.2",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAPA001",
    designation: "Section de bride congénitale médiane du cou, avec plastie",
    tarifSecteur1Optam: "217.66",
    tarifHorsSecteur1HorsOptam: "192.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA012",
    designation:
      "Exérèse d'une récidive d'une fistule ou d'un kyste congénital cervical médian ou latéral",
    tarifSecteur1Optam: "275.8",
    tarifHorsSecteur1HorsOptam: "230.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LCFA007",
    designation: "Exérèse d'une fistule cutanée faciale acquise",
    tarifSecteur1Optam: "24.11",
    tarifHorsSecteur1HorsOptam: "22.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA034",
    designation: "Relèvement de 1 à 5 cicatrices, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA009",
    designation: "Relèvement de 6 cicatrices ou plus, par abord direct",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZLA004",
    designation: "Pose d'implant pharmacologique souscutané",
    tarifSecteur1Optam: "17.99",
    tarifHorsSecteur1HorsOptam: "14.47",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA002",
    designation: "Ablation ou changement d'implant pharmacologique souscutané",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA023",
    designation:
      "Pansement de plaie de la peau et des tissus mous, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "25.52",
    tarifHorsSecteur1HorsOptam: "18.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZRP002",
    designation:
      "Séance de photothérapie de la main, du pied et/ou du cuir chevelu, par rayons ultraviolets A [UVA] ou ultraviolets B [UVB]",
    tarifSecteur1Optam: "14.24",
    tarifHorsSecteur1HorsOptam: "10.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZRP003",
    designation:
      "Séance de photothérapie corporelle totale, par rayons ultraviolets A [UVA] ou ultraviolets B [UVB]",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZRP005",
    designation: "Séance de balnéopuvathérapie localisée",
    tarifSecteur1Optam: "22.07",
    tarifHorsSecteur1HorsOptam: "20.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZRP004",
    designation: "Séance de balnéopuvathérapie généralisée",
    tarifSecteur1Optam: "22.07",
    tarifHorsSecteur1HorsOptam: "20.87",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZMP002",
    designation:
      "Confection d'un conformateur de pressothérapie d'une cicatrice vicieuse et/ou chéloïde",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZRB001",
    designation: "Séance d'acupuncture",
    tarifSecteur1Optam: "18",
    tarifHorsSecteur1HorsOptam: "18",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP027",
    designation: "Séance d'épilation cutanée électrique",
    tarifSecteur1Optam: "29.19",
    tarifHorsSecteur1HorsOptam: "23.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP029",
    designation:
      "Séance d'épilation cutanée sur moins de 50 cm², avec laser ou avec lampe flash",
    tarifSecteur1Optam: "17.8",
    tarifHorsSecteur1HorsOptam: "16.78",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP028",
    designation:
      "Séance d'épilation cutanée sur 50 cm² à 150 cm², avec laser ou avec lampe flash",
    tarifSecteur1Optam: "29.19",
    tarifHorsSecteur1HorsOptam: "23.38",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNP030",
    designation:
      "Séance d'épilation cutanée sur plus de 150 cm², avec laser ou avec lampe flash",
    tarifSecteur1Optam: "43.43",
    tarifHorsSecteur1HorsOptam: "31.64",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QAEA001",
    designation: "Transplantation de moins de 50 greffons de cuir chevelu",
    tarifSecteur1Optam: "80.83",
    tarifHorsSecteur1HorsOptam: "77.12",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA005",
    designation: "Transplantation de 50 à 200 greffons de cuir chevelu",
    tarifSecteur1Optam: "108.48",
    tarifHorsSecteur1HorsOptam: "93.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA007",
    designation: "Transplantation de 201 greffons de cuir chevelu, ou plus",
    tarifSecteur1Optam: "151.02",
    tarifHorsSecteur1HorsOptam: "136.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA013",
    designation: "Réimplantation de cuir chevelu avec anastomoses vasculaires",
    tarifSecteur1Optam: "474.04",
    tarifHorsSecteur1HorsOptam: "474.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJB001",
    designation:
      "Évacuation d'un hématome infra-unguéal, par voie transunguéale",
    tarifSecteur1Optam: "11.34",
    tarifHorsSecteur1HorsOptam: "10.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZMP001",
    designation:
      "Couverture du lit de l'ongle par reposition de la tablette ou pose de prothèse provisoire",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZJA022",
    designation: "Parage et/ou suture d'une plaie pulpo-unguéale",
    tarifSecteur1Optam: "80.83",
    tarifHorsSecteur1HorsOptam: "64.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA021",
    designation: "Parage et/ou suture de plusieurs plaies pulpo-unguéales",
    tarifSecteur1Optam: "141.8",
    tarifHorsSecteur1HorsOptam: "104.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA008",
    designation:
      "Autogreffe du lit de l'ongle avec reposition de la tablette unguéale ou pose de prothèse",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "81.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA020",
    designation: "Exérèse partielle ou totale de la tablette d'un ongle",
    tarifSecteur1Optam: "46.79",
    tarifHorsSecteur1HorsOptam: "35.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA039",
    designation: "Exérèse partielle de l'appareil unguéal",
    tarifSecteur1Optam: "35.45",
    tarifHorsSecteur1HorsOptam: "29.34",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA029",
    designation: "Exérèse totale de l'appareil unguéal",
    tarifSecteur1Optam: "51.76",
    tarifHorsSecteur1HorsOptam: "38.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA032",
    designation:
      "Exérèse de l'appareil unguéal, avec réparation par autogreffe de peau totale",
    tarifSecteur1Optam: "82.24",
    tarifHorsSecteur1HorsOptam: "74.04",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA009",
    designation:
      "Exérèse de l'appareil unguéal, avec réparation par lambeau pédiculé unguéomatriciel",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "81.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA028",
    designation:
      "Transfert libre de complexe unguéomatriciel avec anastomoses vasculaires",
    tarifSecteur1Optam: "951.48",
    tarifHorsSecteur1HorsOptam: "806.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJB004",
    designation:
      "Évacuation de kystes acnéiques et/ou de comédons, par micro-incisions",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZLP001",
    designation: "Séance d'ionophorèse [iontophorèse] pour hyperhidrose",
    tarifSecteur1Optam: "10.68",
    tarifHorsSecteur1HorsOptam: "8.61",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QCLB222",
    designation:
      "Injection unilatérale ou bilatérale de toxine botulique au niveau du creux axillaire",
    tarifSecteur1Optam: "32.04",
    tarifHorsSecteur1HorsOptam: "32.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZJA020",
    designation:
      "Pansement chirurgical initial de brûlure en dehors de l'extrémité céphalique et des mains, sur moins de 1% de la surface corporelle",
    tarifSecteur1Optam: "43.25",
    tarifHorsSecteur1HorsOptam: "32.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA005",
    designation:
      "Pansement chirurgical initial de brûlure en dehors de l'extrémité céphalique et des mains, sur 1% à 10% de la surface corporelle",
    tarifSecteur1Optam: "73.74",
    tarifHorsSecteur1HorsOptam: "49.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA004",
    designation:
      "Pansement chirurgical initial de brûlure en dehors de l'extrémité céphalique et des mains, sur 10% à 30% de la surface corporelle",
    tarifSecteur1Optam: "163.07",
    tarifHorsSecteur1HorsOptam: "116.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA019",
    designation:
      "Pansement chirurgical initial de brûlure en dehors de l'extrémité céphalique et des mains, sur 30% à 60% de la surface corporelle",
    tarifSecteur1Optam: "238.22",
    tarifHorsSecteur1HorsOptam: "190.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA014",
    designation:
      "Pansement chirurgical initial de brûlure en dehors de l'extrémité céphalique et des mains, sur plus de 60% de la surface corporelle",
    tarifSecteur1Optam: "357.34",
    tarifHorsSecteur1HorsOptam: "259.92",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA014",
    designation:
      "Pansement chirurgical initial de brûlure sur l'extrémité céphalique",
    tarifSecteur1Optam: "116.28",
    tarifHorsSecteur1HorsOptam: "77.97",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCJA003",
    designation: "Pansement chirurgical initial de brûlure sur 1 main",
    tarifSecteur1Optam: "96.42",
    tarifHorsSecteur1HorsOptam: "66.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCJA005",
    designation: "Pansement chirurgical initial de brûlure sur les 2 mains",
    tarifSecteur1Optam: "147.47",
    tarifHorsSecteur1HorsOptam: "101.33",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA008",
    designation:
      "Pansement chirurgical initial de brûlure sur l'extrémité céphalique et 1 main",
    tarifSecteur1Optam: "170.16",
    tarifHorsSecteur1HorsOptam: "114.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA007",
    designation:
      "Pansement chirurgical initial de brûlure sur l'extrémité céphalique et les 2 mains",
    tarifSecteur1Optam: "221.21",
    tarifHorsSecteur1HorsOptam: "144.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA003",
    designation:
      "Pansement chirurgical secondaire de brûlure en dehors de l'extrémité céphalique et des mains, sur moins de 1% de la surface corporelle",
    tarifSecteur1Optam: "40.41",
    tarifHorsSecteur1HorsOptam: "36.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA010",
    designation:
      "Pansement chirurgical secondaire de brûlure en dehors de l'extrémité céphalique et des mains, sur 1% à 10% de la surface corporelle",
    tarifSecteur1Optam: "75.86",
    tarifHorsSecteur1HorsOptam: "57.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA007",
    designation:
      "Pansement chirurgical secondaire de brûlure en dehors de l'extrémité céphalique et des mains, sur 10% à 30% de la surface corporelle",
    tarifSecteur1Optam: "158.11",
    tarifHorsSecteur1HorsOptam: "126.82",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA008",
    designation:
      "Pansement chirurgical secondaire de brûlure en dehors de l'extrémité céphalique et des mains, sur 30% à 60% de la surface corporelle",
    tarifSecteur1Optam: "221.92",
    tarifHorsSecteur1HorsOptam: "181.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZJA018",
    designation:
      "Pansement chirurgical secondaire de brûlure en dehors de l'extrémité céphalique et des mains, sur plus de 60% de la surface corporelle",
    tarifSecteur1Optam: "333.23",
    tarifHorsSecteur1HorsOptam: "245.94",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA010",
    designation:
      "Pansement chirurgical secondaire de brûlure sur l'extrémité céphalique",
    tarifSecteur1Optam: "107.77",
    tarifHorsSecteur1HorsOptam: "82.26",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCJA004",
    designation: "Pansement chirurgical secondaire de brûlure sur 1 main",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "74.86",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCJA002",
    designation: "Pansement chirurgical secondaire de brûlure sur les 2 mains",
    tarifSecteur1Optam: "151.02",
    tarifHorsSecteur1HorsOptam: "116.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA011",
    designation:
      "Pansement chirurgical secondaire de brûlure sur l'extrémité céphalique et 1 main",
    tarifSecteur1Optam: "170.87",
    tarifHorsSecteur1HorsOptam: "127.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAJA001",
    designation:
      "Pansement chirurgical secondaire de brûlure sur l'extrémité céphalique et les 2 mains",
    tarifSecteur1Optam: "226.88",
    tarifHorsSecteur1HorsOptam: "160.41",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA002",
    designation: "Escarrotomie de décharge sur 1 site",
    tarifSecteur1Optam: "119.11",
    tarifHorsSecteur1HorsOptam: "86.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA001",
    designation: "Escarrotomie de décharge sur 2 sites",
    tarifSecteur1Optam: "246.02",
    tarifHorsSecteur1HorsOptam: "169.03",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA005",
    designation: "Escarrotomie de décharge sur 3 sites",
    tarifSecteur1Optam: "319.76",
    tarifHorsSecteur1HorsOptam: "211.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA007",
    designation: "Escarrotomie de décharge sur 4 sites",
    tarifSecteur1Optam: "443.83",
    tarifHorsSecteur1HorsOptam: "283.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA006",
    designation: "Escarrotomie de décharge sur 5 sites",
    tarifSecteur1Optam: "555.86",
    tarifHorsSecteur1HorsOptam: "348.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZPA003",
    designation: "Escarrotomie de décharge sur 6 sites",
    tarifSecteur1Optam: "652.28",
    tarifHorsSecteur1HorsOptam: "404.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA019",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur moins de 2,5% de la surface corporelle",
    tarifSecteur1Optam: "94.3",
    tarifHorsSecteur1HorsOptam: "61.71",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA006",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 2,5% à 5% de la surface corporelle",
    tarifSecteur1Optam: "131.17",
    tarifHorsSecteur1HorsOptam: "83.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA017",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 5% à 7,5% de la surface corporelle",
    tarifSecteur1Optam: "185.76",
    tarifHorsSecteur1HorsOptam: "120.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA016",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 7,5% à 10% de la surface corporelle",
    tarifSecteur1Optam: "241.77",
    tarifHorsSecteur1HorsOptam: "153.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA012",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 10% à 12,5% de la surface corporelle",
    tarifSecteur1Optam: "297.78",
    tarifHorsSecteur1HorsOptam: "207.83",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA018",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 12,5% à 15% de la surface corporelle",
    tarifSecteur1Optam: "357.34",
    tarifHorsSecteur1HorsOptam: "242.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA034",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 15% à 17,5% de la surface corporelle",
    tarifSecteur1Optam: "394.2",
    tarifHorsSecteur1HorsOptam: "263.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA025",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur 17,5% à 20% de la surface corporelle",
    tarifSecteur1Optam: "487.08",
    tarifHorsSecteur1HorsOptam: "317.62",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZFA037",
    designation:
      "Excision de brûlure en dehors de l'extrémité céphalique et des mains, sur plus de 20% de la surface corporelle",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "398.09",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA006",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur moins de 1% de la surface corporelle",
    tarifSecteur1Optam: "143.22",
    tarifHorsSecteur1HorsOptam: "93.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA002",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 1% à 2% de la surface corporelle",
    tarifSecteur1Optam: "186.47",
    tarifHorsSecteur1HorsOptam: "118.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA004",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 2% à 3% de la surface corporelle",
    tarifSecteur1Optam: "227.59",
    tarifHorsSecteur1HorsOptam: "142.54",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA008",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 3% à 4% de la surface corporelle",
    tarifSecteur1Optam: "273.67",
    tarifHorsSecteur1HorsOptam: "169.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA005",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 4% à 5% de la surface corporelle",
    tarifSecteur1Optam: "338.9",
    tarifHorsSecteur1HorsOptam: "207.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA003",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 5% à 7,5% de la surface corporelle",
    tarifSecteur1Optam: "393.5",
    tarifHorsSecteur1HorsOptam: "247.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA007",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 7,5% à 10% de la surface corporelle",
    tarifSecteur1Optam: "441",
    tarifHorsSecteur1HorsOptam: "275.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA010",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur 10% à 12,5% de la surface corporelle",
    tarifSecteur1Optam: "488.5",
    tarifHorsSecteur1HorsOptam: "336",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA009",
    designation:
      "Excision de brûlure de l'extrémité céphalique, sur plus de 12,5% de la surface corporelle",
    tarifSecteur1Optam: "374.24",
    tarifHorsSecteur1HorsOptam: "374.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCFA002",
    designation:
      "Excision de brûlure d'une main, sur moins de 1,25% de la surface corporelle",
    tarifSecteur1Optam: "142.51",
    tarifHorsSecteur1HorsOptam: "93.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCFA003",
    designation:
      "Excision de brûlure d'une main, sur plus de 1,25% de la surface corporelle",
    tarifSecteur1Optam: "194.27",
    tarifHorsSecteur1HorsOptam: "132.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCFA001",
    designation:
      "Excision de brûlure des 2 mains, sur moins de 1,25% de la surface corporelle",
    tarifSecteur1Optam: "156.69",
    tarifHorsSecteur1HorsOptam: "106.68",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCFA006",
    designation:
      "Excision de brûlure des 2 mains, sur 1,25% à 2,5% de la surface corporelle",
    tarifSecteur1Optam: "213.41",
    tarifHorsSecteur1HorsOptam: "139.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCFA004",
    designation:
      "Excision de brûlure des 2 mains, sur 2,5% à 3,75% de la surface corporelle",
    tarifSecteur1Optam: "250.99",
    tarifHorsSecteur1HorsOptam: "161.37",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCFA005",
    designation:
      "Excision de brûlure des 2 mains, sur plus de 3,75% de la surface corporelle",
    tarifSecteur1Optam: "191.23",
    tarifHorsSecteur1HorsOptam: "191.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA021",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur moins de 2,5% de la surface corporelle",
    tarifSecteur1Optam: "95.01",
    tarifHorsSecteur1HorsOptam: "68.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA037",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 2,5% à 5% de la surface corporelle",
    tarifSecteur1Optam: "119.11",
    tarifHorsSecteur1HorsOptam: "82.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA041",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 5% à 7,5% de la surface corporelle",
    tarifSecteur1Optam: "146.76",
    tarifHorsSecteur1HorsOptam: "98.29",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA017",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 7,5% à 10% de la surface corporelle",
    tarifSecteur1Optam: "198.52",
    tarifHorsSecteur1HorsOptam: "128.31",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA011",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 10% à 12,5% de la surface corporelle",
    tarifSecteur1Optam: "259.49",
    tarifHorsSecteur1HorsOptam: "176.84",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA012",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 12,5% à 15% de la surface corporelle",
    tarifSecteur1Optam: "193.7",
    tarifHorsSecteur1HorsOptam: "193.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA002",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 15% à 17,5% de la surface corporelle",
    tarifSecteur1Optam: "355.21",
    tarifHorsSecteur1HorsOptam: "232.36",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA025",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur 17,5% à 20% de la surface corporelle",
    tarifSecteur1Optam: "445.25",
    tarifHorsSecteur1HorsOptam: "284.58",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA010",
    designation:
      "Greffe cutanée pour brûlure en dehors de l'extrémité céphalique et des mains, sur plus de 20% de la surface corporelle",
    tarifSecteur1Optam: "337.22",
    tarifHorsSecteur1HorsOptam: "337.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA002",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur moins de 1% de la surface corporelle",
    tarifSecteur1Optam: "158.82",
    tarifHorsSecteur1HorsOptam: "111.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA010",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 1% à 2% de la surface corporelle",
    tarifSecteur1Optam: "131.61",
    tarifHorsSecteur1HorsOptam: "131.61",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA011",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 2% à 3% de la surface corporelle",
    tarifSecteur1Optam: "161.63",
    tarifHorsSecteur1HorsOptam: "161.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA006",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 3% à 4% de la surface corporelle",
    tarifSecteur1Optam: "297.78",
    tarifHorsSecteur1HorsOptam: "192.46",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA008",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 4% à 5% de la surface corporelle",
    tarifSecteur1Optam: "229.06",
    tarifHorsSecteur1HorsOptam: "229.06",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA009",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 5% à 7,5% de la surface corporelle",
    tarifSecteur1Optam: "264.43",
    tarifHorsSecteur1HorsOptam: "264.43",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA012",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 7,5% à 10% de la surface corporelle",
    tarifSecteur1Optam: "443.83",
    tarifHorsSecteur1HorsOptam: "277.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA004",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur 10% à 12,5% de la surface corporelle",
    tarifSecteur1Optam: "523.95",
    tarifHorsSecteur1HorsOptam: "330.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAEA003",
    designation:
      "Greffe cutanée pour brûlure de l'extrémité céphalique, sur plus de 12,5% de la surface corporelle",
    tarifSecteur1Optam: "595.56",
    tarifHorsSecteur1HorsOptam: "371.76",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCEA005",
    designation:
      "Greffe cutanée pour brûlure d'une main, sur moins de 1,25% de la surface corporelle",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "115.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCEA006",
    designation:
      "Greffe cutanée pour brûlure d'une main, sur plus de 1,25% de la surface corporelle",
    tarifSecteur1Optam: "224.04",
    tarifHorsSecteur1HorsOptam: "149.69",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCEA002",
    designation:
      "Greffe cutanée pour brûlure des deux mains, sur moins de 1,25% de la surface corporelle",
    tarifSecteur1Optam: "133.66",
    tarifHorsSecteur1HorsOptam: "133.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCEA004",
    designation:
      "Greffe cutanée pour brûlure des deux mains, sur 1,25% à 2,5% de la surface corporelle",
    tarifSecteur1Optam: "247.44",
    tarifHorsSecteur1HorsOptam: "173.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCEA001",
    designation:
      "Greffe cutanée pour brûlure des deux mains, sur 2,5% à 3,75% de la surface corporelle",
    tarifSecteur1Optam: "302.74",
    tarifHorsSecteur1HorsOptam: "205.22",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QCEA003",
    designation:
      "Greffe cutanée pour brûlure des deux mains, sur plus de 3,75% de la surface corporelle",
    tarifSecteur1Optam: "236.88",
    tarifHorsSecteur1HorsOptam: "236.88",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA038",
    designation:
      "Greffe cutanée en sandwich, sur moins de 2,5% de la surface corporelle",
    tarifSecteur1Optam: "176.79",
    tarifHorsSecteur1HorsOptam: "176.79",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA029",
    designation:
      "Greffe cutanée en sandwich, sur 2,5% à 5% de la surface corporelle",
    tarifSecteur1Optam: "216.25",
    tarifHorsSecteur1HorsOptam: "199.67",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA004",
    designation:
      "Greffe cutanée en sandwich, sur 5% à 7,5% de la surface corporelle",
    tarifSecteur1Optam: "260.39",
    tarifHorsSecteur1HorsOptam: "260.39",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA013",
    designation:
      "Greffe cutanée en sandwich, sur 7,5% à 10% de la surface corporelle",
    tarifSecteur1Optam: "304.16",
    tarifHorsSecteur1HorsOptam: "285.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA043",
    designation:
      "Greffe cutanée en sandwich, sur 10% à 12,5% de la surface corporelle",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA014",
    designation:
      "Greffe cutanée en sandwich, sur 12,5% à 15% de la surface corporelle",
    tarifSecteur1Optam: "374.48",
    tarifHorsSecteur1HorsOptam: "374.48",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA040",
    designation:
      "Greffe cutanée en sandwich, sur 15% à 17,5% de la surface corporelle",
    tarifSecteur1Optam: "426.63",
    tarifHorsSecteur1HorsOptam: "426.63",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA030",
    designation:
      "Greffe cutanée en sandwich, sur 17,5% à 20% de la surface corporelle",
    tarifSecteur1Optam: "555.15",
    tarifHorsSecteur1HorsOptam: "479.27",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA001",
    designation:
      "Greffe cutanée en sandwich, sur plus de 20% de la surface corporelle",
    tarifSecteur1Optam: "631.01",
    tarifHorsSecteur1HorsOptam: "558.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA023",
    designation:
      "Greffe de culture de kératinocytes autologues pour brûlure, sur moins de 10% de la surface corporelle",
    tarifSecteur1Optam: "307.16",
    tarifHorsSecteur1HorsOptam: "307.16",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA042",
    designation:
      "Greffe de culture de kératinocytes autologues pour brûlure, sur 10% à 20% de la surface corporelle",
    tarifSecteur1Optam: "517.1",
    tarifHorsSecteur1HorsOptam: "517.1",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA022",
    designation:
      "Greffe de culture de kératinocytes autologues pour brûlure, sur 20% à 30% de la surface corporelle",
    tarifSecteur1Optam: "668.99",
    tarifHorsSecteur1HorsOptam: "668.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA003",
    designation:
      "Greffe de culture de kératinocytes autologues pour brûlure, sur 30% à 40% de la surface corporelle",
    tarifSecteur1Optam: "933.75",
    tarifHorsSecteur1HorsOptam: "751.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA016",
    designation:
      "Greffe de culture de kératinocytes autologues pour brûlure, sur 40% à 50% de la surface corporelle",
    tarifSecteur1Optam: "955.78",
    tarifHorsSecteur1HorsOptam: "955.78",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZEA033",
    designation:
      "Greffe de culture de kératinocytes autologues pour brûlure, sur plus de 50% de la surface corporelle",
    tarifSecteur1Optam: "1061.75",
    tarifHorsSecteur1HorsOptam: "1061.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGP004",
    designation:
      "Ablation des supports de culture de kératinocytes autologues pour brûlure, sur moins de 10% de la surface corporelle",
    tarifSecteur1Optam: "161.65",
    tarifHorsSecteur1HorsOptam: "106.93",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGP009",
    designation:
      "Ablation des supports de culture de kératinocytes autologues pour brûlure, sur 10% à 20% de la surface corporelle",
    tarifSecteur1Optam: "177.4",
    tarifHorsSecteur1HorsOptam: "177.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGP001",
    designation:
      "Ablation des supports de culture de kératinocytes autologues pour brûlure, sur 20% à 30% de la surface corporelle",
    tarifSecteur1Optam: "340.32",
    tarifHorsSecteur1HorsOptam: "250.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGP002",
    designation:
      "Ablation des supports de culture de kératinocytes autologues pour brûlure, sur 30% à 40% de la surface corporelle",
    tarifSecteur1Optam: "448.09",
    tarifHorsSecteur1HorsOptam: "338.9",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGP006",
    designation:
      "Ablation des supports de culture de kératinocytes autologues pour brûlure, sur 40% à 50% de la surface corporelle",
    tarifSecteur1Optam: "407.98",
    tarifHorsSecteur1HorsOptam: "407.98",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGP007",
    designation:
      "Ablation des supports de culture de kératinocytes autologues pour brûlure, sur plus de 50% de la surface corporelle",
    tarifSecteur1Optam: "483.64",
    tarifHorsSecteur1HorsOptam: "483.64",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEJB001",
    designation:
      "Évacuation de collection de la glande mammaire, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "23.53",
    tarifHorsSecteur1HorsOptam: "16.06",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEJH001",
    designation:
      "Évacuation de collection de la glande mammaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEJH002",
    designation:
      "Drainage de collection de la glande mammaire, par voie transcutanée avec guidage échographique et/ou radiologique",
    tarifSecteur1Optam: "95.67",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEJA001",
    designation:
      "Évacuation de collection de la glande mammaire, par abord direct",
    tarifSecteur1Optam: "60.9",
    tarifHorsSecteur1HorsOptam: "52.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA004",
    designation: "Tumorectomie du sein",
    tarifSecteur1Optam: "109.9",
    tarifHorsSecteur1HorsOptam: "103.24",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA001",
    designation: "Tumorectomie du sein avec curage lymphonodal axillaire",
    tarifSecteur1Optam: "282.18",
    tarifHorsSecteur1HorsOptam: "264.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA017",
    designation: "Mastectomie partielle",
    tarifSecteur1Optam: "145.35",
    tarifHorsSecteur1HorsOptam: "128.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA008",
    designation: "Mastectomie partielle avec curage lymphonodal axillaire",
    tarifSecteur1Optam: "302.03",
    tarifHorsSecteur1HorsOptam: "275.77",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA016",
    designation:
      "Exérèse de conduit lactifère [Exérèse de canal galactophore] [Pyramidectomie mammaire]",
    tarifSecteur1Optam: "151.73",
    tarifHorsSecteur1HorsOptam: "131.89",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA007",
    designation:
      "Mastectomie souscutanée avec exérèse de la plaque aréolomamelonnaire",
    tarifSecteur1Optam: "231.84",
    tarifHorsSecteur1HorsOptam: "222.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA006",
    designation:
      "Exérèse de tissu mammaire ectopique ou de glande mammaire aberrante [sein surnuméraire]",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA011",
    designation: "Exérèse unilatérale de gynécomastie",
    tarifSecteur1Optam: "128.33",
    tarifHorsSecteur1HorsOptam: "118.32",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA002",
    designation: "Exérèse bilatérale de gynécomastie",
    tarifSecteur1Optam: "200.65",
    tarifHorsSecteur1HorsOptam: "182.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA019",
    designation: "Mastectomie totale",
    tarifSecteur1Optam: "190.72",
    tarifHorsSecteur1HorsOptam: "154.51",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA020",
    designation:
      "Mastectomie totale avec conservation des pectoraux et curage lymphonodal axillaire",
    tarifSecteur1Optam: "350.25",
    tarifHorsSecteur1HorsOptam: "303.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA005",
    designation:
      "Mastectomie totale avec exérèse des pectoraux et curage lymphonodal axillaire",
    tarifSecteur1Optam: "282.89",
    tarifHorsSecteur1HorsOptam: "264.66",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA010",
    designation:
      "Mastectomie totale avec curages lymphonodaux axillaire et supraclaviculaire",
    tarifSecteur1Optam: "423.27",
    tarifHorsSecteur1HorsOptam: "372.42",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA003",
    designation:
      "Mastectomie totale avec curages lymphonodaux axillaire et parasternal [mammaire interne]",
    tarifSecteur1Optam: "517.57",
    tarifHorsSecteur1HorsOptam: "453.44",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA012",
    designation:
      "Mastectomie totale élargie en surface, avec autogreffe cutanée",
    tarifSecteur1Optam: "343.99",
    tarifHorsSecteur1HorsOptam: "343.99",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA013",
    designation:
      "Mastectomie totale élargie en surface, avec lambeau pédiculé de muscle grand dorsal ou lambeau parascapulaire",
    tarifSecteur1Optam: "589.89",
    tarifHorsSecteur1HorsOptam: "530.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA015",
    designation:
      "Mastectomie totale élargie en surface, avec lambeau libre musculocutané",
    tarifSecteur1Optam: "951.48",
    tarifHorsSecteur1HorsOptam: "740.23",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA012",
    designation: "Mastoplastie unilatérale de réduction",
    tarifSecteur1Optam: "252.4",
    tarifHorsSecteur1HorsOptam: "234.17",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA005",
    designation:
      "Mastoplastie unilatérale de réduction, avec reconstruction de la plaque aréolomamelonnaire par lambeau local et autogreffe cutanée",
    tarifSecteur1Optam: "354.5",
    tarifHorsSecteur1HorsOptam: "319.72",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA013",
    designation: "Mastoplastie bilatérale de réduction",
    tarifSecteur1Optam: "408.38",
    tarifHorsSecteur1HorsOptam: "368.53",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA003",
    designation:
      "Mastoplastie unilatérale d'augmentation, avec pose d'implant prothétique",
    tarifSecteur1Optam: "175.83",
    tarifHorsSecteur1HorsOptam: "154.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA004",
    designation:
      "Mastoplastie bilatérale d'augmentation, avec pose d'implant prothétique",
    tarifSecteur1Optam: "303.45",
    tarifHorsSecteur1HorsOptam: "255.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEDA002",
    designation: "Mastopexie unilatérale, sans pose d'implant prothétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEDA001",
    designation: "Mastopexie unilatérale, avec pose d'implant prothétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEDA004",
    designation: "Mastopexie bilatérale, sans pose d'implant prothétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEDA003",
    designation: "Mastopexie bilatérale, avec pose d'implant prothétique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEEB317",
    designation:
      "Autogreffe de tissu adipeux de moins de 200 cm³ au niveau du sein",
    tarifSecteur1Optam: "128",
    tarifHorsSecteur1HorsOptam: "128",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEEB152",
    designation:
      "Autogreffe de tissus adipeux de 200 cm³ et plus au niveau du sein",
    tarifSecteur1Optam: "302",
    tarifHorsSecteur1HorsOptam: "302",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA006",
    designation: "Reconstruction du sein par pose d'implant prothétique",
    tarifSecteur1Optam: "296.36",
    tarifHorsSecteur1HorsOptam: "224.56",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"QEMA740',
  },
  {
    codeCCAM: '"',
    designation:
      "Reconstruction autologue du sein par lambeau musculocutané pédiculé du muscle grand dorsal autologue [LDA [Latissimus dorsi autologue]] sans pose d’implant prothétique",
    tarifSecteur1Optam: "664",
    tarifHorsSecteur1HorsOptam: "664",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"QEMA702',
  },
  {
    codeCCAM: '"',
    designation:
      " Reconstruction autologue du sein par lambeau musculocutané pédiculé de la partie antérieure de grand dorsal sans pose d’implant prothétique",
    tarifSecteur1Optam: "673",
    tarifHorsSecteur1HorsOptam: "673",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"QEMA392',
  },
  {
    codeCCAM: '"',
    designation:
      "Reconstruction du sein par lambeau musculocutané pédiculé du muscle grand dorsal [LD [Latissimus dorsi]] avec pose d’implant prothétique",
    tarifSecteur1Optam: "592",
    tarifHorsSecteur1HorsOptam: "592",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"QEMA463',
  },
  {
    codeCCAM: '"',
    designation:
      "Reconstruction du sein par lambeau cutanéograisseux pédiculé perforant du pédicule thoraco-dorsal sans pose d’implant prothétique [lambeau TDAP [ThoracoDorsal Artery Perforator]]",
    tarifSecteur1Optam: "709",
    tarifHorsSecteur1HorsOptam: "709",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA001",
    designation:
      "Reconstruction du sein par lambeau unipédiculé de muscle droit de l'abdomen",
    tarifSecteur1Optam: "668.59",
    tarifHorsSecteur1HorsOptam: "567.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA014",
    designation:
      "Reconstruction du sein par lambeau bipédiculé de muscle droit de l'abdomen",
    tarifSecteur1Optam: "668.59",
    tarifHorsSecteur1HorsOptam: "567.73",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA002",
    designation:
      "Reconstruction du sein par lambeau musculocutané libre de muscle droit de l'abdomen, avec anastomoses vasculaires",
    tarifSecteur1Optam: "932.34",
    tarifHorsSecteur1HorsOptam: "720.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEGA001",
    designation:
      "Ablation unilatérale d'implant prothétique mammaire, sans capsulectomie",
    tarifSecteur1Optam: "94.05",
    tarifHorsSecteur1HorsOptam: "94.05",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEGA003",
    designation:
      "Ablation unilatérale d'implant prothétique mammaire, avec capsulectomie",
    tarifSecteur1Optam: "183.63",
    tarifHorsSecteur1HorsOptam: "146.01",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEGA002",
    designation:
      "Ablation bilatérale d'implant prothétique mammaire, sans capsulectomie",
    tarifSecteur1Optam: "141.08",
    tarifHorsSecteur1HorsOptam: "141.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEGA004",
    designation:
      "Ablation bilatérale d'implant prothétique mammaire, avec capsulectomie",
    tarifSecteur1Optam: "249.57",
    tarifHorsSecteur1HorsOptam: "204",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEKA002",
    designation:
      "Changement d'implant prothétique mammaire, sans capsulectomie",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "148.07",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEKA001",
    designation:
      "Changement d'implant prothétique mammaire, avec capsulectomie",
    tarifSecteur1Optam: "228.3",
    tarifHorsSecteur1HorsOptam: "185.08",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEPA001",
    designation:
      "Capsulotomie mammaire avec changement de loge de l'implant prothétique",
    tarifSecteur1Optam: "190.01",
    tarifHorsSecteur1HorsOptam: "162.87",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEBA001",
    designation: "Réduction de volume du mamelon",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA009",
    designation: "Exérèse du mamelon",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA014",
    designation: "Exérèse de mamelon surnuméraire",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEEA002",
    designation: "Désinvagination unilatérale du mamelon ombiliqué",
    tarifSecteur1Optam: "89.33",
    tarifHorsSecteur1HorsOptam: "78.15",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEEA003",
    designation: "Désinvagination bilatérale du mamelon ombiliqué",
    tarifSecteur1Optam: "119.11",
    tarifHorsSecteur1HorsOptam: "108.59",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEEA001",
    designation: "Transposition du mamelon",
    tarifSecteur1Optam: "141.09",
    tarifHorsSecteur1HorsOptam: "134.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEFA018",
    designation: "Exérèse de la plaque aréolomamelonnaire",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA007",
    designation:
      "Plastie d'augmentation ou de réduction de la plaque aréolomamelonnaire",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMB001",
    designation:
      "Séance de réfection de l'aspect de la plaque aréolomamelonnaire par dermopigmentation",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QEMA009",
    designation:
      "Reconstruction de la plaque aréolomamelonnaire par autogreffe de mamelon, avec dermopigmentation",
    tarifSecteur1Optam: "135.42",
    tarifHorsSecteur1HorsOptam: "131.21",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QEMA010",
    designation:
      "Reconstruction de la plaque aréolomamelonnaire par autogreffe de mamelon ou lambeau local, et autogreffe cutanée",
    tarifSecteur1Optam: "190.19",
    tarifHorsSecteur1HorsOptam: "190.19",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZQM004",
    designation: "Échographie transcutanée, au lit du malade",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZZQM001",
    designation: "Échographie-doppler transcutanée, au lit du malade",
    tarifSecteur1Optam: "69.93",
    tarifHorsSecteur1HorsOptam: "69.93",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZZQH002",
    designation: "Radiographie d'une fistule [Fistulographie]",
    tarifSecteur1Optam: "59.85",
    tarifHorsSecteur1HorsOptam: "59.85",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQK002",
    designation: "Radiographie au lit du malade, selon 1 ou 2 incidences",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQK001",
    designation: "Radiographie au lit du malade, selon 3 incidences ou plus",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQH001",
    designation: "Scanographie d'une fistule",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQK024",
    designation:
      "Scanographie de 3 territoires anatomiques ou plus, sans injection de produit de contraste",
    tarifSecteur1Optam: "75.81",
    tarifHorsSecteur1HorsOptam: "75.81",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQH033",
    designation:
      "Scanographie de 3 territoires anatomiques ou plus, avec injection de produit de contraste",
    tarifSecteur1Optam: "75.81",
    tarifHorsSecteur1HorsOptam: "75.81",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQN001",
    designation: "Remnographie [IRM] comportant 6 séquences ou plus",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL016",
    designation:
      "Tomoscintigraphie du corps entier par émission de positons, avec tépographe [caméra TEP] dédié",
    tarifSecteur1Optam: "89.54",
    tarifHorsSecteur1HorsOptam: "89.54",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL004",
    designation:
      "Scintigraphie du corps entier après une scintigraphie segmentaire, sans réinjection de produit radio-isotopique",
    tarifSecteur1Optam: "176.85",
    tarifHorsSecteur1HorsOptam: "176.85",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL013",
    designation:
      "Détection radio-isotopique préopératoire de lésion par injection transcutanée intratumorale ou péritumorale, avec détection radio-isotopique peropératoire",
    tarifSecteur1Optam: "313.08",
    tarifHorsSecteur1HorsOptam: "313.08",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL005",
    designation:
      "Recherche scintigraphique de tumeur par émetteur monophotonique non spécifique des tumeurs",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL012",
    designation:
      "Recherche scintigraphique de tumeur par émetteur monophotonique spécifique des tumeurs",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL014",
    designation:
      "Recherche scintigraphique de tumeur par émetteur monophotonique spécifique des tumeurs, avec scintigraphie du corps entier",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL002",
    designation:
      "Recherche scintigraphique de tumeur par émetteur monophotonique spécifique des tumeurs, avec tomoscintigraphie complémentaire, scintigraphie du corps entier complémentaire d'une image segmentaire et scintigraphie du corps entier à 72 heures",
    tarifSecteur1Optam: "268.87",
    tarifHorsSecteur1HorsOptam: "268.87",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL020",
    designation:
      "Scintigraphie et/ou tomoscintigraphie de contrôle avec quantification, après administration d'agent pharmacologique radio-isotopique à visée thérapeutique",
    tarifSecteur1Optam: "265.28",
    tarifHorsSecteur1HorsOptam: "265.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZHB001",
    designation:
      "Ponction ou cytoponction de lésion superficielle, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ007",
    designation:
      "Ponction ou cytoponction d'un organe superficiel sur une cible, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "26.1",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ016",
    designation:
      "Ponction ou cytoponction d'organe superficiel sur plusieurs cibles, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "47.15",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ006",
    designation:
      "Ponction ou cytoponction d'un organe profond sur une cible, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "68.2",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH004",
    designation:
      "Ponction ou cytoponction d'un organe profond sur une cible, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "87.19",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH006",
    designation:
      "Ponction ou cytoponction d'un organe profond sur une cible, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "43.68",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ002",
    designation:
      "Ponction ou cytoponction d'un organe profond sur une cible, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ003",
    designation:
      "Ponction ou cytoponction d'un organe profond sur une cible, par voie intracavitaire avec guidage échographique",
    tarifSecteur1Optam: "75.78",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ021",
    designation:
      "Ponction ou cytoponction d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "86.73",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH011",
    designation:
      "Ponction ou cytoponction d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH009",
    designation:
      "Ponction ou cytoponction d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ018",
    designation:
      "Ponction ou cytoponction d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ020",
    designation:
      "Ponction ou cytoponction d'organe profond sur plusieurs cibles, par voie intracavitaire avec guidage échographique",
    tarifSecteur1Optam: "93.46",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHB002",
    designation:
      "Biopsie de lésion superficielle, par voie transcutanée sans guidage",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ001",
    designation:
      "Biopsie d'un organe superficiel sur une cible, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ022",
    designation:
      "Biopsie d'organe superficiel sur plusieurs cibles, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ013",
    designation:
      "Biopsie d'un organe profond sur une cible, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH001",
    designation:
      "Biopsie d'un organe profond sur une cible, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "104.15",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH008",
    designation:
      "Biopsie d'un organe profond sur une cible, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ009",
    designation:
      "Biopsie d'un organe profond sur une cible, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ019",
    designation:
      "Biopsie d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH012",
    designation:
      "Biopsie d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "136.84",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHH010",
    designation:
      "Biopsie d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ017",
    designation:
      "Biopsie d'organe profond sur plusieurs cibles, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "115.2",
    tarifHorsSecteur1HorsOptam: "115.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZHJ010",
    designation:
      "Biopsie d'un organe profond, par voie intracavitaire avec guidage échographique",
    tarifSecteur1Optam: "75.78",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX128",
    designation:
      "Examen cytopathologique de l'étalement d'un prélèvement ou de plusieurs prélèvements non différenciés de liquide de structure anatomique",
    tarifSecteur1Optam: "28",
    tarifHorsSecteur1HorsOptam: "28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX103",
    designation:
      "Examen cytopathologique de l'étalement de 2 prélèvements différenciés de liquide de structure anatomique",
    tarifSecteur1Optam: "46.42",
    tarifHorsSecteur1HorsOptam: "46.42",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX110",
    designation:
      "Examen cytopathologique de l'étalement de 3 prélèvements différenciés de liquide de structure anatomique",
    tarifSecteur1Optam: "78.25",
    tarifHorsSecteur1HorsOptam: "78.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX117",
    designation:
      "Examen cytopathologique de l'étalement de 4 prélèvements différenciés ou plus de liquide de structure anatomique",
    tarifSecteur1Optam: "75.79",
    tarifHorsSecteur1HorsOptam: "75.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX151",
    designation:
      "Examen cytopathologique de l'étalement d'un prélèvement ou de plusieurs prélèvements non différenciés de produit de ponction de structure anatomique ",
    tarifSecteur1Optam: "33.6",
    tarifHorsSecteur1HorsOptam: "33.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX139",
    designation:
      "Examen cytopathologique de l'étalement de 2 prélèvements différenciés de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "41.94",
    tarifHorsSecteur1HorsOptam: "41.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX141",
    designation:
      "Examen cytopathologique de l'étalement de 3 prélèvements différenciés de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "42.62",
    tarifHorsSecteur1HorsOptam: "42.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX133",
    designation:
      "Examen cytopathologique de l'étalement de 4 prélèvements différenciés ou plus de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "64.24",
    tarifHorsSecteur1HorsOptam: "64.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX116",
    designation:
      "Examen cytopathologique de culot cellulaire d'un prélèvement ou de plusieurs prélèvements non différenciés de liquide et/ou de produit de ponction de structure anatomique, avec inclusion en paraffine",
    tarifSecteur1Optam: "36.62",
    tarifHorsSecteur1HorsOptam: "36.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX023",
    designation:
      "Examen cytopathologique de culot cellulaire de 2 prélèvements différenciés de liquide et/ou de produit de ponction de structure anatomique, avec inclusion en paraffine",
    tarifSecteur1Optam: "36.62",
    tarifHorsSecteur1HorsOptam: "36.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX098",
    designation:
      "Examen cytopathologique de culot cellulaire de 3 prélèvements différenciés de liquide et/ou de produit de ponction de structure anatomique, avec inclusion en paraffine",
    tarifSecteur1Optam: "36.62",
    tarifHorsSecteur1HorsOptam: "36.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX054",
    designation:
      "Examen cytopathologique de culot cellulaire de 4 prélèvements différenciés de liquide et/ou de produit de ponction de structure anatomique, avec inclusion en paraffine",
    tarifSecteur1Optam: "36.62",
    tarifHorsSecteur1HorsOptam: "36.62",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX153",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] d'un prélèvement ou de prélèvements non différenciés de liquide, de structure anatomique",
    tarifSecteur1Optam: "28",
    tarifHorsSecteur1HorsOptam: "28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX145",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de 2 prélèvements différenciés de liquide, de structure anatomique",
    tarifSecteur1Optam: "42.57",
    tarifHorsSecteur1HorsOptam: "42.57",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX147",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de 3 prélèvements différenciés de liquide, de structure anatomique",
    tarifSecteur1Optam: "75.5",
    tarifHorsSecteur1HorsOptam: "75.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX155",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de 4 prélèvements différenciés ou plus de liquide, de structure anatomique",
    tarifSecteur1Optam: "104.7",
    tarifHorsSecteur1HorsOptam: "104.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX170",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] d'un prélèvement ou de plusieurs prélèvements non différenciés de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "33.6",
    tarifHorsSecteur1HorsOptam: "33.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX172",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de 2 prélèvements différenciés de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "47.46",
    tarifHorsSecteur1HorsOptam: "47.46",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX158",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de 3 prélèvements différenciés de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "62.74",
    tarifHorsSecteur1HorsOptam: "62.74",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX174",
    designation:
      "Examen cytopathologique en phase liquide [technique monocouche] de 4 prélèvements différenciés ou plus de produit de ponction de structure anatomique",
    tarifSecteur1Optam: "80.5",
    tarifHorsSecteur1HorsOptam: "80.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX162",
    designation:
      "Examen histopathologique de biopsie d'une structure anatomique ",
    tarifSecteur1Optam: "28",
    tarifHorsSecteur1HorsOptam: "28",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX163",
    designation:
      "Examen histopathologique de biopsie de 2 structures anatomiques",
    tarifSecteur1Optam: "54",
    tarifHorsSecteur1HorsOptam: "54",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX132",
    designation:
      "Examen histopathologique de biopsie de 3 structures anatomiques",
    tarifSecteur1Optam: "80",
    tarifHorsSecteur1HorsOptam: "80",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX197",
    designation:
      "Examen histopathologique de biopsie de 4 structures anatomiques",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX035",
    designation:
      "Examen histopathologique de biopsie de 5 structures anatomiques ou plus",
    tarifSecteur1Optam: "110",
    tarifHorsSecteur1HorsOptam: "110",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX077",
    designation:
      "Examen histopathologique de biopsies étagées d'une structure anatomique",
    tarifSecteur1Optam: "36.4",
    tarifHorsSecteur1HorsOptam: "36.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX200",
    designation:
      "Examen histopathologique de biopsies étagées de 2 structures anatomiques",
    tarifSecteur1Optam: "73.1",
    tarifHorsSecteur1HorsOptam: "73.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX068",
    designation:
      "Examen histopathologique de biopsies étagées de 3 structures anatomiques",
    tarifSecteur1Optam: "107.89",
    tarifHorsSecteur1HorsOptam: "107.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX047",
    designation:
      "Examen histopathologique de biopsies étagées de 4 structures anatomiques ou plus",
    tarifSecteur1Optam: "126.55",
    tarifHorsSecteur1HorsOptam: "126.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX217",
    designation:
      "Examen histopathologique de biopsies d'une structure anatomique avec cartographie",
    tarifSecteur1Optam: "84.69",
    tarifHorsSecteur1HorsOptam: "84.69",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX012",
    designation:
      "Examen histopathologique de biopsies de plusieurs structures anatomiques avec cartographie de chaque structure anatomique",
    tarifSecteur1Optam: "138.73",
    tarifHorsSecteur1HorsOptam: "138.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX149",
    designation:
      "Examen cytopathologique et/ou histopathologique extemporané d'un prélèvement de structure anatomique, hors du lieu du prélèvement",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX119",
    designation:
      "Examen cytopathologique et/ou histopathologique extemporané d'un prélèvement de structure anatomique, sur le lieu du prélèvement",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX175",
    designation:
      "Examen cytopathologique et/ou histopathologique extemporané de 2 à 4 prélèvements différenciés de structure anatomique, hors du lieu du prélèvement",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX118",
    designation:
      "Examen cytopathologique et/ou histopathologique extemporané de 2 à 4 prélèvements différenciés de structure anatomique, sur le lieu du prélèvement",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX146",
    designation:
      "Examen cytopathologique et/ou histopathologique extemporané de 5 prélèvements différenciés ou plus de structure anatomique, hors du lieu du prélèvement",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX104",
    designation:
      "Examen cytopathologique et/ou histopathologique extemporané de 5 prélèvements différenciés ou plus de structure anatomique, sur le lieu du prélèvement",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX123",
    designation:
      "Examen histopathologique de fragment d'exérèse endoscopique ou de curetage de structure anatomique, non différenciés par le préleveur",
    tarifSecteur1Optam: "33.6",
    tarifHorsSecteur1HorsOptam: "33.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX159",
    designation:
      "Examen histopathologique de fragments d'exérèse endoscopique ou de curetage d'une structure anatomique, différenciés par le préleveur",
    tarifSecteur1Optam: "33.6",
    tarifHorsSecteur1HorsOptam: "33.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX177",
    designation:
      "Examen histopathologique de fragments d'exérèse endoscopique ou de curetage de plusieurs structures anatomiques, différenciés par le préleveur sur chaque structure",
    tarifSecteur1Optam: "67.2",
    tarifHorsSecteur1HorsOptam: "67.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX188",
    designation:
      "Examen anatomopathologique de pièce d'exérèse monobloc ou en fragments non différenciés, d'une structure anatomique",
    tarifSecteur1Optam: "34.52",
    tarifHorsSecteur1HorsOptam: "34.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX127",
    designation:
      "Examen anatomopathologique de pièce d'exérèse en fragments différenciés d'une structure anatomique",
    tarifSecteur1Optam: "40",
    tarifHorsSecteur1HorsOptam: "40",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX192",
    designation:
      "Examen anatomopathologique de pièce d'exérèse en fragments différenciés de plusieurs structures anatomiques",
    tarifSecteur1Optam: "119.48",
    tarifHorsSecteur1HorsOptam: "119.48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX180",
    designation:
      "Examen anatomopathologique de pièce d'exérèse de 2 structures anatomiques",
    tarifSecteur1Optam: "67.79",
    tarifHorsSecteur1HorsOptam: "67.79",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX178",
    designation:
      "Examen anatomopathologique de pièce d'exérèse de 3 structures anatomiques",
    tarifSecteur1Optam: "93.45",
    tarifHorsSecteur1HorsOptam: "93.45",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX181",
    designation:
      "Examen anatomopathologique de pièce d'exérèse de 4 structures anatomiques ou plus",
    tarifSecteur1Optam: "86.7",
    tarifHorsSecteur1HorsOptam: "86.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX069",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé avec 1 à 2 anticorps, sans quantification du signal",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX081",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé avec 1 à 2 anticorps, avec quantification du signal pour chaque anticorps",
    tarifSecteur1Optam: "56",
    tarifHorsSecteur1HorsOptam: "56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX027",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé avec 3 à 5 anticorps, sans quantification du signal",
    tarifSecteur1Optam: "68",
    tarifHorsSecteur1HorsOptam: "68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX045",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé avec 3 à 5 anticorps, avec quantification du signal pour chaque anticorps",
    tarifSecteur1Optam: "83",
    tarifHorsSecteur1HorsOptam: "83",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX034",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé avec 6 à 9 anticorps, sans quantification du signal",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX122",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé avec 6 à 9 anticorps, avec quantification du signal pour chaque anticorps",
    tarifSecteur1Optam: "130",
    tarifHorsSecteur1HorsOptam: "130",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX092",
    designation:
      "Examen immunocytochimique ou immunohistochimique de prélèvement cellulaire ou tissulaire fixé, avec 10 anticorps ou plus, sans quantification du signal",
    tarifSecteur1Optam: "160",
    tarifHorsSecteur1HorsOptam: "160",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX016",
    designation:
      "Examen cytopathologique ou anatomopathologique de prélèvement cellulaire ou tissulaire congelé, avec examen immunocytochimique, immunohistochimique et/ou immunofluorescence avec 1 à 4 anticorps",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX073",
    designation:
      "Examen cytopathologique ou anatomopathologique de prélèvement cellulaire ou tissulaire congelé, avec examen immunocytochimique, immunohistochimique et/ou immunofluorescence avec 5 anticorps ou plus",
    tarifSecteur1Optam: "84",
    tarifHorsSecteur1HorsOptam: "84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX129",
    designation:
      "Qualification pour analyse de génétique somatique d'échantillon tissulaire fixé en paraffine",
    tarifSecteur1Optam: "36.89",
    tarifHorsSecteur1HorsOptam: "36.89",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX201",
    designation:
      "Qualification pour analyse génétique somatique d’échantillon tissulaire congelé",
    tarifSecteur1Optam: "39.73",
    tarifHorsSecteur1HorsOptam: "39.73",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX029",
    designation:
      "Examen cytopathologique ou histopathologique par hybridation in situ, sans quantification du signal",
    tarifSecteur1Optam: "140",
    tarifHorsSecteur1HorsOptam: "140",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX058",
    designation:
      "Examen cytopathologique ou histopathologique par hybridation in situ, avec quantification du signal",
    tarifSecteur1Optam: "190",
    tarifHorsSecteur1HorsOptam: "190",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX628",
    designation:
      "Test de détection de l'acide ribonucléique ou désoxyribonucléique des papillomavirus humains oncogènes [test HPV à ARNm ou test HPV à ADN] pour dépistage individuel",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX603",
    designation:
      "Test de détection de l'acide ribonucléique ou désoxyribonucléique des papillomavirus humains oncogènes [test HPV à ARNm ou test HPV à ADN] pour dépistage organisé",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX065",
    designation:
      "Examen histopathologique ou cytopathologique pour second avis",
    tarifSecteur1Optam: "60",
    tarifHorsSecteur1HorsOptam: "60",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQX086",
    designation:
      "Examen histopathologique ou cytopathologique de cancer rare pour seconde lecture",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ003",
    designation:
      "Évacuation d'une collection d'un organe superficiel, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "26.1",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ002",
    designation:
      "Évacuation de plusieurs collections d'un organe superficiel, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "47.15",
    tarifHorsSecteur1HorsOptam: "20.9",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ013",
    designation:
      "Évacuation d'une collection d'un organe profond, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "68.2",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH002",
    designation:
      "Évacuation d'une collection d'un organe profond, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "96.88",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH001",
    designation:
      "Évacuation d'une collection d'un organe profond, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "43.68",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ011",
    designation:
      "Évacuation d'une collection d'un organe profond, par voie intracavitaire avec guidage échographique",
    tarifSecteur1Optam: "75.78",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ012",
    designation:
      "Évacuation de plusieurs collections d'un organe profond, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "92.62",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH004",
    designation:
      "Évacuation de plusieurs collections d'un organe profond, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "152.59",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH008",
    designation:
      "Évacuation de plusieurs collections d'un organe profond, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "56.64",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ009",
    designation:
      "Évacuation de plusieurs collections d'un organe profond, par voie intracavitaire avec guidage échographique",
    tarifSecteur1Optam: "90.09",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ001",
    designation:
      "Évacuation de collection d'un organe profond, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ007",
    designation:
      "Drainage d'une collection d'un organe superficiel, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "48.84",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ010",
    designation:
      "Drainage de plusieurs collections d'un organe superficiel, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "65.68",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ008",
    designation:
      "Drainage d'une collection d'un organe profond, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "106.09",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH007",
    designation:
      "Drainage d'une collection d'un organe profond, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "121.1",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH003",
    designation:
      "Drainage d'une collection d'un organe profond, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "60",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ004",
    designation:
      "Drainage de plusieurs collections d'un organe profond, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "146.51",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH006",
    designation:
      "Drainage de plusieurs collections d'un organe profond, par voie transcutanée avec guidage radiologique",
    tarifSecteur1Optam: "175.6",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJH005",
    designation:
      "Drainage de plusieurs collections d'un organe profond, par voie transcutanée avec guidage scanographique",
    tarifSecteur1Optam: "76.32",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ006",
    designation:
      "Drainage de collection d'un organe profond, par voie transcutanée avec guidage remnographique [IRM]",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZJJ005",
    designation:
      "Drainage de collection d'un organe profond, par voie intracavitaire avec guidage échographique",
    tarifSecteur1Optam: "111.14",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZLJ002",
    designation:
      "Injection d'agent pharmacologique dans un organe superficiel, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "10.1",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZLJ001",
    designation:
      "Injection d'agent pharmacologique dans un organe profond, par voie transcutanée avec guidage échographique",
    tarifSecteur1Optam: "82.52",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZLF900",
    designation:
      "Séance d'administration locorégionale intrartérielle d'agent pharmacologique anticancéreux par un dispositif implanté, sans CEC, sans hyperthermie",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZLF004",
    designation:
      "Administration locorégionale d'agent pharmacologique anticancéreux par voie artérielle, avec CEC, avec hyperthermie",
    tarifSecteur1Optam: "173.71",
    tarifHorsSecteur1HorsOptam: "165.26",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL016",
    designation:
      "Irradiation interne par injection intraveineuse transcutanée d'un agent pharmacologique radio-isotopique",
    tarifSecteur1Optam: "180.44",
    tarifHorsSecteur1HorsOptam: "180.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQP001",
    designation:
      "Surveillance médicalisée du transport intrahospitalier d'un patient non ventilé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZQP003",
    designation:
      "Surveillance médicalisée du transport intrahospitalier d'un patient ventilé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZGP001",
    designation:
      "Mobilisation ou ablation de dispositif de drainage, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZKP001",
    designation:
      "Changement de dispositif de drainage, sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZGA010",
    designation:
      "Ablation d'un système diffuseur ou d'une pompe souscutané implanté",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QZKA007",
    designation:
      "Changement d'un système diffuseur ou d'une pompe souscutané implanté",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZMK014",
    designation:
      "Préparation à une irradiation externe sans dosimétrie, avec simulation sous l'appareil de traitement",
    tarifSecteur1Optam: "133.6",
    tarifHorsSecteur1HorsOptam: "133.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK002",
    designation:
      "Préparation à une irradiation externe sans dosimétrie, avec simulation à l'aide d'un simulateur, d'un simulateur-scanographe ou d'un scanographe à fonction simulateur intégrée",
    tarifSecteur1Optam: "133.6",
    tarifHorsSecteur1HorsOptam: "133.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK013",
    designation:
      "Préparation à une irradiation externe sans dosimétrie, avec simulation à l'aide d'un simulateur, d'un simulateur-scanographe ou d'un scanographe à fonction simulateur intégrée et fabrication de cache personnalisé focalisé et/ou paramétrage d'un collimateur multilame",
    tarifSecteur1Optam: "133.6",
    tarifHorsSecteur1HorsOptam: "133.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK026",
    designation:
      "Préparation à une irradiation externe avec repérage par simulateur-scanographe, dosimétrie bidimensionnelle et simulation à l'aide d'un simulateur-scanographe",
    tarifSecteur1Optam: "257.55",
    tarifHorsSecteur1HorsOptam: "257.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK028",
    designation:
      "Préparation à une irradiation externe avec repérage par simulateur-scanographe, dosimétrie bidimensionnelle, simulation à l'aide d'un simulateur-scanographe et fabrication de cache personnalisé focalisé et/ou paramétrage d'un collimateur multilame",
    tarifSecteur1Optam: "257.55",
    tarifHorsSecteur1HorsOptam: "257.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK001",
    designation:
      "Préparation à une irradiation externe avec repérage par scanographe, dosimétrie bidimensionnelle et simulation à l'aide d'un simulateur ou d'un scanographe à fonction simulateur intégrée",
    tarifSecteur1Optam: "257.55",
    tarifHorsSecteur1HorsOptam: "257.55",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK017",
    designation:
      "Préparation à une irradiation externe avec repérage par scanographe, dosimétrie bidimensionnelle, simulation à l'aide d'un simulateur ou d'un scanographe à fonction simulateur intégrée et fabrication de cache personnalisé focalisé et/ou paramétrage d'un collimateur multilame",
    tarifSecteur1Optam: "292.25",
    tarifHorsSecteur1HorsOptam: "292.25",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK016",
    designation:
      "Préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle sans HDV, simulation à l'aide d'un simulateur ou d'un scanographe à fonction simulateur intégrée et fabrication de cache personnalisé focalisé et/ou paramétrage d'un collimateur multilame",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK011",
    designation:
      '"Préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle sans HDV, simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle, et fabrication de cache personnalisé focalisé et/ou paramétrage d\'un collimateur multilame"',
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK018",
    designation:
      '"Préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle avec HDV, simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle, et fabrication de filtre compensateur personnalisé ou de cache personnalisé focalisé et/ou paramétrage d\'un collimateur multilame"',
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK024",
    designation:
      '"Préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle avec HDV, simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle, et paramétrage d\'un collimateur multilame pour modulation d\'intensité"',
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP001",
    designation: "Préparation à une irradiation corporelle totale",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZMP003",
    designation: "Préparation à une irradiation cutanée totale",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AGMP001",
    designation:
      "Préparation à une irradiation externe du névraxe [irradiation craniospinale]",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP018",
    designation: "Préparation à une radiothérapie de contact intracavitaire",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP012",
    designation:
      "Préparation à une irradiation intracrânienne en conditions stéréotaxiques en dose unique, avec pose de cadre effractif",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "",
  },
  {
    codeCCAM: "ZZMP016",
    designation:
      '"Préparation à une irradiation externe en conditions stéréotaxiques sans synchronisation avec la respiration avec dosimétrie tridimensionnelle avec HDV après repérage par fusion numérique multimodale et simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle"',
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP013",
    designation:
      '"Préparation à une irradiation externe en conditions stéréotaxiques avec synchronisation avec la respiration avec dosimétrie tridimensionnelle avec HDV après repérage par fusion numérique multimodale et simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle"',
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK019",
    designation:
      "Reprise de préparation à une irradiation externe sans dosimétrie, avec simulation sous l'appareil de traitement",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK027",
    designation:
      "Reprise de préparation à une irradiation externe, dosimétrie bidimensionnelle, simulation à l'aide d'un simulateur, d'un simulateur-scanographe ou d'un scanographe à fonction simulateur intégrée et fabrication de cache personnalisé focalisé et/ou paramétrage d'un collimateur multilame",
    tarifSecteur1Optam: "178.41",
    tarifHorsSecteur1HorsOptam: "178.41",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK022",
    designation:
      "Reprise de préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle sans HDV, simulation à l'aide d'un simulateur ou d'un scanographe à fonction simulateur intégrée et fabrication de cache personnalisé focalisé et/ou paramétrage d'un collimateur multilame",
    tarifSecteur1Optam: "417.5",
    tarifHorsSecteur1HorsOptam: "417.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK020",
    designation:
      '"Reprise de préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle avec HDV, simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle, et fabrication de filtre compensateur personnalisé ou de cache personnalisé focalisé et/ou paramétrage d\'un collimateur multilame"',
    tarifSecteur1Optam: "584.5",
    tarifHorsSecteur1HorsOptam: "584.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMK025",
    designation:
      '"Reprise de préparation à une irradiation externe avec repérage par scanographe, dosimétrie tridimensionnelle avec HDV, simulation virtuelle utilisant la fonction ""vue de la source"" [beam\'s eye view] [BEV] et la restitution tridimensionnelle, et paramétrage d\'un collimateur multilame pour modulation d\'intensité"',
    tarifSecteur1Optam: "584.5",
    tarifHorsSecteur1HorsOptam: "584.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL061",
    designation:
      "Séance d'irradiation externe par machine de puissance inférieure à 5 mégavolts [MV]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL053",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire de puissance égale ou supérieure à 5 mégavolts [MV], sans système de contrôle du positionnement",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL064",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire de puissance égale ou supérieure à 5 mégavolts [MV], équipé d'imagerie portale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL048",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire de puissance égale ou supérieure à 5 mégavolts [MV], équipé d'un collimateur multilame",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL065",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire de puissance égale ou supérieure à 5 mégavolts [MV], équipé d'un collimateur multilame et d'imagerie portale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL062",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire de puissance égale ou supérieure à 5 mégavolts [MV], équipé d'un système de repositionnement ",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL063",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire de puissance égale ou supérieure à 5 mégavolts [MV], équipé d'un système de repositionnement guidé par imagerie [IGRT]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL050",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire avec modulation d'intensité, sans contrôle de la position de la cible",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL054",
    designation:
      "Séance d'irradiation externe par accélérateur linéaire avec modulation d'intensité, avec contrôle de la position de la cible",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL051",
    designation:
      "Séance d'irradiation externe par machine dédiée produisant des photons avec modulation d'intensité et contrôle de la position de la cible par imagerie [IGRT]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL058",
    designation:
      "Séance d'irradiation externe en conditions stéréotaxiques par machine produisant des photons avec guidage par imagerie, sans synchronisation avec la respiration",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL052",
    designation:
      "Séance d'irradiation externe en conditions stéréotaxiques par machine produisant des photons avec guidage par imagerie, avec synchronisation avec la respiration",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL059",
    designation:
      "Séance d'irradiation externe en conditions stéréotaxiques par machine dédiée produisant des photons avec guidage par imagerie, sans synchronisation avec la respiration",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL060",
    designation:
      "Séance d'irradiation externe en conditions stéréotaxiques par machine dédiée produisant des photons avec guidage par imagerie, avec synchronisation avec la respiration",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZANL001",
    designation:
      "Irradiation externe intracrânienne en conditions stéréotaxiques avec cadre effractif, en dose unique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL049",
    designation:
      "Irradiation externe en conditions stéréotaxiques par machine produisant des photons avec guidage par imagerie, sans synchronisation avec la respiration, en dose unique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL055",
    designation:
      "Irradiation externe en conditions stéréotaxiques par machine dédiée produisant des photons avec guidage par imagerie, sans synchronisation avec la respiration, en dose unique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AZNL001",
    designation:
      "Séance d'irradiation externe du névraxe [irradiation craniospinale]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL045",
    designation: "Séance d'irradiation externe par protons",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL046",
    designation: "Séance d'irradiation externe par neutrons",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL047",
    designation: "Séance d'irradiation corporelle totale [ICT]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "QZNL001",
    designation: "Séance d'irradiation cutanée totale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL066",
    designation: "Séance d'irradiation de contact endocavitaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNL003",
    designation:
      "Curiethérapie intracavitaire utérovaginale à bas débit de dose sans projecteur de source, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNL005",
    designation:
      "Curiethérapie intracavitaire utérovaginale à bas débit de dose sans projecteur de source, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNL004",
    designation:
      "Curiethérapie intracavitaire utérovaginale à bas débit de dose avec projecteur de source, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNL001",
    designation:
      "Curiethérapie intracavitaire utérovaginale à bas débit de dose avec projecteur de source, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNL006",
    designation:
      "Curiethérapie intracavitaire utérovaginale à débit de dose pulsé, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JKNL002",
    designation:
      "Curiethérapie intracavitaire utérovaginale à débit de dose pulsé, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLNL001",
    designation:
      "Curiethérapie intracavitaire vaginale à bas débit de dose sans projecteur de source, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLNL005",
    designation:
      "Curiethérapie intracavitaire vaginale à bas débit de dose sans projecteur de source, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLNL004",
    designation:
      "Curiethérapie intracavitaire vaginale à bas débit de dose avec projecteur de source, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLNL003",
    designation:
      "Curiethérapie intracavitaire vaginale à bas débit de dose avec projecteur de source, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLNL007",
    designation:
      "Curiethérapie intracavitaire vaginale à débit de dose pulsé, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JLNL006",
    designation:
      "Curiethérapie intracavitaire vaginale à débit de dose pulsé, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL007",
    designation:
      "Curiethérapie intraluminale à bas débit de dose sans projecteur de source",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL010",
    designation:
      "Curiethérapie intraluminale à bas débit de dose avec projecteur de source",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL008",
    designation: "Curiethérapie intraluminale à débit de dose pulsé",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL019",
    designation: "Curiethérapie intraluminale à haut débit de dose",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "",
  },
  {
    codeCCAM: "ZZNL001",
    designation:
      "Curiethérapie interstitielle superficielle à bas débit de dose sans projecteur de source, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL006",
    designation:
      "Curiethérapie interstitielle superficielle à bas débit de dose sans projecteur de source, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL011",
    designation:
      "Curiethérapie interstitielle superficielle à débit de dose pulsé, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL002",
    designation:
      "Curiethérapie interstitielle superficielle à débit de dose pulsé, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL005",
    designation:
      "Curiethérapie interstitielle profonde à bas débit de dose sans projecteur de source, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL009",
    designation:
      "Curiethérapie interstitielle profonde à bas débit de dose sans projecteur de source, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL015",
    designation:
      "Curiethérapie interstitielle profonde à débit de dose pulsé, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL018",
    designation:
      "Curiethérapie interstitielle profonde à débit de dose pulsé, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL012",
    designation:
      "Curiethérapie interstitielle à bas débit de dose sans projecteur de source après mise en place de vecteur au cours d'une intervention chirurgicale, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL013",
    designation:
      "Curiethérapie interstitielle à bas débit de dose sans projecteur de source après mise en place de vecteur au cours d'une intervention chirurgicale, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL017",
    designation:
      "Curiethérapie interstitielle à bas débit de dose sans projecteur de source après mise en place de vecteur au cours d'une intervention chirurgicale, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL014",
    designation:
      "Curiethérapie interstitielle à débit de dose pulsé après mise en place de vecteur au cours d'une intervention chirurgicale, avec dosimétrie dans un plan",
    tarifSecteur1Optam: "367.4",
    tarifHorsSecteur1HorsOptam: "367.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL004",
    designation:
      "Curiethérapie interstitielle à débit de dose pulsé après mise en place de vecteur au cours d'une intervention chirurgicale, avec dosimétrie bidimensionnelle après acquisition des données anatomiques par clichés radiographiques de repérage spatial",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNL003",
    designation:
      "Curiethérapie interstitielle à débit de dose pulsé après mise en place de vecteur au cours d'une intervention chirurgicale, avec dosimétrie tridimensionnelle après acquisition des données anatomiques par scanographie et/ou remnographie [IRM]",
    tarifSecteur1Optam: "384.1",
    tarifHorsSecteur1HorsOptam: "384.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "JGNL001",
    designation:
      "Curiethérapie de la prostate par insertion permanente d'iode 125",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZLP025",
    designation: "Anesthésie générale ou locorégionale complémentaire niveau 1",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "ZZLP030",
    designation: "Anesthésie générale ou locorégionale complémentaire niveau 2",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "ZZLP054",
    designation: "Anesthésie générale ou locorégionale complémentaire niveau 3",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "ZZLP042",
    designation: "Anesthésie générale ou locorégionale complémentaire niveau 4",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "ZZLP008",
    designation: "Anesthésie générale ou locorégionale complémentaire niveau 5",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "ZZLP012",
    designation: "Anesthésie générale ou locorégionale complémentaire niveau 6",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "AFLB010",
    designation:
      "Anesthésie rachidienne au cours d'un accouchement par voie basse",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "AAQP004",
    designation:
      "Surveillance électroencéphalographique peropératoire sur au moins 8 dérivations, pendant moins de 4 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP008",
    designation:
      "Surveillance électroencéphalographique peropératoire sur au moins 8 dérivations, pendant 4 heures ou plus",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AAQP005",
    designation:
      "Surveillance électrocorticographique peropératoire de l'activité encéphalique spontanée et/ou provoquée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AGQP006",
    designation:
      "Surveillance peropératoire des potentiels évoqués moteurs, sensoriels ou somesthésiques, pendant moins de 2 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AGQP005",
    designation:
      "Surveillance peropératoire des potentiels évoqués moteurs, sensoriels ou somesthésiques pendant 2 à 4 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AGQP004",
    designation:
      "Surveillance peropératoire des potentiels évoqués moteurs, sensoriels ou somesthésiques, pendant plus de 4 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AGQC001",
    designation: "Endoscopie intradurale peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ACQP002",
    designation:
      "Repérage de structure nerveuse et/ou osseuse et guidage peropératoires assistés par ordinateur [Navigation]",
    tarifSecteur1Optam: "203.04",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHFA009",
    designation:
      "Prélèvement peropératoire d'autogreffe nerveuse sur un membre différent du membre opéré",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AGMA001",
    designation:
      "Réparation de perte de substance durale de plus de 10 cm² par greffe ou substitut, au cours d'une intervention intracrânienne ou intrarachidienne",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZLB004",
    designation:
      "Sédation et curarisation continues avec surveillance de la curarisation par stimulateur de nerf spinal, par 24 heures",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "BFLA002",
    designation:
      "Insertion d'un anneau de contention intrasacculaire, au cours d'une extraction du cristallin",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BFLA900",
    designation:
      "Insertion d'un deuxième système optique intraoculaire, au cours d'une extraction du cristallin",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGFA008",
    designation: "Vitrectomie mécanique complémentaire, par abord antérieur",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "BGBA002",
    designation:
      "Tamponnement intraoculaire provisoire par utilisation peropératoire de perfluorocarbone",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "DDQJ001",
    designation:
      "Échographie et/ou échographie-doppler intraartérielle coronaire, au cours d'un acte par voie vasculaire transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EDQJ900",
    designation:
      "Échographie et/ou échographie-doppler intraartérielle aortique ou rénale, au cours d'un acte par voie vasculaire transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQM003",
    designation:
      "Échographie-doppler transthoracique du cœur et des vaisseaux intrathoraciques, au cours d'un acte thérapeutique intracavitaire cardiaque par voie vasculaire transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ012",
    designation:
      "Échographie-doppler du cœur et des vaisseaux intrathoraciques par voie œsophagienne, au cours d'un acte thérapeutique intracavitaire cardiaque par voie vasculaire transcutanée",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ002",
    designation:
      "Échographie-doppler peropératoire du cœur et des vaisseaux intrathoraciques par voie œsophagienne, pour contrôle de la correction chirurgicale d'une cardiopathie congénitale ou d'une valvopathie [valvulopathie]",
    tarifSecteur1Optam: "122.85",
    tarifHorsSecteur1HorsOptam: "122.85",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ007",
    designation:
      "Échographie-doppler peropératoire du cœur et des vaisseaux intrathoraciques par voie œsophagienne, pour surveillance de défaillance cardiocirculatoire à l'issue d'une CEC",
    tarifSecteur1Optam: "122.85",
    tarifHorsSecteur1HorsOptam: "122.85",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DAQM900",
    designation:
      "Vidéodensitométrie pour analyse tissulaire du myocarde, au cours d’une échographie du cœur et des vaisseaux intrathoraciques",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DAQM901",
    designation:
      "Échographie harmonique avec injection intraveineuse transcutanée d'un produit de contraste ultrasonore pour étude de la perfusion du myocarde, au cours d'une échographie du cœur et des vaisseaux intrathoraciques",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "DZQJ900",
    designation:
      "Restitution tridimensionnelle informatisée des structures cardiaques et des flux, au cours d’une échographie du cœur et des vaisseaux intrathoraciques",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EZQJ900",
    designation:
      "Restitution tridimensionnelle informatisée, au cours d’une échographie-doppler des vaisseaux périphériques",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADE",
  },
  {
    codeCCAM: "EZQH004",
    designation: "Angiographie peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "DEQP008",
    designation:
      "Restitution tridimensionnelle informatisée de l'activité électrophysiologique cardiaque [Cartographie cardiaque tridimensionnelle], au cours d'une intervention sur le système cardionecteur",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDRH001",
    designation:
      "Épreuve pharmacodynamique de provocation de spasme coronaire, au cours d'une artériographie coronaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DDQF202",
    designation:
      "Mesure du flux de réserve coronarien [FFR] au cours d’une artériographie coronaire",
    tarifSecteur1Optam: "148",
    tarifHorsSecteur1HorsOptam: "148",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EPFA006",
    designation:
      "Prélèvement peropératoire d'autogreffe veineuse pour pontage vasculaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ENFA003",
    designation:
      "Prélèvement peropératoire d'autogreffe artérielle pour pontage vasculaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EQCF002",
    designation:
      "Prolongation ou reprise d'assistance circulatoire par circulation extracorporelle au bloc opératoire à la fin d'une intervention cardiovasculaire, pendant plus d'une demiheure",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DGLA001",
    designation:
      "Pose d'un dispositif de contrepulsion diastolique intraaortique, au cours d'une intervention par thoracotomie avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FELF001",
    designation:
      "Transfusion de concentré de globules rouges d'un volume supérieur à une demimasse sanguine, au cours d'une intervention sous anesthésie générale ou locorégionale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DELA002",
    designation:
      "Pose peropératoire d'électrode épicardique sans pose de générateur",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "AALF002",
    designation:
      "Perfusion peropératoire de protection du parenchyme encéphalique, au cours d'une intervention avec CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "EAMA002",
    designation:
      "Réparation de plaie d'un sinus veineux de la dure-mère par suture ou angioplastie [patch], au cours d'une intervention intracrânienne",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EAMA001",
    designation:
      "Reconstruction d'un sinus veineux de la dure-mère ou d'une artère intracrânienne par autogreffe, au cours d'une intervention intracrânienne",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDEA002",
    designation:
      "Réimplantation ou pontage de l'artère mésentérique inférieure, au cours d'une intervention sur l'aorte",
    tarifSecteur1Optam: "261.25",
    tarifHorsSecteur1HorsOptam: "261.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDEA003",
    designation:
      "Réimplantation ou pontage de l'artère iliaque interne, au cours d'une intervention sur l'aorte",
    tarifSecteur1Optam: "261.25",
    tarifHorsSecteur1HorsOptam: "261.25",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EDAA003",
    designation:
      "Dilatation intraluminale de l'artère iliaque et/ou de l'artère fémorale, au cours d'une intervention restauratrice de l'aorte, de l'artère iliaque ou de l'artère fémorale",
    tarifSecteur1Optam: "156.75",
    tarifHorsSecteur1HorsOptam: "156.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EMMA001",
    designation:
      "Création d'une fistule artérioveineuse pour augmentation locale du débit, au cours d'un pontage artériel des membres inférieurs",
    tarifSecteur1Optam: "131.87",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EPCA003",
    designation:
      "Suture complémentaire d'une veine de drainage sur un lambeau pédiculé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZCA005",
    designation:
      "Pontage artériel ou veineux, au cours d'une réparation par lambeau libre",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZCA001",
    designation:
      "Pontages artériel et veineux, au cours d'une réparation par lambeau libre",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELCA002",
    designation: "Exclusion vasculaire totale, au cours d'une hépatectomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZSF001",
    designation:
      "Occlusion définitive peropératoire d'un tronc vasculaire par ballonnet ou embolisation",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ELFA001",
    designation:
      "Résection du tronc de la veine porte, des vaisseaux mésentériques supérieurs et/ou de l'artère hépatique avec rétablissement de la continuité vasculaire, au cours d'une exérèse du foie ou du pancréas",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "EZNF900",
    designation:
      "Irradiation intravasculaire, au décours d'une dilatation de vaisseau par voie vasculaire transcutanée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ATM",
  },
  {
    codeCCAM: "DZSA900",
    designation:
      "Suppression d'une anastomose palliative au cours d'une correction chirurgicale secondaire de cardiopathie congénitale, avec CEC",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADC",
  },
  {
    codeCCAM: "FCFA017",
    designation:
      "Curage lymphonodal du hile du poumon et du médiastin, au cours d'une intervention pleuropulmonaire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GERD001",
    designation:
      "Épreuve pharmacodynamique par agent bronchodilatateur, au cours d'une épreuve fonctionnelle respiratoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GERD002",
    designation:
      "Épreuve de provocation par agent bronchoconstricteur ou facteur physique, au cours d'une épreuve fonctionnelle respiratoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQD003",
    designation:
      "Mesure des volumes pulmonaires non mobilisables par dilution ou rinçage d'un gaz indicateur, au cours d'une épreuve fonctionnelle respiratoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GLQD001",
    designation:
      "Mesure de la capacité de transfert pulmonaire du monoxyde de carbone [TLCO] ou d'un autre gaz en apnée ou en état stable, au cours d'une épreuve fonctionnelle respiratoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "GGBA001",
    designation:
      "Interposition ou apposition de lambeau pleural ou péricardique, au cours d'une intervention intrathoracique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LMBA001",
    designation:
      "Interposition ou apposition d'un lambeau de muscle thoracique ou abdominal sur un organe médiastinal, au cours d'une intervention intrathoracique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LLBA002",
    designation:
      "Interposition ou apposition de lambeau diaphragmatique ou intercostal, au cours d'une intervention intrathoracique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPBA002",
    designation:
      "Interposition ou apposition de grand omentum [grand épiploon], au cours d'une intervention intrathoracique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GEHE001",
    designation:
      "Biopsie trachéale et/ou bronchique, au cours d'une endoscopie diagnostique des voies aériennes",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GELE001",
    designation:
      "Intubation trachéale par fibroscopie ou dispositif laryngé particulier, au cours d'une anesthésie générale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "HEMA010",
    designation:
      "Création d'une fistule œsotrachéale avec pose d'implant phonatoire, au cours d'une pharyngolaryngectomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "GLLD005",
    designation:
      "Administration de monoxyde d'azote [NO] inhalé, chez un patient ventilé",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HBQK040",
    designation:
      "Radiographies intrabuccales rétroalvéolaires sur un secteur de 1 à 3 dents contigües préinterventionnelle ou perinterventionnelle avec radiographie finale pour acte thérapeutique endodontique",
    tarifSecteur1Optam: "11.97",
    tarifHorsSecteur1HorsOptam: "11.97",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK303",
    designation:
      "Radiographies intrabuccales rétroalvéolaires sur un secteur de 1 à 3 dents contigües préinterventionnelle, perinterventionnelle et finale pour acte thérapeutique endodontique",
    tarifSecteur1Optam: "15.96",
    tarifHorsSecteur1HorsOptam: "15.96",
    regroupement: "AID",
  },
  {
    codeCCAM: "HBQK061",
    designation:
      "Radiographie intrabuccale rétroalvéolaire et/ou rétrocoronaire d'un secteur de 1 à 3 dents contigües, finale pour acte thérapeutique endodontique ou perinterventionnelle et/ou finale, en dehors  d'un acte thérapeutique endodontique",
    tarifSecteur1Optam: "3.99",
    tarifHorsSecteur1HorsOptam: "3.99",
    regroupement: "AID",
  },
  {
    codeCCAM: "HMQH008",
    designation:
      "Cholangiographie et/ou pancréaticographie [wirsungographie] peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "HEQE004",
    designation: "Endoscopie œso-gastro-duodénale peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGQE004",
    designation: "Entéroscopie jéjunale et/ou iléale peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HHQE001",
    designation: "Coloscopie peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMQA001",
    designation:
      "Endoscopie peropératoire des voies biliaires, par abord transcystique ou par cholédochotomie",
    tarifSecteur1Optam: "116.38",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HZHE002",
    designation:
      "Biopsie et/ou brossage cytologique de la paroi du tube digestif ou de conduit biliopancréatique, au cours d'une endoscopie diagnostique",
    tarifSecteur1Optam: "17.6",
    tarifHorsSecteur1HorsOptam: "17.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HZHE005",
    designation:
      "Macrobiopsie de la paroi du tube digestif, au cours d'une endoscopie diagnostique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HZHE004",
    designation:
      "Coloration et/ou tatouage de la paroi du tube digestif, au cours d'une endoscopie diagnostique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HZHE001",
    designation:
      "Prélèvement pour analyse biologique, au cours d'une endoscopie diagnostique des voies digestives",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZCQA002",
    designation:
      "Exploration de la cavité abdominale par phrénotomie, au cours d'une intervention par thoracotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZCFA001",
    designation:
      "Exérèse de lésion d'un organe infradiaphragmatique par phrénotomie, au cours de l'exérèse de lésion intrathoracique par thoracotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HBDD008",
    designation:
      "Contention peropératoire des arcades dentaires par arc vestibulaire continu",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBDD007",
    designation:
      "Contention peropératoire des arcades dentaires par moyen autre qu'un arc vestibulaire continu",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBDD014",
    designation:
      "Contention peropératoire des arcades dentaires par un arc vestibulaire continu sur l'une, par autre moyen sur l'autre",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ASC",
  },
  {
    codeCCAM: "HBMD006",
    designation:
      "Reconstitution coronaire provisoire pour acte endodontique sur dent délabrée",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "END",
  },
  {
    codeCCAM: "HBMD490",
    designation:
      "Adjonction d'un 1er élément intermédiaire métallique à une prothèse dentaire plurale fixée [2e élément métallique intermédiaire de bridge] ",
    tarifSecteur1Optam: "0.1",
    tarifHorsSecteur1HorsOptam: "0.1",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBMD342",
    designation:
      "Adjonction d'un 2e élément intermédiaire métallique à une prothèse dentaire plurale  [3e élément métallique  intermédiaire de bridge] ",
    tarifSecteur1Optam: "0.1",
    tarifHorsSecteur1HorsOptam: "0.1",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBMD082",
    designation:
      "Adjonction à une prothèse dentaire plurale fixée d'un élément intermédiaire métallique supplémentaire, au-delà du 3e ",
    tarifSecteur1Optam: "10.75",
    tarifHorsSecteur1HorsOptam: "10.75",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBMD479",
    designation:
      "Adjonction d'un 1er élément intermédiaire céramométallique ou en équivalents minéraux à une prothèse dentaire plurale fixée [2e élément céramométallique ou en équivalents minéraux intermédiaire de bridge] ",
    tarifSecteur1Optam: "0.1",
    tarifHorsSecteur1HorsOptam: "0.1",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBMD433",
    designation:
      "Adjonction d'un 2e élément intermédiaire céramométallique ou en équivalents minéraux à une prothèse dentaire plurale fixée [3e élément  céramométallique ou en équivalents minéraux intermédiaire de bridge] ",
    tarifSecteur1Optam: "0.1",
    tarifHorsSecteur1HorsOptam: "0.1",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBMD072",
    designation:
      "Adjonction à une prothèse dentaire plurale fixée d'un élément intermédiaire céramométallique ou en équivalents minéraux supplémentaire, au-delà du 3e ",
    tarifSecteur1Optam: "10.75",
    tarifHorsSecteur1HorsOptam: "10.75",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBMD081",
    designation:
      "Adjonction d'un pilier d'ancrage métallique à une prothèse dentaire plurale fixée [pilier de bridge]",
    tarifSecteur1Optam: "107.5",
    tarifHorsSecteur1HorsOptam: "107.5",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBMD087",
    designation:
      "Adjonction d'un pilier d'ancrage céramométallique ou en équivalents minéraux  à une prothèse dentaire plurale fixée [pilier de bridge]",
    tarifSecteur1Optam: "107.5",
    tarifHorsSecteur1HorsOptam: "107.5",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HBMD776",
    designation:
      "Adjonction d'un élément en extension métallique, autre qu'une canine, à une prothèse plurale fixée [bridge] comportant au moins 2 piliers d'ancrage et 1 élément intermédiaire",
    tarifSecteur1Optam: "10.75",
    tarifHorsSecteur1HorsOptam: "10.75",
    regroupement: "PFM",
  },
  {
    codeCCAM: "HBMD689",
    designation:
      "Adjonction d'1 élément en extension céramométallique ou en équivalents minéraux, autre qu'une canine, à une prothèse plurale fixée  [bridge] comportant au moins 2 piliers d'ancrage et un élément intermédiaire",
    tarifSecteur1Optam: "10.75",
    tarifHorsSecteur1HorsOptam: "10.75",
    regroupement: "PFC",
  },
  {
    codeCCAM: "HCNE083",
    designation:
      "Fragmentation intracanalaire de calcul des glandes salivaires au cours d’une sialendoscopie",
    tarifSecteur1Optam: "50.05",
    tarifHorsSecteur1HorsOptam: "50.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HGCA004",
    designation:
      "Entérostomie ou colostomie cutanée de protection, au cours d'une résection intestinale avec rétablissement de la continuité",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HGMA003",
    designation:
      "Confection d'un réservoir iléal ou colique, au cours d'une anastomose iléoanale ou coloanale",
    tarifSecteur1Optam: "191.43",
    tarifHorsSecteur1HorsOptam: "149.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HPMA001",
    designation:
      "Épiploplastie intraabdominale par libération de la grande courbure gastrique avec pédiculisation sur un pédicule gastroépiploïque, au cours d'une intervention par laparotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HMJE001",
    designation:
      "Drainage de conduit biliaire ou pancréatique par voie nasale, au cours d'une endoscopie œso-gastro-duodénale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HMJA001",
    designation:
      "Drainage transpariétal de conduit biliaire [Drainage biliaire externe], au cours d'une intervention intraabdominale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "HHFA032",
    designation:
      "Kystectomie ovarienne, par abord vaginal au cours d'une intervention par abord vaginal",
    tarifSecteur1Optam: "31.35",
    tarifHorsSecteur1HorsOptam: "31.35",
    regroupement: "ADC",
  },
  {
    codeCCAM: "JNQD001",
    designation:
      "Monitorage électronique des contractions de l'utérus gravide et/ou du rythme cardiaque du fœtus, par voie utérine",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQQP900",
    designation:
      "Oxymétrie transcutanée du fœtus sur la présentation [Oxymétrie de pouls fœtal]",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQHB001",
    designation: "Prélèvement de sang du fœtus sur la présentation",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JMPA006",
    designation: "Épisiotomie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQED001",
    designation: "Version du fœtus par manœuvres obstétricales internes",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQED002",
    designation: "Réduction d'une dystocie sévère des épaules",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD009",
    designation:
      "Extraction instrumentale au détroit moyen sur présentation céphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD006",
    designation:
      "Extraction instrumentale au détroit inférieur sur présentation céphalique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JQGD011",
    designation:
      "Extraction instrumentale sur tête dernière, au cours d'un accouchement par le siège",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JPGD001",
    designation: "Extraction manuelle du placenta complet",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "JNMD002",
    designation: "Révision de la cavité de l'utérus après délivrance naturelle",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ACO",
  },
  {
    codeCCAM: "LBLP001",
    designation:
      "Pose d'un guide positionnel, au cours d'une ostéotomie de la mâchoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LGDA001",
    designation:
      "Fixation sacrale ou sacro-iliaque par abord postérieur, au cours d'une arthrodèse lombale sur 6 vertèbres ou plus",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LHDA003",
    designation:
      "Ligamentoplastie interépineuse de la colonne vertébrale, par abord postérieur",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "LAFA008",
    designation:
      "Prélèvement d'autogreffe osseuse par dédoublement de la voûte du crâne",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA010",
    designation:
      "Prélèvement d'autogreffe osseuse corticale ou corticospongieuse, ou d'autogreffe périostée à distance du foyer opératoire, sur un site sans changement de position",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ASC",
  },
  {
    codeCCAM: "PAFA004",
    designation:
      "Prélèvement d'autogreffe osseuse corticale ou corticospongieuse à distance du foyer opératoire, sur un site avec changement de position",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "PAFA003",
    designation:
      "Prélèvement d'autogreffe osseuse corticale ou corticospongieuse à distance du foyer opératoire, sur plusieurs sites sans changement de position",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ASC",
  },
  {
    codeCCAM: "PAFA009",
    designation:
      "Prélèvement d'autogreffe osseuse corticale ou corticospongieuse à distance du foyer opératoire, sur plusieurs sites avec changement de position",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "QAFA001",
    designation:
      "Prélèvement d'autogreffe cutanée sur le cuir chevelu, pour brûlure",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZQA002",
    designation: "Échographie peropératoire",
    tarifSecteur1Optam: "28.35",
    tarifHorsSecteur1HorsOptam: "28.35",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZZQA003",
    designation: "Échographie-doppler peropératoire",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZZQM005",
    designation:
      "Étude de la vascularisation du parenchyme d'un organe au cours d'une échographie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADE",
  },
  {
    codeCCAM: "ZZQL010",
    designation:
      "Détection peropératoire de lésion après injection de produit radio-isotopique",
    tarifSecteur1Optam: "44.22",
    tarifHorsSecteur1HorsOptam: "44.22",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL007",
    designation: "Tomoscintigraphie complémentaire d'une image planaire",
    tarifSecteur1Optam: "132.64",
    tarifHorsSecteur1HorsOptam: "132.64",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL019",
    designation:
      "Images planaires scintigraphiques tardives sans réinjection, complémentaires d'un examen standard",
    tarifSecteur1Optam: "88.43",
    tarifHorsSecteur1HorsOptam: "88.43",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL018",
    designation:
      "Images tomoscintigraphiques tardives sans réinjection, complémentaires d'un examen standard",
    tarifSecteur1Optam: "132.64",
    tarifHorsSecteur1HorsOptam: "132.64",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL017",
    designation:
      "Quantification complexe d'une étude scintigraphique en dehors de l'examen de la glande thyroïde ou de la scintigraphie rénale corticale",
    tarifSecteur1Optam: "26.53",
    tarifHorsSecteur1HorsOptam: "26.53",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQL900",
    designation:
      "Mise en œuvre d'une correction d'atténuation par transmission tomoscintigraphique",
    tarifSecteur1Optam: "Non pris en charge",
    tarifHorsSecteur1HorsOptam: "Non pris en charge",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQP004",
    designation:
      "Restitution tridimensionnelle des images acquises par scanographie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZQN002",
    designation:
      "Restitution tridimensionnelle des images acquises par remnographie [IRM]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZHA001",
    designation:
      "Prélèvement peropératoire pour examen cytologique et/ou anatomopathologique extemporané",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADC",
  },
  {
    codeCCAM: "ZZQL021",
    designation:
      "Fusion numérique multimodale des images acquises par techniques d'imagerie en coupes ou imagerie fonctionnelle",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADI",
  },
  {
    codeCCAM: "ZZMP015",
    designation:
      "Confection d'un système de contention personnalisé [individualisé] pour radiothérapie externe",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZML001",
    designation:
      "Contrôle du centrage des champs d'irradiation externe [Contrôle balistique] par imagerie portale",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZML003",
    designation:
      "Contrôle du centrage des champs d'irradiation externe [Contrôle balistique] au cours d'une irradiation externe guidée par imagerie [IGRT]",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZML002",
    designation:
      "Contrôle de la dose d'irradiation externe reçue [Dosimétrie in vivo]",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP017",
    designation:
      "Enregistrement numérisé et vérification des paramètres d'un traitement par irradiation externe [Système record and verify]",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP004",
    designation: "Fabrication d'un applicateur personnalisé pour curiethérapie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZMP003",
    designation:
      "Fabrication de protection plombée personnalisée pour curiethérapie",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "ZZNA002",
    designation: "Irradiation externe peropératoire en dose unique",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "AHQJ021",
    designation:
      "Guidage échographique pour anesthésie locorégionale périphérique du cou, du sein, de la paroi thoracique, de la paroi abdominale ou de membre, ou pour anesthésie rachidienne des patients dont l'indice de masse corporelle est supérieur ou égal à 30 kg/m2",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "YYYY108",
    designation:
      "Surveillance post opératoire d'un patient de chirurgie cardiaque avec CEC, niveau 1",
    tarifSecteur1Optam: "480",
    tarifHorsSecteur1HorsOptam: "480",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY118",
    designation:
      "Surveillance post opératoire d'un patient de chirurgie cardiaque avec CEC, niveau 2",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY044",
    designation:
      "Assistance anesthésique au cours du travail, sur appel de l'obstétricien",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ADA",
  },
  {
    codeCCAM: "YYYY001",
    designation: "Forfait de cardiologie niveau 1",
    tarifSecteur1Optam: "26.88",
    tarifHorsSecteur1HorsOptam: "26.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY002",
    designation: "Forfait de cardiologie niveau 2",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY062",
    designation:
      "Participation d'un 2ème chirurgien au cours d'un acte de chirurgie cardiaque",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY017",
    designation:
      "Injection secondaire souscutanée susfasciale de tissu adipeux autologue au niveau du visage",
    tarifSecteur1Optam: "62.7",
    tarifHorsSecteur1HorsOptam: "62.7",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY059",
    designation:
      "Appareillage de contention ou de réduction pré et postopératoire pour résection ou greffe du maxillaire ou de la mandibule",
    tarifSecteur1Optam: "249.6",
    tarifHorsSecteur1HorsOptam: "249.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY027",
    designation:
      "Prélèvement de spermatozoïdes au niveau du testicule, de l'épididyme ou du conduit déférent, par abord direct",
    tarifSecteur1Optam: "125.4",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY172",
    designation:
      "Échographie et/ou échographie doppler de contrôle ou surveillance de pathologie d'un ou deux organes intra-abdominaux et/ou intrapelviens, ou de vaisseaux périphériques",
    tarifSecteur1Optam: "37.8",
    tarifHorsSecteur1HorsOptam: "37.8",
    regroupement: "ADE",
  },
  {
    codeCCAM: "YYYY088",
    designation:
      "Échographie de contrôle ou surveillance de pathologie gravidique fœtale ou maternelle au cours d'une grossesse unifœtale",
    tarifSecteur1Optam: "30.24",
    tarifHorsSecteur1HorsOptam: "30.24",
    regroupement: "ADE",
  },
  {
    codeCCAM: "YYYY075",
    designation:
      "Échographie de contrôle ou surveillance de pathologie gravidique fœtale ou maternelle au cours d'une grossesse multifœtale",
    tarifSecteur1Optam: "60.48",
    tarifHorsSecteur1HorsOptam: "60.48",
    regroupement: "ADE",
  },
  {
    codeCCAM: "YYYY028",
    designation: "Guidage échographique",
    tarifSecteur1Optam: "34.97",
    tarifHorsSecteur1HorsOptam: "34.97",
    regroupement: "ADE",
  },
  {
    codeCCAM: "YYYY010",
    designation:
      "Traitement de premier recours de cas nécessitant des actes techniques (pose d'une perfusion, administration d'oxygène, soins de réanimation cardio-respiratoire...) et la présence prolongée du médecin (en dehors d'un établissement de soins) dans les situations suivantes : - détresse respiratoire ; - détresse cardiaque ; - détresse d'origine allergique ; - état aigu d'agitation ; - état de mal comitial ; - détresse d'origine traumatique.",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY011",
    designation:
      "Prise en charge diagnostique et thérapeutique dans le même temps d'une lésion ostéo-articulaire, musculo-tendineuse ou des parties molles d'origine traumatique",
    tarifSecteur1Optam: "20.16",
    tarifHorsSecteur1HorsOptam: "20.16",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY095",
    designation:
      "Assistance pédiatrique avant la naissance, sur appel du praticien responsable de l'accouchement, pour une situation de risque néonatal, avec établissement d'un compte rendu",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY154",
    designation:
      "Réanimation immédiate ou différée du nouveau-né en détresse vitale, comportant toute technique de ventilation, avec ou sans intubation, et les actes associés, avec établissement d'un compte rendu",
    tarifSecteur1Optam: "150",
    tarifHorsSecteur1HorsOptam: "150",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY117",
    designation:
      "Mise en condition médicale et surveillance d'un nouveau-né pour transfert médicalisé vers un centre spécialisé, avec établissement d'un compte rendu",
    tarifSecteur1Optam: "130",
    tarifHorsSecteur1HorsOptam: "130",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY123",
    designation:
      "Surveillance en unité d'obstétrique d'un enfant dont l'état nécessite un placement en incubateur ou des soins de courte durée",
    tarifSecteur1Optam: "23",
    tarifHorsSecteur1HorsOptam: "23",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY009",
    designation: "Forfait de néonatalogie en unité de néonatalogie",
    tarifSecteur1Optam: "31",
    tarifHorsSecteur1HorsOptam: "31",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY006",
    designation:
      "Épreuve quantitative aux agents pharmacodynamiques ou de provocation aux allergènes comportant une mesure du seuil de réactivité",
    tarifSecteur1Optam: "42.24",
    tarifHorsSecteur1HorsOptam: "42.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY076",
    designation:
      "Mesure du transfert de monoxyde de carbone, en état stable, en apnée, en inspiration unique ou en réinspiration",
    tarifSecteur1Optam: "37.24",
    tarifHorsSecteur1HorsOptam: "34.56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY089",
    designation:
      "Mesure du transfert de monoxyde de carbone, en état stable, en apnée, en inspiration unique ou en réinspiration, avec épreuve d'exercice",
    tarifSecteur1Optam: "51.84",
    tarifHorsSecteur1HorsOptam: "51.84",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY163",
    designation:
      "Radiographie de l'hémisquelette ou du squelette complet chez l'adulte",
    tarifSecteur1Optam: "159.6",
    tarifHorsSecteur1HorsOptam: "159.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY024",
    designation:
      "Bilan radiologique veineux complet des membres inférieurs pour pathologie veineuse complexe nécessitant plusieurs voies d'abord, des poses de garrots éventuelles et des clichés dans différentes positions",
    tarifSecteur1Optam: "290.4",
    tarifHorsSecteur1HorsOptam: "290.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY029",
    designation:
      "Manœuvres intratubaires uni ou bilatérales après cathétérisme de trompe utérine avec guidage radiologique",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY033",
    designation: "Guidage radiologique",
    tarifSecteur1Optam: "19.29",
    tarifHorsSecteur1HorsOptam: "19.29",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY115",
    designation: "Guidage scanographique",
    tarifSecteur1Optam: "25.27",
    tarifHorsSecteur1HorsOptam: "25.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY125",
    designation: "Guidage remnographique",
    tarifSecteur1Optam: "69",
    tarifHorsSecteur1HorsOptam: "69",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY105",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 1, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "15.28",
    tarifHorsSecteur1HorsOptam: "15.28",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY110",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 2, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "26.33",
    tarifHorsSecteur1HorsOptam: "26.33",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY120",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 3, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "31.6",
    tarifHorsSecteur1HorsOptam: "31.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY140",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 4, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "52.67",
    tarifHorsSecteur1HorsOptam: "52.67",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY150",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 5, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "63.2",
    tarifHorsSecteur1HorsOptam: "63.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY160",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 6, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "73.74",
    tarifHorsSecteur1HorsOptam: "73.74",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY170",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 7, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "84.27",
    tarifHorsSecteur1HorsOptam: "84.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY180",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 8, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "94.81",
    tarifHorsSecteur1HorsOptam: "94.81",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY130",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 9, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "105.34",
    tarifHorsSecteur1HorsOptam: "105.34",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY190",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 10, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "126.41",
    tarifHorsSecteur1HorsOptam: "126.41",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY200",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 11, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "158",
    tarifHorsSecteur1HorsOptam: "158",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY210",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 12, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "168.53",
    tarifHorsSecteur1HorsOptam: "168.53",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY220",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 13, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "189.6",
    tarifHorsSecteur1HorsOptam: "189.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY034",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 14, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "200.14",
    tarifHorsSecteur1HorsOptam: "200.14",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY230",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 15, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "210.67",
    tarifHorsSecteur1HorsOptam: "210.67",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY240",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 16, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "242.27",
    tarifHorsSecteur1HorsOptam: "242.27",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY245",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 17, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "252.81",
    tarifHorsSecteur1HorsOptam: "252.81",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY250",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 18, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "263.34",
    tarifHorsSecteur1HorsOptam: "263.34",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY260",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 19, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "294.94",
    tarifHorsSecteur1HorsOptam: "294.94",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY270",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 20, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "316.01",
    tarifHorsSecteur1HorsOptam: "316.01",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY280",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 21, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "421.34",
    tarifHorsSecteur1HorsOptam: "421.34",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY290",
    designation:
      "Imagerie pour acte de radiologie interventionnelle ou de cardiologie interventionnelle niveau 22, réalisée en salle d'imagerie",
    tarifSecteur1Optam: "474.01",
    tarifHorsSecteur1HorsOptam: "474.01",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY082",
    designation:
      "Tentative d'angioplastie d'un vaisseau coronaire, d'une artère subclavière, viscérale ou périphérique, en cas de non franchissement de la sténose",
    tarifSecteur1Optam: "209",
    tarifHorsSecteur1HorsOptam: "209",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY045",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par télécobalt par fraction de 28 centiGrays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY099",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par télécobalt par fraction de 2,8 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY101",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par télécobalt par fraction de 28 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY046",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 25 centiGrays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY136",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 2,5 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY312",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 25 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY047",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 20 centiGrays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY152",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 2 Grays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY323",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 20 Grays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY048",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 14 centiGrays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY211",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 1,4 Grays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY334",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 14 Grays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY049",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 12 centiGrays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY197",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 1,2 Grays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY345",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 12 Grays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY050",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 9 centiGrays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY244",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 0,9 Grays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY356",
    designation:
      "Irradiation de moins de 300 cm² en champs fixes par accélérateur par fraction de 9 Grays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY301",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 centiGrays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY302",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 centiGrays pour les faisceaux de 0,5 à 4,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY313",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 centiGrays pour les faisceaux de 0,5 à 4,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "5.01",
    tarifHorsSecteur1HorsOptam: "5.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY324",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 centiGrays pour les faisceaux de 0,5 à 4,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "6.68",
    tarifHorsSecteur1HorsOptam: "6.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY343",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 2,8 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY335",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 2,8 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY346",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 2,8 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY357",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 2,8 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY367",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY368",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY379",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY383",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par télécobalt par fraction de 28 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY390",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 centiGrays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY392",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 centiGrays pour les faisceaux de 0,5 à 4,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY457",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 centiGrays pour les faisceaux de 0,5 à 4,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "5.01",
    tarifHorsSecteur1HorsOptam: "5.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY468",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 centiGrays pour les faisceaux de 0,5 à 4,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "6.68",
    tarifHorsSecteur1HorsOptam: "6.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY471",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2,5 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY479",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2,5 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY497",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2,5 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY303",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2,5 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY310",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY314",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY325",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY336",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 25 Grays pour les faisceaux de 0,5 à 4,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY307",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 centiGrays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY347",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 centiGrays pour les faisceaux de 5 à 7,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY358",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 centiGrays pour les faisceaux de 5 à 7,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "5.01",
    tarifHorsSecteur1HorsOptam: "5.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY369",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 centiGrays pour les faisceaux de 5 à 7,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "6.68",
    tarifHorsSecteur1HorsOptam: "6.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY380",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2 Grays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY387",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2 Grays pour les faisceaux de 5 à 7,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY391",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2 Grays pour les faisceaux de 5 à 7,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY458",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 2 Grays pour les faisceaux de 5 à 7,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY460",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 Grays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY469",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 Grays pour les faisceaux de 5 à 7,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY480",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 Grays pour les faisceaux de 5 à 7,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY491",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 20 Grays pour les faisceaux de 5 à 7,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY299",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 centiGrays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY304",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 centiGrays pour les faisceaux de 8 à 16,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY315",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 centiGrays pour les faisceaux de 8 à 16,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "5.01",
    tarifHorsSecteur1HorsOptam: "5.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY326",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 centiGrays pour les faisceaux de 8 à 16,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "6.68",
    tarifHorsSecteur1HorsOptam: "6.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY331",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,4 Grays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY337",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,4 Grays pour les faisceaux de 8 à 16,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY348",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,4 Grays pour les faisceaux de 8 à 16,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY359",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,4 Grays pour les faisceaux de 8 à 16,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY370",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 Grays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY377",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 Grays pour les faisceaux de 8 à 16,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY381",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 Grays pour les faisceaux de 8 à 16,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY398",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 14 Grays pour les faisceaux de 8 à 16,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY450",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 centiGrays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY459",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 centiGrays pour les faisceaux de 17 à 24,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY470",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 centiGrays pour les faisceaux de 17 à 24,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "5.01",
    tarifHorsSecteur1HorsOptam: "5.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY481",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 centiGrays pour les faisceaux de 17 à 24,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "6.68",
    tarifHorsSecteur1HorsOptam: "6.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY493",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,2 Grays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY492",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,2 Grays pour les faisceaux de 17 à 24,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY305",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,2 Grays pour les faisceaux de 17 à 24,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY316",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 1,2 Grays pour les faisceaux de 17 à 24,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY320",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 Grays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY327",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 Grays pour les faisceaux de 17 à 24,9 MeV, incluant 2 volumes",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY338",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 Grays pour les faisceaux de 17 à 24,9 MeV, incluant 3 volumes",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY349",
    designation:
      "Irradiation de 300 cm² et plus en champs fixes par accélérateur par fraction de 12 Grays pour les faisceaux de 17 à 24,9 MeV, incluant 4 volumes",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY360",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 centiGrays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "1.67",
    tarifHorsSecteur1HorsOptam: "1.67",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY365",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 centiGrays pour les faisceaux de 25 MeV ou plus, incluant 2 volumes",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY371",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 centiGrays pour les faisceaux de 25 MeV ou plus, incluant 3 volumes",
    tarifSecteur1Optam: "5.01",
    tarifHorsSecteur1HorsOptam: "5.01",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY382",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 centiGrays pour les faisceaux de 25 MeV ou plus, incluant 4 volumes",
    tarifSecteur1Optam: "6.68",
    tarifHorsSecteur1HorsOptam: "6.68",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY451",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 0,9 Grays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "16.7",
    tarifHorsSecteur1HorsOptam: "16.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY393",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 0,9 Grays pour les faisceaux de 25 MeV ou plus, incluant 2 volumes",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY500",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 0,9 Grays pour les faisceaux de 25 MeV ou plus, incluant 3 volumes",
    tarifSecteur1Optam: "50.1",
    tarifHorsSecteur1HorsOptam: "50.1",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY511",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 0,9 Grays pour les faisceaux de 25 MeV ou plus, incluant 4 volumes",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY520",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 Grays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "167",
    tarifHorsSecteur1HorsOptam: "167",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY522",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 Grays pour les faisceaux de 25 MeV ou plus, incluant 2 volumes",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY533",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 Grays pour les faisceaux de 25 MeV ou plus, incluant 3 volumes",
    tarifSecteur1Optam: "501",
    tarifHorsSecteur1HorsOptam: "501",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY544",
    designation:
      "Irradiation de 300 cm² ou plus en champs fixes par accélérateur par fraction de 9 Grays pour les faisceaux de 25 MeV ou plus, incluant 4 volumes",
    tarifSecteur1Optam: "668",
    tarifHorsSecteur1HorsOptam: "668",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY051",
    designation:
      "Irradiation en cyclothérapie par télécobalt par fraction de 28 centiGrays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY122",
    designation:
      "Irradiation en cyclothérapie par télécobalt par fraction de 2,8 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY555",
    designation:
      "Irradiation en cyclothérapie par télécobalt par fraction de 28 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY052",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 25 centiGrays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY053",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 2,5 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY054",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 25 Grays pour les faisceaux de 0,5 à 4,9 MeV",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY055",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 20 centiGrays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY056",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 2 Grays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY141",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 20 Grays pour les faisceaux de 5 à 7,9 MeV",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY175",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 14 centiGrays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY223",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 1,4 Grays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY256",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 14 Grays pour les faisceaux de 8 à 16,9 MeV",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY267",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 12 centiGrays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY566",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 1,2 Grays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY577",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 12 Grays pour les faisceaux de 17 à 24,9 MeV",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY588",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 9 centiGrays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "3.34",
    tarifHorsSecteur1HorsOptam: "3.34",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY599",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 0,9 Grays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "33.4",
    tarifHorsSecteur1HorsOptam: "33.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY306",
    designation:
      "Irradiation en cyclothérapie par accélérateur par fraction de 9 Grays pour les faisceaux de 25 MeV ou plus",
    tarifSecteur1Optam: "334",
    tarifHorsSecteur1HorsOptam: "334",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY023",
    designation: "Irradiation de contact pour affection maligne",
    tarifSecteur1Optam: "83.5",
    tarifHorsSecteur1HorsOptam: "83.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY015",
    designation: "Forfait de réanimation niveau A",
    tarifSecteur1Optam: "96",
    tarifHorsSecteur1HorsOptam: "96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY020",
    designation: "Forfait de réanimation niveau B",
    tarifSecteur1Optam: "160",
    tarifHorsSecteur1HorsOptam: "160",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY032",
    designation:
      "Induction de l'ovulation par gonadotrophines suivie d'une insémination artificielle ou d'une fécondation in vitro",
    tarifSecteur1Optam: "61.44",
    tarifHorsSecteur1HorsOptam: "61.44",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY007",
    designation:
      "Forfait hebdomadaire de prise en charge d’un patient insuffisant rénal en dialyse péritonéale",
    tarifSecteur1Optam: "56",
    tarifHorsSecteur1HorsOptam: "56",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY041",
    designation: "Supplément pour récupération peropératoire de sang",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ADA",
  },
  {
    codeCCAM: "YYYY595",
    designation:
      "Supplément pour hémofiltration au cours d'une surveillance de CEC",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY490",
    designation:
      "Supplément pour électrocardiographie réalisée au domicile du patient",
    tarifSecteur1Optam: "9.6",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY066",
    designation:
      "Supplément pour prélèvement peropératoire d'autogreffe veineuse saphène pour pontage fémoropoplité, pour pontage fémorotibial ou pontage fémoropéronier",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "83.6",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY013",
    designation:
      "Supplément pour prélèvement peropératoire d'autogreffe veineuse saphène ",
    tarifSecteur1Optam: "164.49",
    tarifHorsSecteur1HorsOptam: "125.4",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY068",
    designation:
      "Supplément pour injection intravasculaire de produit de contraste radiologique au cours d'une intervention pour pontage des membres inférieurs",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY072",
    designation:
      "Supplément pour injection intracarotidienne peropératoire de produit de contraste radiologique pour artériographie",
    tarifSecteur1Optam: "48",
    tarifHorsSecteur1HorsOptam: "48",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY400",
    designation:
      "Supplément pour injection peropératoire de produit de contraste radiologique dans les voies excrétrices bilio pancréatiques pour prises de clichés radiologiques",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY405",
    designation:
      "Supplément pour injection peropératoire de produit de contraste radiologique dans les voies excrétrices urinaires pour prises de clichés radiologiques",
    tarifSecteur1Optam: "41.8",
    tarifHorsSecteur1HorsOptam: "41.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY188",
    designation:
      "Supplément pour prélèvement peropératoire et pose d'autogreffe osseuse à distance du foyer opératoire",
    tarifSecteur1Optam: "85.08",
    tarifHorsSecteur1HorsOptam: "78.38",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY012",
    designation:
      "Supplément pour radiographie per opératoire au cours d'un acte de chirurgie orthopédique ou traumatologique",
    tarifSecteur1Optam: "19.29",
    tarifHorsSecteur1HorsOptam: "19.29",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY026",
    designation:
      "Supplément pour curage ganglionnaire médiastinal au cours d'une pneumonectomie ou d'une lobectomie",
    tarifSecteur1Optam: "178.67",
    tarifHorsSecteur1HorsOptam: "177.65",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY031",
    designation:
      "Supplément pour curage ganglionnaire médiastinal au cours d'un acte de chirurgie médiastinale",
    tarifSecteur1Optam: "178.67",
    tarifHorsSecteur1HorsOptam: "156.75",
    regroupement: "ADC",
  },
  {
    codeCCAM: "YYYY022",
    designation:
      "Supplément pour circulation extracorporelle au cours d'un acte de chirugie pulmonaire ",
    tarifSecteur1Optam: "313.5",
    tarifHorsSecteur1HorsOptam: "313.5",
    regroupement: "ADC",
  },
  {
    codeCCAM: '"YYYY603',
  },
  {
    codeCCAM: '[F]"',
    designation:
      "Supplément pour acte réalisé en urgence un dimanche ou un jour férié (F) appliqué aux actes d'accouchement ou de surveillance du travail réalisés par la sage-femme",
    tarifSecteur1Optam: "20.94",
    tarifHorsSecteur1HorsOptam: "20.94",
    regroupement: "ACO",
  },
  {
    codeCCAM: '"YYYY740',
  },
  {
    codeCCAM: '[P]"',
    designation:
      "Supplément pour acte réalisé en urgence de 20h à 00h (P) appliqué aux actes d'accouchement ou de surveillance du travail réalisés par la sage-femme",
    tarifSecteur1Optam: "15",
    tarifHorsSecteur1HorsOptam: "15",
    regroupement: "ACO",
  },
  {
    codeCCAM: '"YYYY285',
  },
  {
    codeCCAM: '[S]"',
    designation:
      "Supplément pour acte réalisé en urgence entre 00h et 08h (S) appliqué aux actes d'accouchement ou de surveillance du travail réalisés par la sage-femme",
    tarifSecteur1Optam: "40",
    tarifHorsSecteur1HorsOptam: "40",
    regroupement: "ACO",
  },
  {
    codeCCAM: "YYYY069",
    designation: "Supplément pour accouchement multiple par césarienne",
    tarifSecteur1Optam: "104.5",
    tarifHorsSecteur1HorsOptam: "104.5",
    regroupement: "ACO",
  },
  {
    codeCCAM: "YYYY067",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur moins de 30 cm²",
    tarifSecteur1Optam: "24.96",
    tarifHorsSecteur1HorsOptam: "24.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY085",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 30 cm² à 60 cm²",
    tarifSecteur1Optam: "37.94",
    tarifHorsSecteur1HorsOptam: "37.94",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY077",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 60 cm² à 100 cm²",
    tarifSecteur1Optam: "47.58",
    tarifHorsSecteur1HorsOptam: "47.58",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY094",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 100 cm² à 200 cm²",
    tarifSecteur1Optam: "69.14",
    tarifHorsSecteur1HorsOptam: "69.14",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY063",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 200 cm² à 350 cm²",
    tarifSecteur1Optam: "80.29",
    tarifHorsSecteur1HorsOptam: "80.29",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY112",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 350 cm² à 500 cm²",
    tarifSecteur1Optam: "93.7",
    tarifHorsSecteur1HorsOptam: "93.7",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY222",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 500 cm² à 700 cm²",
    tarifSecteur1Optam: "114.24",
    tarifHorsSecteur1HorsOptam: "114.24",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY005",
    designation:
      "Supplément pour utilisation d'un laser à colorant pulsé pour destruction d'angiome plan sur 700 cm² et plus",
    tarifSecteur1Optam: "150.93",
    tarifHorsSecteur1HorsOptam: "150.93",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY146",
    designation:
      "Réalisation de potentiels évoqués somesthésiques au cours de la chirurgie rachidienne",
    tarifSecteur1Optam: "186.62",
    tarifHorsSecteur1HorsOptam: "134.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY189",
    designation:
      "Réalisation d'un acte d' électroéncéphalographie au cours d'une intervention de chirurgie endo thoracique ou de neurochirurgie",
    tarifSecteur1Optam: "186.62",
    tarifHorsSecteur1HorsOptam: "134.4",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY456",
    designation:
      "Supplément pour réalisation d'un acte d'électroencéphalographie au domicile ou dans un établissement dépourvu d'appareillage électroencéphalographique",
    tarifSecteur1Optam: "76.8",
    tarifHorsSecteur1HorsOptam: "76.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY058",
    designation:
      "Supplément pour réalisation de gaz du sang au cours d'exploration fonctionnelle respiratoire niveau 1",
    tarifSecteur1Optam: "24.96",
    tarifHorsSecteur1HorsOptam: "24.96",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY186",
    designation:
      "Supplément pour réalisation de gaz du sang au cours d'exploration fonctionnelle respiratoire niveau 2",
    tarifSecteur1Optam: "26.88",
    tarifHorsSecteur1HorsOptam: "26.88",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY232",
    designation:
      "Supplément pour réalisation de gaz du sang au cours d'exploration fonctionnelle respiratoire niveau 3",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY025",
    designation:
      "Supplément pour mesure du volume résiduel de la ventilation maximale au cours d'une spirométrie standard",
    tarifSecteur1Optam: "23.04",
    tarifHorsSecteur1HorsOptam: "23.04",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY065",
    designation:
      "Supplément pour cathétérisme et injection de produit de contraste radiologique dans les voies lacrymales",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY425",
    designation:
      "Supplément pour injection de produit de contraste radiologique pour sialographie",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY145",
    designation:
      "Supplément pour examen radiologique de la vésicule et des voies biliaires et /ou pancréatiques au cours d'un acte diagnostique ou thérapeutique",
    tarifSecteur1Optam: "57.19",
    tarifHorsSecteur1HorsOptam: "57.19",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY212",
    designation:
      "Supplément pour étude mictionnelle au cours d'une urétrocystographie",
    tarifSecteur1Optam: "27.93",
    tarifHorsSecteur1HorsOptam: "27.93",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY410",
    designation:
      "Supplément pour injection de produit de contraste radiologique pour urétrocystographie rétrograde",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY415",
    designation:
      "Supplément pour injection intra utérine de produit de contraste radiologique ou de substance médicamenteuse",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY070",
    designation:
      "Supplément pour injection de produit de contraste radiologique pour discographie intervertébrale",
    tarifSecteur1Optam: "38.4",
    tarifHorsSecteur1HorsOptam: "38.4",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY318",
    designation:
      "Supplément pour injection de produit de contraste radiologique pour discographie intervertébrale multiple",
    tarifSecteur1Optam: "57.6",
    tarifHorsSecteur1HorsOptam: "57.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY420",
    designation:
      "Supplément pour injection de produit de contraste radiologique pour arthrographie",
    tarifSecteur1Optam: "9.6",
    tarifHorsSecteur1HorsOptam: "9.6",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY300",
    designation:
      "Supplément pour imagerie pour acte de radiologie interventionnelle, réalisée au bloc opératoire",
    tarifSecteur1Optam: "79.8",
    tarifHorsSecteur1HorsOptam: "79.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY057",
    designation:
      "Supplément pour injection intrathécale de produit de contraste radiologique",
    tarifSecteur1Optam: "15.36",
    tarifHorsSecteur1HorsOptam: "15.36",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY039",
    designation:
      "Supplément pour utilisation de radiocinéma, en 35 mm au cours d'un examen de radiologie conventionnelle",
    tarifSecteur1Optam: "33.25",
    tarifHorsSecteur1HorsOptam: "33.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY064",
    designation:
      "Supplément pour utilisation de magnétoscope, au cours d'un examen de radiologie conventionnelle",
    tarifSecteur1Optam: "33.25",
    tarifHorsSecteur1HorsOptam: "33.25",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY073",
    designation:
      "Supplément pour injection de produit de contraste radiologique, dans les conduits lactifères",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY037",
    designation:
      "Supplément pour injection sus pubienne de produit de contraste radiologique pour cystographie",
    tarifSecteur1Optam: "28.8",
    tarifHorsSecteur1HorsOptam: "28.8",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY019",
    designation:
      "Supplément pour injection de produit de contraste radiologique pour arthrographie de la hanche",
    tarifSecteur1Optam: "19.2",
    tarifHorsSecteur1HorsOptam: "19.2",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY600",
    designation:
      "Supplément pour archivage numérique d’une mammographie ou d’un examen scanographique ou remnographique",
    tarifSecteur1Optam: "0.75",
    tarifHorsSecteur1HorsOptam: "0.75",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY666",
    designation:
      "Supplément pour acte de radiographie conventionnelle nécessitant l'utilisation d'un produit de contraste radiologique à la charge du professionnel",
    tarifSecteur1Optam: "54.65",
    tarifHorsSecteur1HorsOptam: "54.65",
    regroupement: "ADI",
  },
  {
    codeCCAM: "YYYY109",
    designation:
      "Supplément pour utilisation de caches personnalisés et focalisés",
    tarifSecteur1Optam: "66.8",
    tarifHorsSecteur1HorsOptam: "66.8",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY128",
    designation: "Supplément pour paramétrage d'un collimateur multilames",
    tarifSecteur1Optam: "83.5",
    tarifHorsSecteur1HorsOptam: "83.5",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY151",
    designation:
      "Supplément pour contrôles balistiques de qualité en radiothérapie par gammagraphie",
    tarifSecteur1Optam: "8.35",
    tarifHorsSecteur1HorsOptam: "8.35",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY166",
    designation:
      "Supplément pour contrôles balistiques de qualité en radiothérapie par imagerie portale",
    tarifSecteur1Optam: "25.05",
    tarifHorsSecteur1HorsOptam: "25.05",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY008",
    designation:
      "Supplément pour réalisation d'un examen radiologique par un médecin de montagne lors de la prise en charge diagnostique et thérapeutique dans le même temps d'une lésion ostéo-articulaire, musculo-tendineuse ou des parties molles d'origine traumatique",
    tarifSecteur1Optam: "11.52",
    tarifHorsSecteur1HorsOptam: "11.52",
    regroupement: "ATM",
  },
  {
    codeCCAM: "YYYY176",
    designation:
      "Supplément pour pose d'une dent contreplaquée sur une prothèse amovible à plaque base résine",
    tarifSecteur1Optam: "21.5",
    tarifHorsSecteur1HorsOptam: "21.5",
    regroupement: "SU0",
  },
  {
    codeCCAM: "YYYY275",
    designation:
      "Supplément pour pose de 2 dents contreplaquées sur une prothèse amovible à plaque base résine",
    tarifSecteur1Optam: "43",
    tarifHorsSecteur1HorsOptam: "43",
    regroupement: "SU0",
  },
  {
    codeCCAM: "YYYY246",
    designation:
      "Supplément pour pose de 3 dents contreplaquées sur une prothèse amovible à plaque base résine",
    tarifSecteur1Optam: "64.5",
    tarifHorsSecteur1HorsOptam: "64.5",
    regroupement: "SU0",
  },
  {
    codeCCAM: "YYYY478",
    designation:
      "Supplément pour pose de 4 dents contreplaquées sur une prothèse amovible à plaque base résine",
    tarifSecteur1Optam: "86",
    tarifHorsSecteur1HorsOptam: "86",
    regroupement: "SU0",
  },
  {
    codeCCAM: "YYYY426",
    designation:
      "Supplément pour pose de 5 dents contreplaquées sur une prothèse amovible à plaque base résine",
    tarifSecteur1Optam: "107.5",
    tarifHorsSecteur1HorsOptam: "107.5",
    regroupement: "SU0",
  },
  {
    codeCCAM: "YYYY389",
    designation:
      "Supplément pour pose de 6 dents contreplaquées sur une prothèse amovible à plaque base résine",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "SU0",
  },
  {
    codeCCAM: "YYYY159",
    designation:
      "Supplément pour pose d'une dent contreplaquée ou massive à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "32.25",
    tarifHorsSecteur1HorsOptam: "32.25",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY329",
    designation:
      "Supplément pour pose de 2 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "64.5",
    tarifHorsSecteur1HorsOptam: "64.5",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY258",
    designation:
      "Supplément pour pose de 3 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "96.75",
    tarifHorsSecteur1HorsOptam: "96.75",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY259",
    designation:
      "Supplément pour pose de 4 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "129",
    tarifHorsSecteur1HorsOptam: "129",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY440",
    designation:
      "Supplément pour pose de 5 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "161.25",
    tarifHorsSecteur1HorsOptam: "161.25",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY447",
    designation:
      "Supplément pour pose de 6 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "193.5",
    tarifHorsSecteur1HorsOptam: "193.5",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY142",
    designation:
      "Supplément pour pose de 7 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "225.75",
    tarifHorsSecteur1HorsOptam: "225.75",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY158",
    designation:
      "Supplément pour pose de 8 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "258",
    tarifHorsSecteur1HorsOptam: "258",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY476",
    designation:
      "Supplément pour pose de 9 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "290.25",
    tarifHorsSecteur1HorsOptam: "290.25",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY079",
    designation:
      "Supplément pour pose de 10 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "322.5",
    tarifHorsSecteur1HorsOptam: "322.5",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY184",
    designation:
      "Supplément pour pose de 11 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "354.75",
    tarifHorsSecteur1HorsOptam: "354.75",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY284",
    designation:
      "Supplément pour pose de 12 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "387",
    tarifHorsSecteur1HorsOptam: "387",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY236",
    designation:
      "Supplément pour pose de 13 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "419.25",
    tarifHorsSecteur1HorsOptam: "419.25",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY353",
    designation:
      "Supplément pour pose de 14 dents contreplaquées ou massives à une prothèse amovible sur châssis métallique",
    tarifSecteur1Optam: "451.5",
    tarifHorsSecteur1HorsOptam: "451.5",
    regroupement: "SU1",
  },
  {
    codeCCAM: "YYYY465",
    designation:
      "Supplément pour examen spécifique préalable et postérieur à l’acte de pose d'un appareillage en propulsion mandibulaire dans le traitement du syndrome d'apnées-hypopnées obstructives du sommeil",
    tarifSecteur1Optam: "70",
    tarifHorsSecteur1HorsOptam: "70",
    regroupement: "ADM",
  },
  {
    codeCCAM: "YYYY183",
    designation:
      "Supplément pour actes buccodentaires pour un patient en situation de handicap sévère",
    tarifSecteur1Optam: "100",
    tarifHorsSecteur1HorsOptam: "100",
    regroupement: "ASC",
  },
  {
    codeCCAM: "YYYY185",
    designation:
      "Supplément pour actes bucco-dentaires réalisés en 2 séances ou plus pour prise en charge d’un patient en situation de handicap sévère",
    tarifSecteur1Optam: "200",
    tarifHorsSecteur1HorsOptam: "200",
    regroupement: "ASC",
  },
  {
    codeCCAM: "YYYY614",
    designation:
      "Supplément pour soins chirurgicaux dentaires pour un patient en ALD et traité par anticoagulants oraux directs [AOD] ou par antivitamine K [AVK]",
    tarifSecteur1Optam: "20",
    tarifHorsSecteur1HorsOptam: "20",
    regroupement: "ASC",
  },
  {
    codeCCAM: "YYYY755",
    designation:
      "Supplément pour avulsion de 4 troisièmes molaires retenues ou à l'état de germe réalisée en cabinet sous anesthésie locale",
    tarifSecteur1Optam: "125",
    tarifHorsSecteur1HorsOptam: "125",
    regroupement: "ASC",
  },
  {
    codeCCAM: "YYYY071",
    designation: "Supplément pour réalisation d'une colposcopie",
    tarifSecteur1Optam: "15",
    tarifHorsSecteur1HorsOptam: "15",
    regroupement: "ATM",
  },
  {
    codeCCAM: "HN",
    designation: "Hors Nomenclature",
    tarifSecteur1Optam: "",
    tarifHorsSecteur1HorsOptam: "",
    regroupement: "",
  },
];
