import mc from "./login.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase.config";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, password } = useSelector((store) => store.loginReducer);
  const { loading } = useSelector((store) => store.loadingReducer.loading);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const form = { email, password };
    // dispatch((dispatch, getState) => loginThunk(dispatch, getState, form));
    try {
      await signInWithEmailAndPassword(auth, email, password);
      dispatch({ type: "CONNECT_USER" });
      navigate("./dashboard");
      dispatch({ type: "RESET_LOGIN_FORM" });
    } catch {
      console.log("wrong username or password.");
    }
  };

  const handleChangeField = (key, value) => {
    dispatch({ type: "UPDATE_LOGIN_FIELD", payload: { key, value } });
  };

  return (
    <div className={mc.wrapper}>
      <form className={mc.card} onSubmit={(e) => handleSubmit(e)}>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type="email"
              placeholder="Email"
              id="email"
              name="email"
              onChange={(e) => handleChangeField("email", e.target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
            {/* <span className="icon is-small is-right">
              <i className="fas fa-check"></i>
            </span> */}
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left">
            <input
              className="input"
              type="password"
              placeholder="Password"
              name="password"
              id="password"
              onChange={(e) => handleChangeField("password", e.target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control">
            <button
              className={`button is-success ${loading ? "is-loading" : ""}`}
              type="submit"
              disabled={loading}
            >
              Login
            </button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
