const formatDateInput = (d) => {
  if (d !== "") {
    const date = new Date(d);
    // Check if the date is valid
    if (!isNaN(date.getTime())) {
      // Format the date as dd/mm/yyyy
      const formattedDate =
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear();
      return formattedDate;
    } else {
      // Handle invalid date
      console.error("Invalid date");
    }
  }
};

export default formatDateInput;
