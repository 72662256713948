import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.scss";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
/* Mise en place redux */
import { Provider } from "react-redux";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import reducers from "./lib/reducers";
import { thunk } from "redux-thunk";
// import appMiddleware from "./lib/middlewares/app-middleware.js";

const middlewares = applyMiddleware(thunk);

// LIEN AVEC L'EXTENSION REDUX DEVTOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* Mise en place redux */
const store = createStore(reducers, composeEnhancers(middlewares));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
