import { Link } from "react-router-dom";
import Hero from "../hero/Hero";
import { collection, doc, getDocs } from "firebase/firestore";
import { auth, db } from "../../config/firebase.config";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const BACKEND = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PORT}`;

// Function to fetch clinic's pecs from Firestore
const fetchPecs = async (clinicId) => {
  try {
    // Check if user is authenticated
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User not authenticated");
    }
    // Get the collection "clinics"
    const clinicRef = doc(db, "clinics", clinicId);
    // Get the sub-collection "pecs"
    const pecsSnapshot = await getDocs(collection(clinicRef, "pecs"));
    // Extract data from snapshot
    const pecs = pecsSnapshot.docs.map((doc) => {
      return { id: doc.id, data: doc.data() };
    });
    // Return the array of pecs
    return pecs;
  } catch (error) {
    console.error("Error fetching Clinic's PEC:", error);
    throw error; // You can handle the error appropriately in your application
  }
};

const getPecFile = async (clinicId, pecId) => {
  try {
    // Get the Firebase ID token
    const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);

    // Make an HTTP request to the Cloud Function endpoint, the function must be deployed in firebase function !
    const response = await fetch(
      `${BACKEND}/downloadPec?clinicId=${clinicId}&pecId=${pecId}`,
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Authorization: `${idToken}`, // Include the Firebase ID token in the Authorization header
        },
      }
    );

    // Check if the request was successful
    if (response.ok) {
      // Handle success
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PEC-${pecId}.json`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("PEC downloaded successfully");
    } else {
      // Handle error
      console.error("Failed to download PEC:", response.statusText);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const OverviewClinic = ({ clinic }) => {
  const clinicId = clinic.id;
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allPecs = await fetchPecs(clinicId);
        dispatch({ type: "GET_ALL_CLINIC_PECS", payload: allPecs });
      } catch (error) {
        console.error("Error fetching clinic's PECs:", error);
      }
    };

    fetchData();
  }, [dispatch, clinicId]);

  const { pecs } = useSelector((store) => store.clinicReducer);
  const { loading } = useSelector((store) => store.loadingReducer);
  const [rowSelected, setRowSelected] = useState(false);

  const handleClickDownload = async (e, pecId) => {
    e.preventDefault();
    dispatch({ type: "START_LOADING" });
    // download PEC to JSON file
    await getPecFile(clinicId, pecId);

    dispatch({ type: "STOP_LOADING" });
  };
  return (
    <>
      <div className="block">
        <Hero title={clinic.data.name} subtitle={clinic.data.city} />
      </div>
      <div className="block">
        <Link to="new-pec">
          <button className="button is-medium is-fullwidth is-danger is-rounded">
            <span>NOUVELLE SAISIE</span>
          </button>
        </Link>
      </div>
      <div className="block">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Patient</th>
              <th>N° Sécurité Social</th>
              <th>N°Devis</th>
              <th>Statut</th>
              <th>Télécharger</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>#</th>
              <th>Patient</th>
              <th>N°Sécurité Social</th>
              <th>N°Devis</th>
              <th>Statut</th>
              <th>Télécharger</th>
            </tr>
          </tfoot>
          <tbody>
            {pecs.map((v, i) => {
              return (
                <tr
                  className={`${rowSelected ? "is-selected" : ""}`}
                  onClick={() => setRowSelected(!rowSelected)}
                  key={v.id}
                >
                  <th>{i + 1}</th>
                  <td>
                    <a href="/#">{`${v.data.patientFirstName} ${v.data.patientLastName}`}</a>
                  </td>
                  <td>{v.data.numSS}</td>
                  <td>{v.data.numDevis}</td>
                  <td>{v.data.statut}</td>
                  <td>
                    <button
                      onClick={(e) => handleClickDownload(e, v.id)}
                      className={`button is-link is-fullwidth ${loading ? "is-loading" : ""}`}
                    >
                      <span className="icon">
                        <i className="fas fa-download"></i>
                      </span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OverviewClinic;
